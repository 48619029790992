import { CircularProgress } from "@react-md/progress";

import * as NAV from '@utilities/constants/navigation';
import Dashboard from '@views/dashboard';
import PractitionerDashboard from '@views/PractitionerDashboard.js';
import BoardOfDirectorsAndCompensation from '@views/governance/boardOfDirectorsAndCompensation';
import ForeignActivities from '@views/financial/foreignActivities';
import TaxComplianceFilings from '@views/governance/taxComplianceFilings';
import EntryExperienceModal from "@components/entryExperience/entryModal";
import BasicData from '@views/basicInformation/basicData';
import GovernanceManagement from '@views/governance/governanceAndManagement/index.js';
import Contributions from '@views/financial/contributions';
import FundraisingAndGaming from '@views/financial/fundraisingAndGaming'
import PubCharStaSec from '@views/financial/publicCharityAndSupport';
import FinancialSection from '@views/financial/financials';
import DomGraOthSection from '@views/financial/domesticGrants';
import SchoolsSection from '@views/otherSchedules/schools';
import Hospital from '@views/otherSchedules/hospital';
import InterestedPersonsTransactions from '@views/otherSchedules/interestedPersons';
import PolCampaigLobAno from '@views/financial/politicalCampaignAndLobbying';
import LiquidationAndDisposition from '@views/financial/liquidationAndDisposition';

import AdminDashboard from '@views/admin/adminDashboard';
import ManageClients from '@views/admin/manageClients';
import ManageOrganizers from '@views/admin/manageOrganizers';
import ManageGroups from '@views/admin/manageGroups';
import ManageInCharge from '@views/admin/manageInCharge';
import ManageUsers from '@views/admin/manageUsers';
import OrganizerDetails from '@views/admin/organizerDetails';

import SuppFinStatements from '@views/otherSchedules/supplementalFinancialStatements';
import TaxExemptBonds from "@views/otherSchedules/taxExemptBonds";
import Programs from '@views/basicInformation/programs';
import InternalSetup from '@views/basicInformation/internalSetup';
import UnrelatedBusinessIncomeTax from '@views/financial/unrelatedBusinessIncomeTax';
import RelatedOrganizations from "@views/otherSchedules/relatedOrganizations";
import OrganizerExport from '@views/organizerExport';
import CacheBreak from '@views/cachebreak';
import Home from "@views/home";

const Loading = () => <CircularProgress id="loading-progress" />;

const getRoutes = (isAuthenicated, isPractitioner, isAdmin, isClient, activeReturn) => {
	// GROWTH: Handle strict page access with all three roles including client

	// If the account is disabled, only allow access to the one page.
	if (!isAuthenicated) {
		return [
			{ label: 'Home', to: NAV.DEFAULT, componentObject: Loading }
		];
	}

	const defaultHome = PractitionerDashboard;
	const organizerIdUrlParam = "/:organizerId"

	const pages = [
		{ to: `${organizerIdUrlParam}${NAV.DASHBOARD}`, componentObject: Dashboard },
		{ to: `${organizerIdUrlParam}${NAV.BOARD_OF_DIRECTORS_AND_COMPENSATION}`, componentObject: BoardOfDirectorsAndCompensation },
		{ to: `${organizerIdUrlParam}${NAV.PROGRAMS}`, componentObject: Programs },
		{ to: `${organizerIdUrlParam}${NAV.FOREIGN_ACTIVITIES}`, componentObject: ForeignActivities },
		{ to: `${organizerIdUrlParam}${NAV.BASIC_DATA}`, componentObject: BasicData },
		{ to: `${organizerIdUrlParam}${NAV.ENTRY_INTERVIEW}`, componentObject: EntryExperienceModal },
		{ to: `${organizerIdUrlParam}${NAV.GOVERNANCE_AND_MANAGEMENT}`, componentObject: GovernanceManagement },
		{ to: `${organizerIdUrlParam}${NAV.TAX_COMPLIANCE_FILINGS}`, componentObject: TaxComplianceFilings },
		{ to: `${organizerIdUrlParam}${NAV.CONTRIBUTIONS}`, componentObject: Contributions },
		{ to: `${organizerIdUrlParam}${NAV.FUNDRAISING_AND_GAMING}`, componentObject: FundraisingAndGaming },
		{ to: `${organizerIdUrlParam}${NAV.PUBLIC_CHARITY_AND_SUPPORT}`, componentObject: PubCharStaSec },
		{ to: `${organizerIdUrlParam}${NAV.FINANCIALS}`, componentObject: FinancialSection },
		{ to: `${organizerIdUrlParam}${NAV.SUPPLEMENTAL_FINANCIAL_STATEMENTS}`, componentObject: SuppFinStatements },
		{ to: `${organizerIdUrlParam}${NAV.TAX_EXEMPT_BONDS}`, componentObject: TaxExemptBonds },
		{ to: `${organizerIdUrlParam}${NAV.DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE}`, componentObject: DomGraOthSection },
		{ to: `${organizerIdUrlParam}${NAV.SCHOOLS}`, componentObject: SchoolsSection },
		{ to: `${organizerIdUrlParam}${NAV.HOSPITAL}`, componentObject: Hospital },
		{ to: `${organizerIdUrlParam}${NAV.INTERESTED_PERSONS_TRANSACTIONS}`, componentObject: InterestedPersonsTransactions },
		{ to: `${organizerIdUrlParam}${NAV.POLITICAL_CAMPAIGN_AND_LOBBYING}`, componentObject: PolCampaigLobAno },
		{ to: `${organizerIdUrlParam}${NAV.LIQUIDATION_AND_DISPOSITION_OF_ASSETS}`, componentObject: LiquidationAndDisposition },
		{ to: `${organizerIdUrlParam}${NAV.INTERNAL_SETUP}`, componentObject: InternalSetup },
		{ to: `${organizerIdUrlParam}${NAV.UNRELATED_BUSINESS_INCOME_TAX}`, componentObject: UnrelatedBusinessIncomeTax },
		{ to: `${organizerIdUrlParam}${NAV.RELATED_ORGANIZATIONS_SCHED_R}`, componentObject: RelatedOrganizations },
	];

	const adminPages = [
		{ to: NAV.DEFAULT, componentObject: defaultHome },
		{ to: NAV.PRACTITIONERS_DASHBOARD, componentObject: PractitionerDashboard },
		{ to: NAV.ADMIN_DASHBOARD, componentObject: AdminDashboard },
		{ to: NAV.MANAGE_CLIENTS, componentObject: ManageClients },
		{ to: NAV.MANAGE_GROUPS, componentObject: ManageGroups },
		{ to: NAV.MANAGE_ORGANIZERS, componentObject: ManageOrganizers },
		{ to: `${NAV.MANAGE_ORGANIZERS}/:id`, componentObject: OrganizerDetails },
		{ to: NAV.MANAGE_IN_CHARGE, componentObject: ManageInCharge },
		{ to: NAV.MANAGE_USERS, componentObject: ManageUsers },
		{ to: `${NAV.ORGANIZER_EXPORT}/:orgId`, componentObject: OrganizerExport },
	];

	const practitionerPages = [
		{ to: NAV.DEFAULT, componentObject: defaultHome },
		{ to: NAV.PRACTITIONERS_DASHBOARD, componentObject: PractitionerDashboard },
		{ to: `${NAV.ORGANIZER_EXPORT}/:orgId`, componentObject: OrganizerExport },
	];

	const clientPages = [
		{ to: NAV.DEFAULT, componentObject: defaultHome },
		{ to: NAV.PRACTITIONERS_DASHBOARD, componentObject: PractitionerDashboard },
		{ to: `${NAV.ORGANIZER_EXPORT}/:orgId`, componentObject: OrganizerExport },
	];

	const routes = [
		...pages,
		...(isClient ? clientPages : []),
		...(isAdmin ? adminPages : []),
		...(isPractitioner ? practitionerPages : []),
		{ to: NAV.HOME, componentObject: Home },
		{ to: NAV.CACHE_BREAK, componentObject: CacheBreak },
		{ to: `${organizerIdUrlParam}`, componentObject: Dashboard },
	];

	return routes;
};

export {
	getRoutes
};