import { TransformDateHumanFriendly } from './transformers';
import { convertDate } from './convertDate';

export function required(message = 'Required') {
    return {
        required: {
            message
        }
    };
}

export function email(message = 'Must be a valid email address!') {
    return {
        email: {
            message
        }
    };
}

export function minLength(length, message = `Must be at least ${length} characters.`) {
    return {
        minLength: {
            length,
            message
        }
    };
}

export function maxLength(length, message = `Must be ${length} character or no charaacters.`) {
    return {
        maxLength: {
            length,
            message
        }
    };
}

export function minDate(date) {
    const dateStart = date ? date === 'today' ? convertDate(String(new Date())) : convertDate(date) : convertDate('01-01-1900');
    const start = new Date(dateStart);
    start.setDate(start.getDate() + 1);

    return {
        minDate: {
            date,
            message: `Must be ${TransformDateHumanFriendly(start)} or later.`
        }
    };
}

export function maxDate(date) {
    const dateEnd = date ? date === 'today' ? convertDate(String(new Date())) : convertDate(date) : convertDate('12-31-2099');
    const end = new Date(dateEnd);

    return {
        maxDate: {
            date,
            message: `Must be ${TransformDateHumanFriendly(end)} or earlier.`
        } 
    };  
}

export function pattern(pattern, message) {
    return {
        pattern: {
            pattern,
            message
        }
    };
}

export function year(message = 'Must be from 1950 to current.') {
    return {
        year: {
            message
        }
    };
}

export function ssn(message = 'Must be 9 digits.') {
    return {
        ssn: {
            message
        }
    };
}

export function fixLength(length, message = `Must be ${length} characters.`) {
    return {
        fixLength: {
            length,
            message
        }
    };
}

export function ein(message = 'Must be 9 digits.') {
    return {
        ein: {
            message
        }
    };
}

export function maxValue(value, message = `Maximum value that can be entered is ${value}`) {
    return {
        maxValue: {
            value,
            message
        }
    };
}

export function isRoutingNumber(message = 'Invalid check digit, or only 9 digits starting with 01-12 or 21-32') {
    return {
        routingNumber: {
            message
        }
    };
}