import * as EVENT from '@utilities/constants/triggerKeys';



const triggeredEvent = (trigger, event, options) => {
  switch (trigger) {
    case 'showPoliticalCampaignActivities':
      showPoliticalCampaignActivities(event, options);
      break;
    case 'showForm4720':
      showForm4720(event, options);
      break;
    case 'showCorrectionMade':
      showCorrectionMade(event, options);
      break;
    case 'show527InternalAmout':
      show527InternalAmout(event, options);
      break;
    case 'showPolCamProvince':
      showPolCamProvince(event, options);
      break;
    case 'showOrganizationsExpenditure':
      showOrganizationsExpenditure(event, options);
      break;
    case 'totalExciseTax':
      totalExciseTax(event, options);
      break;
    case 'orgFileForm4720':
      orgFileForm4720(event, options);
      break;
    case 'politicalExpenditureExpenses':
      politicalExpenditureExpenses(event, options);
      break;
    case 'showPolActDltNo5768DescAndExpAmt':
      showPolActDltNo5768DescAndExpAmt(event, options);
      break;
    default:
      break;
  }
};

const showPoliticalCampaignActivities = (event, options) => {
  const { formSections, setFormSections } = options;
  const name = formSections;

  const reqSchdChkCi = name[0].groups[0].fields[1].default;
  const reqSchdChkCii = name[0].groups[1]?.fields[1].default;
  const reqSchdChkCiii = name[0].groups[2]?.fields[1].default;

  name[1].isVisible = reqSchdChkCi === EVENT.KEY_YES || reqSchdChkCii === EVENT.KEY_YES;
  name[2].isVisible = (name[0].groups[1].isVisible && reqSchdChkCii === EVENT.KEY_YES)|| (name[0].groups[2].isVisible && reqSchdChkCiii === EVENT.KEY_YES);



  if (reqSchdChkCi === EVENT.KEY_YES) {
    name[1].groups[0].isVisible = true;
  } else {
    name[1].groups[0].isVisible = false;
  }

  if (reqSchdChkCii === EVENT.KEY_YES) {
    name[2].groups[0].isVisible = true;
  } else {
    name[2].groups[0].isVisible = true;
  }

  if (reqSchdChkCiii === EVENT.KEY_YES) {
    name[2].groups[0].isVisible = true;
  }

  setFormSections(name);

}

const showForm4720 = (event, options) => {

  const { formSections, setFormSections } = options;
  const name = formSections;

  if (name[1].groups[5].fields[1].default === EVENT.KEY_NO) {
    name[1].groups[6].isVisible = true;
  }
  else {
    name[1].groups[6].isVisible = false;
  }

  setFormSections(name);

}

const showCorrectionMade = (event, options) => {

  const { formSections, setFormSections } = options;
  const name = formSections;

  if (name[1].groups[7].fields[1].default === EVENT.KEY_YES) {
    name[1].groups[8].isVisible = true;
    name[1].groups[9].isVisible = false;
  }
  else {
    name[1].groups[8].isVisible = false;
    name[1].groups[9].isVisible = true;
  }

  setFormSections(name);

}

const show527InternalAmout = (event, options) => {

  const { formSections, setFormSections } = options;
  const name = formSections;

  if (name[1].groups[12].fields[1].default === EVENT.KEY_NO) {
    name[1].groups[13].isVisible = true;
  }
  else {
    name[1].groups[13].isVisible = false;
  }

  setFormSections(name);

}

const showPolCamProvince = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;


  const isUnitedStates = event === EVENT.KEY_UNITED_STATES;
  const isCanada = event === EVENT.KEY_CANADA;
  const isOther = !isUnitedStates && !isCanada;

  name[0].groups[1].fields[3].isVisible = isOther;
  name[0].groups[1].fields[4].isVisible = isUnitedStates;
  name[0].groups[1].fields[5].isVisible = isCanada;

  setFormSections(name);

}

const showOrganizationsExpenditure = (event, options) => {
  const { formSections, setFormSections } = options;
  const name = formSections;

  if (event === EVENT.KEY_YES) {
    name[2].groups[1].isVisible = true;
    name[2].groups[2].isVisible = true;
    name[2].groups[3].isVisible = true;
    name[2].groups[4].isVisible = true;
    name[2].groups[5].isVisible = true;
    name[2].groups[6].isVisible = true;
    name[2].groups[7].isVisible = true;
  } else {
    name[2].groups[1].isVisible = false;
    name[2].groups[2].isVisible = false;
    name[2].groups[3].isVisible = false;
    name[2].groups[4].isVisible = false;
    name[2].groups[5].isVisible = false;
    name[2].groups[6].isVisible = false;
    name[2].groups[7].isVisible = false;
  }

  if (event === EVENT.KEY_NO) {
    name[2].groups[8].isVisible = true;
    name[2].groups[9].isVisible = true;
    name[2].groups[10].isVisible = true;


  } else {
    name[2].groups[8].isVisible = false;
    name[2].groups[9].isVisible = false;
    name[2].groups[10].isVisible = false;
  }

  if (name[2].groups[11] && name[2].groups[12]) {
    name[2].groups[11].isVisible = event === EVENT.KEY_NO && name[2].groups[10].fields[1].default === EVENT.KEY_YES;
    name[2].groups[12].isVisible = event === EVENT.KEY_NO && name[2].groups[10].fields[1].default === EVENT.KEY_YES;
  }

  if (name[2].groups[13]) {
    name[2].groups[13].isVisible = event === EVENT.KEY_NO && name[2].groups[10].fields[1].default === EVENT.KEY_YES;
  }

  if (name[2].groups[14]) {
    name[2].groups[14].isVisible = event === EVENT.KEY_NO &&
      name[2].groups[10].fields[1].default === EVENT.KEY_YES &&
      name[2].groups[13].fields[1].default === EVENT.KEY_NO;
  }

  setFormSections(name);
}

const totalExciseTax = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;

  const firstRadio = name[2].groups[0].fields[1].default;
  const secondRadio = name[2].groups[10].fields[1].default;

  name[2].groups[11].isVisible = secondRadio === EVENT.KEY_YES && firstRadio === EVENT.KEY_NO;
  name[2].groups[12].isVisible = secondRadio === EVENT.KEY_YES && firstRadio === EVENT.KEY_NO;
  name[2].groups[13].isVisible = secondRadio === EVENT.KEY_YES && firstRadio === EVENT.KEY_NO;

  if (name[2].groups[14]) {
    name[2].groups[14].isVisible = firstRadio === EVENT.KEY_NO &&
      secondRadio === EVENT.KEY_YES &&
      name[2].groups[13].fields[1].default === EVENT.KEY_NO;
  }

  setFormSections(name);

}

const orgFileForm4720 = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;

  const firstRadio = name[2].groups[0].fields[1].default;
  const secondRadio = name[2].groups[10].fields[1].default;
  const thirdRadio = name[2].groups[13].fields[1].default;

  name[2].groups[14].isVisible = secondRadio === EVENT.KEY_YES && firstRadio === EVENT.KEY_NO && thirdRadio === EVENT.KEY_NO;

  setFormSections(name);

}

const politicalExpenditureExpenses = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;

  const firstRadio = name[2].groups[0].fields[1].default;
  const secondRadio = name[2].groups[10].fields[1].default;
  const thirdRadio = name[2].groups[13].fields[1].default;

  name[2].groups[14].isVisible = secondRadio === EVENT.KEY_YES && firstRadio === EVENT.KEY_NO && thirdRadio === EVENT.KEY_NO;

  if (name[2].groups[17].fields[1].default === EVENT.KEY_YES || (name[2].groups[15].fields[1].default === EVENT.KEY_NO && name[2].groups[16].fields[1].default === EVENT.KEY_NO)) {

    name[2].groups[17].hasDivider = true;
    name[2].groups[18].isVisible = true;
    name[2].groups[19].isVisible = true;
    name[2].groups[20].isVisible = true;
    name[2].groups[21].isVisible = true;
    name[2].groups[22].isVisible = true;
    name[2].groups[19].hasDivider = true;
    name[2].groups[20].hasDivider = true;
    name[2].groups[21].hasDivider = true;
  } else {
    name[2].groups[18].isVisible = false;
    name[2].groups[19].isVisible = false;
    name[2].groups[20].isVisible = false;
    name[2].groups[21].isVisible = false;
    name[2].groups[22].isVisible = false;
    name[2].groups[19].hasDivider = false;
    name[2].groups[20].hasDivider = false;
    name[2].groups[21].hasDivider = false;
  }

  setFormSections(name);

}

const showPolActDltNo5768DescAndExpAmt = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;

  for (let i = 0; i < name[2].groups[9].lineItems.length; i++) {
    if (name[2].groups[9].lineItems[i][1].default === true) {
      name[2].groups[9].lineItems[i][2].isVisible = true
      name[2].groups[9].lineItems[i][3].isVisible = true
    } else {
      name[2].groups[9].lineItems[i][2].isVisible = false
      name[2].groups[9].lineItems[i][3].isVisible = false
    }
  }  

  setFormSections(name);
}

export {
  triggeredEvent
};