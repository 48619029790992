import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { triggeredEvent, sectionFieldLogic } from './unrelatedBusinessIncomeTaxLogicTrigger';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';


export const sections = [
	{
		title: 'Unrelated Business Income Tax',
		isVisible: true,
		sectionId: 1,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				groupType: 'lineItem',
				uploadType: 'drawer',
				class: 'householdEmploymentUpload',
				fields: [
					{ 
						label: 'If you have calculated your unrelated business income, please upload it here:', 
						name: 'ubitActUploadLabel', 
						type: 'label', 
						col: 9, 
					},
					{ type: 'lineButtons', col: 3 }  
				],
				lineItems: [],
				lineItemDetails: {
					headerLabels: [],
					footerLabels: [],
					lineButtons: [
						{ button: 'uploadOnly', uploadSectionName: 'ubitActUpload', viewSectionId: 0 },
					]
				}
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				groupType: 'lineItem',
				uploadType: 'drawer',
				class: 'householdEmploymentUpload',
				fields: [
					{ 
						label: `Please attach any K-1's you have received here:`, 
						name: 'ubitJVattachLabel', 
						type: 'label', 
						col: 9, 
					},
					{ type: 'lineButtons', col: 3 }  
				],
				lineItems: [],
				lineItemDetails: {
					headerLabels: [],
					footerLabels: [],
					lineButtons: [
						{ button: 'uploadOnly', uploadSectionName: 'ubitJVattach', viewSectionId: 0 },
					]
				}
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ 
						label: 'Did the organization have unrelated business gross income of $1,000 or more during the year?', 
						name: 'othFilingsUnrelBusIncLabel', 
						type: 'label', 
						col: 9
					},
					{ 
						label: '', 
						name: 'othFilingsUnrelBusInc', 
						type: 'radio', 
						lookup: 'YESNO_RADIO', 
						col: 3,
					},
				]
			},
			{
				groupId: 4,
				isVisible: true,
				fields: [
					{ 
						label: 'Does the organization have income from any of the following (Check all that apply)?', 
						name: 'textFour', 
						type: 'label', 
						col: 12, 
					}
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ 
						label: 'Advertising', 
						name: 'ubitAdLabel', 
						type: 'label', 
						col: 10, 
						class: 'labelSingleCheckbox' 
					},
					{ 
						label: 'Yes', 
						name: 'ubitAd', 
						type: 'checkbox', 
						col: 2, 
						class: 'singleCheckboxMargin',
						isFieldCheckbox: true
					}
				]
			},
			{
				groupId: 6,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ 
						label: `
							Sponsorships where sponsor receives substantial benefit 
							(e.g., Sponsor receives benefits in return for sponsorship 
							that are valued at greater than 2% of the sponsorship payment. 
							Exclusive provider arrangements, goods, facilities, services or other privileges)`, 
						name: 'ubitSponsorLabel', 
						type: 'label', 
						col: 10, 
						class: 'labelSingleCheckbox' 
					},
					{ 
						label: 'Yes', 
						name: 'ubitSponsor', 
						type: 'checkbox', 
						col: 2, 
						class: 'singleCheckboxMargin',
						isFieldCheckbox: true
					}
				]
			},
			{
				groupId: 7,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ 
						label: 'Rental of personal property', 
						name: 'ubitPersPropLabel', 
						type: 'label', 
						col: 10, 
						class: 'labelSingleCheckbox' 
					},
					{ 
						label: 'Yes', 
						name: 'ubitPersProp', 
						type: 'checkbox', 
						col: 2, 
						class: 'singleCheckboxMargin',
						isFieldCheckbox: true
					}
				]
			},
			{
				groupId: 8,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ 
						label: `
							Rental of real property where property is debt-financed or from a Controlled Organization 
							(e.g., Debt-financing includes purchasing on margin, mortgages or acquisition indebtedness)
						`, 
						name: 'ubitRealPropLabel', 
						type: 'label', 
						col: 10, 
						class: 'labelSingleCheckbox' 
					},
					{ 
						label: 'Yes', 
						name: 'ubitRealProp', 
						type: 'checkbox', 
						col: 2, 
						class: 'singleCheckboxMargin',
						isFieldCheckbox: true
					}
				]
			},
			{
				groupId: 9,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ 
						label: `
							Controlled organizations 
							(e.g., any organization that you hold more than 50% of the value or voting power)
						`, 
						name: 'ubitControlOrgLabel', 
						type: 'label', 
						col: 10, 
						class: 'labelSingleCheckbox' 
					},
					{ 
						label: 'Yes', 
						name: 'ubitControlOrg', 
						type: 'checkbox', 
						col: 2, 
						class: 'singleCheckboxMargin',
						isFieldCheckbox: true
					}
				]
			},
			{
				groupId: 10,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ 
						label: `
							Royalties (Consider the sale of mailing lists, use of intellectual property, 
							insurance, travel, "pouring rights", logo merchandise, credit cards, etc.)	
						`, 
						name: 'ubitRoyalLabel', 
						type: 'label', 
						col: 10, 
						class: 'labelSingleCheckbox' 
					},
					{ 
						label: 'Yes', 
						name: 'ubitRoyal', 
						type: 'checkbox', 
						col: 2, 
						class: 'singleCheckboxMargin',
						isFieldCheckbox: true
					}
				]
			},
			{
				groupId: 11,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ 
						label: `
							Sale of merchandise (Consider gift shops, pro shops, catalogs, websites, door-to-door sales, etc.)		
						`, 
						name: 'ubitMerchLabel', 
						type: 'label', 
						col: 10, 
						class: 'labelSingleCheckbox' 
					},
					{ 
						label: 'Yes', 
						name: 'ubitMerch', 
						type: 'checkbox', 
						col: 2, 
						class: 'singleCheckboxMargin',
						isFieldCheckbox: true
					}
				]
			},
			{
				groupId: 12,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ 
						label: `
							Joint ventures or partnerships (including LLC's and S Corporations) with for-profit entities?			
						`, 
						name: 'ubitJVLabel', 
						type: 'label', 
						col: 10, 
						class: 'labelSingleCheckbox' 
					},
					{ 
						label: 'Yes', 
						name: 'ubitJV', 
						type: 'checkbox', 
						col: 2, 
						class: 'singleCheckboxMargin',
						isFieldCheckbox: true
					}
				]
			},
			{
				groupId: 13,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ 
						label: `
							Provision of services to individuals outside of your charitable class 
							(general public) (e.g., services to non-patients, non-members, non-students, etc.)				
						`, 
						name: 'ubitProvServLabel', 
						type: 'label', 
						col: 10, 
						class: 'labelSingleCheckbox' 
					},
					{ 
						label: 'Yes', 
						name: 'ubitProvServ', 
						type: 'checkbox', 
						col: 2, 
						class: 'singleCheckboxMargin',
						isFieldCheckbox: true
					}
				]
			},
			{
				groupId: 14,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ 
						label: 'Other activity that may be unrelated business activity', 
						name: 'ubitOtherActivitiesLabel', 
						type: 'label', 
						col: 10, 
						class: 'labelSingleCheckbox' 
					},
					{ 
						label: 'Yes', 
						name: 'ubitOtherActivities', 
						type: 'checkbox', 
						col: 2, 
						class: 'singleCheckboxMargin',
						isFieldCheckbox: true,
						logicFunction:[
							{ trigger: 'showUbitOtherActDesc', isDifferentGroup: true },
						]
					}
				]
			},
			{
				groupId: 15,
				isVisible: false,
				fields: [
					{ 
						label: 'Please describe any other activities:', 
						name: 'ubitOtherActDescLabel', 
						type: 'label', 
						class: 'labelTitleTextArea',
						col: 12 
					},
					{ 
						label: 'Please explain', 
						placeholder: 'Please explain',
						name: 'ubitOtherActDesc', 
						type: 'textarea', 
						maxRows: 3,
						class: 'fieldTextArea',
						maxLength: 9000,
						col: 12 
					},
				]
			},
		]
	},
	{  
    title: 'Additional Information',
    collapseClass: 'collapsibleForm',
    isVisible: true,
    isDefaultExpanded: false,
		isOptionalSectionMerge: true,
    sectionId: 2,
    groups: [
      {
        groupId: 2,
        groupType: 'details',
        fields: [
          { 
            label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
            type: 'label', 
            class: 'labelLeftLight' 
          },
        ]
      },
      {
        groupId: 1,
        fields: [
          {
            label: 'Start typing to provide additional information', 
            name: 'additionalInfoTextArea', 
            type: 'textarea', 
            col: 12,
						maxLength: 9000
          }
        ]
      }
    ]
  },
];

export const getUnrelatedBusinessIncomeTaxTitles = () => {
	let titles = [];
	sections.forEach((section) => {
		if (!titles.includes(section.title)) {
			titles.push(section.title);
		}
	});
	return titles;
};

function UnrelatedBusinessIncomeTax(props) {
	const { isExportForm } = props;
	const { updateCard, REDUX } = usePageFramework(NAV.UNRELATED_BUSINESS_INCOME_TAX);
	const [sectionState, setSectionState] = useState('');
	const {isActiveClient, isLoading} = useLoadOrganizer();

	useEffect(() => {
		if (!isExportForm) updateCard();

		setSectionState(sections);
		// eslint-disable-next-line	
	}, []);

	return (
		<>
			{!isLoading && isActiveClient && <FormRendererHelper
				sections={sectionState}
				key={REDUX.UNRELATED_BUSINESS_INCOME_TAX}
				formName={REDUX.UNRELATED_BUSINESS_INCOME_TAX}
				triggeredEvent={triggeredEvent}
				sectionFieldLogic={sectionFieldLogic}
				isExportForm={isExportForm}
			/>}
			{!isLoading && !isActiveClient && <NoActiveClient />}
		</>
	);
}

export default UnrelatedBusinessIncomeTax;