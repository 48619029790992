import { getPullFieldValues, matchToLookupOptions, translatePullData, getPYCalculationFunction } from '@utilities/axcessTaxPull';
import * as INPUT_STATE from '@utilities/constants/inputState';
import * as FIELD_TYPE from '@utilities/constants/fieldType';
import _ from 'lodash';

const addMultiSelectValue = (field, value) => {
	if (!field.priorYearValue.some((i) => i.value === value.value)) {
		field.priorYearValue.push(value);
	}
};

const setFieldValue = (field, value, shouldConcatenate = false, separator) => {
	const {
		type,
		overRideText,
		priorCompareOptions
	} = field;

	let lookupValue;
	if (field.lookup) {
		// match to lookup
		lookupValue = matchToLookupOptions(
			value,
			field.lookup,
			priorCompareOptions,
			type
		);
	}

	switch (type) {
		case FIELD_TYPE.SELECT:
		case FIELD_TYPE.RADIO:
			if (field.isFiscalYear) {
				field.priorYearValue = value ? value : field.default
			} else {
				field.priorYearValue = lookupValue;
			}
			break;
		case FIELD_TYPE.MULTISELECT:
			if (lookupValue) {
				field.priorYearValue && shouldConcatenate
					? addMultiSelectValue(field, lookupValue)
					: field.priorYearValue = [lookupValue];
			}

			break;
		case FIELD_TYPE.LABEL:
			field.label = overRideText ? overRideText : value;
			field.priorYearValue = overRideText ? overRideText : value;
			break;
		case FIELD_TYPE.CHECKBOX:
			if (value === true) {
				field.priorYearValue = value
			}
			break;
		default:
			field.priorYearValue && shouldConcatenate && separator
				? field.priorYearValue += `${separator}${value}`
				: field.priorYearValue = value;
	}

	field.default = field.priorYearValue;
	field.inputState = INPUT_STATE.PRIOR_INPUT;
};

const triggerFieldFn = (field, fieldTrigger) => {
	const { triggered, default: fieldValue } = field;

	if (fieldTrigger) {
		fieldTrigger(triggered, fieldValue);
	}
};

const populateFields = (priorYearData, fieldTrigger) => (field) => {
	const {
		prior,
		name,
		default: fieldValue,
		inputState,
		axcess,
		overRideText,
		axcessTranslate
	} = field;

	const pullMultiple = axcess?.pull?.populateMultiple;
	const separator = axcess?.pull?.separator;

	if (!prior || !name) return; // Exit if not marked to pull from prior or no name
	if (!pullMultiple && fieldValue && (inputState & INPUT_STATE.PRIOR_INPUT)) return; // Exit if field is already populated

	const returnSelf = ([x]) => x;
	let axcessFieldKeys = axcess?.pull?.fields || [name];

	let pullFn = _.isFunction(axcess?.pull?.fn)
		? axcess?.pull?.fn
		: _.isString(axcess?.pull?.fn)
			? getPYCalculationFunction(axcess?.pull?.fn)
			: returnSelf;

	let pullDefault = axcess?.pull?.default || null;
	let pullData = axcess?.pull?.data || null;

	const axcessFieldValue = pullFn(axcessFieldKeys.map(getPullFieldValues(priorYearData)), pullData) || pullDefault;

	if (axcess?.pull && !axcessFieldValue) return; // Exit if no Complex Pull and no axcessValue

	const translatedValue = overRideText ? overRideText : translatePullData(axcessTranslate, axcessFieldValue);

	if (!translatedValue && _.isNil(axcessFieldValue)) return; // Exit if no translatedValue && no value from AxcessTax
	if (translatedValue === overRideText && _.isNil(axcessFieldValue)) return; // Exit if overriding value and no value from axcessTax

	setFieldValue(field, translatedValue, pullMultiple, separator);

	triggerFieldFn(field, fieldTrigger);
};

export default populateFields;

export {
	// For testing purpose
	setFieldValue,
	triggerFieldFn,
};