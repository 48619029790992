const showFsRptAcctMthdOthPY = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  
  if (event === 'Other') {
    name[0].groups[1].isVisible = true;
  } else {
    name[0].groups[1].isVisible = false;
  }
  
  setFormSections(name);
}

const showFsRptAcctRevBasis = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  
  if (event === 'Yes') {
    name[0].groups[3].isVisible = true;
  } else {
    name[0].groups[3].isVisible = false;
  }
  
  setFormSections(name);
}

const showFsRptAcctAudBasis = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  
  if (event === 'Yes') {
    name[0].groups[5].isVisible = true;
  } else {
    name[0].groups[5].isVisible = false;
  }
  
  setFormSections(name);
}

const showFsRptAcctChgOverProc = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  
  if (event === 'Yes') {
    name[0].groups[7].isVisible = true;
  } else {
    name[0].groups[7].isVisible = false;
  }

  if (event === 'Yes' && name[0].groups[7].fields[1].default === 'Yes') {
    name[0].groups[8].isVisible = true;
  } else {
    name[0].groups[8].isVisible = false;
  }
  
  setFormSections(name);
}

const showFsRptAcctChgOverDesc = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  
  if (event === 'Yes') {
    name[0].groups[8].isVisible = true;
  } else {
    name[0].groups[8].isVisible = false;
  }
  
  setFormSections(name);
}

const showFsRptAcctFedReqAudMiss = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  
  if (event === 'Yes') {
    name[0].groups[10].isVisible = true;
  } else {
    name[0].groups[10].isVisible = false;
  }

  if (event === 'Yes' && name[0].groups[10].fields[1].default === 'No') {
    name[0].groups[11].isVisible = true;
  } else {
    name[0].groups[11].isVisible = false;
  }
  
  setFormSections(name);
}

const showFsRptAcctFedReqAudMissExp = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  
  if (event === 'No') {
    name[0].groups[11].isVisible = true;
  } else {
    name[0].groups[11].isVisible = false;
  }
  
  setFormSections(name);
}

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
    case 'showFsRptAcctMthdOthPY': 
      showFsRptAcctMthdOthPY(event, options);
      break;
    case 'showFsRptAcctRevBasis': 
      showFsRptAcctRevBasis(event, options);
      break;
    case 'showFsRptAcctAudBasis': 
      showFsRptAcctAudBasis(event, options);
      break;
    case 'showFsRptAcctChgOverProc': 
      showFsRptAcctChgOverProc(event, options);
      break;
    case 'showFsRptAcctChgOverDesc': 
      showFsRptAcctChgOverDesc(event, options);
      break;
    case 'showFsRptAcctFedReqAudMiss': 
      showFsRptAcctFedReqAudMiss(event, options);
      break;
    case 'showFsRptAcctFedReqAudMissExp': 
      showFsRptAcctFedReqAudMissExp(event, options);
      break;
    default:
        break;
    }
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        default:
            break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};