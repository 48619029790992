import { useState } from 'react';
import { frameworkSetter } from '../../hooks/usePageFramework';
import useAdaptive from '../../hooks/useAdaptive';

function BookmarkMenu(props) {
    const { sections, setFormSections, passFromEntity, setBookmarkTitle } = props;
    const visibleSections = sections?.length ? sections.filter(section => section.isVisible || section.isVisible === undefined) : [];
    const [ currentSectionIndex, setCurrentSectionIndex ] = useState(0);

    const { isLaptopOrDesktop } = useAdaptive();
    const { REDUX, selectState } = frameworkSetter.usePageFramework();
    const isFormNA = selectState(REDUX.IS_CURRENT_FORM_NA);

    if (passFromEntity == true){
        return null;
    }

    if (visibleSections && visibleSections.length > 1) {
        const changeSection = (section, index) => {
            section.isExpanded = true;
            setFormSections(sections);
            setCurrentSectionIndex(index);
            setBookmarkTitle(section.title);
        };

        const filteredSections = visibleSections?.filter(section => !section.isSubSection);
        
        const sectionItems = filteredSections.map((section, index) => {
            
            if (!section.isVisible) return null;
            
            return (
                <a key={`a-${index}`} className={currentSectionIndex == index ? 'selectedFormBookmarkMenuItem' : 'formBookmarkMenuItem'}
                    onClick={!isFormNA ? () => changeSection(section, index) : null}
                    data-testid={`currentSection-${index}`}
                    href={`#${section.title}`}>
                    {(section.shortTitle == null) ? section.title : section.shortTitle}
                </a>
            );
        });
    
        const sectionTabs = (isLaptopOrDesktop) && (
            <div className="formTabSection">
                <div className="formBookmarkMenu"> 
                    {sectionItems}
                </div>
            </div>
        );

        return (
            <>  
                {sectionTabs}
            </>
        );
    }
    else {
        return null;
    }
    
}

export default BookmarkMenu;