import * as EVENT from '@utilities/constants/triggerKeys';
import * as REDUX from '@utilities/constants/redux';
import { getEntrySections } from './index';

const setCardRequired = (options) => {
    const { field, logic } = options;
    const internalSetupForm = logic.triggerRelatedForms?.internalSetupForm;
    let isRequiredSupp = false;
    if(internalSetupForm){
        if(internalSetupForm?.[0]?.groups?.[12]?.fields?.[1]?.value === EVENT.KEY_AUDIT
        && internalSetupForm?.[0]?.groups?.[13]?.fields?.[1]?.value === EVENT.KEY_NO){
            isRequiredSupp =  true;
        }
    }
    if (field.default === EVENT.KEY_YES || isRequiredSupp) {
        field.requiredCardsToSet = logic.requiredCards;
        field.isSetRequiredCards = true;
    } else {
        field.requiredCardsToSet = logic.requiredCards;
        field.isSetRequiredCards = false;
    }
};

const showContributionsSection = (options) => {
    const { field, logic } = options;
    const contributionsForm = logic.triggerRelatedForms?.contributions;

    if (contributionsForm) {
        contributionsForm[0].isVisible = field.default === EVENT.KEY_YES;
        if (contributionsForm.length === 5) {
            contributionsForm[4].isVisible = contributionsForm[0].isVisible;
        }
        logic.setGlobalFormState(REDUX.CONTRIBUTIONS, contributionsForm);
    }  
};

const showBDgovDocAtch = (options) => {
    const { field, logic } = options;
    const basicDataForm = logic.triggerRelatedForms?.basicData;

    if (basicDataForm) {
        basicDataForm[3].groups[0].isVisible = field.default === EVENT.KEY_YES;
        basicDataForm[3].groups[1].isVisible = field.default === EVENT.KEY_YES;

        logic.setGlobalFormState(REDUX.BASIC_DATA, basicDataForm);
    }
};

const showBDltrDetermineAtt = (options) => {
    const { field, logic } = options;
    const basicDataForm = logic.triggerRelatedForms?.basicData;

    if (basicDataForm) {
        basicDataForm[3].groups[2].isVisible = field.default === EVENT.KEY_NO;

        logic.setGlobalFormState(REDUX.BASIC_DATA, basicDataForm);
    }
};

const showSupFinDonorAdvised = (options) => {
    const { field, logic } = options;
    const supFinStmntsForm = logic.triggerRelatedForms?.supplementalFinancialStatements;
    const sections = getEntrySections();

    if (supFinStmntsForm) {
        supFinStmntsForm[0].isVisible = field.default === EVENT.KEY_YES;
        if (supFinStmntsForm.length === 6) {
            supFinStmntsForm[5].isVisible = supFinStmntsForm[0].isVisible;
        }
        logic.setGlobalFormState(REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS, supFinStmntsForm);
        sections[0].groups[4].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[0].groups[5].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[0].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[1].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[2].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
    }  
};

const showConservativeEasements = (options) => {
    const { field, logic } = options;
    const supFinStmntsForm = logic.triggerRelatedForms?.supplementalFinancialStatements;
    const sections = getEntrySections();

    if (supFinStmntsForm) {
        supFinStmntsForm[1].isVisible = field.default === EVENT.KEY_YES;
        if (supFinStmntsForm.length === 6) {
            supFinStmntsForm[5].isVisible = supFinStmntsForm[1].isVisible;
        }
        logic.setGlobalFormState(REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS, supFinStmntsForm);
        sections[0].groups[4].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[0].groups[5].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[0].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[1].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[2].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
    }  
};

const showArtHistoricalTreasures = (options) => {
    const { field, logic } = options;
    const supFinStmntsForm = logic.triggerRelatedForms?.supplementalFinancialStatements;
    const sections = getEntrySections();

    if (supFinStmntsForm) {
        supFinStmntsForm[2].isVisible = field.default === EVENT.KEY_YES;
        if (supFinStmntsForm.length === 6) {
            supFinStmntsForm[5].isVisible = supFinStmntsForm[2].isVisible;
        }
        logic.setGlobalFormState(REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS, supFinStmntsForm);
        sections[0].groups[4].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[0].groups[5].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[0].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[1].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[2].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
    }  
};

const showEscrowCustodialArrangements = (options) => {
    const { field, logic } = options;
    const supFinStmntsForm = logic.triggerRelatedForms?.supplementalFinancialStatements;
    const sections = getEntrySections();

    if (supFinStmntsForm) {
        supFinStmntsForm[3].isVisible = field.default === EVENT.KEY_YES;
        if (supFinStmntsForm.length === 6) {
            supFinStmntsForm[5].isVisible = supFinStmntsForm[3].isVisible;
        }
        logic.setGlobalFormState(REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS, supFinStmntsForm);
        sections[0].groups[4].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[0].groups[5].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[0].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[1].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[2].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
    }  
};

const showEndowmentsSection = (options) => {
    const { field, logic } = options;
    const supFinStmntsForm = logic.triggerRelatedForms?.supplementalFinancialStatements;
    const sections = getEntrySections();

    if (supFinStmntsForm) {
        supFinStmntsForm[4].isVisible = field.default === EVENT.KEY_YES;
        if (supFinStmntsForm.length === 6) {
            supFinStmntsForm[5].isVisible = supFinStmntsForm[4].isVisible;
        }
        logic.setGlobalFormState(REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS, supFinStmntsForm);
        sections[0].groups[4].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[0].groups[5].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[0].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[1].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
        sections[1].groups[2].fields[1].logicFunction[1].triggerRelatedForms.supplementalFinancialStatements = supFinStmntsForm;
    }  
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        case 'setCardRequired':
            setCardRequired(options);
            break;
        case 'showContributionsSection':
            showContributionsSection(options);
            break;
        case 'showBDgovDocAtch':
            showBDgovDocAtch(options);
            break;
        case 'showBDltrDetermineAtt':
            showBDltrDetermineAtt(options);
            break;
        case 'showSupFinDonorAdvised':
            showSupFinDonorAdvised(options);
            break;
        case 'showConservativeEasements':
            showConservativeEasements(options);
            break;
        case 'showArtHistoricalTreasures':
            showArtHistoricalTreasures(options);
            break;
        case 'showEscrowCustodialArrangements':
            showEscrowCustodialArrangements(options);
            break;
        case 'showEndowmentsSection':
            showEndowmentsSection(options);
            break;
        default:
            break;
    }
};

const setSchedDRequired = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const entryQuestion1 = name[0].groups[4].fields[1];
    const entryQuestion2 = name[0].groups[5].fields[1];
    const entryQuestion3 = name[1].groups[0].fields[1];
    const entryQuestion4 = name[1].groups[1].fields[1];
    const entryQuestion5 = name[1].groups[2].fields[1];

    if ( entryQuestion1.default === EVENT.KEY_YES || entryQuestion2.default === EVENT.KEY_YES || entryQuestion3.default === EVENT.KEY_YES ||
        entryQuestion4.default === EVENT.KEY_YES || (entryQuestion5.isVisible && entryQuestion5.default === EVENT.KEY_YES) ) {        
            entryQuestion1.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
            entryQuestion1.isSetRequiredCards = true;
            entryQuestion2.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
            entryQuestion2.isSetRequiredCards = true;
            entryQuestion3.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
            entryQuestion3.isSetRequiredCards = true;
            entryQuestion4.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
            entryQuestion4.isSetRequiredCards = true;
            entryQuestion5.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
            entryQuestion5.isSetRequiredCards = true;
    } else {
        entryQuestion1.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
        entryQuestion1.isSetRequiredCards = false;
        entryQuestion2.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
        entryQuestion2.isSetRequiredCards = false;
        entryQuestion3.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
        entryQuestion3.isSetRequiredCards = false;
        entryQuestion4.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
        entryQuestion4.isSetRequiredCards = false;
        entryQuestion5.requiredCardsToSet = [ REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS ];
        entryQuestion5.isSetRequiredCards = false;
    }

    setFormSections(name);
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'setSchedDRequired':
            setSchedDRequired(event, options);
            break;
        default:
        break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};