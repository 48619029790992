// common modules
import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import {
	TableContainer,
	Table,
	TableHeader,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	DropdownMenu,
	MenuItem
} from 'react-md';
import ReactPaginate from 'react-paginate';
import { Button } from "@react-md/button";
import { DeleteSVGIcon, EditSVGIcon, AddSVGIcon } from "@react-md/material-icons";
import { useToggle } from "@react-md/utils";
import {
	CircularProgress
} from "@react-md/progress";
import moment from 'moment';
import { useHistory } from "react-router-dom";

// custom modules
import '@components/restfulTable/RestfulTable.css';
import api from '@utilities/claApi';
import CreateResourceDialog from '@components/restfulTable/CreateResourceDialog.js';
import DeleteResourceDialog from '@components/restfulTable/DeleteResourceDialog.js';
import EditResourceDialog from '@components/restfulTable/EditResourceDialog.js';
import * as IMG from '@utilities/constants/images.js';
import CheckPYData from '@components/exchangeManager/CheckPriorYearData';

function RestfulTable(props) {
	const {
		resourceName,
		resourceUri,
		columns,
		defaultSortKey,
		defaultSortOrder = 'ascending',
		disableCreate = false,
		actions,
		createOverRide,
		editOverRide,
		allowRowClick,
		disableDelete,
		disableEdit,
		reloadTable,
		showPriorYearData,
		filterFn
	} = props;
	const [isLoaded, enable, disable] = useToggle(false);
	const [showCreateDialog, enableCreateDialog, disableCreateDialog] = useToggle(false);
	const [showDeleteDialog, enableDeleteDialog, disableDeleteDialog] = useToggle(false);
	const [showEditDialog, enableEditDialog, disableEditDialog] = useToggle(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [currentPageData, setCurrentPageData] = useState([]);
	const [selected, setSelected] = useState({});
	const [masterData, setMasterData] = useState([]);
	const [state, setState] = useState({
		data: [],
		sortKey: defaultSortKey ? defaultSortKey : columns[0].key,
		sortOrder: defaultSortOrder
	});
	const [searchValue, setSearchValue] = useState('')
	const history = useHistory();
	const pageSize = 10;

	const { data, sortKey, sortOrder } = state;
	const pageCount = Math.ceil(data.length / pageSize);

	const update = (sortKey) => {
		setState((prevState) => {
			const prevSortKey = prevState.sortKey;
			const prevSortOrder = prevState.sortOrder;

			let sortOrder;
			if (sortKey === prevSortKey) {
				// it's the same column, so toggle the sort order
				sortOrder = prevSortOrder === "ascending" ? "descending" : "ascending";
			} else {
				// it's a new column to sort by, so default to ascending for the name column
				// but descending for all the rest.
				sortOrder = sortKey === "name" ? "ascending" : "descending";
			}

			const translatedSortOrder = sortOrder === 'ascending' ? 'asc' : 'desc';

			const sorted = _.orderBy(data, sortKey, translatedSortOrder);

			return {
				data: sorted,
				sortKey,
				sortOrder
			};
		});
	};

	const handlePageClick = ({ selected: selectedPage }) => {
		setCurrentPage(selectedPage);
	};

	const getResourceSet = async (resourceUri, limit, offset) => {
		return api.get(`${resourceUri}?limit=${limit}&offset=${offset}`)
			.then(response => {
				return response.data;
			});
	};

	const getResources = useCallback(async () => {
		disable();

		const limit = 250;
		let offset = 0;
		const data = [];

		getResourceSet(resourceUri, limit, offset).then(async (response) => {
			const { results, total } = response;
			resourceUri === 'groups' ? data.push(...response) : data.push(...results);

			while (offset < total) {
				offset += limit;
				const nextPageResults = await getResourceSet(resourceUri, limit, offset);
				data.push(...nextPageResults.results);
			}

			const sorted = _.orderBy(data, sortKey, sortOrder === 'ascending' ? 'asc' : 'desc');

			setState({
				data: sorted,
				sortKey,
				sortOrder
			});
			setMasterData(sorted);
			enable();
		}).catch((error) => {
			console.error(error);
			enable();
		});
	}, [disable, enable, resourceUri, sortKey, sortOrder]);

	useEffect(() => {
		if (!isLoaded) {
			// load resources from API if page has not already loaded
			getResources();
		}

		const offset = currentPage * pageSize;
		setCurrentPageData(data.slice(offset, offset + pageSize));

	}, [data, currentPage, isLoaded, pageSize, getResources]);

	useEffect(() => {
		disable();
		// eslint-disable-next-line
	}, [reloadTable]);

	// create resource
	const createResource = async (resource) => {
		api.post(resourceUri, resource).then((response) => {
			// hide create dialog
			disableCreateDialog();

			// update resource list
			disable();
		}).catch((error) => {
			console.error(error);
		});
	};

	// update resource
	const updateResource = async (id, update) => {
		if (!update.submitted) {
			update.status = 'New';
		}
		delete update.id;
		api.put(`${resourceUri}/${id}`, update).then((response) => {
			// hide create dialog
			disableEditDialog();

			// update resource list
			disable();
		}).catch((error) => {
			console.error(error);
		})
	};

	// delete resource
	const deleteResource = async (id) => {
		api.delete(`${resourceUri}/${id}`).then((response) => {
			// hide delete dialog
			disableDeleteDialog();

			// update resource list
			disable();
		}).catch((error) => {
			console.error(error);
		});
	};

	const getCellValue = (col, object) => {
		const { key, type, format = 'MM/DD/YY' } = col;
		// split the key
		let cellKey = key.split(".");

		let value = object;

		for (let index = 0; index < cellKey.length; index++) {
			const keyFrag = cellKey[index];

			if (value[keyFrag] || typeof value[keyFrag] === 'boolean') {
				value = value[keyFrag];
			} else {
				return '';
			}
		}

		switch (type) {
			case 'boolean':
				return value.toString();
			case 'date':
				return moment(new Date(value)).format(format);
			default:
				return value;
		}

	};

	const handleRowClicked = (id) => {
		if (allowRowClick) {
			history.push(`/admin/${resourceUri}/${id}`);
		}
	};

	function searchUpdate(e) {
		setSearchValue(e?.target?.value?.toLowerCase());
	};

	useEffect(() => {
		let filteredData = _.cloneDeep(masterData);
		
		// filter data based on search value
		if (filterFn) {
			filteredData = filteredData.filter(filterFn(searchValue));
		}

		setState({
			data: filteredData,
			sortKey,
			sortOrder
		});
	}, [searchValue, masterData, filterFn]);


	return (
		<div>
			<input onChange={(e) => searchUpdate(e)} placeholder="Search name" />
			<CreateResourceDialog visible={showCreateDialog} resourceName={resourceName} resourceProps={columns} onCancel={disableCreateDialog} onConfirm={createResource}>
			</CreateResourceDialog>
			<DeleteResourceDialog visible={showDeleteDialog} resourceName={resourceName} resourceProps={columns} onCancel={disableDeleteDialog} onConfirm={() => deleteResource(selected.id)}>
			</DeleteResourceDialog>
			<EditResourceDialog visible={showEditDialog} selected={selected} resourceName={resourceName} resourceProps={columns} onCancel={disableEditDialog} onConfirm={updateResource}>
			</EditResourceDialog>
			<TableContainer>
				<Table fullWidth={true}>
					<TableHeader className="restful-table-header-bar">
						<TableRow style={{ 'borderBottom': '#1e2133' }}>
							{columns.map((col) => (
								<TableCell
									key={col.key}
									aria-sort={col.key === sortKey ? sortOrder : 'none'}
									onClick={() => update(col.key)}
									className={col.key === sortKey ? 'restful-table-header-selected' : 'restful-table-header'}
									sortIconAfter={true}
									sortIcon={<img width={9} alt="White expand icon" src={IMG.WHITE_EXPANDED_ICON}></img>}
									header={true}>
									{col.text}
								</TableCell>
							))}
								{showPriorYearData && <TableCell
									className='restful-table-header'
									header={true}>
									PY Data Available
								</TableCell>}
							<TableCell className="restful-table-header action-header-cell restful-table-cell-actions" header={true}>
								<Button
									data-testid="create-resource-button"
									id="create-resource-button"
									onClick={() => createOverRide ? createOverRide(getResources) : enableCreateDialog()} disabled={disableCreate}>
									<AddSVGIcon />
								</Button>
							</TableCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{!isLoaded ?
							<TableRow>
								<TableCell colSpan="100%">
									<CircularProgress id="loading-progress" />
								</TableCell>
							</TableRow> : currentPageData.length > 0 ? currentPageData.map((resource, i) => {
								return (
									<TableRow key={`row-${i}`} clickable>
										{columns.map((col) => (
											<TableCell
												key={col.key}
												className="restful-table-cell restful-table-cell-text"
												onClick={() => handleRowClicked(resource.id)} >
												{getCellValue(col, resource)}
											</TableCell>
											
										))}

									{showPriorYearData && <TableCell className='restful-table-cell restful-table-cell-text'>
										<CheckPYData organizer={resource} />
									</TableCell>}
										<TableCell className="restful-table-cell restful-table-cell-text restful-table-cell-actions" hAlign="center">
											<Button
												id={`delete-button-${resource.id}`}
												buttonType="icon"
												theme="warning"
												aria-label="Delete resource"
												disabled={disableDelete}
												onClick={() => { setSelected(resource); enableDeleteDialog(); }}
											>
												<DeleteSVGIcon />
											</Button>
											<Button
												id={`edit-button-${resource.id}`}
												buttonType="icon"
												theme="primary"
												aria-label="Edit resource"
												disabled={disableEdit}
												onClick={() => {
													if (editOverRide) {
														editOverRide(resource);
													} else {
														setSelected(resource);
														enableEditDialog();
													}
												}}
											>
												<EditSVGIcon />
											</Button>
											{actions ?
												<DropdownMenu
													id={`action-menu-${i}`}
													key={`action-menu-${i}`}
													aria-label="Actions...">
													{actions.map((action, i) => {
														return (
															<MenuItem key={`organizer-action-menu-item-${i}`} onClick={() => action.onClick(resource)}>{action.label}</MenuItem>
														)
													})}
												</DropdownMenu> : <></>}
										</TableCell>
									</TableRow>
								);
							}) : <TableRow><TableCell colSpan="100%">{`No ${resourceName} found...`}</TableCell></TableRow>}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell colSpan={'100%'} hAlign="right">
								<div className="pracDashboardSize">
									<ReactPaginate
										previousLabel={'<'}
										nextLabel={'>'}
										pageCount={pageCount}
										marginPagesDisplayed={pageSize}
										pageRangeDisplayed={10}
										onPageChange={handlePageClick}
										containerClassName={'pagination'}
										activeClassName={'active'}
									/>
								</div>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div >
	);
}

export default RestfulTable;