import {
    Button
} from 'react-md';

const PrevNextButton = ({ setCurrentIndex, stepIndex, handleQuestionnaireClick,
    setStepCompleteStatus, stepCompleteStatus
}) => {

    const handleClick = (page) => {
        if (page === 'prev') setCurrentIndex(prevState =>
            prevState === 0 ? 1 : prevState - 1);
        else if (page === 'next') {
            setCurrentIndex(prevState => {
                if (prevState > stepCompleteStatus) {
                    setStepCompleteStatus(prevState)
                }
                return prevState === 3 ? prevState : prevState + 1
                
             } )
        }

        else if (page === 'goToDashboard') handleQuestionnaireClick();
    };

    const renderButton = (isDisabled) => {
        return (
            <><div>
                <Button
                    data-testid="entryBackButtonId"
                    themeType="outline"
                    className="entryBackButton"
                    onClick={() => handleClick('prev')}
                    disabled={isDisabled}>
                    Back
                </Button>
            </div>
                <div>
                    <Button
                        data-testid="entryNextButtonId"
                        themeType="contained"
                        className="entryNextButton"
                        onClick={
                            stepIndex === 2 ? () => handleClick('goToDashboard') :
                                () => handleClick('next')
                        }
                    >
                        Next
                    </Button>
                </div></>
        );
    };

    let entrySideButtons = null;

    switch (stepIndex) {
        case 0:
            entrySideButtons = renderButton(true);
            break;
        case 1:
            entrySideButtons = renderButton(false);
            break;
        case 2:
            entrySideButtons = renderButton(false);
            break;
        default:
            entrySideButtons = null;
            break;
    }
    return (
        <div className="entryExperienceGridPrevNextContainer">
            {entrySideButtons}
        </div>
    );
};

export default PrevNextButton;