// common modules
import { useState, useEffect } from "react";

// custom modules
import api from '@utilities/claApi';

export const checkPYData = (organizer) => {
    const { id: organizerId } = organizer;

    if (!organizerId) {
        return;
    };
    
    return api.head(`/organizers/${organizerId}/prior`).then((response) => {
        if (response.status === 200) {
            return 'Yes';
        }

        return 'No';
    }).catch((err) => {
        return 'No';
    });

};

const CheckPYData = (props) => {
    const { organizer } = props;
    const [hasPriorYearData, setHasPriorYearData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        checkPYData(organizer).then((hasPYData) => {
            setHasPriorYearData(hasPYData);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        })
        
    }, [organizer])

    if (isLoading) {
        return (
            <div>
                loading...
            </div>
        )
    }
        
    return (
        <div>
            {hasPriorYearData}
        </div>
    );  

};

export default CheckPYData