// common modules
import {
	TextIconSpacing,
	FontIcon,
} from '@react-md/icon';
import { useState, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { useIntercom } from 'react-use-intercom';

// custom modules
import './topBar.css';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { authenticated, injectLocalDevelopmentTokens } from '@utilities/authentication.js';
import { scopes } from '@utilities/claApi.js';
import GlosaryModal from '../../glossary/GlossaryModal';

function TopBar() {
	const fixedTo = useRef();
	const { instance } = useMsal();
	const { shutdown } = useIntercom();
	const isAuthenticated = authenticated();
	const { selectState, REDUX } = usePageFramework();
	const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
	const isAdmin = selectState(REDUX.IS_ADMIN);
	const [dialogVisible, setDialog] = useState(false);
	const handleSignin = (e) => {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			// inject development tokens into cache then refresh entire page
			injectLocalDevelopmentTokens();
			window.location.reload();
		} else {
			// redirect to MSAL login
			instance.loginRedirect({
				scopes: scopes,
			});
		}
	}
	const handleSignout = (e) => {
		// GROWTH: Handle for automatic sign outs
		shutdown();
		
		instance.logoutRedirect({
			postLogoutRedirectUri: '/'
		});
	}
	const showGlossaryDialog = (e) => {
		setDialog(true);
	}

	return (
		<div className="notification-bar">
			<div className="appBarHelpText">
				{/* TODO: Temporarily hiding until functionality is built */}

				<ul>

					{/* <li>
                        <TextIconSpacing 
                            icon={
                                <FontIcon className="appBarFontIconColor">
                                    comment
                                </FontIcon>
                            }
                        >
                                Ask CLA
                        </TextIconSpacing>
                    </li> */}
					{isAdmin || isPractitioner ? <li onClick={() => window.open(' https://apps.powerapps.com/play/e/default-4aaa468e-93ba-4ee3-ab9f-6a247aa3ade0/a/1866e626-f032-4a7e-894f-785115e07547?tenantId=4aaa468e-93ba-4ee3-ab9f-6a247aa3ade0&hint=20d41626-9c16-4ca1-97e7-b48769125f84&sourcetime=1697211395003', '_blank')}>
								<TextIconSpacing>Feedback</TextIconSpacing>
						</li>: null}
					{isAuthenticated ?
						<li onClick={showGlossaryDialog} ref={fixedTo} data-testid="enableglossaryDialogId">
							<TextIconSpacing
								// icon={
								// 	<FontIcon className="appBarFontIconColor">
								// 		glossary
								// 	</FontIcon>
								// }
							>
								Glossary
							</TextIconSpacing>
						</li> : null}
					<li onClick={isAuthenticated ? handleSignout : handleSignin}>
						<TextIconSpacing
							icon={
								<FontIcon className="appBarFontIconColor">
									{isAuthenticated ? 'logout' : 'login'}
								</FontIcon>
							}
						>
							{isAuthenticated ? 'Sign Out' : 'Sign In'}
						</TextIconSpacing>
					</li>
				</ul>
				{dialogVisible && (fixedTo?.current?.offsetLeft ?? 0) > 0 &&

					<GlosaryModal
						setDialog={setDialog}
						top={fixedTo?.current?.offsetTop ?? 0} left={fixedTo?.current?.offsetLeft ?? 0} />}

			</div>
		</div>
	);
}

export default TopBar;