import { Button } from 'react-md';
import * as IMG from '@utilities/constants/images';

function InlineNotApplicableButton(props) {
    const { className, height, group, index } = props;

    const isLineNA = group.lineItems[index][0].notApplicable

    return (
        <Button className={className}
            data-testid='btn-inline-na'
            disabled
            >
            <img height={height} alt={ isLineNA  ? 'Line item not applicable button active' : 
                'Line item not applicable button inactive' } 
            src={ isLineNA  ? IMG.naIconCompleted :
                IMG.naIconSecondary } />
        </Button>
    );
}

export default InlineNotApplicableButton;