import * as EVENT from '@utilities/constants/triggerKeys';

const showProgramActivities = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {        
        name[0].groups[2].isVisible = true;
    } else {
        name[0].groups[2].isVisible = false;
        name[0].groups[1].hasDivider = true;
    }
    setFormSections(name);
}

const showProgramServices = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {        
        name[0].groups[4].isVisible = true;
        name[0].groups[3].hasDivider = true;
    } else {
        name[0].groups[4].isVisible = false;
    }
    setFormSections(name);
}

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
    case 'showProgramActivities':
        showProgramActivities(event, options);
        break;
    case 'showProgramServices':
        showProgramServices(event, options);
        break;   
    default:
        break;
    }
};


export {
    triggeredEvent,
};