import _ from 'lodash';
import * as STRINGS from '@utilities/constants/strings';
import * as INPUTSTATE from '@utilities/constants/inputState';
import moneyFormatter, { parseMoneyToNumber, noDollarFormatter } from '../moneyFormatter';
import { getPYCalculationFunction } from '@utilities/axcessTaxPull';

/* eslint-disable no-unused-expressions */

const formatNumberValue = (value, isMonetary) => {
	return isMonetary === false
		? noDollarFormatter(Number(value))
		: moneyFormatter(Number(value));
};

const setFirstFieldProperties = (prePopulateControls) => (row, prePopulateItem) => {
	const { lineOptions, label } = prePopulateItem;
	const firstField = row[0];

	const firstColumnProperties = {
		default: label,
		lookup: '',
		inputState: INPUTSTATE.NO_INPUT,
		type: prePopulateControls[0] || 'label',
	};

	lineOptions?.staticLine ? firstColumnProperties.isStaticPrePopLine = true : null;

	Object.assign(firstField, firstColumnProperties);
};

const setStaticFieldProperties = (prePopulateControls) => (field) => {
	if (field.type === 'lineButtons') return;

	const staticFieldProperties = {
		type: prePopulateControls[2] || 'label',
		default: field.isTotal ? formatNumberValue(0, field.isMoney) : STRINGS.NOT_AVAILABLE_LABEL,
		inputState: INPUTSTATE.NO_INPUT,
	};

	Object.assign(field, staticFieldProperties);
};

const updateFieldWithValue = (prePopulateControls, totalsHolder) => (field, value) => {
	const { isMoney, name, isDefaultDate } = field;


	let valueFieldProperties = {
		default: formatNumberValue(value || 0, isMoney),
		inputState: INPUTSTATE.PRIOR_INPUT,
		priorYearValue: formatNumberValue(value || 0, isMoney),
		type: prePopulateControls[1] || 'label',
	};
	if (!Object.keys(totalsHolder).includes(name)) {
		totalsHolder[name] = 0;
	}

	if (!isNaN(value)) {
		totalsHolder[name] += Number(value);
	}

	if (isDefaultDate) {
		valueFieldProperties = {
			default: value,
			inputState: INPUTSTATE.PRIOR_INPUT,
			priorYearValue: value,
			type: prePopulateControls[1] || 'date',
		}
	}

	Object.assign(field, valueFieldProperties);
};

const prePopulateLineItems = (group) => {
	const { fields, lineItems, prePopulate, prePopulateControls, prePopulateIndexes, lineItemDetails } = group;

	const prePopLines = [];
	let totals = {};

	prePopulate.forEach((item) => {
		const { lineOptions } = item;
		const lineItem = _.cloneDeep(fields);

		if (lineOptions?.staticLine) {
			lineItem.forEach(setStaticFieldProperties(prePopulateControls));
		}

		setFirstFieldProperties(prePopulateControls)(lineItem, item);

		let prePopulatedAugments = null;

		if (item.name) {
			prePopulatedAugments = [{ name: item.name, priorValue: item.priorValue }];
		} else if (item.prePopulateAugments) {
			prePopulatedAugments = item.prePopulateAugments;
		}

		if (prePopulatedAugments) {
			prePopulatedAugments.forEach((prePopulateAugment, index) => {
				const { name, priorValue, sameValue = false,
					calculateValues, calculateKeys, calculateFn,
					priorModifiers, currentModifiers, defaultDueDate } = prePopulateAugment;

				if (_.isNil(name)) return;

				const adjustBy = lineItem[lineItem.length - 1].type === 'lineButtons' ? 2 : 1;

				// By default, prior index is second column and current index is last column
				let indexes = { priorIndex: 1, currentIndex: lineItem.length - adjustBy };

				if (_.isArray(prePopulateIndexes) && prePopulateIndexes.length >= index && _.isPlainObject(prePopulateIndexes[index])) {
					indexes = prePopulateIndexes[index];
				}

				const { priorIndex, currentIndex } = indexes;
				let lineValue = priorValue;

				if (calculateValues && calculateKeys && calculateFn) {
					const values = calculateKeys.map(x => calculateValues[x]);
					const pullFunction = getPYCalculationFunction(calculateFn);
					lineValue = pullFunction(values);
				}

				if (defaultDueDate && calculateValues && calculateKeys && calculateFn) {
					const values = calculateKeys.map(x => calculateValues[x]);
					const pullFunction = getPYCalculationFunction(calculateFn);
					lineValue = pullFunction(values);
				}

				if (_.isNumber(priorIndex) && lineItem.length >= priorIndex) {
					const priorField = lineItem[priorIndex];
					priorField.preGroupName = name;

					if (priorModifiers) {
						Object.keys(priorModifiers).forEach((modifierKey) => {
							priorField[modifierKey] = priorModifiers[modifierKey];
						});
					}

					updateFieldWithValue(prePopulateControls, totals)(priorField, lineValue);
				}

				if (_.isNumber(currentIndex) && lineItem.length >= currentIndex) {
					const currentField = lineItem[currentIndex];
					currentField.preGroupName = `C-${name}`;

					if (currentModifiers) {
						Object.keys(currentModifiers).forEach((modifierKey) => {
							currentField[modifierKey] = currentModifiers[modifierKey];
						});
					}
					if (sameValue || defaultDueDate) {
						updateFieldWithValue(prePopulateControls, totals)(currentField, lineValue);
					}

				}
			});
		}

		prePopLines.push(lineItem);
	});

	Object.keys(totals).forEach((totalKey) => {
		const priorTotal = lineItemDetails.footerLabels.find(x => x.name === totalKey);

		if (priorTotal) {
			const total = totals[totalKey] || 0;

			const initialTotal = parseMoneyToNumber(priorTotal.label);
			const summedTotal = _.isNaN(initialTotal) ? Number(total) : Number(initialTotal) + Number(total);

			priorTotal.label = priorTotal.type === 'totalMoney' ? moneyFormatter(summedTotal) : noDollarFormatter(summedTotal);
		}
	});

	// Insert generated prepopulated lines to the front of the lineItems list
	lineItems.splice(0, 0, ...prePopLines);
};

export default prePopulateLineItems;