import { Button, Dialog,
    FontIcon
} from 'react-md';
import { useState } from 'react';
import _ from 'lodash';

import api from '@utilities/claApi';
import usePageFramework from '@utilities/hooks/usePageFramework';

function RelatedPeopleDialog(props){
    const { 
        peopleData, 
        setData, 
        role, 
        dialogTitle, 
        visibleDialog, 
        hideDialog, 
        personId, 
        organizerId,
        organizerStatus,
        changeData, 
        rowData         // organizer row data
    } = props;
    const isAddRole = role === 'add';
    const isUpdateICRole = role === 'updateSigner';

    const selectedPerson = peopleData ? peopleData.find(person => person.id === personId) :
        rowData ? rowData.signer : null;
    const signerFields = [
        {label: 'First Name', required: true, type: 'text', defaultValue: selectedPerson?.first, propName: 'first', testId: 'signerFirst', maxLength: 30 },
        {label: 'Middle Initial', required: false, type: 'text', defaultValue: selectedPerson?.middle, propName: 'middle', testId: 'signerMiddle', maxLength: 1 },
        {label: 'Last Name', required: true, type: 'text', defaultValue: selectedPerson?.last, propName: 'last', testId: 'signerLast', maxLength: 30 },
        {label: 'CLA Email Address', required: true, type: 'email', defaultValue: selectedPerson?.email, propName: 'email', testId: 'signerEmail' },
    ];

    const relPeopleFields = [
        {label: 'First Name', required: true, type: 'text', defaultValue: selectedPerson?.first, propName: 'first', testId: 'relPeopleFirstName' },
        {label: 'Last Name', required: true, type: 'text', defaultValue: selectedPerson?.last, propName: 'last', testId: 'relPeopleLastName' },
        {label: 'Email Address', required: true, type: 'email', defaultValue: selectedPerson?.email, propName: 'email', testId: 'relPeopleEmail' },
        {label: 'Role', required: true, type: 'select', defaultValue: selectedPerson?.role, propName: 'role', testId: 'relPeopleRole',
            options: [
                {value: '', name: ''},
                {value: 'Client Admin', name: 'Client Admin'},
                {value: 'Client User', name: 'Client User'},
            ]
        },
        {label: 'Notifications', required: true, type: 'select', defaultValue: selectedPerson?.notifications, propName: 'notifications', testId: 'relPeopleNotification',
            options: [
                {value: 'All', name: 'All'},
                {value: 'None', name: 'None'},
            ]
        },
    ];
    const tableFields = isUpdateICRole ? signerFields : relPeopleFields;

    const [ emailError, setEmailError ] = useState({
        hasError: false,
        errorMessage: ''
    });
    const { ACTION, dispatch } = usePageFramework();
    const [ personData, setPersonData ] = useState({
        id: selectedPerson?.id || '',
        first: selectedPerson?.first || '',
        middle: selectedPerson?.middle || '',
        last: selectedPerson?.last || '',
        email: selectedPerson?.email || '',
        role: selectedPerson?.role || '',
        inviteSent: selectedPerson?.inviteSent || '',
        notifications: selectedPerson?.notifications || 'All',
        sendWelcome: false
    });
    const [ valued, setValued ] = useState(!tableFields.find(field => field.required && personData[field.propName] === ''));
    const [isPristine, setIsPristine] = useState(true);
    const addDisabled = (!valued || emailError.hasError || isPristine);

    const validateEmailRelPerson = (event) => {
        const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let isValidEmail = true;
        //if not a valid email address
        if (!pattern.test(event.target.value)) {
            isValidEmail = false;
            setEmailError({
                hasError: true,
                errorMessage: 'Must be a valid email address!'
            });
        }

        //if claconnect.com email
        if (event?.target?.value?.includes('@claconnect.com')) {
            isValidEmail = false;
            setEmailError({
                hasError: true,
                errorMessage: 'Must not be a CLA internal email!'
            });
        }

        if (isValidEmail) {
            setFieldState(event, 'email');
            setEmailError({
                hasError: false,
                errorMessage : ''
            }); 
        }
    };

    const validateEmailSigner = (event) => {
        let isValidEmail = true;

        //if not claconnect.com email
        if (!event.target.value.toLowerCase().includes('@claconnect.com')) {
            isValidEmail = false;
            setEmailError({
                hasError: true,
                errorMessage: 'Please enter a CLA email address'
            });
        }

        if (isValidEmail) {
            setFieldState(event, 'email');
            setEmailError({
                hasError: false,
                errorMessage : ''
            }); 
        }
    };

    const onClose = () => {
        setEmailError({
            hasError: false,
            errorMessage : ''
        }); 
        setValued(false);
        hideDialog();
        const personDataCopy = _.cloneDeep(personData);
        Object.keys(personDataCopy).forEach(key => {
            personDataCopy[key] = '';
        })
        setPersonData(personDataCopy);
    };

    const onUpdateOrgSigner = (signerData) => {
        dispatch(ACTION.setProgressVisible(false));
        changeData(organizerId, [ { column: 'signer', value: signerData } ]);
        onClose();
    };

    const showErrorDialog = (dialogTitle) => {
        dispatch(ACTION.setProgressVisible(false));
        dispatch(ACTION.setShowCustomDialog(true));
        dispatch(ACTION.setCustomDialogTitle(dialogTitle));
        dispatch(ACTION.setCustomDialogMsg('Please try again or contact IT for support'));
    };

    const addSavePerson = () => {
        if (isAddRole) {
            dispatch(ACTION.setProgressText('Adding New Person...'));
            dispatch(ACTION.setProgressVisible(true));
            const peopleList = _.cloneDeep(peopleData);
            const personDataToAdd = _.cloneDeep(personData);
            delete personDataToAdd.id;
            delete personDataToAdd.inviteSent;
            
            // org status to determine if email notif is sent or not (send for Not New)
            if (organizerStatus !== 'New')
                personDataToAdd.sendWelcome = true;

            api.post(`/organizers/${organizerId}/users`, personDataToAdd).then((response) => {
                // need to check if these are still needed? START
                personData.id = response?.data?.id
                peopleList.push(personData); 
                setData(peopleList);
                // need to check if these are still needed? END
		        dispatch(ACTION.setProgressVisible(false));
                onClose();
            }).catch((error) => {
                console.log(error);
		        dispatch(ACTION.setProgressVisible(false));
                dispatch(ACTION.setShowCustomDialog(true));
                dispatch(ACTION.setCustomDialogTitle('Add New Person Error!'));
                if (error?.response?.data?.message === 'user already exists') {
                    dispatch(ACTION.setCustomDialogMsg('User already exists. Try adding another email'));
                } else {
                    dispatch(ACTION.setCustomDialogMsg('Please try again or contact IT for support'));
                }
            })
        } 

        if (isUpdateICRole) {
            dispatch(ACTION.setProgressText('Updating Signer'));
		    dispatch(ACTION.setProgressVisible(true));

            const signerData = _.cloneDeep(personData);
            delete signerData.id;
            delete signerData.inviteSent;
            delete signerData.role;
            delete signerData.notifications;

            //check if the email exists in users
            api.get('/users', { params: { email: signerData.email }}).then((response) => {
                const signerId = response?.data?.results?.length ? response?.data?.results[0].id : null;
                if (!signerId) {
                    // if in charge user does not exist, create new user
                    api.post('users', signerData).then((response) => {
                        const signerIdNew = response?.data?.id;
                        //update organizer's signerId
                        api.put(`/organizers/${organizerId}`, { signerId: signerIdNew }).then((response) => {
                            onUpdateOrgSigner(signerData);
                        }).catch((error) => {
                            console.log(error);
                            showErrorDialog('Update Signer Error');
                        });
                    }).catch((error) => {
                        console.log(error);
                        showErrorDialog('Unable to add new Signer');
                    })
                } else {
                    //email should not be part of param obj to be updated
                    const signerDataCopy = _.cloneDeep(signerData);
                    delete signerDataCopy.email;
                    // if in charge user exists, update user
                    api.put(`users/${signerId}`, signerDataCopy).then((response) => {
                        //update organizer's signerId
                        api.put(`/organizers/${organizerId}`, { signerId: signerId }).then((response) => {
                            dispatch(ACTION.setProgressVisible(false));
                            onClose();
                        }).catch((error) => {
                            console.log(error);
                            showErrorDialog('Update Signer Error');
                        });
                    }).catch((error) => {
                        console.log(error);
                        showErrorDialog('Unable to update Signer');
                    })
                }
            }).catch((error) => {
                console.log(error);
                showErrorDialog('Update Signer Error');
            });
        }
    };

    const setFieldState = (event, propKey) => {
        const peopleDataCopy = _.cloneDeep(personData);
        peopleDataCopy[propKey] = event.target.value;
        setPersonData(peopleDataCopy);
        setValued(!tableFields.find(field => field.required && peopleDataCopy[field.propName] === ''));
    };

    return(
        <Dialog
            id={`dialog-${role}`}
            key={`dialog-${role}`}
            visible={visibleDialog}
            role="dialog"
            aria-labelledby="fixed-dialog-add-person"
            onRequestClose={() => {}}
            className="relPeopleDialog"
            overlayStyle={{backgroundColor: 'unset'}}
        >   
            <div className='relPersonHeaderContainer' key={`dialog-header-${role}`}>
                <div className='relPersonTitle'>{dialogTitle}</div>
                <Button onClick={() => onClose()} first="true" last="true">
                    <FontIcon className='relPersonCloseButton' data-testid='close-icon'>close</FontIcon>
                </Button>
            </div>

            <div key={`dialog-table-${role}`} className="relPeopleTable" style={isUpdateICRole ? {gridTemplateColumns: '2fr 1fr 2fr 2fr .5fr'} : null}>
                {tableFields.map((field, index) => (
                    <div key={`dialog-field-${index}-${role}`}>
                    <label className="relPeopleTopLabel" style={field.type === 'email' && emailError.hasError ? {color: 'red'} : null}>{field.label} {field.required ? <span className='requiredAsterisk'>*</span> : null}</label>
                    {field.type === 'text' ? 
                        <input type="text" data-testid={field.testId} className="relPeopleInput" maxLength={field.maxLength} defaultValue={field.defaultValue} onBlur={(event) => setFieldState(event, field.propName)} onChange={e => setIsPristine(false)} />
                    : field.type === 'email' ? 
                        <input type="email" data-testid={field.testId} className="relPeopleInput" defaultValue={field.defaultValue} onBlur={(event) => isUpdateICRole ? validateEmailSigner(event) : validateEmailRelPerson(event)} style={emailError.hasError ? {border: 'solid 1px red'} : null} onChange={e => setIsPristine(false)} />
                    : field.type === 'select' ?
                        <select className="relPeopleInput" data-testid={field.testId} style={{paddingLeft: '12px'}}  defaultValue={field.defaultValue} onChange={(event) => setFieldState(event, field.propName)}>
                            {field.options.map((option)=> (
                                <option value={option.value}>{option.name}</option>
                            ))}
                        </select>
                    : null}
                    {field.type === 'email' && emailError.hasError && <label className="relPeopleError">{emailError.errorMessage}</label>}
                    </div>
                ))}
            
                <Button id="contained-button-1" className="addSaveButton" themeType="contained" 
                    onClick={() => addSavePerson()} disabled={addDisabled} style={addDisabled ? {opacity: '0.4'} : null}>
                    {isAddRole ? 'Add' : 'Save'}
                </Button>
            </div>
        </Dialog>
    );
}

export default RelatedPeopleDialog;