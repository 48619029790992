import _ from "lodash";
import { getRequiredPSData } from "@utilities/axcessTaxPull";

const splitRemove = (lineData, lineSplitters) => {
  const newLines = [];

  lineSplitters.forEach((splitter) => {
    const clonedLineData = _.cloneDeep(lineData);

    const doesExist = (x) => splitter.includes(x.key);

    _.remove(clonedLineData, doesExist);
    newLines.push(clonedLineData);
  });

  if (!newLines.length && lineData.length) {
    newLines.push(_.cloneDeep(lineData));
  }

  return newLines;
};

const splitInclude = (lineData, lineSplitters) => {
  const newLines = [];

  lineSplitters.forEach((splitter) => {
    let shouldSplit = false;

    splitter.splitOnFields.forEach((field) => {
      const foundField = lineData.find((x) => x && x.key === field);

      if (foundField) {
        if (foundField.value) {
          shouldSplit = true;
        }
      }
    });

    if (shouldSplit) {
      const clonedLineData = _.cloneDeep(lineData); // Make a copy of the current row

      const shouldNotInclude = (x) => !splitter.includeFields.includes(x?.key); // Is this a field that should split on?

      _.remove(clonedLineData, shouldNotInclude);
      newLines.push(clonedLineData);
    }
  });
  if (!newLines.length && lineData.length) {
    newLines.push(_.cloneDeep(lineData));
  }

  return newLines;
};
const splitIncludeMap = (lineData, lineSplitters) => {
  return lineData
    .filter((element) => {
      return lineSplitters[0].splitOnFields.includes(element.key);
    })
    .map((element) => {
      return [
        {
          key: lineSplitters[0]?.fieldOverride
            ? lineSplitters[0].fieldOverride
            : element.key,
          value: element.value,
        },
      ].concat(
        lineData.filter((element) => {
          return !lineSplitters[0].splitOnFields.includes(element.key);
        })
      );
    });
};

const split = (lineData, lineSplitters) => {
  const newLines = [];

  return newLines;
};

const splitOnIndex = (lineData, lineSplitters) => {
  const newLines = [];

  const groupedData = _.groupBy(lineData, "index");
  Object.entries(groupedData)
    .sort((a, b) => a - b)
    .forEach(([index, row]) => {
      newLines.push(row);
    });

  return newLines;
};

const splitMergeAndInclude = (lineData, lineSplitters, priorYearData) => {
  const newLines = [];
  const psArray = [];

  lineSplitters.forEach((splitter) => {
    let shouldSplit = false;

    splitter.splitOnFields.forEach((field) => {
      const foundField = lineData.find((x) => x && x.key === field);
      const fields = splitter.includeFields;
      const lookUp = splitter.lookUpField;
      const sectionId = splitter.sectionId;

      if (foundField) {
        if (foundField.value) {
          shouldSplit = true;
        }

        if (fields.includes(foundField.key)) {
          const psStatement = getRequiredPSData(
            sectionId,
            priorYearData,
            lookUp
          );

          if (psStatement) {
            psArray.push(psStatement);
          }
        }
      }
    });

    if (shouldSplit) {
      const clonedLineData = _.cloneDeep(lineData);
      const shouldNotInclude = (x) => !splitter.includeFields.includes(x?.key); // Is this a field that should split on?
      _.remove(clonedLineData, shouldNotInclude);

      newLines.push(clonedLineData);
    }
  });

  if (!newLines.length && lineData.length) {
    newLines.push(_.cloneDeep(lineData));
  }

  const accumulator = [];
  for (let i = 0; i < newLines.length; i++) {
    newLines[i] && psArray[i] && Object.keys(psArray[i]).length !== 0
      ? accumulator.push([...newLines[i], psArray[i]])
      : accumulator.push(newLines[i]);
  }
  return accumulator;
};

const LINE_SPLITTER_FUNCTIONS = {
  split,
  splitRemove,
  splitInclude,
  splitIncludeMap,
  splitOnIndex,
  splitMergeAndInclude,
};

const splitGroupLines = (
  dataLines,
  lineSplitterType,
  lineSplitters,
  priorYearData
) => {
  const lineSplitterFunction = LINE_SPLITTER_FUNCTIONS[lineSplitterType];

  if (!lineSplitterType || !lineSplitterFunction) return dataLines;

  const splitLines = dataLines.reduce((prev, curr) => {
    const newCurrs = lineSplitterFunction(curr, lineSplitters, priorYearData); // return an array
    prev.push(...newCurrs);
    return prev;
  }, []);

  return splitLines;
};

export {
  splitGroupLines,
  splitRemove,
  splitInclude,
  splitIncludeMap,
  splitMergeAndInclude,
};
