import { useState, useEffect } from 'react';
import usePageFramework from './usePageFramework';


const useLoading = ({ isLoadingInit = false, loadingTextInit = "Loading..." } = {}) => {
    const { dispatch, ACTION } = usePageFramework();
    const [isLoading, setIsLoading] = useState(isLoadingInit);
    const [loadingText, setLoadingText] = useState(loadingTextInit);

    useEffect(() => {
        dispatch(ACTION.setProgressVisible(isLoading));
        // eslint-disable-next-line
    }, [isLoading])

    useEffect(() => {
        dispatch(ACTION.setProgressText(loadingText));
        // eslint-disable-next-line
    }, [loadingText])

    return {
        setIsLoading, setLoadingText, isLoading
    }

}
export default useLoading;