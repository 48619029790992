import React, { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { triggeredEvent, sectionFieldLogic } from './internalSetupLogicTrigger';
import { pullFieldValue } from '../../../utilities/pullFieldValue';
import * as NAV from '@utilities/constants/navigation';
import SuccessDialog from '@components/SuccessDialog';
import { useToggle } from 'react-md';

import { selectReduxGlobalState } from '@utilities/store';
import { useSelector } from 'react-redux';
import api from '@utilities/claApi';

import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

let sections = [];
//TODO: need to refactor this to get the updated formstate titles
export const getInternalSetupTitles = () => {
  let titles = [];
  sections.forEach((section) => {
    if (!titles.includes(section.title)) {
      titles.push(section.title);
    }
  });
  return titles;
};

export const getInternalSetupSections = () => {
  return sections;
};

function InternalSetup(props) {
  const { isExportForm } = props;
  const { updateCard, REDUX, selectState, setGlobalFormState } =
    usePageFramework(NAV.INTERNAL_SETUP);
  const [sectionState, setSectionState] = useState('');
  const entryExperienceForm = selectState(REDUX.ENTRY_INTERVIEW);
  const financialsForm = selectState(REDUX.FINANCIALS);
  const supFinStmntsForm = selectState(REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS);
  const taxComplianceForm = selectState(REDUX.TAX_COMPLIANCE_FILINGS);
  const taxExemptBondsForm = selectState(REDUX.TAX_EXEMPT_BONDS);
  const hospitalForm = selectState(REDUX.HOSPITAL);
  const basicDataForm = selectState(REDUX.BASIC_DATA);
  const [visibleWarningDialog, showWarningDialog, hideWarningDialog] =
    useToggle(false);

  const organizerId = useSelector((state) =>
    selectReduxGlobalState(state, REDUX.ORGANIZER_ID)
  );

  //function for close button that turns checkedForPrior true
  function checkForPriorCloseButton() {
    hideWarningDialog();
    api.put(`/organizers/${organizerId}`, {
      checkedForPrior: true,
      lastUserActivityOn: new Date(),
    });
  }

  const { isActiveClient, isLoading } = useLoadOrganizer();

  // Warn the user if this organizer has no prior year data
  useEffect(() => {
    api.head(`/organizers/${organizerId}/prior`).catch((err) => {
      api
        .get(`/organizers/${organizerId}`)
        .then((response) => {
          const checkedForPrior = response?.data?.checkedForPrior || false;

          if (!checkedForPrior) {
            showWarningDialog();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    });
  }, []);

  useEffect(() => {
    if (!isExportForm) updateCard();
    sections = [
      {
        title: 'CLA Client Setup',
        isVisible: true,
        sectionId: 1,
        groups: [
          {
            groupId: 1,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Return Status (Please Check All that apply)',
                name: 'labelOne',
                type: 'label',
                col: 10,
                class: 'labelQuestionTitle',
              },
            ],
          },
          {
            groupId: 2,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Group Return for Subordinates',
                name: 'grpRtrnLabel',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                name: 'grpRtrn',
                type: 'checkbox',
                col: 2,
                class: 'singleCheckboxMargin',
                isFieldCheckbox: true,
                prior: true,
                isPriorEditable: true,
                axcess: {
                  pull: {
                    fields: ['XFDSGRP.9'],
                    fn: 'checkboxValuePull',
                  },
                },
                logicFunction: [
                  {
                    trigger: 'showBasicDatasubsIncl',
                    triggerRelatedForms: { basicData: basicDataForm },
                    setGlobalFormState: setGlobalFormState,
                  },
                ],
              },
            ],
          },
          {
            groupId: 3,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Initial Return',
                name: 'initRtrnLabel',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                name: 'initRtrn',
                type: 'checkbox',
                col: 2,
                class: 'singleCheckboxMargin',
                isFieldCheckbox: true,
              },
            ],
          },
          {
            groupId: 4,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Final Return/terminated',
                name: 'finalRtrnLabel',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                name: 'finalRtrn',
                type: 'checkbox',
                col: 2,
                class: 'singleCheckboxMargin',
                isFieldCheckbox: true,
              },
            ],
          },
          {
            groupId: 5,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Application Pending',
                name: 'appPendLabel',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                name: 'appPend',
                type: 'checkbox',
                col: 2,
                class: 'singleCheckboxMargin',
                isFieldCheckbox: true,
              },
            ],
          },
          {
            groupId: 6,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Organization name change',
                name: 'nameChngLabel',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                name: 'nameChng',
                type: 'checkbox',
                col: 2,
                class: 'singleCheckboxMargin',
                isFieldCheckbox: true,
              },
            ],
          },
          {
            groupId: 7,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: `Does CLA have a copy of the organization's IRS Determinations Letter (or subsequent IRS confirmation of exempt status)?`,
                name: 'ltrDetermineLabel',
                type: 'label',
                col: 8,
              },
              {
                name: 'ltrDetermine',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 4,
                logicFunction: [
                  {
                    trigger: 'showBasicDataltrDetermineAtt',
                    triggerRelatedForms: {
                      basicData: basicDataForm,
                      entryExperience: entryExperienceForm,
                    },
                    setGlobalFormState: setGlobalFormState,
                  },
                ],
              },
            ],
          },
          {
            groupId: 8,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: `Do you want to request a copy of the organization's Application for Recognition of Exemption?`,
                name: 'appExemptRequestLabel',
                type: 'label',
                col: 8,
              },
              {
                name: 'appExemptRequest',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 4,
                logicFunction: [
                  {
                    trigger: 'showBasicDataappExemptCopy',
                    triggerRelatedForms: { basicData: basicDataForm },
                    setGlobalFormState: setGlobalFormState,
                  },
                ],
              },
            ],
          },
          {
            groupId: 9,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Year of Formation',
                name: 'yrFormLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Year of Formation',
                name: 'yrForm',
                type: 'yearPicker',
                col: 3,
                isVisible: true,
                ...pullFieldValue(['XFDS99GN.4'], false),
              },
            ],
          },
          {
            groupId: 10,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'State of Legal Domicile',
                name: 'legalStateLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'State of Legal Domicile',
                name: 'legalState',
                type: 'select',
                lookup: 'STATES',
                col: 3,
                isVisible: true,
                ...pullFieldValue(['XFDS99GN.5'], true),
              },
            ],
          },
          {
            groupId: 11,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Form of Organization',
                name: 'formOrgLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Form of Organization',
                name: 'formOrg',
                type: 'select',
                lookup: 'CLIENT_SETUP_FORM_ORG',
                col: 3,
                isVisible: true,
                logicFunction: [
                  { trigger: 'showFormOrgOth', isDifferentGroup: true },
                ],
                ...pullFieldValue(['XFDS99GN.2'], true),
              },
            ],
          },
          {
            groupId: 12,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                name: 'formOrgOth',
                type: 'textarea',
                label: 'Please Describe',
                placeholder: 'Please Describe',
                col: 12,
                class: 'fieldTextArea',
                maxLength: 76,
                ...pullFieldValue(['XFDS99GN.3'], true),
              },
            ],
          },
          {
            groupId: 13,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Which of the following did CLA perform for the client?',
                name: 'claCompRvwAudLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Services provided',
                name: 'claCompRvwAud',
                type: 'select',
                lookup: 'CLIENT_SETUP_CLA_COMP_RVW_AUD',
                col: 3,
                isVisible: true,
                logicFunction: [
                  {
                    trigger: 'showEntryreqSchdChkDv',
                    triggerRelatedForms: {
                      entryExperience: entryExperienceForm,
                      supplementalFinancialStatements: supFinStmntsForm,
                    },
                    setGlobalFormState: setGlobalFormState,
                  },
                  {
                    trigger: 'showFinancialsSections',
                    triggerRelatedForms: {
                      financials: financialsForm,
                      basicData: basicDataForm,
                    },
                    setGlobalFormState: setGlobalFormState,
                  },
                ],
              },
            ],
          },
          {
            groupId: 14,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: `Does the endowment reporting in the Form 990 match the endowment reporting in the notes to the audited financial statements?`,
                name: 'supFinEndowChkTrigLabel',
                type: 'label',
                col: 8,
              },
              {
                name: 'supFinEndowChkTrig',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 4,
                logicFunction: [
                  {
                    trigger: 'showSupplementStatement',
                    triggerRelatedForms: {
                      supplementalFinancialStatements: supFinStmntsForm,
                    },
                    setGlobalFormState: setGlobalFormState,
                  },
                ],
              },
            ],
          },
          {
            groupId: 15,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: `Did the Client's Year-End change?`,
                name: 'clientYearEndChangeLabel',
                type: 'label',
                col: 8,
              },
              {
                name: 'clientYearEndChange',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 4,
                logicFunction: [
                  {
                    trigger: 'setTaxYearEndEditable',
                    triggerRelatedForms: { basicData: basicDataForm },
                    setGlobalFormState: setGlobalFormState,
                  },
                ],
              },
            ],
          },
          {
            groupId: 16,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Tax calendar year:',
                name: 'calYearEndLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Tax Calendar Year',
                name: 'calYearEnd',
                type: 'yearPicker',
                col: 3,
                isVisible: true,
                logicFunction: [
                  {
                    trigger: 'setTaxCompothFilings1096Label',
                    triggerRelatedForms: {
                      taxCompliance: taxComplianceForm,
                      hospitalForm: hospitalForm,
                    },
                    setGlobalFormState: setGlobalFormState,
                  },
                ],
              },
            ],
          },
          {
            groupId: 17,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: `Does client's Fiscal Year end at the end of the calendar year?`,
                name: 'fyIsCalYrLabel',
                type: 'label',
                col: 8,
              },
              {
                name: 'fyIsCalYr',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 4,
                prior: true,
                isPriorEditable: true,
                isFiscalYear: true,
                default: 'Yes',
                axcess: {
                  pull: {
                    fields: ['XFDSGEN.18'],
                    fn: 'fiscalYearPull',
                  },
                },
              },
            ],
          },
          {
            groupId: 18,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Does CLA already have copies of all Form 8038s for each Tax Exempt Bond?',
                name: 'taxExBond8038sCCLabel',
                type: 'label',
                col: 9,
              },
              {
                label: '',
                name: 'taxExBond8038sCC',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 3,
                isVisible: true,
                logicFunction: [
                  {
                    trigger: 'setTaxExBond8038sCcAttach',
                    triggerRelatedForms: {
                      taxExemptBondsForm: taxExemptBondsForm,
                    },
                    setGlobalFormState: setGlobalFormState,
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
    setSectionState(sections);
    // eslint-disable-next-line
  }, []);

  const axcessIdentifiers = [
    { section: 'General', id: 'Basic Data' },
    { section: 'Return Type', id: 'Form 990 - Exempt Organization Return' },
  ];

  return (
    <>
      <SuccessDialog
        subtitleText="No prior year data was found! If you are expecting prior year data, please contact the service desk before completing the internal setup."
        onCloseClick={() => {
          checkForPriorCloseButton();
        }}
        visible={visibleWarningDialog}
      />
      {!isLoading && isActiveClient && (
        <FormRendererHelper
          sections={sectionState}
          key="internalSetupForm"
          formName={REDUX.INTERNAL_SETUP}
          identifiers={axcessIdentifiers}
          triggeredEvent={triggeredEvent}
          sectionFieldLogic={sectionFieldLogic}
          isExportForm={isExportForm}
        />
      )}
      {!isLoading && !isActiveClient && <NoActiveClient />}
    </>
  );
}

export default InternalSetup;
