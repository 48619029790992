import { TextField } from 'react-md';
import { SearchSVGIcon } from '@react-md/material-icons';

const GlossarySearch = ({ placeholder, iconPosition = 'left', setSearchTerm }) => {
    const styles = iconPosition === 'left' ?
        { borderBottom: 'none', borderRadius: '4px', height: 'px' } :
        { background: 'none', height: '39px', width: '100%' };
    
    return <TextField 
        id={`dashboard-searchfield-${iconPosition}`}
        data-testid="glossarySearchInput"
        className="glossarySearch"
        style={styles}
        theme="filled"
        placeholder={placeholder}
        inline
        dense
        leftChildren={iconPosition === 'left' && <SearchSVGIcon />}
        rightChildren={iconPosition === 'right' && <SearchSVGIcon />}
        onChange={event => {setSearchTerm(event.target.value)}}
    />;
};

export default GlossarySearch;