import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { sectionFieldLogic, triggeredEvent } from './domesticGrantsLogicTrigger';
import { pullFieldValue, pullFieldValueIfOtherEquals } from '@utilities/pullFieldValue';
import { GRANTS_INDV_EST_METH } from '../../../utilities/constants/axcessKeys';
import * as NAV from '@utilities/constants/navigation';
import { Validators } from 'cla-formrenderer';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

const OrganizationGovernmentLineSection = [
	{
		title: ' ',
		isVisible: true,
		sectionId: 1,
		isCollapsed: false,
		isDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider:true,
				fields: [
					{ label: 'Address', name: 'grntOrgAdd', type: 'freeText', col: 3 },
					{ label: 'City', name: 'grntOrgCity', type: 'freeText', col: 3 },
					{ label: 'State', name: 'grntOrgState', type: 'select', lookup: 'STATES', col: 3 },
					{ label: 'Zip', name: 'grntOrgZip', type: 'zippostal', col: 3 }
				],
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider:true,
				fields: [
					 { label: 'IRC Section (if applicable)', name: 'grntOrgIrcSecLabel', type: 'label', col: 7},
					 { label: '', name: 'grntOrgIrcSec', type: 'select', lookup: 'EXEMPT_STATUSES', col: 5, logicFunction: [{ trigger: 'showGovEntity', isDifferentGroup: true }]},
					],

			},	
			{
				groupId: 6,
				isVisible: false,
				hasDivider: true,
				isOptionalGroupMerge: true,
				fields: [
					{ label: 'Enter the name of the government entity (only if grantee is a government entity)', name: 'grntOrgIrcSecLabel', type: 'label', col: 9},
					{ label: '', name: 'govEntity', type: 'freeText', maxLength: 20, col: 3}
				]
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider:true,
				fields: [
					{ label: 'Method of Valuation', name: 'grntOrgAmtValMthdLabel', type: 'label', col: 9 },
					{ label: 'Method of Valuation', name: 'grntOrgAmtValMthd', type: 'select', lookup: 'GRNT_OUT_US_VAL_METH', col: 3 },
				]

			},

			{
				groupId: 4,
				isVisible: true,
				hasDivider:true,
				fields: [
					{ label: 'Description of Noncash Assistance', name: 'grntOrgNonCashDescLabel',class: 'labelTitleTextArea', type: 'label', col: 12},
					{label: 'Please provide details', name: 'grntOrgNonCashDesc', type: 'textarea', maxLength: 900, class: 'fieldTextArea', col: 12}
				],
			},
			{
				groupId: 5,
				isVisible: true,
				fields: [
					{ label:  'Purpose of Grant or Assistance', name: 'grntOrgPurpLabel', type: 'label', class: 'labelTitleTextArea', col: 12 },
					{ label: 'Please provide details', name: 'grntOrgPurp', class: 'fieldTextArea', type: 'textarea', maxLength: 900, col: 12}
				],
			}
		]
	},
];
const IndividualsLineSection = [
	{
		title: 'Grant/Assistance Details',
		isVisible: true,
		sectionId: 1,
		isCollapsed: false,
		isDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider:true,
				fields: [
					{ label: 'Description of Noncash Assistance', name: 'grntIndvAmtValMthdLabel',class: 'labelTitleTextArea', type: 'label', col: 12},
					{label: 'Please provide details', name: 'grntIndvAmtValMthd', type: 'textarea', maxLength: 900, class: 'fieldTextArea', col: 12}
				],
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider:true,
				fields: [
					{ label: 'Method of Valuation', name: 'grntIndvNonCashDescLabel', type: 'label', col: 9},
					{ label: 'Method of Valuation', name: 'grntIndvNonCashDesc', type: 'select', lookup: 'GRNT_OUT_US_VAL_METH', col: 3},
				],
			},
			{
				groupId: 3,
				isVisible: true,
				fields: [
					{ label: 'Explain the method used to estimate the number of recipients for each type of assistance', name: 'grntIndvEstMethLabel', class: 'labelTitleTextArea', type: 'label', col: 12},
					{label: 'Please explain', name: 'grntIndvEstMeth', type: 'textarea', maxLength: 9000, class: 'fieldTextArea', col: 12, prior: true,
					...pullFieldValueIfOtherEquals(
						'XFDSIEXP.4',
						'XFDSIEXP.2',
						GRANTS_INDV_EST_METH,
						true
						)
					}
				],
			}
		]
	},
];

const sections = [
	{
		title: 'Domestic Grants',
		isVisible: true,
		sectionId: 1,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization report more than $5,000 of grants or other assistance to or domestic organization or domestic government?', name: 'reqSchdChkIiiLabel', type: 'label', col: 9 },
					{ label: '', name: 'reqSchdChkIii', type: 'radio', lookup: 'YESNO_RADIO', col: 3, triggered: 'showStepOneAndTwo'},
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: '	Did the organization report more than $5,000 of grants or other assistance to or for domestic individuals?', name: 'reqSchdChkIiiiLabel', type: 'label', col: 9 },
					{ label: '', name: 'reqSchdChkIiii', type: 'radio', lookup: 'YESNO_RADIO', col: 3, triggered: 'showStepOneAndThree'},
				]
			},
		]
	},
	{
		title: 'Organization\'s Records Management',
		isVisible: false,
		sectionId: 2,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Does the organization maintain records to substantiate the amount of grants or assistance, the grantees\' eligibility for the grants/assistance, and the selection criteria used to award the grants/assistance?', name: 'grntMaintRecLabel', type: 'label', col: 9},
					{ label: '', name: 'grntMaintRec', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{trigger: 'showGrntMaintRecProc'}] },
				]
			},
			{
				groupId: 2,
				isVisible: false,
				fields: [
					{ label: 'Describe the organization\'s procedures for monitoring the use of grant funds to entities for use in the United States:', name: 'grntMaintRecProcLabel', type: 'label', class: 'labelTitleTextArea', col: 12 },
					{ label:'Please provide details', name: 'grntMaintRecProc', col: 12, type: 'textarea', class:'fieldTextArea', maxLength: 900, ...pullFieldValue(['XFDS99I1.1'], true) },
				]
			},
		]
	},
	{
		title: 'Organizations and Governments',
		isVisible: false,
		sectionId: 3,
		hasDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Provide the following information for any grant or other assistance to U.S. organization or government (not individuals) that are more than $5,000', name: 'grntOrgLabel', type: 'label', col: 10 },
					{ label: 'Attach or Table', name: 'grntOrgAttachOrFill', type: 'select', lookup: 'TABLE_OR_ATTACH', col: 2,  logicFunction: [{ trigger: 'showTableOrUpload' }]},
				]
			},
			{
				groupId: 2,
				isVisible: false,
				hasDivider: true,
				groupType: 'lineItem',
				uploadType: 'drawer',
				class: 'householdEmploymentUpload',
				fields: [
					{ label: 'Provide the following information for any grant or other assistance to U.S. organization or government (not individuals) that are more than $5,000', name: 'grntOrgAttachLabel', type: 'label', col: 9 },
					{ type: 'lineButtons', col: 3 }  
				],
				lineItems: [],
				lineItemDetails: {
					headerLabels: [],
					footerLabels: [],
					lineButtons: [
						{ button: 'uploadOnly', uploadSectionName: 'grntOrgAttach', viewSectionId: 0 },
					]
				}
			},
			{
				groupType: 'lineItem',
				isVisible: false,
				groupId: 4,
				fields: [
					{ label: 'Name', name: 'grntOrgName', type: 'freeText' },
					{ label: 'EIN', name: 'grntOrgEIN', type: 'ein', validations: { ...Validators.ein() }  },
					{ label: 'Amount of Cash Grant', name: 'grntOrgAmtCash', type: 'money',  isTotal: true },
					{ label: 'Amount of Noncash Grant', name: 'grntOrgAmtNonCash', type: 'money',  isTotal: true },
					{ type: 'lineButtons' }
				],
				lineItems: [],
				entities: [],
				currentEntity: -1,
				lineSections: OrganizationGovernmentLineSection,
				headerLabelsPrintStyles: {gridTemplateColumns: '2.12fr 2fr 1.75fr 1.85fr .85fr'},
				footerLabelsPrintStyles: {gridTemplateColumns: '6.5fr 2fr 2fr'},
				lineItemDetails:
				{
					headerLabels: [
						{ label: 'Name', name: 'grntOrgName', col: 3, colTablet: 2,  styles: {textAlign: 'left', paddingLeft: '50px'} },
						{ label: 'EIN', name: 'grntOrgEIN', col: 3, colTablet: 2, styles: {textAlign: 'left', paddingLeft: '40px'} },
						{ label: 'Amount of Cash Grant', name: 'grntOrgAmtCash', col: 3, colTablet: 2, styles: {textAlign: 'left', paddingLeft: '30px'} },
						{ label: 'Amount of Noncash Grant', name: 'grntOrgAmtNonCash', type: 'freeText', col: 2, colTablet: 1, styles: {textAlign: 'left', paddingLeft: '20px'}  },					
						{ label: 'Actions', col: 1, colTablet: 1, styles: { paddingLeft: '20px' } },
					],
					footerLabels: [
                        { type: 'buttons', col: 12, buttonTitle: 'Add Organization', icon: 'add' },
                        { type: 'totalMoney', name: 'grntOrgAmtCash', col: 9, colTablet: 4, label: ' $ 0' },
                        { type: 'totalMoney', name: 'grntOrgAmtNonCash', col: 2, colTablet: 2, label: ' $ 0' },
                    ],
					lineButtons: [
                        //{ button: 'uploadNa', uploadSectionName: '', viewSectionId: 0 },
                        { button: 'remove', },
                    ]
				}
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{label: 'Enter total number of Section 501(c)(3) and government organizations listed in the table above:', name: 'grntOrgCntNpGovLabel', type: 'label', col: 10},
					{ label: '', name: 'grntOrgCntNpGov', type: 'total', col: 2 },
				]
			},
		]
	},
	{
		title: 'Individuals',
		isVisible: false,
		hasDenseRow: true,
		sectionId: 4,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				fields: [
					{ label: 'Provide the following information for each grant or other assistance that was given to individual recipients during the year:', name: 'grntIndvLabel', type: 'label', col: 10 },
				]
			},
			{
				groupType: 'lineItem',
				groupId: 2,
				axcessGroup: {
					pull: {
						fromSections: [
							'Schedule I Supplemental Explanations'
						],
						fieldPrefixes: ['XFDSIEXP'],
						lineFilterType: 'any',
							lineFilters: [
								{ key: 'XFDSIEXP.2', filterType: 'notContainsAny', filterData: ['Column (b)'] },
							],
					}
				},
				entityIdentifiers: [
					{
						axcessDataType: 'PassDataToChild',
					},
        		],
				fields: [
					{ label: 'Type of Grant or Assistance', name: 'grntIndvType', type: 'freeText' },
					{ label: 'Number of Recipients', name: 'grntIndvCntRecp', type: 'total' },
					{ label: 'Amount of Cash Grant', name: 'grntIndvCash', type: 'money',  isTotal: true},
					{ label: 'Amount of Non-cash Assistance', name: 'grntIndvNonCash', type: 'money',  isTotal: true },					
					{ type: 'lineButtons' }
				],
				lineItems: [],
				entities: [],
				subWorkSheets: [],
				currentEntity: -1,
				lineSections: IndividualsLineSection,
				headerLabelsPrintStyles: {gridTemplateColumns: '2.12fr 2fr 1.5fr 1.5fr 1fr'},
				footerLabelsPrintStyles: {gridTemplateColumns: '6.5fr 2fr 2fr'},
				lineItemDetails:
				{
					headerLabels: [
						{ label: 'Type of Grant or Assistance', name: 'grntIndvType', col: 3, colTablet: 2, styles: {textAlign: 'left', paddingLeft: '50px'} },
						{ label: 'Number of Recipients', name: 'grntIndvCntRecp', col: 3, colTablet: 1, styles: {textAlign: 'left', paddingLeft: '40px'} },
						{ label: 'Amount of Cash Grant', name: 'grntIndvCash', col: 3, colTablet: 2, styles: {textAlign: 'left', paddingLeft: '30px'}},
						{ label: 'Amount of Non-cash Assistance', name: 'grntIndvNonCash', col: 2, colTablet: 2, styles: {textAlign: 'left', paddingLeft: '25px'} },
						{ label: 'Actions', col: 1, colTablet: 1, styles: { paddingLeft: '20px' } },
					],
					footerLabels: [
						{ type: 'buttons', col: 12, buttonTitle: 'Add Grant/Assistance', icon: 'add' },
						{ type: 'totalMoney', name: 'grntIndvCash', col: 9, colTablet: 4, label: ' $ 0' },
						{ type: 'totalMoney', name: 'grntIndvNonCash', col: 2, colTablet: 2, label: ' $ 0' },
                    ],
					lineButtons: [
						{ button: 'remove', }
					]
				}
			},
		]
	},
	{  
    title: 'Additional Information',
    collapseClass: 'collapsibleForm',
    isVisible: true,
    isDefaultExpanded: false,
		isOptionalSectionMerge: true,
    sectionId: 5,
    groups: [
      {
        groupId: 2,
        groupType: 'details',
        fields: [
          { 
            label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
            type: 'label', 
            class: 'labelLeftLight' 
          },
        ]
      },
      {
        groupId: 1,
        fields: [
          {
            label: 'Start typing to provide additional information', 
            name: 'additionalInfoTextArea', 
            type: 'textarea', 
            col: 12,
						maxLength: 9000
          }
        ]
      }
    ]
  },
];

export const getDomGraOthSectionTitles = () => {
	let titles = [];
	sections.forEach((section) => {
		if (!titles.includes(section.title)) {
			titles.push(section.title);
		}
	});
	return titles;
};

export const getOrganizationGovernmentLineSection = () => {
	return OrganizationGovernmentLineSection
}

function DomGraOthSection(props) {
	const { isExportForm } = props;
	const { updateCard, REDUX } = usePageFramework(NAV.DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE);
	const [sectionState, setSectionState] = useState('');
	const {isActiveClient, isLoading} = useLoadOrganizer();


	useEffect(() => {
		if (!isExportForm) updateCard();

		setSectionState(sections);
		// eslint-disable-next-line	
	}, []);

	const axcessIdentifiers = [
		{ section: 'Schedules', id: 'Sch I - Grants and Other Assistance to Individuals and Entities in the U.S.' }
	];

	return (
		<>
			{!isLoading && isActiveClient && <FormRendererHelper
				sections={sectionState}
				key={REDUX.DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE}
				formName={REDUX.DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE}
				triggeredEvent={triggeredEvent}
				sectionFieldLogic={sectionFieldLogic}
				identifiers={axcessIdentifiers}
				isExportForm={isExportForm}
			/>}
			{!isLoading && !isActiveClient && <NoActiveClient />}
		</>
	);
}

export default DomGraOthSection;