/* eslint-disable */
import _ from 'lodash';
import { ObjectId } from 'bson';
import { isNotNil } from '@utilities/formData';
import { sortLineItems, sortEntities } from './formSectionSort';

const ommitedFooterKeys = ['name'];
const ommitedCardKeys = ['sectionId', 'title', 'isNewEntity', 'collapseClass', 'entitiesList'];
const ommitedGroupKeys = ['groupId', 'uploadType', 'logicFunction'];
const ommitedFieldKeys = ['name', 'label', 'type', 'overRideText', 'isMoney', 'lookup', 'isEntityName', 'entityNamePriority', 'uniqueName', 'logicFunction', 'htmlLabel', 'hasDivider', 'maxLength'];
const ommitedPrepopKeys = ['name', 'label', 'overRideText', 'validations', 'isMoney'];
const ommitedPrepopFieldKeys = ['name', 'label', 'type', 'overRideText', 'isMoney', 'lookup', 'isEntityName', 'entityNamePriority'];
const ommitedStaticPrepopKeys = ['name', 'overRideText', 'validations', 'isMoney'];

const mergeFooterData = (footer, priorData, footerData) => {
    if (!footerData) {
        return footer;
    }

    const foundFooterData = footerData.find(x => x.name === footer.name);

    if (!foundFooterData) {
        return footer;
    }

    const clonedFooter = _.cloneDeep(footer);

    const filteredFoundFooterData = _.omit(foundFooterData, ommitedFooterKeys);
    _.assign(clonedFooter, filteredFoundFooterData);

    return clonedFooter;
};

const mergeFieldData = (field, priorData, fieldData, index, isPrePopulatedLine, isStaticPrePopLine) => {
    if (!fieldData) {
        return field;
    }

    const foundFieldData = fieldData.find(x => x.name === field.name);

    if (!foundFieldData) {
        return field;
    }

    const clonedField = _.cloneDeep(field);
    const { value, ...foundFieldProps } = foundFieldData;

    let ommitedKeys = [];
    if (isPrePopulatedLine && isStaticPrePopLine) {
        ommitedKeys = ommitedStaticPrepopKeys;
    } else if (index === 0 && isPrePopulatedLine) {
        ommitedKeys = ommitedPrepopKeys;
    } else if (isPrePopulatedLine) {
        ommitedKeys = ommitedPrepopFieldKeys;
    } else {
        ommitedKeys = ommitedFieldKeys;
    }

    const filteredFoundFieldProps = _.omit(foundFieldProps, ommitedKeys);
    _.assign(clonedField, filteredFoundFieldProps);

    // TODO: when value is moved over to 'value' property, change this code to set to property clonedField.value
    isNotNil(value) ? clonedField.default = value : null;

    return clonedField;
};

const mergeGroupData = (group, priorData, groupData, isSorted, isFormLocked) => {
    // Find associated group data by groupId
    const foundFormGroupData = groupData.find(x => x.groupId === group.groupId);
    let hasErrors = false;

    const clonedGroup = _.cloneDeep(group);

    // The group is new and no client form data exists
    if (!foundFormGroupData) {
        return { mergedGroup: clonedGroup, hasErrors };
    }

    // The group exists in client form data. Continue to merge object properties in the fields
    const { fields, lineItems, lineItemDetails, entities, lineSections, bonds, bondsSections } = group;
    const { fields: foundFields, lineItems: foundLineItems, lineItemDetails: foundLineItemDetails, entities: foundEntities, bonds: foundBonds, ...foundGroupProps } = foundFormGroupData;

    const filteredFoundGroupProps = _.omit(foundGroupProps, ommitedGroupKeys);
    _.assign(clonedGroup, filteredFoundGroupProps);

    if (lineItems && lineItemDetails && fields) {

        // Form has been reworked to expect line items
        if (!foundLineItems || !foundLineItemDetails || !foundFields) {
            hasErrors = true;
        }

        const mergedLineItems = foundLineItems?.map((lineItemRow) => {
            // Determine if the row is a prepopulated line item. Merging will change for prepopulated rows vs added rows
            const isPrePopulatedLine = lineItemRow.find((lineField) => lineField && lineField.preGroupName) ? true : false;
            const isStaticPrePopLine = lineItemRow.find((lineField) => lineField && lineField.isStaticPrePopLine) ? true : false;
            const mergedLineItemRow = fields.map((field, index) => mergeFieldData(field, null, lineItemRow, index, isPrePopulatedLine, isStaticPrePopLine));

            return mergedLineItemRow;
        });

        if (lineItemDetails && lineItemDetails.footerLabels && foundLineItemDetails && foundLineItemDetails.footerLabels) {
            const mergedFooterDetails = lineItemDetails.footerLabels.map((footer) => mergeFooterData(footer, null, foundLineItemDetails.footerLabels));
            clonedGroup.lineItemDetails.footerLabels = mergedFooterDetails;
        }

        let clonedLineItems = _.cloneDeep(mergedLineItems)
        if (filteredFoundGroupProps.sorted && filteredFoundGroupProps.sortMethod === "sortIndividualByCompensation"
            && isSorted
            && isFormLocked === true
        ) {
            clonedLineItems = sortLineItems(clonedLineItems);
        }
        clonedGroup.lineItems = clonedLineItems;
    } else if (fields) {
        const mergedFieldData = fields.map((field) => mergeFieldData(field, null, foundFields, null, false));
        clonedGroup.fields = mergedFieldData;
    }

    // Merge section formbuilder data
    if (entities && lineSections) {

        // Form has been reworked to expect entities
        if (!foundEntities) {
            hasErrors = true;
        }

        let clonedEntities = _.cloneDeep(foundEntities)
        if (filteredFoundGroupProps.sorted && filteredFoundGroupProps.sortMethod === "sortIndividualByCompensation"
            && isSorted
            && isFormLocked === true
        ) {
            clonedEntities = sortEntities(clonedEntities);
        }
        const mergedEntityData = clonedEntities?.map((foundEntity) => {
            const { sections, id, ...entityProps } = foundEntity;
            const { mergedFormData: mergedEntity, hasErrors: hasEntityErrors } = mergeFormData(lineSections, null, sections);

            if (hasEntityErrors) {
                hasErrors = hasErrors || hasEntityErrors;
            }

            return { ...entityProps, id: id || new ObjectId().toString(), sections: mergedEntity };
        });

        clonedGroup.entities = mergedEntityData;
        clonedGroup.lineSections = lineSections;
    }

    if (bonds && bondsSections) {

        // Form has been reworked to expect bonds
        if (!foundBonds) {
            hasErrors = true;
        }

        let clonedBonds = _.cloneDeep(foundBonds)

        const mergedBondData = clonedBonds?.map((foundBond) => {
            const { sections, id, ...bondProps } = foundBond;
            const { mergedFormData: mergedBond, hasErrors: hasBondErrors } = mergeFormData(bondsSections, null, sections);

            if (hasBondErrors) {
                hasErrors = hasErrors || hasBondErrors;
            }

            return { ...bondProps, id: id || new ObjectId().toString(), sections: mergedBond };
        });

        clonedGroup.bonds = mergedBondData;
        clonedGroup.bondsSections = bondsSections;
    }

    return { mergedGroup: clonedGroup, hasErrors };
};

const mergeFormData = (formSections, priorData, formData, isSorted, isFormLocked) => {
    if (!formSections) {
        return [];
    }

    let hasErrors = false;

    // Merge client form data into form definition for each section
    const formSection = formSections.map((formCard) => {
        const clonedCard = _.cloneDeep(formCard);

        // Find associated client form data by sectionId
        const foundFormData = formData?.find(x => x.sectionId === formCard.sectionId);

        // The section is new and no client form data exists.
        if (!foundFormData) {
            return clonedCard;
        }

        // The section exists in client form data. Continue to merge object properties in the groups
        const { groups } = formCard;
        const { groups: foundGroups, ...foundCardProps } = foundFormData;

        const filteredFoundCardProps = _.omit(foundCardProps, ommitedCardKeys);
        _.assign(clonedCard, filteredFoundCardProps);

        const mergedGroupData = groups && groups.map((group) => {
            const { mergedGroup, hasErrors: hasGroupError } = mergeGroupData(group, null, foundGroups, isSorted, isFormLocked)

            hasErrors = hasErrors || hasGroupError;
            return mergedGroup;
        });

        isNotNil(mergedGroupData) ? clonedCard.groups = mergedGroupData : null;

        //temp solution to sort ind contractors
        if (clonedCard.sorted && isSorted) {
            const sortByCompensationDec = (groupA, groupB) => Number(groupB.fields[2]?.default) - Number(groupA.fields[2]?.default);
            clonedCard.groups.sort(sortByCompensationDec);
        }

        return clonedCard;
    });

    return { mergedFormData: formSection, hasErrors };
};

export {
    mergeFieldData,
    mergeGroupData,
    mergeFormData,
};