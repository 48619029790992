import { pullFieldValue } from "@utilities/pullFieldValue";


const orgSections = [
        {
            title: 'Address Details',
            isVisible: true,
            sectionId: 1,
            isCollapsed: false,
            isDenseRow: true,
            groups: [
                {
                    groupId: 1,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Street Address', name: 'contribAdd', type: 'freeText', maxLength: 76 },
                        { label: 'City', name: 'contribCity', type: 'freeText', maxLength: 55 },
                        { label: 'State', name: 'contribState', type: 'select', lookup: 'STATES' },
                        { label: 'Zip', name: 'contribZip', type: 'zippostal' },
                    ]
                }
            ]
        },
        {
            title: 'Noncash Contribution Details',
            isVisible: false,
            sectionId: 2,
            isCollapsed: true,
            isDenseRow: true,
            groups: [
                {
                    groupId: 1,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Description of noncash contribution(s)', name: 'noncashPropDescLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
                        {
                            label: 'Please explain',
                            name: 'noncashPropDesc',
                            type: 'textarea',
                            col: 12,
                            maxRows: 3,
                            class: 'fieldTextArea',
                        },
                    ]
                },
                {
                    groupId: 2,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Date received', name: 'noncashPropDateRecLabel', type: 'label', col: 9 },
                        { label: 'Date received', name: 'noncashPropDateRec', type: 'date', col: 3 },
                    ]
                },
            ]
        },
    ];

const generateContributionsSections = ({ setGlobalFormState, basicDataForm })=>{
    return  [
        {
            title: 'Contributions',
            isVisible: false,
            sectionId: 6,
            groups: [
                {
                    groupId: 1,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Approximately how much in total contribution revenue did the organization report for the current year (include both cash & noncash)?', name: 'contribRecTotalLabel', type: 'label', col: 10 },
                        {
                            label: 'Amount', name: 'contibRecTotal', type: 'money', col: 2,
                            logicFunction: [
                                { trigger: 'showContribSecRule1Calc', triggerRelatedForms: { basicData: basicDataForm }, setGlobalFormState: setGlobalFormState },
                                { trigger: 'showContribSecRule1CalcMajor', isDifferentGroup: true },
                                { trigger: 'showMajorContrib', isDifferentGroup: true }
                            ]
                        },
                    ]
                },
                {
                    groupId: 2,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Of that revenue, how much was exclusively related to noncash contributions (including auction items, etc.)?', name: 'contribRecTotalNonCashLabel', type: 'label', col: 10 },
                        {
                            label: 'Amount', name: 'contribRecTotalNonCash', type: 'money', col: 2,
                            logicFunction: [
                                { trigger: 'showNoncashDtlNonRcrdRev', isDifferentGroup: true },
                                { trigger: 'showMajorContrib', isDifferentGroup: true }
                            ]
                        },
                    ]
                },
                {
                    groupId: 3,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        { label: 'Compute Special Rule Value', name: 'contribSecRule1CalcLabel', type: 'label', col: 10, styles: { padding: '0 0 15px 16px' } },
                        { label: '$ 0', name: 'contribSecRule1Calc', type: 'label', class: 'labelTextPriorAmount', isTotal: true, col: 2, styles: { padding: '0 16px 15px 0' }, },
                    ]
                },
                {
                    groupId: 4,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        { label: 'Did the organization record contribution revenue of at least $5,000 from any single contributor/grantor (includes both entities and individuals and both cash & noncash donations)?', name: 'contribSecRule1aLabel', type: 'label', col: 10 },
                        { label: '', name: 'contribSecRule1a', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showMajorContrib', isDifferentGroup: true }, { trigger: 'showMajorContribNonCash', isDifferentGroup: true }] },
                    ]
                },
                {
                    groupId: 5,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        { label: 'Did the organization record contribution revenue of at least $0 from any single contributor/grantor (includes both entities and individuals and both cash & noncash donations)?', name: 'contribSecRule1bLabel', type: 'label', col: 10 },
                        { label: '', name: 'contribSecRule1b', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showMajorContrib', isDifferentGroup: true }, { trigger: 'showMajorContribNonCash', isDifferentGroup: true }] },
                    ]
                },
                {
                    groupId: 6,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        { label: 'Did the organization record contribution revenue of at least $5,000 from any single contributor/grantor (includes both entities and individuals and both cash and noncash donations)?', name: 'contribGenRuleLabel', type: 'label', col: 10 },
                        { label: '', name: 'contribGenRule', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showMajorContrib', isDifferentGroup: true }, { trigger: 'showMajorContribNonCash', isDifferentGroup: true }] },
                    ]
                },
                {
                    groupId: 7,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        { label: 'Were any of the contributions the organization received during the year non cash contributions of art, historical treasures, or other similar assets, or qualified conservation contributions?', name: 'reqSchdChkMbLabel', type: 'label', col: 10 },
                        { label: '', name: 'reqSchdChkMb', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showNoncashDtlNonRcrdRevReq', isDifferentGroup: true }] },
                    ]
                },
            ]
        },
        {
            title: 'Major Contributors',
            isVisible: false,
            hasDenseRow: true,
            sectionId: 1,
            groups: [
                {
                    groupId: 1,
                    isVisible: false,
                    fields: [
                        { label: 'Provide the following information for each contributor whose total contributions are greater than $5,000 during the year:', name: 'headingOne', type: 'label', col: 12 },
                    ]
                },
                {
                    groupId: 2,
                    isVisible: false,
                    fields: [
                        {
                            label: 'Provide the following information for each contributor whose total contributions are greater than $0 during the year:', name: 'headingTwo', type: 'label', col: 12
                        },
                    ]
                },
                {
                    groupId: 3,
                    isVisible: false,
                    fields: [
                        {
                            label: 'Provide the following information for each contributor whose total contributions are greater than $5,000 OR $1,000 if EXCLUSIVELY for religious, charitable, scientific, literary, or education purposes or for the prevention of cruelty to children or animals contributions during the year:',
                            name: 'headingThree',
                            type: 'label',
                            col: 12
                        },
                    ]
                },
                {
                    groupId: 4,
                    isVisible: false,
                    fields: [
                        {
                            label: 'Provide the following information for each contributor whose total contributions are greater than $1,000 if EXCLUSIVELY for religious, charitable, scientific, literary, or education purposes or for the prevention of cruelty to children or animals contributions during the year:',
                            name: 'headingFour',
                            type: 'label',
                            col: 12
                        },
                    ]
                },
                {
                    groupId: 5,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        {
                            label: ' Please select how information will be provided for contributors:',
                            name: 'contribAttachOrFill',
                            type: 'label',
                            col: 9
                        }, // TODO: Update to match what is seen in board of directors
                        { label: 'Select', name: 'contriSelect', type: 'select', lookup: 'TABLE_OR_ATTACH', col: 3, logicFunction: [{ trigger: 'showContributionSection' }] },
                    ]
                },
                {
                    groupId: 7,
                    isVisible: false,
                    groupType: 'lineItem',
                    uploadType: 'drawer',
                    class: 'householdEmploymentUpload',
                    fields: [
                        {
                            label: 'If you would like to attach a listing of contributors instead of completing the table below, please also include detailed information for any non-cash contributions of those contributors (e.g., description, FMV and dates) and exclusive use contributions (e.g., purpose, use, how held and transferee information) of those contributors.',
                            name: 'contribAttachOrFil2Label',
                            type: 'label',
                            col: 9,
                            styles: { paddingTop: 0 }
                        },
                        { type: 'lineButtons', col: 3 }
                    ],
                    lineItems: [],
                    lineItemDetails: {
                        headerLabels: [],
                        footerLabels: [],
                        lineButtons: [
                            { button: 'uploadOnly', uploadSectionName: 'contribAttachOrFil2', viewSectionId: 0 },
                        ]
                    }
                },
                {
                    groupType: 'lineItem',
                    with990DenseRows: true,
                    defaultDenseRowLineItem: true,
                    isVisible: false,
                    groupId: 6,
                    fields: [
                        { label: 'Name', name: 'contribName', type: 'freeText', maxLength: 76 },
                        { label: 'Amount', name: 'contribAmount', type: 'money', isTotal: true, maxLength: 17 },
                        { label: 'Contribution Type(s)', name: 'contribType', type: 'multiSelectDropdown', singleSelect: false, showArrow: true, showCheckbox: true, displayValue: 'name', isObject: true, disablePreSelectedValues: false, lookup: 'CONTRIBUTION_TYPE', logicFunction: [{ trigger: 'showMajorContribNonCashContribType', isDifferentGroup: true }, { trigger: 'showContribName', isDifferentGroup: true }] }, // TODO: Create multiselect component
                        { label: 'Amount', name: 'noncashPropFMV', type: 'money', isTotal: true, isDisable: true },
                        { label: 'Additional Info', name: 'additionalInfoContrib', type: 'viewButton', viewSectionId: 100 },
                        { type: 'lineButtons' },
                    ],
                    lineItems: [],
                    entities: [],
                    lineSections: orgSections,
                    currentEntity: -1,
                    headerLabelsPrintStyles: { gridTemplateColumns: '1.25fr 1.25fr 1.25fr 1.25fr 1fr .5fr' },
                    footerLabelsPrintStyles: { gridTemplateColumns: '3fr 3fr 1.5fr' },
                    lineItemDetails:
                    {
                        headerLabels: [
                            { label: 'Name', name: 'contribName', col: 3, colTablet: 2, styles: { textAlign: 'left', paddingLeft: '45px' } },
                            { label: 'Fiscal Year Contributions (cash and noncash)', name: 'contribAmount', col: 2, colTablet: 1, styles: { textAlign: 'left', paddingLeft: '35px' } },
                            { label: 'Contribution Type(s) (Select all that apply)', name: 'contribType', col: 3, colTablet: 2, styles: { textAlign: 'left', paddingLeft: '30px' } },
                            { label: 'Fair Market Value (or estimate)', name: 'noncashPropFMV', col: 2, colTablet: 1 },
                            { label: 'Additional Info', col: 1, colTablet: 1, styles: { textAlign: 'left', paddingLeft: '5px' } },
                            { label: 'Actions', col: 1, colTablet: 1, align: 'left', }
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Add Another Contributor', icon: 'add' },
                            { type: 'totalMoney', name: 'contribAmount', col: 5, colTablet: 4, label: ' $ 0' },
                            { type: 'totalMoney', name: 'noncashPropFMV', col: 5, colTablet: 4, label: ' $ 0' },
                        ],
                        lineButtons: [
                            { button: 'remove', },
                        ]
                    }
                },
            ]
        },
        {
            title: 'Noncash Contributions',
            isVisible: false,
            hasDivider: true,
            sectionId: 4,
            groups: [
                {
                    groupId: 1,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Did the organization receive any noncash contributions which were NOT recorded on the Trial Balance as revenue?', name: 'noncashDtlNonRcrdRevLabel', type: 'label', col: 10 },
                        { label: '', name: 'noncashDtlNonRcrdRev', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showNoncashDtlNonRcrdRevDesc' }] },
                    ]
                },
                {
                    groupId: 2,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        { label: 'Provide a description of the noncash contributions not recorded as revenue, including a description of the type of property.', name: 'noncashDtlNonRcrdRevDescLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
                        { label: 'Provide description', name: 'noncashDtlNonRcrdRevDesc', type: 'textarea', col: 12, maxRows: 3, class: 'fieldTextArea' },
                    ]
                },
                {
                    groupId: 3,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'How many Forms 8283 did the organization receive for contributions for which it completed Form 8283, Part IV Donee Acknowledgement?', name: 'noncashDtlNo8283Label', type: 'label', col: 10 },
                        { label: 'Form 8283', name: 'noncashDtlNo8283', type: 'total', col: 2 },
                    ]
                },
                {
                    groupId: 4,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Did the organization receive any contributed property that it must hold for at least 3 years?', name: 'noncashDtlHold3YrLabel', type: 'label', col: 10 },
                        { label: '', name: 'noncashDtlHold3Yr', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showNoncashDtlHold3YrExmpt' }] },
                    ]
                },
                {
                    groupId: 5,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        { label: 'Is that 3-year property required to be used for exempt purposes for the entire holding period?', name: 'noncashDtlHold3YrExmptLabel', type: 'label', col: 10 },
                        { label: '', name: 'noncashDtlHold3YrExmpt', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showNoncashDtlHold3YrDesc' }] },
                    ]
                },
                {
                    groupId: 6,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        { label: 'Provide a description of the arrangement for the property that must be held for at least 3 years.', name: 'noncashDtlHold3YrDescLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
                        { label: 'Provide description', name: 'noncashDtlHold3YrDesc', type: 'textarea', col: 12, maxRows: 3, class: 'fieldTextArea' },
                    ]
                },
                {
                    groupId: 7,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Does the organization have a gift acceptance policy that requires the review of any non-standard contributions?', name: 'noncashDtlGiftRvwPolLabel', type: 'label', col: 10 },
                        { label: '', name: 'noncashDtlGiftRvwPol', type: 'radio', lookup: 'YESNO_RADIO', col: 2 },
                    ]
                },
                {
                    groupId: 8,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'Does the organization hire or use third parties or related organizations to solicit, process or sell non-cash contributions?', name: 'noncashDtl3rdPartyLabel', type: 'label', col: 10 },
                        { label: '', name: 'noncashDtl3rdParty', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showNoncashDtl3rdPartyYes' }] },
                    ]
                },
                {
                    groupId: 9,
                    isVisible: false,
                    hasDivider: true,
                    fields: [
                        { label: 'Provide a description of any of these arrangements.', name: 'noncashDtl3rdPartyYesLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
                        {
                            label: 'Provide description',
                            name: 'noncashDtl3rdPartyYes',
                            type: 'textarea',
                            col: 12,
                            maxRows: 3,
                            class: 'fieldTextArea',
                            maxLength: 9000,
                            ...pullFieldValue(['XFDS99M3.4'], true)
                        },
                    ]
                },
                {
                    groupId: 10,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'How much of the noncash revenue was recorded for gifts of services?', name: 'noncashPropGiftsLabel', type: 'label', col: 10 },
                        { label: 'Noncash revenue recorded', name: 'noncashPropGifts', type: 'money', col: 2 },
                    ]
                },
                {
                    groupId: 11,
                    isVisible: true,
                    hasDivider: true,
                    fields: [
                        { label: 'How much of the noncash revenue was recorded for contributions of the use of facilities or equipment (in-kind rent)?', name: 'noncashPropInKindLabel', type: 'label', col: 10 },
                        { label: 'Noncash revenue recorded', name: 'noncashPropInKind', type: 'money', col: 2 },
                    ]
                },
            ]
        },
        {
            title: 'Noncash Contribution Details',
            isVisible: false,
            sectionId: 5,
            groups: [
                {
                    groupId: 1,
                    isVisible: true,
                    fields: [
                        { label: 'For each type of property below received by the organization during the tax year (include donated auction items), check the box and provide the requested information:', name: 'noncashDt', type: 'label', col: 12 },
                    ]
                },
                {
                    groupType: 'lineItem',
                    groupId: 3,
                    hasDivider: true,
                    fields: [
                        { label: 'Other', name: 'noncashDtlItmName', maxLength: 70, type: 'freeText' },
                        { label: 'Count', name: 'noncashDtlItmCnt', maxLength: 14, type: 'total' },
                        { label: 'Type', name: 'noncashDtlItmCntType', type: 'select', lookup: 'CONTRIBUTIONS_ITEM_TYPE' },
                        { label: 'Amount', name: 'noncashDtlItmAmt', type: 'money', isTotal: true },
                        { label: 'Method of determining value', name: 'noncashDtlItmAmtMthd', maxLength: 50, type: 'freeText' },
                    ],
                    prePopulate: [
                        { label: 'Art-Works of art', prePopulateAugments: [{ name: 'artWorksOfArtCnt', currentModifiers: { maxLength: 11 } }, { name: 'artWorksOfArtType' }, { name: 'artWorksOfArtAmt' }, { name: 'artWorksOfArtMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Art-Historical treasures', prePopulateAugments: [{ name: 'artHistoricalTreasuresCnt', currentModifiers: { maxLength: 11 } }, { name: 'artHistoricalTreasuresType' }, { name: 'artHistoricalTreasureAmt' }, { name: 'artHistoricalTreasuresMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Art-Fractional interests', prePopulateAugments: [{ name: 'artFractionalInterestsCnt', currentModifiers: { maxLength: 11 } }, { name: 'artFractionalInterestsType' }, { name: 'artFractionalInterestsAmt' }, { name: 'artFractionalInterestsMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Books & publications', prePopulateAugments: [{ name: 'booksAndPublicationsCnt', currentModifiers: { maxLength: 11 } }, { name: 'booksAndPublicationsType' }, { name: 'booksAndPublicationsAmt' }, { name: 'booksAndPublicationsMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Clothing & household goods', prePopulateAugments: [{ name: 'clothingAndHouseholdGoodsCnt', currentModifiers: { maxLength: 11 } }, { name: 'clothingAndHouseholdGoodsType' }, { name: 'clothingAndHouseholdGoodsAmt' }, { name: 'clothingAndHouseholdGoodsMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Cars & other vehicles', prePopulateAugments: [{ name: 'carsAndOtherVehiclesCnt', currentModifiers: { maxLength: 11 } }, { name: 'carsAndOtherVehiclesType' }, { name: 'carsAndOtherVehiclesAmt' }, { name: 'carsAndOtherVehiclesMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Boats & airplanes', prePopulateAugments: [{ name: 'boatsAndAirplanesCnt', currentModifiers: { maxLength: 11 } }, { name: 'boatsAndAirplanesType' }, { name: 'boatsAndAirplanesAmt' }, { name: 'boatsAndAirplanesMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Intellectual property', prePopulateAugments: [{ name: 'intellectualPropertyCnt', currentModifiers: { maxLength: 11 } }, { name: 'intellectualPropertyType' }, { name: 'intellectualPropertyAmt' }, { name: 'intellectualPropertyMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Securities-Publicly traded', prePopulateAugments: [{ name: 'securitiesPubliclyTradedCnt', currentModifiers: { maxLength: 11 } }, { name: 'securitiesPubliclyTradedType' }, { name: 'securitiesPubliclyTradedAmt' }, { name: 'securitiesPubliclyTradedMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Securities-Closely held stock', prePopulateAugments: [{ name: 'securitiesCloselyHeldStockCnt', currentModifiers: { maxLength: 11 } }, { name: 'securitiesCloselyHeldStockType' }, { name: 'securitiesCloselyHeldStockAmt' }, { name: 'securitiesCloselyHeldStockMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Securities-Partnership, LLC or trust interests', prePopulateAugments: [{ name: 'securitiesPartnershipLLCOrTrustInterestsCnt', currentModifiers: { maxLength: 11 } }, { name: 'securitiesPartnershipLLCOrTrustInterestsType' }, { name: 'securitiesPartnershipLLCOrTrustInterestsAmt' }, { name: 'securitiesPartnershipLLCOrTrustInterestsMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Securities-Other', prePopulateAugments: [{ name: 'securitiesOtherCnt', currentModifiers: { maxLength: 11 } }, { name: 'securitiesOtherType' }, { name: 'securitiesOtherAmt' }, { name: 'securitiesOtherMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Qualified conservation contribution-Historic structures', prePopulateAugments: [{ name: 'qualifiedConservationContributionHistoricStructuresCnt', currentModifiers: { maxLength: 11 } }, { name: 'qualifiedConservationContributionHistoricStructuresType' }, { name: 'qualifiedConservationContributionHistoricStructuresAmt' }, { name: 'qualifiedConservationContributionHistoricStructuresMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Qualified conservation contribution-Other', prePopulateAugments: [{ name: 'qualifiedConservationContributionOtherCnt', currentModifiers: { maxLength: 11 } }, { name: 'qualifiedConservationContributionOtherType' }, { name: 'qualifiedConservationContributionOtherAmt' }, { name: 'qualifiedConservationContributionOtherMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Real estate-Residential', prePopulateAugments: [{ name: 'realEstateResidentialCnt', currentModifiers: { maxLength: 11 } }, { name: 'realEstateResidentialType' }, { name: 'realEstateResidentialAmt' }, { name: 'realEstateResidentialMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Real estate-Commercial', prePopulateAugments: [{ name: 'realEstateCommercialCnt', currentModifiers: { maxLength: 11 } }, { name: 'realEstateCommercialType' }, { name: 'realEstateCommercialAmt' }, { name: 'realEstateCommercialMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Real estate-Other', prePopulateAugments: [{ name: 'realEstateOtherCnt', currentModifiers: { maxLength: 11 } }, { name: 'realEstateOtherType' }, { name: 'realEstateOtherAmt' }, { name: 'realEstateOtherMthd', currentModifiers: { maxLength: 24 } }] },
                        { label: 'Collectibles', prePopulateAugments: [{ name: 'collectiblesCnt', currentModifiers: { maxLength: 11 } }, { name: 'collectiblesType' }, { name: 'collectiblesAmt' }, { name: 'collectiblesMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Food inventory', prePopulateAugments: [{ name: 'foodInventoryCnt', currentModifiers: { maxLength: 11 } }, { name: 'foodInventoryType' }, { name: 'foodInventoryAmt' }, { name: 'foodInventoryMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Drugs & medical supplies', prePopulateAugments: [{ name: 'drugsAndMedicalSuppliesCnt', currentModifiers: { maxLength: 11 } }, { name: 'drugsAndMedicalSuppliesType' }, { name: 'drugsAndMedicalSuppliesAmt' }, { name: 'drugsAndMedicalSuppliesMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Taxidermy', prePopulateAugments: [{ name: 'taxidermyCnt', currentModifiers: { maxLength: 11 } }, { name: 'taxidermyType' }, { name: 'taxidermyAmt' }, { name: 'taxidermyMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Historical artifacts', prePopulateAugments: [{ name: 'historicalArtifactsCnt', currentModifiers: { maxLength: 11 } }, { name: 'historicalArtifactsType' }, { name: 'historicalArtifactsAmt' }, { name: 'historicalArtifactsMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Scientific specimens', prePopulateAugments: [{ name: 'scientificSpecimensCnt', currentModifiers: { maxLength: 11 } }, { name: 'scientificSpecimensType' }, { name: 'scientificSpecimensAmt' }, { name: 'scientificSpecimensMthd', currentModifiers: { maxLength: 24 } },] },
                        { label: 'Archeological artifacts', prePopulateAugments: [{ name: 'archeologicalArtifactsCnt', currentModifiers: { maxLength: 11 } }, { name: 'archeologicalArtifactsType' }, { name: 'archeologicalArtifactsAmt' }, { name: 'archeologicalArtifactsMthd', currentModifiers: { maxLength: 24 } },] }
                    ],
                    prePopulateControls: ['label', 'number', 'select', 'money', 'freeText'],
                    prePopulateIndexes: [{ currentIndex: 1 }, { currentIndex: 2 }, { currentIndex: 3 }, { currentIndex: 4 },],
                    lineItems: [],
                    entities: [],
                    headerLabelsPrintStyles: { gridTemplateColumns: '2fr 1.75fr 1.75fr 2fr 2fr' },
                    footerLabelsPrintStyles: { gridTemplateColumns: '4fr 1fr' },
                    lineItemDetails:
                    {
                        headerLabels: [
                            { label: 'Types of Property', col: 3, colTablet: 2 },
                            { label: 'Contributions/Items Count', col: 2, colTablet: 1 },
                            { label: 'Contributions/Items Type', col: 2, colTablet: 2 },
                            { label: 'Total Amount Reported as Revenue', col: 2, colTablet: 1 },
                            { label: 'Method of determining value (ex. cost, selling price, sale of comparable properties, etc.)', col: 2, colTablet: 2 },
                        ],
                        footerLabels: [
                            { type: 'buttons', col: 12, buttonTitle: 'Add Other Property Type', icon: 'add' },
                            { type: 'totalMoney', name: 'noncashDtlItmAmt', col: 9, colTablet: 5, label: ' $ 0' },
                        ],
                        lineButtons: []
                    }
                },
            ]
        },
        {  
            title: 'Additional Information',
            collapseClass: 'collapsibleForm',
            isVisible: false,
            isDefaultExpanded: false,
            isOptionalSectionMerge: true,
            triggered: 'showContributionsAddInfo',
            sectionId: 7,
            groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                        { 
                            label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
                            type: 'label', 
                            class: 'labelLeftLight' 
                        },
                    ]
                },
                {
                    groupId: 1,
                    fields: [
                        {
                            label: 'Start typing to provide additional information', 
                            name: 'additionalInfoTextArea', 
                            type: 'textarea', 
                            col: 12,
                            maxLength: 9000
                        }
                    ]
                }
            ]
        },
    ];
}


export {
    generateContributionsSections
}