import * as EVENT from '@utilities/constants/triggerKeys';

// const triggeredEvent = (trigger, event, options) => {
//   switch (trigger) {
//     default:
//       break;
//   }
// };

const setRelOrgEntity = (options) => {
    const { group, field, index } = options;
    const isDisregardedEntity = field.default === EVENT.KEY_DISREGARDED_ENTITY;
    const isTaxExempt = field.default === EVENT.KEY_TAX_EXEMPT;
    const isPartnership = field.default === EVENT.KEY_PARTNERSHIP;
    const isCorpTrust = field.default === EVENT.KEY_CORP;
    const isUnrelPartnershp = field.default === EVENT.KEY_UNREALATED_PARTNERSHIP;
    let visibleSectionIndex = 0;

    group.entities[index].sections[0].isVisible = isDisregardedEntity;
    group.entities[index].sections[1].isVisible = isTaxExempt;
    group.entities[index].sections[2].isVisible = isPartnership;
    group.entities[index].sections[3].isVisible = isCorpTrust;
    group.entities[index].sections[4].isVisible = isUnrelPartnershp; 

    visibleSectionIndex = isDisregardedEntity ? 0 : isTaxExempt ? 1 : isPartnership ? 2 : isCorpTrust ? 3 : isUnrelPartnershp ? 4 : 0; 

    const orgNameGroup = group.entities[index].sections[visibleSectionIndex].groups[0];
    const orgNameField = orgNameGroup ? orgNameGroup.fields[1] : null;

    setOrgName({group: orgNameGroup, parentGroup: group, parentIndex: index, field: orgNameField});
}

const setSchedRProvState = (options) => {
    const { section, field } = options;
    const isUnitedStates = field.default === EVENT.KEY_UNITED_STATES || field.default.trim() === EVENT.KEY_EMPTY;
    const isCanada = field.default === EVENT.KEY_CANADA;
    const isOther = !isUnitedStates && !isCanada;

    section.groups[2].fields[4].isVisible = isUnitedStates;
    section.groups[2].fields[6].isVisible = isOther;
    section.groups[2].fields[5].isVisible = isCanada;
}

const showLegalDmcl = (options) => {
  const { section, field } = options;

  section.groups[4].fields[2].isVisible = field.default === EVENT.KEY_YES;
  section.groups[4].fields[3].isVisible = field.default === EVENT.KEY_NO;
}

const setOrgName = (options) => {
  const { group, parentGroup, parentIndex, field } = options;

  let orgName = '';

  group?.fields?.forEach((groupField) => {
    if (groupField.name === field?.name) {
      orgName = groupField.default;
    }
  });

  if (orgName && orgName.length > 20) {
    orgName = `${orgName.substring(0, 17)}...`;
  }

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'relorgName') {
      lineItemField.default = `${orgName || ''}`;
    }
  });
}

const showrelOrgTaxExmptPCS = (options) => {
  const { section, field } = options;

  section.groups[6].isVisible = field.default === EVENT.KEY_501C3_NUM;
}

const showrelOrgTaxExmptTrnsacts = (options) => {
  const { section, field } = options;

  section.groups[10].isVisible = field.default === EVENT.KEY_YES;
}

const showrelOrgCorpTrustTrnsacts = (options) => {
  const { section, field } = options;
  
  section.groups[11].isVisible = field.default === EVENT.KEY_YES;
}

const showrelOrgPartshipTrnsacts = (options) => {
  const { section, field } = options;
  
  section.groups[14].isVisible = field.default === EVENT.KEY_YES;
}
  
  
  const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        case 'setRelOrgEntity':
            setRelOrgEntity(options);
            break;
        case 'setSchedRProvState':
            setSchedRProvState(options);
            break;
        case 'showLegalDmcl':
            showLegalDmcl(options);
            break;
        case 'setOrgName':
            setOrgName(options);
            break;
        case 'showrelOrgTaxExmptPCS':
          showrelOrgTaxExmptPCS(options);
          break;
        case 'showrelOrgTaxExmptTrnsacts':
          showrelOrgTaxExmptTrnsacts(options);
          break;
        case 'showrelOrgCorpTrustTrnsacts':
          showrelOrgCorpTrustTrnsacts(options);
          break;
        case 'showrelOrgPartshipTrnsacts':
          showrelOrgPartshipTrnsacts(options);
          break;
        default:
        break;
    }
  };
  
  export {
    //   triggeredEvent,
      sectionFieldLogic
  };