// common modules
import _ from 'lodash';

// custom modules
import { dashboard as defaultDashboard } from '@utilities/constants/dashboard';
import * as CARDSTATUS from '@utilities/constants/cardStatus';

const calculateDashboardSummary = ( dashboard ) => {
    const dashboardData = dashboard || defaultDashboard;
  const getTodosCounter = (dashboardData) => {
    const requiredForms = dashboardData?.filter((category) => category?.title !== 'Opportunity Services')
      .map((cat) => cat?.cards.filter((card) => card?.isRequired)).flat(2) || [];

    const completed = dashboardData?.filter((category) => category?.title !== 'Opportunity Services')
      .map((cat) => cat?.cards?.filter((card) => card?.isRequired && card?.statusId === CARDSTATUS.COMPLETED)).flat(2) || [];

    return `${completed.length} of ${requiredForms.length}` || '0 of 2';
  };

  
  return dashboardData && dashboardData?.length > 0
    ? { // dashboard exists already
      summary: getTodosCounter(dashboard),
      dashboard,
    }
    : {
        summary: '0 of 2',
        dashboard: defaultDashboard,
    };
};

export {
    calculateDashboardSummary,
}

