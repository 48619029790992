// common modules
import { Button } from 'react-md';
import { useState } from "react";
import {
    Dialog,
    DialogHeader,
    DialogTitle,
    DialogContent,
    DialogFooter,
} from "@react-md/dialog";
import {
    Form,
    TextField,
    FormMessage
  } from "@react-md/form";

// custom modules
import '@components/restfulTable/CreateResourceDialog.css';

function CreateResourceDialog(props) {
    const { visible, resourceName, resourceProps, onCancel, onConfirm } = props;
    const [ data, setData ] = useState({});

    const changeFieldValue = (event, key) => {
        const { value } = event.target;

        setData((prev) => ({ ...prev, [key]: value }));
    };

    const hasMissingData = resourceProps.filter((prop) => prop.required).map((prop) => !data[prop.key]).some(Boolean);

    return (
        <Dialog
            id="create-resource-dialog"
            role="alertdialog"
            visible={visible}
            onRequestClose={() => { }}
            aria-labelledby="create-resource-dialog-title"
            data-testid="create-resource-dialog-title"
        >
            <DialogHeader>
                <DialogTitle>{`Create ${resourceName}`}</DialogTitle>
            </DialogHeader>
            <DialogContent>
                <Form>
                    {resourceProps.filter((props) => props.create.show).map((col) => (
                        <div key={`text-field-${col.key}-container`}>
                            <TextField 
                                id={`text-field-${col.key}`}
                                key={`text-field-${col.key}`}
                                type="text"
                                label={col.text}
                                required={col.create.required ? true : false}
                                onChange={(event) => changeFieldValue(event, col.key)}
                                disabled={col.create.readOnly ? true : false}>
                            </TextField>
                            <FormMessage id={`text-field-${col.key}-message`} error>{col.create.required && !data[col.key] ? 'This field is required' : ''}</FormMessage>
                        </div>
                    ))}
                </Form>
            </DialogContent>
            <DialogFooter>
                <Button id="dialog-submit" onClick={() => onConfirm(data)} disabled={hasMissingData}>Create</Button>
                <Button id="dialog-close" onClick={() => onCancel()}>Close</Button>
            </DialogFooter>
        </Dialog>
    );
}

export default CreateResourceDialog;