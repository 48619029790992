import { Tooltipped } from 'react-md';
import { HoverModeProvider } from '@react-md/utils';
import NumberFormat from 'react-number-format';
import { parseToUSDate } from '../fields/utils/formatDate';
import { getDropDownValue } from '../../utils/getDropdownValue';
import moneyFormatter, { parseMoneyToNumber } from '@utilities/moneyFormatter';
import SubText from '../subText';

const fnSelf = (x) => x;
const formatFunction = (type) => {
    const fns = {
        date: (x) => parseToUSDate(x),
    };

    return fns[type] || fnSelf;
};

function LabelText(props) {
    const { field, colspan, group, index } = props;
    const groupLineItem = group.lineItems ? group.lineItems[index][0] : null; 
    const formatFn = formatFunction(field?.labelFormat);
    const fieldValue = field.lookup ?  getDropDownValue(field) : formatFn(field.default);
    const calcLabelWidthStyle = (group) => {
        if (field.name === '') return;
        const headerLabelCols = group.isRestrictAdd ? group.lineItemDetails?.headerLabels?.length : group.lineItemDetails?.headerLabels?.length - 1;
        const width = 650 / (headerLabelCols);
        return {width: width, ...field.styles};
    };
    const styles = group.hasTableManagerTabs || group.entityPlaceHolder ? calcLabelWidthStyle(group)
        : field.styles ? field.styles 
        : field.name === 'aboutYourYearForeignMattersLabel' ? { paddingLeft: 0 } 
        : null;
    const isOccupantSummary = field.name === 'occupantSummary';

    const renderValue = () => {
        if (isOccupantSummary && Array.isArray(fieldValue)) {
            if (fieldValue.length > 1) {
                const firstValue = fieldValue[0]
                let toolTipValue = fieldValue.slice(1);
                const length = toolTipValue.length;
                toolTipValue = <div>{toolTipValue.map((value, index) => <p key={index}>{value}</p>)}</div>;

                return (
                    <>
                        {field.isTotal ? moneyFormatter(parseMoneyToNumber(firstValue)) : firstValue}
                        <HoverModeProvider>
                            <Tooltipped 
                                id={`lbl-${field.name}`} 
                                position='above' 
                                tooltip={toolTipValue} 
                                className='pracDashOnHover'
                            >
                                <p className='moreOccupants'>(+{length} more)</p>
                            </Tooltipped>
                        </HoverModeProvider>
                    </>
                )
            }
        }

        const isOverridden = field.overRideText && fieldValue === field.overRideText;
        if (field.isMaskOnBlur && !isOverridden) {
            return ('' + fieldValue).slice(0, -4).replace(/./g, 'X') + ('' + fieldValue).slice(-4);
        }
        
        if (field.isTotal && !group.currencySymbol && field.isMoney !== false) {
            if (fieldValue) {
                return moneyFormatter(parseMoneyToNumber(fieldValue));
            } else {
                return moneyFormatter(parseMoneyToNumber(field.label));
            }
        } else {
            if (field.isTotal) {
                return (
                    <NumberFormat
                        value={fieldValue || field.label}
                        displayType='text'
                        thousandSeparator={true}
                    />
                )
            } else {
                return fieldValue || field.label || field.htmlLabel;
            }
        }
    }

    // const setLabelClicked = () => {
    //     if (field.triggered) {
    //         fns.triggered(field.triggered, field);
    //         // fns.saveForm(field);
    //     }
    // };

    return (
        <div 
            className={
                groupLineItem?.notApplicable && field.isTotal ? 'labelMoneyDisabled' :
                    groupLineItem?.notApplicable ? 'labelDisabled' :
                        field.inputState === 1 && field.isTotal ? 'labelPriorMoney' :
                            field.isTotal ? 'labelTextMoney' :
                                field.inputState === 1 ? 'labelPriorData' :
                                    field.class === 'labelRequired' ? 'labelRequired' : 
                                        field.class === 'labelState' ? 'labelState' : 
                                            field.class === 'labelLeft' ? 'labelLeft' :
                                                field.class ? field.class : 'labelText'
            } 
            style={styles}
            colSpan={colspan} 
            key={`cell-${field.name}`}
        >
            <div 
                id={`lbl-${field.name}`} 
                name={field.name} 
                key={`lbl-${field.name}`} 
            >
                {renderValue()}
            </div>
            <SubText subText={field?.subText} />
        </div>     
    );
}

export default LabelText;
