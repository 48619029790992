import React, { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import * as NAV from '@utilities/constants/navigation';
import { triggeredEvent, sectionFieldLogic } from './entryInterviewLogicTrigger';

let sections = [];
//TODO: need to refactor this to get the updated formstate titles
export const getEntryTitles = () => {
	let titles = [];
	sections.forEach((section) => {
		if (!titles.includes(section.title)) {
			titles.push(section.title);
		}
	});
	return titles;
};

export const getEntrySections = () => {
	return sections;
};

function EntryInterview(props) {
    const { currentIndex, isExportForm } = props;
    const { updateCard, REDUX, selectState, setGlobalFormState } = usePageFramework(NAV.ENTRY_INTERVIEW);
    const [sectionState, setSectionState] = useState('');
    const contribForm = selectState(REDUX.CONTRIBUTIONS);
    const basicDataForm = selectState(REDUX.BASIC_DATA);
    const supFinStmntsForm = selectState(REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS);   
    const internalSetupForm = selectState(REDUX.INTERNAL_SETUP); 

    useEffect(() => {
        if (!isExportForm) updateCard();
        sections = [
            {
                title: 'Page 1',
                isVisible: true,
                sectionId: 1,
                isSectionHeaderHidden: true,
                groups: [
                    {
                        groupId: 1,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Have the organization\'s by-laws, articles of incorporation, or trust documents changed during the year?',
                                name: 'govBodyChangeGovDocsLabel',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'govBodyChangeGovDocs',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                logicFunction: [
                                    {trigger: 'showBDgovDocAtch', triggerRelatedForms: {basicData: basicDataForm}, setGlobalFormState: setGlobalFormState }
                                ]
                            }    
                        ]
                    },
                    {
                        groupId: 2,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Have you received any IRS notices that you have not already provided to CLA?',
                                name: 'irsNoteLabel',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'irsNote',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                logicFunction: [
                                    {trigger: 'showBDltrDetermineAtt', triggerRelatedForms: {basicData: basicDataForm}, setGlobalFormState: setGlobalFormState }
                                ]
                            }
                        ]
                    },
                    {
                        groupId: 3,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization receive any contributions (cash or noncash) during the year?',
                                name: 'contribRecLabel',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'contribRec',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                logicFunction: [
                                    {trigger: 'setCardRequired', requiredCards: [REDUX.CONTRIBUTIONS]},
                                    {trigger: 'showContributionsSection', triggerRelatedForms: {contributions: contribForm}, setGlobalFormState: setGlobalFormState }
                                ]
                            }
                        ]
                    },
                    {
                        groupId: 4,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization engage in direct or indirect political campaign activities, or lobbying during the year?',
                                name: 'newQuestion4Label',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'newQuestion4',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                logicFunction: [{trigger: 'setCardRequired', requiredCards: [REDUX.POLITICAL_CAMPAIGN_AND_LOBBYING]}]
                            }
                        ]
                    },
                    {
                        groupId: 5,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization maintain any donor-advised funds for any accounts where donors have the right to provide advice on the distribution or investment of amounts in such funds or accounts?',
                                name: 'reqSchdChkDiLabel',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'reqSchdChkDi',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                triggered: 'setSchedDRequired',
                                logicFunction: [
                                    {trigger: 'setCardRequired', triggerRelatedForms:{internalSetupForm: internalSetupForm}, requiredCards: [REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS]},
                                    {trigger: 'showSupFinDonorAdvised', triggerRelatedForms: {supplementalFinancialStatements: supFinStmntsForm}, setGlobalFormState: setGlobalFormState }
                                ]
                            }
                        ]
                    },
                    {
                        groupId: 6,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization receive or hold a conservation easement, including easements to preserve open space, the environment, historic land areas, or historic structures?',
                                name: 'reqSchdChkDiiLabel',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'reqSchdChkDii',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                triggered: 'setSchedDRequired',
                                logicFunction: [
                                    {trigger: 'setCardRequired', triggerRelatedForms:{internalSetupForm: internalSetupForm}, requiredCards: [REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS]},
                                    {trigger: 'showConservativeEasements', triggerRelatedForms: {supplementalFinancialStatements: supFinStmntsForm}, setGlobalFormState: setGlobalFormState }
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Page 2',
                isVisible: false,
                sectionId: 2,
                isSectionHeaderHidden: true,
                isDefaultExpanded: true,
                groups: [
                    {
                        groupId: 1,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization maintain collections of works of art, historical treasures, or other similar assets?',
                                name: 'reqSchdChkDiiiLabel',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'reqSchdChkDiii',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                triggered: 'setSchedDRequired',
                                logicFunction: [
                                    {trigger: 'setCardRequired', triggerRelatedForms:{internalSetupForm: internalSetupForm}, requiredCards: [REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS]},
                                    {trigger: 'showArtHistoricalTreasures', triggerRelatedForms: {supplementalFinancialStatements: supFinStmntsForm}, setGlobalFormState: setGlobalFormState }
                                ]
                            }
                        ]
                    },
                    {
                        groupId: 2,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization report an amount for escrow or custodial account liability, serve as a custodian for amounts not listed; or provide credit counseling, debt management, credit repair, or debt negotiation services?',
                                name: 'reqSchdChkDivLabel',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'reqSchdChkDiv',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                triggered: 'setSchedDRequired',
                                logicFunction: [
                                    {trigger: 'setCardRequired', triggerRelatedForms:{internalSetupForm: internalSetupForm}, requiredCards: [REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS]},
                                    {trigger: 'showEscrowCustodialArrangements', triggerRelatedForms: {supplementalFinancialStatements: supFinStmntsForm}, setGlobalFormState: setGlobalFormState }
                                ]
                            }
                        ]
                    },
                    {
                        groupId: 3,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization, directly or through a related organization, hold assets in donor-restricted endowments or in quasi endowments?',
                                name: 'reqSchdChkDvLabel',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'reqSchdChkDv',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                triggered: 'setSchedDRequired',
                                logicFunction: [
                                    {trigger: 'setCardRequired', triggerRelatedForms:{internalSetupForm: internalSetupForm}, requiredCards: [REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS]},
                                    {trigger: 'showEndowmentsSection', triggerRelatedForms: {supplementalFinancialStatements: supFinStmntsForm}, setGlobalFormState: setGlobalFormState }
                                ]
                            }
                        ]
                    },
                    {
                        groupId: 4,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization maintain an office, employees, or agents outside of the United States?',
                                name: 'reqSchdChkFLabel',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'reqSchdChkF',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                            }
                        ]
                    },
                    {
                        groupId: 5,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization engage in any transactions with a foreign organization or foreign individual during the year (i.e., grant making, foreign investments or program service activities)?',
                                name: 'newQuestion11Label',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'newQuestion11',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                logicFunction: [{trigger: 'setCardRequired', requiredCards: [REDUX.FOREIGN_ACTIVITIES]}]
                            }
                        ]
                    },
                    {
                        groupId: 6,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization either use a professional fundraiser, hold any fundraising events (dinners, golf outings, etc), or conduct gaming?',
                                name: 'newQuestion12Label',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'newQuestion12',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                logicFunction: [{trigger: 'setCardRequired', requiredCards: [REDUX.FUNDRAISING_AND_GAMING]}]
                            }
                        ]
                    },
                ]
            },
            {
                title: 'Page 3',
                isVisible: false,
                sectionId: 3,
                isSectionHeaderHidden: true,
                isDefaultExpanded: true,
                groups: [
                    {
                        groupId: 1,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization make any grants or other assistance to a domestic organization, domestic government or domestic individual for more than $5,000?',
                                name: 'newQuestion13Label',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'newQuestion13',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                logicFunction: [{trigger: 'setCardRequired', requiredCards: [REDUX.DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE]}]
                            }
                        ]
                    },
                    {
                        groupId: 2,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization have a tax-exempt bond issue with an outstanding principal amount of more than $100,000 as of the last day of the year that was issued after December 31, 2002?',
                                name: 'reqSchdChkKaLabel',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'reqSchdChkKa',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                logicFunction: [{trigger: 'setCardRequired', requiredCards: [REDUX.TAX_EXEMPT_BONDS]}],
                                prior: true,
                                isPriorEditable: true,
                                priorCompareOptions: {
                                  radioShouldLookup: true
                                },
                                axcess: {
                                  pull: {
                                    fields: ['XFDS99P4.24'],
                                    fn: 'checkboxToRadioPull'
                                  }
                                }
                            }
                        ]
                    },
                    {
                        groupId: 3,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization engage in any transaction (except compensation) with an officer, director, trustee, key employee, creator/founder, substantial contributor, a family member of  any of these people or to a 35% controlled entity of any of these people (grants, loans, business relationship, etc)?',
                                name: 'newQuestion15Label',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'newQuestion15',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                logicFunction: [{trigger: 'setCardRequired', requiredCards: [REDUX.INTERESTED_PERSONS_TRANSACTIONS]}]
                            }
                        ]
                    },
                    {
                        groupId: 4,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization liquidate, terminate, dissolve and cease operations or disposed of 25% or more of it\'s assets?',
                                name: 'reqSchdChkNiLabel',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'reqSchdChkNi',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                logicFunction: [{trigger: 'setCardRequired', requiredCards: [REDUX.LIQUIDATION_AND_DISPOSITION_OF_ASSETS]}]
                            }
                        ]
                    },
                    {
                        groupId: 5,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Did the organization have an ownership or other relationship with any other entity?',
                                name: 'newQuestion17Label',
                                type: 'label',
                                col: 8
                            },
                            {
                                name: 'newQuestion17',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 4,
                                logicFunction: [{trigger: 'setCardRequired', requiredCards: [REDUX.RELATED_ORGANIZATIONS_SCHED_R]}],
                                prior: true,
                                isPriorEditable: true,
                                priorCompareOptions: {
                                  radioShouldLookup: true
                                },
                                axcess: {
                                  pull: {
                                    fields: [
                                      'XFDS99P4.39',
                                      'XFDS99P4.41', 
                                      'XFDS99P4.43', 
                                      'XFDS99R2.0', 
                                      'XFDS99R3.0', 
                                      'XFDS99R4.0'
                                    ],
                                    fn: 'anyValidCheckboxOrFieldToRadioPull'
                                  }
                                }
                            }
                        ]
                    },
                ]
            },
        ];
        setSectionState(sections);
        // eslint-disable-next-line
    }, []);


    const axcessIdentifiers = [
      { section: 'Return Type', id: 'Form 990 - Exempt Organization Return' },
      { section: 'Schedules', id: 'Sch R - Related Organizations and Unrelated Partnerships' },
    ];

    return <>
        <FormRendererHelper 
          sections={sectionState} 
          key="entryInterviewForm" 
          formName={REDUX.ENTRY_INTERVIEW} 
          identifiers={axcessIdentifiers} 
          passFromEntity={true} 
          currentIndex={currentIndex} 
          triggeredEvent={triggeredEvent} 
          sectionFieldLogic={sectionFieldLogic}
          isExportForm={isExportForm} />
    </>;

}

export default EntryInterview;
