export const form = {
  sections: null,
  set data (value) {
    this.sections = value;
  },
  get formSections() {
    if (this.sections) {
      return this.sections;
    }
    return null;
  }
}