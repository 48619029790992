import React, { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { triggeredEvent } from './programsLogicTrigger';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

const programsActivitiesFields = [
  {
    label:
      'Did the organization undertake any new, significant program activities during the year?',
    name: 'newProgActLabel',
    type: 'label',
    col: 9,
  },
  {
    name: 'newProgAct',
    type: 'radio',
    triggered: 'showProgramActivities',
    lookup: 'YESNO_RADIO',
    col: 3,
  },
];
const programsServicesFields = [
  {
    label:
      'Did the organization cease or make significant changes to any existing program services?',
    name: 'ceaseChangeServLabel',
    type: 'label',
    col: 9,
  },
  {
    name: 'ceaseChangeServ',
    type: 'radio',
    triggered: 'showProgramServices',
    lookup: 'YESNO_RADIO',
    col: 3,
  },
];

const commentFields = (fieldName, fieldLabel) => {
  return [
    {
      label: fieldLabel,
      name: fieldName + 'Label',
      type: 'label',
      col: 9,
      class: 'labelTitleTextArea',
    },
    {
      label: 'Please explain',
      placeholder: 'Please explain',
      type: 'textarea',
      col: 12,
      maxRows: 3,
      class: 'fieldTextArea',
      name: fieldName,
    },
  ];
};

const sections = [
  {
    title: 'Mission Statement',
    isVisible: true,
    sectionId: 1,
    hasDenseRow: true,
    groups: [
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe organization’s mission statement as adopted by the organization’s governing body:',
            name: 'missionStmtLabel',
            type: 'label',
            col: 9,
            class: 'labelTitleTextArea',
          },
          {
            name: 'missionStmt',
            type: 'textarea',
            label: 'Please explain',
            placeholder: 'Please explain',
            col: 12,
            class: 'fieldTextArea',
            maxLength: 9000,
            prior: true,
            isPriorEditable: true,
            separator: '\n',
            axcess: {
              pull: {
                fields: ['XFDSMSTM.0', 'XFDS99O1.4', 'XFDS99O1.5'],
                fn: 'programsMSPull',
                populateMultiple: true,
                separator: '\n',
              },
            },
          },
        ],
      },

      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [...programsActivitiesFields],
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        fields: commentFields('newProgActDesc', 'Describe the program(s):'),
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: false,
        fields: [...programsServicesFields],
      },
      {
        groupId: 4,
        isVisible: false,
        hasDivider: false,
        fields: commentFields('ceaseChangeServDesc', 'Describe the change(s):'),
      },
    ],
  },
  {
    title: 'Program Service Accomplishments',
    isVisible: true,
    sectionId: 2,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            htmlLabel: (
              <React.Fragment key={'program-service-accomp'}>
                <ul key={'program-service-listOne'} className="discList">
                  <li key={'program-service-list-1'} className="discListItem">
                    Describe program service accomplishments through specific,
                    quantitative measurements.
                  </li>
                  <li key={'program-service-list-2'} className="discListItem">
                    Describe the activity's objective, for both this time period
                    and the longer-term goal.
                  </li>
                  <li key={'program-service-list-3'} className="discListItem">
                    Give reasonable estimates for any statistical information if
                    exact figures are not available. Indicate that this
                    information is estimated.
                  </li>
                  <li key={'program-service-list-4'} className="discListItem">
                    Be clear, concise, and complete in the description.
                  </li>
                  <li key={'program-service-list-5'} className="discListItem">
                    REMEMBER — This is the organization's opportunity to tell
                    the story, and to justify (to the IRS, public, potential
                    donors, etc.) the organization's status as tax-exempt.
                  </li>
                </ul>
              </React.Fragment>
            ),
            name: 'listOne',
            type: 'label',
            col: 12,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label:
              'Provide the following information for each major program service activity:',
            name: 'progServAccomp',
            type: 'label',
            col: 12,
            styles: { padding: '0 0 10px 0', marginTop: '-15px' },
          },
        ],
      },
      {
        groupType: 'lineItem',
        groupId: 3,
        isVisible: true,
        axcessGroup: {
          pull: {
            fromSections: ['Other Program Services'],
            fieldPrefixes: ['XFDSOOPS'],
            postPopulateFn: 'threeLargestProgramAccomplishments',
            postPopulateMetadata: {
              descriptionFieldName: 'progActDesc',
              threeLargestWorksheetData: {
                form990Identifier: {
                  section: 'Return Type',
                  id: 'Form 990 - Exempt Organization Return',
                },
                form990WorksheetSectionName:
                  'Three Largest Program Service Accomplishments',
                threeLargestFieldKeys: [
                  'XFDSPSSA.24',
                  'XFDSPSSA.25',
                  'XFDSPSSA.26',
                ],
              },
              schOWorksheetData: {
                schOIdentifier: {
                  section: 'Schedules',
                  id: 'Sch O - Supplemental Information for Form 990',
                },
                schOWorksheetSectionName: 'Schedule O Explanations',
                schOTypeKey: 'XFDS99O1.5',
                schOValueKey: 'XFDS99O1.4',
              },
            },
          },
        },
        fields: [
          {
            label: 'Program Expenses',
            name: 'progActExp',
            type: 'money',
            isTotal: true,
          },
          {
            label: 'Program Grants',
            name: 'progActGrants',
            type: 'money',
            isTotal: true,
          },
          {
            label: 'Program Revenue',
            name: 'progActRev',
            type: 'money',
            isTotal: true,
          },
          { type: 'lineButtons' },
          {
            name: 'progActDesc',
            type: 'textarea',
            label: 'Program Accomplishments',
            placeholder: 'Please type to add a description of your program',
            col: 12,
            isSyncFieldValue: true,
            class: 'fieldTextArea',
            maxLength: 9000,
            maxRows: -1,
            prior: true,
            isPriorEditable: true,
            pullCalculation: {
              keyIdentifiers: ['XFDSOOPS.0'],
              fn: 'joinTextOnNewLinePull',
            },
          },
        ],
        lineItems: [],
        lineSectionStyles: { marginBottom: '-70px' },
        entities: [],
        subWorkSheets: [],
        currentEntity: -1,
        headerLabelsPrintStyles: { gridTemplateColumns: '2fr 2fr 1.5fr .5fr' },
        footerLabelsPrintStyles: {
          gridTemplateColumns: '.25fr .5fr 1.25fr 1fr',
        },
        lineItemDetails: {
          headerLabels: [
            { label: 'Program Expenses', col: 4, colTablet: 2 },
            { label: 'Program Grants', col: 4, colTablet: 2 },
            { label: 'Program Revenue', col: 3, colTablet: 2 },
            { label: 'Actions', col: 1, colTablet: 2, align: 'left' },
            { label: '', col: 12, colTablet: 8 },
          ],
          footerLabels: [
            {
              type: 'buttons',
              col: 12,
              buttonTitle: 'Add Another Program',
              icon: 'add',
            },
            { type: 'label', label: 'Total', col: 1, colTablet: 2 },
            {
              type: 'totalMoney',
              name: 'progActExp',
              col: 3,
              colTablet: 2,
              label: '$ 0',
            },
            {
              type: 'totalMoney',
              name: 'progActGrants',
              col: 4,
              colTablet: 2,
              label: '$ 0',
            },
            {
              type: 'totalMoney',
              name: 'progActRev',
              col: 3,
              colTablet: 2,
              label: '$ 0',
            },
          ],
          lineButtons: [{ button: 'remove' }],
        },
      },
      {
        groupType: 'lineItem',
        groupId: 4,
        isVisible: false,
        // RETAINING THIS IN CASE NEEDED IN THE FUTURE
        // axcessGroup: {
        //   pull: {
        //     fromSections: ['Other Program Services'],
        //     fieldPrefixes: ['XFDSOOPS'],
        //     postPopulateFn: 'threeLargestProgramAccomplishments',
        //     postPopulateMetadata: {
        //       descriptionFieldName: 'progActDesc',
        //       threeLargestWorksheetData: {
        //         form990Identifier: {
        //           section: 'Return Type',
        //           id: 'Form 990 - Exempt Organization Return',
        //         },
        //         form990WorksheetSectionName:
        //           'Three Largest Program Service Accomplishments',
        //         threeLargestFieldKeys: [
        //           'XFDSPSSA.24',
        //           'XFDSPSSA.25',
        //           'XFDSPSSA.26',
        //         ],
        //       },
        //       schOWorksheetData: {
        //         schOIdentifier: {
        //           section: 'Schedules',
        //           id: 'Sch O - Supplemental Information for Form 990',
        //         },
        //         schOWorksheetSectionName: 'Schedule O Explanations',
        //         schOTypeKey: 'XFDS99O1.5',
        //         schOValueKey: 'XFDS99O1.4',
        //       },
        //     },
        //   },
        // },
        fields: [
          {
            name: 'progActDesc',
            type: 'textarea',
            label: 'Program Accomplishments',
            placeholder: 'Please type to add a description of your program',
            col: 12,
            isSyncFieldValue: true,
            class: 'fieldTextArea',
            maxLength: 9000,
            maxRows: -1,
            prior: true,
            isPriorEditable: true,
            pullCalculation: {
              keyIdentifiers: ['XFDSOOPS.0'],
              fn: 'joinTextOnNewLinePull',
            },
          },
          { type: 'lineButtons' },
        ],
        lineItems: [],
        lineSectionStyles: { marginBottom: '-70px' },
        currentEntity: -1,
        headerLabelsPrintStyles: { gridTemplateColumns: '2fr 2fr 1.5fr .5fr' },
        footerLabelsPrintStyles: {
          gridTemplateColumns: '.25fr .5fr 1.25fr 1fr',
        },
        lineItemDetails: {
          headerLabels: [
            { label: 'Program Accomplishments', col: 11, colTablet: 8 },
            { label: 'Actions', col: 1, colTablet: 2, align: 'left' },
          ],
          footerLabels: [
            {
              type: 'buttons',
              col: 12,
              buttonTitle: 'Add Another Program',
              icon: 'add',
            },
          ],
          lineButtons: [{ button: 'remove' }],
        },
      },
    ],
  },
  {
    title: 'Additional Information',
    collapseClass: 'collapsibleForm',
    isVisible: true,
    isDefaultExpanded: false,
    isOptionalSectionMerge: true,
    sectionId: 3,
    groups: [
      {
        groupId: 2,
        groupType: 'details',
        fields: [
          {
            label:
              'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.',
            type: 'label',
            class: 'labelLeftLight',
          },
        ],
      },
      {
        groupId: 1,
        fields: [
          {
            label: 'Start typing to provide additional information',
            name: 'additionalInfoTextArea',
            type: 'textarea',
            col: 12,
            maxLength: 9000,
          },
        ],
      },
    ],
  },
];

export const getProgramsTitles = () => {
  let titles = [];
  sections.forEach((section) => {
    if (!titles.includes(section.title)) {
      titles.push(section.title);
    }
  });
  return titles;
};

export const getProgramsSections = () => {
  return sections;
};

function Programs(props) {
  const { isExportForm } = props;
  const { updateCard, REDUX } = usePageFramework(NAV.PROGRAMS);
  const [sectionState, setSectionState] = useState('');

  const { isActiveClient, isLoading } = useLoadOrganizer();

  useEffect(() => {
    if (!isExportForm) updateCard();

    setSectionState(sections);
    // eslint-disable-next-line
  }, []);

  const axcessIdentifiers = [
    { section: 'Return Type', id: 'Form 990 - Exempt Organization Return' },
    {
      section: 'Schedules',
      id: 'Sch O - Supplemental Information for Form 990',
    },
  ];

  return (
    <>
      {!isLoading && isActiveClient && (
        <FormRendererHelper
          sections={sectionState}
          key="programsForm"
          formName={REDUX.PROGRAMS}
          identifiers={axcessIdentifiers}
          triggeredEvent={triggeredEvent}
          isExportForm={isExportForm}
        />
      )}
      {!isLoading && !isActiveClient && <NoActiveClient />}
    </>
  );
}

export default Programs;
