export const KEY_CANADA = 'CA';
export const KEY_UNITED_STATES = 'US';
export const KEY_MEXICO = 'MX';
export const KEY_PROVINCES = 'PROVINCES';
export const KEY_STATES = 'STATES';
export const KEY_YES = 'Yes';
export const KEY_NO = 'No';
export const KEY_EMPTY = '';
export const KEY_NA = 'n/a';
export const KEY_ZERO = '0';
export const KEY_TABLE = 'Table';
export const KEY_ATTACH = 'Attach';
export const KEY_FORMER = 'Former';
export const KEY_TRUSTEE = 'Institutional Trustee';
export const KEY_EIN = 'EIN';
export const KEY_FOREIGN = 'Foreign';
export const KEY_MARRIED = 'Married';
export const KEY_SINGLE = 'Single';
export const KEY_SSN = 'SSN / ITIN';
export const KEY_WIDOWED = 'Widowed';
export const KEY_HOSP_CHARITY = '170(b)(1)(A)(iii)-Hospital';
export const KEY_ADD = 'Add';
export const KEY_SUBSTITUTE = 'Substitute';
export const KEY_PUBLIC_FUNDED = '170(b)(1)(A)(vi)-Gov/Public Funded';
export const KEY_COMMUNITY_TRUST = '170(b)(1)(A)(vi)-Community Trust';
export const KEY_SCHOOL = '170(b)(1)(A)(ii)-School';
export const KEY_HOSPITAL = '170(b)(1)(A)(iii)-Hospital';

export const KEY_AUDIT = 'Audit';
export const KEY_REVIEW = 'Review';
export const KEY_COMPILATION = 'Compilation';
export const KEY_NONE = 'None';
export const KEY_NONE_OF_THE_ABOVE = 'None of the Above';

export const EVENT_KEY_1 = 'Single';
export const EVENT_KEY_2 = 'Married filing jointly (even if only one had income)';
export const EVENT_KEY_3 = 'Head of household';
export const EVENT_KEY_4 = 'Qualifying widow(er)';
export const EVENT_KEY_5 = 'Married filing separately - itemized';
export const EVENT_KEY_6 = 'Married filing separately - not itemized';
export const EVENT_KEY_7 = 'Married filing separately - spouse not filing';

export const EVENT_KEY_TAX_EXMP_1 = '501(c)(3)';
export const EVENT_KEY_TAX_EXMP_2 = '4947(a)(1)';
export const KEY_501C12 = '501(c)(12)';
export const KEY_501C7 = '501(c)(7)';
export const KEY_501C29 = '501(c)(29)';
export const KEY_509A3I = '509(a)(3)–Type I';
export const KEY_509A3II = '509(a)(3)–Type II';
export const KEY_509A3III_FUNC = '509(a)(3)–Type III Functionally Int.';
export const KEY_509A3III_NON_FUNC = '509(a)(3)–Type III Non-functionally Int.';
export const KEY_509A2_GROSS_RECEIPTS_TEST = '509(a)(2)–Gross Receipts Test';
export const KEY_MED_RESEARCH_ORG = '170(b)(1)(A)(iii)-Med Research Org';
export const KEY_AGRI_RESEARCH_ORG = '170(b)(1)(A)(ix)–Ag Research Org';
export const KEY_COLL_UNIV = '170(b)(1)(A)(iv)-College/University';
export const KEY_501C4 = '501(c)(4)';
export const KEY_501C5 = '501(c)(5)';
export const KEY_501C6 = '501(c)(6)';
export const KEY_501C3_NUM = '3';

export const KEY_DISREGARDED_ENTITY = 'Disregarded Entity';
export const KEY_TAX_EXEMPT = 'Tax Exempt';
export const KEY_PARTNERSHIP = 'Taxable as a Partnership';
export const KEY_CORP = 'Taxable as a Corp/Trust';
export const KEY_UNREALATED_PARTNERSHIP = 'Unrelated Partnership';

export const KEY_OTHER_501C = 'Other 501(c)';