export const EXCHANGE_990 = '990 Exchange';
export const DASHBOARD_KEY = 'clientDashboard';
export const DASHBOARD = 'Dashboard';
export const TAXES = 'Taxes';
export const COMPANY_NAME = 'Company Name';
export const SUBMIT_TEXT = 'Submit to CLA';
export const EXCHANGE_MANAGER_990 = '990 Exchange Manager';
export const CLIENT_SUBMITTED_TEXT = 'Client Submitted';
export const CLA_SETUP_TEXT = 'CLA Setup';
export const WITH_CLIENT_TEXT = 'With Client';
export const ORGANIZER_NEW_STATUS_TEXT = 'New';
export const SEND_WELCOME_EMAIL = 'Click Here to Send Client Welcome Email';
export const MARK_CARD_COMPLETE = 'Click Here to Mark Card Complete';
export const COMPLETED = 'Completed';

//Categories
export const BASIC_INFO = 'Basic Information';
export const GOVERNANCE = 'Governance';
export const FINANCIAL = 'Financial';
export const OTHER_SCHEDULES = 'Other Schedules';

//Forms Cards
export const BOARD_OF_DIRECTORS_AND_COMPENSATION =
  'Board of Directors & Compensation';
export const ENTRY_EXPERIENCE = 'Entry Experience';
export const BASIC_DATA = 'Basic Data';
export const PROGRAMS = 'Programs';
export const INTERNAL_SETUP = 'Internal Setup';
export const TAX_COMPLIANCE_FILINGS = 'Tax Compliance Filings';
export const GOVERNANCE_AND_MANAGEMENT = 'Governance & Management';
export const FINANCIALS = 'Financials';
export const CONTRIBUTIONS = 'Contributions';
export const FUNDRAISING_AND_GAMING = 'Fundraising & Gaming';
export const FOREIGN_ACTIVITIES = 'Foreign Activities';
export const POLITICAL_CAMPAIGN_AND_LOBBYING = 'Political Campaign & Lobbying';
export const DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE =
  'Domestic Grants & Other Assistance';
export const LIQUIDATION_AND_DISPOSITION_OF_ASSETS =
  'Liquidation & Disposition Of Assets';
export const PUBLIC_CHARITY_AND_SUPPORT = 'Public Charity & Support';
export const INTERESTED_PERSONS_TRANSACTIONS =
  'Interested Persons Transactions';
export const RELATED_ORGANIZATIONS_SCHED_R = 'Related Organizations';
export const SUPPLEMENTAL_FINANCIAL_STATEMENTS =
  'Supplemental Financial Statements';
export const TAX_EXEMPT_BONDS = 'Tax Exempt Bonds';
export const HOSPITALS = 'Hospitals';
export const SCHOOLS = 'Schools';
export const UNRELATED_BUSINESS_INCOME_TAX = 'Unrelated Business Income Tax';

export const SCHED_R_FORM_TITLE =
  'Related Organizations & Unrelated Partnerships';

//lookups
export const DROP_DOWN_DEFAULT_VALUE = ' ';
export const DROP_DOWN_DEFAULT_NAME = ' ';
export const DROP_DOWN_NONE = 'None';

export const NAME_AB = 'Alberta';
export const NAME_BC = 'British Columbia';
export const NAME_MB = 'Manitoba';
export const NAME_NB = 'New Brunswick';
export const NAME_NL = 'Newfoundland and Labrador';
export const NAME_NS = 'Nova Scotia';
export const NAME_NT = 'Northwest Territories';
export const NAME_NU = 'Nunavut';
export const NAME_ON = 'Ontario';
export const NAME_PE = 'Prince Edward Island';
export const NAME_QC = 'Québec';
export const NAME_SK = 'Saskatchewan';
export const NAME_YT = 'Yukon';

//subtitle
export const SCH_J = 'Sch J';
export const SCH_D = 'Sch D';
export const SCHED_BM = 'Sch B & Sch M';
export const SCH_G = 'Sch G';
export const SCH_E = 'Sch E';
export const SCH_F = 'Sch F';
export const SCH_C = 'Sch C';
export const SCH_I = 'Sch I';
export const SCH_N = 'Sch N';
export const SCH_A = 'Sch A';
export const SCH_L = 'Sch L';
export const SCH_R = 'Sch R';
export const SCH_K = 'Sch K';
export const SCH_H = 'Sch H';

//Error Message
export const GREATER_THAN_W2 = 'Bonus + Other cannot be greater than W-2';
export const DASHBOARD_ERROR_MESSAGE =
  'An error occured and your data has not been saved. Please try again later. Your administrator has been contacted to correct the error. For additional help, use the chat to contact our support team.';

export const NOT_AVAILABLE_LABEL = 'N/A';

export const ACCOUNT_TYPE_CHECKING = 'Checking';
export const ACCOUNT_TYPE_SAVINGS = 'Savings';

export const TAX_TYPE = 'TAX_TYPE';
export const TAX_TYPE_ADDITIONAL = '1';
export const TAX_TYPE_ESTIMATED = '5';

export const FORM_SUBMIT_WARNING_TXT =
  'Are you sure you want to submit? If you select yes, you will no longer be able to edit the form';
export const NO_ACTIVE_CLIENT = 'No active client found';
export const FORM_COMPLETE_WARNING_TXT =
  'This will send the client an invitation.  Do you wish to proceed?';
export const ORGANIZER_SUBMITTED = 'ORGANIZER_SUBMITTED';

//Exchange Manager kebab menuitems
export const RETURN_TO_CLIENT = 'Return to Client';
export const UPDATE_IN_CHARGE = 'Update In Charge';
export const PRINT = 'Print';
export const DELETE = 'Delete';

// Invalid upload file names
export const INVALID_CHARS = `' ~ " # % & * : < > ? / \\ { | }`;
export const INVALID_FILES_MESSAGE_H3 =
  'Files listed below have invalid names. Please fix and reupload:';
export const INVALID_FILES_MESSAGE_H4 =
  "A file name can't contain any of the following characters:";
export const INVALID_FILES_MESSAGE_RENAME =
  'A file name can\'t contain any of the following characters: ~ " # % & * : < > ? / \\ { | }';

// Upload Warning Dialog texts
export const WARNING = 'WARNING';
export const SKIP = 'Skip';
export const CANCEL = 'Cancel';
export const CANCEL_ALL = 'Cancel All';
export const REPLACE = 'Replace';

// Dashboard upload
export const DASHBOARD_SECTION_NAME = 'dashboardBulkUploads';
export const DASHBOARD_FILE_UPLOADS = 'File Uploads';
export const DASHBOARD_UPLOAD_SUBTITLE = 'Choose a file or drag it here.';
export const DASHBOARD_UPLOAD_SUBTITLE_MOBILE =
  'Choose a file from your file folder to upload.';
export const QUICK_UPLOAD_TITLE = 'Quick Upload';
export const QUICK_UPLOAD_SUBTITLE =
  'In a hurry, drag and drop your files here to organize later.';
