import * as EVENT from '@utilities/constants/triggerKeys';
import { findIndex, forEach } from 'lodash';

const showNoncashDtlNonRcrdRevDesc = (options) => {
  const { section, field } = options;

  section.groups[1].isVisible = field.default === EVENT.KEY_YES;
};

const showNoncashDtlHold3YrExmpt = (options) => {
  const { section, field } = options;

  const isNoncashDtlHold3YrExmptNo = section.groups[4].fields[1].default === EVENT.KEY_NO;

  section.groups[4].isVisible = field.default === EVENT.KEY_YES;
  section.groups[5].isVisible = isNoncashDtlHold3YrExmptNo && field.default === EVENT.KEY_YES;
};

const showContributionSection = (options) => {
  const { section, field } = options;

  section.groups[5].isVisible = field.default === EVENT.KEY_ATTACH;
  section.groups[6].isVisible = field.default === EVENT.KEY_TABLE;

};

const showNoncashDtlHold3YrDesc = (options) => {
  const { section, field } = options;

  section.groups[5].isVisible = field.default === EVENT.KEY_NO;
};

const showContribSecRule1Calc = (options) => {
    const { section, field, logic } = options;
    const basicData = logic.triggerRelatedForms?.basicData;
    const basicDataTaxExempt = basicData[0].groups[11].fields[1].default || basicData[0].groups[11].fields[1].value;
    const basicDataCharityStat = basicData[0].groups[12].fields[1].default || basicData[0].groups[12].fields[1].value;
    const isTaxExempt = (basicDataCharityStat === EVENT.KEY_PUBLIC_FUNDED || basicDataCharityStat === EVENT.KEY_COMMUNITY_TRUST) &&
        (basicDataTaxExempt === EVENT.EVENT_KEY_TAX_EXMP_1 || basicDataTaxExempt === EVENT.EVENT_KEY_TAX_EXMP_2);
    const contribSecRule1Calc = field.default * .02;
    const dollerUSlocale = Intl.NumberFormat('en-US');
    const contribSecRule1CalcDollar = `$${dollerUSlocale.format(contribSecRule1Calc.toFixed())}`;
    
    section.groups[5].isVisible = field.default >= 5000 && !isTaxExempt;
    section.groups[3].isVisible = field.default >= 5000 && field.default <= 250000 && isTaxExempt;
    section.groups[2].isVisible = field.default > 250000 && isTaxExempt;
    section.groups[4].isVisible = field.default > 250000 && isTaxExempt;
    section.groups[2].fields[1].label = contribSecRule1CalcDollar;
    section.groups[4].fields[0].label = `Did the organization record contribution revenue of at least ${contribSecRule1CalcDollar} from any single contributor/grantor (includes both entities and individuals and both cash & noncash donations)?`;
    section.groups[2].fields[1].default = contribSecRule1CalcDollar;
    section.groups[4].fields[0].default = `Did the organization record contribution revenue of at least ${contribSecRule1CalcDollar} from any single contributor/grantor (includes both entities and individuals and both cash & noncash donations)?`;
};

const showContribSecRule1CalcMajor = (event, options, fieldName) =>{
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;
  const contribSecRule1Calc = name[0].groups[0].fields[1].default * .02 ;
  const dollerUSlocale = Intl.NumberFormat('en-US');
  const contribSecRule1CalcDollar = `$${dollerUSlocale.format(contribSecRule1Calc.toFixed())}`;
  name[1].groups[1].fields[0].label = `Provide the following information for each contributor whose total contributions are greater than ${contribSecRule1CalcDollar} during the year:`
  name[1].groups[1].fields[0].default = `Provide the following information for each contributor whose total contributions are greater than ${contribSecRule1CalcDollar} during the year:`

}

const showNoncashDtl3rdPartyYes = (options) => {
  const { section, field } = options;

  section.groups[8].isVisible = field.default === EVENT.KEY_YES;
};

const showContribName = (event, options, fieldName) => {

  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  
  // const numberLabel = name[1].groups[6].lineItems[0][0].default;
  // const nameLabel = name[1].groups[6].lineItems[0][1].default;
 
  // if (fieldName === 'contribType' && name[2].groups[1].lineItems && name[2].groups[1].lineItems.length > 0) {
  //     if (!event.length) {
  //     name[2].groups[1].lineItems[0][1].label = ' ';
  //     name[2].groups[1].lineItems[0][0].label = ' ';
  //     }
  //   for (let i = 0; i < event.length; i++) {
  //     if (event[i].value === 'Noncash') {
  //       name[2].groups[1].lineItems[0][1].label = nameLabel;
  //       name[2].groups[1].lineItems[0][0].label = numberLabel;
  //     break;
  //     } else {
  //       name[2].groups[1].lineItems[0][1].label = ' ';
  //       name[2].groups[1].lineItems[0][0].label = ' ';
  //     }
  //   }
  // }

  setFormSections(name)
}

const showNoncashDtlNonRcrdRev = (event, options, fieldName) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;
  if (fieldName === 'contribRecTotalNonCash') {
    name[0].groups[6].isVisible = event > 0;
    name[2].isVisible = event > 25000 || (name[0].groups[6].isVisible && name[0].groups[6].fields[1].default === EVENT.KEY_YES);
    name[3].isVisible = event > 25000 || (name[0].groups[6].isVisible && name[0].groups[6].fields[1].default === EVENT.KEY_YES);
  }else if(fieldName === 'reqSchdChkMb'){
    name[2].isVisible = event === EVENT.KEY_YES || name[0].groups[1].fields[1].default > 25000;
    name[3].isVisible = event === EVENT.KEY_YES || name[0].groups[1].fields[1].default > 25000;
  }
}

const showMajorContrib = (event, options ) => {
  const { sections, formSections } = options;
  
  const name = formSections ? formSections : sections;
  
  //heading 1
  if (name[0].groups[3].fields[1].default === EVENT.KEY_YES || name[0].groups[5].fields[1].default === EVENT.KEY_YES){
    name[1].groups[0].isVisible = true;
  }
  else {
    name[1].groups[0].isVisible = false;
  }

  //heading 2
  if (name[0].groups[4].fields[1].default === EVENT.KEY_YES ){
    name[1].groups[1].isVisible = true;
  }
  else {
    name[1].groups[1].isVisible = false;
  }

  //heading 3
  if (name[0].groups[5].fields[1].default === EVENT.KEY_YES ){
    name[1].groups[2].isVisible = true;
  }
  else {
    name[1].groups[2].isVisible = false;
  }

  //heading 4
  if (name[0].groups[5].fields[1].default === EVENT.KEY_NO){
    name[1].groups[3].isVisible = true;
  }
  else {
    name[1].groups[3].isVisible = false;
  }

  //contribAttach Heading
  if (name[0].groups[3].fields[1].default === EVENT.KEY_YES || name[0].groups[4].fields[1].default === EVENT.KEY_YES  || name[0].groups[5].fields[1].default === EVENT.KEY_YES)
  {
    name[1].groups[4].isVisible = true ;
  }
  else {
    name[1].groups[4].isVisible = false;
  }
  

  //section trigger
  if ((name[0].groups[3].isVisible && name[0].groups[3].fields[1].default === EVENT.KEY_YES) || (name[0].groups[4].isVisible && name[0].groups[4].fields[1].default === EVENT.KEY_YES) || (name[0].groups[5].isVisible && name[0].groups[5].fields[1].default === EVENT.KEY_YES)) {
    name[1].isVisible = true;
  }
  else if (name[0].groups[5].fields[1].default === EVENT.KEY_NO) {
    name[1].isVisible = true;
    name[1].groups[3].isVisible = true;
    name[1].groups[4].isVisible = true;

  }
  else {
    name[1].isVisible = false;
  }
}

const showMajorContribNonCash = (event, options, fieldName) => {
  const { sections, formSections } = options;

  const name = formSections ? formSections : sections;

  if (name[0].groups[3].fields[1].default === EVENT.KEY_YES || name[0].groups[4].fields[1].default === EVENT.KEY_YES || name[0].groups[5].fields[1].default === EVENT.KEY_YES) {
    // name[2].isVisible = true;
  }
  if (fieldName === 'contribType') {
    forEach(name[1].groups[6].lineItems, (items, index) => {
      items[3].isDisable = true;
      if(items[2].default && items[2].default.length > 0){
        let nonCashPresent = findIndex(items[2].default, {value: 'Noncash'});
        if(name[1].groups[6].entities[index]){
          name[1].groups[6].entities[index].sections[1].isVisible = nonCashPresent > -1;
        }
        items[3].isDisable = nonCashPresent === -1;
      }else{
        if(name[1].groups[6].entities[index]){
          name[1].groups[6].entities[index].sections[1].isVisible = false;
        }
      }
    });
    // if (!event.length) {
    //   name[2].isVisible = false;
    // }
    // for (let i = 0; i < event.length; i++) {
    //   if (event[i].value === 'Noncash') {
    //     name[2].isVisible = true;
    //     break;
    //   } else {
    //     name[2].isVisible = false;
    //   }
    // }
  }
  // else if (name[0].groups[3].fields[1].default === EVENT.KEY_NO && name[0].groups[4].fields[1].default === EVENT.KEY_NO && name[0].groups[5].fields[1].default === EVENT.KEY_NO) {
  //   name[2].isVisible = false;
  // }
}

const showContributionsAddInfo = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (name.length === 5) {
        name[4].isVisible = name[0].isVisible;
    }
};

const triggeredEvent = (trigger, event, options) => {
  switch (trigger) {
    case 'showNoncashDtlNonRcrdRev':
      showNoncashDtlNonRcrdRev(event, options, 'contribRecTotalNonCash');
      break;
    case 'showNoncashDtlNonRcrdRevReq':
      showNoncashDtlNonRcrdRev(event, options, 'reqSchdChkMb');
      break;
    case 'showMajorContrib':
      showMajorContrib(event, options );
      break;
    case 'showContribSecRule1CalcMajor':
      showContribSecRule1CalcMajor(event, options);
      break;
    case 'showMajorContribNonCashContribType':
      showMajorContribNonCash(event, options, 'contribType');
      break;
    case 'showMajorContribNonCash':
      showMajorContribNonCash(event, options, 'NotcontribType');
      break;
    case 'showContribName':
      showContribName(event, options, 'contribType');
      break;
    case 'showContributionsAddInfo':
        showContributionsAddInfo(event, options);
      break;
  default:
      break;
  }
};

const sectionFieldLogic = (logicFunction, options) => {
  switch (logicFunction) {
    case 'showNoncashDtlNonRcrdRevDesc':
      showNoncashDtlNonRcrdRevDesc(options);
      break;
    case 'showNoncashDtlHold3YrExmpt':
      showNoncashDtlHold3YrExmpt(options);
      break;
    case 'showNoncashDtlHold3YrDesc':
      showNoncashDtlHold3YrDesc(options);
      break;
    case 'showNoncashDtl3rdPartyYes':
      showNoncashDtl3rdPartyYes(options);
      break;
    case 'showContributionSection':
      showContributionSection(options);
      break;
    case 'showContribSecRule1Calc':
      showContribSecRule1Calc(options);
      break;
    default:
      break;
  }
};

export {
  triggeredEvent,
  sectionFieldLogic
};