import { useState, useEffect } from 'react';
import { Validators } from 'cla-formrenderer';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { triggeredEvent, sectionFieldLogic } from './publicCharityAndSupportLogicTrigger';
import { pullFieldValue, pullFieldValueIfOtherEquals } from '@utilities/pullFieldValue';
import * as AXCESS_KEYS from '@utilities/constants/axcessKeys';
import * as NAV from '@utilities/constants/navigation';
import { INTERESTED_PERSONS_TRANSACTIONS } from '@utilities/constants/redux';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';


export const supportedOrgLineSections = [
    {
        title: 'Supported Organization Information',
        sectionId: 1,
        isVisible: true,
        isDenseRow: true,
        isCollapsed: false,
        groups: [
            {
                groupId: 1,
                isVisible: true,
                hasDivider: true,
                fields: [
                    { label: 'Select Public Charity Status', name: 'pubChrSupOrgTypeLabel', type: 'label', col: 8 },
                    { label: 'Public Charity Status.', name: 'pubChrSupOrgType', type: 'select', lookup: 'PUBLIC_CHARITY_STATUS', col: 4 },
                ]
            },
            {
                groupId: 2,
                isVisible: true,
                hasDivider: true,
                fields: [
                    { label: 'Description of Other Support', name: 'pubChrSupOrgAmtOthDescLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
                    {
                        label: 'Please enter your comments/questions',
                        name: 'pubChrSupOrgAmtOthDesc',
                        type: 'textarea',
                        col: 12,
                        maxRows: 3,
                        class: 'fieldTextArea',
                    },
                ]
            },
            {
                groupId: 3,
                isVisible: true,
                hasDivider: true,
                fields: [
                    { label: `Is the supported organization’s name listed in the organization’s governing documents?`, name: 'pubChrSupOrgNameGovDocLabel', type: 'label', col: 10 },
                    { label: '', name: 'pubChrSupOrgNameGovDoc', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgNameGovDocDesc' }] },
                ]
            },
            {
                groupId: 4,
                isVisible: false,
                fields: [
                    { label: 'Describe how the supported organizations are designated. If designated by class or purpose, describe the designation. If historic and continuing relationship, explain:', name: 'pubChrSupOrgNameGovDocDescLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
                    {
                        label: 'Please enter your comments/questions',
                        name: 'pubChrSupOrgNameGovDocDesc',
                        type: 'textarea',
                        col: 12,
                        maxRows: 3,
                        class: 'fieldTextArea',
                        ...pullFieldValueIfOtherEquals(
                            'XFDSAEXP.0',
                            'XFDSAEXP.1',
                            AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_A1,
                            true
                        )
                    },
                ]
            },
        ]
    },
];

const supportedOrgActionLineSections = [
    {
        title: 'Supported Organization Action Information',
        sectionId: 1,
        isVisible: true,
        isDenseRow: true,
        isCollapsed: false,
        groups: [
            {
                groupId: 1,
                isVisible: true,
                hasDivider: true,
                fields: [
                    { label: 'Reason for such action', name: 'pubChrSupOrgAddActionRsnLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
                    {
                        label: 'Please enter your comments/questions',
                        name: 'pubChrSupOrgAddActionRsn',
                        type: 'textarea',
                        col: 12,
                        maxRows: 3,
                        class: 'fieldTextArea',
                    },
                ]
            },
            {
                groupId: 2,
                isVisible: true,
                fields: [
                    { label: 'How was the action accomplished', name: 'pubChrSupOrgAddActionMthdLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
                    {
                        label: 'Please enter your comments/questions',
                        name: 'pubChrSupOrgAddActionMthd',
                        type: 'textarea',
                        col: 12,
                        maxRows: 3,
                        class: 'fieldTextArea',
                    },
                ]
            },
        ]
    },
];

let sections = [];

export const getPubCharStaSecTitles = () => {
    let titles = [];
    sections.forEach((section) => {
        if (!titles.includes(section.title)) {
            titles.push(section.title);
        }
    });
    return titles;
};

export const getPubCharSections = () => {
    return sections;
};

function PubCharStaSec(props) {
	const { isExportForm } = props;
    const { updateCard, REDUX, selectState, setGlobalFormState } = usePageFramework(NAV.PUBLIC_CHARITY_AND_SUPPORT);
    const [sectionState, setSectionState] = useState('');
    const basicDataForm = selectState(REDUX.BASIC_DATA);
    const interestedPersonsForm = selectState(REDUX.INTERESTED_PERSONS_TRANSACTIONS);
    const {isActiveClient, isLoading} = useLoadOrganizer();

    useEffect(() => {
        if (!isExportForm) updateCard();

        sections = [
            {
                title: 'Research Organizations',
                isVisible: false,
                sectionId: 2,
                groups: [
                    {
                        groupId: 1,
                        isVisible: false,
                        fields: [
                            { label: 'Enter the following information for the hospital:', name: 'sec170b1AiiiHsptDataLabel', type: 'label', col: 12 },
                        ]
                    },
                    {
                        groupType: 'lineItem',
                        groupId: 2,
                        isVisible: false,
                        axcessGroup: {
                            pull: {
                                fromSections: ['Reason for Public Charity Status'],
                                fieldIdentifiers: [
                                    'XFDSABFS.18',
                                    'XFDSABFS.19',
                                    'XFDSABFS.20',
                                    'XFDSABFS.21'
                                ]
                            }
                        },
                        fields: [
                            {
                                label: 'Name of Hospital',
                                name: 'sec170b1AiiiHsptName',
                                type: 'freeText',
                                maxLength: 60,
                                prior: true,
                                isPriorEditable: true,
                                pullCalculation: {
                                    keyIdentifiers: ['XFDSABFS.18'],
                                    fn: 'anyValidPull',
                                },
                            },
                            {
                                label: 'City',
                                name: 'sec170b1AiiiHsptCity',
                                type: 'freeText',
                                maxLength: 25,
                                prior: true,
                                isPriorEditable: true,
                                pullCalculation: {
                                    keyIdentifiers: ['XFDSABFS.19'],
                                    fn: 'anyValidPull',
                                }
                            },
                            {
                                label: 'State/Province',
                                name: 'sec170b1AiiiHsptState',
                                type: 'freeText',
                                maxLength: 25,
                                prior: true,
                                isPriorEditable: true,
                                pullCalculation: {
                                    keyIdentifiers: ['XFDSABFS.20'],
                                    fn: 'anyValidPull',
                                },
                            },
                            {
                                label: 'Country (non US)',
                                name: 'sec170b1AiiiHsptCntry',
                                type: 'select',
                                lookup: 'PUBLIC_CHARITY_COUNTRIES',
                                prior: true,
                                isPriorEditable: true,
                                pullCalculation: {
                                    keyIdentifiers: ['XFDSABFS.21'],
                                    fn: 'anyValidPull',
                                },
                            },
                            { type: 'lineButtons' }
                        ],
                        lineItems: [],
                        entities: [],
                        currentEntity: -1,
                        headerLabelsPrintStyles: {gridTemplateColumns: '1.75fr 1.75fr 1.5fr 1.5fr .75fr'},
                        lineItemDetails:
                        {
                            headerLabels: [
                                { label: 'Name of Hospital', name: 'sec170b1AiiiHsptName', col: 4, colTablet: 2 },
                                { label: 'City', name: 'sec170b1AiiiHsptCity', col: 2, colTablet: 1 },
                                { label: 'State', name: 'sec170b1AiiiHsptState', col: 2, colTablet: 2 },
                                { label: 'Country (non US)', name: 'sec170b1AiiiHsptCntry', col: 3, colTablet: 2 },
                                { label: 'Actions', col: 1, colTablet: 1, align: 'left' }
                            ],
                            footerLabels: [
                                { type: 'buttons', col: 12, buttonTitle: 'Add Another Hospital', icon: 'add' },
                            ],
                            lineButtons: [
                                { button: 'remove', icon: 'remove' },
                            ]
                        }
                    },
                    {
                        groupId: 3,
                        isVisible: false,
                        fields: [
                            { label: 'Agricultural Research Organization', name: 'agricultureResearchOrganization', type: 'label', col: 12, class: 'formSingleHeaderContainer' },
                        ]
                    },
                    {
                        groupId: 6,
                        isVisible: false,
                        fields: [
                            { label: 'Benefit of College/University', name: 'benCollegeUniv', type: 'label', col: 12, class: 'formSingleHeaderContainer' },
                        ]
                    },
                    {
                        groupId: 4,
                        isVisible: false,
                        fields: [
                            { label: 'Enter the following information for the College or University', name: 'sec170b1AixDataLabel', type: 'label', col: 12 },
                        ]
                    },
                    {
                        groupType: 'lineItem',
                        groupId: 5,
                        isVisible: false,
                        axcessGroup: {
                            pull: {
                                fromSections: ['Reason for Public Charity Status'],
                                fieldIdentifiers: [
                                    'XFDSABFS.17',
                                    'XFDSABFS.23',
                                    'XFDSABFS.24',
                                    'XFDSABFS.25'
                                ]
                            }
                        },
                        fields: [
                            {
                                label: 'Name of College or University',
                                name: 'sec170b1AixName',
                                type: 'freeText',
                                maxLength: 60,
                                prior: true,
                                isPriorEditable: true,
                                pullCalculation: {
                                    keyIdentifiers: ['XFDSABFS.17'],
                                    fn: 'anyValidPull',
                                }
                            },
                            {
                                label: 'City',
                                name: 'sec170b1AixCity',
                                type: 'freeText',
                                maxLength: 25,
                                prior: true,
                                isPriorEditable: true,
                                pullCalculation: {
                                    keyIdentifiers: ['XFDSABFS.23'],
                                    fn: 'anyValidPull',
                                }
                            },
                            {
                                label: 'State',
                                name: 'sec170b1AixState',
                                type: 'select',
                                lookup: 'STATES',
                                prior: true,
                                isPriorEditable: true,
                                pullCalculation: {
                                    keyIdentifiers: ['XFDSABFS.24'],
                                    fn: 'anyValidPull',
                                }
                            },
                            {
                                label: 'Country (non US)',
                                name: 'sec170b1AixCntry',
                                type: 'select',
                                lookup: 'PUBLIC_CHARITY_COUNTRIES',
                                prior: true,
                                isPriorEditable: true,
                                pullCalculation: {
                                    keyIdentifiers: ['XFDSABFS.25'],
                                    fn: 'anyValidPull',
                                }
                            },
                            { type: 'lineButtons' },
                        ],
                        lineItems: [],
                        entities: [],
                        currentEntity: -1,
                        headerLabelsPrintStyles: {gridTemplateColumns: '1.75fr 1.75fr 1.5fr 1.5fr .75fr'},
                        lineItemDetails:
                        {
                            headerLabels: [
                                { label: 'Name of College or University', name: 'sec170b1AixName', col: 4, colTablet: 2 },
                                { label: 'City', name: 'sec170b1AixCity', col: 2, colTablet: 1 },
                                { label: 'State', name: 'sec170b1AixState', col: 2, colTablet: 2 },
                                { label: 'Country (non US)', name: 'sec170b1AixCntry', col: 3, colTablet: 2 },
                                { label: 'Actions', col: 1, colTablet: 1, align: 'left', }

                            ],
                            footerLabels: [
                                { type: 'buttons', col: 12, buttonTitle: 'Add Another College or University', icon: 'add' },
                            ],
                            lineButtons: [
                                { button: 'remove', },
                            ]
                        }
                    },
                ]
            },
            {
                title: 'Organization Support',
                isVisible: false,
                sectionId: 3,
                isDefaultExpanded: true,
                groups: [
                    {
                        groupId: 1,
                        isVisible: true,
                        fields: [
                            { label: 'Did the organization receive any payments from a disqualified person(s)?  Please select all that apply:', name: 'pubChrPayDqLabel', type: 'label', col: 12 },
                        ]
                    },
                    {
                        groupId: 2,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Any Officer, Director or Key Employee (ODK)', name: 'listOneLabel', type: 'label', col: 10, class: 'labelSingleCheckbox' },
                            { label: 'Yes', name: 'listOne', type: 'checkbox', isFieldCheckbox: true, col: 2, class: 'singleCheckboxMargin', logicFunction: [{ trigger: 'showPubChrPayDqTable', isDifferentGroup: 'true' }] }
                        ]
                    },
                    {
                        groupId: 3,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Family member of an Officer, Director, or Key Employee of the organization', name: 'listTwoLabel', type: 'label', col: 10, class: 'labelSingleCheckbox' },
                            { label: 'Yes', name: 'listTwo', type: 'checkbox', isFieldCheckbox: true, col: 2, class: 'singleCheckboxMargin', logicFunction: [{ trigger: 'showPubChrPayDqTable', isDifferentGroup: 'true' }] }
                        ]
                    },
                    {
                        groupId: 4,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Corporation, partnership, trust or estate in which an Officer, Director or Key Employee or their family member have combined control, voting power or other interest that is greater than 35%', name: 'listThreeLabel', type: 'label', col: 10, class: 'labelSingleCheckbox' },
                            { label: 'Yes', name: 'listThree', type: 'checkbox', isFieldCheckbox: true, col: 2, class: 'singleCheckboxMargin', logicFunction: [{ trigger: 'showPubChrPayDqTable', isDifferentGroup: 'true' }] }
                        ]
                    },
                    {
                        groupId: 5,
                        isVisible: false,
                        fields: [
                            { label: 'Please provide details for all payments included in revenue on the trial balance (contributions, program payments, etc.) received from an individual above by either attaching a list or complete the table below:', name: 'pubChrPayDqTable', type: 'label', col: 12 },
                        ]
                    },
                    {
                        groupType: 'lineItem',
                        isVisible: false,
                        groupId: 6,
                        fields: [
                            { label: 'Contributors Name', name: 'pubChrPayDqName', type: 'freeText' },
                            { label: 'Contributor Type', name: 'pubChrPayDqType', type: 'select', lookup: 'CONTRIBUTOR_TYPE' },
                            { label: 'Contribution Amount', name: 'pubChrPayDqAmt', type: 'money', isTotal: true },
                            { label: 'Contribution Description', name: 'pubChrPayDqDesc', type: 'freeText' },
                            { type: 'lineButtons' },

                        ],
                        lineItems: [],
                        entities: [],
                        currentEntity: -1,
                        headerLabelsPrintStyles: {gridTemplateColumns: '1.75fr 1.75fr 1.5fr 1.5fr .75fr'},
						footerLabelsPrintStyles: {gridTemplateColumns: '6fr 2.25fr'},
                        lineItemDetails:
                        {
                            headerLabels: [
                                { label: 'Contributors Name', name: 'pubChrPayDqName', col: 3, colTablet: 2 },
                                { label: 'Contributor Type', name: 'pubChrPayDqType', col: 3, colTablet: 1 },
                                { label: 'Contribution Amount', name: 'pubChrPayDqAmt', col: 2, colTablet: 2 },
                                { label: 'Contribution Description', name: 'pubChrPayDqDesc', col: 3, colTablet: 2 },
                                { label: 'Actions', col: 1, colTablet: 1, align: 'left' }
                            ],
                            footerLabels: [
                                { type: 'buttons', col: 12, buttonTitle: 'Add another contributor', icon: 'add' },
                                { type: 'totalMoney', name: 'pubChrPayDqAmt', col: 8, colTablet: 5, label: '$ 0' }
                            ],
                            lineButtons: [
                                { button: 'remove', },
                            ]
                        }
                    },
                    {
                        groupId: 7,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Provide the organizations total expected program revenue.', name: 'pubChrPayProgRevLabel', type: 'label', col: 10 },
                            { label: 'Amount', name: 'pubChrPayProgRev', type: 'money', col: 2, logicFunction: [{ trigger: 'showProgramRevenue', isDifferentGroup: 'true' }] },
                        ]
                    },
                    {
                        groupId: 8,
                        isVisible: true,
                        fields: [
                            { label: 'Did the organization receive any payments from a payee of program revenue greater than $5000?', name: 'pubChrPayNonDqLabel', type: 'label', col: 8 },
                            { label: '', name: 'pubChrPayNonDq', type: 'radio', lookup: 'YESNO_RADIO', col: 4, logicFunction: [{ trigger: 'showPayeeListings', isDifferentGroup: 'true' }] },
                        ]
                    },
                    {
                        groupId: 9,
                        isVisible: false,
                        fields: [
                            { label: 'Please provide a complete listing for each payee including amounts reported as program revenue on the trial balance, and exclude all contribution revenue.', name: 'pubChrPayNonDqData', type: 'label', col: 10 },
                        ]
                    },
                    {
                        groupType: 'lineItem',
                        groupId: 10,
                        isVisible: false,
                        fields: [
                            { label: 'Payee Name', name: 'pubChrPayNonDqName', type: 'freeText' },
                            { label: 'Amount', name: 'pubChrPayNonDqAmt', type: 'money', isTotal: true },
                            { label: 'Comments/Description', name: 'pubChrPayNonDqDesc', type: 'freeText' },
                            { type: 'lineButtons' },
                        ],
                        lineItems: [],
                        entities: [],
                        currentEntity: -1,
						headerLabelsPrintStyles: {gridTemplateColumns: '2fr 1.75fr 1.75fr .5fr'},
						footerLabelsPrintStyles: {gridTemplateColumns: '6fr 3fr'},
                        lineItemDetails:
                        {
                            headerLabels: [
                                { label: 'Payee Name', name: 'pubChrPayNonDqName', col: 4, colTablet: 3 },
                                { label: 'Amount', name: 'pubChrPayNonDqAmt', col: 2, colTablet: 2 },
                                { label: 'Comments/Description', name: 'pubChrPayNonDqDesc', col: 5, colTablet: 2 },
                                { label: 'Actions', col: 1, colTablet: 1, align: 'left' }

                            ],
                            footerLabels: [
                                { type: 'buttons', col: 12, buttonTitle: 'Add another Payee', icon: 'add' },
                                { type: 'totalMoney', name: 'pubChrPayNonDqAmt', col: 6, colTablet: 5, label: '$ 0' }
                            ],
                            lineButtons: [
                                { button: 'remove', },
                            ]
                        }
                    },
                ]
            },
            {
                title: 'Supporting Organizations',
                isVisible: false,
                sectionId: 4,
                hasDenseRow: true,
                isDefaultExpanded: true,
                groups: [
                    {
                        groupId: 1,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization received a written determination from the IRS that it is a Type I, Type II, Type III functionally integrated, or Type III non-functionally integrated supporting organization?', name: 'secWrtnDtrmTypeRecLabel', type: 'label', col: 10 },
                            { label: '', name: 'secWrtnDtrmTypeRec', type: 'radio', lookup: 'YESNO_RADIO', col: 2 },
                        ]
                    },
                    {
                        groupId: 2,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Enter the number of supported organizations', name: 'secNoSupOrgsLabel', type: 'label', col: 10 },
                            { label: 'Supported Organizations', name: 'secNoSupOrgs', type: 'total', maxLength: 10, col: 2 },
                        ]
                    },
                    {
                        groupId: 3,
                        isVisible: true,
                        fields: [
                            { label: 'Provide the following information about the supported organization(s):', name: 'pubChrSupOrgDataLabel', type: 'label', col: 12 },
                        ]
                    },
                    {
                        groupType: 'lineItem',
                        groupId: 4,
                        axcessGroup: {
                            pull: {
                                postPopulateFn: 'mergeA1IntoFirstEntity',
                                fromSections: ['Schedule A Supplemental Information'],
                                fieldIdentifiers: ['XFDSAEXP.0', 'XFDSAEXP.1'],
                                lineFilterType: 'any',
                                lineFilters: [
                                    {
                                        key: 'XFDSAEXP.1',
                                        value: AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_A1,
                                        filterType: 'containsKeyWithValue'
                                    }
                                ]
                            },

                        },
                        entityIdentifiers: [
                            {
                                axcessDataType: 'PassDataToChild'
                            }
                        ],
                        subWorkSheets: [],
                        fields: [
                            {
                                label: 'Name',
                                name: 'pubChrSupOrgName',
                                type: 'freeText',
                                maxLength: 75,
                            },
                            { label: 'EIN', name: 'pubChrSupOrgEIN', type: 'ein', validations: Validators.ein() },
                            { label: 'Details', name: 'additionalInfo', type: 'viewButton', viewSectionId: 100 },
                            { label: 'Amount', name: 'pubChrSupOrgAmtMoney', type: 'money', isTotal: true },
                            { label: 'Amount', name: 'pubChrSupOrgAmtOther', type: 'money', isTotal: true },
                            { type: 'lineButtons' },

                        ],
                        lineItems: [],
                        entities: [],
                        currentEntity: -1,
                        lineSections: supportedOrgLineSections,
						headerLabelsPrintStyles: {gridTemplateColumns: '1.75fr 1.5fr .75fr 1.75fr 1.75fr .75fr'},
						footerLabelsPrintStyles: {gridTemplateColumns: '6fr 1.75fr .5fr'},
                        lineItemDetails:
                        {
                            headerLabels: [
                                { label: 'Name of Supported Org.', col: 3, colTablet: 2, styles: { paddingLeft: '48px' } },
                                { label: 'EIN', col: 2, colTablet: 2, styles: { paddingLeft: '40px' } },
                                { label: 'Additional Info', col: 2, colTablet: 1, styles: { textAlign: 'center', paddingLeft: '30px' } },
                                { label: 'Monetary Support Amt.', col: 2, colTablet: 1, align: 'right', styles: { paddingRight: '11px' } },
                                { label: 'Other Support Amt', col: 2, colTablet: 1, align: 'right', styles: { paddingRight: '11px' } },
                                { label: 'Actions', col: 1, colTablet: 1, styles: { paddingLeft: '20px' } },
                            ],
                            footerLabels: [
                                { type: 'buttons', col: 12, buttonTitle: 'Add Another Supported Organization', icon: 'add' },
                                { type: 'totalMoney', name: 'pubChrSupOrgAmtMoney', col: 9, colTablet: 6, label: '$ 0' },
                                { type: 'totalMoney', name: 'pubChrSupOrgAmtOther', col: 2, colTablet: 2, label: '$ 0' },
                            ],
                            lineButtons: [
                                { button: 'remove', },
                            ]
                        }
                    },
                    {
                        groupId: 5,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization have any supported organization that does not have an IRS determination of status under section 509(a)(1) or 509(a)(2)?', name: 'pubChrSupOrgNoIrsDtrmLabel', type: 'label', col: 10, },
                            {
                                label: '',
                                name: 'pubChrSupOrgNoIrsDtrm',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 2,
                                logicFunction: [{ trigger: 'showPubChrSupOrgNoIrsDtrmExp' }],
                                ...pullFieldValue(['XFDSSUPA.1'], true)
                            },
                        ]
                    },
                    {
                        groupId: 6,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Explain how the organization determined that the supported organization was described in section 509(a)(1) or 509(a)(2):', name: 'pubChrSupOrgNoIrsDtrmExpLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgNoIrsDtrmExp',
                                type: 'textarea',
                                col: 12,
                                maxRows: 3,
                                class: 'fieldTextArea',
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_A2,
                                    true
                                )
                            },
                        ]
                    },
                    {
                        groupId: 7,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization have a supported organization described in section 501(c)(4), 501(c)(5), or 501(c)(6)?', name: 'pubChrSupOrgC456Label', type: 'label', col: 10 },
                            {
                                label: '',
                                name: 'pubChrSupOrgC456',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 2,
                                logicFunction: [{ trigger: 'showPubChrSupOrgC456Fields' }],
                                ...pullFieldValue(['XFDSSUPA.2'], true)
                            },
                        ]
                    },
                    {
                        groupId: 8,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization confirm that each supported organization qualified under section 501(c)(4), 501(c)(5), or 501(c)(6) and satisfied the public support tests under section 509(a)(2)?', name: 'pubChrSupOrgC456ConfirmLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgC456Confirm', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgC456ConfirmFields' }] },
                        ]
                    },
                    {
                        groupId: 9,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Describe when and how the organization made the determination:', name: 'pubChrSupOrgC456ConfirmDescLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgC456ConfirmDesc',
                                type: 'textarea',
                                col: 12,
                                maxRows: 3,
                                class: 'fieldTextArea',
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_A3B,
                                    true
                                )
                            },
                        ]
                    },
                    {
                        groupId: 10,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'This confirmation is required please contact CLA to discuss further.', name: 'textEight', type: 'label', col: 12, styles: { marginTop: '-20px' } },
                        ]
                    },
                    {
                        groupId: 11,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization ensure that all support to such organizations was used exclusively for charitable purposes?', name: 'pubChrSupOrgExcls170c2bLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgExcls170c2b', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgExcls170c2bFields' }] },
                        ]
                    },
                    {
                        groupId: 12,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Explain what controls the organization has in place to ensure all support was used for such purposes:', name: 'pubChrSupOrgExcls170c2bExpLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgExcls170c2bExp',
                                type: 'textarea',
                                col: 12,
                                maxRows: 3,
                                class: 'fieldTextArea',
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_A3C,
                                    true
                                )
                            },
                        ]
                    },
                    {
                        groupId: 13,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'This confirmation is required please contact CLA to discuss further.', name: 'textNine', type: 'label', col: 12, styles: { marginTop: '-30px' } },
                        ]
                    },
                    {
                        groupId: 14,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Was any supported organization not organized in the United States?', name: 'pubChrSupOrgNonUSLabel', type: 'label', col: 10 },
                            {
                                label: '', name: 'pubChrSupOrgNonUS', type: 'radio', lookup: 'YESNO_RADIO', col: 2,
                                logicFunction: [
                                    { trigger: 'showPubChrSupOrgNonUSFields', triggerRelatedForms: { basicData: basicDataForm }, setGlobalFormState: setGlobalFormState }
                                ]
                            },
                        ]
                    },
                    {
                        groupId: 15,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization have ultimate control and discretion in deciding whether to make grants to the foreign supported organization?', name: 'pubChrSupOrgNonUSGrntCtrlLabel', type: 'label', col: 10 },
                            {
                                label: '', name: 'pubChrSupOrgNonUSGrntCtrl', type: 'radio', lookup: 'YESNO_RADIO', col: 2,
                                logicFunction: [
                                    { trigger: 'showPubChrSupOrgNonUSGrntCtrlDesc', triggerRelatedForms: { basicData: basicDataForm }, setGlobalFormState: setGlobalFormState }
                                ]
                            },
                        ]
                    },
                    {
                        groupId: 16,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Describe how the organization had such control and discretion despite being controlled or supervised by or in connection with its supported organizations:', name: 'pubChrSupOrgNonUSGrntCtrlDescLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgNonUSGrntCtrlDesc',
                                type: 'textarea',
                                col: 12,
                                maxRows: 3,
                                class: 'fieldTextArea',
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_A4B,
                                    true
                                )
                            },
                        ]
                    },
                    {
                        groupId: 17,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization support any foreign supported organization that does not have an IRS determination under sections 501(c)(3) and 509(a)(1) or 509(a)(2)?', name: 'pubChrSupOrgNonUSnoIRSLabel', type: 'label', col: 9 },
                            {
                                label: '', name: 'pubChrSupOrgNonUSnoIRS', type: 'radio', lookup: 'YESNO_RADIO', col: 3,
                                logicFunction: [
                                    { trigger: 'showPubChrSupOrgNonUSnoIRSDesc', triggerRelatedForms: { basicData: basicDataForm }, setGlobalFormState: setGlobalFormState }
                                ]
                            },
                        ]
                    },
                    {
                        groupId: 18,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Explain what controls the organization used to ensure that all support to the foreign supported organization was used exclusively for charitable purposes:', name: 'pubChrSupOrgNonUSnoIRSDescLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgNonUSnoIRSDesc',
                                type: 'textarea',
                                col: 12,
                                maxRows: 3,
                                class: 'fieldTextArea',
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_A4C,
                                    true
                                )
                            },
                        ]
                    },
                    {
                        groupId: 19,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization add, substitute, or remove any supported organizations during the tax year?', name: 'pubChrSupOrgAddSubRmvLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgAddSubRmv', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgAdd' }] },
                        ]
                    },
                    {
                        groupId: 20,
                        isVisible: false,
                        fields: [
                            { label: 'Provide the following information about the organizations that were added/removed/substituted:', name: 'pubChrSupOrgAdd', type: 'label', col: 10 },
                        ]
                    },
                    {
                        groupType: 'lineItem',
                        isVisible: false,
                        hasDenseRow: true,
                        hasDivider: true,
                        groupId: 21,
                        fields: [
                            { label: 'Name', name: 'pubChrSupOrgAddName', type: 'freeText' },
                            { label: 'EIN', name: 'pubChrSupOrgAddEIN', type: 'ein' },
                            { label: 'Action', name: 'pubChrSupOrgAddAction', type: 'select', lookup: 'SUPPORTED_ORG_ACTIONS', logicFunction: [{ trigger: 'showActionFields', isDifferentGroup: true }] },
                            { label: 'Yes/No', name: 'pubChrSupOrgAddActionAuth', type: 'select' },
                            { label: 'Details', name: 'additionalInfo', type: 'viewButton', viewSectionId: 100 },
                            { type: 'lineButtons' }
                        ],
                        lineItems: [],
                        entities: [],
                        currentEntity: -1,
                        lineSections: supportedOrgActionLineSections,
						headerLabelsPrintStyles: {gridTemplateColumns: '1.75fr 1.75fr 1.75fr 1.75fr 1fr .75fr'},
                        lineItemDetails:
                        {
                            headerLabels: [
                                { label: 'Name', col: 3, colTablet: 2, styles: { paddingLeft: '48px' } },
                                { label: 'EIN', col: 2, colTablet: 2, styles: { paddingLeft: '40px' } },
                                { label: 'Action', col: 2, colTablet: 1, styles: { paddingLeft: '36px' } },
                                { label: 'Action Authorized via Organizing Documents', col: 2, colTablet: 1, styles: { paddingLeft: '28px' } },
                                { label: 'Additional Info', col: 2, colTablet: 1, styles: { textAlign: 'center', paddingLeft: '15px' } },
                                { label: '', col: 1, colTablet: 1, },
                            ],
                            footerLabels: [
                                { type: 'buttons', col: 12, buttonTitle: 'Add Another Supported Organization', icon: 'add' },
                            ],
                            lineButtons: [
                                { button: 'remove', },
                            ]
                        }
                    },
                    {
                        groupId: 22,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Was any added or substituted supported organization part of a class already designated in the organization\'s organizing document?', name: 'pubChrSupOrgAddSubOrgDocLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgAddSubOrgDoc', type: 'radio', lookup: 'YESNO_RADIO', col: 2 },
                        ]
                    },
                    {
                        groupId: 23,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Was the substitution the result of an event beyond the organization\'s control?', name: 'pubChrSupOrgSubByndCtrlLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgSubByndCtrl', type: 'radio', lookup: 'YESNO_RADIO', col: 2 },
                        ]
                    },
                    {
                        groupId: 24,
                        isVisible: true,
                        fields: [
                            { label: 'Did the organization provide support (such as grants, services, or facilities) to anyone other than:', name: 'textEleven', type: 'label', col: 12 },
                        ]
                    },
                    {
                        groupId: 25,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Its supported organizations?', name: 'pubChrSupOrgPrvdSupALabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgPrvdSupA', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showpubChrSupOrgPrvdSupDtl' }] },
                        ]
                    },
                    {
                        groupId: 26,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Individuals that are part of the charitable class benefited by one or more of its supported organizations?', name: 'pubChrSupOrgPrvdSupBLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgPrvdSupB', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showpubChrSupOrgPrvdSupDtl' }] },
                        ]
                    },
                    {
                        groupId: 27,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Other supporting organizations that also support or benefit one or more of its supported organizations?', name: 'pubChrSupOrgPrvdSupCLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgPrvdSupC', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showpubChrSupOrgPrvdSupDtl' }] },
                        ]
                    },
                    {
                        groupId: 28,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Please provide details:', name: 'pubChrSupOrgPrvdSupDtlLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgPrvdSupDtl',
                                type: 'textarea',
                                col: 12,
                                class: 'fieldTextArea',
                                maxLength: 900,
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_A6,
                                    true
                                )
                            },
                        ]
                    },
                    {
                        groupId: 29,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization provide a grant, loan, compensation, or other similar payment to a substantial contributor, a family member of a substantial contributor, an entity controlled by a substantial contributor, or any other disqualified person?', name: 'pubChrSupOrgGrntLabel', type: 'label', col: 10 },
                            {
                                label: '',
                                name: 'pubChrSupOrgGrnt',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 2,
                                logicFunction: [
                                    {
                                        trigger: 'setCardRequired',
                                        requiredCards: [INTERESTED_PERSONS_TRANSACTIONS]
                                    },
                                    {
                                        trigger: 'showExcessBenefitsTransactions',
                                        triggerRelatedForms: { interestedPersons: interestedPersonsForm },
                                        setGlobalFormState: setGlobalFormState
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        groupId: 30,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Was the organization controlled directly or indirectly at any time during the tax year by one or more disqualified persons (other than foundation managers and organizations described in section 509(a)(1) or (2))?', name: 'pubChrSupOrgDqCtrlLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgDqCtrl', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgDqCtrlExp' }] },
                        ]
                    },
                    {
                        groupId: 31,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Please provide details:', name: 'pubChrSupOrgDqCtrlExpLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgDqCtrlExp',
                                type: 'textarea',
                                col: 12,
                                class: 'fieldTextArea',
                                maxLength: 900,
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_A9A,
                                    true
                                )
                            },
                        ]
                    },
                    {
                        groupId: 32,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did one or more disqualified persons hold a controlling interest in any entity in which the supporting organization had an interest?', name: 'pubChrSupOrgDqCtrlIntLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgDqCtrlInt', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgDqCtrlIntExp' }] },
                        ]
                    },
                    {
                        groupId: 33,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Please provide details:', name: 'pubChrSupOrgDqCtrlIntExpLabel', type: 'label', class: 'labelTitleTextArea', col: 12 },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgDqCtrlIntExp',
                                type: 'textarea',
                                col: 12,
                                class: 'fieldTextArea',
                                maxLength: 900,
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_A9B,
                                    true
                                )
                            },
                        ]
                    },
                    {
                        groupId: 34,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did a disqualified person have an ownership interest in, or derive any personal benefit from, assets in which the supporting organization also had an interest?', name: 'pubChrSupOrgDqOwnLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgDqOwn', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgDqOwnExp' }] },
                        ]
                    },
                    {
                        groupId: 35,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Please provide details:', name: 'pubChrSupOrgDqOwnExpLabel', type: 'label', class: 'labelTitleTextArea', col: 12 },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgDqOwnExp',
                                type: 'textarea',
                                col: 12,
                                class: 'fieldTextArea',
                                maxLength: 900,
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_A9C,
                                    true
                                ),
                            },
                        ]
                    },
                    {
                        groupId: 36,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization have excess business holdings at any time during the year?', name: 'pubChrSupOrgExcsBusHoldLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgExcsBusHold', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showTextThirteen' }] },
                            { label: 'Excess Business holdings may be subject to excise tax, please contact CLA to discuss further.', name: 'textThirteen', type: 'label', isVisible: false, col: 12 },
                        ]
                    },
                    {
                        groupId: 37,
                        isVisible: true,
                        // hasDivider: true,
                        fields: [
                            { label: 'Has the organization accepted a gift or contribution from any of the following:', name: 'textFourteen', type: 'label', col: 12 },
                        ]
                    },
                    {
                        groupId: 38,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'A) A person who directly or indirectly controls, either alone or together with persons described in (b) and (c) below, the governing body of a supported organization?', name: 'pubChrSupOrgGiftODKLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgGiftODK', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgGiftDtl' }] },
                        ]
                    },
                    {
                        groupId: 39,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'B) Family member of a person described in (a) above?', name: 'pubChrSupOrgGiftODKFamMbrLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgGiftODKFamMbr', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgGiftDtl' }] },
                        ]
                    },
                    {
                        groupId: 40,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'C) A 35% controlled entity of a person described in (a) above?', name: 'pubChrSupOrgGift35Label', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgGift35', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgGiftDtl' }] },
                        ]
                    },
                    {
                        groupId: 41,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Please provide details:', name: 'pubChrSupOrgGiftDtlLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgGiftDtl',
                                type: 'textarea',
                                col: 12,
                                class: 'fieldTextArea',
                                maxLength: 900,
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_A11,
                                    true
                                )
                            },
                        ]
                    },

                ]
            },
            {
                title: 'Supporting Organization Type',
                isVisible: false,
                sectionId: 6,
                groups: [
                    {
                        groupId: 1,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the directors, trustees, or membership of one or more supported organizations have the power to regularly appoint or elect at least a majority of the organization’s directors or trustees at all times during the tax year?', name: 'pubChrSupOrgPwrApptLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgPwrAppt', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgPwrApptDesc' }] },
                        ]
                    },
                    {
                        groupId: 2,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Describe how the supported organization(s) effectively operated, supervised, or controlled the organization’s activities:', col: 12, type: 'label', class: 'labelTitleTextArea', name: 'pubChrSupOrgPwrApptDescLabel',
                            },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgPwrApptDesc',
                                type: 'textarea',
                                maxLength: 900,
                                class: 'fieldTextArea',
                                col: 12,
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_B1,
                                    true
                                )
                            }
                        ]
                    },
                    {
                        groupId: 3,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization operate for the benefit of any supported organization other than the supported organization(s) that operated, supervised, or controlled the supporting organization?', name: 'pubChrSupOrgOpBenLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgOpBen', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgOpBenExpl' }] },
                        ]
                    },
                    {
                        groupId: 4,
                        isVisible: false,
                        fields: [
                            { label: 'Explain how providing such benefit carried out the purposes of the supported organization(s) that operated, supervised, or controlled the supporting organization:', name: 'pubChrSupOrgOpBenExplLabel', type: 'label', class: 'labelTitleTextArea', col: 12 },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgOpBenExpl',
                                type: 'textarea',
                                col: 12,
                                maxLength: 900,
                                hasDivider: 'yes',
                                class: 'fieldTextArea',
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_B2,
                                    true
                                )
                            }
                        ]
                    },
                    {
                        groupId: 5,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Were a majority of the organization’s directors or trustees during the tax year also a majority of the directors or trustees of each of the organization’s supported organization(s)?', name: 'pubChrSupOrgMjrBothLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgMjrBoth', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showPubChrSupOrgMjrBothDesc' }] },
                        ]
                    },
                    {
                        groupId: 6,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Describe how control or management of the supporting organization was vested in the same persons that controlled or managed the supported organization(s):', name: 'pubChrSupOrgMjrBothDescLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgMjrBothDesc',
                                type: 'textarea',
                                maxLength: 900,
                                class: 'fieldTextArea',
                                col: 12,
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_C1,
                                    true
                                )
                            }
                        ]
                    },
                    {
                        groupId: 7,
                        isVisible: false,
                        fields: [
                            { label: 'Did the organization provide to each of its supported organizations, by the last day of the fifth month of the organization’s tax year:', name: 'textFifteen', type: 'label', col: 12 },
                        ]
                    },
                    {
                        groupId: 8,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'A written notice describing the type and amount of support provided during the prior tax year?', name: 'pubChrSupOrgWtrnNtcLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgWtrnNtc', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showTextSixteen' }] },
                        ]
                    },
                    {
                        groupId: 9,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'A copy of the Form 990 that was most recently filed as of the date of notification?', name: 'pubChrSupOrgCopy990Label', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgCopy990', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showTextSixteen' }] },
                        ]
                    },
                    {
                        groupId: 10,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Copies of the organization’s governing documents in effect on the date of notification, to the extent not previously provided?', name: 'pubChrSupOrgCopyGovDocLabel', type: 'label', col: 10 },
                            { label: '', name: 'pubChrSupOrgCopyGovDoc', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showTextSixteen' }] },
                        ]
                    },
                    {
                        groupId: 11,
                        isVisible: false,
                        fields: [
                            { label: 'This notification is required, please contact CLA with any questions on what action steps are needed', name: 'textSixteen', type: 'label', col: 12 },
                        ]
                    },
                    {
                        groupId: 12,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Were any of the organization’s officers, directors, or trustees either (i) appointed or elected by the supported organization(s) or (ii) serving on the governing body of a supported organization?', name: 'pubChrSupOrgOdtSrvGovBdyLabel', type: 'label', col: 10 },
                            {
                                label: '',
                                name: 'pubChrSupOrgOdtSrvGovBdy',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 2,
                                logicFunction: [{ trigger: 'showPubChrSupOrgOdtSrvGovBdyExp' }],
                                ...pullFieldValue(['XFDSSUPD.1'], false)
                            },
                        ]
                    },
                    {
                        groupId: 13,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Explain how the organization maintained a close and continuous working relationship with the supported organization(s):', name: 'pubChrSupOrgOdtSrvGovBdyExpLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgOdtSrvGovBdyExp',
                                type: 'textarea',
                                maxLength: 900,
                                class: 'fieldTextArea',
                                col: 12,
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_D2,
                                    true
                                )
                            }
                        ]
                    },
                    {
                        groupId: 14,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization’s supported organization(s) have a significant voice in the organization’s investment policies and in directing the use of the organization’s income or assets at all times during the tax year?', name: 'pubChrSupOrgSignfVoiceLabel', type: 'label', col: 10 },
                            {
                                label: '',
                                name: 'pubChrSupOrgSignfVoice',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 2,
                                logicFunction: [{ trigger: 'showPubChrSupOrgSignfVoiceDesc' }],
                                ...pullFieldValue(['XFDSSUPD.2'], true)
                            },
                        ]
                    },
                    {
                        groupId: 15,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Describe the role the organization’s supported organizations played in this regard:', name: 'pubChrSupOrgSignfVoiceDescLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgSignfVoiceDesc',
                                type: 'textarea',
                                maxLength: 900,
                                class: 'fieldTextArea',
                                col: 12,
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_D3,
                                    true
                                )
                            }
                        ]
                    },
                    {
                        groupId: 16,
                        isVisible: false,
                        fields: [
                            { label: 'Which of the following methods did the organization use to satisfy the Integral Part Test during the year:', name: 'textSeventeen', type: 'label', col: 12 },
                        ]
                    },
                    {
                        groupId: 17,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'The organization satisfied the Activities Test', name: 'pubChrSupOrgActTestLabel', type: 'label', col: 10, class: 'labelSingleCheckbox' },
                            {
                                label: 'Yes',
                                type: 'checkbox',
                                name: 'pubChrSupOrgActTest',
                                isFieldCheckbox: true,
                                col: 2,
                                class: 'singleCheckboxMargin',
                                logicFunction: [{ trigger: 'showpPubChrSupOrgSubstExPurp' }],
                                prior: true,
                                isPriorEditable: true,
                                axcess: {
                                    pull: {
                                        fields: ['XFDSSUPE.0'],
                                        fn: 'checkboxValuePull'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        groupId: 18,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did substantially all of the organization’s activities during the tax year directly further the exempt purposes of the supported organization(s) to which the organization was responsive?', name: 'pubChrSupOrgSubstExPurpLabel', type: 'label', col: 10 },
                            {
                                label: '',
                                name: 'pubChrSupOrgSubstExPurp',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 2,
                                logicFunction: [{ trigger: 'showPubChrSupOrgIdExp' }],
                                ...pullFieldValue(['XFDSSUPE.3'], true)
                            },
                        ]
                    },
                    {
                        groupId: 19,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Identify those supported organizations and explain: How these activities directly furthered their exempt purposes; How the organization was responsive to those supported organizations; and How the organization determined that these activities constituted substantially all of its activities', name: 'pubChrSupOrgIdExpLabel', class: 'labelTitleTextArea', type: 'label', col: 12 },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgIdExp',
                                type: 'textarea',
                                maxLength: 900,
                                class: 'fieldTextArea',
                                col: 12,
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_E2A,
                                    true
                                )
                            }
                        ]
                    },
                    {
                        groupId: 20,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the activities described constitute activities that, but for the organization’s involvement, one or more of the organization’s supported organization(s) would have been engaged in?', name: 'pubChrSupOrgConstInvLabel', type: 'label', col: 10 },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgConstInv',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 2,
                                logicFunction: [{ trigger: 'showPubChrSupOrgConstInvExp' }],
                                ...pullFieldValue(['XFDSSUPE.4'], true)
                            },
                        ]
                    },
                    {
                        groupId: 21,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Explain the reasons for the organization’s position that its supported organization(s) would have engaged in these activities but for the organization’s involvement:', name: 'pubChrSupOrgConstInvExpLabel', type: 'label', class: 'labelTitleTextArea', col: 12 },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgConstInvExp',
                                type: 'textarea',
                                maxLength: 900,
                                class: 'fieldTextArea',
                                col: 12,
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_E2B,
                                    true
                                )
                            }
                        ]
                    },
                    {
                        groupId: 22,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'The organization is the parent of each of its supported organizations', name: 'pubChrSupOrgParentLabel', type: 'label', col: 10, class: 'labelSingleCheckbox' },
                            {
                                label: 'Yes',
                                type: 'checkbox',
                                name: 'pubChrSupOrgParent',
                                isFieldCheckbox: true,
                                col: 2,
                                class: 'singleCheckboxMargin',
                                logicFunction: [{ trigger: 'showPubChrSupOrgPwrAptODT' }],
                                prior: true,
                                isPriorEditable: true,
                                axcess: {
                                    pull: {
                                        fields: ['XFDSSUPE.1'],
                                        fn: 'checkboxValuePull'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        groupId: 23,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization have the power to regularly appoint or elect a majority of the officers, directors, or trustees of each of the supported organizations?', name: 'pubChrSupOrgPwrAptODTLabel', col: 10, type: 'label' },
                            {
                                label: '',
                                name: 'pubChrSupOrgPwrAptODT',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 2,
                                logicFunction: [{ trigger: 'showPubChrSupOrgPwrAptODTdesc' }],
                                ...pullFieldValue(['XFDSSUPE.5'], true)
                            },
                        ]
                    },
                    {
                        groupId: 24,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Please provide details:', name: 'pubChrSupOrgPwrAptODTdescLabel', class: 'labelTitleTextArea', type: 'label', col: 12 },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgPwrAptODTdesc',
                                type: 'textarea',
                                col: 12,
                                class: 'fieldTextArea',
                                maxLength: 900,
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_E3A,
                                    true
                                )
                            }
                        ]
                    },
                    {
                        groupId: 25,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization exercise a substantial degree of direction over the policies, programs, and activities of each of its supported organizations?', name: 'pubChrSupOrgDirPlcyPrgrmLabel', type: 'label', col: 10 },
                            {
                                label: '',
                                name: 'pubChrSupOrgDirPlcyPrgrm',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 2,
                                logicFunction: [{ trigger: 'showPubChrSupOrgDirPlcyPrgrmDesc' }],
                                ...pullFieldValue(['XFDSSUPE.6'], true)
                            },
                        ]
                    },
                    {
                        groupId: 26,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Describe the role played by the organization in this regard:', name: 'pubChrSupOrgDirPlcyPrgrmDescLabel', class: 'labelTitleTextArea', type: 'label', col: 12 },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgDirPlcyPrgrmDesc',
                                type: 'textarea',
                                col: 12,
                                maxLength: 900,
                                class: 'fieldTextArea',
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_E3B,
                                    true
                                )
                            }
                        ]
                    },
                    {
                        groupId: 27,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'The organization supported a governmental entity', name: 'pubChrSupOrgGovEntLabel', type: 'label', col: 10, class: 'labelSingleCheckbox' },
                            {
                                label: 'Yes',
                                type: 'checkbox',
                                name: 'pubChrSupOrgGovEnt',
                                isFieldCheckbox: true,
                                col: 2,
                                class: 'singleCheckboxMargin',
                                logicFunction: [{ trigger: 'showPubChrSupOrgGovEntDescDir' }],
                                prior: true,
                                isPriorEditable: true,
                                axcess: {
                                    pull: {
                                        fields: ['XFDSSUPE.2'],
                                        fn: 'checkboxValuePull'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        groupId: 28,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Describe how the organization exercised a substantial degree of direction over the government entity: [Requires completion of Part VI of Sched A-See Tax form]', class: 'labelTitleTextArea', name: 'pubChrSupOrgGovEntDescDirLabel', type: 'label', col: 12 },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgGovEntDescDir',
                                type: 'textarea',
                                col: 12,
                                maxLength: 900,
                                class: 'fieldTextArea',
                                ...pullFieldValueIfOtherEquals(
                                    'XFDSAEXP.0',
                                    'XFDSAEXP.1',
                                    AXCESS_KEYS.DIAGNOSTIC_REFERENCE_CODE_E1C,
                                    true
                                )

                            }
                        ]
                    },
                    {
                        groupId: 29,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization satisfy the Integral Part Test as a qualifying trust on Nov. 20, 1970?', name: 'pubChrSupOrgQualTrstLabel', type: 'label', col: 10 },
                            {
                                label: 'Please enter your comments/questions',
                                name: 'pubChrSupOrgQualTrst',
                                type: 'radio',
                                lookup: 'YESNO_RADIO',
                                col: 2,
                                ...pullFieldValue(['XFDSSUP5.5'], true)
                            },
                        ]
                    },
                    {
                        groupId: 30,
                        isVisible: false,
                        hasDivider: true,
                        groupType: 'lineItem',
                        uploadType: 'drawer',
                        class: 'householdEmploymentUpload',
                        fields: [
                            {
                                label: 'Upload your monthly investment statements here:',
                                name: 'pubChrSupOrgMthInvStmtLabel',
                                type: 'label',
                                col: 9
                            },
                            { type: 'lineButtons', col: 3 }
                        ],
                        lineItems: [],
                        lineItemDetails: {
                            headerLabels: [],
                            footerLabels: [],
                            lineButtons: [
                                { button: 'uploadOnly', uploadSectionName: 'pubChrSupOrgMthInvStmt', viewSectionId: 0 },
                            ]
                        }
                    },
                    {
                        groupId: 31,
                        isVisible: false,
                        hasDivider: true,
                        groupType: 'lineItem',
                        uploadType: 'drawer',
                        class: 'householdEmploymentUpload',
                        fields: [
                            {
                                label: 'Upload your monthly bank account statements here: ',
                                name: 'pubCharSupOrgMthBkAccStmtLabel',
                                type: 'label',
                                col: 9
                            },
                            { type: 'lineButtons', col: 3 }
                        ],
                        lineItems: [],
                        lineItemDetails: {
                            headerLabels: [],
                            footerLabels: [],
                            lineButtons: [
                                { button: 'uploadOnly', uploadSectionName: 'pubCharSupOrgMthBkAccStmt', viewSectionId: 0 },
                            ]
                        }
                    },
                ]
            },
            {  
                title: 'Additional Information',
                collapseClass: 'collapsibleForm',
                isVisible: false,
                isDefaultExpanded: false,
                isOptionalSectionMerge: true,
                sectionId: 7,
                triggered: 'showPubCharAddInfo',
                groups: [
                    {
                        groupId: 2,
                        groupType: 'details',
                        fields: [
                            { 
                                label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
                                type: 'label', 
                                class: 'labelLeftLight' 
                            },
                        ]
                    },
                    {
                        groupId: 1,
                        fields: [
                            {
                                label: 'Start typing to provide additional information', 
                                name: 'additionalInfoTextArea', 
                                type: 'textarea', 
                                col: 12,
                                maxLength: 9000
                            }
                        ]
                    }
                ]
            },
        ];
        setSectionState(sections);
        // eslint-disable-next-line	
    }, []);

    const axcessIdentifiers = [
        { section: 'Schedules', id: 'Sch A' }
    ];

    return (
        <>
            {!isLoading && isActiveClient && <FormRendererHelper
                sections={sectionState}
                key={REDUX.PUBLIC_CHARITY_AND_SUPPORT}
                formName={REDUX.PUBLIC_CHARITY_AND_SUPPORT}
                identifiers={axcessIdentifiers}
                triggeredEvent={triggeredEvent}
                sectionFieldLogic={sectionFieldLogic}
                isExportForm={isExportForm} />
            }
            {!isLoading && !isActiveClient && <NoActiveClient />}
        </>
    );
}

export default PubCharStaSec;