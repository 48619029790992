import * as EVENT from '@utilities/constants/triggerKeys';
import * as STR from '@utilities/constants/strings';
import { form } from '@utilities/helpers/getFormSections';

// const sortTopContractors = (event, options) => {
//     const { sections, formSections, setFormSections } = options;
//     const name = formSections ? formSections : sections;

//     const sortByCompensationDec = (groupA, groupB) => Number(groupB.fields[2]?.default) - Number(groupA.fields[2]?.default);

//     name[2].groups.sort(sortByCompensationDec);
//     setFormSections(name);
// };

// const setOrgsDividers = (section, value, orgType) => {
//     let compFilingIsVisible = section[2].groups[0].isVisible;
//     let compRelatedIsVisible = section[2].groups[1].isVisible;
//     let compUnrelatedIsVisible = section[2].groups[2].isVisible;

//     let benefitsFilingIsVisible = section[3].groups[0].isVisible;
//     let benefitsRelatedIsVisible = section[3].groups[1].isVisible;
//     let benefitsUnrelatedIsVisible = section[3].groups[2].isVisible;

//     let fiscalFilingIsVisible = section[4].groups[0].isVisible;
//     let fiscalRelatedIsVisible = section[4].groups[1].isVisible;
//     let fiscalUnrelatedIsVisible = section[4].groups[2].isVisible;

//     if (orgType === 'filing') {
//         if (compFilingIsVisible && !compRelatedIsVisible && !compUnrelatedIsVisible) {
//             section[2].groups[0].hasDivider = !value;
//         } else section[2].groups[0].hasDivider = value;

//         if (benefitsFilingIsVisible && !benefitsRelatedIsVisible && !benefitsUnrelatedIsVisible) {
//             section[3].groups[0].hasDivider = !value;
//         } else section[3].groups[0].hasDivider = value;

//         if (fiscalFilingIsVisible && !fiscalRelatedIsVisible && !fiscalUnrelatedIsVisible) {
//             section[4].groups[0].hasDivider = !value;
//         } else section[4].groups[0].hasDivider = value;
//     } else if (orgType === 'related') {
//         if (compFilingIsVisible && compRelatedIsVisible && !compUnrelatedIsVisible) {
//             section[2].groups[0].hasDivider = value;
//             section[2].groups[1].hasDivider = !value;
//         } else if (
//             compFilingIsVisible && 
//             compRelatedIsVisible && 
//             compUnrelatedIsVisible) section[2].groups[1].hasDivider = value;
//         else if (
//             compFilingIsVisible && 
//             !compRelatedIsVisible && 
//             !compUnrelatedIsVisible) section[2].groups[0].hasDivider = value;
//         else if (
//             !compFilingIsVisible && 
//             compRelatedIsVisible && 
//             compUnrelatedIsVisible) section[2].groups[1].hasDivider = value;
//         else if (
//             !compFilingIsVisible && 
//             compRelatedIsVisible && 
//             !compUnrelatedIsVisible) section[2].groups[1].hasDivider = !value;

//         if (benefitsFilingIsVisible && benefitsRelatedIsVisible && !benefitsUnrelatedIsVisible) {
//             section[3].groups[0].hasDivider = value;
//             section[3].groups[1].hasDivider = !value;
//         } else if (
//             benefitsFilingIsVisible && 
//             benefitsRelatedIsVisible && 
//             benefitsUnrelatedIsVisible) section[3].groups[1].hasDivider = value;
//         else if (
//             benefitsFilingIsVisible && 
//             !benefitsRelatedIsVisible && 
//             !benefitsUnrelatedIsVisible) section[3].groups[0].hasDivider = value;
//         else if (
//             !benefitsFilingIsVisible && 
//             benefitsRelatedIsVisible && 
//             benefitsUnrelatedIsVisible) section[3].groups[1].hasDivider = value;
//         else if (
//             !benefitsFilingIsVisible && 
//             benefitsRelatedIsVisible && 
//             !benefitsUnrelatedIsVisible) section[3].groups[1].hasDivider = !value;

//         if (fiscalFilingIsVisible && fiscalRelatedIsVisible && !fiscalUnrelatedIsVisible) {
//             section[4].groups[0].hasDivider = value;
//             section[4].groups[1].hasDivider = !value;
//         } else if (
//             fiscalFilingIsVisible && 
//             fiscalRelatedIsVisible && 
//             fiscalUnrelatedIsVisible) section[4].groups[1].hasDivider = value;
//         else if (
//             fiscalFilingIsVisible && 
//             !fiscalRelatedIsVisible && 
//             !fiscalUnrelatedIsVisible) section[4].groups[0].hasDivider = value;
//         else if (
//             !fiscalFilingIsVisible && 
//             fiscalRelatedIsVisible && 
//             fiscalUnrelatedIsVisible) section[4].groups[1].hasDivider = value;
//         else if (
//             !fiscalFilingIsVisible && 
//             fiscalRelatedIsVisible && 
//             !fiscalUnrelatedIsVisible) section[4].groups[1].hasDivider = !value;
//     } else if (orgType === 'unrelated') {
//         if (compUnrelatedIsVisible && compRelatedIsVisible) {
//             section[2].groups[1].hasDivider = value;
//         } else if (!compUnrelatedIsVisible && compRelatedIsVisible) section[2].groups[1].hasDivider = value;
//         else if (!compUnrelatedIsVisible && compFilingIsVisible) section[2].groups[0].hasDivider = value;
//         else if (compUnrelatedIsVisible && compFilingIsVisible) section[2].groups[0].hasDivider = value;

//         if (benefitsUnrelatedIsVisible && benefitsRelatedIsVisible) {
//             section[3].groups[1].hasDivider = value;
//         } else if (!benefitsUnrelatedIsVisible && benefitsRelatedIsVisible) section[3].groups[1].hasDivider = value;
//         else if (!benefitsUnrelatedIsVisible && benefitsFilingIsVisible) section[3].groups[0].hasDivider = value;
//         else if (benefitsUnrelatedIsVisible && benefitsFilingIsVisible) section[3].groups[0].hasDivider = value;

//         if (fiscalUnrelatedIsVisible && fiscalRelatedIsVisible) {
//             section[4].groups[1].hasDivider = value;
//         } else if (!fiscalUnrelatedIsVisible && fiscalRelatedIsVisible) section[4].groups[1].hasDivider = value;
//         else if (!fiscalUnrelatedIsVisible && fiscalFilingIsVisible) section[4].groups[0].hasDivider = value;
//         else if (fiscalUnrelatedIsVisible && fiscalFilingIsVisible) section[4].groups[0].hasDivider = value;
//     }

// }

const showHeadersSummary = (name) => {
    let filteredGroup = name.map(section => section.groups.filter(group => group.isVisible))
        .filter(item => item.length > 0);
    if (filteredGroup.length > 0) {
        name[3].headers.forEach(header => header.isVisible = true);
        name[4].headers.forEach(header => header.isVisible = true);
    } else {
        name[3].headers.forEach(header => header.isVisible = false);
        name[4].headers.forEach(header => header.isVisible = false);
    }
}

const showIndividualName = (name) => {
    // const avgHoursVisibleFields = name[1]
    //     .groups[0].fields.filter((field, index) => index > 0 && field.isVisible);
    name[1].groups[0].fields[0].isVisible = true;
}

const showFilingOrgFields = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    // name[1].groups[0].fields[1].isVisible = event;
    name[2].groups[0].isVisible = event;
    name[3].groups[0].isVisible = event;
    name[4].groups[0].isVisible = event;
    name[2].headers[0].isVisible = event;
    showIndividualName(name);
    showHeadersSummary(name);
    setFormSections(name);
};

const showRelatedOrgFields = (event, options) => {
    const { sections, formSections, setFormSections} = options;
    const name = formSections ? formSections : sections;

    // name[1].groups[0].fields[2].isVisible = event;
    name[2].groups[1].isVisible = event;
    name[3].groups[1].isVisible = event;
    name[4].groups[1].isVisible = event;
    name[2].headers[1].isVisible = event;
    showIndividualName(name);
    showHeadersSummary(name);
    setFormSections(name);
};

const showUnrelatedOrgFields = (event, options) => {
    const { sections, formSections, setFormSections} = options;
    const name = formSections ? formSections : sections;

    // name[1].groups[0].fields[3].isVisible = event;
    name[2].groups[2].isVisible = event;
    name[3].groups[2].isVisible = event;
    name[4].groups[2].isVisible = event;
    name[2].headers[2].isVisible = event;
    showIndividualName(name);
    showHeadersSummary(name);
    setFormSections(name);
};

const showCompDetailsOrgsFields = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    name[2].groups.forEach(group => {
        let fields = group.fields
            .filter(field => field.name !== 'compIndvName' && field.totalOrg !== 'compW2Total');

        fields.forEach(field => {
            field.isVisible = event;
            field.error = false;
            field.errorMessage = null;
        });
    });
    
    setFormSections(name);
};

const showSupCompInfoSection = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const isCompOver150k = event === EVENT.KEY_YES;
    // const hasFormerIndv = name[0]?.groups[6]?.entities.find(entity => entity.sections[0]?.groups[0]?.fields[2]?.default === EVENT.KEY_FORMER);
    // const isNotInstTrustee = name[0]?.groups[6]?.entities.find(entity => entity.sections[0]?.groups[0]?.fields[2]?.default !== EVENT.KEY_TRUSTEE);
    // let compTotal = 0;
    // if (isNotInstTrustee) {
    //     compTotal = parseMoneyToNumber(isNotInstTrustee.sections[2]?.footers[0]?.label);
    // }
    // const hasCompIndvOver150k = compTotal > 150000;

    // if (isCompOver150k || hasFormerIndv || hasCompIndvOver150k) {
    //     name[1].isVisible = true;
    // } else  name[1].isVisible = false;

    name[1].isVisible = isCompOver150k;
    setFormSections(name);
};

const showSupCompPerksQuestions = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    const supCompPerks1 = name[1].groups[2].fields[1].default;
    const supCompPerks2 = name[1].groups[3].fields[1].default;
    const supCompPerks3 = name[1].groups[4].fields[1].default;
    const supCompPerks4 = name[1].groups[5].fields[1].default;
    const supCompPerks5 = name[1].groups[6].fields[1].default;
    const supCompPerks6 = name[1].groups[7].fields[1].default;
    const supCompPerks7 = name[1].groups[8].fields[1].default;
    const supCompPerks8 = name[1].groups[9].fields[1].default;
    const supCompPerksQuestionsVisible = supCompPerks1 || supCompPerks2 || supCompPerks3 || supCompPerks4 || supCompPerks5 || supCompPerks6 || supCompPerks7 || supCompPerks8;
    const supCompPerksPolicy = name[1].groups[11].fields[1].default;
    const supCompPerksW2 = name[1].groups[13].fields[1].default;


    if (event) {
        name[1].groups[10].isVisible = event;
        name[1].groups[11].isVisible = event;
        name[1].groups[13].isVisible = event;
        name[1].groups[15].isVisible = event;
    } else {
        name[1].groups[10].isVisible = !!supCompPerksQuestionsVisible;
        name[1].groups[11].isVisible = !!supCompPerksQuestionsVisible;
        name[1].groups[13].isVisible = !!supCompPerksQuestionsVisible;
        name[1].groups[15].isVisible = !!supCompPerksQuestionsVisible;
    }

    if (supCompPerksQuestionsVisible) showSupCompPerksPolicyExplain(supCompPerksPolicy, options);
    else showSupCompPerksPolicyExplain(EVENT.KEY_YES, options);
    if (supCompPerksQuestionsVisible) showSupCompPerksW2Explain(supCompPerksW2, options);
    else showSupCompPerksW2Explain(EVENT.KEY_YES, options);

    setFormSections(name);
};

const showSupCompPerksPolicyExplain = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    name[1].groups[12].isVisible = event === EVENT.KEY_NO ? true : false;

    setFormSections(name);
};

const showSupCompPerksW2Explain = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    name[1].groups[14].isVisible = event === EVENT.KEY_NO ? true : false;;

    setFormSections(name);
};

const showSupCompMthdExpl = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    const supCompMthd1 = name[1].groups[17].fields[1].default;
    const supCompMthd2 = name[1].groups[18].fields[1].default;
    const supCompMthd3 = name[1].groups[19].fields[1].default;
    const supCompMthd4 = name[1].groups[20].fields[1].default;
    const supCompMthd5 = name[1].groups[21].fields[1].default;
    const supCompMthd6 = name[1].groups[22].fields[1].default;
    const supCompMthdExplVisible = supCompMthd1 || supCompMthd2 || supCompMthd3 || supCompMthd4 || supCompMthd5 || supCompMthd6;

    if (event) {
        name[1].groups[23].isVisible = event;
    } else {
        name[1].groups[23].isVisible = !!supCompMthdExplVisible;
    }

    setFormSections(name);
};

const setIndividualName = (event, options, fieldName) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    name.forEach((section, index) => {
        if (index > 0) {
            section.groups.forEach(group => {
                group.fields.forEach(field => {
                    if (field.name === 'compIndvName' && fieldName === 'compIndvName') {
                        
                        field.label = event;
                        field.default = event;

                        if (group.title) {
                            group.individualName = event && event !== ' ' ? event : '';
                        }
                    }
                    // if(field.name === 'compIndvTitle' && fieldName === 'compIndvTitle'){
                    //     field.label = event;
                    //     field.default = event;
                    // }
                })
            })
        }
    });

    setFormSections(name);
}

const setAverageHoursWeek = (event, options, fieldName) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    name.forEach((section, index) => {
        if (index === 1) {
            section.groups.forEach(group => {
                group.fields.forEach(field => {
                    if (field.name === 'compIndvAvgHrsOrg' && fieldName === 'compIndvAvgHrsOrg') {
                        field.default = event;
                    }
                    if(field.name === 'compIndvAvgHrsRelated' && fieldName === 'compIndvAvgHrsRelated'){
                        field.default = event;
                    }
                    if(field.name === 'compIndvAvgHrsUnrelated' && fieldName === 'compIndvAvgHrsUnrelated'){
                        field.default = event;
                    }
                })
            })
        }
    });

    setFormSections(name);
}

const showSupCompPay = (event, options, triggerFn) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_YES) {
        name[1].groups[28].isVisible = true;
        name[1].groups[29].isVisible = true;
    } else {
        let supCompSvrnc = name[1].groups[25].fields[1].default;
        let supCompNonQaulRet = name[1].groups[26].fields[1].default;
        let supCompEqtyBased = name[1].groups[27].fields[1].default;

        if ('supCompPay1' === triggerFn) {
            supCompSvrnc = event;
        } else if ('supCompPay2' === triggerFn) {
            supCompNonQaulRet = event;
        } else if ('supCompPay3' === triggerFn) {
            supCompEqtyBased = event;
        }

        const supCompPayVisible = supCompSvrnc === EVENT.KEY_YES || supCompNonQaulRet === EVENT.KEY_YES || supCompEqtyBased === EVENT.KEY_YES;
        name[1].groups[28].isVisible = supCompPayVisible;
        name[1].groups[29].isVisible = supCompPayVisible;
    }

    setFormSections(name);
}

const calculateBaseComp = (w2Amount, bonus, other) => w2Amount && w2Amount - (bonus + other);

const showFilingBaseCompAmount = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const w2filing = Number(name[2].groups[0].fields[1].default) || 0;
    const bonusFiling = Number(name[2].groups[0].fields[3].default) || 0;
    const otherFiling = Number(name[2].groups[0].fields[4].default) || 0;

    const baseComp = calculateBaseComp(w2filing, bonusFiling, otherFiling);
    name[2].groups[0].fields[2].default = `$${baseComp}`;
    name[2].groups[0].fields[2].amount = baseComp;
    name[2].groups[0].fields[3].isGreaterThanW2Amount = w2filing < bonusFiling + otherFiling;
    name[2].groups[0].fields[4].isGreaterThanW2Amount = w2filing < bonusFiling + otherFiling;

    if (name[2].groups[0].fields[3].isGreaterThanW2Amount) {
        name[2].groups[0].fields[3].error = true;
        name[2].groups[0].fields[3].errorMessage = STR.GREATER_THAN_W2;
    } else {
        name[2].groups[0].fields[3].error = false;
        name[2].groups[0].fields[3].errorMessage = null;
    }

    if (name[2].groups[0].fields[4].isGreaterThanW2Amount) {
        name[2].groups[0].fields[4].error = true;
        name[2].groups[0].fields[4].errorMessage = STR.GREATER_THAN_W2;
    } else {
        name[2].groups[0].fields[4].error = false;
        name[2].groups[0].fields[4].errorMessage = null;
    }

    setFormSections(name);
}

const showRelatedBaseCompAmount = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const w2filing = Number(name[2].groups[1].fields[1].default) || 0;
    const bonusFiling = Number(name[2].groups[1].fields[3].default) || 0;
    const otherFiling = Number(name[2].groups[1].fields[4].default) || 0;

    const baseComp = calculateBaseComp(w2filing, bonusFiling, otherFiling);
    name[2].groups[1].fields[2].default = `$${baseComp}`;
    name[2].groups[1].fields[2].amount = baseComp;
    name[2].groups[1].fields[3].isGreaterThanW2Amount = w2filing < bonusFiling + otherFiling;
    name[2].groups[1].fields[4].isGreaterThanW2Amount = w2filing < bonusFiling + otherFiling;

    if (name[2].groups[1].fields[3].isGreaterThanW2Amount) {
        name[2].groups[1].fields[3].error = true;
        name[2].groups[1].fields[3].errorMessage = STR.GREATER_THAN_W2;
    } else {
        name[2].groups[1].fields[3].error = false;
        name[2].groups[1].fields[3].errorMessage = null;
    }

    if (name[2].groups[1].fields[4].isGreaterThanW2Amount) {
        name[2].groups[1].fields[4].error = true;
        name[2].groups[1].fields[4].errorMessage = STR.GREATER_THAN_W2;
    } else {
        name[2].groups[1].fields[4].error = false;
        name[2].groups[1].fields[4].errorMessage = true;
    }

    setFormSections(name);
}

const showUnrelatedBaseCompAmount = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    const w2filing = Number(name[2].groups[2].fields[1].default) || 0;
    const bonusFiling = Number(name[2].groups[2].fields[3].default) || 0;
    const otherFiling = Number(name[2].groups[2].fields[4].default) || 0;

    const baseComp = calculateBaseComp(w2filing, bonusFiling, otherFiling);
    name[2].groups[2].fields[2].default = `$${baseComp}`;
    name[2].groups[2].fields[2].amount = baseComp;
    name[2].groups[2].fields[3].isGreaterThanW2Amount = w2filing < bonusFiling + otherFiling;
    name[2].groups[2].fields[4].isGreaterThanW2Amount = w2filing < bonusFiling + otherFiling;

    if (name[2].groups[2].fields[3].isGreaterThanW2Amount) {
        name[2].groups[2].fields[3].error = true;
        name[2].groups[2].fields[3].errorMessage = STR.GREATER_THAN_W2;
    } else {
        name[2].groups[2].fields[3].error = false;
        name[2].groups[2].fields[3].errorMessage = null;
    }

    if (name[2].groups[2].fields[4].isGreaterThanW2Amount) {
        name[2].groups[2].fields[4].error = true;
        name[2].groups[2].fields[4].errorMessage = STR.GREATER_THAN_W2;
    } else {
        name[2].groups[2].fields[4].error = false;
        name[2].groups[2].fields[4].errorMessage = null;
    }
}

const checkW2FilingAmtGreaterThan150K = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const w2Filing = Number(name[2].groups[0].fields[1].default) || 0;
    checkW2Amounts(w2Filing, name[2].groups[0].fields);

    setFormSections(name);
}

const checkW2RelatedAmtGreaterThan150K = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const bonusFiling = Number(name[2].groups[1].fields[1].default) || 0;
    checkW2Amounts(bonusFiling, name[2].groups[1].fields);

    setFormSections(name);
}

const checkW2UnrelatedAmtGreaterThan150K = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const unrelatedFiling = Number(name[2].groups[2].fields[1].default) || 0;
    checkW2Amounts(unrelatedFiling, name[2].groups[2].fields);

    setFormSections(name);
}

const triggerCompGreater150kCheckbox = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    let amounts = [];
    
    name[2].groups.forEach(group => {
        let w2Amount = group.fields.find(field => field.totalOrg === 'compW2Total');
        w2Amount = Number(w2Amount.default) || 0;
        amounts.push(w2Amount);
    });
    
    amounts = amounts.filter(amount => amount > 150000);
    if (amounts.length) {
        name[0].groups[2].fields[1].default = true;
        name[0].groups[2].fields[1].disabled = true;
    } else {
        name[0].groups[2].fields[1].default = false;
        name[0].groups[2].fields[1].disabled = false;
    }

    setFormSections(name);
}

const checkW2Amounts = (amount, data) => {
    let filteredData = data
        .filter(field => field.name !== 'compIndvName' && field.totalOrg !== 'compW2Total');
        
    if (amount > 150000) {
        filteredData.forEach(field => field.isVisible = true);
    } else if (amount <= 150000 && amount > 0) {
        filteredData.forEach(field => {
            field.isVisible = false;
            field.amount = 0;
            field.default = '0';
        });
    }
}

const showSupCompContRevOrgDesc = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {
        name[1].groups[32].isVisible = true;
    } else {
        name[1].groups[32].isVisible = false;
    }
    setFormSections(name);
}

const showSupCompContRevOrgRelDesc = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {
        name[1].groups[34].isVisible = true;
    } else {
        name[1].groups[34].isVisible = false;
    }
    setFormSections(name);
}

const showSupCompContEarnOrgDesc = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {
        name[1].groups[37].isVisible = true;
    } else {
        name[1].groups[37].isVisible = false;
    }
    setFormSections(name);
}

const showSupCompContEarnOrgRelDesc = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {
        name[1].groups[39].isVisible = true;
    } else {
        name[1].groups[39].isVisible = false;
    }
    setFormSections(name);
}

const showSupCompContNonFixedDesc = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {
        name[1].groups[41].isVisible = true;
    } else {
        name[1].groups[41].isVisible = false;
    }
    setFormSections(name);
}

const showSupCompContExceptPayDesc = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    if (event === EVENT.KEY_YES) {
        name[1].groups[43].isVisible = true;
        name[1].groups[44].isVisible = true;
    } else {
        name[1].groups[43].isVisible = false;
        name[1].groups[44].isVisible = false;
    }
    setFormSections(name);
}

const showRelatedOrganization = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_YES) {
        name[0].groups[1].isVisible = true;
        name[0].groups[2].isVisible = true;
        name[1].groups[33].isVisible = true;

        if (name[1].groups[33].fields[1].default) {
            name[1].groups[34].isVisible = true;
        }
        name[1].groups[38].isVisible = true;

        if (name[1].groups[38].fields[1].default) {
            name[1].groups[39].isVisible = true;
        }

        if (name[0].groups[1].fields[1].default === EVENT.KEY_YES) {
            name[1].isVisible = true;
        }

    } else {
        name[0].groups[1].isVisible = false;
        name[0].groups[2].isVisible = false;

        name[1].isVisible = false;
        name[1].groups[33].isVisible = false;
        name[1].groups[34].isVisible = false;
        name[1].groups[38].isVisible = false;
        name[1].groups[39].isVisible = false;
    }

    setFormSections(name);
}

const showFilingTotalNontaxableAmount = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const benefitsDetailsOrgDeductions = Number(name[3].groups[0].fields[1].default) || 0;
    const benefitsDetailsOrgEmployerPaid = Number(name[3].groups[0].fields[2].default) || 0;
    name[3].groups[0].fields[3].default = `$${benefitsDetailsOrgDeductions + benefitsDetailsOrgEmployerPaid}`;
    name[3].groups[0].fields[3].amount = benefitsDetailsOrgDeductions + benefitsDetailsOrgEmployerPaid;

    setFormSections(name);
}

const showRelatedTotalNontaxableAmount = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const benefitsDetailsOrgDeductions = Number(name[3].groups[1].fields[1].default) || 0;
    const benefitsDetailsOrgEmployerPaid = Number(name[3].groups[1].fields[2].default) || 0;
    name[3].groups[1].fields[3].default = `$${benefitsDetailsOrgDeductions + benefitsDetailsOrgEmployerPaid}`;
    name[3].groups[1].fields[3].amount = benefitsDetailsOrgDeductions + benefitsDetailsOrgEmployerPaid;

    setFormSections(name);
}

const showUnrelatedTotalNontaxableAmount = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const benefitsDetailsOrgDeductions = Number(name[3].groups[2].fields[1].default) || 0;
    const benefitsDetailsOrgEmployerPaid = Number(name[3].groups[2].fields[2].default) || 0;
    name[3].groups[2].fields[3].default = `$${benefitsDetailsOrgDeductions + benefitsDetailsOrgEmployerPaid}`;
    name[3].groups[2].fields[3].amount = benefitsDetailsOrgDeductions + benefitsDetailsOrgEmployerPaid;

    setFormSections(name);
}

const showForeignAddress = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
       
    if (event !== EVENT.KEY_YES) {
        if (name[0].groups[4].fields[1].default === false) {
            name[0].groups[5].isVisible = true;
            name[0].groups[5].fields[1].isVisible = false;
            setForeignAddress({ group: name[0].groups[5], field: {default: EVENT.KEY_UNITED_STATES}});  
        }
    } else {
        name[0].groups[4].fields[1].default = false;
        name[0].groups[5].isVisible = true;
        name[0].groups[5].fields[1].isVisible = true;
        setForeignAddress({ group: name[0].groups[5], field: name[0].groups[5].fields[1]});
    }

    setFormSections(name);
};

const setUSAddress = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    if (event) {
        name[0].groups[5].isVisible = false;
        name[0].groups[4].hasDivider = false;
    } else {
        name[0].groups[5].isVisible = true;
        name[0].groups[4].hasDivider = true;
        const addressField = name[0].groups[3].fields[1].default !== EVENT.KEY_YES ? {default: EVENT.KEY_UNITED_STATES} : name[0].groups[5].fields[1];
        if (addressField.default === EVENT.KEY_UNITED_STATES) {
            name[0].groups[5].fields[1].isVisible = false;
        }
        setForeignAddress({ group: name[0].groups[5], field: addressField});
    }

    setFormSections(name);
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
    // case 'sortTopContractors':
    //     sortTopContractors(event, options);
    //     break;
    case 'showFilingOrgFields':
        showFilingOrgFields(event, options);
        break;
    case 'showRelatedOrgFields':
        showRelatedOrgFields(event, options);
        break;
    case 'showUnrelatedOrgFields':
        showUnrelatedOrgFields(event, options);
        break;
    case 'showSupCompInfoSection':
        showSupCompInfoSection(event, options);
        break;
    case 'showSupCompPerksQuestions':
        showSupCompPerksQuestions(event, options);
        break;
    case 'showSupCompPerksPolicyExplain':
        showSupCompPerksPolicyExplain(event, options);
        break;
    case 'showSupCompPerksW2Explain':
        showSupCompPerksW2Explain(event, options);
        break;
    case 'showSupCompMthdExpl':
        showSupCompMthdExpl(event, options);
        break;
    case 'setIndividualName':
        setIndividualName(event, options, 'compIndvName');
        break;
    case 'setIndividualTitle':
        setIndividualName(event, options, 'compIndvTitle');
        break;
    case 'setcompIndvAvgHrsOrg':
        setAverageHoursWeek(event, options, 'compIndvAvgHrsOrg');
        break;
    case 'setcompIndvAvgHrsRelated':
        setAverageHoursWeek(event, options, 'compIndvAvgHrsRelated');
        break;
    case 'setcompIndvAvgHrsUnrelated':
        setAverageHoursWeek(event, options, 'compIndvAvgHrsUnrelated');
        break;
    case 'showFilingBaseCompAmount':
        showFilingBaseCompAmount(event, options);
        break;
    case 'showRelatedBaseCompAmount':
        showRelatedBaseCompAmount(event, options);
        break;
    case 'showUnrelatedBaseCompAmount':
        showUnrelatedBaseCompAmount(event, options);
        break;
    case 'supCompPay1':
    case 'supCompPay2':
    case 'supCompPay3':
        showSupCompPay(event, options, trigger);
        break;
    case 'showSupCompContRevOrgDesc':
        showSupCompContRevOrgDesc(event, options);
        break;
    case 'showSupCompContRevOrgRelDesc':
        showSupCompContRevOrgRelDesc(event, options);
        break;
    case 'showSupCompContEarnOrgDesc':
        showSupCompContEarnOrgDesc(event, options);
        break;
    case 'showSupCompContEarnOrgRelDesc':
        showSupCompContEarnOrgRelDesc(event, options);
        break;
    case 'showSupCompContNonFixedDesc':
        showSupCompContNonFixedDesc(event, options);
        break;
    case 'showSupCompContExceptPayDesc':
        showSupCompContExceptPayDesc(event, options);
        break;
    case 'showRelatedOrganization':
        showRelatedOrganization(event, options)
        break;
    case 'showFilingTotalNontaxableAmount':
        showFilingTotalNontaxableAmount(event, options);
        break;
    case 'showRelatedTotalNontaxableAmount':
        showRelatedTotalNontaxableAmount(event, options);
        break;
    case 'showUnrelatedTotalNontaxableAmount':
        showUnrelatedTotalNontaxableAmount(event, options);
        break;
    case 'showCompDetailsOrgsFields': 
        showCompDetailsOrgsFields(event, options);
        break;
    case 'checkW2FilingAmtGreaterThan150K': 
        checkW2FilingAmtGreaterThan150K(event, options);
        break;
    case 'checkW2RelatedAmtGreaterThan150K': 
        checkW2RelatedAmtGreaterThan150K(event, options);
        break;
    case 'checkW2UnrelatedAmtGreaterThan150K': 
        checkW2UnrelatedAmtGreaterThan150K(event, options);
        break;
    case 'triggerCompGreater150kCheckbox': 
        triggerCompGreater150kCheckbox(event, options);
        break;
    case 'showForeignAddress':
        showForeignAddress(event, options);
        break;
    case 'setUSAddress':
        setUSAddress(event, options);
        break;
    default:
        break;
    }
};

const showIndependentContractorsTable = (options) => {
    const { section, field } = options;

    const isNotEmpty = field.default?.trim() !== EVENT.KEY_EMPTY;
    const isNotZero = field.default?.trim() !== EVENT.KEY_ZERO;

    section.groups[1].isVisible = isNotEmpty && isNotZero;
    section.groups[2].isVisible = isNotEmpty && isNotZero;
    section.groups[3].isVisible = isNotEmpty && isNotZero;
    section.groups[4].isVisible = isNotEmpty && isNotZero;
    section.groups[5].isVisible = isNotEmpty && isNotZero;
    section.groups[6].isVisible = isNotEmpty && isNotZero;
    section.groups[7].isVisible = isNotEmpty && isNotZero;

};

const setContractorForeignAddress = (options) => {
    const { group, field } = options;
    const countryField = group.fields[4].default;
    const isUnitedStates = countryField === EVENT.KEY_UNITED_STATES;
    const isCanada = countryField === EVENT.KEY_CANADA;
    const isMexico = countryField === EVENT.KEY_MEXICO;
    const isOther = !isUnitedStates && !isCanada && !isMexico;


    if (field.default === EVENT.KEY_NO) {
        group.fields[4].isVisible = false;
        group.fields[7].isVisible = true;
        group.fields[8].isVisible = false;
        group.fields[9].isVisible = false;
        group.fields[10].isVisible = false;
    } else if (field.default === EVENT.KEY_YES) {
        group.fields[4].isVisible = true;

        // state
        group.fields[7].isVisible = isUnitedStates;

        // Province
        group.fields[9].isVisible = isCanada;

        // State/Prov
        group.fields[8].isVisible = isOther;

        // Mexico State
        group.fields[10].isVisible = isMexico;
    } else {
        group.fields[7].isVisible = true;
        group.fields[4].isVisible = false;
        group.fields[8].isVisible = false;
        group.fields[9].isVisible = false;
        group.fields[10].isVisible = false;
    }
};

const setContractorStateProv = (options) => {
    const { group, field } = options;

    const isUnitedStates = field.default === EVENT.KEY_UNITED_STATES;
    const isCanada = field.default === EVENT.KEY_CANADA;
    const isMexico = field.default === EVENT.KEY_MEXICO;
    const isOther = !isUnitedStates && !isCanada && !isMexico;

    if (group) {
        // state
        group.fields[7].isVisible = isUnitedStates;

        // Province
        group.fields[9].isVisible = isCanada;

        // State/Prov
        group.fields[8].isVisible = isOther;

        // Mexico State
        group.fields[10].isVisible = isMexico;
    }
};

const showTableOrUpload = (options) => {
    const { section, field } = options;

    section.groups[5].isVisible = field.default === EVENT.KEY_ATTACH;
    section.groups[6].isVisible = field.default === EVENT.KEY_ATTACH;
    section.groups[7].isVisible = field.default === EVENT.KEY_TABLE;

};

const syncUpIndividualName = (options) => {
    const { parentGroup, parentIndex, field } = options;
    const name1 = parentGroup.entities[parentIndex].sections;

    let name = '', title = '', formattedText = '';

    name1.forEach((section, index) => {
        if (index === 0) {
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if ((field1.name === 'compIndvName' && field1.default) || (field.name === 'compIndvName' && field1.name === 'compIndvName')) {
                        name = field.name === 'compIndvName' ? field.default : field1.default
                    }
                    if((field1.name === 'compIndvTitle' && field1.default) || (field.name === 'compIndvTitle' && field1.name === 'compIndvTitle')){
                        title = field.name === 'compIndvTitle' ? field.default : field1.default;
                    }
                    if (name?.length > 20) {
                        name = `${name.substring(0, 17)}...`;
                    }
                    if (title?.length > 20) {
                        title = `${title.substring(0, 17)}...`;
                    }
                })
            })
        }
    });
    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if((field.name !== 'compIndvName') && (field.name !== 'compIndvTitle') &&  (lineItemField.name === field.name)){
            lineItemField.default = field.default;
        }else{
            if(lineItemField.name === 'compIndvName'){
                if(name && title){
                    formattedText = 'Name: '+name+'\n Title: '+title;
                }else if(name){
                    formattedText = 'Name: '+name+'\n Title: ';
                }else if(title){
                    formattedText = 'Name: \n Title: '+title;
                }
                lineItemField.default = formattedText;
                lineItemField.fieldName = name;
            }else if(lineItemField.name === field.name){
                lineItemField.default = field.default;
            }            
        }
        
    });
}

const syncAverageHoursWeek = (options) => {
    const { parentGroup, parentIndex, field } = options;
    const parentSection = parentGroup.entities[parentIndex].sections;
    let filingOrg = 0, relatedOrg = 0, unrelatedOrg = 0;
    let isFilingOrg = true;
    let isRelatedOrg = true;
    let isUnrelatedOrg = true;
    parentSection.forEach((section, index) => {
        if(index === 0){
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if (field1.name === 'filingOrg' || field.name === 'filingOrg') {
                        isFilingOrg = true;
                    }
                    if (field1.name === 'relatedOrg' || field.name === 'relatedOrg') {
                        isRelatedOrg = true;
                    }
                    if (field1.name === 'unrelatedOrg' || field.name === 'unrelatedOrg') {
                        isUnrelatedOrg = true;
                    }
                })
            })
        }
        if (index === 1) {
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if (isFilingOrg && ((field1.name === 'compIndvAvgHrsOrg' && field1.default)
                    || (field.name === 'compIndvAvgHrsOrg' && field1.name === 'compIndvAvgHrsOrg'))){
                        filingOrg = field.name === 'compIndvAvgHrsOrg' ? field.default : field1.default
                        filingOrg = !filingOrg ? 0 : +filingOrg;
                    }
                    if(isRelatedOrg && ((field1.name === 'compIndvAvgHrsRelated' && field1.default) 
                    || (field.name === 'compIndvAvgHrsRelated' && field1.name === 'compIndvAvgHrsRelated'))){
                        relatedOrg = field.name === 'compIndvAvgHrsRelated' ? field.default : field1.default;
                        relatedOrg = !relatedOrg ? 0 : +relatedOrg;
                    }
                    if(isUnrelatedOrg && ((field1.name === 'compIndvAvgHrsUnrelated' && field1.default) 
                    || (field.name === 'compIndvAvgHrsUnrelated' && field1.name === 'compIndvAvgHrsUnrelated'))){
                        unrelatedOrg = field.name === 'compIndvAvgHrsUnrelated' ? field.default : field1.default;
                        unrelatedOrg = !unrelatedOrg ? 0 : +unrelatedOrg;
                    }
                })
            })
        }
    });
    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if(lineItemField.name === 'avgHoursLineSummary'){
            lineItemField.default = 'Total: ' + Number(filingOrg + relatedOrg + unrelatedOrg).toFixed(2);
        }
    });
}

const syncCompIndvOfficer = (options) => {
    const { parentGroup, parentIndex, field } = options;
    const parentSection = parentGroup.entities[parentIndex].sections;
    let isOfficer = false;
    parentSection.forEach((section, index) => {
        if(index === 0){
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if (field1.name === 'compIndvOfficer' || field.name === 'compIndvOfficer') {
                        isOfficer = field.name === 'compIndvOfficer' ? field.default : field1.default;
                    }
                })
            })
        }
    });

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if(lineItemField.name === 'compLineSummary'){
            lineItemField.isOfficer = isOfficer;
        }
    });
}

const syncCompensation = (options) => {
    const { parentGroup, parentIndex, field } = options;
    const parentSection = parentGroup.entities[parentIndex].sections;
    let filingOrg = 0, relatedOrg = 0, unrelatedOrg = 0;
    let isFilingOrg = false;
    let isRelatedOrg = false;
    let isUnrelatedOrg = false;
    parentSection.forEach((section, index) => {
        if(index === 0){
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if (field1.name === 'filingOrg' || field.name === 'filingOrg') {
                        isFilingOrg = field.name === 'filingOrg' ? field.default : field1.default;
                    }
                    if (field1.name === 'relatedOrg' || field.name === 'relatedOrg') {
                        isRelatedOrg = field.name === 'relatedOrg' ? field.default : field1.default;
                    }
                    if (field1.name === 'unrelatedOrg' || field.name === 'unrelatedOrg') {
                        isUnrelatedOrg = field.name === 'unrelatedOrg' ? field.default : field1.default;
                    }
                })
            })
        }
        if (index === 2) {
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if ((field1.name === 'compIndvCompOrg' && field1.default) 
                    || (field.name === 'compIndvCompOrg' && field1.name === 'compIndvCompOrg')) {
                        filingOrg = field.name === 'compIndvCompOrg' ? field.default : field1.default
                        filingOrg = !filingOrg ? 0 : +filingOrg;
                    }
                    if((field1.name === 'compIndvCompRelOrg' && field1.default) 
                    || (field.name === 'compIndvCompRelOrg' && field1.name === 'compIndvCompRelOrg')){
                        relatedOrg = field.name === 'compIndvCompRelOrg' ? field.default : field1.default;
                        relatedOrg = !relatedOrg ? 0 : +relatedOrg;
                    }
                    if((field1.name === 'compIndvCompUnrelOrg' && field1.default) 
                    || (field.name === 'compIndvCompUnrelOrg' && field1.name === 'compIndvCompUnrelOrg')){
                        unrelatedOrg = field.name === 'compIndvCompUnrelOrg' ? field.default : field1.default;
                        unrelatedOrg = !unrelatedOrg ? 0 : +unrelatedOrg;
                    }
                })
            })
        } 
    });

    const dollerUSlocale = Intl.NumberFormat('en-US');
    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if(lineItemField.name === 'compLineSummary'){
            let compensationTxt = '';
            if(isFilingOrg && filingOrg){
                compensationTxt = 'Filing Org - W-2/1099: $'+ dollerUSlocale.format(filingOrg) + '\n';
            }else{
                filingOrg = 0;
            }
            if(isRelatedOrg && relatedOrg){
                compensationTxt = compensationTxt + 'Related Org - W-2/1099: $'+ dollerUSlocale.format(relatedOrg) + '\n';
            }else{
                relatedOrg = 0;
            }
            if(isUnrelatedOrg && unrelatedOrg){
                compensationTxt = compensationTxt + 'Unrelated Org - W-2/1099: $'+ dollerUSlocale.format(unrelatedOrg) + '\n';
            }else{
                unrelatedOrg = 0;
            }
            lineItemField.compTotal = Number(filingOrg) + Number(relatedOrg) + Number(unrelatedOrg);
            lineItemField.default = compensationTxt;
        }
    });
}

const syncBenefits = (options) => {
    const { parentGroup, parentIndex, field } = options;
    const parentSection = parentGroup.entities[parentIndex].sections;
    
    let filingOrg = 0, relatedOrg = 0, unrelatedOrg = 0;
    let compNontax = 0, nontaxUnrelOrgEEPd = 0, nontaxUnrelOrgERPd = 0;
    let compRetire = 0, compRelOrgRetire = 0, compRetireUnrelOrg = 0;

    const dollerUSlocale = Intl.NumberFormat('en-US');

    let isFilingOrg = false;
    let isRelatedOrg = false;
    let isUnrelatedOrg = false;

    let totalNontaxableBenefitsAmt = '';
    let totalRetirementBenefitsAmt = '';

    parentSection.forEach((section, index) => {
        if(index === 0){
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if (field1.name === 'filingOrg' || field.name === 'filingOrg') {
                        isFilingOrg = field.name === 'filingOrg' ? field.default : field1.default;
                    }
                    if (field1.name === 'relatedOrg' || field.name === 'relatedOrg') {
                        isRelatedOrg = field.name === 'relatedOrg' ? field.default : field1.default;
                    }
                    if (field1.name === 'unrelatedOrg' || field.name === 'unrelatedOrg') {
                        isUnrelatedOrg = field.name === 'unrelatedOrg' ? field.default : field1.default;
                    }
                })
            })
        }

        if (index === 3) {
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if (isFilingOrg && ((field1.name === 'addCompNontaxOrgEEPd' && field1.default) 
                    || (field.name === 'addCompNontaxOrgEEPd' && field1.name === 'addCompNontaxOrgEEPd'))) {
                        filingOrg = field.name === 'addCompNontaxOrgEEPd' ? field.default : field1.default
                        filingOrg = !filingOrg ? 0 : +filingOrg;
                    }
                    if(isRelatedOrg && ((field1.name === 'addCompNontaxRelOrgEEPd' && field1.default) 
                    || (field.name === 'addCompNontaxRelOrgEEPd' && field1.name === 'addCompNontaxRelOrgEEPd'))) {
                        relatedOrg = field.name === 'addCompNontaxRelOrgEEPd' ? field.default : field1.default;
                        relatedOrg = !relatedOrg ? 0 : +relatedOrg;
                    }
                    if(isUnrelatedOrg && ((field1.name === 'addCompNontaxUnrelOrgEEPd' && field1.default) 
                    || (field.name === 'addCompNontaxUnrelOrgEEPd' && field1.name === 'addCompNontaxUnrelOrgEEPd'))) {
                        unrelatedOrg = field.name === 'addCompNontaxUnrelOrgEEPd' ? field.default : field1.default;
                        unrelatedOrg = !unrelatedOrg ? 0 : +unrelatedOrg;
                    }

                    if(isFilingOrg && ((field1.name === 'addCompNontax' && field1.default) 
                    || (field.name === 'addCompNontax' && field1.name === 'addCompNontax'))) {
                        compNontax = field.name === 'addCompNontax' ? field.default : field1.default;
                        compNontax = !compNontax ? 0 : +compNontax;
                    }

                    if(isRelatedOrg && ((field1.name === 'addCompNontaxRelOrgERPd' && field1.default) 
                    || (field.name === 'addCompNontaxRelOrgERPd' && field1.name === 'addCompNontaxRelOrgERPd'))) {
                        nontaxUnrelOrgERPd = field.name === 'addCompNontaxRelOrgERPd' ? field.default : field1.default;
                        nontaxUnrelOrgERPd = !nontaxUnrelOrgERPd ? 0 : +nontaxUnrelOrgERPd;
                    }

                    if(isUnrelatedOrg && ((field1.name === 'addCompNontaxUnrelOrgERPd' && field1.default) 
                    || (field.name === 'addCompNontaxUnrelOrgERPd' && field1.name === 'addCompNontaxUnrelOrgERPd'))) {
                        nontaxUnrelOrgEEPd = field.name === 'addCompNontaxUnrelOrgERPd' ? field.default : field1.default;
                        nontaxUnrelOrgEEPd = !nontaxUnrelOrgEEPd ? 0 : +nontaxUnrelOrgEEPd;
                    }

                    if(isFilingOrg && ((field1.name === 'addCompRetire' && field1.default) 
                    || (field.name === 'addCompRetire' && field1.name === 'addCompRetire'))) {
                        compRetire = field.name === 'addCompRetire' ? field.default : field1.default;
                        compRetire = !compRetire ? 0 : +compRetire;
                    }

                    if(isRelatedOrg && ((field1.name === 'addCompRelOrgRetire' && field1.default) 
                    || (field.name === 'addCompRelOrgRetire' && field1.name === 'addCompRelOrgRetire'))) {
                        compRelOrgRetire = field.name === 'addCompRelOrgRetire' ? field.default : field1.default;
                        compRelOrgRetire = !compRelOrgRetire ? 0 : +compRelOrgRetire;
                    }

                    if(isUnrelatedOrg && ((field1.name === 'addCompRetireUnrelOrg' && field1.default) 
                    || (field.name === 'addCompRetireUnrelOrg' && field1.name === 'addCompRetireUnrelOrg'))) {
                        compRetireUnrelOrg = field.name === 'addCompRetireUnrelOrg' ? field.default : field1.default;
                        compRetireUnrelOrg = !compRetireUnrelOrg ? 0 : +compRetireUnrelOrg;
                    }
                })
            })
        }
    });

    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if (lineItemField.name === 'benefitsLineSummary') {
            let compensationTxt = '';
            if(filingOrg  || 
                relatedOrg || 
                unrelatedOrg || 
                compNontax ||  
                nontaxUnrelOrgERPd || 
                nontaxUnrelOrgEEPd
            ) {
                totalNontaxableBenefitsAmt = dollerUSlocale.format(
                    Number(
                        filingOrg + 
                        relatedOrg + 
                        unrelatedOrg + 
                        compNontax + 
                        nontaxUnrelOrgERPd + 
                        nontaxUnrelOrgEEPd
                    ));
                compensationTxt = 'Total Nontaxable Benefits: $'+ totalNontaxableBenefitsAmt + '\n';
            }else {
                compensationTxt = 'Total Nontaxable Benefits: $\n';
            }
            if (compRetire ||  compRelOrgRetire || compRetireUnrelOrg) {
                totalRetirementBenefitsAmt = dollerUSlocale.format(
                    Number(
                        compRetire + 
                        compRelOrgRetire + 
                        compRetireUnrelOrg));
                compensationTxt = compensationTxt + 'Total Retirement Benefits: $'+ totalRetirementBenefitsAmt + '\n';
            } else {
                compensationTxt = compensationTxt + 'Total Retirement Benefits: $\n';
            }

            totalNontaxableBenefitsAmt = totalNontaxableBenefitsAmt.replace(/[^0-9.]/g,'');
            totalRetirementBenefitsAmt = totalRetirementBenefitsAmt.replace(/[^0-9.]/g,'');
            let total = dollerUSlocale.format(Number(totalNontaxableBenefitsAmt) + Number(totalRetirementBenefitsAmt));
            
            if (total) {
                compensationTxt = compensationTxt + 'Total			   $'+ total + '\n';
            } else {
                compensationTxt = compensationTxt + 'Total			   $\n';
            } 

            lineItemField.default = compensationTxt;
        }
    });
}

const syncFiscalDetails = (options) => {
    const { parentGroup, parentIndex, field } = options;
    const parentSection = parentGroup.entities[parentIndex].sections;
    let filingOrg = 0, relatedOrg = 0, unrelatedOrg = 0;
    let compNontax = 0, nontaxUnrelOrgEEPd = 0, nontaxUnrelOrgERPd = 0, compIndvCompUnrelOrgFY = 0, EmprPaidBensFYUnrelOr = 0, EmprPaidRetirementUnrelOrgFY = 0;
    const dollerUSlocale = Intl.NumberFormat('en-US');
    let isFilingOrg = false;
    let isRelatedOrg = false;
    let isUnrelatedOrg = false;
    parentSection.forEach((section, index) => {
        if(index === 0){
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if (field1.name === 'filingOrg' || field.name === 'filingOrg') {
                        isFilingOrg = field.name === 'filingOrg' ? field.default : field1.default;
                    }
                    if (field1.name === 'relatedOrg' || field.name === 'relatedOrg') {
                        isRelatedOrg = field.name === 'relatedOrg' ? field.default : field1.default;
                    }
                    if (field1.name === 'unrelatedOrg' || field.name === 'unrelatedOrg') {
                        isUnrelatedOrg = field.name === 'unrelatedOrg' ? field.default : field1.default;
                    }
                })
            })
        }
        if (index === 4) {
            section.groups.forEach(group => {
                group.fields.forEach(field1 => {
                    if (isFilingOrg && ((field1.name === 'compIndvCompOrgFY' && field1.default) 
                    || (field.name === 'compIndvCompOrgFY' && field1.name === 'compIndvCompOrgFY'))){
                        filingOrg = field.name === 'compIndvCompOrgFY' ? field.default : field1.default
                        filingOrg = !filingOrg ? 0 : +filingOrg;
                    }

                    if(isFilingOrg && ((field1.name === 'EmprPaidBensFY' && field1.default) 
                    || (field.name === 'EmprPaidBensFY' && field1.name === 'EmprPaidBensFY'))){
                        relatedOrg = field.name === 'EmprPaidBensFY' ? field.default : field1.default;
                        relatedOrg = !relatedOrg ? 0 : +relatedOrg;
                    }
                    
                    if(isFilingOrg && ((field1.name === 'EmprPaidRetirementFY' && field1.default) 
                    || (field.name === 'EmprPaidRetirementFY' && field1.name === 'EmprPaidRetirementFY'))){
                        unrelatedOrg = field.name === 'EmprPaidRetirementFY' ? field.default : field1.default;
                        unrelatedOrg = !unrelatedOrg ? 0 : +unrelatedOrg;
                    }

                    if(isRelatedOrg && ((field1.name === 'compIndvCompRelOrgFY' && field1.default) 
                    || (field.name === 'compIndvCompRelOrgFY' && field1.name === 'compIndvCompRelOrgFY'))){
                        compNontax = field.name === 'compIndvCompRelOrgFY' ? field.default : field1.default;
                        compNontax = !compNontax ? 0 : +compNontax;
                    }

                    if(isRelatedOrg && ((field1.name === 'EmprPaidBensFYRelOr' && field1.default) 
                    || (field.name === 'EmprPaidBensFYRelOr' && field1.name === 'EmprPaidBensFYRelOr'))){
                        nontaxUnrelOrgERPd = field.name === 'EmprPaidBensFYRelOr' ? field.default : field1.default;
                        nontaxUnrelOrgERPd = !nontaxUnrelOrgERPd ? 0 : +nontaxUnrelOrgERPd;
                    }

                    if(isRelatedOrg && ((field1.name === 'EmprPaidRetirementRelOrgFY' && field1.default) 
                    || (field.name === 'EmprPaidRetirementRelOrgFY' && field1.name === 'EmprPaidRetirementRelOrgFY'))){
                        nontaxUnrelOrgEEPd = field.name === 'EmprPaidRetirementRelOrgFY' ? field.default : field1.default;
                        nontaxUnrelOrgEEPd = !nontaxUnrelOrgEEPd ? 0 : +nontaxUnrelOrgEEPd;
                    }

                    if(isUnrelatedOrg && ((field1.name === 'compIndvCompUnrelOrgFY' && field1.default) 
                    || (field.name === 'compIndvCompUnrelOrgFY' && field1.name === 'compIndvCompUnrelOrgFY'))){
                        compIndvCompUnrelOrgFY = field.name === 'compIndvCompUnrelOrgFY' ? field.default : field1.default;
                        compIndvCompUnrelOrgFY = !compIndvCompUnrelOrgFY ? 0 : +compIndvCompUnrelOrgFY;
                    }

                    if(isUnrelatedOrg && ((field1.name === 'EmprPaidBensFYUnrelOr' && field1.default) 
                    || (field.name === 'EmprPaidBensFYUnrelOr' && field1.name === 'EmprPaidBensFYUnrelOr'))){
                        EmprPaidBensFYUnrelOr = field.name === 'EmprPaidBensFYUnrelOr' ? field.default : field1.default;
                        EmprPaidBensFYUnrelOr = !EmprPaidBensFYUnrelOr ? 0 : +EmprPaidBensFYUnrelOr;
                    }

                    if(isUnrelatedOrg && ((field1.name === 'EmprPaidRetirementUnrelOrgFY' && field1.default) 
                    || (field.name === 'EmprPaidRetirementUnrelOrgFY' && field1.name === 'EmprPaidRetirementUnrelOrgFY'))){
                        EmprPaidRetirementUnrelOrgFY = field.name === 'EmprPaidRetirementUnrelOrgFY' ? field.default : field1.default;
                        EmprPaidRetirementUnrelOrgFY = !EmprPaidRetirementUnrelOrgFY ? 0 : +EmprPaidRetirementUnrelOrgFY;
                    }
                })
            })
        }
    });
    
    parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
        if(lineItemField.name === 'fiscalYearLineSummary'){
            let compensationTxt = '';
            if(filingOrg  || compNontax || compIndvCompUnrelOrgFY ){
                compensationTxt = 'Gross Salaries/Wages: $'+ dollerUSlocale.format(Number(filingOrg + compNontax + compIndvCompUnrelOrgFY)) + '\n';
            }else{
                compensationTxt = 'Gross Salaries/Wages: $\n';
            }
            if(unrelatedOrg || nontaxUnrelOrgEEPd || EmprPaidRetirementUnrelOrgFY){
                compensationTxt = compensationTxt + 'Total Retirement: $'+ dollerUSlocale.format(Number(unrelatedOrg + nontaxUnrelOrgEEPd + EmprPaidRetirementUnrelOrgFY)) + '\n';
            }else{
                compensationTxt = compensationTxt + 'Total Retirement: $\n'
            }
            if( relatedOrg || nontaxUnrelOrgERPd ||  EmprPaidBensFYUnrelOr ){
                compensationTxt = compensationTxt + 'Total Benefits: $'+ dollerUSlocale.format(Number(relatedOrg + nontaxUnrelOrgERPd + EmprPaidBensFYUnrelOr));
            }else{
                compensationTxt = compensationTxt + 'Total Benefits: $\n';
            }
            lineItemField.default = compensationTxt;
        }
    });
}

const syncFilingOrgSummary = (options) => {
    syncAverageHoursWeek(options);
    syncCompensation(options);
    syncBenefits(options);
    syncFiscalDetails(options);
}

const showOrgAddressQuestions = (options) => {
    const { section, field } = options;
    const event = field ? field.default : section.groups[4].fields[1].default;
    section.groups[7].entities.forEach(entitySection => {
        let showAddressGroup = entitySection.sections[0].groups[4].fields[1].default;
        if (entitySection.sections[0].groups[4].fields[1].default === false && event === EVENT.KEY_YES ){
            showAddressGroup = false ;
        }

        entitySection.sections[0].groups[3].isVisible = event !== EVENT.KEY_YES;
        entitySection.sections[0].groups[4].isVisible = event !== EVENT.KEY_YES;
        entitySection.sections[0].groups[2].hasDivider = event !== EVENT.KEY_YES;
        if (!showAddressGroup && event === EVENT.KEY_YES ){
            entitySection.sections[0].groups[5].isVisible = false ;
        } else if (!showAddressGroup && event !== EVENT.KEY_YES){
            entitySection.sections[0].groups[5].isVisible = true ;
        }
    });
};

const setForeignAddress = (options) => {
    const { group, field } = options;
   
    const isUnitedStates = field.default === EVENT.KEY_UNITED_STATES;
    const isCanada = field.default === EVENT.KEY_CANADA;
    const isMexico = field.default === EVENT.KEY_MEXICO;
    const isOther = !isUnitedStates && !isCanada && !isMexico;

    if (group) {
        // state
        group.fields[4].isVisible = isUnitedStates;
    
        // Province
        group.fields[3].isVisible = isCanada;

        // State/Prov
        group.fields[5].isVisible = isOther;

        // Mexico State
        group.fields[6].isVisible = isMexico;
    }
};

const compIndvRelOrgLogic = (options) => {
    const { section, field } = options;
    const internalSetup = form.formSections;
    
    const fyIsCalYr = internalSetup ? internalSetup[0].groups[15].fields[1] : null;
    const isFyIsCalYrNo = fyIsCalYr !== null && fyIsCalYr.value === EVENT.KEY_NO;
    const event = field ? field.default : section.groups[0].fields[1].default;
    const hiddenStyle = {visibility: 'hidden'};
    const preLineStyle = { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' };
    
    if (event !== EVENT.KEY_YES) {
        section.groups[7].lineItems.forEach(lineItem => {
            lineItem[2].styles = hiddenStyle;
            lineItem[3].styles = hiddenStyle;
            lineItem[4].styles = hiddenStyle;
        });
        section.groups[7].fields[4].styles = !isFyIsCalYrNo ? hiddenStyle : preLineStyle;
    } else {
        section.groups[7].lineItems.forEach(lineItem => {
            lineItem[2].styles = preLineStyle;
            lineItem[3].styles = preLineStyle;
            lineItem[4].styles = !isFyIsCalYrNo ? hiddenStyle : preLineStyle;
        });
        section.groups[7].fields[4].styles = !isFyIsCalYrNo ? hiddenStyle : preLineStyle;
    }

    section.groups[7].entities.forEach(entitySection => {
        entitySection.sections[0].groups[2].isVisible = event === EVENT.KEY_YES;
        entitySection.sections[2].isVisible = event === EVENT.KEY_YES;
        entitySection.sections[3].isVisible = event === EVENT.KEY_YES;
        entitySection.sections[4].isVisible = event === EVENT.KEY_YES && isFyIsCalYrNo;
    });

    if (section.groups[7].summaryList) {
        section.groups[7].summaryList.sections[2].isVisible = event === EVENT.KEY_YES;
        section.groups[7].summaryList.sections[3].isVisible = event === EVENT.KEY_YES;
        section.groups[7].summaryList.sections[4].isVisible = event === EVENT.KEY_YES && isFyIsCalYrNo;
    }
};

const showAverageHoursFields = (options) => {
    const { group } = options;
    group?.entities?.forEach(entitySection => {
        entitySection.sections[1].groups[0].fields[0].isVisible = true;
        entitySection.sections[1].groups[0].fields[1].isVisible = true;
        entitySection.sections[1].groups[0].fields[2].isVisible = true;
        entitySection.sections[1].groups[0].fields[3].isVisible = true;
    });
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        case 'showIndependentContractorsTable':
            showIndependentContractorsTable(options);
            break;
        case 'setContractorForeignAddress':
            setContractorForeignAddress(options);
            break;
        case 'setForeignAddress':
            setForeignAddress(options);
            break;
        case 'setContractorStateProv':
            setContractorStateProv(options);
            break;
        case 'showTableOrUpload':
            showTableOrUpload(options);
            break;
        case 'syncUpIndividualName':
            syncUpIndividualName(options);
            break;
        case 'syncAverageHoursWeek':
            syncAverageHoursWeek(options);
            break;
        case 'syncCompensation':
            syncCompensation(options);
            break;
        case 'syncBenefits':
            syncBenefits(options);
            break;
        case 'syncFiscalDetails':
            syncFiscalDetails(options);
            break;
        case 'syncFilingOrgSummary':
            syncFilingOrgSummary(options);
            break;
        case 'syncCompIndvOfficer':
            syncCompIndvOfficer(options);
            break;
        case 'showOrgAddressQuestions':
            showOrgAddressQuestions(options);
            break;
        case 'compIndvRelOrgLogic':
            compIndvRelOrgLogic(options);
            break;
        case 'showAverageHoursFields':
            showAverageHoursFields(options);
            break;
        default:
            break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};