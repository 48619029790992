import {
    Button,
    DialogHeader,
    DialogTitle,
    DialogContent,
    DialogFooter,
    CloseSVGIcon
} from 'react-md';
import { useState } from 'react';
import { CLA_NAVY_LOGO } from '@utilities/constants/images';
import { Stepper, Step } from 'react-form-stepper';
import './questionnaire.css';
import PrevNextButton from '@components/entryExperience/prevNextButton';
import usePageFramework from '@utilities/hooks/usePageFramework';
import EntryInterview from '@views/basicInformation/entryInterview';
import { DASHBOARD } from '@utilities/constants/strings';
import { isAllFormFieldsValued } from '@utilities/formData/index.js';

const Questionnaire = ({ onClose, currentIndex, setCurrentIndex }) => {
    const stepsList = [{ type: 'Page 1' }, { type: 'Page 2' }, { type: 'Page 3' }];
    const { ACTION, dispatch, updateStatus, CARDSTATUS, selectState, REDUX, history, NAV, organizerId } = usePageFramework();
    const entryInterviewForm = Array.from(selectState(REDUX.ENTRY_INTERVIEW) || []);
    const [stepCompleteStatus, setStepCompleteStatus] = useState(0);

    const buildStepStyle = (index) => {
        let stepStyle = <div
            data-testid={'build-step-dot-' + index}
            className={
                index < currentIndex ? 'entryExperienceCompletedCheck' :
                    index === currentIndex ? 'entryExperienceActiveDot' :
                        index > currentIndex ? 'entryExperienceInactiveDot' :
                            'entryExperienceInactiveDot'
            }></div>;
        return (
            // eslint-disable-next-line
            <Button
                buttonType="icon"
                theme="primary"
                data-testid={'build-step-id-' + index}
                onClick={() => setCurrentIndex(index)}
            >
                {stepStyle}
            </Button>
        );
    };

    const stepperStyleConfig = (index = null) => {
        const styles = {
            inactiveBgColor: '#FFFFFF',
            activeBgColor: '#7DD2D3',
            completedBgColor: '#7DD2D3',
            size: '24px',
            circleFontSize: '12px'
        }

        if (index === currentIndex) {
            styles.fontWeight = 'bold';
        }

        return styles;
    }
    
    const handleQuestionnaireClick = () => {
        updateStatus(isAllFormFieldsValued(entryInterviewForm) ? CARDSTATUS.COMPLETED : CARDSTATUS.IN_PROGRESS)
        onClose();
        // Nav back to Dashboard
        history.push(`/${organizerId}${NAV.DASHBOARD}`)
        dispatch(ACTION.setToolbarTitle(DASHBOARD));
        dispatch(ACTION.setCurrentCardKey(null));
    };

    return (
        <div>
            <div className='questionnaireHeader'>
                <img src={CLA_NAVY_LOGO} alt="CLA navy colored logo" className="entryHeaderSilIcon"></img>
                <Button
                    id="icon-button-close"
                    data-testid="icon-button-close"
                    buttonType="icon"
                    theme="clear"
                    aria-label="close"
                    className="entryExperienceQuestionnaireCloseBtn"
                    onClick={handleQuestionnaireClick}
                >
                    <CloseSVGIcon className="closeIcon" />
                </Button>
                <DialogHeader>
                    <DialogTitle id="entry-experience-dialog-title" className="entry-experience-title">
                        Welcome to the <span className="entry-experience-app-title">990 Exchange</span>
                    </DialogTitle>
                </DialogHeader>
            </div>
            <DialogContent className="questionnaireContainer">
                <div className="entry-step-text-container">
                    <div className="questionnaireTitle">990 Basic Information</div>
                </div>

                <Stepper
                    activeStep={currentIndex}
                    connectorStateColors={true}
                    styleConfig={stepperStyleConfig()}
                    connectorStyleConfig={{
                        disabledColor: '#d2d2d2',
                        activeColor: '#7dd2d3',
                        completedColor: stepCompleteStatus < 2 ? '#d2d2d2' : '#7dd2d3',
                        size: 3
                    }}
                >
                    {stepsList.map((step, index) => (
                        <Step onClick={() => {
                            setCurrentIndex(index);
                            setStepCompleteStatus(index);
                        }} completed={(stepCompleteStatus + 1) >= index} key={`entry-modal-step${index}`} label={step.type} styleConfig={stepperStyleConfig(index)}>
                            {buildStepStyle(index)}
                        </Step>
                    ))}
                </Stepper>
                <div style={{ height: '458px' }}>
                    <EntryInterview currentIndex={currentIndex} />
                </div>
                <DialogFooter className='entryExperienceFooterBtnContainer'>
                    <PrevNextButton
                        stepCompleteStatus={stepCompleteStatus}
                        setStepCompleteStatus={setStepCompleteStatus}
                        setCurrentIndex={setCurrentIndex}
                        stepIndex={currentIndex}
                        handleQuestionnaireClick={handleQuestionnaireClick}
                    />
                </DialogFooter>
            </DialogContent>
        </div>
    )
}

export default Questionnaire;