// common modules
import React, { useState, useEffect } from 'react';
import { NativeSelect } from "@react-md/form";

// custom modules
import './groupSelectField.css';
import api from '@utilities/claApi';

function GroupSelectField(props) {
	const { value = [], setValue } = props;
	const [groups, setGroups] = useState([]);

	useEffect(() => {
		api.get('groups').then((response) => {
			return response.data;
		}).then((groupList) => {
			return groupList.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));
		}).then((sortedGroup) => {
			setGroups(sortedGroup);
		}).catch((error) => {
			console.log(error);
		})
	}, []);

	const onMultiSelect = (event) => {
		const groupsOption = Array.from(event.target.children);
		const selectedGroups = [];
		groupsOption.forEach(group => {
			if (group.selected) {
				selectedGroups.push(group.value)
			}
		});
		setValue(selectedGroups)
	};

	return (
		<>
			<NativeSelect
				id="group-select-field"
				data-testid={'admin-groups-select'}
				name="select"
				label="Groups"
				value={value}
				multiple={true}
				onChange={onMultiSelect}
				style={{ height: '20vh' }}
			>
				<option value="" disabled hidden />
				{groups.map((group) => (
					<option data-testid={'admin-groups-option'} key={group.id} value={group.id}>
						{`${group.displayName} (${group.name})`}
					</option>
				))}
			</NativeSelect>
		</>

	);
}

export default GroupSelectField;