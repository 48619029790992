import * as EVENT from '@utilities/constants/triggerKeys';

const showExcessBenefitTrans = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    const firstRadio = name[0].groups[0].fields[1].default;
    const secondRadio = name[0].groups[1].fields[1].default;
    const pubChrSupOrgGrntVal = name[0].groups[0].pubChrSupOrgGrntVal; // pubChrSupOrgGrnt boolean value
    
    const reqSchdChkLiAVal = (pubChrSupOrgGrntVal && firstRadio === EVENT.KEY_YES) || 
        (pubChrSupOrgGrntVal && firstRadio === EVENT.KEY_NO) || 
        (!pubChrSupOrgGrntVal && firstRadio === EVENT.KEY_YES);
    const reqSchdChkLiB = (pubChrSupOrgGrntVal && secondRadio === EVENT.KEY_YES) || 
        (pubChrSupOrgGrntVal && secondRadio === EVENT.KEY_NO) || 
        (!pubChrSupOrgGrntVal && secondRadio === EVENT.KEY_YES);

    name[1].isVisible = reqSchdChkLiAVal || reqSchdChkLiB;
}

const showLoans = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[2].isVisible = event === EVENT.KEY_YES;
}
const showGrantsorAssistance = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    name[3].isVisible = event === EVENT.KEY_YES;
}
const showBusinessTransactions =  (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    const businessTransactionsShow =  name[0].groups[6].fields[1].default || name[0].groups[7].fields[1].default || name[0].groups[8].fields[1].default;
    name[4].isVisible = businessTransactionsShow;
}

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'showExcessBenefitTrans':
            showExcessBenefitTrans(event, options);
            break;
        case 'showLoans':
            showLoans(event, options);
            break;
        case 'showGrantsorAssistance':
            showGrantsorAssistance(event, options);
            break;
        case 'showBusinessTransactions':
            showBusinessTransactions(event, options);
            break;            
        default:
            break;
    }
};

const sectionFieldLogic = (logicFunction, options) => {
    // switch (logicFunction) {
    //     default:
    //         break;
    // }
};

export {
    triggeredEvent,
    sectionFieldLogic
};