import welcomeIntro from './welcomeIntro.module.css';
import { Button, DialogContent, Divider } from 'react-md';
import { Grid, GridCell } from '@react-md/utils';
import { ChevronRightSVGIcon } from '@react-md/material-icons';
import { 
    ENTRY_EXPERIENCE, 
    ENTRY_EXPERIENCE_TOP, 
    ENTRY_EXPERIENCE_MIDDLE, 
    ENTRY_EXPERIENCE_BOTTOM,
    CLA_WHITE_LOGO } from '@utilities/constants/images';

function EntryWelcomeIntro(props) {
    const { goToQuestionnaire } = props;
    const styles = {
        backgroundImage: `
            url('${ENTRY_EXPERIENCE_TOP}'), 
            url('${ENTRY_EXPERIENCE_MIDDLE}'), 
            url('${ENTRY_EXPERIENCE_BOTTOM}'), 
            url('${ENTRY_EXPERIENCE}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        // setting height and width makes the dialog unresponsive on smaller screens
        // height: '696px', 
        // width: '902px',
        // padding: '56px 130px 22px 15px'
    };

    return (
        <DialogContent style={styles}>
            <Grid>
                <GridCell 
                    colSpan={12} 
                    style={{ marginTop: 140 }}
                >
                    <img src={CLA_WHITE_LOGO} alt="CLA logo" />
                </GridCell>
                <GridCell colSpan={8} style={{ marginTop: -20 }}>
                    <p className={welcomeIntro.entryExperienceHeaderText}>Welcome to the 990 Exchange</p>
                    <Divider className={welcomeIntro.entryExperienceDivider} />
                </GridCell>
                <GridCell colSpan={12} >
                    <p className={welcomeIntro.entryExperienceText}>
                        Nonprofits need to focus on their mission. 
                        CLA can help: We simplify the Form 990 information 
                        return process so you can manage your important work.
                    </p>
                </GridCell>
                <Grid className={welcomeIntro.getStartedBtnContainer}>
                    <GridCell colSpan={2}>
                        <p className={welcomeIntro.entryExperienceGetStartedText}>Get Started</p>
                    </GridCell>
                    <GridCell colSpan={2}>
                        <Button
                            onClick={() => goToQuestionnaire()}
                            id="icon-button-chevron"
                            buttonType="icon"
                            theme="clear"
                            className={welcomeIntro.getStartedChevBtn}
                        >
                            <ChevronRightSVGIcon />
                        </Button>
                    </GridCell>
                </Grid>
            </Grid>
        </DialogContent>

    );
}

export default EntryWelcomeIntro;