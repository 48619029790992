
/**
 * 
 * @param {number|string} amount - The amount to format.
 * @param {string} currency - currency code, default is 'USD'
 * @param {number} minimumFractionDigits - min number after decimal, default is 0.
 * @param {number} maximumFractionDigits - max number after decimal, default is 0.
 * @returns {string} A formatted number with currency symbol
 */
const amountCurrencyFormatter = (amount = 0, currency = 'USD', minimumFractionDigits = 0, maximumFractionDigits = 0) => {

    const currencyFormatter = Intl.NumberFormat('en-US', {
        style: "currency",
        currency,
        minimumFractionDigits,
        maximumFractionDigits
    });

    return currencyFormatter.format(Number(amount))
}

export {
    amountCurrencyFormatter
}