import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { triggeredEvent, sectionFieldLogic } from './basicDataLogicTriggger';
import { pullFieldValue } from '@utilities/pullFieldValue';
import * as NAV from '@utilities/constants/navigation';
import getYear from '@utilities/helpers/getYear';
import { Validators } from 'cla-formrenderer';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

let sections = [];
//TODO: need to refactor this to get the updated formstate titles
export const getBasicDataTitles = () => {
  let titles = [];
  sections.forEach((section) => {
    if (!titles.includes(section.title)) {
      titles.push(section.title);
    }
  });
  return titles;
};

export const getBasicDataSections = () => {
  return sections;
};

function BasicData(props) {
  const { updateCard, REDUX, selectState, setGlobalFormState } =
    usePageFramework(NAV.BASIC_DATA);
  const [sectionState, setSectionState] = useState('');
  const contributionForm = selectState(REDUX.CONTRIBUTIONS);
  const taxComplianceForm = selectState(REDUX.TAX_COMPLIANCE_FILINGS);
  const publicCharityForm = selectState(REDUX.PUBLIC_CHARITY_AND_SUPPORT);
  const internalSetupForm = selectState(REDUX.INTERNAL_SETUP);
  const financialsForm = selectState(REDUX.FINANCIALS);
  const interestedPersonsForm = selectState(
    REDUX.INTERESTED_PERSONS_TRANSACTIONS
  );
  const politicalCampaignForm = selectState(
    REDUX.POLITICAL_CAMPAIGN_AND_LOBBYING
  );
  const programsForm = selectState(REDUX.PROGRAMS);
  const year = getYear(selectState);
  const { isExportForm } = props;

  const { isActiveClient, isLoading } = useLoadOrganizer();

  useEffect(() => {
    if (!isExportForm) updateCard();
    const excludedSubordinateLineSections = [
      {
        title: 'Subordinate Information',
        sectionId: 1,
        isVisible: true,
        isDenseRow: true,
        isCollapsed: false,
        groups: [
          {
            groupId: 1,
            isVisible: true,
            fields: [
              {
                label: 'Address',
                name: 'subAddress',
                type: 'freeText',
                col: 9,
              },
              {
                label: 'Foreign country code',
                name: 'subAddressForeignCountry',
                type: 'select',
                lookup: 'BASIC_DATA_COUNTRIES',
                col: 3,
                logicFunction: [{ trigger: 'showSubsProvState' }],
              },
              {
                label: 'City',
                name: 'subAddressCity',
                type: 'freeText',
                col: 3,
              },
              {
                label: 'State',
                name: 'subAddressState',
                type: 'select',
                lookup: 'STATES',
                col: 3,
              },
              {
                label: 'Foreign Province',
                name: 'subAddressForeignProv',
                type: 'freeText',
                col: 3,
                isVisible: false,
              },
              {
                label: 'Foreign Province',
                name: 'subAddressForeignProvCanada',
                type: 'select',
                lookup: 'PROVINCES_CODE',
                col: 3,
                isVisible: false,
              },
              {
                label: 'Zip',
                name: 'subAddressZIP',
                type: 'zippostal',
                col: 3,
              },
            ],
          },
        ],
      },
    ];

    sections = [
      {
        title: 'Organization Data',
        isVisible: true,
        sectionId: 1,
        groups: [
          {
            groupId: 1,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Name of Organization:',
                name: 'orgNameLabel',
                type: 'label',
                col: 4,
              },
              {
                label: 'Organization Name',
                name: 'orgName',
                type: 'freeText',
                col: 8,
                prior: true,
                isPriorEditable: true,
                axcess: {
                  pull: {
                    fields: ['XFDSGEN.4', 'XFDSGEN.5'],
                    fn: 'joinTextPull',
                  },
                },
              },
            ],
          },
          {
            groupId: 2,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Does the organization go by a name other than their legal name?',
                name: 'orgDBALabel',
                type: 'label',
                col: 8,
              },
              {
                label: '',
                name: 'orgDBA',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 4,
                prior: true,
                logicFunction: [
                  {
                    trigger: 'showOrgDbaName',
                    priorResponse: '',
                  },
                ],
                ...pullFieldValue(['XFDSDBA.0', 'XFDSDBA.1'], true),
              },
            ],
          },
          {
            groupId: 3,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Please provide the other name:',
                name: 'orgDbaNameLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Alternative Name',
                name: 'orgDbaName',
                type: 'freeText',
                col: 3,
                prior: true,
                isPriorEditable: true,
                axcess: {
                  pull: {
                    fields: ['XFDSDBA.0', 'XFDSDBA.1'],
                    fn: 'joinTextPull',
                  },
                },
              },
            ],
          },
          {
            groupId: 4,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  "Has the organization's address changed since you filed your last return?",
                name: 'addChngLabel',
                type: 'label',
                col: 9,
              },
              {
                label: '',
                name: 'addChng',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 3,
              },
            ],
          },
          {
            groupId: 5,
            isVisible: true,
            fields: [
              {
                label: 'Organization Address',
                name: 'orgAddressLabel',
                type: 'label',
                col: 12,
                class: 'formSingleHeaderContainer',
              },
            ],
          },
          {
            groupId: 6,
            isVisible: true,
            fields: [
              {
                label: 'Address 1',
                name: 'orgAdd1',
                type: 'freeText',
                maxLength: 45,
                col: 6,
                printFieldWidth: 380,
                placeholder: 'Address 1',
                ...pullFieldValue(['XFDSGEN.6'], true),
              },
              {
                label: 'Address 2',
                name: 'orgAdd2',
                type: 'freeText',
                maxLength: 15,
                col: 6,
                printFieldWidth: 380,
                placeholder: 'Address 2',
                ...pullFieldValue(['XFDSGEN.7'], true),
              },
            ],
          },
          {
            groupId: 7,
            isVisible: true,
            fields: [
              {
                label: 'Country',
                name: 'orgCntry',
                type: 'select',
                lookup: 'BASIC_DATA_COUNTRIES',
                col: 3,
                default: 'US',
                logicFunction: [{ trigger: 'showAddressFields' }],
                ...pullFieldValue(['XFDSGEN.25'], true),
              },
              {
                label: 'Zip',
                name: 'orgZip',
                type: 'zippostal',
                col: 3,
                ...pullFieldValue(['XFDSGEN.10'], true),
              },
              {
                label: 'City',
                name: 'orgCity',
                type: 'freeText',
                maxLength: 40,
                col: 3,
                ...pullFieldValue(['XFDSGEN.8'], true),
                isVisible: true,
              },
              {
                label: 'State or Province',
                name: 'orgProv',
                type: 'freeText',
                maxLength: 17,
                col: 3,
                isVisible: false,
                prior: true,
                isPriorEditable: true,
                axcess: {
                  pull: {
                    fields: ['XFDSGEN.26'],
                    fn: 'basicDataForeignProvincePull',
                  },
                },
              },
              {
                label: 'Province',
                name: 'orgCanProv',
                type: 'select',
                lookup: 'BASIC_DATA_PROVINCES',
                col: 3,
                ...pullFieldValue(['XFDSGEN.26'], true),
                isVisible: false,
              },
              {
                label: 'State',
                name: 'orgState',
                type: 'select',
                lookup: 'BASIC_DATA_STATES',
                col: 3,
                ...pullFieldValue(['XFDSGEN.9'], true),
              },
              {
                label: 'Foreign Postal Code',
                name: 'orgPostal',
                type: 'zippostal',
                col: 3,
                isVisible: false,
                ...pullFieldValue(['XFDSGEN.29'], true),
              },
            ],
          },
          {
            groupId: 8,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Phone Number',
                name: 'orgPhoneUS',
                type: 'telephone',
                col: 3,
                ...pullFieldValue(['XFDSGEN.13'], true),
                printFieldWidth: 220,
              },
              {
                label: 'International Phone Number',
                name: 'orgPhoneIntl',
                type: 'telephone',
                col: 6,
                isVisible: false,
                ...pullFieldValue(['XFDSGEN.13'], true),
                printFieldWidth: 380,
              },
              {
                label: 'Website',
                name: 'orgWeb',
                type: 'freeText',
                maxLength: 49,
                col: 6,
                ...pullFieldValue(['XFDSGEN.16'], true),
                printFieldWidth: 300,
              },
            ],
          },
          // {
          // 	groupId: 9,
          // 	isVisible: true,
          // 	hasDivider: true,
          // 	fields: [
          // 		{
          // 			label: 'Is the organization’s mailing address different than its legal address?',
          // 			name: 'orgMailAddSameLabel',
          // 			type: 'label',
          // 			col: 9
          // 		},

          // 		{
          // 			label: '', name: 'orgMailAddSame', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showOrgAddMail' }],
          // 			prior: true,
          // 			...pullFieldValue(['XFDSMAIL.0', 'XFDSMAIL.1', 'XFDSMAIL.2', 'XFDSMAIL.3', 'XFDSGEN.25'], true)
          // 		},
          // 	]
          // },
          // {
          // 	groupId: 10,
          // 	isVisible: false,
          // 	fields: [
          // 		{ label: 'Organization Mailing Address', name: 'orgAddressLabel', type: 'label', col: 12, class: 'formSingleHeaderContainer' },
          // 	]
          // },
          // {
          // 	groupId: 11,
          // 	isVisible: false,
          // 	fields: [
          // 		{ label: 'Address', name: 'orgMailAddStreet', type: 'freeText', maxLength: 45, col: 6, ...pullFieldValue(['XFDSMAIL.0'], true) },
          // 		{ label: 'City', name: 'orgMailAddCity', type: 'freeText', maxLength: 25, col: 3, ...pullFieldValue(['XFDSMAIL.1'], true) },
          // 		{ label: 'State', name: 'orgMailAddState', type: 'select', lookup: 'BASIC_DATA_STATES_AND_PROV', col: 3, ...pullFieldValue(['XFDSMAIL.2'], true) },
          // 	]
          // },
          // {
          // 	groupId: 12,
          // 	isVisible: false,
          // 	hasDivider: true,
          // 	fields: [
          // 		{ label: 'Country', name: 'orgMailCountry', type: 'select', lookup: 'BASIC_DATA_COUNTRIES', col: 3, default: 'US', ...pullFieldValue(['XFDSGEN.25'], true) },
          // 		{ label: 'Zip Code', name: 'orgMailAddZip', type: 'zippostal', col: 3, ...pullFieldValue(['XFDSMAIL.3'], true) },
          // 	]
          // },
          {
            groupId: 13,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  "Briefly describe the organization's mission or most significant activities:",
                name: 'orgMissLabel',
                type: 'label',
                col: '12',
                class: 'labelTitleTextArea',
              },
              {
                label: 'Description',
                placeholder: 'Please enter your description here',
                name: 'orgMiss',
                type: 'textarea',
                class: 'fieldTextArea',
                col: 12,
                maxLength: 10000,
                prior: true,
                isPriorEditable: true,
                axcess: {
                  pull: {
                    fields: ['XFDS99GN.0', 'XFDS99O1.4', 'XFDS99O1.5'],
                    fn: 'basicDataMSPull',
                    populateMultiple: true,
                    separator: '\n',
                  },
                },
              },
            ],
          },
          {
            groupId: 14,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Tax Year End:',
                name: 'fy_TYLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Date',
                name: 'fy_TY',
                type: 'date',
                col: 3,
                prior: true,
                isPriorEditable: false,
                axcess: {
                  pull: {
                    default: `12/31/${year.current}`,
                    fields: ['XFDSGEN.18'],
                    fn: 'incrementTaxYearPull',
                  },
                },
              },
            ],
          },
          {
            groupId: 15,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Federal Employer Identification Number (EIN)',
                name: 'eINLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'EIN',
                name: 'eIN',
                type: 'ein',
                col: 3,
                prior: true,
                isPriorEditable: false,
                validations: { ...Validators.ein() },
                axcess: {
                  pull: {
                    fields: ['XFDSGEN.11'],
                    fn: 'removeHyphensPull',
                  },
                },
              },
            ],
          },
          {
            groupId: 16,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Provide Tax-Exempt Status:',
                name: 'taxExmptStatLabel',
                type: 'label',
                lookup: 'EXEMPT_STATUSES',
                col: 9,
              },
              {
                label: 'Tax-Exempt Status',
                name: 'taxExmptStat',
                type: 'select',
                lookup: 'EXEMPT_STATUSES',
                col: 3,
                prior: true,
                isPriorEditable: false,
                axcess: {
                  pull: {
                    fields: ['XFDSGEN.19', 'XFDSGEN.20', 'XFDSGEN.21'],
                    fn: 'taxExemptStatusPull',
                  },
                },
                logicFunction: [
                  { trigger: 'showTaxExemp' },
                  {
                    trigger: 'setCardRequired',
                    requiredCards: [REDUX.POLITICAL_CAMPAIGN_AND_LOBBYING],
                  },
                  {
                    trigger: 'setTaxExmptRequiredCardsTE',
                    triggerRelatedForms: { publicCharity: publicCharityForm },
                    setGlobalFormState: setGlobalFormState,
                  },
                  {
                    trigger: 'showtTaxExmptStatContributionFields',
                    triggerRelatedForms: { contributions: contributionForm },
                    setGlobalFormState: setGlobalFormState,
                  },
                  {
                    trigger: 'showtTaxExmptStatTaxComplianceFields',
                    triggerRelatedForms: { taxCompliance: taxComplianceForm },
                    setGlobalFormState: setGlobalFormState,
                  },
                  {
                    trigger: 'showInterestedPersonsTransactionFields',
                    triggerRelatedForms: {
                      interestedPersons: interestedPersonsForm,
                    },
                    setGlobalFormState: setGlobalFormState,
                  },
                  {
                    trigger: 'showPoliticalCampaignFields',
                    triggerRelatedForms: {
                      politicalCampaignAndLobbying: politicalCampaignForm,
                    },
                    setGlobalFormState: setGlobalFormState,
                  },
                  {
                    trigger: 'showOthFilingsLicensedMultiStateField',
                    triggerRelatedForms: { taxCompliance: taxComplianceForm },
                    setGlobalFormState: setGlobalFormState,
                  },
                  {
                    trigger: 'showFinancialExpenseSchedule',
                    triggerRelatedForms: {
                      internalSetup: internalSetupForm,
                      financials: financialsForm,
                    },
                    setGlobalFormState: setGlobalFormState,
                  },
                  {
                    trigger: 'showProgramServiceAccompFields',
                    triggerRelatedForms: {
                      programs: programsForm,
                    },
                    setGlobalFormState: setGlobalFormState,
                  },
                ],
              },
            ],
          },
          {
            groupId: 17,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Provide Public Charity Status:',
                name: 'pubCharStatLabel',
                type: 'label',
                lookup: 'CHARITY_STATUSES',
                col: 9,
              },
              {
                label: 'Public Charity Status',
                name: 'pubCharStat',
                type: 'select',
                lookup: 'CHARITY_STATUSES',
                isDisable: false,
                col: 3,
                prior: true,
                isPriorEditable: true,
                axcess: {
                  pull: {
                    fields: [
                      'XFDSABFS.0',
                      'XFDSABFS.1',
                      'XFDSABFS.2',
                      'XFDSABFS.4',
                      'XFDSABFS.6',
                      'XFDSABFS.3',
                      'XFDSABFS.7',
                      'XFDSABFS.8',
                      'XFDSABFS.15',
                      'XFDSABFS.9',
                      'XFDSABFS.13',
                      'XFDSABFS.10',
                      'XFDSABFS.11',
                      'XFDSABFS.12',
                      'XFDSABFS.14',
                    ],
                    fn: 'publicCharityStatusPull',
                  },
                },
                logicFunction: [
                  { trigger: 'showHosDbtClaPrep' },
                  {
                    trigger: 'setTaxExmptRequiredCardsPC',
                    triggerRelatedForms: { publicCharity: publicCharityForm },
                    setGlobalFormState: setGlobalFormState,
                  },
                  {
                    trigger: 'showPubCharStatContributionsFields',
                    triggerRelatedForms: { contributions: contributionForm },
                    setGlobalFormState: setGlobalFormState,
                  },
                  {
                    trigger: 'showPubCharStatTaxComplianceFields',
                    triggerRelatedForms: { taxCompliance: taxComplianceForm },
                    setGlobalFormState: setGlobalFormState,
                  },
                  {
                    trigger: 'showExcessBenefitTransactions',
                    triggerRelatedForms: {
                      interestedPersons: interestedPersonsForm,
                    },
                    setGlobalFormState: setGlobalFormState,
                  },
                ],
              },
            ],
          },
          {
            groupId: 18,
            isVisible: false,
            fields: [
              {
                label:
                  'Is CLA preparing the Medicare cost report for the current tax year?',
                name: 'hosDbtClaPrepLabel',
                type: 'label',
                col: 9,
              },
              {
                label: '',
                name: 'hosDbtClaPrep',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 3,
              },
            ],
          },
        ],
      },
      {
        title: 'Principal Officer',
        isVisible: true,
        sectionId: 2,
        groups: [
          {
            groupId: 1,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Name of Principal Officer',
                name: 'prinOffNamelabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Principal Name',
                name: 'prinOffName',
                type: 'freeText',
                col: 3,
                maxLength: 35,
                ...pullFieldValue(['XFDSOFF.0'], true),
              },
            ],
          },
          {
            groupId: 2,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: "Principal officer's title",
                name: 'prinOffTitleLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Principal Title',
                name: 'prinOffTitle',
                type: 'freeText',
                col: 3,
                maxLength: 35,
                ...pullFieldValue(['XFDSOFF.1'], true),
              },
            ],
          },
          {
            groupId: 3,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Can the principal officer be contacted at the same address as the organization’s mailing address (as of the date the return will be filed)?',
                name: 'prinOffAddOrgLabel',
                type: 'label',
                lookup: 'YESNO',
                col: 9,
              },
              {
                label: '',
                name: 'prinOffAddOrg',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 3,
                logicFunction: [{ trigger: 'showPrinAddress' }],
              },
            ],
          },
          {
            groupId: 4,
            isVisible: false,
            fields: [
              {
                label: 'Alternate Mailing Address',
                name: 'orgAddressLabel',
                type: 'label',
                col: 12,
                class: 'formSingleHeaderContainer',
              },
            ],
          },
          {
            groupId: 5,
            isVisible: false,
            fields: [
              {
                label: 'Address 1',
                name: 'prinOffAddress1',
                type: 'freeText',
                col: 12,
                maxLength: 75,
                ...pullFieldValue(['XFDSOFF.3'], true),
              },
            ],
          },
          {
            groupId: 6,
            isVisible: false,
            fields: [
              {
                label: 'Address 2',
                name: 'prinOffAddress2',
                type: 'freeText',
                maxLength: 75,
                col: 12,
              },
            ],
          },
          {
            groupId: 7,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Country',
                name: 'prinOffCntry',
                type: 'select',
                lookup: 'BASIC_DATA_COUNTRIES',
                col: 3,
                default: 'US',
                logicFunction: [{ trigger: 'showPrinAddCountry' }],
                ...pullFieldValue(['XFDSOFF.14'], true),
              },
              {
                label: 'Zipcode',
                name: 'prinOffZip',
                type: 'zippostal',
                col: 3,
                ...pullFieldValue(['XFDSOFF.12'], true),
              },
              {
                label: 'City or Town',
                name: 'prinOffCity',
                type: 'freeText',
                col: 3,
                maxLength: 70,
                isVisible: true,
                ...pullFieldValue(['XFDSOFF.6'], true),
              },
              {
                label: 'State or Province',
                name: 'prinOffProv',
                type: 'freeText',
                maxLength: 20,
                col: 3,
                isVisible: false,
                ...pullFieldValue(['XFDSOFF.13'], true),
              },
              {
                label: 'State',
                name: 'prinOffState',
                type: 'select',
                lookup: 'BASIC_DATA_STATES',
                col: 3,
                ...pullFieldValue(['XFDSOFF.11'], true),
              },
              {
                label: 'Foreign postal code',
                name: 'prinOffPostal',
                type: 'zippostal',
                col: 3,
                isVisible: false,
                ...pullFieldValue(['XFDSOFF.12'], true),
              },
            ],
          },
          {
            groupId: 8,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: "Principal Officer's email address",
                name: 'prinEmailLabel',
                type: 'label',
                col: 8,
              },
              {
                label: "Principal Officer's email",
                name: 'prinEmail',
                type: 'freeText',
                maxLength: 40,
                col: 4,
                ...pullFieldValue(['XFDSOFF.10'], true),
              },
            ],
          },
          {
            groupId: 9,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: "Officer's position:",
                name: 'prinTypeLabel',
                type: 'label',
                col: 8,
              },
              {
                label: "Officer's Position",
                name: 'prinType',
                type: 'select',
                lookup: 'FORM_SIGNER_TYPE',
                col: 4,
                ...pullFieldValue(['XFDSOFF.17'], true),
              },
            ],
          },
          {
            groupId: 10,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Will the principal officer be responsible for signing the return when it is filed?',
                name: 'prinSignLabel',
                type: 'label',
                lookup: 'YESNO',
                col: 6,
              },
              {
                label: '',
                name: 'prinSign',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 6,
                triggered: 'showForm990Signer',
              },
            ],
          },
        ],
      },
      {
        title: 'Form 990 Signer',
        isVisible: false,
        sectionId: 3,
        groups: [
          {
            groupId: 1,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Name (As of the date the return will be filed)',
                name: 'formSignNameLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Signer Name',
                name: 'formSignName',
                type: 'freeText',
                col: 3,
                maxLength: 35,
              },
            ],
          },
          {
            groupId: 2,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Title (As of the date the return will be filed)',
                name: 'formSignTitleLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Signer Title',
                name: 'formSignTitle',
                type: 'freeText',
                col: 3,
                maxLength: 30,
              },
            ],
          },
          {
            groupId: 3,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Email Address:',
                name: 'formSignEmailLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Signer Email',
                name: 'formSignEmail',
                type: 'freeText',
                col: 3,
                maxLength: 40,
              },
            ],
          },
          {
            groupId: 4,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Officer Type:',
                name: 'formSignTypeLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Officer’s Position',
                name: 'formSignType',
                type: 'select',
                lookup: 'FORM_SIGNER_TYPE',
                col: 3,
              },
            ],
          },
        ],
      },
      {
        title: 'Additional Information',
        isVisible: true,
        sectionId: 4,
        hasDenseRow: true,
        groups: [
          {
            groupId: 1,
            isVisible: false,
            hasDivider: true,
            groupType: 'lineItem',
            uploadType: 'drawer',
            class: 'householdEmploymentUpload',
            fields: [
              {
                label:
                  "Attach the updated organization's By-Laws, Articles of Incorporation or trust documents that changed during the year here:",
                name: 'govDocAtchLabel',
                type: 'label',
                col: 9,
              },
              { type: 'lineButtons', col: 3 },
            ],
            lineItems: [],
            lineItemDetails: {
              headerLabels: [],
              footerLabels: [],
              lineButtons: [
                {
                  button: 'uploadOnly',
                  uploadSectionName: 'govDocAtch',
                  viewSectionId: 0,
                },
              ],
            },
          },
          {
            groupId: 2,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label:
                  "Describe any significant changes due to the updated organization's By-Laws, Articles of Incorporation or trust documents that changed during the year",
                name: 'govBodyChangeGovDocsSumLabel',
                type: 'label',
                col: 12,
                class: 'labelTitleTextArea',
              },
              {
                label: 'Please explain',
                name: 'govBodyChangeGovDocsSum',
                type: 'textarea',
                col: 12,
                maxRows: 3,
                class: 'fieldTextArea',
              },
            ],
          },
          {
            groupId: 3,
            isVisible: false,
            hasDivider: true,
            groupType: 'lineItem',
            uploadType: 'drawer',
            class: 'householdEmploymentUpload',
            fields: [
              {
                label:
                  'Provide a copy of the IRS Determination Letter/Confirmation',
                name: 'ltrDetermineAttLabel',
                type: 'label',
                col: 9,
              },
              { type: 'lineButtons', col: 3 },
            ],
            lineItems: [],
            lineItemDetails: {
              headerLabels: [],
              footerLabels: [],
              lineButtons: [
                {
                  button: 'uploadOnly',
                  uploadSectionName: 'ltrDetermineAtt',
                  viewSectionId: 0,
                },
              ],
            },
          },
          {
            groupId: 4,
            isVisible: false,
            hasDivider: true,
            groupType: 'lineItem',
            uploadType: 'drawer',
            class: 'householdEmploymentUpload',
            fields: [
              {
                label:
                  "Provide a copy of your organization's Application for Recognition of Exemption (Form 1023 or Form 1024)",
                name: 'appExemptCopyLabel',
                type: 'label',
                col: 9,
              },
              { type: 'lineButtons', col: 3 },
            ],
            lineItems: [],
            lineItemDetails: {
              headerLabels: [],
              footerLabels: [],
              lineButtons: [
                {
                  button: 'uploadOnly',
                  uploadSectionName: 'appExemptCopy',
                  viewSectionId: 0,
                },
              ],
            },
          },
          {
            groupId: 5,
            isVisible: true,
            hasDivider: true,
            groupType: 'lineItem',
            uploadType: 'drawer',
            class: 'householdEmploymentUpload',
            fields: [
              {
                label:
                  'Attach any IRS notices that have not been provided to CLA',
                name: 'irsNoteCopyLabel',
                type: 'label',
                col: 9,
              },
              { type: 'lineButtons', col: 3 },
            ],
            lineItems: [],
            lineItemDetails: {
              headerLabels: [],
              footerLabels: [],
              lineButtons: [
                {
                  button: 'uploadOnly',
                  uploadSectionName: 'irsNoteCopy',
                  viewSectionId: 0,
                },
              ],
            },
          },
          {
            groupId: 6,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Total number of volunteers during the current year (estimate if necessary).',
                name: 'noVolLabel',
                type: 'label',
                col: 10,
              },
              {
                label: 'Current Volunteers',
                name: 'noVol',
                type: 'total',
                col: 2,
              },
            ],
          },
          {
            groupId: 7,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Reported volunteers from prior year were:',
                name: 'noVolPYLabel',
                type: 'label',
                col: 10,
              },
              {
                label: 'Prior Volunteers',
                name: 'noVolPY',
                type: 'total',
                col: 2,
                ...pullFieldValue(['XFDS99GN.8'], false),
              },
            ],
          },
          {
            groupId: 8,
            isVisible: false,
            excuseInVisible: true,
            fields: [
              {
                label: 'Are all subordinates included in this tax return?',
                name: 'subsInclLabel',
                type: 'label',
                col: 10,
              },
              {
                name: 'subsIncl',
                type: 'radio',
                col: 2,
                lookup: 'YESNO_RADIO',
                logicFunction: [
                  { trigger: 'showSubordinatesTable' },
                  {
                    trigger: 'showBasicDatasubsIncl',
                    triggerRelatedForms: { internalSetup: internalSetupForm },
                    setGlobalFormState: setGlobalFormState,
                  },
                ],
                isPriorTriggered: false,
                prior: true,
                isPriorEditable: true,
                priorYearValue: true,
                priorCompareOptions: {
                  radioShouldLookup: true,
                },
                axcess: {
                  pull: {
                    fields: ['XFDSGRP.11'],
                    fn: 'checkboxToRadioPull',
                  },
                },
              },
            ],
          },
          {
            groupId: 9,
            groupType: 'lineItem',
            isVisible: false,
            fields: [
              { label: 'Number', name: 'subsInclNumber', type: 'number' },
              { label: 'Yes/No', name: 'subReturn', type: 'select' },
              {
                label: 'Exemption #',
                name: 'GrpRtrnNumber',
                type: 'number',
                maxLength: 4,
              },
              { label: '', name: '', type: 'label' },
              { type: 'lineButtons' },
            ],
            lineItems: [],
            headerLabelsPrintStyles: {
              gridTemplateColumns: '2fr 2fr 1.5fr .25fr .5fr',
            },
            lineItemDetails: {
              headerLabels: [
                {
                  label: '# of subordinates included on return:',
                  col: 3,
                  colTablet: 2,
                },
                {
                  label:
                    'Is this a separate return filed by a group subordinate:',
                  col: 3,
                  colTablet: 2,
                },
                {
                  label: 'Four-Digit group exemption #:',
                  col: 3,
                  colTablet: 2,
                },
                { col: 2, colTablet: 1 },
                { label: 'Actions', col: 1, colTablet: 1 },
              ],
              footerLabels: [
                {
                  type: 'buttons',
                  col: 12,
                  buttonTitle: 'Add Another Included Subordinate',
                  icon: 'add',
                },
              ],
              lineButtons: [{ button: 'remove' }],
            },
          },
          {
            groupId: 10,
            isVisible: false,
            fields: [
              {
                label: 'List subordinates not included in this filing',
                name: 'subsExcl',
                type: 'label',
                col: 10,
              },
            ],
          },
          {
            groupId: 11,
            groupType: 'lineItem',
            isVisible: false,
            fields: [
              { label: 'Name', name: 'subName', type: 'freeText' },
              {
                label: 'Name Control',
                name: 'subNameControl',
                type: 'freeText',
              },
              {
                label: 'EIN',
                name: 'subEIN',
                type: 'ein',
                validations: { ...Validators.ein() },
              },
              {
                label: 'Details',
                name: 'additionalInfo',
                type: 'viewButton',
                viewSectionId: 100,
              },
              { type: 'lineButtons' },
            ],
            lineItems: [],
            entities: [],
            currentEntity: -1,
            lineSections: excludedSubordinateLineSections,
            headerLabelsPrintStyles: {
              gridTemplateColumns: '2fr 2fr 1fr 1fr .75fr',
            },
            lineItemDetails: {
              headerLabels: [
                {
                  label: 'Name',
                  col: 3,
                  colTablet: 2,
                  styles: { paddingLeft: '48px' },
                },
                {
                  label: 'Name Control',
                  col: 3,
                  colTablet: 2,
                  styles: { paddingLeft: '40px' },
                },
                {
                  label: 'EIN',
                  col: 3,
                  colTablet: 2,
                  styles: { paddingLeft: '32px' },
                },
                {
                  label: 'Additional Info',
                  col: 2,
                  colTablet: 1,
                  styles: { textAlign: 'center' },
                },
                { label: 'Actions', col: 1, colTablet: 1 },
              ],
              footerLabels: [
                {
                  type: 'buttons',
                  col: 12,
                  buttonTitle: 'Add Another Excluded Subordinate',
                  icon: 'add',
                },
              ],
              lineButtons: [{ button: 'remove' }],
            },
          },
          {
            groupId: 12,
            groupType: 'details',
            isOptionalGroupMerge: true,
            fields: [
              {
                label:
                  'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.',
                type: 'label',
                class: 'labelLeftLight',
              },
            ],
          },
          {
            groupId: 13,
            isOptionalGroupMerge: true,
            fields: [
              {
                label: 'Start typing to provide additional information',
                name: 'additionalInfoTextArea',
                type: 'textarea',
                col: 12,
                maxLength: 9000,
              },
            ],
          },
        ],
      },
    ];
    setSectionState(sections);
    // eslint-disable-next-line
  }, []);

  const axcessIdentifiers = [
    { section: 'General', id: 'Basic Data' },
    { section: 'Return Type', id: 'Form 990 - Exempt Organization Return' },
    { section: 'Schedules', id: 'Sch A' },
    {
      section: 'Schedules',
      id: 'Sch O - Supplemental Information for Form 990',
    },
  ];

  return (
    <>
      {!isLoading && isActiveClient && (
        <FormRendererHelper
          sections={sectionState}
          key={REDUX.BASIC_DATA}
          formName={REDUX.BASIC_DATA}
          identifiers={axcessIdentifiers}
          triggeredEvent={triggeredEvent}
          sectionFieldLogic={sectionFieldLogic}
          isExportForm={isExportForm}
        />
      )}
      {!isLoading && !isActiveClient && <NoActiveClient />}
    </>
  );
}

export default BasicData;
