import { useState, useEffect } from 'react';
import {
    Card,
    Link as ReactMDLink,
    Divider
} from 'react-md';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { Link as ReactRouterLink } from 'react-router-dom';
import { getCardProp } from '@utilities/helpers/getCardProp';
import './dashboardCard.css';

function DashboardCard(props) {
    const { card, setIsGoToQuestionnaire } = props;
    const { REDUX, history, NAV, organizerId } = usePageFramework();        
    const [statusId, setStatusId] = useState(card.statusId);
    
    useEffect(() => {
        setStatusId(card.statusId);
    }, [card.statusId]);

    const Link = (props) => {
        return (
            <ReactMDLink
                component={ReactRouterLink}
                {...props}
                style={{ textDecoration: 'none', color: 'black' }}
            />
        );
    };

    let statusTitle = 'Not Started';
    card.statusTitle = statusTitle;

    let barStyle = {};
    let cardStyle = {};

    switch (statusId) {
        case 2:
            statusTitle = 'In Progress';
            card.statusTitle = statusTitle;
            barStyle.backgroundColor = '#2e334e';
            break;
        case 3:
            statusTitle = 'Error';
            card.statusTitle = statusTitle;
            barStyle.backgroundColor = '#ef0606';
            break;
        case 4:
            statusTitle = 'Completed';
            card.statusTitle = statusTitle;
            barStyle.backgroundColor = '#439818';
            break;
        case 5:
            statusTitle = 'Does Not Apply';
            card.statusTitle = statusTitle;
            cardStyle.opacity = 0.4;
            break;
        default:
            statusTitle = 'Not Started';
            card.statusTitle = statusTitle;
            break;
    }

    const handleEntryExperienceOnClick = () => {
            history.push(`/${organizerId}${NAV.ENTRY_INTERVIEW}`)
            if (statusId !== 1) setIsGoToQuestionnaire(true);
    };

    const LayoutDashboardCard = ({onClick}) =>{
        return  <Card className={'cardContainer'} onClick={onClick}>
        <div style={{display: card.isRequired ? 'none' : 'flex'}} className="cardWarning">
            <p>{card.isRequired ? '' : `Please double check your responses on the Entry Experience card if you believe the ${card.title} card is applicable.`}</p>
            <Divider />
        </div>
        <div className="cardContents">
            <div className="cardTitle">
                <div className="cardIconContainer" style={cardStyle}>
                    <img className="overHangImg" alt={`Icon for ${card.title} dashboard card`} src={getCardProp(card.formName, 'dashBoardIcon')} />
                </div>
                <div className="cardTitleContainer" style={cardStyle}>
                <p>{getCardProp(card.formName, 'title')}</p>
                            <div className='cardSubtitle'>{getCardProp(card.formName, 'subtitle')}</div>
                </div>
            </div>
        </div>
        <div className="cardStatusContainer" style={cardStyle}>
            <Divider />
            <div className="cardProgressLabelContainer">
                <div>
                    <p style={{ margin: '5px 0 0 5px' }}>{card.isRequired ? 'Required' : 'Optional'}</p>
                </div>
                <div>
                    <p style={{ margin: '5px 5px 0 0' }}>{statusTitle}</p>
                </div>
            </div>
        </div>
    </Card>
    }

    return (
        card.formName === REDUX.ENTRY_INTERVIEW ?
            <LayoutDashboardCard onClick={handleEntryExperienceOnClick} />
            :
            <Link to={`/${organizerId}${card.route}`} onClick={() => window.scrollTo(0, 0)}>
                <LayoutDashboardCard />
            </Link>
    );
}

export default DashboardCard;