import * as STRING from '@utilities/constants/strings';

function NoActiveClient() {
	return (
		<>
			<div className="dashboardSize" style={{minHeight: '65vh'}}>
				<div className="headerMenuText"> {STRING.NO_ACTIVE_CLIENT} </div>
			</div>
		</>
	);
}
export default NoActiveClient;