import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { sectionFieldLogic } from './taxComplianceFilingsLogicTrigger';
import { pullFieldValue } from '@utilities/pullFieldValue';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

let sections = [];

export const getTaxComplianceSections = () => {
	return sections;
};

export const getTaxComplianceFilingsTitles = () => {
	let titles = [];
	sections.forEach((section) => {
		if (!titles.includes(section.title)) {
			//if (!titles.includes(section.title) && section.isVisible) {
			titles.push(section.title);
		}
	});
	return titles;
};
function TaxComplianceFilings(props) {
    const { isExportForm } = props;
	const { updateCard, REDUX, selectState } = usePageFramework(NAV.TAX_COMPLIANCE_FILINGS);
	const [sectionState, setSectionState] = useState('');
    const internalSetupForm = selectState(REDUX.INTERNAL_SETUP); 
	const calYearEnd = internalSetupForm ? internalSetupForm[0].groups[14].fields[1].value || internalSetupForm[0].groups[14].fields[1].default || '' : '';

    const {isActiveClient, isLoading} = useLoadOrganizer();

	useEffect(() => {
		if (!isExportForm) updateCard();
        sections = [
            {
                title: 'Basic Filings & Tax Compliance Data',
                isVisible: true,
                sectionId: 1,
                groups: [
                    {
                        groupId: 1,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: `Enter the number from Box 3 of all Forms 1096 for the calendar year ending ${calYearEnd}. Enter 0 if not applicable.`,
                                name: 'othFilings1096Label',
                                type: 'label',
                                col: 8
                            },
                            { label: 'Forms 1096', name: 'othFilings1096', type: 'total', col: 2 },
                            {
                                label: 'Last year you reported:',
                                name: 'othFilings1096PY',
                                type: 'total',
                                col: 2,
                                ...pullFieldValue(['XFDS99P5.0'], false)
                            },
                        ]
                    },
                    {
                        groupId: 2,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            {
                                label: 'Enter the number of Forms W-2G (Certain Gambling Winnings) included in response above.',
                                name: 'othFilingsW2GLabel',
                                type: 'label',
                                col: 8
                            },
                            { label: 'Forms W-2G', name: 'othFilingsW2G', type: 'total', col: 2 },
                            {
                                label: 'Last year you reported:',
                                name: 'othFilingsW2GPY',
                                type: 'total',
                                col: 2,
                                ...pullFieldValue(['XFDS99P5.1'], false)
                            },
                        ]
                    },
                    {
                        groupId: 3,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization comply with backup withholding rules for reportable payments to vendors and reportable gaming (gambling) winnings to prize winners?', name: 'othFilingsBackupWithholdLabel', type: 'label', col: 10 },
                            { label: '', name: 'othFilingsBackupWithhold', type: 'select', lookup: 'YESNONA', col: 2 },
                        ]
                    },
                    {
                        groupId: 4,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: `Enter the number of W-2's filed with the IRS for the calendar year ending ${calYearEnd}. Enter 0 if not applicable.`, name: 'othFilingsFormW3Label', type: 'label', col: 8 },
                            { label: 'Forms W-3', name: 'othFilingsFormW3', type: 'total', col: 2, logicFunction: [{ trigger: 'showOthFilingsFedErTaxRet' }] },
                            {
                                label: 'Last year you reported:',
                                name: 'othFilingsFormW3PY',
                                type: 'total',
                                col: 2,
                                ...pullFieldValue(['XFDS99P5.3'], false)
                            },
                        ]
                    },
                    {
                        groupId: 5,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization file all required federal employment tax returns?', name: 'othFilingsFedErTaxRetLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsFedErTaxRet', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
                        ]
                    },
                    {
                        groupId: 6,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'At any time during the calendar year, did the organization have an interest in, or a signature or other authority over, a financial account in a foreign country (such as a bank account, securities account, or other financial account)?', name: 'othFilingsForeignFinAcctLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsForeignFinAcct', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showOthFilingsForeignCntry' }] },
                        ]
                    },
                    {
                        groupId: 7,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Enter the name(s) of the foreign country(ies):', type: 'label', name: 'othFilingsForeignCntryLabel', col: 9 },
                            {
                                label: '', name: 'othFilingsForeignCntry',
                                type: 'multiSelectDropdown', lookup: 'TAX_COMPLIANCE_COUNTRIES', col: 12, singleSelect: false,
                                placeHolder: 'Foreign Country(ies):', showArrow: true, showCheckbox: true, displayValue: 'name', isObject: true, disablePreSelectedValues: false
                            },
                        ]
                    },
                    {
                        groupId: 8,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization solicit any charitable contributions that were not tax deductible?', name: 'othFilingsGrossRct100KLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsGrossRct100K', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showothFilingsNotifyNotTaxDeduct' }] },
                        ]
                    },
                    {
                        groupId: 9,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization include an express statement with every solicitation that such contributions or gifts were not tax deductible?', name: 'othFilingsNotifyNotTaxDeductLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsNotifyNotTaxDeduct', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
                        ]
                    },
                ]
            },
            {
                title: 'Deductible Contributions',
                isVisible: true,
                sectionId: 2,
                groups: [
                    {
                        groupId: 1,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization provide goods or services in exchange for any contribution of $75 or more?', name: 'othFilingsPartContGoodsLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsPartContGoods', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showothFilingsNotifyValueGoods' }] },
                        ]
                    },
                    {
                        groupId: 2,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization notify the donor of the value of the goods or services provided?', name: 'othFilingsNotifyValueGoodsLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsNotifyValueGoods', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
                        ]
                    },
                    {
                        groupId: 3,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization sell, exchange or otherwise dispose of tangible personal property for which it filed Form 8282 — Donee Information Return?', name: 'othFilingsReqFile8282Label', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsReqFile8282', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showothFilingsNoFile8282' }] },
                        ]
                    },
                    {
                        groupId: 4,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Indicate the number of Forms 8282 filed during the year.', name: 'othFilingsNoFile8282Label', type: 'label', col: 9},
                            { name: 'othFilingsNoFile8282', type: 'total', col: 3},
                        ]
                    },
                    {
                        groupId: 5,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization, during the year, receive any funds, directly or indirectly, to pay premiums on a personal benefit contract?', name: 'othFilingsPremBeneFundsLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsPremBeneFunds', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
                        ]
                    },
                    {
                        groupId: 6,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization, during the year, pay premiums, directly or indirectly, on a personal benefit contract?', name: 'othFilingsPremBeneDirectLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsPremBeneDirect', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
                        ]
                    },
                    {
                        groupId: 7,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization receive any contributions of qualified intellectual property?', name: 'othFilingsIntelPropForm8899Label', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsIntelPropForm8899', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showothFilingsIntelPropForm8899' }] },
                        ]
                    },
                    {
                        groupId: 8,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'If the organization received any contributions of qualified intellectual property, has the organization already filed Form 8899 – Notification of Income from Donated Intellectual Property?', name: 'othFilingsIntelPropForm8899fileLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsIntelPropForm8899file', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
                        ]
                    },
                    {
                        groupId: 9,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization receive any contributions of cars, boats, airplanes or other vehicles?', name: 'othFilingsForm1098CLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsForm1098C', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showothFilingsForm1098Cfile' }] },
                        ]
                    },
                    {
                        groupId: 10,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Has the organization already filed Form 1098-C — Contributions of Motor Vehicles, Boats and Airplanes?', name: 'othFilingsForm1098CfileLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsForm1098Cfile', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
                        ]
                    },
                    // {
                    // 	groupId: 11,
                    // 	isVisible: true,
                    // 	hasDivider: true,
                    // 	fields: [
                    // 		{ label: 'Comments/Questions', name: 'commIntQs0402Label', type: 'label', col: 9, class: 'labelTitleTextArea',  },
                    // 		{ label: 'Please explain', name: 'commIntQs0402', type: 'textarea', col: 12, class: 'fieldTextArea' },
                    // 	]
                    // },
                ]
            },
            {
                title: 'Other Compliance Data',
                isVisible: true,
                sectionId: 3,
                groups: [
                    {
                        groupId: 1,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Enter the initiation fees and capital contributions', name: 'othFilingsInitFeesLabel', type: 'label', col: 8 },
                            { label: 'Amount', name: 'othFilingsInitFees', type: 'money', col: 2 },
                            { label: 'Last year you reported:', name: 'othFilingsInitFeesPY', type: 'money', maxLength: 14, col: 2, ...pullFieldValue(['XFDS99P5.27'], false) }
                        ]
                    },
                    {
                        groupId: 2,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Enter the gross receipts for public use of club facilities', name: 'othFilingsClubGrossRctLabel', type: 'label', col: 8 },
                            { label: 'Amount', name: 'othFilingsClubGrossRct', type: 'money', col: 2 },
                            { label: 'Last year you reported:', name: 'othFilingsClubGrossRctPY', type: 'money', maxLength: 14, col: 2, ...pullFieldValue(['XFDS99P5.28'], false) },
                        ]
                    },
                    {
                        groupId: 3,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Enter the gross income from members or shareholders', name: 'othFilingsGrossIncMbrLabel', type: 'label', col: 8 },
                            { label: 'Amount', name: 'othFilingsGrossIncMbr', type: 'money', col: 2 },
                            { label: 'Last year you reported:', name: 'othFilingsGrossIncMbrPY', type: 'money', maxLength: 14, col: 2, ...pullFieldValue(['XFDS99P5.29'], false) }
                        ]
                    },
                    {
                        groupId: 4,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Enter the gross income from other sources', name: 'othFilingsGrossIncOthLabel', type: 'label', col: 8 },
                            { label: 'Amount', name: 'othFilingsGrossIncOth', type: 'money', col: 2 },
                            { label: 'Last year you reported:', name: 'othFilingsGrossIncOthPY', type: 'money', maxLength: 14, col: 2, ...pullFieldValue(['XFDS99P5.30'], false) },
                        ]
                    },
                    {
                        groupId: 5,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Is the organization filing Form 990 in lieu of Form 1041?', name: 'othFilingsForm1041Label', type: 'label', col: 9 },
                            { label: 'Please select', name: 'othFilingsForm1041', type: 'radio', lookup: 'YESNO_RADIO', col: 3, ...pullFieldValue(['XFDS99P5.31'], true) },
                        ]
                    },
                    {
                        groupId: 6,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Enter the amount of tax-exempt interest received or accrued during the year', name: 'othFilingsTaxExmptIntLabel', type: 'label', col: 8 },
                            { label: 'Amount', name: 'othFilingsTaxExmptInt', type: 'money', col: 2 },
                            { label: 'Last year you reported:', name: 'othFilingsTaxExmptIntPY', type: 'money', col: 2, maxLength: 14, ...pullFieldValue(['XFDS99P5.32'], false) },
                        ]
                    },
                    {
                        groupId: 7,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Is the organization licensed to issue qualified health plans in more than one state?', name: 'othFilingsLicensedMultiStateLabel', type: 'label', col: 9 },
                            { label: 'Yes', name: 'othFilingsLicensedMultiState', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showOthNpHealhInsIss' }], ...pullFieldValue(['XFDS99P5.33'], true) },
                        ]
                    },
                    {
                        groupId: 8,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Enter the amount of reserves the organization is required to maintain by the states in which the organization is licensed to issue qualified health plans', name: 'othFilingsReqReserveAmtLabel', type: 'label', col: 10 },
                            { label: 'Amount', name: 'othFilingsReqReserveAmt', type: 'money', col: 2 },
                        ]
                    },
                    {
                        groupId: 9,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Enter the amount of reserves on hand', name: 'othFilingsActRsrvAmtLabel', type: 'label', col: 10 },
                            { label: 'Amount', name: 'othFilingsActRsrvAmt', type: 'money', col: 2 },
                        ]
                    },
                    {
                        groupId: 10,
                        isVisible: false,
                        fields: [
                            { label: 'Complete the table below:', name: 'othNpHealhInsIss', type: 'label', col: 10, class: 'labelQuestionTitle' },
                        ]
                    },
                    {
                        groupType: 'lineItem',
                        isVisible: false,
                        groupId: 11,
                        fields: [
                            { label: 'State', name: 'othNpHealhInsProvState', type: 'select', lookup: 'FOREIGN_ASSETS_STATES_CODES' },
                            { label: 'Amount', name: 'othNpHealhInsReqReserve', type: 'money', isTotal: true, },
                            { label: 'Amount', name: 'othNpHealhInsReserves', type: 'money', isTotal: true, },
                            { type: 'lineButtons' }
                        ],
                        lineItems: [],
                        headerLabelsPrintStyles: {gridTemplateColumns: '3fr 2.75fr 2.75fr .5fr'},
                        footerLabelsPrintStyles: {gridTemplateColumns: '6fr 3fr'},
                        lineItemDetails:
                        {
                            headerLabels: [
                                { label: 'State', col: 5, colTablet: 3 },
                                { label: 'Reserve Dollars Required to Maintain', col: 4, colTablet: 2 },
                                { label: 'Reserve Dollars On Hand', col: 2, colTablet: 2 },
                                { label: 'Actions', col: 1, colTablet: 1, align: 'left', }
                            ],
                            footerLabels: [
                                { type: 'buttons', col: 12, buttonTitle: 'Add State', icon: 'add' },
                                { type: 'totalMoney', name: 'othNpHealhInsReqReserve', col: 9, colTablet: 4, label: '$ 0' },
                                { type: 'totalMoney', name: 'othNpHealhInsReserves', col: 2, colTablet: 2, label: '$ 0' },
                            ],
                            lineButtons: [
                                { button: 'remove', },
                            ]
                        }
                    },
                    {
                        groupId: 12,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Did the organization receive any payments for indoor tanning services during the year?', name: 'othFilingsTanPayLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsTanPay', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showOthFilingsPaymentRpt' }] },
                        ]
                    },
                    {
                        groupId: 13,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Has the organization already filed Form 720 - Quarterly Federal Excise Tax Return?', name: 'othFilingsPaymntRptLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsPaymntRpt', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showOthFilingsNonPaymntRptRsn' }] },
                        ]
                    },
                    {
                        groupId: 14,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Explain why you didn\'t file Form 720', name: 'othFilingsNonPaymntRptRsnLabel', type: 'label', col: 9, class: 'labelTitleTextArea', },
                            { label: 'Please explain', name: 'othFilingsNonPaymntRptRsn', type: 'textarea', col: 12, maxRows: 3, class: 'fieldTextArea' },
                        ]
                    },
                    {
                        groupId: 15,
                        isVisible: true,
                        hasDivider: true,
                        fields: [
                            { label: 'Was anyone paid more than $1 Million in remuneration or excess parachute payments during the year?', name: 'othFilingsSubSec4960TaxLabel', type: 'label', col: 9 },
                            { label: '', name: 'othFilingsSubSec4960Tax', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showOthFilingsParachutePayData' }] },
                        ]
                    },
                    {
                        groupId: 16,
                        isVisible: false,
                        fields: [
                            { label: 'Complete the table below:', name: 'othFilingsParachutePayData', type: 'label', col: 10, class: 'labelQuestionTitle' },
                        ]
                    },
                    {
                        groupType: 'lineItem',
                        isVisible: false,
                        groupId: 17,
                        fields: [
                            { label: 'Person\'s Name', name: 'othParaPayName', type: 'freeText' },
                            { label: 'Title', name: 'othParaPayTitle', type: 'freeText' },
                            { label: 'Amount', name: 'othParaPayAmount', type: 'money', isTotal: true },
                            { type: 'lineButtons' }
                        ],
                        lineItems: [],
                        headerLabelsPrintStyles: {gridTemplateColumns: '2.5fr 2fr 2fr .5fr'},
                        footerLabelsPrintStyles: {gridTemplateColumns: '8fr'},
                        lineItemDetails:
                        {
                            headerLabels: [
                                { label: 'Person\'s Name', col: 5, colTablet: 3 },
                                { label: 'Title', col: 4, colTablet: 2 },
                                { label: 'Amount of Parachute Payment', col: 2, colTablet: 2 },
                                { label: 'Actions', col: 1, colTablet: 1, align: 'left', }
                            ],
                            footerLabels: [
                                { type: 'totalMoney', name: 'othParaPayAmount', col: 11, colTablet: 2, label: '$ 0' },
                                { type: 'buttons', col: 12, buttonTitle: 'Add a Person', icon: 'add' },
                            ],
                            lineButtons: [
                                { button: 'remove', },
                            ]
                        }
                    },
                    {
                        groupId: '18Removed',
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Was anyone paid more than $1 Million in remuneration or excess parachute payments during the year? ', name: 'othFilingsPaidMillionLabel', type: 'label', col: 9 },
                            { label: 'Please select', name: 'othFilingsPaidMillion', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
                        ]
                    },
                    {
                        groupId: 19,
                        isVisible: false,
                        hasDivider: true,
                        fields: [
                            { label: 'Is the organization subject to excise tax on net investment income?', name: 'othFilingsExciseTaxLabel', type: 'label', col: 9 },
                            { label: 'Please select', name: 'othFilingsExciseTax', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
                        ]
                    },
                ]
            },
            {  
                title: 'Additional Information',
                collapseClass: 'collapsibleForm',
                isVisible: true,
                isDefaultExpanded: false,
                isOptionalSectionMerge: true,
                sectionId: 4,
                groups: [
                {
                    groupId: 2,
                    groupType: 'details',
                    fields: [
                    { 
                        label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
                        type: 'label', 
                        class: 'labelLeftLight' 
                    },
                    ]
                },
                {
                    groupId: 1,
                    fields: [
                        {
                            label: 'Start typing to provide additional information', 
                            name: 'additionalInfoTextArea', 
                            type: 'textarea', 
                            col: 12,
                            maxLength: 9000
                        }
                    ]
                }
                ]
            },
        ];
		setSectionState(sections);
		// eslint-disable-next-line    
	}, []);

	const axcessIdentifiers = [
		{ section: 'Return Type', id: 'Form 990 - Exempt Organization Return' }
	];

	return (
        <>
            {!isLoading && isActiveClient && <FormRendererHelper
                sections={sectionState}
                key="taxComplianceFilingsForm"
                formName={REDUX.TAX_COMPLIANCE_FILINGS}
                sectionFieldLogic={sectionFieldLogic}
                identifiers={axcessIdentifiers}
                isExportForm={isExportForm}
            />}
            {!isLoading && !isActiveClient && <NoActiveClient />}
        </>
	);
}
export default TaxComplianceFilings;