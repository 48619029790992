import React, { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { Validators } from 'cla-formrenderer';
import FormRendererHelper from '@components/formRenderer';
import { form } from '@utilities/helpers/getFormSections';
import { triggeredEvent, sectionFieldLogic } from './hospitalLogicTrigger';
import { pullFieldValue } from '@utilities/pullFieldValue';
import * as NAV from '@utilities/constants/navigation';
import { MERGE_WITH_OTHER_DATA, MERGE_WITH_OTHER_ROW } from '@utilities/constants/axcessKeys';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

export const hospitalAndPracticeSection = [
  {
    title: 'Community Health Needs Assessment',
    isVisible: true,
    sectionId: 1,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Was the hospital first licensed, registered, or similarly recognized by a state as a hospital during the tax year or in the immediately preceding tax year?',
            name: 'hosChnaPyRegLicLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosChnaPyRegLic',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.38'],
                fn: 'checkboxToYNPull'
              }
            }
          },
          {
            name: 'HospitalName',
            isVisible: false,
            type: 'freeText',
            prior: true,
            isPriorEditable: false,
            axcess: {
              pull: {
                fields: ['XFDS99H8.0'],
                fn: 'anyValidPull'
              }
            }
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Was the hospital acquired or placed into service as a 501(c)(3) hospital in the current tax year or in the immediately preceding tax year?',
            name: 'hosChna501C3CYLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosChna501C3CY',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showAcquisitionDetails', isDifferentGroup: true }
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.39'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 3,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Provide details of the acquisition.',
            name: 'hosChnaAcqDtlLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'hosChnaAcqDtl',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDSCHN.24'], true)
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              "Indicate the year in which the hospital most recently conducted a CHNA. For example, if a hospital with a June 30 year-end completed their CHNA by June 30, 2022, it should enter '2021' as the tax year.",
            name: 'hosChnaYrCHNALabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Year',
            name: 'hosChnaYrCHNA',
            type: 'yearPicker',
            col: 2,
            logicFunction: [
              { trigger: 'calculateLastCHNA', isDifferentGroup: true },
              { trigger: 'calculateSummaryCHNA' },
              { trigger: 'calculateSummaryError' }
            ],
            prior: true,
            ...pullFieldValue(['XFDSCHN.12'], true)
          }
        ]
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Calculate the number of years since last CHNA - (if answer is greater than 2 years skip ahead to step 2)',
            name: ' hosChnaYrCHNAcalcLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosChnaYrCHNAcalc',
            isDisable: true,
            type: 'number',
            col: 2
          }
        ]
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the hospital conduct a CHNA during this tax year or the one of the two previous years?',
            name: 'hosChnaYrCHNA0to2Label',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosChnaYrCHNA0to2',
            type: 'radio',
            isDisable: true,
            lookup: 'YESNO_RADIO',
            col: 2,
            prior: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.0'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 25,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'In conducting its most recent CHNA, did the hospital take into account input from persons who represent the broad interests of the community, including those with special knowledge of or expertise in public health?',
            name: 'hosChnaSpecKnowLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosChnaSpecKnow',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showhosChnaSpecKnow', isDifferentGroup: true }
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.13'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 26,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe how the hospital took into account input from persons who represent the broad interests of the community.  Describe how the hospital consulted with these persons.  Identify any businesses or organizations with which the hospital consulted.',
            name: 'hosChnaSpecKnowHowLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'hosChnaSpecKnowHow',
            type: 'textarea',
            maxLength: 9000,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDSCHN.14'], true)
          }
        ]
      },
      {
        groupId: 27,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              "Was the hospital's CHNA conducted in collaboration with one or more other hospitals?",
            name: 'hosChnaManyLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosChnaMany',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showhosChnaMany', isDifferentGroup: true }
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.15'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 28,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Identify the other hospital(s).',
            name: 'hosChnaManyIDLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Other hospital(s).',
            name: 'hosChnaManyID',
            type: 'freeText',
            maxLength: 9000,
            col: 3,
            prior: true,
            ...pullFieldValue(['XFDSCHN.20'], true)
          }
        ]
      },
      {
        groupId: 29,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              "Was the hospital's CHNA conducted in collaboration with one or more non-hospital organizations?",
            name: 'hosChnaManyNonHsptLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosChnaManyNonHspt',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showhosChnaManyNonHspt', isDifferentGroup: true }
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.40'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 30,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Identify the other non-hospital organization(s).',
            name: 'hosChnaManyNonHsptIDLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Other non-hospital organization(s).',
            name: 'hosChnaManyNonHsptID',
            type: 'freeText',
            maxLength: 9000,
            col: 3,
            prior: true,
            ...pullFieldValue(['XFDSCHN.41'], true)
          }
        ]
      },
      {
        groupId: 31,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Does the hospital make its CHNA Report widely available to the public?',
            name: 'hosChnaPublicAvailLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosChnaPublicAvail',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showhosChnaPublicAvail', isDifferentGroup: true }
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.16'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 32,
        isVisible: false,
        fields: [
          {
            label:
              'Indicate how the CHNA Report is made widely available to the public (Check all that apply):',
            name: 'labelTwo',
            type: 'label',
            col: 10,
            class: 'labelQuestionTitle'
          }
        ]
      },
      {
        groupId: 33,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: "On the hospital's website",
            name: 'hosChnaHosWebLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaHosWeb',
            col: 2,
            class: 'singleCheckbox',
            logicFunction: [
              { trigger: 'showhosChnaHosWeb', isDifferentGroup: true }
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.17'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 34,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide the direct website address (URL) where the CHNA Report is available.',
            name: 'hosChnaHosWebAddLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'website address',
            name: 'hosChnaHosWebAdd',
            type: 'freeText',
            maxLength: 75,
            col: 3,
            prior: true,
            ...pullFieldValue(['XFDSCHN.35'], true)
          }
        ]
      },
      {
        groupId: 35,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'On another website',
            name: 'hosChnaOthWebLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaOthWeb',
            col: 2,
            class: 'singleCheckbox',
            logicFunction: [
              { trigger: 'showhosChnaOthWeb', isDifferentGroup: true }
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.37'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 36,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide the direct website address (URL) where the CHNA Report is available.',
            name: 'hosChnaOthWebAddLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'website address',
            name: 'hosChnaOthWebAdd',
            type: 'freeText',
            maxLength: 75,
            col: 3,
            prior: true,
            ...pullFieldValue(['XFDSCHN.36'], true)
          }
        ]
      },
      {
        groupId: 37,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Paper copies are made available for public inspection without charge at the hospital',
            name: 'hosChnaPaperLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaPaper',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.18'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 38,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Other',
            name: 'hosChnaOther1Label',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaOther1',
            col: 2,
            class: 'singleCheckbox',
            logicFunction: [
              { trigger: 'showhosChnaOther1Desc', isDifferentGroup: true }
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.19'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 39,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the other methods of making the CHNA Report widely available to the public.',
            name: 'hosChnaOther1DescLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'hosChnaOther1Desc',
            type: 'textarea',
            maxLength: 9000,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDSCHN.11'], true)
          }
        ]
      },
      {
        groupId: 18,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the actions taken during the current tax year to address significant health needs identified in all prior community health needs assessments.  If no actions were taken to address a significant community health need, identify the need and explain why.',
            name: 'hosChnaActionsAddNeedsLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'hosChnaActionsAddNeeds',
            type: 'textarea',
            maxLength: 9000,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDSCHN.33'], true)
          }
        ]
      },
      {
        groupId: 19,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Describe the other items included in the CHNA Report.',
            name: 'hosChnaOtherDesLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'hosChnaOtherDes',
            type: 'textarea',
            maxLength: 9000,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDSCHN.11'], true)
          }
        ]
      },
      {
        groupId: 20,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the hospital facility adopt an implementation strategy to meet the significant community health needs identified through its most recently conducted CHNA?',
            name: 'hosChnaAdptImpStrtLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosChnaAdptImpStrt',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showhosChnaYrAdopt', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 21,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Indicate the tax year in which the hospital most recently adopted an implementation strategy.',
            name: 'hosChnaYrAdoptLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Year',
            name: 'hosChnaYrAdopt',
            type: 'yearPicker',
            col: 2,
            prior: true,
            ...pullFieldValue(['XFDSCHN.34'], true)
          }
        ]
      },
      {
        groupId: 22,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              "Is the hospital's most recently adopted implementation strategy available on a website?",
            name: 'hosChnaImpStratAvailWebLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosChnaImpStratAvailWeb',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            maxLength: 75,
            col: 2,
            logicFunction: [
              {
                trigger: 'showhosChnaImpStratAvailWebAdd',
                isDifferentGroup: true
              }
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.42'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 23,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide the direct website address (URL) where the implementation strategy is available.',
            name: 'hosChnaImpStratAvailWebAddLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Website Address',
            name: 'hosChnaImpStratAvailWebAdd',
            type: 'freeText',
            maxLength: 75,
            col: 3,
            prior: true,
            ...pullFieldValue(['XFDSCHN.43'], true)
          }
        ]
      },
      {
        groupId: 40,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Do you want to attach the implementation strategy to this Form 990? (This is optional)',
            name: 'hosChnaImpStratAttchLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosChnaImpStratAttch',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2
          }
        ]
      },
      {
        groupId: 41,
        isVisible: false,
        hasDivider: true,
        groupType: 'lineItem',
        uploadType: 'drawer',
        class: 'householdEmploymentUpload',
        fields: [
          {
            label:
              'Upload a copy of the most recently completed Implementation Strategy',
            name: 'hosChnaImpStratAttchCpyLabel',
            type: 'label',
            col: 9
          },
          { type: 'lineButtons', col: 3 }
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [],
          footerLabels: [],
          lineButtons: [
            {
              button: 'uploadOnly',
              uploadSectionName: 'hosChnaImpStratAttchCpy',
              viewSectionId: 0
            }
          ]
        }
      },
      {
        groupId: 7,
        isVisible: false,
        fields: [
          {
            label:
              'Which of the following does that CHNA Report include (check all that apply)?',
            name: 'labelOne',
            type: 'label',
            col: 10,
            class: 'labelQuestionTitle'
          }
        ]
      },
      {
        groupId: 8,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'A definition of the community served by the hospital',
            name: 'hosChnaDefineLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaDefine',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.1'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 9,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'A description of the demographics of the community',
            name: 'hosChnaDemoLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaDemo',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.2'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 10,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Existing health care facilities and resources within the community that are available to respond to the identified community health needs',
            name: 'hosChnaExistLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaExist',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.3'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 11,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'A description of how the data was obtained',
            name: 'hosChnaDataLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaData',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.4'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 12,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'A description of the significant community health needs',
            name: 'hosChnaNeedsLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaNeeds',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.5'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 13,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'A description of primary and chronic disease needs and other health issues of uninsured persons, low-income persons and minority groups',
            name: 'hosChnaRxChronicLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaRxChronic',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.6'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 14,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'A description of the process of identifying and prioritizing community health needs and services to meet the community health needs',
            name: 'hosChnaPriortzLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaPriortz',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.7'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 15,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              "A description of the process for consulting with persons representing the community's interests",
            name: 'hosChnaConsultLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaConsult',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.8'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 16,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              "A description of any information gaps that limit the hospital's ability to assess the community's health needs",
            name: 'hosChnaInfoGapLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaInfoGap',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.9'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 17,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Other',
            name: 'hosChnaOtherLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChnaOther',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSCHN.10'],
                fn: 'checkboxPull'
              }
            },
            logicFunction: [
              { trigger: 'showhosChnaOtherDesc', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 24,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the other methods of making the CHNA Report widely available to the public.',
            name: 'hosChnaOtherDescLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'hosChnaOtherDesc',
            type: 'textarea',
            maxLength: 9000,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDSCHN.21'], true)
          }
        ]
      }
    ]
  },
  {
    title: 'Financial Assistance Policy',
    isVisible: true,
    sectionId: 2,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'As of the end of the tax year, which of the following eligibility criteria for financial assistance were identified in the written financial assistance policy (FAP)? (check all that apply)',
            name: 'hosFapEligCritLabel',
            type: 'label',
            col: 10
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Federal poverty guidelines (FPG)',
            name: 'hosFapFpgLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapFpg',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Income level other than FPG',
            name: 'hosFapOthIncmLvlLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapOthIncmLvl',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            triggered: 'showHosFapOthIncmLvlDesc',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.6'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 4,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the income guidelines other than FPG used as a criteria for financial assistance.',
            name: 'hosFapOthIncmLvlDescLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Description',
            name: 'hosFapOthIncmLvlDesc',
            type: 'textarea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS9H15.26'], true)
          }
        ]
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Asset level',
            name: 'hosFapAsstLvlLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapAsstLvl',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.7'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Medical indigency',
            name: 'hosFapMedIndLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapMedInd',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.8'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Insurance status',
            name: 'hosFapInsStatLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapInsStat',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.9'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 8,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Underinsurance status',
            name: 'hosFapUnderinsLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapUnderins',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.10'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 9,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Residency',
            name: 'hosFapRsdncyLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapRsdncy',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.27'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Other',
            name: 'hosFapOthLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapOth',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            triggered: 'showHosFapOthDesc',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.13'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 11,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the other criteria used to determine eligibility for financial assistance.',
            name: 'hosFapOthDescLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Description',
            name: 'hosFapOthDesc',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS9H15.25'], true)
          }
        ]
      },
      {
        groupId: 12,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'By the end of the tax year, did the hospital have a written FAP that explains the basis for calculating amounts charged to patients?',
            name: 'hosFapCalcBasisLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapCalcBasis',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.5'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 13,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'By the end of the tax year, did the hospital have a written FAP that explains the method of applying for financial assistance?',
            name: 'hosFapFinAsstMthLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapFinAsstMth',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            triggered: 'showHosFapFinAsstMthLabel11',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.14'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 14,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Which of the following were included in the FAP or financial assistance application form (including the accompanying instructions) to explain the method of applying for financial assistance?',
            name: '',
            type: 'label',
            col: 10
          }
        ]
      },
      {
        groupId: 15,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'A description of the information the hospital may require an individual to provide as part of their application.',
            name: 'hosFapInfoReqLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapInfoReq',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.1'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      // {
      // 	groupId: 16,
      // 	isVisible: true,
      // 	hasDivider: true,
      // 	fields: [
      // 		{
      // 			label: 'A description of the information the hospital may require an individual to provide as part of their application.',
      // 			name: 'hosFapInfoReqLabel', type: 'label', col: 10
      // 		},
      // 		{
      // 			label: 'Yes', name: 'hosFapInfoReq', type: 'checkbox',
      // 			col: 2,
      // 			class: 'singleCheckboxMargin',
      // 			isFieldCheckbox: true
      // 		},
      // 	]
      // },
      {
        groupId: 16,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'A description of the supporting documentation the hospital may require an individual to submit as part of their application.',
            name: 'hosFapSupDocLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapSupDoc',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.3'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 17,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'The contact information of hospital staff who can provide information about the FAP and the FAP application process.',
            name: 'hosFapContactStaffLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapContactStaff',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.11'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 18,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'The contact information of nonprofit organizations and/or government agencies that may provide assistance with FAP applications.',
            name: 'hosFapContactNonProfLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapContactNonProf',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.12'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 19,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Other',
            name: 'hosFapOthAsstLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapOthAsst',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            triggered: 'showHosFapOtherAssistDesc',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.28'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 20,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the other methods of applying for financial assistance that are described in the FAP.',
            name: 'hosFapOtherAssistDescLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Description',
            name: 'hosFapOtherAssistDesc',
            type: 'textarea',
            col: 12,
            class: 'fieldTeaxtArea',
            maxLength: 900,
            prior: true,
            ...pullFieldValue(['XFDS9H15.23'], true)
          }
        ]
      },
      {
        groupId: 21,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'By the end of the tax year, did the hospital have a written FAP that includes measures to publicize the FAP within the community?',
            name: 'hosFapWrtMeasPubLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapWrtMeasPub',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            triggered: 'showHosFapWrtMeasPub1',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.15'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 22,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'In which of the following ways did the hospital publicize the FAP? (Check all that apply)',
            name: '',
            type: 'label',
            col: 10
          }
          // {
          // 	label: 'Yes', name: 'hosFapWrtMeasPub1', type: 'checkbox',
          // 	col: 2,
          // 	class: 'singleCheckboxMargin',
          // 	isFieldCheckbox: true
          // },
        ]
      },
      {
        groupId: 23,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'The FAP is widely available on a website.',
            name: 'hosFapWebLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapWeb',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            triggered: 'showHosFapWebAdd',
            // triggered: 'showHosFapWrtMeasPub1'
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.16'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 24,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide the direct website address (URL) where the FAP is available.',
            name: 'hosFapWebAddLabel',
            type: 'label',
            col: 10,
            class: 'labelTitleTextArea'
          },
          {
            label: '',
            name: 'hosFapWebAdd',
            type: 'textarea',
            maxLength: 76,
            col: 12,
            class: 'fieldTextArea',
            logicFunction: [{ trigger: 'setFapDetails' }],
            prior: true,
            ...pullFieldValue(['XFDS9H15.31'], true)
          }
        ]
      },
      {
        groupId: 25,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'The financial assistance application is widely available on a website.',
            name: 'hosFapAppWebLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapAppWeb',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            triggered: 'showHosFapAppWebAdd',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.17'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 26,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide the direct website address (URL) where the financial assistance application is available.',
            name: 'hosFapAppWebAddLabel',
            type: 'label',
            col: 10,
            class: 'labelTitleTextArea'
          },
          {
            label: '',
            name: 'hosFapAppWebAdd',
            type: 'textarea',
            maxLength: 76,
            col: 12,
            class: 'fieldTextArea',
            logicFunction: [{ trigger: 'setFapDetails' }],
            prior: true,
            ...pullFieldValue(['XFDS9H15.32'], true)
          }
        ]
      },
      {
        groupId: 27,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'A plain language summary of the FAP is widely available on a website.',
            name: 'hosFapPlainLangWebLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapPlainLangWeb',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            triggered: 'showHosFapPlainLangWebAdd',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.18'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 28,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide the direct website address (URL) where the plain language summary of the FAP is available.',
            name: 'hosFapPlainLangWebAddLabel',
            type: 'label',
            col: 10,
            class: 'labelTitleTextArea'
          },
          {
            label: '',
            name: 'hosFapPlainLangWebAdd',
            type: 'textarea',
            maxLength: 76,
            col: 12,
            class: 'fieldTextArea',
            logicFunction: [{ trigger: 'setFapDetails' }],
            prior: true,
            ...pullFieldValue(['XFDS9H15.33'], true)
          }
        ]
      },
      {
        groupId: 29,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'The FAP is available upon request and without charge at the hospital and by mail.',
            name: 'hosFapUponReqLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapUponReq',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.19'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 30,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'The financial assistance application is available upon request and without charge at the hospital and by mail.',
            name: 'hosFapAppUponReqLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapAppUponReq',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.20'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 31,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'A plain language summary of the FAP is available upon request and without charge at the hospital and by mail.',
            name: 'hosFapPlainLangUponReqLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapPlainLangUponReq',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.21'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 32,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'A description of the information the hospital may require an individual to provide as part of their application.',
            name: 'hosFapInfoReqLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapInfoReq',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true
          }
        ]
      },
      {
        groupId: 33,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Individuals are offered a paper copy of the plain language summary of the FAP as part of intake or discharge.',
            name: 'hosFapIntakeDischgLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapIntakeDischg',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true
          }
        ]
      },
      {
        groupId: 34,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Information about the FAP is conspicuously included on billing statements.',
            name: 'hosFapOnBillLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapOnBill',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true
          }
        ]
      },
      {
        groupId: 35,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Notices of the availability of the FAP are conspicuously displayed throughout the hospital.',
            name: 'hosFapDisplayHsptlLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapDisplayHsptl',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true
          }
        ]
      },
      {
        groupId: 36,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Members of the community who are most likely to require financial assistance were notified about availability of the FAP.',
            name: 'hosFapCommNotifyLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapCommNotify',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.29'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 37,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'The FAP, FAP application form, and plain language summary of the FAP were translated into the primary language(s) spoken by Limited English Proficiency (LEP) populations',
            name: 'hosFapTranslateLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapTranslate',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.30'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 38,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Other',
            name: 'hosFapOthPubLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFapOthPub',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            triggered: 'showHosFapOthPubDesc',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H15.34'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 39,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the other methods to publicize the FAP within the community.',
            name: 'hosFapOthPubDescLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Description',
            name: 'hosFapOthPubDesc',
            type: 'textarea',
            col: 12,
            class: 'fieldTeaxtArea',
            prior: true,
            ...pullFieldValue(['XFDS9H15.24'], true)
          }
        ]
      },
      {
        groupId: 40,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              "Describe how the hospital informs and educates individuals in the community about their eligibility for assistance under federal, state or local government programs or under the hospital's FAP.",
            name: 'hosFapInfoEdDescLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Description',
            name: 'hosFapInfoEdDesc',
            type: 'textarea',
            col: 12,
            class: 'fieldTeaxtArea',
            prior: true,
            ...pullFieldValue(['XFDS99H9.1'], true)
          }
        ]
      }
    ]
  },
  {
    title: 'Billing and Collections',
    isVisible: true,
    sectionId: 3,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              "By the end of the tax year, did the hospital's FAP or a separate written billing and collections policy explaining all of the actions the hospital (or other authorized parties) may take in the event of non-payment?",
            name: 'hosBillClctPlcyNoPayLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosBillClctPlcyNoPay',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.0'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label:
              "During the tax year, which of the following actions were permitted, under the hospital's policies, against an individual before making reasonable efforts to determine the individual's eligibility for financial assistance under the FAP? (Check all that apply)",
            name: 'hosBillLabel2',
            type: 'label',
            col: 10,
            class: 'labelQuestionTitle'
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Reporting to credit agencies',
            name: 'hosBillPermCredAgcyLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillPermCredAgcy',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillPermNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.1'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: "Selling an individual's debt to another party",
            name: 'hosBillPermSellDbtLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillPermSellDbt',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillPermNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.2'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Deferring, denying, or requiring a payment before providing medically necessary care due to nonpayment of a previous bill for FAP-eligible services',
            name: 'hosBillPermDeferLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillPermDefer',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillPermNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.20'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Actions that require a legal or judicial process',
            name: 'hosBillPermLegalLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillPermLegal',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillPermNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.3'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Other',
            name: 'hosBillPermOtherLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillPermOther',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            logicFunction: [
              {
                trigger: 'showhosBillPermOtherDescLabel',
                isDifferentGroup: true
              }
            ],
            triggered: 'showHosBillPermNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.4'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 8,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Describe the other similar action(s).',
            name: 'hosBillPermOtherDescLabel',
            type: 'label',
            col: 12,
            class: 'labelTitleTextArea'
          },
          {
            label: 'Description',
            placeHolder: 'Please enter your description here',
            name: 'hosBillPermOtherDesc',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS9H16.6'], true)
          }
        ]
      },
      {
        groupId: 9,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'None of the above',
            name: 'hosBillPermNoneLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillPermNone',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillPermNoneDisOthr',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.5'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 10,
        isVisible: true,
        fields: [
          {
            label:
              "During the tax year, which of the following actions were taken made by the hospital or other authorized parties before making reasonable efforts to determine the an individual's eligibility for financial assistance under the FAP? (Check all that apply)",
            name: 'hosBillLabel3',
            type: 'label',
            col: 10,
            class: 'labelQuestionTitle'
          }
        ]
      },
      {
        groupId: 11,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Reporting to credit agencies',
            name: 'hosBillTknCredAgcyLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillTknCredAgcy',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillTknNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.8'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 12,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: "Selling an individual's debt to another party",
            name: 'hosBillTknSellDbtLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillTknSellDbt',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillTknNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.9'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 13,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Deferring, denying, or requiring a payment before providing medically necessary care due to nonpayment of a previous bill for FAP-eligible services',
            name: 'hosBillTknDeferLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillTknDefer',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillTknNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.21'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 14,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Actions that require a legal or judicial process',
            name: 'hosBillTknLegalLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillTknLegal',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillTknNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.10'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 15,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Other',
            name: 'hosBillTknOtherLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillTknOther',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            logicFunction: [
              {
                trigger: 'showhosBillTknOtherDescLabel',
                isDifferentGroup: true
              }
            ],
            triggered: 'showHosBillTknNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.11'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 16,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Describe the other similar action(s).',
            name: 'hosBillTknOtherDescLabel',
            type: 'label',
            col: 12,
            class: 'labelTitleTextArea'
          },
          {
            label: 'Description',
            placeHolder: 'Please enter your description here',
            name: 'hosBillTknOtherDesc',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS9H16.13'], true)
          }
        ]
      },
      {
        groupId: 17,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'None of the above',
            name: 'hosBillTknNoneLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillTknNone',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillTknNoneDisOthr'
          }
        ]
      },
      {
        groupId: 18,
        isVisible: true,
        fields: [
          {
            label:
              "During the tax year, which of the following efforts were made by the hospital or other authorized parties to determine individuals' eligibility for financial assistance before initiating any extraordinary collection actions? (Check all that apply)",
            name: 'hosBillLabel4',
            type: 'label',
            col: 10,
            class: 'labelQuestionTitle'
          }
        ]
      },
      {
        groupId: 19,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Provided a written notice about upcoming extraordinary collection actions and a plain language summary of the FAP at least 30 days before initiating those extraordinary collection actions.',
            name: 'hosBillInitWrtnNoteLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillInitWrtnNote',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillInitNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.15'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 20,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Made reasonable efforts to orally notify individuals about the FAP and the application process.',
            name: 'hosBillInitOralNoteLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillInitOralNote',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillInitNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.16'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 21,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Processed complete and incomplete FAP applications.',
            name: 'hosBillInitAppsProcLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillInitAppsProc',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillInitNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.17'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 22,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Made presumptive eligibility determinations.',
            name: 'hosBillInitPrsmptvLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillInitPrsmptv',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillInitNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.18'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 23,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Other',
            name: 'hosBillInitOthLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillInitOth',
            col: 2,
            class: 'singleCheckbox',
            logicFunction: [
              { trigger: 'showhosBillInitOthDescLabel', isDifferentGroup: true }
            ],
            isDisable: false,
            triggered: 'showHosBillInitNone',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.19'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 24,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the other efforts made by the hospital or other authorized parties.',
            name: 'hosBillInitOthDescLabel',
            type: 'label',
            col: 12,
            class: 'labelTitleTextArea'
          },
          {
            label: 'Description',
            placeHolder: 'Please enter your description here',
            name: 'hosBillInitOthDesc',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS9H16.14'], true)
          }
        ]
      },
      {
        groupId: 25,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'None of these efforts were made',
            name: 'hosBillInitNoneLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosBillInitNone',
            col: 2,
            class: 'singleCheckbox',
            isDisable: false,
            triggered: 'showHosBillInitNoneDisOthr',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H16.12'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      }
    ]
  },
  {
    title: 'Emergency Medical Care',
    isVisible: true,
    sectionId: 4,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'Emergancy Medical care',
            name: 'hosEmgcWtnPolLabel1',
            type: 'label',
            col: 10
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            htmlLabel: (
              <React.Fragment key={'hospital-emergency-medicalcare'}>
                An "Emergency medical conditions" is any medical condition
                manifesting itself by acute symptoms of sufficient severity
                (including severe pain) such that the absence of immediate
                medical attention could reasonably be expected to result in:
                <ul key={'hospital-info-statementOne'}>
                  <li
                    key={'hospital-emergency-medicalcare-1'}
                    className="numListItem">
                    Placing the health of the individual (or, in the case of a
                    pregnant woman, the health of the woman or the unborn child)
                    in serious jeopardy;
                  </li>
                  <li
                    key={'hospital-emergency-medicalcare-2'}
                    className="numListItem">
                    Serious impairment to bodily functions; or
                  </li>
                  <li
                    key={'hospital-emergency-medicalcare-3'}
                    className="numListItem">
                    Serious dysfunction of any bodily organ or part.
                  </li>
                </ul>
              </React.Fragment>
            ),
            name: 'hosEmgcWtnPolLabel2',
            type: 'label',
            col: 12
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        fields: [
          {
            htmlLabel: (
              <React.Fragment key={'hospital-emergency-care'}>
                For a pregnant woman who is having contractions, an emergency
                medical condition also includes situations in which:
                <ul>
                  <li key={'hospital-emergency-care-1'} className="numListItem">
                    There is inadequate time to effect a safe transfer to
                    another hospital before delivery; or
                  </li>
                  <li key={'hospital-emergency-care-2'} className="numListItem">
                    Transfer may pose a threat to the health or safety of the
                    woman or the unborn child.
                  </li>
                </ul>
              </React.Fragment>
            ),
            name: 'hosEmgcWtnPolLabel3',
            type: 'label',
            col: 12
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              "By the end of the tax year, did the hospital have a written policy related to emergency medical care that requires the hospital to provide, without discrimination, care for emergency medical conditions to individuals regardless of their eligibility for financial assistance under the hospital's FAP?",
            name: 'hosEmgcWtnPolLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'hosEmgcWtnPol',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            triggered: 'showhosEmgcWtnPolLabel4',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H17.0'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 5,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Which of the following is the best explanation for why it did not?',
            name: 'hosEmgcWtnPolLabel4',
            type: 'label',
            col: 9
          }
        ]
      },
      {
        groupId: 6,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              "The hospital didn't provide care for any emergency medical conditions during the tax year.",
            name: 'hosEmgcNoCareLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Yes',
            name: 'hosEmgcNoCare',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H17.1'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 7,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: "The hospital's policy was not in writing.",
            name: 'hosEmgcNoWriteLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Yes',
            name: 'hosEmgcNoWrite',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H17.2'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 8,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'The hospital limited who was eligible to receive care for emergency medical conditions.',
            name: 'hosEmgcLmtdEligLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Yes',
            name: 'hosEmgcLmtdElig',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            triggered: 'showhosEmgcLmtdEligDesc',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H17.3'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 9,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Describe the limitations.',
            name: 'hosEmgcLmtdEligDescLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Description',
            name: 'hosEmgcLmtdEligDesc',
            type: 'textarea',
            col: 12,
            class: 'fieldTeaxtArea',
            maxLength: 900,
            prior: true,
            ...pullFieldValue(['XFDS9H17.5'], true)
          }
        ]
      },
      {
        groupId: 10,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Other',
            name: 'hosEmgcOthLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Yes',
            name: 'hosEmgcOth',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            triggered: 'showHosEmgcOthDescLabel',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H17.4'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 11,
        isVisible: false,
        fields: [
          {
            label: 'Describe the other reason(s) why.',
            name: 'hosEmgcOthDescLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Description',
            name: 'hosEmgcOthDesc',
            type: 'textarea',
            col: 12,
            class: 'fieldTeaxtArea',
            maxLength: 900,
            prior: true,
            ...pullFieldValue(['XFDS9H17.6'], true)
          }
        ]
      }
    ]
  },

  {
    title: 'Limitations on Charges Under FAP',
    isVisible: true,
    sectionId: 5,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label:
              'During the tax year, how did the hospital determine the maximum amount(s) that it could charge to FAP-eligible individuals (i.e. the "AGB Limit") for emergency and other medically necessary care (check one)? ',
            name: 'hosChrgEqlGrossChrgLabel1',
            type: 'label',
            col: 10,
            class: 'labelQuestionTitle'
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              '1. The look-back method based on claims allowed by Medicare fee-for-service.',
            name: 'hosChrgLookMcFeeLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChrgLookMcFee',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H18.0'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              '2. The look-back method based on claims allowed by Medicare fee-for-service and all private health insurers.',
            name: 'hosChrgLookMcFeePrvInsLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChrgLookMcFeePrvIns',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H18.7'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              '3. The look-back method based on claims allowed by Medicaid.',
            name: 'hosChrgLookMaLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChrgLookMa',
            col: 2,
            class: 'singleCheckbox'
          }
        ]
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              '4. The look-back method based on claims allowed by Medicaid and Medicare fee-for-service.',
            name: 'hosChrgLookMaMcFeeLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChrgLookMaMcFee',
            col: 2,
            class: 'singleCheckbox'
          }
        ]
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              '5. The look-back method based on claims allowed by Medicaid, Medicare fee-for-service, and all private health insurers.',
            name: 'hosChrgLookMaMcFeePrvInsLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChrgLookMaMcFeePrvIns',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H18.8'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              '6. The prospective method based on Medicare or Medicaid reimbursement rates',
            name: 'hosChrgReimbMthdLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChrgReimbMthd',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS9H18.9'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 8,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'During the tax year, did the hospital charge any FAP-eligible individual more than the amounts generally billed to individuals who had insurance covering such care?  (This question only relates to emergency and other medically necessary care.)',
            name: 'hosChrgMoreInsCovLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChrgMoreInsCov',
            col: 2,
            class: 'singleCheckbox',
            logicFunction: [
              { trigger: 'showhosChrgMoreInsCovExp', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 9,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Explain the situation(s) in which the hospital charged more than amounts generally billed to a FAP-eligible individual for emergency or other medically necessary care.',
            name: 'hosChrgMoreInsCovExpLabel',
            type: 'label',
            col: 12,
            class: 'labelTitleTextArea'
          },
          {
            label: 'Description',
            placeHolder: 'Please enter your description here',
            name: 'hosChrgMoreInsCovExp',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS9H18.6'], true)
          }
        ]
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'During the tax year, did the hospital charge any FAP-eligible individual an amount equal to gross charges? (Answer yes only if an individual was actually required to pay the gross charge amount.  Answer no if gross charges were only used as the basis of calculating a reduced liability amount.)',
            name: 'hosChrgEqlGrossChrgLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosChrgEqlGrossChrg',
            col: 2,
            class: 'singleCheckbox',
            logicFunction: [
              { trigger: 'showhosChrgEqlGrossChrgExp', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 11,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Explain the situation in which the hospital charged a FAP-eligible individual an amount equal to gross charges.',
            name: 'hosChrgEqlGrossChrgExpLabel',
            type: 'label',
            col: 12,
            class: 'labelTitleTextArea'
          },
          {
            label: 'Description',
            placeHolder: 'Please enter your description here',
            name: 'hosChrgEqlGrossChrgExp',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS9H18.3'], true)
          }
        ]
      }
    ]
  }
];

export const hospitalLineSections = [
  {
    title: 'Community Health Needs Assessment',
    isVisible: true,
    sectionId: 1,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Hospital Name',
            name: 'hosFacHosName',
            placeholder: 'name',
            type: 'freeText',
            col: 6,
            isSyncFieldValue: true,
            logicFunction: [
              { trigger: 'setHospitalName', isDifferentGroup: true },
              { trigger: 'showHospitalNameInSummary' }
            ],
            prior: true,
            ...pullFieldValue(['XFDS99H8.0'], true),
            printFieldWidth: 360
          },
          {
            label: 'Website Address',
            name: 'hosFacHosWeb',
            type: 'freeText',
            col: 3,
            logicFunction: [{ trigger: 'showHospitalNameInSummary' }],
            prior: true,
            ...pullFieldValue(['XFDS99H8.18'], true),
            printFieldWidth: 300
          },
          {
            label: 'State License Number',
            name: 'hosFacHosLsncNo',
            type: 'freeText',
            col: 3,
            prior: true,
            ...pullFieldValue(['XFDS99H8.19'], true),
            printFieldWidth: 250
          },
          {
            label: 'Street Address',
            name: 'hosFacHosAdd',
            type: 'freeText',
            col: 8,
            logicFunction: [{ trigger: 'showHospitalNameInSummary' }],
            prior: true,
            ...pullFieldValue(['XFDS99H8.1'], true),
            printFieldWidth: 550
          },
          {
            label: 'Country',
            name: 'hospFacHosCntry',
            type: 'select',
            lookup: 'BASIC_DATA_COUNTRIES',
            col: 4,
            logicFunction: [
              { trigger: 'showHospitalStateProv', isDifferentGroup: true },
              { trigger: 'showHospitalNameInSummary' }
            ],
            default: 'US',
            printFieldWidth: 320
          },
          {
            label: 'City',
            name: 'hosFacHosCity',
            type: 'freeText',
            col: 3,
            logicFunction: [{ trigger: 'showHospitalNameInSummary' }],
            prior: true,
            ...pullFieldValue(['XFDS99H8.2'], true),
            printFieldWidth: 240
          },
          {
            label: 'Province/State',
            name: 'hosFacHosState',
            type: 'select',
            lookup: 'BASIC_DATA_STATES',
            col: 6,
            isVisible: true,
            logicFunction: [{ trigger: 'showHospitalNameInSummary' }],
            prior: true,
            ...pullFieldValue(['XFDS99H8.3'], true),
            printFieldWidth: 370
          },
          {
            label: 'Province',
            name: 'hospitalSaleProv',
            type: 'select',
            lookup: 'BASIC_DATA_PROVINCES',
            col: 6,
            isVisible: false,
            logicFunction: [{ trigger: 'showHospitalNameInSummary' }],
            printFieldWidth: 370
          },
          {
            label: 'Province',
            name: 'hospitalOtherProv',
            type: 'freeText',
            col: 6,
            isVisible: false,
            logicFunction: [{ trigger: 'showHospitalNameInSummary' }],
            printFieldWidth: 370
          },
          {
            label: 'Zip/Postal Code',
            name: 'hosFacHosZip',
            type: 'zippostal',
            col: 3,
            prior: true,
            ...pullFieldValue(['XFDS99H8.4'], true),
            printFieldWidth: 250
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label: 'Hospital Type Details',
            name: 'hospitalTypeLabel',
            type: 'label',
            col: 10,
            class: 'labelQuestionTitle'
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        fields: [
          {
            label: 'Please select all that apply:',
            name: 'hospitalTypeCheckboxLabel',
            type: 'label',
            col: 10
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Licensed Hospital',
            name: 'hosFacHosType1Label',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosFacHosType1',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H8.7'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'General Medical & Surgical',
            name: 'hosFacHosType2Label',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosFacHosType2',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H8.8'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: "Children's Hospital",
            name: 'hosFacHosType3Label',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosFacHosType3',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H8.9'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Teaching Hospital',
            name: 'hosFacHosType4Label',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosFacHosType4',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H8.10'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 8,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Critical Access Hospital',
            name: 'hosFacHosType5Label',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosFacHosType5',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H8.11'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 9,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Research Facility',
            name: 'hosFacHosType6Label',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosFacHosType6',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H8.12'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'ER-24 Hours',
            name: 'hosFacHosType7Label',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosFacHosType7',
            col: 2,
            class: 'singleCheckbox',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H8.13'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 11,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'ER-Other',
            name: 'hosFacHosType8Label',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'hosFacHosType8',
            col: 2,
            class: 'singleCheckbox',
            logicFunction: [
              { trigger: 'showhosFacHosType8Desc', isDifferentGroup: true }
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H8.14'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 12,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Describe Other (if applicable)',
            name: 'hosFacHosType8DescLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Describe Other',
            placeHolder: 'Please enter your description here',
            name: 'hosFacHosType8Desc',
            type: 'textarea',
            class: 'fieldTextArea',
            maxLength: 900,
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS99H8.15'], true)
          }
        ]
      },
      {
        groupId: 13,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide Hospital Grouping',
            name: 'hosFacHosGrpLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Select',
            name: 'hosFacHosGrp',
            type: 'select',
            lookup: 'HOSPITAL_GROUPING',
            col: 2,
            prior: true,
            ...pullFieldValue(['XFDS99H8.17'], true)
          }
        ]
      }
    ]
  }
];
export const NonhospitalLineSections = [
  {
    title: 'Non-Hospital Facilities',
    isVisible: true,
    sectionId: 1,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Non-Hospital Facilities Name',
            name: 'hosFacNonHosName',
            placeholder: 'Non-Hospital Facilities Name',
            type: 'freeText',
            col: 12,
            logicFunction: [{ trigger: 'showNonHospitalNameInSummary' }],
            prior: true,
            ...pullFieldValue(['XFDS9H19.0'], true),
            printFieldWidth: 900
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Facility Type',
            name: 'hosFacNonHosType',
            placeholder: 'Facility Type',
            type: 'freeText',
            col: 12,
            logicFunction: [{ trigger: 'showNonHospitalNameInSummary' }],
            ...pullFieldValue(['XFDS9H19.15'], true),
            printFieldWidth: 900
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Street Address',
            name: 'hosFacNonHosAdd',
            placeholder: 'Address',
            type: 'freeText',
            col: 9,
            prior: true,
            ...pullFieldValue(['XFDS9H19.1'], true),
            printFieldWidth: 500
          },
          {
            label: 'Country',
            name: 'hospFacNonHosCntry',
            placeholder: 'Country',
            type: 'select',
            lookup: 'BASIC_DATA_COUNTRIES',
            col: 3,
            logicFunction: [
              { trigger: 'showNonHospitalStateProv', isDifferentGroup: true },
              { trigger: 'showNonHospitalNameInSummary' }
            ],
            default: 'US',
            printFieldWidth: 300
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'City',
            name: 'hosFacNonHosCity',
            placeholder: 'City',
            type: 'freeText',
            col: 3,
            logicFunction: [{ trigger: 'showNonHospitalNameInSummary' }],
            prior: true,
            ...pullFieldValue(['XFDS9H19.2'], true),
            printFieldWidth: 240
          },
          {
            label: 'Province / State',
            name: 'hosFacNonHosState',
            placeholder: 'Province / State',
            type: 'select',
            lookup: 'BASIC_DATA_STATES',
            col: 6,
            logicFunction: [{ trigger: 'showNonHospitalNameInSummary' }],
            prior: true,
            ...pullFieldValue(['XFDS9H19.3'], true),
            printFieldWidth: 230
          },
          {
            label: 'Province',
            name: 'hospitalNonSaleProv',
            type: 'select',
            lookup: 'BASIC_DATA_PROVINCES',
            col: 6,
            isVisible: false,
            logicFunction: [{ trigger: 'showNonHospitalNameInSummary' }],
            printFieldWidth: 230
          },
          {
            label: 'Province',
            name: 'hospitalNonOtherProv',
            type: 'freeText',
            col: 6,
            isVisible: false,
            logicFunction: [{ trigger: 'showNonHospitalNameInSummary' }],
            printFieldWidth: 230
          },
          {
            label: 'Zip / Postal Code',
            name: 'hosFacNonHosZip',
            placeholder: 'Zip / Postal Code',
            type: 'zippostal',
            col: 3,
            logicFunction: [{ trigger: 'showNonHospitalNameInSummary' }],
            prior: true,
            ...pullFieldValue(['XFDS9H19.4'], true),
            printFieldWidth: 200
          }
        ]
      }
    ]
  }
];
export const sections = [
  {
    title: 'General Information',
    isVisible: true,
    sectionId: 1,
    hasDenseRow: true,
    subSections: [
      { subSectionId: 2 },
      { subSectionId: 3 },
      { subSectionId: 4 },
      { subSectionId: 5 },
      { subSectionId: 6 }
    ],
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'Please provide your General Information details below.',
            name: 'hosGenInfoLabel1',
            type: 'label',
            col: 10
          }
        ]
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization operate at least one hospital facility (i.e., one that is required to be licensed, registered, or similarly recognized by a state as a hospital) at any time during the tax year?',
            name: 'reqSchdChkHaLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'reqSchdChkHa',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did you calculate the community benefit amounts to report in Schedule H, Part I, Line 7?',
            name: 'hosCbPrepRptLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosGenCbPrepRpt',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showHosCbAttachRptUpld', isDifferentGroup: true },
              {
                trigger: 'showCommunityBenefitReports',
                isDifferentGroup: true
              },
              { trigger: 'showHosCbCharOffTaxAmt', isDifferentGroup: true },
              {
                trigger: 'showHosCbCharCarePoolRevRcv',
                isDifferentGroup: true
              },
              {
                trigger: 'show501rPoliciesPracticesSec',
                isDifferentGroup: true
              },
              //Medicaid Care triggers
              { trigger: 'showhosCbMaProvTaxAmt', isDifferentGroup: true },
              { trigger: 'showhosCbMaNetPatRevAmtt', isDifferentGroup: true },
              { trigger: 'showhosCbMaCarePoolPayAmt', isDifferentGroup: true },
              { trigger: 'showhosCbMaOthRevAmt', isDifferentGroup: true },
              //Other Means - Tested Gov Programs
              { trigger: 'showHosCbOthMnsTaxAmt', isDifferentGroup: true },
              {
                trigger: 'showHosCbOthMnsNetPatRevAmt',
                isDifferentGroup: true
              },
              {
                trigger: 'showHosCbOthMnsCarePoolPayAmt',
                isDifferentGroup: true
              },
              { trigger: 'showHosCbOthMnsOthRevAmt', isDifferentGroup: true },
              //Comm Health Imprv/Benefit Ops
              {
                trigger: 'showHosCbComHlthSubPrgrmExp',
                isDifferentGroup: true
              },
              {
                trigger: 'showHosCbComHlthNdsAssessExp',
                isDifferentGroup: true
              },
              { trigger: 'showHosCbComHlthComBnftExp', isDifferentGroup: true },
              { trigger: 'showHosCbComHlthFundExp', isDifferentGroup: true },
              //Health Professions Ed
              { trigger: 'showHosCbProExpenses', isDifferentGroup: true },
              //Subsidized Hlth Svcs
              { trigger: 'showhosCbSubLoss', isDifferentGroup: true },
              // Medical Research
              { trigger: 'showhosCbRsrchFund', isDifferentGroup: true },
              //Cash/In-Kind Contrib
              { trigger: 'showhosCbInKindCashCont', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 3,
        isVisible: false,
        // hasDivider: true,
        fields: [
          {
            label:
              'Please complete the Community Benefits section below. Provide your supporting work papers for your calculations.',
            name: 'hosGenInfoLabel2',
            type: 'label',
            col: 10
          }
        ]
      },

      {
        groupId: 9,
        isVisible: false,
        hasDivider: true,
        groupType: 'lineItem',
        uploadType: 'drawer',
        class: 'householdEmploymentUpload',
        fields: [
          {
            label: 'Please upload any relevant documents for this section.',
            name: 'hosCbAttachRptUpldLabel',
            type: 'label',
            col: 9
          },
          { type: 'lineButtons', col: 3 }
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [],
          footerLabels: [],
          lineButtons: [
            {
              button: 'uploadOnly',
              uploadSectionName: 'hosCbAttachRptUpld',
              viewSectionId: 0
            }
          ]
        }
      },
      {
        groupId: 4,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              '	Please answer the following questions, in each section below, to assist us in determining your Community Benefit calculation.',
            name: 'hosGenInfoLabel3',
            type: 'label',
            col: 10
          }
        ]
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Do you want to report any community building activities?',
            name: 'hosGenInfoBldgActLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosGenInfoBldgAct',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showCommunityBuildingAct'
          }
        ]
      }
    ]
  },
  {
    title: 'Hospital Information Statement',
    isVisible: false,
    sectionId: 2,
    hasDenseRow: true,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            htmlLabel: (
              <React.Fragment key={'hospital-info-statement'}>
                The organization must file a single form that aggregates
                information for the following:
                <ul key={'hospital-info-statementOne'} className="numList">
                  <li
                    key={'hospital-info-statement-1'}
                    className="alphaListItem">
                    Hospitals directly operated by the organization
                  </li>
                  <li
                    key={'hospital-info-statement-2'}
                    className="alphaListItem">
                    Hospitals operated by disregarded entities of which the
                    organization is the sole member
                  </li>
                  <li
                    key={'hospital-info-statement-3'}
                    className="alphaListItem">
                    Other facilities or programs of the organization or any of
                    the entities described in a) or b), even if provided by a
                    facility that is not a hospital or if provided separately
                    from the hospital's license
                  </li>
                  <li
                    key={'hospital-info-statement-4'}
                    className="alphaListItem">
                    Hospitals operated by any joint venture treated as a
                    partnership, to the extent of the organization's
                    proportionate share of the joint venture. Proportionate
                    share is defined as the ending capital account percentage
                    listed on the Schedule K-1 (Form 1065). If Schedule K-1 is
                    not available, the organization may use other business
                    records to make a reasonable estimate, including the most
                    recently available Schedule K-1, adjusted as appropriate to
                    reflect facts known to the organization, or information used
                    for purposes of determining its proportionate share of the
                    venture for the organization's financial statements.
                  </li>
                </ul>
              </React.Fragment>
            ),
            name: 'hosInfoSheetOne',
            type: 'label',
            col: 12
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            htmlLabel: (
              <React.Fragment key={'hospital-info-statement'}>
                Except as required under Management Companies and Joint
                Ventures, the organization is not to report information from an
                entity organized as a separate legal entity from the
                organization and treated as a corporation for federal income tax
                purposes, even if such entity is affiliated with or otherwise
                related to the organization.
              </React.Fragment>
            ),
            name: 'hosInfoSheetTwo',
            type: 'label',
            col: 12
          }
        ]
      }
    ]
  },
  {
    title: 'Financial Asst. & Other Community Benefits',
    isVisible: false,
    sectionId: 3,
    hasDenseRow: true,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization have a written Financial Assistance Policy ("FAP") during the tax year?',
            name: 'hosFaPolWriteLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosFaPolWrite',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showHosFaPolAppDsc',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H1.1'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              "Indicate which of the following best describes application of the FAP to the organization's hospital facilities?",
            name: 'hosFaPolAppDscLabel',
            type: 'label',
            col: 8
          },
          {
            label: '',
            name: 'hosFaPolAppDsc',
            type: 'select',
            lookup: 'HOSPITAL_FAP_FACILITIES',
            col: 4,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H1.2', 'XFDS99H1.4', 'XFDS99H1.3'],
                fn: 'hosFaPolAppDscPull'
              }
            }
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization use Federal Poverty Guidelines (FPG) to determine eligibility for providing free care under its FAP?',
            name: 'hosFaUseFPGLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosFaUseFPG',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showHosFaIncmLmtFree',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H1.5'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 4,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'What was the family income limit for eligibility for free care at the end of the tax year?',
            name: 'hosFaIncmLmtFreeLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosFaIncmLmtFree',
            type: 'select',
            lookup: 'HOSPITAL_FAP_FREE_CARE',
            col: 2,
            triggered: 'showhosFaIncmLmtFreeOth',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H1.6'],
                fn: 'hosFaIncmLmtFreePull'
              }
            }
          }
        ]
      },
      {
        groupId: 17,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Enter the other percentage',
            name: 'hosFaIncmLmtFreeOthLabel',
            type: 'label',
            col: 10
          },
          { label: '', name: 'hosFaIncmLmtFreeOth', type: 'percent', col: 2 }
        ]
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization use FPG to determine eligibility for providing discounted care under its FAP?',
            name: 'hosFaDiscFPGLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosFaDiscFPG',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showHosFaIncmLmtDisc',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H1.8'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 6,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'What was the family income limit for eligibility for discounted care at the end of the tax year?',
            name: 'hosFaIncmLmtDiscLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosFaIncmLmtDisc',
            type: 'select',
            lookup: 'HOSPITAL_FAP_DISCOUNT_CARE',
            col: 2,
            triggered: 'showHosFaIncmLmtDiscOth',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H1.9'],
                fn: 'hosFaIncmLmtDiscPull'
              }
            }
          }
        ]
      },
      {
        groupId: 18,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Enter the other percentage',
            name: 'hosFaIncmLmtDiscOthLabel',
            type: 'label',
            col: 10
          },
          { label: '', name: 'hosFaIncmLmtDiscOth', type: 'percent', col: 2 }
        ]
      },
      {
        groupId: 7,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Based on your financial assistance policy, describe all criteria used to determine eligibility for financial assistance under the FAP at the end of the year.',
            name: 'hosFaEligCritLabel',
            type: 'label',
            col: 12,
            class: 'labelTitleTextArea'
          },
          {
            label: 'FAP description',
            placeHolder: 'Please enter your description here',
            name: 'hosFaEligCrit',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS99H1.18'], true)
          }
        ]
      },
      {
        groupId: 8,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: `Did the organization's FAP provide for free or discounted care to the"medically indigent"?`,
            name: 'hosFaProvMedIndgntLabel',
            class: 'labelSingleCheckbox',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'hosFaProvMedIndgnt',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H1.12'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 9,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'For the tax year, did the organization budget an amount for free or discounted care provided under its FAP?',
            name: 'hosFaBdgtFreeDiscLabel',
            type: 'label',
            col: 10
          },

          {
            label: '',
            name: 'hosFaBdgtFreeDisc',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H1.13'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              "Did the organization's financial assistance expenses exceed the budgeted amount?",
            name: 'hosFaBdgtExceedLabel',
            type: 'label',
            col: 10
          },

          {
            label: '',
            name: 'hosFaBdgtExceed',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H1.14'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 11,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'As a result of budget considerations, was the organization unable to provide free or discounted care to a patient who was eligible for financial assistance?',
            name: 'hosFaProvUnableLabel',
            type: 'label',
            col: 10
          },

          {
            label: '',
            name: 'hosFaProvUnable',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H1.15'],
                fn: 'checkboxToYNPull'
              }
            }
          }
        ]
      },
      {
        groupId: 12,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization prepare a community benefit report during the tax year?',
            name: 'hosFaComBftRptLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosFaComBftRpt',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showHosFaComServDesc',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H1.16'],
                fn: 'YesNoToRadioPull'
              }
            }
          }
        ]
      },
      {
        groupId: 13,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the community(ies) the organization serves. Take into account geographic area, demographic constituents, the number of other hospitals serving the community(ies) and whether one or more federally designated medically underserved areas or populations are present.',
            name: 'hosFaComServDescLabel',
            type: 'label',
            class: 'labelTitleTextArea',
            col: 10
          },
          {
            label: 'Description',
            placeHolder: 'Please enter your description here',
            name: 'hosFaComServDesc',
            type: 'textarea',
            class: 'fieldTextArea',
            maxLength: 900,
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS99H9.2'], true)
          }
        ]
      },
      {
        groupId: 19,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization make the community benefit report available to the public?',
            name: 'hosFaComBftRptPubLabel',
            type: 'label',
            col: 10
          },

          {
            label: '',
            name: 'hosFaComBftRptPub',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2
          }
        ]
      },
      {
        groupId: 20,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Identify all states with which the organization or a related organization files a community benefit report.',
            name: 'hosFaComBftRptStatesLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'hosFaComBftRptStates',
            type: 'multiSelectDropdown',
            lookup: 'SELECTSTATES',
            singleSelect: false,
            placeHolder: 'States',
            showArrow: true,
            showCheckbox: true,
            displayValue: 'name',
            isObject: true,
            disablePreSelectedValues: false,
            col: 3
          }
        ]
      },
      {
        groupId: 14,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Is your organization part of an affiliated health care system?',
            name: 'hosFaAffilSysLabel',
            type: 'label',
            col: 10
          },

          {
            label: '',
            name: 'hosFaAffilSys',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showHosFaAffilSysRole',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H9.5'],
                fn: 'anyValidCheckboxOrFieldToRadioPull'
              }
            }
          }
        ]
      },
      {
        groupId: 15,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the respective roles of your organization and its affiliates in promoting the health of the communities served.',
            name: 'hosFaAffilSysRoleLabel',
            type: 'label',
            col: 10
          },

          {
            label: 'Description',
            placeHolder: 'Please enter your description here',
            name: 'hosFaAffilSysRole',
            type: 'textarea',
            class: 'fieldTextArea',
            maxLength: 900,
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS99H9.5'], true)
          }
        ]
      },
      {
        groupId: 16,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              "Provide any other important information about how the organization's hospitals or other health care facilities further its exempt purpose by promoting the health of the community.",
            name: 'hosFaOthInfoExmptLabel',
            type: 'label',
            col: 10
          },

          {
            label: 'Description',
            placeHolder: 'Please enter your description here',
            name: 'hosFaOthInfoExmpt',
            type: 'textarea',
            class: 'fieldTextArea',
            maxLength: 900,
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS99H9.4'], true)
          }
        ]
      }
    ]
  },
  {
    title: 'Community Building Activities',
    isVisible: false,
    sectionId: 4,
    hasDenseRow: true,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 12,
        isVisible: true,
        fields: [
          {
            label:
              'Do not report any activities that are reportable in community benefits. Include grants to other organizations to conduct an activity described here.',
            name: 'hosCbActLabel1',
            type: 'label',
            col: 10
          }
        ]
      },
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'Activities',
            name: 'activities',
            class: 'formSingleHeaderColumns formSingleHeaderColFirst',
            type: 'label',
            col: 8
          },
          {
            label: 'Total Expense',
            name: 'totalExpense',
            class: 'formSingleHeaderColumns ',
            type: 'label',
            col: 2
          },
          {
            label: 'Direct Offsetting Revenue',
            name: 'directOffsettingRevenue',
            class: 'formSingleHeaderColumns formSingleHeaderColLast',
            type: 'label',
            col: 2
          }
        ]
      },
      {
        groupId: 2,
        hasDivider: true,
        fields: [
          {
            label: 'Physical improvements and housing',
            name: 'hosCbActPhysImpHsLabel1',
            type: 'label',
            col: 8
          },
          {
            label: 'Amount',
            name: 'hosCbActPhysImpHsExp',
            type: 'money',
            placeholder: '$',
            col: 2
          },
          {
            label: 'Revenue',
            name: 'hosCbActPhysImpHsRev',
            type: 'money',
            placeholder: '$',
            col: 2
          }
        ]
      },

      {
        groupId: 3,
        hasDivider: true,
        fields: [
          {
            label: 'Economic Development',
            name: 'hosCbActEcoDvlpLabel1',
            type: 'label',
            col: 8
          },
          {
            label: 'Amount',
            name: 'hosCbActEcoDvlpExp',
            type: 'money',
            placeholder: '$',
            col: 2
          },
          {
            label: 'Revenue',
            name: 'hosCbActEcoDvlpRev',
            type: 'money',
            placeholder: '$',
            col: 2
          }
        ]
      },
      {
        groupId: 4,
        hasDivider: true,
        fields: [
          {
            label: 'Community support',
            name: 'hosCbActCommSprtLabel1',
            type: 'label',
            col: 8
          },
          {
            label: 'Amount',
            name: 'hosCbActCommSprtExp',
            type: 'money',
            placeholder: '$',
            col: 2
          },
          {
            label: 'Revenue',
            name: 'hosCbActCommSprtRev',
            type: 'money',
            placeholder: '$',
            col: 2
          }
        ]
      },
      {
        groupId: 5,
        hasDivider: true,
        fields: [
          {
            label: 'Environmental improvements',
            name: 'hosCbActEnviroImprLabel1',
            type: 'label',
            col: 8
          },
          {
            label: 'Amount',
            name: 'hosCbActEnviroImprExp',
            type: 'money',
            placeholder: '$',
            col: 2
          },
          {
            label: 'Revenue',
            name: 'hosCbActEnviroImprRev',
            type: 'money',
            placeholder: '$',
            col: 2
          }
        ]
      },
      {
        groupId: 6,
        hasDivider: true,
        fields: [
          {
            label: 'Leadership development and training for community members',
            name: 'hosCbActLdrshpDvLabel1',
            type: 'label',
            col: 8
          },
          {
            label: 'Amount',
            name: 'hosCbActLdrshpDvExp',
            type: 'money',
            placeholder: '$',
            col: 2
          },
          {
            label: 'Revenue',
            name: 'hosCbActLdrshpDvRev',
            type: 'money',
            placeholder: '$',
            col: 2
          }
        ]
      },
      {
        groupId: 7,
        hasDivider: true,
        fields: [
          {
            label: 'Coalition building',
            name: 'hosCbActCoaltnBldgLabel1',
            type: 'label',
            col: 8
          },
          {
            label: 'Amount',
            name: 'hosCbActCoaltnBldgExp',
            type: 'money',
            placeholder: '$',
            col: 2
          },
          {
            label: 'Revenue',
            name: 'hosCbActCoaltnBldgRev',
            type: 'money',
            placeholder: '$',
            col: 2
          }
        ]
      },
      {
        groupId: 8,
        hasDivider: true,
        fields: [
          {
            label: 'Community health improvement advocacy',
            name: 'hosCbActCommHlthImprLabel1',
            type: 'label',
            col: 8
          },
          {
            label: 'Amount',
            name: 'hosCbActCommHlthImprExp',
            type: 'money',
            placeholder: '$',
            col: 2
          },
          {
            label: 'Revenue',
            name: 'hosCbActCommHlthImprRev',
            type: 'money',
            placeholder: '$',
            col: 2
          }
        ]
      },
      {
        groupId: 9,
        hasDivider: true,
        fields: [
          {
            label: 'Workforce development',
            name: 'hosCbActWrkfrcDevLabel1',
            type: 'label',
            col: 8
          },
          {
            label: 'Amount',
            name: 'hosCbActWrkfrcDevExp',
            type: 'money',
            placeholder: '$',
            col: 2
          },
          {
            label: 'Revenue',
            name: 'hosCbActWrkfrcDevRev',
            type: 'money',
            placeholder: '$',
            col: 2
          }
        ]
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Other',
            name: 'hosCbActOtherLabel1',
            type: 'label',
            col: 8
          },
          {
            label: 'Amount',
            name: 'hosCbActOtherLabel1Exp',
            type: 'money',
            placeholder: '$',
            col: 2
          },
          {
            label: 'Revenue',
            name: 'hosCbActOtherLabel1Rev',
            type: 'money',
            placeholder: '$',
            col: 2
          }
        ]
      },
      {
        groupId: 11,
        isVisible: true,

        fields: [
          {
            label:
              "Describe the community building activities above and how they promoted the health of the hospital's community during the tax year.",
            name: 'hosCbActDescLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 10
          },
          {
            label: 'Description',
            name: 'hosCbAchosCbActDesc',
            type: 'textarea',
            col: 12,
            class: 'fieldTextArea',
            prior: true,
            ...pullFieldValue(['XFDS99H9.3'], true)
          }
        ]
      }
    ]
  },
  {
    title: 'Bad Debt, Medicare, & Collection Practices',
    isVisible: false,
    sectionId: 5,
    hasDenseRow: true,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Please check all that apply:',
            name: 'hospDbtBadLabel1',
            type: 'label',
            col: 10
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization report bad debt expense in accordance with HFMA Statement No. 15?',
            name: 'hosDbtRptHfma15Label',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosDbtRptHfma15',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H4.0'],
                fn: 'YesNoToRadioPull'
              }
            }
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: `Provide the organization's bad debt expense for the tax year.`,
            name: 'hosDbtBadExpAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosDbtBadExpAmt',
            type: 'money',
            col: 2
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the methodology used to estimate bad debt expense, including how the organization accounted for discounts and payments on patient accounts.',
            name: 'hosDbtBadExpAmtMthdLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'hosDbtBadExpAmtMthd',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS99H4.9'], true)
          }
        ]
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: `Provide an estimate of the amount of the organization's bad debt expense attributable to patients who would likely qualify for the hospital's financial assistance but for whom insufficient information was obtained to determine their eligibility.`,
            name: 'hosDbtBadExpAmtDueFaLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosDbtBadExpAmtDueFa',
            type: 'money',
            col: 2
          }
        ]
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the methodology used to estimate this amount.  If applicable, explain the rationale for including this amount as community benefit.',
            name: 'hosDbtBadExpAmtDueFaMthdLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'hosDbtBadExpAmtDueFaMthd',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS99H4.11'], true)
          }
        ]
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Are you providing the Medicare revenues and costs?',
            name: 'hosDbtPrvdMcRevCostLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosDbtPrvdMcRevCost',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showMedicareRevenueCosts'
          }
        ]
      },
      {
        groupId: 8,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide the total revenue received from Medicare, including disproportionate share hospital (DSH) and indirect medical education (IME) during the tax year.',
            name: 'hosDbtMcRevLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosDbtMcRev',
            type: 'money',
            col: 2
          }
        ]
      },
      {
        groupId: 9,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide the Medicare allowable costs of care relating to this total revenue.',
            name: 'hosDbtMcCostLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosDbtMcCost',
            type: 'money',
            col: 2
          }
        ]
      },
      {
        groupId: 10,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Which of these the costing methodology or sources did you use to determine the total Medicare revenue?',
            name: 'hosDbtMcRevMthdLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'hosDbtMcRevMthd',
            type: 'select',
            lookup: 'HOSPITAL_MEDICAL_REVENUE',
            col: 3,
            prior: true,
            triggered: 'showhosDbtMcRevMthdOther',
            ...pullFieldValue(['XFDS99H5.2'], true)
          }
        ]
      },
      {
        groupId: 11,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Please describe "other":',
            name: 'hosDbtMcRevMthdOtherLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'hosDbtMcRevMthdOther',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12
          }
        ]
      },
      {
        groupId: 12,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe, in detail, the costing methodology or source used to calculate the total Medicare revenue.',
            name: 'hosDbtMcCostMthdDtlLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'hosDbtMcCostMthdDtl',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12
          }
        ]
      },
      {
        groupId: 13,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the extent to which your Medicare shortfall, if any, should be treated as community benefit.',
            name: 'hosDbtTreatCmtyBftLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'hosDbtTreatCmtyBft',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS99H5.3'], true)
          }
        ]
      },
      {
        groupId: 14,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization have a written debt collection policy during the tax year?',
            name: 'hosDbtCollPolWritLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosDbtCollPolWrit',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showWrittenDebtCollectionPolicy',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H6.0'],
                fn: 'YToRadioPull'
              }
            }
          }
        ]
      },
      {
        groupId: 15,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: `Did the organization's collection policy contain provisions on the collection practices to be followed for patients who are known to qualify for financial assistance?`,
            name: 'hosDbtCollPolProvFaLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosDbtCollPolProvFa',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99H6.1'],
                fn: 'checkboxPull'
              }
            }
          }
        ]
      },
      {
        groupId: 16,
        isVisible: false,
        fields: [
          {
            label:
              'Describe the collection practices to be followed for such patients, including whether these practices apply specifically to financial assistance patients or more broadly to other types of patients.',
            name: 'hosDbtCollPolProvFaDescLabel',
            class: 'labelTitleTextArea',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'hosDbtCollPolProvFaDesc',
            type: 'textarea',
            maxLength: 900,
            class: 'fieldTextArea',
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS99H6.2'], true)
          }
        ]
      }
    ]
  },
  {
    title: 'Hospitals & Other Medical Facilities',
    isVisible: false,
    sectionId: 6,
    hasDenseRow: true,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Based on your input below, the number of hospital facilities owned or operated by your organization during the tax year, including those owned through a partnership or LLC is:\n(A hospital is a facility that, at any time during the tax year, was required to be licensed, registered or similarly recognized as a hospital under state law.)',
            name: 'hosFacHosNoLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosFacHosNo',
            type: 'number',
            isReadOnly: true,
            col: 2
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Will any of the hospitals be reporting their 501(r) response as a group? (Multiple hospitals are allowed to report as a group if they will give identical answers to all 501(r) questions.)',
            name: 'hosFacGrpRptLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosFacGrpRpt',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2
          }
        ]
      },
      {
        groupId: 3,
        groupType: 'lineItem',
        // isRestrictAdd: true,
        isVisible: true,
        with990DenseRows: true,
        hasTableManagerTabs: true,
        isH2TabsHidden: true,
        entityPlaceHolder: 'Hospital',
        summaryAllType: 'Hospital',
        entityNameField: 'hosFacHosName',
        isMasterSummaryHidden: true,
        axcessGroup: {
          pull: {
            fromSections: ['Hospital Facility Information - Section A'],
            fieldPrefixes: ['XFDS99H8'],
            fieldIdentifiers: ['XFDS99H8.0', 'XFDS99H8.18', 'XFDS99H8.1'],
          }
        },
        entityIdentifiers: [
          {
            axcessDataType: 'PassDataToChild'
          }
        ],
        fields: [
          {
            label: '',
            name: 'hosFacHosName',
            type: 'label',
            styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
            printCol: 2,
            prior: true,
            pullCalculation: {
                keyIdentifiers: ['XFDS99H8.0'],
                fn: 'anyValidPullMany',
            }
          },
          {
            label: '',
            name: 'hospitalWebsite',
            type: 'label',
            styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
            printCol: 3,
            prior: true,
            pullCalculation: {
                keyIdentifiers: ['XFDS99H8.18'],
                fn: 'anyValidPull',
            }
          },
          {
            label: '',
            name: 'hospitalAddress',
            type: 'label',
            styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
            printCol: 6,
            prior: true,
            pullCalculation: {
                keyIdentifiers: ['XFDS99H8.1'],
                fn: 'anyValidPull',
            }
          },
          { type: 'lineButtons' }
        ],
        lineItems: [],
        entities: [],
        lineSections: hospitalLineSections,
        currentEntity: -1,
        subWorkSheets: [],
        headerLabelsPrintStyles: {
          gridTemplateColumns: '2.5fr 2.5fr 2.25fr .75fr'
        },
        lineItemDetails: {
          headerLabels: [
            {
              label: 'Name',
              col: 3,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '48px' }
            },
            {
              label: 'Website',
              col: 4,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '48px' }
            },
            {
              label: 'Address',
              col: 4,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '48px' }
            },
            { label: 'Actions', col: 1, colTablet: 2, align: 'left' }
          ],
          footerLabels: [
            {
              type: 'buttons',
              col: 12,
              buttonTitle: 'Add Hospital',
              icon: 'add'
            }
          ],
          lineButtons: [
            {
              button: 'remove',
              logicFunction: [
                { trigger: 'removeHospitalSection', sectionFieldLogic: true }
              ]
            }
          ]
        },
        logicFunction: [
          { trigger: 'showNumberOfHospital', triggerAddEntity: true }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'As of the end of the tax year, did the organization own or operate any non-hospital health care facilities?',
            name: 'hosFacNonHcOwnLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosFacNonHcOwn',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showNonHospitalGroup', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 5,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide the following information for all non-hospital health care facilities owned or operated by the organization during the tax year: (Treat each physical address as a single location.)',
            name: 'hosFacNonHosLabel1',
            type: 'label',
            col: 10
          }
        ]
      },
      {
        groupId: 6,
        groupType: 'lineItem',
        // isRestrictAdd: true,
        isVisible: false,
        with990DenseRows: true,
        hasTableManagerTabs: true,
        isH2TabsHidden: true,
        entityPlaceHolder: 'Non-Hospital Facilities',
        summaryAllType: 'Hospital',
        entityNameField: 'hosFacNonHosName',
        isMasterSummaryHidden: true,
        axcessGroup: {
          pull: {
            fromSections: ['Non-Hospital Facility Information - Section D'],
            fieldPrefixes: ['XFDS9H19']
          }
        },
        entityIdentifiers: [
          {
            axcessDataType: 'PassDataToChild'
          }
        ],
        fields: [
          {
            label: '',
            name: 'nonhospitalName',
            type: 'label',
            styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
            prior: true,
            pullCalculation: {
              keyIdentifiers: ['XFDS9H19.0'],
              fn: 'anyValidPull',
            }
          },
          {
            // label: 'Most recently conducted a CHNA:\nNumber of years since last CHNA:',
            label: '',
            name: 'City',
            type: 'label',
            styles: {
              whiteSpace: 'pre-line',
              paddingTop: 0,
              fontSize: '10px',
              paddingLeft: '30px'
            },
            prior: true,
            pullCalculation: {
              keyIdentifiers: ['XFDS9H19.2'],
              fn: 'anyValidPull',
            }
          },
          {
            //label: 'FAP Website: \nFAP Plain Language Summary Website: \nFinancial Assistance Application Website: ',
            label: '',
            name: 'State',
            type: 'label',
            styles: {
              whiteSpace: 'pre-line',
              paddingTop: 0,
              fontSize: '10px',
              paddingLeft: '35px'
            },
            prior: true,
            pullCalculation: {
              keyIdentifiers: ['XFDS9H19.3'],
              fn: 'anyValidPull',
            }
          },
          {
            label: '',
            name: 'Zip',
            type: 'label',
            styles: {
              whiteSpace: 'pre-line',
              paddingTop: 0,
              fontSize: '10px',
              paddingLeft: '35px'
            },
            prior: true,
            pullCalculation: {
              keyIdentifiers: ['XFDS9H19.4'],
              fn: 'anyValidPull',
            }
          },
          {
            label: '',
            name: 'facilityType',
            type: 'label',
            styles: {
              whiteSpace: 'pre-line',
              paddingTop: 0,
              fontSize: '10px',
              paddingLeft: '40px'
            },
            prior: true,
            pullCalculation: {
              keyIdentifiers: ['XFDS9H19.15'],
              fn: 'anyValidPull',
            }
          },
          { type: 'lineButtons' }
        ],
        lineItems: [],
        entities: [],
        lineSections: NonhospitalLineSections,
        currentEntity: -1,
        subWorkSheets: [],
        headerLabelsPrintStyles: {
          gridTemplateColumns: '2fr 1.35fr 1.85fr 1.75fr 1.65fr 1.25fr'
        },
        lineItemDetails: {
          headerLabels: [
            {
              label: 'Name',
              col: 4,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '48px' }
            },
            {
              label: 'City',
              col: 2,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '48px' }
            },
            {
              label: 'State',
              col: 1,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '48px' }
            },
            {
              label: 'Zip',
              col: 1,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '48px' }
            },
            {
              label: 'Facility Type',
              col: 2,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '48px' }
            },
            { label: 'Actions', col: 1, colTablet: 2, align: 'left' }
          ],
          footerLabels: [
            {
              type: 'buttons',
              col: 12,
              buttonTitle: 'Add Non-Hospital Facilities',
              icon: 'add'
            }
          ],
          lineButtons: [{ button: 'remove' }]
        }
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: '	For the following question: ',
            name: 'hosFacHosLastLabel1',
            type: 'label',
            col: 12
          },
          {
            htmlLabel: (
              <React.Fragment key={'non-hospital'}>
                <ul key={'hospital-listOne'} className="discList">
                  <li key={'hospital-list-1'} className="discListItem">
                    Do not report any publicly traded entities.
                  </li>
                  <li key={'hospital-list-2'} className="discListItem">
                    Do not report any entities whose sole income is passive
                    investment income from interest or dividends.
                  </li>
                </ul>
              </React.Fragment>
            ),
            name: 'listOne',
            type: 'label',
            col: 12
          }
        ]
      },
      {
        groupId: 8,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              "During the tax year, did your organization participate in a joint venture, partnership or limited liability company (LLC) in which at least one other partner/member was one of your organization's officers, board members, key employees, employed physicians, or physicians with staff privileges?",
            name: 'hosFacPartJvStaffPrivLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosFacPartJvStaffPriv',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2
          }
        ]
      }
    ]
  },
  {
    title: 'Community Benefit Reports',
    isVisible: false, //change to false later?
    sectionId: 7,
    hasDenseRow: false,
    isTotalDisplay: false,
    subSections: [
      { subSectionId: 8 },
      { subSectionId: 9 },
      { subSectionId: 10 },
      { subSectionId: 11 },
      { subSectionId: 12 },
      { subSectionId: 13 },
      { subSectionId: 14 },
      { subSectionId: 15 }
    ],
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide your Community Benefit details below.',
            name: 'hosCbLabel1',
            type: 'label',
            col: 12
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Please provide your cost-to-charge ratio calculated for the year (from your cost report).',
            subText: 'If pulling numbers directly from Cost Reports and NOT using ratio method, enter 100% here.',
            name: 'hosCbCostChrRatioLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Cost-to-Charge',
            name: 'hosCbCostChrRatio',
            type: 'percent',
            validations: {
              ...Validators.maxValue(
                100,
                'Value cannot be greater than 100%. Please enter a value from 0% to 100%'
              )
            },
            col: 2,
            logicFunction: [
              {
                trigger: 'showHosCbCharCostsCalcSubTtl',
                isDifferentGroup: true
              },
              { trigger: 'showhosCbMaCostsCalcSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Were any of the amounts below calculated using a methodology based on something other than cost-to-charge ratio?',
            name: 'hosCbTblNonCostChrgMthdLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbTblNonCostChrgMthd',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              {
                trigger: 'showHosCbTblNonCostChrgMthdDesc',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 4,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe the other costing methodology(ies) used.  If a cost accounting system was used, indicate whether the system addresses all patient segments.',
            name: 'hosCbTblNonCostChrgMthdDescLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Description',
            name: 'hosCbTblNonCostChrgMthdDesc',
            type: 'textarea',
            maxLength: 9000,
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDS99H2.48'], true)
          }
        ]
      }
    ]
  },
  {
    title: 'Charity Care',
    isVisible: true,
    sectionId: 8,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide the amount of gross patient charges written off under your financial assistance policy',
            name: 'hosCbCharGrossWOLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbCharGrossWO',
            type: 'money',
            col: 2,
            logicFunction: [
              {
                trigger: 'showHosCbCharCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did you pay any Medicaid provider taxes during the year to offset the cost of providing financial assistance?',
            name: 'hosCbCharOffProvTaxLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbCharOffProvTax',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showHosCbCharOffTaxAmt', isDifferentGroup: true },
              {
                trigger: 'showHosCbCharCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 3,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Amount paid to generate Medicaid DSH funds to be used to offset financial assistance.',
            name: 'hosCbCharOffTaxAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbCharOffTaxAmt',
            type: 'money',
            col: 2,
            logicFunction: [
              {
                trigger: 'showHosCbCharCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did you receive any revenue from uncompensated care pools or programs to offset financial assistance costs?',
            name: 'hosCbCharCarePoolRevLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbCharCarePoolRev',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              {
                trigger: 'showHosCbCharCarePoolRevRcv',
                isDifferentGroup: true
              },
              { trigger: 'showHospCbCharOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 5,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Amount of revenue received during the year from uncompensated care pools or programs to offset financial assistance costs.',
            name: 'hosCbCharCarePoolRevRcvLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbCharCarePoolRevRcv',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'showHospCbCharOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: false,
        fields: [
          {
            label: 'Charity care at cost',
            name: 'hosCbCharCalcSubTtlLabel',
            type: 'label',
            col: 7,
            class: 'footerCharityCareCost'
          },
          {
            label: 'Total Benefit Expense \n $0',
            name: 'hospCbCharOffSetSubTtl',
            type: 'label',
            col: 2,
            class: 'footerTotalMoneyBar'
          },
          {
            label: 'Direct Offsetting Revenue \n $0',
            name: 'hosCbCharCostsCalcSubTtl',
            type: 'label',
            col: 3,
            class: 'footerTotalMoneyBar'
          }
        ]
      },
      {
        groupId: 7,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide Charity Care costs calculated for Community Benefit:',
            name: 'hosCbCharCostsCalcLabel',
            type: 'label',
            col: 10
          },
          { label: 'Amount', name: 'hosCbCharCostsCalc', type: 'money', col: 2 }
        ]
      },
      {
        groupId: 8,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Provide Charity Care reimbursement offsetting costs:',
            name: 'hospCbCharOffSetLabel',
            type: 'label',
            col: 10
          },
          { label: 'Amount', name: 'hospCbCharOffSet', type: 'money', col: 2 }
        ]
      }
    ]
  },
  {
    title: 'Medicaid Costs',
    isVisible: true,
    sectionId: 9,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide gross patient charges from Medicaid.',
            name: 'hosCbMaGrsChrgMaLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbMaGrsChrgMa',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'showhosCbMaCostsCalcSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did you pay any Medicaid provider taxes during the year to offset the cost of Medicaid services?',
            name: 'hosCbMaProvTaxLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbMaProvTax',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showhosCbMaProvTaxAmt', isDifferentGroup: true },
              { trigger: 'showhosCbMaCostsCalcSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 3,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Please provide the amount paid to generate Medicaid DSH funds to be used to offset the cost of Medicaid services.',
            name: 'hosCbMaProvTaxAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbMaProvTaxAmt',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'showhosCbMaCostsCalcSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did you receive revenue from net patient revenue from Medicaid?',
            name: 'hosCbMaNetPatRevLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbMaNetPatRev',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showhosCbMaNetPatRevAmtt', isDifferentGroup: true },
              { trigger: 'showhosCbMaOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 5,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide the revenue amount.',
            name: 'hosCbMaNetPatRevAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbMaNetPatRevAmt',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'showhosCbMaOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did you receive revenue from payments from uncompensated care pools to offset Medicaid costs?',
            name: 'hosCbMaCarePoolPayLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbMaCarePoolPay',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showhosCbMaCarePoolPayAmt', isDifferentGroup: true },
              { trigger: 'showhosCbMaOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 7,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide the revenue amount.',
            name: 'hosCbMaCarePoolPayAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbMaCarePoolPayAmt',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'showhosCbMaOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 8,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did you receive revenue from other revenue to offset Medicaid costs?',
            name: 'hosCbMaOthRevLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbMaOthRev',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showhosCbMaOthRevAmt', isDifferentGroup: true },
              { trigger: 'showhosCbMaOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 9,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide the revenue amount.',
            name: 'hosCbMaOthRevAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbMaOthRevAmt',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'showhosCbMaOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: false,
        fields: [
          {
            label: 'Unreimbursed Medicaid Costs',
            name: 'hosCbMaCostsCalcSubTtlLabel',
            type: 'label',
            col: 7,
            class: 'footerCharityCareCost'
          },
          {
            label: 'Total Benefit Expense \n $0',
            name: 'hosCbMaOffSetSubTtl',
            type: 'label',
            col: 2,
            class: 'footerTotalMoneyBar'
          },
          {
            label: 'Direct Offsetting Revenue \n $0',
            name: 'hosCbMaCostsCalcSubTtl',
            type: 'label',
            col: 3,
            class: 'footerTotalMoneyBar'
          }
        ]
      },
      {
        groupId: 11,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Provide Medicaid costs calculated for Community Benefit:',
            name: 'hosCbMaCostsCalcLabel',
            type: 'label',
            col: 10
          },
          { label: 'Amount', name: 'hosCbMaCostsCalc', type: 'money', col: 2 }
        ]
      },
      {
        groupId: 12,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Provide Medicaid reimbursement offsetting costs:',
            name: 'hosCbMaOffSetLabel',
            type: 'label',
            col: 10
          },
          { label: 'Amount', name: 'hosCbMaOffSet', type: 'money', col: 2 }
        ]
      }
    ]
  },
  {
    title: 'Other Means - Tested Gov Programs',
    isVisible: true,
    sectionId: 10,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide gross patient charges from other means-tested government programs.',
            name: 'hosCbOthMnsGrsChrgLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbOthMnsGrsChrg',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'showHosCbOthMnsOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did you pay any Medicaid provider taxes during the year to offset the cost of other means-tested government programs?',
            name: 'hosCbOthMnsTaxLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbOthMnsTax',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showHosCbOthMnsTaxAmt', isDifferentGroup: true },
              { trigger: 'showHosCbOthMnsOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 3,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide the amount paid to generate Medicaid DSH funds to be used to offset the cost of other means-tested government programs.',
            name: 'hosCbOthMnsTaxAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbOthMnsTaxAmt',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'showHosCbOthMnsOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did you receive revenue from Net patient revenue from Other means-tested programs?',
            name: 'hosCbOthMnsNetPatRevLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbOthMnsNetPatRev',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              {
                trigger: 'showHosCbOthMnsNetPatRevAmt',
                isDifferentGroup: true
              },
              {
                trigger: 'calculateHosCbOthMnsCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 5,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide the revenue amount.',
            name: 'hosCbOthMnsNetPatRevAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbOthMnsNetPatRevAmt',
            type: 'money',
            col: 2,
            logicFunction: [
              {
                trigger: 'calculateHosCbOthMnsCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did you receive revenue from payments from uncompensated care pools to offset Other-means test program costs?',
            name: 'hosCbOthMnsCarePoolPayLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbOthMnsCarePoolPay',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              {
                trigger: 'showHosCbOthMnsCarePoolPayAmt',
                isDifferentGroup: true
              },
              {
                trigger: 'calculateHosCbOthMnsCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 7,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide the revenue amount.',
            name: 'hosCbOthMnsNetPatRevAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbOthMnsCarePoolPayAmt',
            type: 'money',
            col: 2,
            logicFunction: [
              {
                trigger: 'calculateHosCbOthMnsCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 8,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did you receive revenue from other revenue to offset Other-means tested program costs?',
            name: 'hosCbOthMnsOthRevLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbOthMnsOthRev',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showHosCbOthMnsOthRevAmt', isDifferentGroup: true },
              {
                trigger: 'calculateHosCbOthMnsCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 9,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide the revenue amount.',
            name: 'hosCbOthMnsOthRevAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbOthMnsOthRevAmt',
            type: 'money',
            col: 2,
            logicFunction: [
              {
                trigger: 'calculateHosCbOthMnsCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: false,
        fields: [
          {
            label:
              'Unreimbursed costs of other means tested-government programs',
            name: 'hosCbOthMnsSubTtlLabel',
            type: 'label',
            col: 7,
            class: 'footerCharityCareCost'
          },
          {
            label: 'Total Benefit Expense\n $0',
            name: 'hosCbOthMnsOffSetSubTtl',
            type: 'label',
            col: 2,
            class: 'footerTotalMoneyBar'
          },
          {
            label: 'Direct Offsetting Revenue \n $0',
            name: 'hosCbOthMnsCostsCalcSubTtl',
            type: 'label',
            col: 3,
            class: 'footerTotalMoneyBar'
          }
        ]
      },
      {
        groupId: 11,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide Other Means - Tested Gov Programs costs calculated for Community Benefit:',
            name: 'hosCbOthMnsCostsCalcLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbOthMnsCostsCalc',
            type: 'money',
            col: 2
          }
        ]
      },
      {
        groupId: 12,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide Other Means - Tested Gov Programs reimbursement offsetting costs:',
            name: 'hosCbOthMnsOffSetLabel',
            type: 'label',
            col: 10
          },
          { label: 'Amount', name: 'hosCbOthMnsOffSet', type: 'money', col: 2 }
        ]
      }
    ]
  },
  {
    title: 'Comm Health Imprv/Benefit Ops',
    isVisible: true,
    sectionId: 11,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization subsidize any programs for the express purpose of improving community health?',
            name: 'hosCbComHlthSubPrgrmLabel',
            type: 'label',
            col: 10
          },

          {
            label: '',
            name: 'hosCbComHlthSubPrgrm',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showHosCbComHlthSubPrgrmExp'
          }
        ]
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide the amount of subsidized expenses:',
            name: 'hosCbComHlthSubPrgrmExpLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbComHlthSubPrgrmExp',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'totalBenefitExpenseCal', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 3,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Please provide the amount of revenue received to offset the subsidized program expenses:',
            name: 'hosCbComHlthSubPrgrmRevLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbComHlthSubPrgrmRev',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'totalBenefitExpenseCal', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization conduct activities associated with conducting a community health needs assessment?',
            name: 'hosCbComHlthNdsAssessLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbComHlthNdsAssess',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showHosCbComHlthNdsAssessExp'
          }
        ]
      },
      {
        groupId: 5,
        isVisible: false,
        hasDivider: false,
        fields: [
          {
            label: 'Please provide the amount:',
            name: 'hosCbComHlthNdsAssessExpLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbComHlthNdsAssessExp',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'totalBenefitExpenseCal', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 6,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Please provide the amount received to offset the activities:',
            name: 'hosCbComHlthNdsAssessRevLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbComHlthNdsAssessRev',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'totalBenefitExpenseCal', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization conduct community benefit program administration?',
            name: 'hosCbComHlthComBnftLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbComHlthComBnft',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showHosCbComHlthComBnftExp'
          }
        ]
      },
      {
        groupId: 8,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide the amount:',
            name: 'hosCbComHlthComBnftExpLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbComHlthComBnftExp',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'totalBenefitExpenseCal', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 9,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Please provide the amount received to offset the activities:',
            name: 'hosCbComHlthComBnftAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbComHlthComBnftAmt',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'totalBenefitExpenseCal', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization conduct activities associated with fundraising or grant-writing for community benefit programs?',
            name: 'hosCbComHlthFundLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbComHlthFund',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showHosCbComHlthFundExp'
          }
        ]
      },
      {
        groupId: 11,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide the amount:',
            name: 'hosCbComHlthFundExpLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbComHlthFundExp',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'totalBenefitExpenseCal', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 12,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Please provide the amount received to offset the activities:',
            name: 'hosCbComHlthFundAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbComHlthFundAmt',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'totalBenefitExpenseCal', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 13,
        isVisible: true,
        hasDivider: false,
        fields: [
          {
            label:
              'Comunity health improvement services and community benefit operations',
            name: 'hosCbComHlthSubTtlLabel',
            type: 'label',
            col: 7,
            class: 'footerCharityCareCost'
          },
          {
            label: 'Total Benefit Expense \n $0',
            name: 'hosCbComHlthCostsCalcSubTtl',
            type: 'label',
            col: 2,
            class: 'footerTotalMoneyBar'
          },
          {
            label: 'Direct Offsetting Revenue \n $0',
            name: 'hosCbComHlthOffSetSubTtl',
            type: 'label',
            col: 3,
            class: 'footerTotalMoneyBar'
          }
        ]
      },
      {
        groupId: 14,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide Comm Health Imprv/Benefit Ops costs calculated for Community Benefit:',
            name: 'hosCbComHlthCostsCalcLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbComHlthCostsCalc',
            type: 'money',
            col: 2
          }
        ]
      },
      {
        groupId: 15,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide Comm Health Imprv/Benefit Ops reimbursement offsetting costs:',
            name: 'hosCbComHlthOffSetLabel',
            type: 'label',
            col: 10
          },
          { label: 'Amount', name: 'hosCbComHlthOffSet', type: 'money', col: 2 }
        ]
      }
    ]
  },
  {
    title: 'Health Professions Ed',
    isVisible: true,
    sectionId: 12,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization incur any expenses related to health professions education?',
            name: 'hosCbProEdExpLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbProEdExp',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showHosCbProExpenses', isDifferentGroup: true },
              { trigger: 'showHosCbProEdOffSetSubTtl', isDifferentGroup: true },
              {
                trigger: 'calculateHosCbProEdCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Expenses incurred related to health professions education:',
            name: 'hosCbProEdExpEdLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbProEdExpEd',
            type: 'money',
            col: 2,
            logicFunction: [
              { trigger: 'showHosCbProEdOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 3,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Medicare reimbursement for direct Graduate Medical Education:',
            name: 'hosCbProEdMcGradExpLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbProEdMcGradExp',
            type: 'money',
            col: 2,
            logicFunction: [
              {
                trigger: 'calculateHosCbProEdCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 4,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Medicaid reimbursement for direct Graduate Medical Education:',
            name: 'hosCbProEdMaGradExpLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbProEdMaGradExp',
            type: 'money',
            col: 2,
            logicFunction: [
              {
                trigger: 'calculateHosCbProEdCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 5,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Continuing health professions education reimbursement/tuition:',
            name: 'hosCbProEdContExpLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbProEdContExp',
            type: 'money',
            col: 2,
            logicFunction: [
              {
                trigger: 'calculateHosCbProEdCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 6,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Other revenue received to offset health professions education expenses incurred:',
            name: 'hosCbProEdRevOffsetLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbProEdRevOffset',
            type: 'money',
            col: 2,
            logicFunction: [
              {
                trigger: 'calculateHosCbProEdCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: false,
        fields: [
          {
            label: 'Health professions education',
            name: 'hosCbProEdSubTtlLabel',
            type: 'label',
            col: 7,
            class: 'footerCharityCareCost'
          },
          {
            label: 'Total Benefit Expense \n $0',
            name: 'hosCbProEdOffSetSubTtl',
            type: 'label',
            col: 2,
            class: 'footerTotalMoneyBar'
          },
          {
            label: 'Direct Offsetting Revenue \n $0',
            name: 'hosCbProEdCostsCalcSubTtl',
            type: 'label',
            col: 3,
            class: 'footerTotalMoneyBar'
          }
        ]
      },
      {
        groupId: 8,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide Health Professions Ed costs calculated for Community Benefit:',
            name: 'hosCbProEdCostsCalcLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbProEdCostsCalc',
            type: 'money',
            col: 2
          }
        ]
      },
      {
        groupId: 9,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide Health Professions Ed reimbursement offsetting costs:',
            name: 'hosCbProEdCharOffSetLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbProEdCharOffSet',
            type: 'money',
            col: 2
          }
        ]
      }
    ]
  },
  {
    title: 'Subsidized Health Services',
    isVisible: true,
    sectionId: 13,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization provide any clinical services, at a financial loss, to meet an identified community need (e.g., neonatal intensive care, addiction recovery, psychiatric units, low-income clinics, home health, other subsidized health services, etc.)?',
            name: 'hosCbSubLossLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbSubLoss',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showhosCbSubLoss', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Amount of expenses incurred (exclude bad debt, Medicaid, other means-tested, and charity care expenses):',
            name: 'hosCbSubIncurExpLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbSubIncurExp',
            type: 'money',
            col: 2,
            isTotal: true,
            logicFunction: [
              { trigger: 'showhosCbSubOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 3,
        isVisible: false,
        fields: [
          {
            label:
              'Amount of revenues generated from the subsidized health services (exclude bad debt collections, as well as Medicaid, other means-tested, and charity care reimbursements):',
            name: 'hosCbSubGenRevLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbSubGenRev',
            type: 'money',
            col: 2,
            isTotal: true,
            logicFunction: [
              { trigger: 'showhosCbSubCostsCalcSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: false,
        fields: [
          {
            label: 'Subsidized health services',
            name: 'hosCbSubSubTtlLabel',
            type: 'label',
            col: 7,
            class: 'footerCharityCareCost'
          },
          {
            label: 'Total Benefit Expense \n $0',
            name: 'hosCbSubOffSetSubTtl',
            type: 'label',
            col: 2,
            class: 'footerTotalMoneyBar'
          },
          {
            label: 'Direct Offsetting Revenue \n $0',
            name: 'hosCbSubCostsCalcSubTtl',
            type: 'label',
            col: 3,
            class: 'footerTotalMoneyBar'
          }
        ]
      },
      {
        groupId: 5,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide Subsidized Hlth Svcs costs calculated for Community Benefit:',
            name: 'hosCbSubCostsCalcLabel',
            type: 'label',
            col: 10
          },
          { label: 'Amount', name: 'hosCbSubCostsCalc', type: 'money', col: 2 }
        ]
      },
      {
        groupId: 6,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide Subsidized Hlth Svcs reimbursement offsetting costs:',
            name: 'hosCbSubCharOffSetLabel',
            type: 'label',
            col: 10
          },
          { label: 'Amount', name: 'hosCbSubCharOffSet', type: 'money', col: 2 }
        ]
      }
    ]
  },
  {
    title: 'Medical Research',
    isVisible: true,
    sectionId: 14,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization fund any medical research? Exclude amounts funded by an individual or a for-profit entity.',
            name: 'hosCbRsrchFundLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbRsrchFund',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showhosCbRsrchFund', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Amount of expenses incurred for medical research (exclude bad debt, Medicaid, other means-tested, and charity care expenses):',
            name: 'hosCbRsrchCostsLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbRsrchCosts',
            type: 'money',
            col: 2,
            isTotal: true,
            logicFunction: [
              {
                trigger: 'showhosCbRsrchCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 3,
        isVisible: false,
        fields: [
          {
            label:
              'Amount of revenue generated from the medical research (exclude bad debt collections, as well as Medicaid, other means-tested, and charity care reimbursements):',
            name: 'hosCbSubGenRevLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbRsrchRev',
            type: 'money',
            col: 2,
            isTotal: true,
            logicFunction: [
              { trigger: 'showhosCbRsrchOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: false,
        fields: [
          {
            label: 'Research',
            name: 'hosCbRsrchSubTtlLabel',
            type: 'label',
            col: 7,
            class: 'footerCharityCareCost'
          },
          {
            label: 'Total Benefit Expense \n $0',
            name: 'hosCbRsrchCostsCalcSubTtl',
            type: 'label',
            col: 2,
            class: 'footerTotalMoneyBar'
          },
          {
            label: 'Direct Offsetting Revenue \n $0',
            name: 'hosCbRsrchOffSetSubTtl',
            type: 'label',
            col: 3,
            class: 'footerTotalMoneyBar'
          }
        ]
      },
      {
        groupId: 5,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide Medical Research costs calculated for Community Benefit:',
            name: 'hosCbRschCostsCalcLabel',
            type: 'label',
            col: 10
          },
          { label: 'Amount', name: 'hosCbRschCostsCalc', type: 'money', col: 2 }
        ]
      },
      {
        groupId: 6,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Provide Medical Research reimbursement offsetting costs:',
            name: 'hosCbRschCharOffSetLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbRschCharOffSet',
            type: 'money',
            col: 2
          }
        ]
      }
    ]
  },
  {
    title: 'Cash/In-Kind Contrib',
    isVisible: true,
    sectionId: 15,
    isSubSection: true,
    isSubSectionExpanded: false,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: false,
        fields: [
          {
            label:
              'Did the organization provide any cash or in-kind contributions for the benefit of the community you serve?',
            name: 'hosCbInKindCashContLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosCbInKindCashCont',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showhosCbInKindCashCont', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Total amount of cash and in-kind contributions provided for the Community’s benefit by the Organization.',
            name: 'hosCbInKindCashContAmtLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbInKindCashContAmt',
            type: 'money',
            col: 2,
            isTotal: true,
            logicFunction: [
              {
                trigger: 'showhosCbInKindCostsCalcSubTtl',
                isDifferentGroup: true
              }
            ]
          }
        ]
      },
      {
        groupId: 3,
        isVisible: false,
        fields: [
          {
            label:
              'Provide any direct offsetting revenue received as a result of those contributions made by the Organization.',
            name: 'hosCbInKindRevOffContLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbInKindRevOffCont',
            type: 'money',
            col: 2,
            isTotal: true,
            logicFunction: [
              { trigger: 'showhosCbInKindOffSetSubTtl', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: false,
        fields: [
          {
            label: 'Cash and In-Kind Contributions for Community Benefit',
            name: 'hosCbInKindSubTtlLabel',
            type: 'label',
            col: 7,
            class: 'footerCharityCareCost'
          },
          {
            label: 'Total Benefit Expense \n $0',
            name: 'hosCbInKindOffSetSubTtl',
            type: 'label',
            col: 2,
            class: 'footerTotalMoneyBar'
          },
          {
            label: 'Direct Offsetting Revenue \n $0',
            name: 'hosCbInKindCostsCalcSubTtl',
            type: 'label',
            col: 3,
            class: 'footerTotalMoneyBar'
          }
        ]
      },
      {
        groupId: 5,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide Cash/In-Kind Contrib costs calculated for Community Benefit:',
            name: 'hosCbInKindCostsCalcLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbInKindCostsCalc',
            type: 'money',
            col: 2
          }
        ]
      },
      {
        groupId: 6,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Provide Cash/In-Kind Contrib reimbursement offsetting costs:',
            name: 'hosCbInKindCharOffSetLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Amount',
            name: 'hosCbInKindCharOffSet',
            type: 'money',
            col: 2
          }
        ]
      }
    ]
  },
  {
    title: '501r - Policies & Practices',
    isVisible: false,
    hasDenseRow: true,
    sectionId: 16,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'Please provide information for each hospital and/or group.',
            name: 'hospPoliciesLabel',
            type: 'label',
            col: 10
          }
        ]
      },
      {
        groupId: 2,
        groupType: 'lineItem',
        isRestrictAdd: true,
        isVisible: true,
        with990DenseRows: true,
        hasTableManagerTabs: true,
        isH2TabsHidden: false,
        entityPlaceHolder: 'Hospital',
        summaryAllType: 'Hospital',
        entityNameField: 'HospitalName',
        isMasterSummaryHidden: true,
        axcessGroup: {
          pull: {
            fromSections: [
                'Hospital Facility Policies and Practices - Sections B & C'
            ],
            fieldPrefixes: [
              'XFDSCHN',
              'XFDS99H8',
              'XFDS9H15',
              'XFDS9H16',
              'XFDS99H9',
              'XFDS9H18'
            ],
            fieldIdentifiers: [ 'XFDS99H8.18', 'XFDS99H8.1', 'XFDSCHN.12', 'XFDSCHN.31', 'XFDSCHN.32', 'XFDSCHN.33', 'XFDS99H8.0', 'XFDS99H9.1' ],
            mergeWithOtherData: [
              {
                section: 'Schedules',
                id: 'Sch H - Hospitals',
                worksheet: 'Schedule H - Supplemental Information',
                key: 'XFDS99H9.1',
                mergeType: MERGE_WITH_OTHER_DATA // Works for when data to merge are fields
              },
              {
                section: 'Schedules',
                id: 'Sch H - Hospitals',
                worksheet: 'Hospital Facility Information - Sections A',
                key: 'XFDS99H8.0',
                mergeType: MERGE_WITH_OTHER_ROW // Works for when data to merge are rows
              }
            ],
          }
        },
        entityIdentifiers: [
          {
            axcessDataType: 'PassDataToChild'
          },
          {
            axcessDataType: 'PassDataToChild'
          },
          {
            axcessDataType: 'PassDataToChild'
          },
          {
            axcessDataType: 'PassDataToChild'
          },
          {
            axcessDataType: 'PassDataToChild'
          }
        ],
        fields: [
          {
            label: '',
            name: 'hospitalName',
            type: 'label',
            styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
            printCol: 2,
            prior: true,
            pullCalculation: {
                keyIdentifiers: ['XFDS99H8.0'],
                fn: 'anyValidPull',
            }
          },
          {
            // label: 'Most recently conducted a CHNA:\nNumber of years since last CHNA:',
            label: '',
            name: 'aboutHospital',
            type: 'label',
            styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
            printCol: 3,
            prior: true,
            pullCalculation: {
                keyIdentifiers: ['XFDSCHN.12'],
                fn: 'aboutHospitalPull',
            }
          },
          {
            //label: 'FAP Website: \nFAP Plain Language Summary Website: \nFinancial Assistance Application Website: ',
            label: '',
            name: 'FAPdetails',
            type: 'label',
            styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
            printCol: 4,
            prior: true,
            pullCalculation: {
                keyIdentifiers: ['XFDS9H15.31','XFDS9H15.32', 'XFDS9H15.33'],
                fn: 'fapDetailsPull',
            }
          }
        ],
        lineItems: [],
        entities: [],
        lineSections: hospitalAndPracticeSection,
        currentEntity: -1,
        subWorkSheets: [],
        headerLabelsPrintStyles: {
          gridTemplateColumns: '1.75fr 1.65fr 1.65fr'
        },
        lineItemDetails: {
          headerLabels: [
            {
              label: 'Name',
              col: 3,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '48px' }
            },
            {
              label: 'About Hospital',
              col: 4,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '48px' }
            },
            {
              label: 'FAP Details',
              col: 4,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '48px' }
            }
          ],
          footerLabels: [],
          lineButtons: []
        }
      }
    ]
  },
  {
    title: 'Additional Information',
    isVisible: true,
    hasDenseRow: true,
    sectionId: 17,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Is there any additional information related to Schedule H to include in your Form 990?',
            name: 'hosFacAddInfoLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosFacAddInfo',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showhosFacAddInfo',
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSHEXP.4'],
                fn: 'anyValidCheckboxOrFieldToRadioPull'
              }
            }
          }
        ]
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Please provide your additional information below.',
            name: 'hosFacAddInfoDescLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Additional Information',
            name: 'hosFacAddInfoDesc',
            type: 'textarea',
            class: 'fieldTextArea',
            placeHolder: 'Please enter your additional information here...',
            maxLength: 900,
            col: 12,
            prior: true,
            ...pullFieldValue(['XFDSHEXP.4'], true)
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        fields: [
          {
            label:
              'Are there any additional documents you would like to provide related to your Schedule H reporting?',
            name: 'hosFacAddDocsLabel',
            type: 'label',
            col: 10
          },
          {
            label: '',
            name: 'hosFacAddDocs',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            triggered: 'showHosFacAddDocsAttach'
          }
        ]
      },
      {
        groupId: 4,
        isVisible: false,
        groupType: 'lineItem',
        uploadType: 'drawer',
        class: 'householdEmploymentUpload',
        fields: [
          {
            label: 'Please upload any relevant documents for this section.',
            name: 'hosFacAddDocsAttachLabel',
            type: 'label',
            col: 9
          },
          { type: 'lineButtons', col: 3 }
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [],
          footerLabels: [],
          lineButtons: [
            {
              button: 'uploadOnly',
              uploadSectionName: 'hosFacAddDocsAttach',
              viewSectionId: 0
            }
          ]
        }
      },
      {
        groupId: 5,
        groupType: 'details',
        fields: [
          { 
            label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
            type: 'label', 
            class: 'labelLeftLight' 
          },
        ]
      },
      {
        groupId: 6,
        fields: [
          {
            label: 'Start typing to provide additional information', 
            name: 'additionalInfoTextArea', 
            type: 'textarea', 
            col: 12,
            maxLength: 9000
          }
        ]
      }
    ]
  }
];

export const getHospitalTitles = () => {
  let titles = [];
  sections.forEach((section) => {
    if (!titles.includes(section.title)) {
      titles.push(section.title);
    }
  });
  return titles;
};

export const getHospitalSections = () => {
  return sections;
};

function Hospital(props) {
  const { isExportForm } = props;
  const { updateCard, REDUX, selectState } = usePageFramework(NAV.HOSPITAL);
  const [sectionState, setSectionState] = useState('');
  const internalSetupForm = selectState(REDUX.INTERNAL_SETUP);
  const {isActiveClient, isLoading} = useLoadOrganizer();

  form.data = internalSetupForm;

  useEffect(() => {
    if (!isExportForm) updateCard();

    setSectionState(sections);
    // eslint-disable-next-line
  }, []);

  const axcessIdentifiers = [
    {
      section: 'Schedules',
      id: 'Sch H - Hospitals'
    }
  ];

  return (
    <>
      {!isLoading && isActiveClient && <FormRendererHelper
        sections={sectionState}
        key="hospitalform"
        formName={REDUX.HOSPITAL}
        identifiers={axcessIdentifiers}
        triggeredEvent={triggeredEvent}
        sectionFieldLogic={sectionFieldLogic}
        isExportForm={isExportForm}
      />}
      {!isLoading && !isActiveClient && <NoActiveClient />}
    </>
  );
}

export default Hospital;
