import * as CARDSTATUS from '@utilities/constants/cardStatus';
import { getClientDetails } from '../organizerExport/organizerRenderer/components/utils/getClientDetails';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { CLA_BLACK_LOGO } from '@utilities/constants/images.js';
import { getCardProp } from '@utilities/helpers/getCardProp';

function ExportIndex(props){
    const { REDUX, selectState } = usePageFramework();
    const year = selectState(REDUX.YEAR) || 2022;
    const taxYearEndDate = selectState(REDUX.TAX_YEAR_END_DATE);
    const isClient = selectState(REDUX.IS_CLIENT);
    let cards = props?.index || [];

    if (isClient) {
        cards = cards?.filter(card => card.formName !== REDUX.INTERNAL_SETUP );
    }

    // Should all forms show or just the filled ones?
    // const filteredCards = props.index.filter(card => card.statusId !== CARDSTATUS.NOT_STARTED || card.isRequired);
    const setFormStatus = (form) => {
        form.statusId = form.statusId === CARDSTATUS.REQUIRED ? CARDSTATUS.NOT_STARTED : form.statusId;
        const cardRequiredText = form.isRequired ? 'Required' : 'Optional';
        return `${cardRequiredText} - ${CARDSTATUS.STATUS_TITLE[form.statusId]}`;
    };

    return (
        <>
            <div className="grid-container" style={{display: 'flex', flexDirection: 'column-reverse', flex: 1}}>
                <div className="grid-child" style={{textAlign: 'center'}}>
                    <h3 style={{textAlign: 'center'}}>{`${year} Index`}</h3>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="grid-child">
                        <img src={CLA_BLACK_LOGO} alt="CLA logo" width="25" height="25"></img>
                    </div>
                    <div className="grid-child clientDetailsContainer" style={{textAlign: 'end', display: 'flex'}}>
                        <span>{getClientDetails().clientName}</span>&nbsp;
                        <span>{getClientDetails().clientNumber}</span>&nbsp;
                        <span>{taxYearEndDate}</span>
                    </div>
                </div>
            </div>
            <div className="tableHeader">
                <h4>Form</h4>
                <h4>Status</h4>
            </div>
            <hr />
            <div className='orgTable'>
                {cards.map((form, index) => (
                    <div key={index}>
                        <div>{getCardProp(form.formName, 'title')}</div>
                        <div>{setFormStatus(form)}</div>
                    </div>
                ))}
            </div>
            <div className='pdfIndexNote'>{'Note: Unless noted otherwise, all data input by client. Prior year data shown in italics.'}</div>
        </>
    )
}

export default ExportIndex;