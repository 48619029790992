const showUbitOtherActDesc = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  
  if (event) {
    name[0].groups[14].isVisible = true;
  } else {
    name[0].groups[14].isVisible = false;
  }
  
  setFormSections(name);
}

const triggeredEvent = (trigger, event, options) => {
  switch (trigger) {
    case 'showUbitOtherActDesc': 
      showUbitOtherActDesc(event, options);
      break;
    default:
      break;
  }
};

const sectionFieldLogic = (logicFunction, options) => {
  // switch (logicFunction) {
  //   default:
  //     break;
  // }
};

export {
    triggeredEvent,
    sectionFieldLogic
};