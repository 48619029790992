import * as STRINGS from './strings';
import * as AXCESS_KEYS from './axcessKeys';

/* eslint-disable linebreak-style */

const YESNO = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Yes', name: 'Yes' },
  { value: 'No', name: 'No' }
];

const YESNO_RADIO = [
  { value: 'No', name: 'No' },
  { value: 'Yes', name: 'Yes' }
];

const TOFROM = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'To', name: 'To' },
  { value: 'From', name: 'From' }
];

const YESNONA = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Yes', name: 'Yes' },
  { value: 'No', name: 'No' },
  { value: 'NA', name: 'N/A' }
];

const STATES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'DC', name: 'District Of Columbia' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'PR', name: 'Puerto Rico' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' }
];
const SELECTSTATES = [
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'DC', name: 'District Of Columbia' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'PR', name: 'Puerto Rico' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' }
];

const SELECTFILINGSTATES = [
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'AA', name: 'Overseas millitary' },
  { value: 'AE', name: 'Overseas millitary' },
  { value: 'AP', name: 'Overseas millitary' },
  { value: 'AS', name: 'American Samoa' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'DC', name: 'District Of Columbia' },
  { value: 'FM', name: 'Fed. States of Micronesia' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'GU', name: 'Guam' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MH', name: 'Marshall Islands' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MP', name: 'Commonwealth of Northern Mariana Islands' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PW', name: 'Palau' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'PR', name: 'Puerto Rico' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
  { value: 'VI', name: 'Virgin Islands' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' }
];

const HOLDER_TYPE_RADIO = [
  { value: 'The organization', name: 'The organization' },
  { value: 'Individual', name: 'Individual' }
];

const FOREIGN_ASSETS_COUNTRIES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'US', name: 'United States' },
  { value: 'CA', name: 'Canada' },
  { value: 'AF', name: 'Afghanistan' },
  { value: 'AX', name: 'Aland Islands' },
  { value: 'AL', name: 'Albania' },
  { value: 'DZ', name: 'Algeria' },
  { value: 'AS', name: 'American Samoa' },
  { value: 'AD', name: 'Andorra' },
  { value: 'AO', name: 'Angola' },
  { value: 'AI', name: 'Anguilla' },
  { value: 'AQ', name: 'Antarctica' },
  { value: 'AG', name: 'Antigua and Barbuda' },
  { value: 'AR', name: 'Argentina' },
  { value: 'AM', name: 'Armenia' },
  { value: 'AW', name: 'Aruba' },
  { value: 'AU', name: 'Australia' },
  { value: 'AT', name: 'Austria' },
  { value: 'AZ', name: 'Azerbaijan' },
  { value: 'BS', name: 'Bahamas' },
  { value: 'BH', name: 'Bahrain' },
  { value: 'BD', name: 'Bangladesh' },
  { value: 'BB', name: 'Barbados' },
  { value: 'BY', name: 'Belarus' },
  { value: 'BE', name: 'Belgium' },
  { value: 'BZ', name: 'Belize' },
  { value: 'BJ', name: 'Benin' },
  { value: 'BM', name: 'Bermuda' },
  { value: 'BT', name: 'Bhutan' },
  { value: 'BO', name: 'Bolivia, Plurinational State of' },
  { value: 'BQ', name: 'Bonaire, Saint Eustatius and Saba' },
  { value: 'BA', name: 'Bosnia and Herzegovina' },
  { value: 'BW', name: 'Botswana' },
  { value: 'BV', name: 'Bouvet Island' },
  { value: 'BR', name: 'Brazil' },
  { value: 'IO', name: 'British Indian Ocean Territory' },
  { value: 'BN', name: 'Brunei Darussalam' },
  { value: 'BG', name: 'Bulgaria' },
  { value: 'BF', name: 'Burkina Faso' },
  { value: 'BI', name: 'Burundi' },
  { value: 'KH', name: 'Cambodia' },
  { value: 'CM', name: 'Cameroon' },
  { value: 'CV', name: 'Cape Verde' },
  { value: 'KY', name: 'Cayman Islands' },
  { value: 'CF', name: 'Central African Republic' },
  { value: 'TD', name: 'Chad' },
  { value: 'CL', name: 'Chile' },
  { value: 'CN', name: 'China' },
  { value: 'CX', name: 'Christmas Island' },
  { value: 'CC', name: 'Cocos (Keeling) Islands' },
  { value: 'CO', name: 'Colombia' },
  { value: 'KM', name: 'Comoros' },
  { value: 'CG', name: 'Congo' },
  { value: 'CD', name: 'Congo (Democratic Republic)' },
  { value: 'CK', name: 'Cook Islands' },
  { value: 'CR', name: 'Costa Rica' },
  { value: 'CI', name: "Cote D'Ivoire" },
  { value: 'HR', name: 'Croatia' },
  { value: 'CU', name: 'Cuba' },
  { value: 'CW', name: 'Curacao' },
  { value: 'CY', name: 'Cyprus' },
  { value: 'CZ', name: 'Czech Republic' },
  { value: 'DK', name: 'Denmark' },
  { value: 'DJ', name: 'Djibouti' },
  { value: 'DM', name: 'Dominica' },
  { value: 'DO', name: 'Dominican Republic' },
  { value: 'EC', name: 'Ecuador' },
  { value: 'EG', name: 'Egypt' },
  { value: 'SV', name: 'El Salvador' },
  { value: 'GQ', name: 'Equatorial Guinea' },
  { value: 'ER', name: 'Eritrea' },
  { value: 'EE', name: 'Estonia' },
  { value: 'ET', name: 'Ethiopia' },
  { value: 'FK', name: 'Falkland Islands (Malvinas)' },
  { value: 'FO', name: 'Faroe Islands' },
  { value: 'FM', name: 'Micronesia, Federated States of' },
  { value: 'FJ', name: 'Fiji' },
  { value: 'FI', name: 'Finland' },
  { value: 'FR', name: 'France' },
  { value: 'GF', name: 'French Guiana' },
  { value: 'PF', name: 'French Polynesia' },
  { value: 'TF', name: 'French Southern Territories' },
  { value: 'GA', name: 'Gabon' },
  { value: 'GM', name: 'Gambia' },
  { value: 'GE', name: 'Georgia' },
  { value: 'DE', name: 'Germany' },
  { value: 'GH', name: 'Ghana' },
  { value: 'GI', name: 'Gibraltar' },
  { value: 'GR', name: 'Greece' },
  { value: 'GL', name: 'Greenland' },
  { value: 'GD', name: 'Grenada' },
  { value: 'GP', name: 'Guadeloupe' },
  { value: 'GU', name: 'Guam' },
  { value: 'GT', name: 'Guatemala' },
  { value: 'GG', name: 'Guernsey' },
  { value: 'GN', name: 'Guinea' },
  { value: 'GW', name: 'Guinea-Bissau' },
  { value: 'GY', name: 'Guyana' },
  { value: 'HT', name: 'Haiti' },
  { value: 'HM', name: 'Heard Island and McDonald Islands' },
  { value: 'VA', name: 'Holy See (Vatican City State)' },
  { value: 'HN', name: 'Honduras' },
  { value: 'HK', name: 'Hong Kong' },
  { value: 'HU', name: 'Hungary' },
  { value: 'IS', name: 'Iceland' },
  { value: 'IN', name: 'India' },
  { value: 'ID', name: 'Indonesia' },
  { value: 'IR', name: 'Iran, Islamic Republic of' },
  { value: 'IQ', name: 'Iraq' },
  { value: 'IE', name: 'Ireland' },
  { value: 'IM', name: 'Isle of Man' },
  { value: 'IL', name: 'Israel' },
  { value: 'IT', name: 'Italy' },
  { value: 'JM', name: 'Jamaica' },
  { value: 'JP', name: 'Japan' },
  { value: 'JE', name: 'Jersey' },
  { value: 'JO', name: 'Jordan' },
  { value: 'KZ', name: 'Kazakhstan' },
  { value: 'KE', name: 'Kenya' },
  { value: 'KI', name: 'Kiribati' },
  { value: 'KP', name: "Korea, Democratic People's Republic of" },
  { value: 'KR', name: 'Korea, Republic of' },
  { value: 'KW', name: 'Kuwait' },
  { value: 'KG', name: 'Kyrgyzstan' },
  { value: 'LA', name: "Laos People's Democratic Republic" },
  { value: 'LV', name: 'Latvia' },
  { value: 'LB', name: 'Lebanon' },
  { value: 'LS', name: 'Lesotho' },
  { value: 'LR', name: 'Liberia' },
  { value: 'LY', name: 'Libya' },
  { value: 'LI', name: 'Liechtenstein' },
  { value: 'LT', name: 'Lithuania' },
  { value: 'LU', name: 'Luxembourg' },
  { value: 'MO', name: 'Macao' },
  { value: 'MK', name: 'Macedonia, The Former Yugoslav Republic of' },
  { value: 'MG', name: 'Madagascar' },
  { value: 'MW', name: 'Malawi' },
  { value: 'MY', name: 'Malaysia' },
  { value: 'MV', name: 'Maldives' },
  { value: 'ML', name: 'Mali' },
  { value: 'MT', name: 'Malta' },
  { value: 'MH', name: 'Marshall Islands' },
  { value: 'MQ', name: 'Martinique' },
  { value: 'MR', name: 'Mauritania' },
  { value: 'MU', name: 'Mauritius' },
  { value: 'YT', name: 'Mayotte' },
  { value: 'MX', name: 'Mexico' },
  { value: 'MD', name: 'Moldova, Republic of' },
  { value: 'MC', name: 'Monaco' },
  { value: 'MN', name: 'Mongolia' },
  { value: 'ME', name: 'Montenegro' },
  { value: 'MS', name: 'Montserrat' },
  { value: 'MA', name: 'Morocco' },
  { value: 'MZ', name: 'Mozambique' },
  { value: 'MM', name: 'Myanmar' },
  { value: 'NA', name: 'Namibia' },
  { value: 'NR', name: 'Nauru' },
  { value: 'NP', name: 'Nepal' },
  { value: 'NL', name: 'Netherlands' },
  { value: 'NC', name: 'New Caledonia' },
  { value: 'NZ', name: 'New Zealand' },
  { value: 'NI', name: 'Nicaragua' },
  { value: 'NE', name: 'Niger' },
  { value: 'NG', name: 'Nigeria' },
  { value: 'NU', name: 'Niue' },
  { value: 'NF', name: 'Norfolk Island' },
  { value: 'MP', name: 'Northern Mariana Islands' },
  { value: 'NO', name: 'Norway' },
  { value: 'OM', name: 'Oman' },
  { value: 'PK', name: 'Pakistan' },
  { value: 'PW', name: 'Palau' },
  { value: 'PS', name: 'Palestine, State of' },
  { value: 'PA', name: 'Panama' },
  { value: 'PG', name: 'Papua-New Guinea' },
  { value: 'PY', name: 'Paraguay' },
  { value: 'PE', name: 'Peru' },
  { value: 'PH', name: 'Philippines' },
  { value: 'PN', name: 'Pitcairn' },
  { value: 'PL', name: 'Poland' },
  { value: 'PT', name: 'Portugal' },
  { value: 'PR', name: 'Puerto Rico' },
  { value: 'QA', name: 'Qatar' },
  { value: 'RE', name: 'Reunion' },
  { value: 'RO', name: 'Romania' },
  { value: 'RU', name: 'Russian Federation' },
  { value: 'RW', name: 'Rwanda' },
  { value: 'BL', name: 'Saint Barthelemy' },
  { value: 'SH', name: 'Saint Helena, Ascension and Tristan Da Cunha' },
  { value: 'KN', name: 'Saint Kitts and Nevis' },
  { value: 'LC', name: 'Saint Lucia' },
  { value: 'MF', name: 'Saint Martin (French Part)' },
  { value: 'PM', name: 'Saint Pierre and Miquelon' },
  { value: 'VC', name: 'Saint Vincent and the Grenadines' },
  { value: 'WS', name: 'Samoa' },
  { value: 'SM', name: 'San Marino' },
  { value: 'ST', name: 'Sao Tome and Principe' },
  { value: 'SA', name: 'Saudi Arabia' },
  { value: 'SN', name: 'Senegal' },
  { value: 'RS', name: 'Serbia' },
  { value: 'SC', name: 'Seychelles' },
  { value: 'SL', name: 'Sierra Leone' },
  { value: 'SG', name: 'Singapore' },
  { value: 'SX', name: 'Saint Maarten (Dutch Part)' },
  { value: 'SK', name: 'Slovakia' },
  { value: 'SI', name: 'Slovenia' },
  { value: 'SB', name: 'Solomon Islands' },
  { value: 'SO', name: 'Somalia' },
  { value: 'ZA', name: 'South Africa' },
  { value: 'GS', name: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', name: 'South Sudan' },
  { value: 'ES', name: 'Spain' },
  { value: 'LK', name: 'Sri Lanka' },
  { value: 'SD', name: 'Sudan' },
  { value: 'SR', name: 'Suriname' },
  { value: 'SJ', name: 'Svalbard and Jan Mayen' },
  { value: 'SZ', name: 'Swaziland' },
  { value: 'SE', name: 'Sweden' },
  { value: 'CH', name: 'Switzerland' },
  { value: 'SY', name: 'Syrian Arab Republic' },
  { value: 'TW', name: 'Taiwan' },
  { value: 'TJ', name: 'Tajikistan' },
  { value: 'TZ', name: 'Tanzania, United Republic of' },
  { value: 'TH', name: 'Thailand' },
  { value: 'TL', name: 'Timor-Leste' },
  { value: 'TG', name: 'Togo' },
  { value: 'TK', name: 'Tokelau' },
  { value: 'TO', name: 'Tonga' },
  { value: 'TT', name: 'Trinidad and Tobago' },
  { value: 'TN', name: 'Tunisia' },
  { value: 'TR', name: 'Turkey' },
  { value: 'TM', name: 'Turkmenistan' },
  { value: 'TC', name: 'Turks and Caicos Islands' },
  { value: 'TV', name: 'Tuvalu' },
  { value: 'UG', name: 'Uganda' },
  { value: 'UA', name: 'Ukraine' },
  { value: 'AE', name: 'United Arab Emirates' },
  { value: 'GB', name: 'United Kingdom' },
  { value: 'UM', name: 'United States Minor Outlying Islands' },
  { value: 'UY', name: 'Uruguay' },
  { value: 'UZ', name: 'Uzbekistan' },
  { value: 'VU', name: 'Vanuatu' },
  { value: 'VE', name: 'Venezuela, Bolivarian Republic of' },
  { value: 'VN', name: 'Viet Nam' },
  { value: 'VG', name: 'Virgin Islands, British' },
  { value: 'VI', name: 'Virgin Islands, U.S.' },
  { value: 'WF', name: 'Wallis and Futuna' },
  { value: 'EH', name: 'Western Sahara' },
  { value: 'YE', name: 'Yemen' },
  { value: 'ZM', name: 'Zambia' },
  { value: 'ZW', name: 'Zimbabwe' }
];

const FOREIGN_ASSETS_DISPOSITION_COUNTRIES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'US', name: 'United States' },
  { value: 'CA', name: 'Canada' },
  { value: 'AF', name: 'Afghanistan' },
  { value: 'AX', name: 'Akrotiri' },
  { value: 'AL', name: 'Albania' },
  { value: 'AG', name: 'Algeria' },
  { value: 'AQ', name: 'American Samoa' },
  { value: 'AN', name: 'Andorra' },
  { value: 'AO', name: 'Angola' },
  { value: 'AV', name: 'Anguilla' },
  { value: 'AY', name: 'Antarctica' },
  { value: 'AC', name: 'Antigua and Barbuda' },
  { value: 'AR', name: 'Argentina' },
  { value: 'AM', name: 'Armenia' },
  { value: 'AA', name: 'Aruba' },
  { value: 'AT', name: 'Ashmore and Cartier' },
  { value: 'AS', name: 'Australia' },
  { value: 'AU', name: 'Austria' },
  { value: 'AJ', name: 'Azerbaijan' },
  { value: 'BF', name: 'Bahamas' },
  { value: 'BA', name: 'Bahrain' },
  { value: 'FQ', name: 'Baker Island' },
  { value: 'BG', name: 'Bangladesh' },
  { value: 'BB', name: 'Barbados' },
  { value: 'BO', name: 'Belarus' },
  { value: 'BE', name: 'Belgium' },
  { value: 'BH', name: 'Belize' },
  { value: 'BN', name: 'Benin' },
  { value: 'BD', name: 'Bermuda' },
  { value: 'BT', name: 'Bhutan' },
  { value: 'BL', name: 'Bolivia' },
  { value: 'BK', name: 'Bosnia and Herzegovina' },
  { value: 'BC', name: 'Botswana' },
  { value: 'BV', name: 'Bouvet Island' },
  { value: 'BR', name: 'Brazil' },
  { value: 'IO', name: 'British Indian Ocean Territory' },
  { value: 'VI', name: 'British Virgin Islands' },
  { value: 'BX', name: 'Brunei Darussalam' },
  { value: 'BU', name: 'Bulgaria' },
  { value: 'UV', name: 'Burkina Faso' },
  { value: 'BM', name: 'Burma' },
  { value: 'BY', name: 'Burundi' },
  { value: 'CB', name: 'Cambodia' },
  { value: 'CM', name: 'Cameroon' },
  { value: 'CV', name: 'Cape Verde' },
  { value: 'CJ', name: 'Cayman Islands' },
  { value: 'CT', name: 'Central African Republic' },
  { value: 'CD', name: 'Chad' },
  { value: 'CI', name: 'Chile' },
  { value: 'CH', name: 'China' },
  { value: 'KT', name: 'Christmas Islands' },
  { value: 'IP', name: 'Clipperton Islands' },
  { value: 'CK', name: 'Cocos (Keeling) Islands' },
  { value: 'CO', name: 'Colombia' },
  { value: 'CN', name: 'Comoros' },
  { value: 'CF', name: 'Congo' },
  { value: 'CG', name: 'Congo (Democratic Republic)' },
  { value: 'CW', name: 'Cook Islands' },
  { value: 'CR', name: 'Coral Sea Islands' },
  { value: 'CS', name: 'Costa Rica' },
  { value: 'IV', name: "Cote D'Ivoire" },
  { value: 'HR', name: 'Croatia' },
  { value: 'CU', name: 'Cuba' },
  { value: 'UC', name: 'Curacao' },
  { value: 'CY', name: 'Cyprus' },
  { value: 'EZ', name: 'Czech Republic' },
  { value: 'DA', name: 'Denmark' },
  { value: 'DX', name: 'Dhekelia' },
  { value: 'DJ', name: 'Djibouti' },
  { value: 'DO', name: 'Dominica' },
  { value: 'DR', name: 'Dominican Republic' },
  { value: 'TT', name: 'East Timor' },
  { value: 'EC', name: 'Ecuador' },
  { value: 'EG', name: 'Egypt' },
  { value: 'ES', name: 'El Salvador' },
  { value: 'EK', name: 'Equatorial Guinea' },
  { value: 'ER', name: 'Eritrea' },
  { value: 'EN', name: 'Estonia' },
  { value: 'ET', name: 'Ethiopia' },
  { value: 'FK', name: 'Falkland Islands (Malvinas)' },
  { value: 'FO', name: 'Faroe Islands' },
  { value: 'FM', name: 'Micronesia, Federated States of Micronesia' },
  { value: 'FJ', name: 'Fiji' },
  { value: 'FI', name: 'Finland' },
  { value: 'FR', name: 'France' },
  { value: 'FP', name: 'French Polynesia' },
  { value: 'FS', name: 'French Southern and Antarctic Lands' },
  { value: 'GB', name: 'Gabon' },
  { value: 'GA', name: 'Gambia' },
  { value: 'GG', name: 'Georgia' },
  { value: 'GM', name: 'Germany' },
  { value: 'GH', name: 'Ghana' },
  { value: 'GI', name: 'Gibraltar' },
  { value: 'GR', name: 'Greece' },
  { value: 'GL', name: 'Greenland' },
  { value: 'GQ', name: 'Guam' },
  { value: 'GT', name: 'Guatemala' },
  { value: 'GK', name: 'Guernsey' },
  { value: 'GV', name: 'Guinea' },
  { value: 'PU', name: 'Guinea-Bissau' },
  { value: 'GY', name: 'Guyana' },
  { value: 'HA', name: 'Haiti' },
  { value: 'HM', name: 'Heard Island and McDonald Islands' },
  { value: 'HO', name: 'Honduras' },
  { value: 'HK', name: 'Hong Kong' },
  { value: 'HQ', name: 'Howland Island' },
  { value: 'HU', name: 'Hungary' },
  { value: 'IC', name: 'Iceland' },
  { value: 'IN', name: 'India' },
  { value: 'ID', name: 'Indonesia' },
  { value: 'IR', name: 'Iran' },
  { value: 'IZ', name: 'Iraq' },
  { value: 'EI', name: 'Ireland' },
  { value: 'IS', name: 'Israel' },
  { value: 'IT', name: 'Italy' },
  { value: 'JM', name: 'Jamaica' },
  { value: 'JM', name: 'Jan Mayen' },
  { value: 'JA', name: 'Japan' },
  { value: 'DQ', name: 'Jarvis Island' },
  { value: 'JE', name: 'Jersey' },
  { value: 'JQ', name: 'Johnston Atoll' },
  { value: 'JO', name: 'Jordan' },
  { value: 'KZ', name: 'Kazakhstan' },
  { value: 'KE', name: 'Kenya' },
  { value: 'KQ', name: 'Kingman Reef' },
  { value: 'KR', name: 'Kiribati' },
  { value: 'KN', name: 'North Korea' },
  { value: 'KS', name: 'South Korea' },
  { value: 'KV', name: 'Kosovo' },
  { value: 'KU', name: 'Kuwait' },
  { value: 'KG', name: 'Kyrgyzstan' },
  { value: 'LA', name: "Laos People's Democratic Republic" },
  { value: 'LV', name: 'Latvia' },
  { value: 'LE', name: 'Lebanon' },
  { value: 'LT', name: 'Lesotho' },
  { value: 'LI', name: 'Liberia' },
  { value: 'LY', name: 'Libya' },
  { value: 'LS', name: 'Liechtenstein' },
  { value: 'LH', name: 'Lithuania' },
  { value: 'LU', name: 'Luxembourg' },
  { value: 'MC', name: 'Macao' },
  { value: 'MK', name: 'Macedonia' },
  { value: 'MA', name: 'Madagascar' },
  { value: 'MI', name: 'Malawi' },
  { value: 'MY', name: 'Malaysia' },
  { value: 'MV', name: 'Maldives' },
  { value: 'ML', name: 'Mali' },
  { value: 'MT', name: 'Malta' },
  { value: 'IM', name: 'Isle of Man' },
  { value: 'RM', name: 'Marshall Islands' },
  { value: 'MR', name: 'Mauritania' },
  { value: 'MP', name: 'Mauritius' },
  { value: 'MX', name: 'Mexico' },
  { value: 'MD', name: 'Moldova' },
  { value: 'MN', name: 'Monaco' },
  { value: 'MG', name: 'Mongolia' },
  { value: 'MJ', name: 'Montenegro' },
  { value: 'MH', name: 'Montserrat' },
  { value: 'MO', name: 'Morocco' },
  { value: 'MZ', name: 'Mozambique' },
  { value: 'WA', name: 'Namibia' },
  { value: 'NR', name: 'Nauru' },
  { value: 'NR', name: 'Nauru' },
  { value: 'BQ', name: 'Navassa Island' },
  { value: 'NP', name: 'Nepal' },
  { value: 'NL', name: 'Netherlands' },
  { value: 'NC', name: 'New Caledonia' },
  { value: 'NZ', name: 'New Zealand' },
  { value: 'NU', name: 'Nicaragua' },
  { value: 'NG', name: 'Niger' },
  { value: 'NI', name: 'Nigeria' },
  { value: 'NE', name: 'Niue' },
  { value: 'NF', name: 'Norfolk Island' },
  { value: 'CQ', name: 'Northern Mariana Islands' },
  { value: 'NO', name: 'Norway' },
  { value: 'MU', name: 'Oman' },
  { value: 'PK', name: 'Pakistan' },
  { value: 'PS', name: 'Palau' },
  { value: 'LQ', name: 'Palmyra Atoll' },
  { value: 'PM', name: 'Panama' },
  { value: 'PP', name: 'Papua-New Guinea' },
  { value: 'PF', name: 'Paracel Islands' },
  { value: 'PA', name: 'Paraguay' },
  { value: 'PE', name: 'Peru' },
  { value: 'RP', name: 'Philippines' },
  { value: 'PC', name: 'Pitcairn Islands' },
  { value: 'PL', name: 'Poland' },
  { value: 'PO', name: 'Portugal' },
  { value: 'RQ', name: 'Puerto Rico' },
  { value: 'QA', name: 'Qatar' },
  { value: 'RO', name: 'Romania' },
  { value: 'RS', name: 'Russia' },
  { value: 'RW', name: 'Rwanda' },
  { value: 'TB', name: 'St. Barthelemy' },
  { value: 'SH', name: 'St. Helena' },
  { value: 'SC', name: 'St. Kitts and Nevis' },
  { value: 'ST', name: 'St. Lucia Island' },
  { value: 'RN', name: 'St. Martin' },
  { value: 'NN', name: 'Sint Maarten' },
  { value: 'SB', name: 'St. Pierre and Miquelon' },
  { value: 'VC', name: 'St. Vincent and the Grenadines' },
  { value: 'WS', name: 'Samoa' },
  { value: 'SM', name: 'San Marino' },
  { value: 'TP', name: 'Sao Tome and Principe' },
  { value: 'SA', name: 'Saudi Arabia' },
  { value: 'SG', name: 'Senegal' },
  { value: 'RI', name: 'Serbia' },
  { value: 'SE', name: 'Seychelles' },
  { value: 'SL', name: 'Sierra Leone' },
  { value: 'SN', name: 'Singapore' },
  { value: 'LO', name: 'Slovakia' },
  { value: 'SI', name: 'Slovenia' },
  { value: 'BP', name: 'Solomon Islands' },
  { value: 'SO', name: 'Somalia' },
  { value: 'SF', name: 'South Africa' },
  { value: 'SX', name: 'South Georgia and the South Sandwich Islands' },
  { value: 'OD', name: 'South Sudan' },
  { value: 'SP', name: 'Spain' },
  { value: 'PG', name: 'Spratly Islands' },
  { value: 'CE', name: 'Sri Lanka' },
  { value: 'SU', name: 'Sudan' },
  { value: 'NS', name: 'Suriname' },
  { value: 'SV', name: 'Svalbard and Jan Mayen' },
  { value: 'WS', name: 'Swaziland' },
  { value: 'SW', name: 'Sweden' },
  { value: 'SZ', name: 'Switzerland' },
  { value: 'SY', name: 'Syria' },
  { value: 'TW', name: 'Taiwan' },
  { value: 'TI', name: 'Tajikistan' },
  { value: 'TZ', name: 'Tanzania' },
  { value: 'TH', name: 'Thailand' },
  { value: 'TO', name: 'Togo' },
  { value: 'TL', name: 'Tokelau' },
  { value: 'TN', name: 'Tonga' },
  { value: 'TD', name: 'Trinidad and Tobago' },
  { value: 'TS', name: 'Tunisia' },
  { value: 'TU', name: 'Turkey' },
  { value: 'TX', name: 'Turkmenistan' },
  { value: 'TK', name: 'Turks and Caicos Islands' },
  { value: 'TV', name: 'Tuvalu' },
  { value: 'UG', name: 'Uganda' },
  { value: 'UP', name: 'Ukraine' },
  { value: 'AE', name: 'United Arab Emirates' },
  { value: 'UK', name: 'United Kingdom' },
  { value: 'UY', name: 'Uruguay' },
  { value: 'UZ', name: 'Uzbekistan' },
  { value: 'NH', name: 'Vanuatu' },
  { value: 'VE', name: 'Venezuela' },
  { value: 'VM', name: 'Vietnam' },
  { value: 'VQ', name: 'Virgin Islands' },
  { value: 'WQ', name: 'Wake island' },
  { value: 'WF', name: 'Wallis and Futuna' },
  { value: 'WI', name: 'Western Sahara' },
  { value: 'YM', name: 'Yemen (Aden)' },
  { value: 'ZA', name: 'Zambia' },
  { value: 'ZI', name: 'Zimbabwe' },
  { value: 'OC', name: 'Other Country' }
];

const FOREIGN_ASSETS_STATES_CODES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AS', name: 'American Samoa' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'DC', name: 'District of Columbia' },
  { value: 'FM', name: 'Fed. States of Micronesia' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'GU', name: 'Guam' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MH', name: 'Marshall Islands' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'MP', name: 'Northern Mariana Islands' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PW', name: 'Palau' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'PR', name: 'Puerto Rico' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VI', name: 'Virgin Islands' },
  { value: 'VA', name: 'Virginia' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' },
  { value: 'AE', name: 'Armed Forces Africa, Canada, Europe, Middle East' },
  { value: 'AA', name: 'Armed Forces Americas (Except Canada)' },
  { value: 'AP', name: 'Armed Forces Pacific' }
];

// Canadian Provinces as codes
const PROVINCES_CODE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: AXCESS_KEYS.INITIAL_AB, name: STRINGS.NAME_AB },
  { value: AXCESS_KEYS.INITIAL_BC, name: STRINGS.NAME_BC },
  { value: AXCESS_KEYS.INITIAL_MB, name: STRINGS.NAME_MB },
  { value: AXCESS_KEYS.INITIAL_NB, name: STRINGS.NAME_NB },
  { value: AXCESS_KEYS.INITIAL_NL, name: STRINGS.NAME_NL },
  { value: AXCESS_KEYS.INITIAL_NS, name: STRINGS.NAME_NS },
  { value: AXCESS_KEYS.INITIAL_NT, name: STRINGS.NAME_NT },
  { value: AXCESS_KEYS.INITIAL_NU, name: STRINGS.NAME_NU },
  { value: AXCESS_KEYS.INITIAL_ON, name: STRINGS.NAME_ON },
  { value: AXCESS_KEYS.INITIAL_PE, name: STRINGS.NAME_PE },
  { value: AXCESS_KEYS.INITIAL_QC, name: STRINGS.NAME_QC },
  { value: AXCESS_KEYS.INITIAL_SK, name: STRINGS.NAME_SK },
  { value: AXCESS_KEYS.INITIAL_YT, name: STRINGS.NAME_YT }
];

const MEX_STATES_CODE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'AGU', name: 'Aguascalientes' },
  { value: 'BCN', name: 'Baja California' },
  { value: 'BCS', name: 'Baja California Sur' },
  { value: 'CAM', name: 'Campeche' },
  { value: 'CHP', name: 'Chiapas' },
  { value: 'CHH', name: 'Chihuahua' },
  { value: 'CMX', name: 'Cuidad de Mexico' },
  { value: 'COA', name: 'Coahuila de Zaragoza' },
  { value: 'COL', name: 'Colima' },
  { value: 'DUR', name: 'Durango' },
  { value: 'GUA', name: 'Guanajuato' },
  { value: 'GRO', name: 'Guerrero' },
  { value: 'HID', name: 'Hidalgo' },
  { value: 'JAL', name: 'Jalisco' },
  { value: 'MIC', name: 'Michoacan de Ocampo' },
  { value: 'MOR', name: 'Morelos' },
  { value: 'MEX', name: 'Mexico' },
  { value: 'NAY', name: 'Nayarit' },
  { value: 'NLE', name: 'Nuevo Leon' },
  { value: 'OAX', name: 'Oaxaca' },
  { value: 'PUE', name: 'Puebla' },
  { value: 'QUE', name: 'Queretaro' },
  { value: 'ROO', name: 'Quintana Roo' },
  { value: 'SLP', name: 'San Luis Potosi' },
  { value: 'SIN', name: 'Sinaloa' },
  { value: 'SON', name: 'Sonora' },
  { value: 'TAB', name: 'Tabasco' },
  { value: 'TAM', name: 'Tamaulipas' },
  { value: 'TLA', name: 'Tlaxcala' },
  { value: 'VER', name: 'Veracruz de Ignacio de la Llave' },
  { value: 'YUC', name: 'Yucatan' },
  { value: 'ZAC', name: 'Zacatecas' }
];

const TABLE_OR_ATTACH = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Table', name: 'Table' },
  { value: 'Attach', name: 'Attach' }
];

const CONTRIBUTION_TYPE = [
  { value: 'Person', name: 'Person' },
  { value: 'Payroll', name: 'Payroll' },
  { value: 'Noncash', name: 'Noncash' }
];

const COMP_POSITION = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Former', name: 'Former' },
  { value: 'Officer', name: 'Officer' },
  { value: 'Director', name: 'Director' },
  { value: 'Highest Comp EE', name: 'Highest Comp EE' },
  { value: 'Key Employee', name: 'Key Employee' },
  { value: 'Institutional Trustee', name: 'Institutional Trustee' }
];

const CHARITY_STATUSES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: '170(b)(1)(A)(i)–Church', name: '170(b)(1)(A)(i)–Church' },
  { value: '170(b)(1)(A)(ii)-School', name: '170(b)(1)(A)(ii)-School' },
  { value: '170(b)(1)(A)(iii)-Hospital', name: '170(b)(1)(A)(iii)-Hospital' },
  {
    value: '170(b)(1)(A)(iii)-Med Research Org',
    name: '170(b)(1)(A)(iii)-Med Research Org'
  },
  {
    value: '170(b)(1)(A)(iv)-College/University',
    name: '170(b)(1)(A)(iv)-College/University'
  },
  { value: '170(b)(1)(A)(v)–Gov Unit', name: '170(b)(1)(A)(v)–Gov Unit' },
  {
    value: '170(b)(1)(A)(vi)-Gov/Public Funded',
    name: '170(b)(1)(A)(vi)-Gov/Public Funded'
  },
  {
    value: '170(b)(1)(A)(vi)-Community Trust',
    name: '170(b)(1)(A)(vi)-Community Trust'
  },
  {
    value: '170(b)(1)(A)(ix)–Ag Research Org',
    name: '170(b)(1)(A)(ix)–Ag Research Org'
  },
  {
    value: '509(a)(2)–Gross Receipts Test',
    name: '509(a)(2)–Gross Receipts Test'
  },
  { value: '509(a)(4)–Public Safety', name: '509(a)(4)–Public Safety' },
  { value: '509(a)(3)–Type I', name: '509(a)(3)–Type I' },
  { value: '509(a)(3)–Type II', name: '509(a)(3)–Type II' },
  {
    value: '509(a)(3)–Type III Functionally Int.',
    name: '509(a)(3)–Type III Functionally Int.'
  },
  {
    value: '509(a)(3)–Type III Non-functionally Int.',
    name: '509(a)(3)–Type III Non-functionally Int.'
  }
];

const EXEMPT_STATUSES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  {
    value: STRINGS.DROP_DOWN_NONE,
    name: STRINGS.DROP_DOWN_NONE
  },
  { value: '501(c)(1)', name: '501(c)(1)' },
  { value: '501(c)(2)', name: '501(c)(2)' },
  { value: '501(c)(3)', name: '501(c)(3)' },
  { value: '501(c)(4)', name: '501(c)(4)' },
  { value: '501(c)(5)', name: '501(c)(5)' },
  { value: '501(c)(6)', name: '501(c)(6)' },
  { value: '501(c)(7)', name: '501(c)(7)' },
  { value: '501(c)(8)', name: '501(c)(8)' },
  { value: '501(c)(9)', name: '501(c)(9)' },
  { value: '501(c)(10)', name: '501(c)(10)' },
  { value: '501(c)(11)', name: '501(c)(11)' },
  { value: '501(c)(12)', name: '501(c)(12)' },
  { value: '501(c)(13)', name: '501(c)(13)' },
  { value: '501(c)(14)', name: '501(c)(14)' },
  { value: '501(c)(15)', name: '501(c)(15)' },
  { value: '501(c)(16)', name: '501(c)(16)' },
  { value: '501(c)(17)', name: '501(c)(17)' },
  { value: '501(c)(18)', name: '501(c)(18)' },
  { value: '501(c)(19)', name: '501(c)(19)' },
  { value: '501(c)(20)', name: '501(c)(20)' },
  { value: '501(c)(21)', name: '501(c)(21)' },
  { value: '501(c)(22)', name: '501(c)(22)' },
  { value: '501(c)(23)', name: '501(c)(23)' },
  { value: '501(c)(24)', name: '501(c)(24)' },
  { value: '501(c)(25)', name: '501(c)(25)' },
  { value: '501(c)(26)', name: '501(c)(26)' },
  { value: '501(c)(27)', name: '501(c)(27)' },
  { value: '501(c)(28)', name: '501(c)(28)' },
  { value: '501(c)(29)', name: '501(c)(29)' },
  { value: '4947(a)(1)', name: '4947(a)(1)' },
  { value: '527', name: '527' }
];

const EXEMPT_STATUSES_BY_NUMBER = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  {
    value: STRINGS.DROP_DOWN_NONE,
    name: STRINGS.DROP_DOWN_NONE
  },
  { value: '1', name: '501(c)(1)' },
  { value: '2', name: '501(c)(2)' },
  { value: '3', name: '501(c)(3)' },
  { value: '4', name: '501(c)(4)' },
  { value: '5', name: '501(c)(5)' },
  { value: '6', name: '501(c)(6)' },
  { value: '7', name: '501(c)(7)' },
  { value: '8', name: '501(c)(8) & (c)(10)' },
  { value: '9', name: '501(c)(9)' },
  { value: '10', name: '501(c)(11)' },
  { value: '11', name: '501(c)(12)' },
  { value: '12', name: '501(c)(13)' },
  { value: '13', name: '501(c)(14)' },
  { value: '14', name: '501(c)(15)' },
  { value: '15', name: '501(c)(16)' },
  { value: '16', name: '501(c)(17)' },
  { value: '17', name: '501(c)(18)' },
  { value: '18', name: '501(c)(19) & (c)(23)' },
  { value: '19', name: '501(c)(21)' },
  { value: '20', name: '501(c)(22)' },
  { value: '21', name: '501(c)(25)' },
  { value: '22', name: '501(c)(26)' },
  { value: '23', name: '501(c)(27)' },
  { value: '24', name: '501(d)' },
  { value: '25', name: '501(e)' },
  { value: '26', name: '501(f)' },
  { value: '27', name: '501(k)' },
  { value: '28', name: '501(n)' },
  { value: '29', name: '527' },
  { value: '30', name: '501(c)(24)' },
  { value: '31', name: '501(c)(28)' },
  { value: '32', name: '501(c)(29)' },
  { value: '33', name: '501(j)' },
];

const ACCOUNT_TYPE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  {
    value: AXCESS_KEYS.AXCESS_KEY_CHECKING,
    name: STRINGS.ACCOUNT_TYPE_CHECKING
  },
  { value: AXCESS_KEYS.AXCESS_KEY_SAVINGS, name: STRINGS.ACCOUNT_TYPE_SAVINGS }
];
const DOCUMENT_TYPE = [
  { value: 'Governing documents ', name: 'Governing documents' },
  { value: 'Conflict of interest policy', name: 'Conflict of interest policy' },
  { value: 'Financial statements', name: 'Financial statements' }
];

const RELATIONSHIP = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Son', name: 'Son' },
  { value: 'Daughter', name: 'Daughter' },
  { value: 'Stepchild', name: 'Stepchild' },
  { value: 'Foster Child', name: 'Foster Child' },
  { value: 'Brother', name: 'Brother' },
  { value: 'Sister', name: 'Sister' },
  { value: 'Stepbrother', name: 'Stepbrother' },
  { value: 'Stepsister', name: 'Stepsister' },
  { value: 'Half Brother', name: 'Half Brother' },
  { value: 'Half Sister', name: 'Half Sister' },
  { value: 'Grandchild', name: 'Grandchild' },
  { value: 'Niece', name: 'Niece' },
  { value: 'Nephew', name: 'Nephew' },
  { value: 'Parent', name: 'Parent' },
  { value: 'Grandparent', name: 'Grandparent' },
  { value: 'Aunt', name: 'Aunt' },
  { value: 'Uncle', name: 'Uncle' },
  { value: 'Other', name: 'Other' },
  { value: 'None', name: 'None' }
];

const TAX_TYPE = [
  { value: ' ', name: 'Other taxes' },
  { value: '2', name: 'Real estate taxes' },
  { value: '3', name: 'Personal property taxes' },
  { value: '4', name: 'Foreign taxes' },
  { value: '6', name: 'Local taxes' }
];

const FORM_SIGNER_TYPE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'President', name: 'President' },
  { value: 'Chief financial officer', name: 'Chief Financial Officer' },
  { value: 'Treasurer', name: 'Treasurer' },
  { value: 'Other authorized officer', name: 'Other Authorized Officer' }
];

const FSRPTACCTMTHDPY_OPTIONS = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Cash', name: 'Cash' },
  { value: 'Accrual', name: 'Accrual' },
  { value: 'Other', name: 'Other' }
];

const ASSET_LIQ_TYPE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Asset Distributed', name: 'Assets Distributed' },
  { value: 'Transaction Expense', name: 'Transaction Expense' }
];

// Section 7; Step 5
// Field ShortName: indContractForeignCountry
const INDEPENDENT_CONTRACTOR_COUNTRIES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'CA', name: 'Canada' },
  { value: 'AF', name: 'Afghanistan' },
  { value: 'AX', name: 'Akrotiri' },
  { value: 'AL', name: 'Albania' },
  { value: 'AG', name: 'Algeria' },
  { value: 'AQ', name: 'American Samoa' },
  { value: 'AN', name: 'Andorra' },
  { value: 'AO', name: 'Angola' },
  { value: 'AV', name: 'Anguilla' },
  { value: 'AY', name: 'Antarctica' },
  { value: 'AC', name: 'Antigua and Barbuda' },
  { value: 'AR', name: 'Argentina' },
  { value: 'AM', name: 'Armenia' },
  { value: 'AA', name: 'Aruba' },
  { value: 'AT', name: 'Ashmore and Cartier Islands' },
  { value: 'AS', name: 'Australia' },
  { value: 'AU', name: 'Austria' },
  { value: 'AJ', name: 'Azerbaijan' },
  { value: 'BF', name: 'Bahamas' },
  { value: 'BA', name: 'Bahrain' },
  { value: 'FQ', name: 'Baker Island' },
  { value: 'BG', name: 'Bangladesh' },
  { value: 'BB', name: 'Barbados' },
  { value: 'BO', name: 'Belarus' },
  { value: 'BE', name: 'Belgium' },
  { value: 'BH', name: 'Belize' },
  { value: 'BN', name: 'Benin' },
  { value: 'BD', name: 'Bermuda' },
  { value: 'BT', name: 'Bhutan' },
  { value: 'BL', name: 'Bolivia' },
  { value: 'BK', name: 'Bosnia-Herzegovina' },
  { value: 'BC', name: 'Botswana' },
  { value: 'BV', name: 'Bouvet Island' },
  { value: 'BR', name: 'Brazil' },
  { value: 'IO', name: 'British Indian Ocean Territory' },
  { value: 'VI', name: 'British Virgin Islands' },
  { value: 'BX', name: 'Brunei' },
  { value: 'BU', name: 'Bulgaria' },
  { value: 'UV', name: 'Burkina Faso' },
  { value: 'BM', name: 'Burma' },
  { value: 'BY', name: 'Burundi' },
  { value: 'CB', name: 'Cambodia' },
  { value: 'CM', name: 'Cameroon' },
  { value: 'CV', name: 'Cape Verde' },
  { value: 'CJ', name: 'Cayman Islands' },
  { value: 'CT', name: 'Central African Republic' },
  { value: 'CD', name: 'Chad' },
  { value: 'CI', name: 'Chile' },
  { value: 'CH', name: 'China' },
  { value: 'KT', name: 'Christmas Islands' },
  { value: 'IP', name: 'Clipperton Islands' },
  { value: 'CK', name: 'Cocos (Keeling) Islands' },
  { value: 'CO', name: 'Colombia' },
  { value: 'CN', name: 'Comoros' },
  { value: 'CF', name: 'Congo (Brazzaville)' },
  { value: 'CG', name: 'Congo (Kinshasa)' },
  { value: 'CW', name: 'Cook Islands' },
  { value: 'CR', name: 'Coral Sea Islands' },
  { value: 'CS', name: 'Costa Rica' },
  { value: 'IV', name: "Cote D'Ivoire (Ivory Coast)" },
  { value: 'HR', name: 'Croatia' },
  { value: 'CU', name: 'Cuba' },
  { value: 'UC', name: 'Curacao' },
  { value: 'CY', name: 'Cyprus' },
  { value: 'EZ', name: 'Czech Republic' },
  { value: 'DA', name: 'Denmark' },
  { value: 'DX', name: 'Dhekelia' },
  { value: 'DJ', name: 'Djibouti' },
  { value: 'DO', name: 'Dominica' },
  { value: 'DR', name: 'Dominican Republic' },
  { value: 'TT', name: 'East Timor' },
  { value: 'EC', name: 'Ecuador' },
  { value: 'EG', name: 'Egypt' },
  { value: 'ES', name: 'El Salvador' },
  { value: 'EK', name: 'Equatorial Guinea' },
  { value: 'ER', name: 'Eritrea' },
  { value: 'EN', name: 'Estonia' },
  { value: 'ET', name: 'Ethiopia' },
  { value: 'FK', name: 'Falkland Islands (Islas Malvinas)' },
  { value: 'FO', name: 'Faroe Islands' },
  { value: 'FM', name: 'Federated States of Micronesia' },
  { value: 'FJ', name: 'Fiji' },
  { value: 'FI', name: 'Finland' },
  { value: 'FR', name: 'France' },
  { value: 'FP', name: 'French Polynesia' },
  { value: 'FS', name: 'French Southern and Antarctic Lands' },
  { value: 'GB', name: 'Gabon' },
  { value: 'GA', name: 'The Gambia' },
  { value: 'GG', name: 'Georgia' },
  { value: 'GM', name: 'Germany' },
  { value: 'GH', name: 'Ghana' },
  { value: 'GI', name: 'Gibraltar' },
  { value: 'GR', name: 'Greece' },
  { value: 'GL', name: 'Greenland' },
  { value: 'GJ', name: 'Grenada' },
  { value: 'GQ', name: 'Guam' },
  { value: 'GT', name: 'Guatemala' },
  { value: 'GK', name: 'Guernsey' },
  { value: 'GV', name: 'Guinea' },
  { value: 'PU', name: 'Guinea-Bissau' },
  { value: 'GY', name: 'Guyana' },
  { value: 'HA', name: 'Haiti' },
  { value: 'HM', name: 'Heard Island and McDonald Islands' },
  { value: 'HO', name: 'Honduras' },
  { value: 'HK', name: 'Hong Kong' },
  { value: 'HQ', name: 'Howland Island' },
  { value: 'HU', name: 'Hungary' },
  { value: 'IC', name: 'Iceland' },
  { value: 'IN', name: 'India' },
  { value: 'ID', name: 'Indonesia' },
  { value: 'IR', name: 'Iran' },
  { value: 'IZ', name: 'Iraq' },
  { value: 'EI', name: 'Ireland' },
  { value: 'IS', name: 'Israel' },
  { value: 'IT', name: 'Italy' },
  { value: 'JM', name: 'Jamaica' },
  { value: 'JN', name: 'Jan Mayen' },
  { value: 'JA', name: 'Japan' },
  { value: 'DQ', name: 'Jarvis Island' },
  { value: 'JE', name: 'Jersey' },
  { value: 'JQ', name: 'Johnston Atoll' },
  { value: 'JO', name: 'Jordan' },
  { value: 'KZ', name: 'Kazakhstan' },
  { value: 'KE', name: 'Kenya' },
  { value: 'KQ', name: 'Kingman Reef' },
  { value: 'KR', name: 'Kiribati' },
  { value: 'KN', name: 'North Korea' },
  { value: 'KS', name: 'South Korea' },
  { value: 'KV', name: 'Kosovo' },
  { value: 'KU', name: 'Kuwait' },
  { value: 'KG', name: 'Kyrgyzstan' },
  { value: 'LA', name: 'Laos' },
  { value: 'LG', name: 'Latvia' },
  { value: 'LE', name: 'Lebanon' },
  { value: 'LT', name: 'Lesotho' },
  { value: 'LI', name: 'Liberia' },
  { value: 'LY', name: 'Libya' },
  { value: 'LS', name: 'Liechtenstein' },
  { value: 'LH', name: 'Lithuania' },
  { value: 'LU', name: 'Luxembourg' },
  { value: 'MC', name: 'Macau' },
  { value: 'MK', name: 'Macedonia' },
  { value: 'MA', name: 'Madagascar' },
  { value: 'MI', name: 'Malawi' },
  { value: 'MY', name: 'Malaysia' },
  { value: 'MV', name: 'Maldives' },
  { value: 'ML', name: 'Mali' },
  { value: 'MT', name: 'Malta' },
  { value: 'IM', name: 'Isle of Man' },
  { value: 'RM', name: 'Marshall Islands' },
  { value: 'MR', name: 'Mauritania' },
  { value: 'MP', name: 'Mauritius' },
  { value: 'MX', name: 'Mexico' },
  { value: 'MQ', name: 'Midway Islands' },
  { value: 'MD', name: 'Moldova' },
  { value: 'MN', name: 'Monaco' },
  { value: 'MG', name: 'Mongolia' },
  { value: 'MJ', name: 'Montenegro' },
  { value: 'MH', name: 'Montserrat' },
  { value: 'MO', name: 'Morocco' },
  { value: 'MZ', name: 'Mozambique' },
  { value: 'WA', name: 'Namibia' },
  { value: 'NR', name: 'Nauru' },
  { value: 'BQ', name: 'Navassa Island' },
  { value: 'NP', name: 'Nepal' },
  { value: 'NL', name: 'Netherlands' },
  { value: 'NC', name: 'New Caledonia' },
  { value: 'NZ', name: 'New Zealand' },
  { value: 'NU', name: 'Nicaragua' },
  { value: 'NG', name: 'Niger' },
  { value: 'NI', name: 'Nigeria' },
  { value: 'NE', name: 'Niue' },
  { value: 'NF', name: 'Norfolk Island' },
  { value: 'CQ', name: 'Northern Mariana Islands' },
  { value: 'NO', name: 'Norway' },
  { value: 'MU', name: 'Oman' },
  { value: 'PK', name: 'Pakistan' },
  { value: 'PS', name: 'Palau' },
  { value: 'LQ', name: 'Palmyra Atoll' },
  { value: 'PM', name: 'Panama' },
  { value: 'PP', name: 'Papua-New Guinea' },
  { value: 'PF', name: 'Paracel Islands' },
  { value: 'PA', name: 'Paraguay' },
  { value: 'PE', name: 'Peru' },
  { value: 'RP', name: 'Philippines' },
  { value: 'PC', name: 'Pitcairn Islands' },
  { value: 'PL', name: 'Poland' },
  { value: 'PO', name: 'Portugal' },
  { value: 'RQ', name: 'Puerto Rico' },
  { value: 'QA', name: 'Qatar' },
  { value: 'RO', name: 'Romania' },
  { value: 'RS', name: 'Russia' },
  { value: 'RW', name: 'Rwanda' },
  { value: 'TB', name: 'St. Barthelemy' },
  { value: 'SH', name: 'St. Helena' },
  { value: 'SC', name: 'St. Kitts and Nevis' },
  { value: 'ST', name: 'St. Lucia Island' },
  { value: 'RN', name: 'St. Martin' },
  { value: 'NN', name: 'Sint Maarten' },
  { value: 'SB', name: 'St. Pierre and Miquelon' },
  { value: 'VC', name: 'St. Vincent and the Grenadines' },
  { value: 'WS', name: 'Samoa' },
  { value: 'SM', name: 'San Marino' },
  { value: 'TP', name: 'Sao Tome and Principe' },
  { value: 'SA', name: 'Saudi Arabia' },
  { value: 'SG', name: 'Senegal' },
  { value: 'RI', name: 'Serbia' },
  { value: 'SE', name: 'Seychelles' },
  { value: 'SL', name: 'Sierra Leone' },
  { value: 'SN', name: 'Singapore' },
  { value: 'LO', name: 'Slovakia' },
  { value: 'SI', name: 'Slovenia' },
  { value: 'BP', name: 'Solomon Islands' },
  { value: 'SO', name: 'Somalia' },
  { value: 'SF', name: 'South Africa' },
  { value: 'SX', name: 'South Georgia and South Sandwich Island' },
  { value: 'OD', name: 'South Sudan' },
  { value: 'SP', name: 'Spain' },
  { value: 'PG', name: 'Spratly Islands' },
  { value: 'CE', name: 'Sri Lanka' },
  { value: 'SU', name: 'Sudan' },
  { value: 'NS', name: 'Suriname' },
  { value: 'SV', name: 'Svalbard' },
  { value: 'WZ', name: 'Swaziland' },
  { value: 'SW', name: 'Sweden' },
  { value: 'SZ', name: 'Switzerland' },
  { value: 'SY', name: 'Syria' },
  { value: 'TW', name: 'Taiwan' },
  { value: 'TI', name: 'Tajikistan' },
  { value: 'TZ', name: 'Tanzania' },
  { value: 'TH', name: 'Thailand' },
  { value: 'TO', name: 'Togo' },
  { value: 'TL', name: 'Tokelau' },
  { value: 'TN', name: 'Tonga' },
  { value: 'TD', name: 'Trinidad and Tobago' },
  { value: 'TS', name: 'Tunisia' },
  { value: 'TU', name: 'Turkey' },
  { value: 'TX', name: 'Turkmenistan' },
  { value: 'TK', name: 'Turks and Caicos Islands' },
  { value: 'TV', name: 'Tuvalu' },
  { value: 'UG', name: 'Uganda' },
  { value: 'UP', name: 'Ukraine' },
  { value: 'AE', name: 'United Arab Emirates' },
  { value: 'UK', name: 'United Kingdom' },
  { value: 'UY', name: 'Uruguay' },
  { value: 'UZ', name: 'Uzbekistan' },
  { value: 'NH', name: 'Vanuatu' },
  { value: 'VT', name: 'Holy See' },
  { value: 'VE', name: 'Venezuela' },
  { value: 'VM', name: 'Vietnam' },
  { value: 'VQ', name: 'Virgin Islands' },
  { value: 'WQ', name: 'Wake Island' },
  { value: 'WF', name: 'Wallis and Futuna' },
  { value: 'WI', name: 'Western Sahara' },
  { value: 'YM', name: 'Yemen (Aden)' },
  { value: 'ZA', name: 'Zambia' },
  { value: 'ZI', name: 'Zimbabwe' },
  { value: 'OC', name: 'Other Country' }
];

// Section 2; Step 1
// Field ShortName: orgCntry and orgMailCountry
const BASIC_DATA_COUNTRIES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'US', name: 'United States' },
  { value: 'CA', name: 'Canada' },
  { value: 'AF', name: 'Afghanistan' },
  { value: 'AX', name: 'Akrotiri' },
  { value: 'AL', name: 'Albania' },
  { value: 'AG', name: 'Algeria' },
  { value: 'AQ', name: 'American Samoa' },
  { value: 'AN', name: 'Andorra' },
  { value: 'AO', name: 'Angola' },
  { value: 'AV', name: 'Anguilla' },
  { value: 'AY', name: 'Antarctica' },
  { value: 'AC', name: 'Antigua and Barbuda' },
  { value: 'AR', name: 'Argentina' },
  { value: 'AM', name: 'Armenia' },
  { value: 'AA', name: 'Aruba' },
  { value: 'AT', name: 'Ashmore and Cartier Islands' },
  { value: 'AS', name: 'Australia' },
  { value: 'AU', name: 'Austria' },
  { value: 'AJ', name: 'Azerbaijan' },
  { value: 'BF', name: 'Bahamas' },
  { value: 'BA', name: 'Bahrain' },
  { value: 'FQ', name: 'Baker Island' },
  { value: 'BG', name: 'Bangladesh' },
  { value: 'BB', name: 'Barbados' },
  { value: 'BO', name: 'Belarus' },
  { value: 'BE', name: 'Belgium' },
  { value: 'BH', name: 'Belize' },
  { value: 'BN', name: 'Benin' },
  { value: 'BD', name: 'Bermuda' },
  { value: 'BT', name: 'Bhutan' },
  { value: 'BL', name: 'Bolivia' },
  { value: 'BK', name: 'Bosnia-Herzegovina' },
  { value: 'BC', name: 'Botswana' },
  { value: 'BV', name: 'Bouvet Island' },
  { value: 'BR', name: 'Brazil' },
  { value: 'IO', name: 'British Indian Ocean Territory' },
  { value: 'VI', name: 'British Virgin Islands' },
  { value: 'BX', name: 'Brunei' },
  { value: 'BU', name: 'Bulgaria' },
  { value: 'UV', name: 'Burkina Faso' },
  { value: 'BM', name: 'Burma' },
  { value: 'BY', name: 'Burundi' },
  { value: 'CB', name: 'Cambodia' },
  { value: 'CM', name: 'Cameroon' },
  { value: 'CV', name: 'Cape Verde' },
  { value: 'CJ', name: 'Cayman Islands' },
  { value: 'CT', name: 'Central African Republic' },
  { value: 'CD', name: 'Chad' },
  { value: 'CI', name: 'Chile' },
  { value: 'CH', name: 'China' },
  { value: 'KT', name: 'Christmas Islands' },
  { value: 'IP', name: 'Clipperton Islands' },
  { value: 'CK', name: 'Cocos (Keeling) Islands' },
  { value: 'CO', name: 'Colombia' },
  { value: 'CN', name: 'Comoros' },
  { value: 'CF', name: 'Congo (Brazzaville)' },
  { value: 'CG', name: 'Congo (Kinshasa)' },
  { value: 'CW', name: 'Cook Islands' },
  { value: 'CR', name: 'Coral Sea Islands' },
  { value: 'CS', name: 'Costa Rica' },
  { value: 'IV', name: "Cote D'Ivoire (Ivory Coast)" },
  { value: 'HR', name: 'Croatia' },
  { value: 'CU', name: 'Cuba' },
  { value: 'UC', name: 'Curacao' },
  { value: 'CY', name: 'Cyprus' },
  { value: 'EZ', name: 'Czech Republic' },
  { value: 'DA', name: 'Denmark' },
  { value: 'DX', name: 'Dhekelia' },
  { value: 'DJ', name: 'Djibouti' },
  { value: 'DO', name: 'Dominica' },
  { value: 'DR', name: 'Dominican Republic' },
  { value: 'TT', name: 'East Timor' },
  { value: 'EC', name: 'Ecuador' },
  { value: 'EG', name: 'Egypt' },
  { value: 'ES', name: 'El Salvador' },
  { value: 'EK', name: 'Equatorial Guinea' },
  { value: 'ER', name: 'Eritrea' },
  { value: 'EN', name: 'Estonia' },
  { value: 'ET', name: 'Ethiopia' },
  { value: 'FK', name: 'Falkland Islands (Islas Malvinas)' },
  { value: 'FO', name: 'Faroe Islands' },
  { value: 'FM', name: 'Federated States of Micronesia' },
  { value: 'FJ', name: 'Fiji' },
  { value: 'FI', name: 'Finland' },
  { value: 'FR', name: 'France' },
  { value: 'FP', name: 'French Polynesia' },
  { value: 'FS', name: 'French Southern and Antarctic Lands' },
  { value: 'GB', name: 'Gabon' },
  { value: 'GA', name: 'The Gambia' },
  { value: 'GG', name: 'Georgia' },
  { value: 'GM', name: 'Germany' },
  { value: 'GH', name: 'Ghana' },
  { value: 'GI', name: 'Gibraltar' },
  { value: 'GR', name: 'Greece' },
  { value: 'GL', name: 'Greenland' },
  { value: 'GJ', name: 'Grenada' },
  { value: 'GQ', name: 'Guam' },
  { value: 'GT', name: 'Guatemala' },
  { value: 'GK', name: 'Guernsey' },
  { value: 'GV', name: 'Guinea' },
  { value: 'PU', name: 'Guinea-Bissau' },
  { value: 'GY', name: 'Guyana' },
  { value: 'HA', name: 'Haiti' },
  { value: 'HM', name: 'Heard Island and McDonald Islands' },
  { value: 'HO', name: 'Honduras' },
  { value: 'HK', name: 'Hong Kong' },
  { value: 'HQ', name: 'Howland Island' },
  { value: 'HU', name: 'Hungary' },
  { value: 'IC', name: 'Iceland' },
  { value: 'IN', name: 'India' },
  { value: 'ID', name: 'Indonesia' },
  { value: 'IR', name: 'Iran' },
  { value: 'IZ', name: 'Iraq' },
  { value: 'EI', name: 'Ireland' },
  { value: 'IS', name: 'Israel' },
  { value: 'IT', name: 'Italy' },
  { value: 'JM', name: 'Jamaica' },
  { value: 'JN', name: 'Jan Mayen' },
  { value: 'JA', name: 'Japan' },
  { value: 'DQ', name: 'Jarvis Island' },
  { value: 'JE', name: 'Jersey' },
  { value: 'JQ', name: 'Johnston Atoll' },
  { value: 'JO', name: 'Jordan' },
  { value: 'KZ', name: 'Kazakhstan' },
  { value: 'KE', name: 'Kenya' },
  { value: 'KQ', name: 'Kingman Reef' },
  { value: 'KR', name: 'Kiribati' },
  { value: 'KN', name: 'North Korea' },
  { value: 'KS', name: 'South Korea' },
  { value: 'KV', name: 'Kosovo' },
  { value: 'KU', name: 'Kuwait' },
  { value: 'KG', name: 'Kyrgyzstan' },
  { value: 'LA', name: 'Laos' },
  { value: 'LG', name: 'Latvia' },
  { value: 'LE', name: 'Lebanon' },
  { value: 'LT', name: 'Lesotho' },
  { value: 'LI', name: 'Liberia' },
  { value: 'LY', name: 'Libya' },
  { value: 'LS', name: 'Liechtenstein' },
  { value: 'LH', name: 'Lithuania' },
  { value: 'LU', name: 'Luxembourg' },
  { value: 'MC', name: 'Macau' },
  { value: 'MK', name: 'Macedonia' },
  { value: 'MA', name: 'Madagascar' },
  { value: 'MI', name: 'Malawi' },
  { value: 'MY', name: 'Malaysia' },
  { value: 'MV', name: 'Maldives' },
  { value: 'ML', name: 'Mali' },
  { value: 'MT', name: 'Malta' },
  { value: 'IM', name: 'Isle of Man' },
  { value: 'RM', name: 'Marshall Islands' },
  { value: 'MR', name: 'Mauritania' },
  { value: 'MP', name: 'Mauritius' },
  { value: 'MX', name: 'Mexico' },
  { value: 'MQ', name: 'Midway Islands' },
  { value: 'MD', name: 'Moldova' },
  { value: 'MN', name: 'Monaco' },
  { value: 'MG', name: 'Mongolia' },
  { value: 'MJ', name: 'Montenegro' },
  { value: 'MH', name: 'Montserrat' },
  { value: 'MO', name: 'Morocco' },
  { value: 'MZ', name: 'Mozambique' },
  { value: 'WA', name: 'Namibia' },
  { value: 'NR', name: 'Nauru' },
  { value: 'BQ', name: 'Navassa Island' },
  { value: 'NP', name: 'Nepal' },
  { value: 'NL', name: 'Netherlands' },
  { value: 'NC', name: 'New Caledonia' },
  { value: 'NZ', name: 'New Zealand' },
  { value: 'NU', name: 'Nicaragua' },
  { value: 'NG', name: 'Niger' },
  { value: 'NI', name: 'Nigeria' },
  { value: 'NE', name: 'Niue' },
  { value: 'NF', name: 'Norfolk Island' },
  { value: 'CQ', name: 'Northern Mariana Islands' },
  { value: 'NO', name: 'Norway' },
  { value: 'MU', name: 'Oman' },
  { value: 'PK', name: 'Pakistan' },
  { value: 'PS', name: 'Palau' },
  { value: 'LQ', name: 'Palmyra Atoll' },
  { value: 'PM', name: 'Panama' },
  { value: 'PP', name: 'Papua-New Guinea' },
  { value: 'PF', name: 'Paracel Islands' },
  { value: 'PA', name: 'Paraguay' },
  { value: 'PE', name: 'Peru' },
  { value: 'RP', name: 'Philippines' },
  { value: 'PC', name: 'Pitcairn Islands' },
  { value: 'PL', name: 'Poland' },
  { value: 'PO', name: 'Portugal' },
  { value: 'RQ', name: 'Puerto Rico' },
  { value: 'QA', name: 'Qatar' },
  { value: 'RO', name: 'Romania' },
  { value: 'RS', name: 'Russia' },
  { value: 'RW', name: 'Rwanda' },
  { value: 'TB', name: 'St. Barthelemy' },
  { value: 'SH', name: 'St. Helena' },
  { value: 'SC', name: 'St. Kitts and Nevis' },
  { value: 'ST', name: 'St. Lucia Island' },
  { value: 'RN', name: 'St. Martin' },
  { value: 'NN', name: 'Sint Maarten' },
  { value: 'SB', name: 'St. Pierre and Miquelon' },
  { value: 'VC', name: 'St. Vincent and the Grenadines' },
  { value: 'WS', name: 'Samoa' },
  { value: 'SM', name: 'San Marino' },
  { value: 'TP', name: 'Sao Tome and Principe' },
  { value: 'SA', name: 'Saudi Arabia' },
  { value: 'SG', name: 'Senegal' },
  { value: 'RI', name: 'Serbia' },
  { value: 'SE', name: 'Seychelles' },
  { value: 'SL', name: 'Sierra Leone' },
  { value: 'SN', name: 'Singapore' },
  { value: 'LO', name: 'Slovakia' },
  { value: 'SI', name: 'Slovenia' },
  { value: 'BP', name: 'Solomon Islands' },
  { value: 'SO', name: 'Somalia' },
  { value: 'SF', name: 'South Africa' },
  { value: 'SX', name: 'South Georgia and South Sandwich Island' },
  { value: 'OD', name: 'South Sudan' },
  { value: 'SP', name: 'Spain' },
  { value: 'PG', name: 'Spratly Islands' },
  { value: 'CE', name: 'Sri Lanka' },
  { value: 'SU', name: 'Sudan' },
  { value: 'NS', name: 'Suriname' },
  { value: 'SV', name: 'Svalbard' },
  { value: 'WZ', name: 'Swaziland' },
  { value: 'SW', name: 'Sweden' },
  { value: 'SZ', name: 'Switzerland' },
  { value: 'SY', name: 'Syria' },
  { value: 'TW', name: 'Taiwan' },
  { value: 'TI', name: 'Tajikistan' },
  { value: 'TZ', name: 'Tanzania' },
  { value: 'TH', name: 'Thailand' },
  { value: 'TO', name: 'Togo' },
  { value: 'TL', name: 'Tokelau' },
  { value: 'TN', name: 'Tonga' },
  { value: 'TD', name: 'Trinidad and Tobago' },
  { value: 'TS', name: 'Tunisia' },
  { value: 'TU', name: 'Turkey' },
  { value: 'TX', name: 'Turkmenistan' },
  { value: 'TK', name: 'Turks and Caicos Islands' },
  { value: 'TV', name: 'Tuvalu' },
  { value: 'UG', name: 'Uganda' },
  { value: 'UP', name: 'Ukraine' },
  { value: 'AE', name: 'United Arab Emirates' },
  { value: 'UK', name: 'United Kingdom' },
  { value: 'UY', name: 'Uruguay' },
  { value: 'UZ', name: 'Uzbekistan' },
  { value: 'NH', name: 'Vanuatu' },
  { value: 'VT', name: 'Holy See' },
  { value: 'VE', name: 'Venezuela' },
  { value: 'VM', name: 'Vietnam' },
  { value: 'VQ', name: 'Virgin Islands' },
  { value: 'WQ', name: 'Wake Island' },
  { value: 'WF', name: 'Wallis and Futuna' },
  { value: 'WI', name: 'Western Sahara' },
  { value: 'YM', name: 'Yemen (Aden)' },
  { value: 'ZA', name: 'Zambia' },
  { value: 'ZI', name: 'Zimbabwe' },
  { value: 'OC', name: 'Other Country' }
];

// Section 2; Step 1
// Field ShortName: orgMailAddState
const BASIC_DATA_STATES_AND_PROV = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'AB', name: 'Alberta' },
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'AA', name: 'Overseas military' },
  { value: 'AE', name: 'Overseas military' },
  { value: 'AP', name: 'Overseas military' },
  { value: 'AS', name: 'American Samoa' },
  { value: 'BC', name: 'British Columbia' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'DC', name: 'District of Columbia' },
  { value: 'FM', name: 'Fed. States of Micronesia' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'GU', name: 'Guam' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MB', name: 'Manitoba' },
  { value: 'MH', name: 'Marshall Islands' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NB', name: 'New Brunswick' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NL', name: 'Newfoundland/Labrador' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'MP', name: 'Northern Mariana Islands' },
  { value: 'NT', name: 'Northwest Territories' },
  { value: 'NS', name: 'Nova Scotia' },
  { value: 'NU', name: 'Nunavut Territories' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'ON', name: 'Ontario' },
  { value: 'OR', name: 'Oregon' },
  { value: 'OT', name: 'Other territories' },
  { value: 'PW', name: 'Palau' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'PE', name: 'Prince Edward Island' },
  { value: 'PR', name: 'Puerto Rico' },
  { value: 'QC', name: 'Quebec' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SK', name: 'Saskatchewan' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
  { value: 'VI', name: 'Virgin Islands' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' },
  { value: 'YT', name: 'Yukon' },
  { value: 'FC', name: 'Foreign countries' },
  { value: 'FW', name: 'Foreign withholding' },
  { value: 'XX', name: 'Various' },
  { value: 'CZ', name: 'Inactive' },
  { value: 'CQ', name: 'Inactive' },
  { value: 'NF', name: 'Inactive' }
];

// Section 2; Step 1
// Field ShortName: orgState
const BASIC_DATA_STATES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'AA', name: 'Overseas military - AA' },
  { value: 'AE', name: 'Overseas military - AE' },
  { value: 'AP', name: 'Overseas military - AP' },
  { value: 'AS', name: 'American Samoa' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'DC', name: 'District of Columbia' },
  { value: 'FM', name: 'Fed. States of Micronesia' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'GU', name: 'Guam' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MH', name: 'Marshall Islands' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'MP', name: 'Northern Mariana Islands' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PW', name: 'Palau' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'PR', name: 'Puerto Rico' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
  { value: 'VI', name: 'Virgin Islands' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' },
  { value: 'CZ', name: 'Inactive - CZ' },
  { value: 'CQ', name: 'Inactive - CQ' },
  { value: 'NF', name: 'Inactive - NF' }
];

const BASIC_DATA_PROVINCES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Alberta', name: 'Alberta' },
  { value: 'British Columbia', name: 'British Columbia' },
  { value: 'Manitoba', name: 'Manitoba' },
  { value: 'New Brunswick', name: 'New Brunswick' },
  { value: 'Newfoundland/Labr', name: 'Newfoundland/Labrador' },
  { value: 'Northwest Territo', name: 'Northwest Territories' },
  { value: 'Nova Scotia', name: 'Nova Scotia' },
  { value: 'Nunavut Territori', name: 'Nunavut Territories' },
  { value: 'Ontario', name: 'Ontario' },
  { value: 'Prince Edward Isl', name: 'Prince Edward Island' },
  { value: 'Quebec', name: 'Quebec' },
  { value: 'Saskatchewan', name: 'Saskatchewan' },
  { value: 'Yukon', name: 'Yukon' }
];

const BASIC_DATA_PROVINCES_CANADA_12 = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Alberta', name: 'Alberta' },
  { value: 'British Col', name: 'British Columbia' },
  { value: 'Manitoba', name: 'Manitoba' },
  { value: 'New Brunswic', name: 'New Brunswick' },
  { value: 'NFL/Labrador', name: 'Newfoundland/Labrador' },
  { value: 'NW Territory', name: 'Northwest Territories' },
  { value: 'Nova Scotia', name: 'Nova Scotia' },
  { value: 'Nunavut Terr', name: 'Nunavut Territories' },
  { value: 'Ontario', name: 'Ontario' },
  { value: 'Prince E Isl', name: 'Prince Edward Island' },
  { value: 'Quebec', name: 'Quebec' },
  { value: 'Saskatchewan', name: 'Saskatchewan' },
  { value: 'Yukon', name: 'Yukon' }
];

const TAX_COMPLIANCE_COUNTRIES = [
  { value: 'AF', name: 'Afghanistan' },
  { value: 'AX', name: 'Akrotiri' },
  { value: 'AL', name: 'Albania' },
  { value: 'AG', name: 'Algeria' },
  { value: 'AQ', name: 'American Samoa' },
  { value: 'AN', name: 'Andorra' },
  { value: 'AO', name: 'Angola' },
  { value: 'AV', name: 'Anguilla' },
  { value: 'AY', name: 'Antarctica' },
  { value: 'AC', name: 'Antigua and Barbuda' },
  { value: 'AR', name: 'Argentina' },
  { value: 'AM', name: 'Armenia' },
  { value: 'AA', name: 'Aruba' },
  { value: 'AT', name: 'Ashmore and Cartier Islands' },
  { value: 'AS', name: 'Australia' },
  { value: 'AU', name: 'Austria' },
  { value: 'AJ', name: 'Azerbaijan' },
  { value: 'BF', name: 'Bahamas' },
  { value: 'BA', name: 'Bahrain' },
  { value: 'FQ', name: 'Baker Island' },
  { value: 'BG', name: 'Bangladesh' },
  { value: 'BB', name: 'Barbados' },
  { value: 'BO', name: 'Belarus' },
  { value: 'BE', name: 'Belgium' },
  { value: 'BH', name: 'Belize' },
  { value: 'BN', name: 'Benin' },
  { value: 'BD', name: 'Bermuda' },
  { value: 'BT', name: 'Bhutan' },
  { value: 'BL', name: 'Bolivia' },
  { value: 'BK', name: 'Bosnia-Herzegovina' },
  { value: 'BC', name: 'Botswana' },
  { value: 'BV', name: 'Bouvet Island' },
  { value: 'BR', name: 'Brazil' },
  { value: 'IO', name: 'British Indian Ocean Territory' },
  { value: 'VI', name: 'British Virgin Islands' },
  { value: 'BX', name: 'Brunei' },
  { value: 'BU', name: 'Bulgaria' },
  { value: 'UV', name: 'Burkina Faso' },
  { value: 'BM', name: 'Burma' },
  { value: 'BY', name: 'Burundi' },
  { value: 'CB', name: 'Cambodia' },
  { value: 'CM', name: 'Cameroon' },
  { value: 'CA', name: 'Canada' },
  { value: 'CV', name: 'Cape Verde' },
  { value: 'CJ', name: 'Cayman Islands' },
  { value: 'CT', name: 'Central African Republic' },
  { value: 'CD', name: 'Chad' },
  { value: 'CI', name: 'Chile' },
  { value: 'CH', name: 'China' },
  { value: 'KT', name: 'Christmas Islands' },
  { value: 'IP', name: 'Clipperton Islands' },
  { value: 'CK', name: 'Cocos (Keeling) Islands' },
  { value: 'CO', name: 'Colombia' },
  { value: 'CN', name: 'Comoros' },
  { value: 'CF', name: 'Congo (Brazzaville)' },
  { value: 'CG', name: 'Congo (Kinshasa)' },
  { value: 'CW', name: 'Cook Islands' },
  { value: 'CR', name: 'Coral Sea Islands' },
  { value: 'CS', name: 'Costa Rica' },
  { value: 'IV', name: "Cote D'Ivoire (Ivory Coast)" },
  { value: 'HR', name: 'Croatia' },
  { value: 'CU', name: 'Cuba' },
  { value: 'UC', name: 'Curacao' },
  { value: 'CY', name: 'Cyprus' },
  { value: 'EZ', name: 'Czech Republic' },
  { value: 'DA', name: 'Denmark' },
  { value: 'DX', name: 'Dhekelia' },
  { value: 'DJ', name: 'Djibouti' },
  { value: 'DO', name: 'Dominica' },
  { value: 'DR', name: 'Dominican Republic' },
  { value: 'TT', name: 'East Timor' },
  { value: 'EC', name: 'Ecuador' },
  { value: 'EG', name: 'Egypt' },
  { value: 'ES', name: 'El Salvador' },
  { value: 'EK', name: 'Equatorial Guinea' },
  { value: 'ER', name: 'Eritrea' },
  { value: 'EN', name: 'Estonia' },
  { value: 'ET', name: 'Ethiopia' },
  { value: 'FK', name: 'Falkland Islands (Islas Malvinas)' },
  { value: 'FO', name: 'Faroe Islands' },
  { value: 'FM', name: 'Federated States of Micronesia' },
  { value: 'FJ', name: 'Fiji' },
  { value: 'FI', name: 'Finland' },
  { value: 'FR', name: 'France' },
  { value: 'FP', name: 'French Polynesia' },
  { value: 'FS', name: 'French Southern and Antarctic Lands' },
  { value: 'GB', name: 'Gabon' },
  { value: 'GA', name: 'The Gambia' },
  { value: 'GG', name: 'Georgia' },
  { value: 'GM', name: 'Germany' },
  { value: 'GH', name: 'Ghana' },
  { value: 'GI', name: 'Gibraltar' },
  { value: 'GR', name: 'Greece' },
  { value: 'GL', name: 'Greenland' },
  { value: 'GJ', name: 'Grenada' },
  { value: 'GQ', name: 'Guam' },
  { value: 'GT', name: 'Guatemala' },
  { value: 'GK', name: 'Guernsey' },
  { value: 'GV', name: 'Guinea' },
  { value: 'PU', name: 'Guinea-Bissau' },
  { value: 'GY', name: 'Guyana' },
  { value: 'HA', name: 'Haiti' },
  { value: 'HM', name: 'Heard Island and McDonald Islands' },
  { value: 'HO', name: 'Honduras' },
  { value: 'HK', name: 'Hong Kong' },
  { value: 'HQ', name: 'Howland Island' },
  { value: 'HU', name: 'Hungary' },
  { value: 'IC', name: 'Iceland' },
  { value: 'IN', name: 'India' },
  { value: 'ID', name: 'Indonesia' },
  { value: 'IR', name: 'Iran' },
  { value: 'IZ', name: 'Iraq' },
  { value: 'EI', name: 'Ireland' },
  { value: 'IS', name: 'Israel' },
  { value: 'IT', name: 'Italy' },
  { value: 'JM', name: 'Jamaica' },
  { value: 'JN', name: 'Jan Mayen' },
  { value: 'JA', name: 'Japan' },
  { value: 'DQ', name: 'Jarvis Island' },
  { value: 'JE', name: 'Jersey' },
  { value: 'JQ', name: 'Johnston Atoll' },
  { value: 'JO', name: 'Jordan' },
  { value: 'KZ', name: 'Kazakhstan' },
  { value: 'KE', name: 'Kenya' },
  { value: 'KQ', name: 'Kingman Reef' },
  { value: 'KR', name: 'Kiribati' },
  { value: 'KN', name: 'North Korea' },
  { value: 'KS', name: 'South Korea' },
  { value: 'KV', name: 'Kosovo' },
  { value: 'KU', name: 'Kuwait' },
  { value: 'KG', name: 'Kyrgyzstan' },
  { value: 'LA', name: 'Laos' },
  { value: 'LG', name: 'Latvia' },
  { value: 'LE', name: 'Lebanon' },
  { value: 'LT', name: 'Lesotho' },
  { value: 'LI', name: 'Liberia' },
  { value: 'LY', name: 'Libya' },
  { value: 'LS', name: 'Liechtenstein' },
  { value: 'LH', name: 'Lithuania' },
  { value: 'LU', name: 'Luxembourg' },
  { value: 'MC', name: 'Macau' },
  { value: 'MK', name: 'Macedonia' },
  { value: 'MA', name: 'Madagascar' },
  { value: 'MI', name: 'Malawi' },
  { value: 'MY', name: 'Malaysia' },
  { value: 'MV', name: 'Maldives' },
  { value: 'ML', name: 'Mali' },
  { value: 'MT', name: 'Malta' },
  { value: 'IM', name: 'Isle of Man' },
  { value: 'RM', name: 'Marshall Islands' },
  { value: 'MR', name: 'Mauritania' },
  { value: 'MP', name: 'Mauritius' },
  { value: 'MX', name: 'Mexico' },
  { value: 'MQ', name: 'Midway Islands' },
  { value: 'MD', name: 'Moldova' },
  { value: 'MN', name: 'Monaco' },
  { value: 'MG', name: 'Mongolia' },
  { value: 'MJ', name: 'Montenegro' },
  { value: 'MH', name: 'Montserrat' },
  { value: 'MO', name: 'Morocco' },
  { value: 'MZ', name: 'Mozambique' },
  { value: 'WA', name: 'Namibia' },
  { value: 'NR', name: 'Nauru' },
  { value: 'BQ', name: 'Navassa Island' },
  { value: 'NP', name: 'Nepal' },
  { value: 'NL', name: 'Netherlands' },
  { value: 'NC', name: 'New Caledonia' },
  { value: 'NZ', name: 'New Zealand' },
  { value: 'NU', name: 'Nicaragua' },
  { value: 'NG', name: 'Niger' },
  { value: 'NI', name: 'Nigeria' },
  { value: 'NE', name: 'Niue' },
  { value: 'NF', name: 'Norfolk Island' },
  { value: 'CQ', name: 'Northern Mariana Islands' },
  { value: 'NO', name: 'Norway' },
  { value: 'MU', name: 'Oman' },
  { value: 'PK', name: 'Pakistan' },
  { value: 'PS', name: 'Palau' },
  { value: 'LQ', name: 'Palmyra Atoll' },
  { value: 'PM', name: 'Panama' },
  { value: 'PP', name: 'Papua-New Guinea' },
  { value: 'PF', name: 'Paracel Islands' },
  { value: 'PA', name: 'Paraguay' },
  { value: 'PE', name: 'Peru' },
  { value: 'RP', name: 'Philippines' },
  { value: 'PC', name: 'Pitcairn Islands' },
  { value: 'PL', name: 'Poland' },
  { value: 'PO', name: 'Portugal' },
  { value: 'RQ', name: 'Puerto Rico' },
  { value: 'QA', name: 'Qatar' },
  { value: 'RO', name: 'Romania' },
  { value: 'RS', name: 'Russia' },
  { value: 'RW', name: 'Rwanda' },
  { value: 'TB', name: 'St. Barthelemy' },
  { value: 'SH', name: 'St. Helena' },
  { value: 'SC', name: 'St. Kitts and Nevis' },
  { value: 'ST', name: 'St. Lucia Island' },
  { value: 'RN', name: 'St. Martin' },
  { value: 'NN', name: 'Sint Maarten' },
  { value: 'SB', name: 'St. Pierre and Miquelon' },
  { value: 'VC', name: 'St. Vincent and the Grenadines' },
  { value: 'WS', name: 'Samoa' },
  { value: 'SM', name: 'San Marino' },
  { value: 'TP', name: 'Sao Tome and Principe' },
  { value: 'SA', name: 'Saudi Arabia' },
  { value: 'SG', name: 'Senegal' },
  { value: 'RI', name: 'Serbia' },
  { value: 'SE', name: 'Seychelles' },
  { value: 'SL', name: 'Sierra Leone' },
  { value: 'SN', name: 'Singapore' },
  { value: 'LO', name: 'Slovakia' },
  { value: 'SI', name: 'Slovenia' },
  { value: 'BP', name: 'Solomon Islands' },
  { value: 'SO', name: 'Somalia' },
  { value: 'SF', name: 'South Africa' },
  { value: 'SX', name: 'South Georgia and South Sandwich Island' },
  { value: 'OD', name: 'South Sudan' },
  { value: 'SP', name: 'Spain' },
  { value: 'PG', name: 'Spratly Islands' },
  { value: 'CE', name: 'Sri Lanka' },
  { value: 'SU', name: 'Sudan' },
  { value: 'NS', name: 'Suriname' },
  { value: 'SV', name: 'Svalbard' },
  { value: 'WZ', name: 'Swaziland' },
  { value: 'SW', name: 'Sweden' },
  { value: 'SZ', name: 'Switzerland' },
  { value: 'SY', name: 'Syria' },
  { value: 'TW', name: 'Taiwan' },
  { value: 'TI', name: 'Tajikistan' },
  { value: 'TZ', name: 'Tanzania' },
  { value: 'TH', name: 'Thailand' },
  { value: 'TO', name: 'Togo' },
  { value: 'TL', name: 'Tokelau' },
  { value: 'TN', name: 'Tonga' },
  { value: 'TD', name: 'Trinidad and Tobago' },
  { value: 'TS', name: 'Tunisia' },
  { value: 'TU', name: 'Turkey' },
  { value: 'TX', name: 'Turkmenistan' },
  { value: 'TK', name: 'Turks and Caicos Islands' },
  { value: 'TV', name: 'Tuvalu' },
  { value: 'UG', name: 'Uganda' },
  { value: 'UP', name: 'Ukraine' },
  { value: 'AE', name: 'United Arab Emirates' },
  { value: 'UK', name: 'United Kingdom' },
  { value: 'UY', name: 'Uruguay' },
  { value: 'UZ', name: 'Uzbekistan' },
  { value: 'NH', name: 'Vanuatu' },
  { value: 'VT', name: 'Holy See' },
  { value: 'VE', name: 'Venezuela' },
  { value: 'VM', name: 'Vietnam' },
  { value: 'VQ', name: 'Virgin Islands' },
  { value: 'WQ', name: 'Wake Island' },
  { value: 'WF', name: 'Wallis and Futuna' },
  { value: 'WI', name: 'Western Sahara' },
  { value: 'YM', name: 'Yemen (Aden)' },
  { value: 'ZA', name: 'Zambia' },
  { value: 'ZI', name: 'Zimbabwe' },
  { value: 'OC', name: 'Other Country' }
];

const CLIENT_SETUP_CLA_COMP_RVW_AUD = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Compilation', name: 'Compilation' },
  { value: 'Review', name: 'Review' },
  { value: 'Audit', name: 'Audit' },
  { value: 'None of the Above', name: 'None of the Above' }
];

const CLIENT_SETUP_FORM_ORG = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'None', name: 'None' },
  { value: 'Corporation', name: 'Corporation' },
  { value: 'Trust', name: 'Trust' },
  { value: 'Association', name: 'Association' },
  { value: 'Other', name: 'Other' }
];

const CONTRIBUTIONS_ITEM_TYPE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Contribution Count', name: 'Contribution Count' },
  { value: 'Item Count', name: 'Item Count' },
  { value: 'Combination', name: 'Combination' }
];

const SUPPORTED_ORG_TYPES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'A', name: 'A' },
  { value: 'B', name: 'B' },
  { value: 'C', name: 'C' },
  { value: 'D', name: 'D' },
  { value: 'E', name: 'E' },
  { value: 'F', name: 'F' },
  { value: 'G', name: 'G' },
  { value: 'H', name: 'H' },
  { value: 'I', name: 'I' },
  { value: 'J', name: 'J' },
  { value: 'K', name: 'K' }
];

const PUBLIC_CHARITY_STATUS = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: '1', name: '170(b)(1)(A)(i)–Church' },
  { value: '2', name: '170(b)(1)(A)(ii)-School' },
  { value: '3', name: '170(b)(1)(A)(iii)-Hospital' },
  { value: '4', name: '170(b)(1)(A)(iii)-Med Research Org' },
  { value: '5', name: '170(b)(1)(A)(iv)-College/University' },
  { value: '6', name: '170(b)(1)(A)(v)–Gov Unit' },
  { value: '7', name: '170(b)(1)(A)(vi)-Gov/Public Funded' },
  { value: '8', name: '170(b)(1)(A)(vi)-Community Trust' },
  { value: '9', name: '170(b)(1)(A)(ix)–Ag Research Org' },
  { value: '10', name: '509(a)(2)–Gross Receipts Test' },
  { value: '11', name: '509(a)(4)– in section 509(a)(1) or 509(a)(2)' },
  { value: '12', name: '509(A)(3), Type I' },
  { value: '13', name: '509(A)(3), Type II' },
  { value: '14', name: '509(A)(3), Type III Functionality Integrated' },
  { value: '15', name: 'Type III, Other' },
  { value: '16', name: 'private Foundation' },
];

const SUPPORTED_ORG_ACTIONS = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Add', name: 'Add' },
  { value: 'Remove', name: 'Remove' },
  { value: 'Substitute', name: 'Substitute' }
];

const PUBLIC_CHARITY_COUNTRIES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'AF', name: 'Afghanistan' },
  { value: 'AX', name: 'Akrotiri' },
  { value: 'AL', name: 'Albania' },
  { value: 'AG', name: 'Algeria' },
  { value: 'AQ', name: 'American Samoa' },
  { value: 'AN', name: 'Andorra' },
  { value: 'AO', name: 'Angola' },
  { value: 'AV', name: 'Anguilla' },
  { value: 'AY', name: 'Antarctica' },
  { value: 'AC', name: 'Antigua and Barbuda' },
  { value: 'AR', name: 'Argentina' },
  { value: 'AM', name: 'Armenia' },
  { value: 'AA', name: 'Aruba' },
  { value: 'AT', name: 'Ashmore and Cartier Islands' },
  { value: 'AS', name: 'Australia' },
  { value: 'AU', name: 'Austria' },
  { value: 'AJ', name: 'Azerbaijan' },
  { value: 'BF', name: 'Bahamas' },
  { value: 'BA', name: 'Bahrain' },
  { value: 'FQ', name: 'Baker Island' },
  { value: 'BG', name: 'Bangladesh' },
  { value: 'BB', name: 'Barbados' },
  { value: 'BO', name: 'Belarus' },
  { value: 'BE', name: 'Belgium' },
  { value: 'BH', name: 'Belize' },
  { value: 'BN', name: 'Benin' },
  { value: 'BD', name: 'Bermuda' },
  { value: 'BT', name: 'Bhutan' },
  { value: 'BL', name: 'Bolivia' },
  { value: 'BK', name: 'Bosnia-Herzegovina' },
  { value: 'BC', name: 'Botswana' },
  { value: 'BV', name: 'Bouvet Island' },
  { value: 'BR', name: 'Brazil' },
  { value: 'IO', name: 'British Indian Ocean Territory' },
  { value: 'VI', name: 'British Virgin Islands' },
  { value: 'BX', name: 'Brunei' },
  { value: 'BU', name: 'Bulgaria' },
  { value: 'UV', name: 'Burkina Faso' },
  { value: 'BM', name: 'Burma' },
  { value: 'BY', name: 'Burundi' },
  { value: 'CA', name: 'Canada' },
  { value: 'CB', name: 'Cambodia' },
  { value: 'CM', name: 'Cameroon' },
  { value: 'CV', name: 'Cape Verde' },
  { value: 'CJ', name: 'Cayman Islands' },
  { value: 'CT', name: 'Central African Republic' },
  { value: 'CD', name: 'Chad' },
  { value: 'CI', name: 'Chile' },
  { value: 'CH', name: 'China' },
  { value: 'KT', name: 'Christmas Islands' },
  { value: 'IP', name: 'Clipperton Islands' },
  { value: 'CK', name: 'Cocos (Keeling) Islands' },
  { value: 'CO', name: 'Colombia' },
  { value: 'CN', name: 'Comoros' },
  { value: 'CF', name: 'Congo (Brazzaville)' },
  { value: 'CG', name: 'Congo (Kinshasa)' },
  { value: 'CW', name: 'Cook Islands' },
  { value: 'CR', name: 'Coral Sea Islands' },
  { value: 'CS', name: 'Costa Rica' },
  { value: 'IV', name: "Cote D'Ivoire (Ivory Coast)" },
  { value: 'HR', name: 'Croatia' },
  { value: 'CU', name: 'Cuba' },
  { value: 'UC', name: 'Curacao' },
  { value: 'CY', name: 'Cyprus' },
  { value: 'EZ', name: 'Czech Republic' },
  { value: 'DA', name: 'Denmark' },
  { value: 'DX', name: 'Dhekelia' },
  { value: 'DJ', name: 'Djibouti' },
  { value: 'DO', name: 'Dominica' },
  { value: 'DR', name: 'Dominican Republic' },
  { value: 'TT', name: 'East Timor' },
  { value: 'EC', name: 'Ecuador' },
  { value: 'EG', name: 'Egypt' },
  { value: 'ES', name: 'El Salvador' },
  { value: 'EK', name: 'Equatorial Guinea' },
  { value: 'ER', name: 'Eritrea' },
  { value: 'EN', name: 'Estonia' },
  { value: 'ET', name: 'Ethiopia' },
  { value: 'FK', name: 'Falkland Islands (Islas Malvinas)' },
  { value: 'FO', name: 'Faroe Islands' },
  { value: 'FM', name: 'Federated States of Micronesia' },
  { value: 'FJ', name: 'Fiji' },
  { value: 'FI', name: 'Finland' },
  { value: 'FR', name: 'France' },
  { value: 'FP', name: 'French Polynesia' },
  { value: 'FS', name: 'French Southern and Antarctic Lands' },
  { value: 'GB', name: 'Gabon' },
  { value: 'GA', name: 'The Gambia' },
  { value: 'GG', name: 'Georgia' },
  { value: 'GM', name: 'Germany' },
  { value: 'GH', name: 'Ghana' },
  { value: 'GI', name: 'Gibraltar' },
  { value: 'GR', name: 'Greece' },
  { value: 'GL', name: 'Greenland' },
  { value: 'GJ', name: 'Grenada' },
  { value: 'GQ', name: 'Guam' },
  { value: 'GT', name: 'Guatemala' },
  { value: 'GK', name: 'Guernsey' },
  { value: 'GV', name: 'Guinea' },
  { value: 'PU', name: 'Guinea-Bissau' },
  { value: 'GY', name: 'Guyana' },
  { value: 'HA', name: 'Haiti' },
  { value: 'HM', name: 'Heard Island and McDonald Islands' },
  { value: 'HO', name: 'Honduras' },
  { value: 'HK', name: 'Hong Kong' },
  { value: 'HQ', name: 'Howland Island' },
  { value: 'HU', name: 'Hungary' },
  { value: 'IC', name: 'Iceland' },
  { value: 'IN', name: 'India' },
  { value: 'ID', name: 'Indonesia' },
  { value: 'IR', name: 'Iran' },
  { value: 'IZ', name: 'Iraq' },
  { value: 'EI', name: 'Ireland' },
  { value: 'IS', name: 'Israel' },
  { value: 'IT', name: 'Italy' },
  { value: 'JM', name: 'Jamaica' },
  { value: 'JN', name: 'Jan Mayen' },
  { value: 'JA', name: 'Japan' },
  { value: 'DQ', name: 'Jarvis Island' },
  { value: 'JE', name: 'Jersey' },
  { value: 'JQ', name: 'Johnston Atoll' },
  { value: 'JO', name: 'Jordan' },
  { value: 'KZ', name: 'Kazakhstan' },
  { value: 'KE', name: 'Kenya' },
  { value: 'KQ', name: 'Kingman Reef' },
  { value: 'KR', name: 'Kiribati' },
  { value: 'KN', name: 'North Korea' },
  { value: 'KS', name: 'South Korea' },
  { value: 'KV', name: 'Kosovo' },
  { value: 'KU', name: 'Kuwait' },
  { value: 'KG', name: 'Kyrgyzstan' },
  { value: 'LA', name: 'Laos' },
  { value: 'LG', name: 'Latvia' },
  { value: 'LE', name: 'Lebanon' },
  { value: 'LT', name: 'Lesotho' },
  { value: 'LI', name: 'Liberia' },
  { value: 'LY', name: 'Libya' },
  { value: 'LS', name: 'Liechtenstein' },
  { value: 'LH', name: 'Lithuania' },
  { value: 'LU', name: 'Luxembourg' },
  { value: 'MC', name: 'Macau' },
  { value: 'MK', name: 'Macedonia' },
  { value: 'MA', name: 'Madagascar' },
  { value: 'MI', name: 'Malawi' },
  { value: 'MY', name: 'Malaysia' },
  { value: 'MV', name: 'Maldives' },
  { value: 'ML', name: 'Mali' },
  { value: 'MT', name: 'Malta' },
  { value: 'IM', name: 'Isle of Man' },
  { value: 'RM', name: 'Marshall Islands' },
  { value: 'MR', name: 'Mauritania' },
  { value: 'MP', name: 'Mauritius' },
  { value: 'MX', name: 'Mexico' },
  { value: 'MQ', name: 'Midway Islands' },
  { value: 'MD', name: 'Moldova' },
  { value: 'MN', name: 'Monaco' },
  { value: 'MG', name: 'Mongolia' },
  { value: 'MJ', name: 'Montenegro' },
  { value: 'MH', name: 'Montserrat' },
  { value: 'MO', name: 'Morocco' },
  { value: 'MZ', name: 'Mozambique' },
  { value: 'WA', name: 'Namibia' },
  { value: 'NR', name: 'Nauru' },
  { value: 'BQ', name: 'Navassa Island' },
  { value: 'NP', name: 'Nepal' },
  { value: 'NL', name: 'Netherlands' },
  { value: 'NC', name: 'New Caledonia' },
  { value: 'NZ', name: 'New Zealand' },
  { value: 'NU', name: 'Nicaragua' },
  { value: 'NG', name: 'Niger' },
  { value: 'NI', name: 'Nigeria' },
  { value: 'NE', name: 'Niue' },
  { value: 'NF', name: 'Norfolk Island' },
  { value: 'CQ', name: 'Northern Mariana Islands' },
  { value: 'NO', name: 'Norway' },
  { value: 'MU', name: 'Oman' },
  { value: 'PK', name: 'Pakistan' },
  { value: 'PS', name: 'Palau' },
  { value: 'LQ', name: 'Palmyra Atoll' },
  { value: 'PM', name: 'Panama' },
  { value: 'PP', name: 'Papua-New Guinea' },
  { value: 'PF', name: 'Paracel Islands' },
  { value: 'PA', name: 'Paraguay' },
  { value: 'PE', name: 'Peru' },
  { value: 'RP', name: 'Philippines' },
  { value: 'PC', name: 'Pitcairn Islands' },
  { value: 'PL', name: 'Poland' },
  { value: 'PO', name: 'Portugal' },
  { value: 'RQ', name: 'Puerto Rico' },
  { value: 'QA', name: 'Qatar' },
  { value: 'RO', name: 'Romania' },
  { value: 'RS', name: 'Russia' },
  { value: 'RW', name: 'Rwanda' },
  { value: 'TB', name: 'St. Barthelemy' },
  { value: 'SH', name: 'St. Helena' },
  { value: 'SC', name: 'St. Kitts and Nevis' },
  { value: 'ST', name: 'St. Lucia Island' },
  { value: 'RN', name: 'St. Martin' },
  { value: 'NN', name: 'Sint Maarten' },
  { value: 'SB', name: 'St. Pierre and Miquelon' },
  { value: 'VC', name: 'St. Vincent and the Grenadines' },
  { value: 'WS', name: 'Samoa' },
  { value: 'SM', name: 'San Marino' },
  { value: 'TP', name: 'Sao Tome and Principe' },
  { value: 'SA', name: 'Saudi Arabia' },
  { value: 'SG', name: 'Senegal' },
  { value: 'RI', name: 'Serbia' },
  { value: 'SE', name: 'Seychelles' },
  { value: 'SL', name: 'Sierra Leone' },
  { value: 'SN', name: 'Singapore' },
  { value: 'LO', name: 'Slovakia' },
  { value: 'SI', name: 'Slovenia' },
  { value: 'BP', name: 'Solomon Islands' },
  { value: 'SO', name: 'Somalia' },
  { value: 'SF', name: 'South Africa' },
  { value: 'SX', name: 'South Georgia and South Sandwich Island' },
  { value: 'OD', name: 'South Sudan' },
  { value: 'SP', name: 'Spain' },
  { value: 'PG', name: 'Spratly Islands' },
  { value: 'CE', name: 'Sri Lanka' },
  { value: 'SU', name: 'Sudan' },
  { value: 'NS', name: 'Suriname' },
  { value: 'SV', name: 'Svalbard' },
  { value: 'WZ', name: 'Swaziland' },
  { value: 'SW', name: 'Sweden' },
  { value: 'SZ', name: 'Switzerland' },
  { value: 'SY', name: 'Syria' },
  { value: 'TW', name: 'Taiwan' },
  { value: 'TI', name: 'Tajikistan' },
  { value: 'TZ', name: 'Tanzania' },
  { value: 'TH', name: 'Thailand' },
  { value: 'TO', name: 'Togo' },
  { value: 'TL', name: 'Tokelau' },
  { value: 'TN', name: 'Tonga' },
  { value: 'TD', name: 'Trinidad and Tobago' },
  { value: 'TS', name: 'Tunisia' },
  { value: 'TU', name: 'Turkey' },
  { value: 'TX', name: 'Turkmenistan' },
  { value: 'TK', name: 'Turks and Caicos Islands' },
  { value: 'TV', name: 'Tuvalu' },
  { value: 'UG', name: 'Uganda' },
  { value: 'UP', name: 'Ukraine' },
  { value: 'AE', name: 'United Arab Emirates' },
  { value: 'UK', name: 'United Kingdom' },
  { value: 'UY', name: 'Uruguay' },
  { value: 'UZ', name: 'Uzbekistan' },
  { value: 'NH', name: 'Vanuatu' },
  { value: 'VT', name: 'Holy See' },
  { value: 'VE', name: 'Venezuela' },
  { value: 'VM', name: 'Vietnam' },
  { value: 'VQ', name: 'Virgin Islands' },
  { value: 'WQ', name: 'Wake Island' },
  { value: 'WF', name: 'Wallis and Futuna' },
  { value: 'WI', name: 'Western Sahara' },
  { value: 'YM', name: 'Yemen (Aden)' },
  { value: 'ZA', name: 'Zambia' },
  { value: 'ZI', name: 'Zimbabwe' },
  { value: 'OC', name: 'Other Country' }
];

const FSRPTACCTREVBASIS_OPTIONS = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Separate basis', name: 'Separate/Stand Alone' },
  { value: 'Consolidated basis', name: 'Consolidated' },
  { value: 'Both', name: 'Both' }
];

const CONTRIBUTOR_TYPE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Officer/Director/Key EE', name: 'Officer/Director/Key EE' },
  { value: 'Family Member of ODK', name: 'Family Member of ODK' },
  { value: 'Corp/Partner/Trust/Estate', name: 'Corp/Partner/Trust/Estate' }
];

const GRNT_OUT_US_CHRTY_TYPE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: '501(c)(3)', name: '501(c)(3)' },
  { value: 'Forgn Eqv-501(c)(3)', name: 'Forgn Eqv-501(c)(3)' },
  { value: 'Other 501(c)', name: 'Other 501(c)' },
  { value: 'Forgn Govt Entity', name: 'Forgn Govt Entity' },
  { value: 'Other', name: 'Other' }
];

const GRNT_OUT_US_REGION = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Antarctica', name: 'Antarctica' },
  {
    value: 'Central America and the Caribbean',
    name: 'Central America and the Caribbean'
  },
  { value: 'East Asia and the Pacific', name: 'East Asia and the Pacific' },
  {
    value: 'Europe (Including Iceland & Greenland)',
    name: 'Europe (Including Iceland & Greenland)'
  },
  {
    value: 'Middle East and North Africa',
    name: 'Middle East and North Africa'
  },
  { value: 'North America (non US)', name: 'North America (non US)' },
  {
    value: 'Russia and Neighboring States',
    name: 'Russia and Neighboring States'
  },
  { value: 'South America', name: 'South America' },
  { value: 'South Asia', name: 'South Asia' },
  { value: 'Sub-Saharan Africa', name: 'Sub-Saharan Africa' }
];

const GRNT_OUT_US_DISBURS_MTHD = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Cash', name: 'Cash' },
  { value: 'Check', name: 'Check' },
  { value: 'Money Order', name: 'Money Order' },
  { value: 'Wire Transfer', name: 'Wire Transfer' },
  {
    value: 'Other charges against funds on deposit at a financial institution',
    name: 'Other charges against funds on deposit at a financial institution'
  },
  { value: 'Other', name: 'Other' }
];

const GRNT_OUT_US_VAL_METH = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Book', name: 'Book' },
  { value: 'FMV', name: 'FMV' },
  { value: 'Appraisal', name: 'Appraisal' },
  { value: 'Other', name: 'Other' }
];

const GAME_MANAGER_POSITION = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Director/Officer', name: 'Director/Officer' },
  { value: 'Employee', name: 'Employee' },
  { value: 'Independent Contractor', name: 'Independent Contractor' }
];

const ACTIVITIES_OUTSIDEUS_TYPE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Fundraising', name: 'Fundraising' },
  { value: 'Program Services', name: 'Program Services' },
  { value: 'Investments', name: 'Investments' },
  { value: 'Grants to Recipients', name: 'Grants to Recipients' }
];

const REL_ORG_TYPE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Disregarded Entity', name: 'Disregarded Entity' },
  { value: 'Tax Exempt', name: 'Tax Exempt' },
  { value: 'Taxable as a Partnership', name: 'Taxable as a Partnership' },
  { value: 'Taxable as a Corp/Trust', name: 'Taxable as a Corp/Trust' },
  { value: 'Unrelated Partnership', name: 'Unrelated Partnership' }
];

const UNREL_INCOME_TYPE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Related', name: 'Related' },
  { value: 'Unrelated', name: 'Unrelated' },
  {
    value: 'Excluded from tax under section 512, 513, or 514',
    name: 'Excluded from tax under section 512, 513, or 514'
  }
];

const REL_ORG_TRANS_TYPE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  {
    value: 'Receipt of interest, annuities, royalties, or rent',
    name: 'Receipt of interest, annuities, royalties, or rent'
  },
  {
    value: 'Gift, grant, or capital contribution to',
    name: 'Gift, grant, or capital contribution to'
  },
  {
    value: 'Gift, grant, or capital contribution from',
    name: 'Gift, grant, or capital contribution from'
  },
  {
    value: 'Loans or loan guarantees to or for',
    name: 'Loans or loan guarantees to or for'
  },
  { value: 'Loans or loan guarantees by', name: 'Loans or loan guarantees by' },
  { value: 'Dividends from', name: 'Dividends from' },
  { value: 'Sale of assets to', name: 'Sale of assets to' },
  { value: 'Purchase of assets from', name: 'Purchase of assets from' },
  { value: 'Exchange of assets with', name: 'Exchange of assets with' },
  {
    value: 'Lease of facilities, equipment, or other assets to',
    name: 'Lease of facilities, equipment, or other assets to'
  },
  {
    value: 'Lease of facilities, equipment, or other assets from',
    name: 'Lease of facilities, equipment, or other assets from'
  },
  { value: 'Performance of services for', name: 'Performance of services for' },
  { value: 'Performance of services by', name: 'Performance of services by' },
  {
    value: 'Membership or fundraising solicitations for',
    name: 'Membership or fundraising solicitations for'
  },
  {
    value: 'Membership or fundraising solicitations by',
    name: 'Membership or fundraising solicitations by'
  },
  {
    value:
      'Sharing of facilities, equipment, mailing lists, or other assets with',
    name: 'Sharing of facilities, equipment, mailing lists, or other assets with'
  },
  {
    value: 'Sharing of paid employees with',
    name: 'Sharing of paid employees with'
  },
  {
    value: 'Reimbursement for expenses paid to',
    name: 'Reimbursement for expenses paid to'
  },
  {
    value: 'Reimbursement for expenses paid by',
    name: 'Reimbursement for expenses paid by'
  },
  {
    value: 'Other transfer of cash or property to',
    name: 'Other transfer of cash or property to'
  },
  {
    value: 'Other transfer of cash or property from',
    name: 'Other transfer of cash or property from'
  }
];

const CORP_TRUST_TYPE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'C Corp', name: 'C Corp' },
  { value: 'S Corp', name: 'S Corp' },
  { value: 'Trust', name: 'Trust' }
];

const PARTNERSHIP_INCOME_TYPE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Related', name: 'Related' },
  { value: 'Unrelated', name: 'Unrelated' },
  { value: 'Investment', name: 'Investment' }
];

const HOSPITAL_MEDICAL_REVENUE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: 'Cost accounting', name: 'Cost accounting' },
  { value: 'Cost to charge ratio', name: 'Cost-to-charge ratio' },
  { value: 'Other', name: 'Other' }
];

const HOSPITAL_FAP_FREE_CARE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: '100%', name: '100%' },
  { value: '150%', name: '150%' },
  { value: '200%', name: '200%' },
  { value: 'Other', name: 'Other' }
];

const HOSPITAL_FAP_DISCOUNT_CARE = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  { value: '200%', name: '200%' },
  { value: '250%', name: '250%' },
  { value: '300%', name: '300%' },
  { value: '350%', name: '350%' },
  { value: '400%', name: '400%' },
  { value: 'Other', name: 'Other' },
];

const HOSPITAL_FAP_FACILITIES = [
  {
    value: STRINGS.DROP_DOWN_DEFAULT_VALUE,
    name: STRINGS.DROP_DOWN_DEFAULT_NAME
  },
  {
    value: 'Applied uniformly to all hospital facilities',
    name: 'Applied uniformly to all hospital facilities'
  },
  {
    value: 'Applied uniformly to most hospital facilities',
    name: 'Applied uniformly to most hospital facilities'
  },
  {
    value: 'Generally tailored to individual hospital facilities',
    name: 'Generally tailored to individual hospital facilities'
  }
];

const HOSPITAL_GROUPING = [
  { value: 'A', name: 'A' },
  { value: 'B', name: 'B' },
  { value: 'C', name: 'C' },
  { value: 'D', name: 'D' },
  { value: 'E', name: 'E' },
  { value: 'F', name: 'F' },
  { value: 'G', name: 'G' },
  { value: 'H', name: 'H' },
  { value: 'I', name: 'I' },
  { value: 'J', name: 'J' },
  { value: 'K', name: 'K' },
  { value: 'L', name: 'L' },
  { value: 'M', name: 'M' },
  { value: 'N', name: 'N' },
  { value: 'O', name: 'O' },
  { value: 'P', name: 'P' },
  { value: 'Q', name: 'Q' },
  { value: 'R', name: 'R' },
  { value: 'S', name: 'S' },
  { value: 'T', name: 'T' },
  { value: 'U', name: 'U' },
  { value: 'V', name: 'V' },
  { value: 'W', name: 'W' },
  { value: 'X', name: 'X' },
  { value: 'Y', name: 'Y' },
  { value: 'Z', name: 'Z' }
];

export {
  FORM_SIGNER_TYPE,
  TAX_TYPE,
  ACCOUNT_TYPE,
  CHARITY_STATUSES,
  EXEMPT_STATUSES,
  RELATIONSHIP,
  STATES,
  SELECTSTATES,
  SELECTFILINGSTATES,
  DOCUMENT_TYPE,
  HOLDER_TYPE_RADIO,
  PROVINCES_CODE,
  YESNO,
  TOFROM,
  YESNO_RADIO,
  HOSPITAL_FAP_FACILITIES,
  HOSPITAL_FAP_FREE_CARE,
  HOSPITAL_FAP_DISCOUNT_CARE,
  YESNONA,
  FOREIGN_ASSETS_COUNTRIES,
  FOREIGN_ASSETS_DISPOSITION_COUNTRIES,
  FOREIGN_ASSETS_STATES_CODES,
  MEX_STATES_CODE,
  TABLE_OR_ATTACH,
  CONTRIBUTION_TYPE,
  COMP_POSITION,
  FSRPTACCTMTHDPY_OPTIONS,
  ASSET_LIQ_TYPE,
  INDEPENDENT_CONTRACTOR_COUNTRIES,
  BASIC_DATA_COUNTRIES,
  BASIC_DATA_STATES,
  BASIC_DATA_STATES_AND_PROV,
  BASIC_DATA_PROVINCES,
  TAX_COMPLIANCE_COUNTRIES,
  CLIENT_SETUP_CLA_COMP_RVW_AUD,
  CLIENT_SETUP_FORM_ORG,
  CONTRIBUTIONS_ITEM_TYPE,
  SUPPORTED_ORG_TYPES,
  SUPPORTED_ORG_ACTIONS,
  PUBLIC_CHARITY_COUNTRIES,
  FSRPTACCTREVBASIS_OPTIONS,
  CONTRIBUTOR_TYPE,
  GRNT_OUT_US_CHRTY_TYPE,
  GRNT_OUT_US_REGION,
  GRNT_OUT_US_DISBURS_MTHD,
  GRNT_OUT_US_VAL_METH,
  GAME_MANAGER_POSITION,
  ACTIVITIES_OUTSIDEUS_TYPE,
  REL_ORG_TYPE,
  UNREL_INCOME_TYPE,
  REL_ORG_TRANS_TYPE,
  CORP_TRUST_TYPE,
  PARTNERSHIP_INCOME_TYPE,
  PUBLIC_CHARITY_STATUS,
  HOSPITAL_MEDICAL_REVENUE,
  HOSPITAL_GROUPING,
  BASIC_DATA_PROVINCES_CANADA_12,
  EXEMPT_STATUSES_BY_NUMBER
};
