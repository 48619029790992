import { useState, useEffect } from 'react';
import { Validators } from 'cla-formrenderer';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import {
  triggeredEvent,
  sectionFieldLogic,
} from './taxExemptBondsLogicTrigger';
import * as NAV from '@utilities/constants/navigation';
import {
  pullFieldValue,
  pullFilteredFieldValue,
} from '@utilities/pullFieldValue';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

const basicBondInfoSections = [
  {
    title: 'About Issuer',
    isVisible: true,
    sectionId: 1,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'Issuer Name',
            name: 'taxExBondIssNameLabel',
            type: 'label',
            col: 3,
          },
          {
            label: 'Issuer Name',
            name: 'taxExBondIssName',
            type: 'freeText',
            maxLength: 75,
            col: 3,
            logicFunction: [{ trigger: 'syncAboutIssuer' }],
            ...pullFieldValue(['XFDS99K1.1'], true),
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label: 'Issuer EIN',
            name: 'taxExBondIssEINLabel',
            type: 'label',
            col: 3,
          },
          {
            label: 'Issuer EIN',
            name: 'taxExBondIssEIN',
            type: 'ein',
            col: 3,
            logicFunction: [{ trigger: 'syncAboutIssuer' }],
            prior: true,
            validations: Validators.ein(),
            ...pullFilteredFieldValue('XFDS99K1.2', true, '-'),
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        fields: [
          {
            label: 'CUSIP #',
            name: 'taxExBondIssCUSIPLabel',
            type: 'label',
            col: 3,
          },
          {
            label: 'CUSIP #',
            name: 'taxExBondIssCUSIP',
            type: 'freeText',
            maxLength: 9,
            col: 3,
            logicFunction: [{ trigger: 'syncAboutIssuer' }],
            ...pullFieldValue(['XFDS99K1.3'], true),
          },
        ],
      },
    ],
  },
  {
    title: 'Issuer Details',
    isVisible: true,
    sectionId: 2,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'Date Issued',
            name: 'taxExBondIssDateLabel',
            type: 'label',
            col: 3,
          },
          {
            label: 'Date Issued',
            name: 'taxExBondIssDate',
            type: 'date',
            col: 3,
            logicFunction: [{ trigger: 'syncIssuerDetails' }],
            ...pullFieldValue(['XFDS99K1.4'], true),
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label: 'Issue Price',
            name: 'taxExBondIssPriceLabel',
            type: 'label',
            col: 3,
          },
          {
            label: 'Issue Price',
            name: 'taxExBondIssPrice',
            type: 'money',
            col: 3,
            logicFunction: [{ trigger: 'syncIssuerDetails' }],
            ...pullFieldValue(['XFDS99K1.5'], true),
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        fields: [
          {
            label: 'Description of Purpose',
            name: 'taxExBondDescLabel',
            type: 'label',
            col: 12,
            class: 'labelTitleTextArea',
          },
          {
            label: 'Description of Purpose',
            name: 'taxExBondDesc',
            type: 'textarea',
            maxLength: 75,
            col: 12,
            maxRows: 3,
            class: 'fieldTextArea',
            logicFunction: [{ trigger: 'syncIssuerDetails' }],
            ...pullFieldValue(['XFDS99K1.8'], true),
          },
        ],
      },
    ],
  },
  {
    title: 'Other Details',
    isVisible: true,
    sectionId: 3,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: '	Defeased?',
            name: 'taxExBondDefeaseLabel',
            type: 'label',
            col: 3,
          },
          {
            label: '	Defeased?',
            name: 'taxExBondDefease',
            type: 'select',
            lookup: 'YESNO',
            col: 3,
            logicFunction: [{ trigger: 'syncIssuerOtherDetails' }],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.6'],
                fn: 'checkboxToYNPull',
              },
            },
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label: 'On behalf of issuer?',
            name: 'taxExBondOnBehalfLabel',
            type: 'label',
            col: 3,
          },
          {
            label: 'On behalf of issuer?',
            name: 'taxExBondOnBehalf',
            type: 'select',
            lookup: 'YESNO',
            col: 3,
            logicFunction: [{ trigger: 'syncIssuerOtherDetails' }],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.7'],
                fn: 'checkboxToYNPull',
              },
            },
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        fields: [
          {
            label: 'Pooled financing?',
            name: 'taxExBondPooledLabel',
            type: 'label',
            col: 3,
          },
          {
            label: 'Pooled financing?',
            name: 'taxExBondPooled',
            type: 'select',
            lookup: 'YESNO',
            col: 3,
            logicFunction: [{ trigger: 'syncIssuerOtherDetails' }],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.43'],
                fn: 'checkboxToYNPull',
              },
            },
          },
        ],
      },
    ],
  },
];

const useOfProceedsSections = [
  {
    title: 'Retired and Defeased',
    isVisible: true,
    sectionId: 1,
    isCollapsed: true,
    isDenseRow: true,
    hasNoLineItem: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'Amount Retired',
            name: 'taxExBondAmtRetLabel',
            type: 'label',
            col: 4,
          },
          {
            label: '',
            name: 'taxExBondAmtRet',
            type: 'money',
            col: 2,
            ...pullFieldValue(['XFDS99K1.44'], true),
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label: 'Amount Defeased',
            name: 'taxExBondAmtDefLabel',
            type: 'label',
            col: 4,
          },
          {
            label: '',
            name: 'taxExBondAmtDef',
            type: 'money',
            col: 2,
            ...pullFieldValue(['XFDS99K1.45'], true),
          },
        ],
      },
    ],
  },
  {
    title: 'Proceeds Details',
    isVisible: true,
    sectionId: 2,
    isCollapsed: true,
    isDenseRow: true,
    hasNoLineItem: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'Total Proceeds',
            name: 'taxExBondTotProceedLabel',
            type: 'label',
            col: 3,
          },
          {
            label: '',
            name: 'taxExBondTotProceed',
            type: 'money',
            col: 3,
            logicFunction: [{ trigger: 'calculateVariance' }],
            ...pullFieldValue(['XFDS99K1.9'], true),
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label: 'Gross Proceeds in Reserve',
            name: 'taxExBondGrossProInRsvLabel',
            type: 'label',
            col: 3,
          },
          {
            label: '',
            name: 'taxExBondGrossProInRsv',
            type: 'money',
            col: 3,
            logicFunction: [{ trigger: 'calculateVariance' }],
            ...pullFieldValue(['XFDS99K1.10'], true),
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        fields: [
          {
            label: 'Capitalized Interest from Proceeds',
            name: 'taxExBondCapIntLabel',
            type: 'label',
            col: 3,
          },
          {
            label: '',
            name: 'taxExBondCapInt',
            type: 'money',
            col: 3,
            logicFunction: [{ trigger: 'calculateVariance' }],
            ...pullFieldValue(['XFDS99K1.46'], true),
          },
        ],
      },
      {
        groupId: 4,
        isVisible: true,
        fields: [
          {
            label: 'Proceeds in Refunding Escrows',
            name: 'taxExBondRefundEscrwLabel',
            type: 'label',
            col: 3,
          },
          {
            label: '',
            name: 'taxExBondRefundEscrw',
            type: 'money',
            col: 3,
            logicFunction: [{ trigger: 'calculateVariance' }],
            ...pullFieldValue(['XFDS99K1.11'], true),
          },
        ],
      },
      {
        groupId: 5,
        isVisible: true,
        fields: [
          {
            label: 'Issuance Costs From Proceeds',
            name: 'taxExBondIssCostLabel',
            type: 'label',
            col: 3,
          },
          {
            label: '',
            name: 'taxExBondIssCost',
            type: 'money',
            col: 3,
            logicFunction: [{ trigger: 'calculateVariance' }],
            ...pullFieldValue(['XFDS99K1.13'], true),
          },
        ],
      },
      {
        groupId: 6,
        isVisible: true,
        fields: [
          {
            label: 'Credit Enhancement from Proceeds',
            name: 'taxExBondCreditEnhncLabel',
            type: 'label',
            col: 3,
          },
          {
            label: '',
            name: 'taxExBondCreditEnhnc',
            type: 'money',
            col: 3,
            logicFunction: [{ trigger: 'calculateVariance' }],
            ...pullFieldValue(['XFDS99K1.47'], true),
          },
        ],
      },
      {
        groupId: 7,
        isVisible: true,
        fields: [
          {
            label: 'Working Capital Expend from Proceeds',
            name: 'taxExBondWorkCapLabel',
            type: 'label',
            col: 3,
          },
          {
            label: '',
            name: 'taxExBondWorkCap',
            type: 'money',
            col: 3,
            logicFunction: [{ trigger: 'calculateVariance' }],
            ...pullFieldValue(['XFDS99K1.14'], true),
          },
        ],
      },
      {
        groupId: 8,
        isVisible: true,
        fields: [
          {
            label: 'Capital Expend from Proceeds',
            name: 'taxExBondCapExpndLabel',
            type: 'label',
            col: 3,
          },
          {
            label: '',
            name: 'taxExBondCapExpnd',
            type: 'money',
            col: 3,
            logicFunction: [{ trigger: 'calculateVariance' }],
            ...pullFieldValue(['XFDS99K1.15'], true),
          },
        ],
      },
      {
        groupId: 9,
        isVisible: true,
        fields: [
          {
            label: 'Other Spent Proceeds',
            name: 'taxExBondOthSpentLabel',
            type: 'label',
            col: 3,
          },
          {
            label: '',
            name: 'taxExBondOthSpent',
            type: 'money',
            col: 3,
            logicFunction: [{ trigger: 'calculateVariance' }],
            ...pullFieldValue(['XFDS99K1.48'], true),
          },
        ],
      },
      {
        groupId: 12,
        isVisible: true,
        isOptionalGroupMerge: true,
        fields: [
          {
            label: 'Other Unspent Proceeds',
            name: 'taxExBondOthUnspentLabel',
            type: 'label',
            col: 3,
          },
          {
            label: '',
            name: 'taxExBondOthUnspent',
            type: 'money',
            col: 3,
            logicFunction: [{ trigger: 'calculateVariance' }],
            ...pullFieldValue(['XFDS99K1.12'], true),
          },
        ],
      },
      {
        groupId: 10,
        isVisible: false,
        fields: [
          {
            label: 'Variance',
            name: 'taxExBondProcVarLabel',
            type: 'label',
            col: 3,
          },
          {
            label: '',
            name: 'taxExBondProcVar',
            type: 'readOnly',
            isReadOnlyAmount: true,
            default: '$0',
            col: 3,
          },
        ],
      },
      {
        groupId: 11,
        isVisible: false,
        fields: [
          {
            label: 'Explanation',
            name: 'taxExBondProcVarExplLabel',
            type: 'label',
            col: 12,
            class: 'labelTitleTextArea',
          },
          {
            label: 'Explanation',
            name: 'taxExBondProcVarExpl',
            type: 'textarea',
            col: 12,
            maxRows: 3,
            class: 'fieldTextArea',
          },
        ],
      },
    ],
  },
  {
    title: 'Bond Details',
    isVisible: true,
    sectionId: 3,
    isCollapsed: true,
    isDenseRow: true,
    hasNoLineItem: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'For each Tax-Exempt Bond, fill-in if applicable:',
            name: 'staticLabel',
            type: 'label',
            col: 4,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label: 'Year of Substantial Complete',
            name: 'taxExBondYrSubCmpltLabel',
            type: 'label',
            col: 4,
          },
          {
            label: 'Year (yyyy)',
            name: 'taxExBondYrSubCmplt',
            type: 'yearPicker',
            col: 2,
            ...pullFieldValue(['XFDS99K1.16'], true),
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        fields: [
          {
            label: `
							Bonds were issued as part of a refunding issue of tax-exempt 
              bonds (or prior to 2018, a current refunding issue):				
						`,
            name: 'taxExBondRefundExmptLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondRefundExmpt',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            prior: true,
            isPriorEditable: false,
            axcess: {
              pull: {
                fields: ['XFDS99K1.17'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 4,
        isVisible: true,
        fields: [
          {
            label: `
							Bonds were issued as part of a refunding issue of 
              taxable bonds (or prior to 2018, a current refunding issue):				
						`,
            name: 'taxExBondRefundTaxableLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondRefundTaxable',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            prior: true,
            isPriorEditable: false,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99K1.18'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 5,
        isVisible: true,
        fields: [
          {
            label: `
							Final allocation of proceeds have been made:					
						`,
            name: 'taxExBondFinalAllocLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondFinalAlloc',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            prior: true,
            isPriorEditable: false,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99K1.19'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
    ],
  },
  {
    title: 'Books & Records Details',
    isVisible: true,
    sectionId: 4,
    isCollapsed: true,
    isDenseRow: true,
    hasNoLineItem: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'For each Tax-Exempt Bond, fill-in if applicable:',
            name: 'staticLabel',
            type: 'label',
            col: 4,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label: `
							Organization maintain adequate books & records 
              to support the final allocation of proceeds:				
						`,
            name: 'taxExBondBookMaintLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondBookMaint',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            prior: true,
            isPriorEditable: false,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99K1.20'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
    ],
  },
];

const privateBusinessUseSections = [
  {
    title: 'About Financial Property',
    isVisible: true,
    sectionId: 1,
    isCollapsed: true,
    isDenseRow: true,
    hasNoLineItem: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'For each Tax-Exempt Bond, fill-in if applicable:',
            name: 'staticLabel',
            type: 'label',
            col: 4,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label: `The organization was a partner in a partnership, or a 
            member of an LLC, that owned property financed by tax-exempt bonds:`,
            name: 'taxExBondOrgPartnerLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondOrgPartner',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.21'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        fields: [
          {
            label: `There are lease arrangements with respect to the 
            financed property that may result in private business use:`,
            name: 'taxExBondLeaseArrangeLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondLeaseArrange',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            logicFunction: [{ trigger: 'showTaxExBondLeaseCnslRvw' }],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.22'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 4,
        isVisible: false,
        fields: [
          {
            label: `Your bond counsel reviewed the lease arrangements:`,
            name: 'taxExBondLeaseCnslRvwLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondLeaseCnslRvw',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
          },
        ],
      },
      {
        groupId: 5,
        isVisible: true,
        fields: [
          {
            label: `There are management or service contracts that may 
            result in private business use of bond-financed property:`,
            name: 'taxExBondMgmtCntrctLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondMgmtCntrct',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            logicFunction: [{ trigger: 'showTaxExBondMgmtSvcCnslRvw' }],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.23'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 6,
        isVisible: false,
        fields: [
          {
            label: `Your bond counsel routinely reviews the management and service contract(s):`,
            name: 'taxExBondMgmtSvcCnslRvwLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondMgmtSvcCnslRvw',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.53'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 7,
        isVisible: true,
        fields: [
          {
            label: `There are research agreements that may result in private business use of bond-financed property:`,
            name: 'taxExBondResearchAgrLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondResearchAgr',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            logicFunction: [{ trigger: 'showTaxExBondResearchCnslRvw' }],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.24'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 8,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: `Your bond counsel routinely reviews the research agreements:`,
            name: 'taxExBondResearchCnslRvwLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondResearchCnslRvw',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.25'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 9,
        isVisible: false,
        hasDivider: true,
        groupType: 'lineItem',
        uploadType: 'drawer',
        class: 'householdEmploymentUpload',
        fields: [
          {
            label: 'Upload a copy of the lease agreement(s)',
            name: 'taxExBondLeaseAttachLabel',
            type: 'label',
            col: 9,
          },
          { type: 'lineButtons', col: 3 },
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [],
          footerLabels: [],
          lineButtons: [
            {
              button: 'uploadOnly',
              uploadSectionName: 'taxExBondLeaseAttach',
              viewSectionId: 0,
            },
          ],
        },
      },
      {
        groupId: 10,
        isVisible: false,
        hasDivider: true,
        groupType: 'lineItem',
        uploadType: 'drawer',
        class: 'householdEmploymentUpload',
        fields: [
          {
            label: 'Upload a copy of the management or service contracts',
            name: 'taxExBondSrvcAttachLabel',
            type: 'label',
            col: 9,
          },
          { type: 'lineButtons', col: 3 },
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [],
          footerLabels: [],
          lineButtons: [
            {
              button: 'uploadOnly',
              uploadSectionName: 'taxExBondSrvcAttach',
              viewSectionId: 0,
            },
          ],
        },
      },
    ],
  },
  {
    title: 'Financial Property Details',
    isVisible: true,
    sectionId: 2,
    isCollapsed: true,
    isDenseRow: true,
    hasNoLineItem: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'For each Tax-Exempt Bond, fill-in if applicable:',
            name: 'staticLabel',
            type: 'label',
            col: 4,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label: `The Organization would like assistance from CLA in calculating 
            the percentage of financed property used for private use:`,
            name: 'taxExBondPcntClaCalcLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondPcntClaCalc',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        fields: [
          {
            label: `Enter the percentage of financed property used in a private business 
            use by entities other than a 501(c)(3) organization or a state or local government:`,
            name: 'taxExBondPcntPvtBusLabel',
            type: 'label',
            col: 4,
          },
          {
            label: 'Percentage',
            name: 'taxExBondPcntPvtBus',
            type: 'percent',
            col: 2,
            logicFunction: [{ trigger: 'showTaxExBondPcntByNonC3Calc' }],
            validations: { ...Validators.maxValue(100), },
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.27'],
                fn: 'anyValidPull',
              },
            },
          },
        ],
      },
      {
        groupId: 4,
        isVisible: true,
        fields: [
          {
            label: `Enter the percentage of financed property used in a private business use as a 
            result of unrelated business trade or business activity carried on by your organization, 
            another 501(c)(3) organization, or a state or local government:`,
            name: 'taxExBondPcntUnrBusLabel',
            type: 'label',
            col: 4,
          },
          {
            label: 'Percentage',
            name: 'taxExBondPcntUnrBus',
            type: 'percent',
            col: 2,
            logicFunction: [{ trigger: 'showTaxExBondPcntByNonC3Calc' }],
            validations: { ...Validators.maxValue(100), },
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.28'],
                fn: 'anyValidPull',
              },
            },
          },
        ],
      },
      {
        groupId: 5,
        isVisible: true,
        fields: [
          {
            label: `More than 5% of the proceeds of the bond issue are used in a private business 
            (including use by the federal government) and more than 5% of the debt service on the 
            bonds is secured by an interest in property used in a private trade or business or 
            derived from payments with respect to said property:`,
            name: 'taxExBondPassPrvtSecTestLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondPassPrvtSecTest',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
          },
        ],
      },
      {
        groupId: 6,
        isVisible: true,
        fields: [
          {
            label: `There has been a sale or disposition one or more of the bond-financed property 
            to a non-governmental person other than a 501(c)(3) organization since the bonds were issued:`,
            name: 'taxExBondPropSaleLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondPropSale',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            logicFunction: [{ trigger: 'showTaxExBondPropSalePctg' }],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.59'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 7,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: `Enter the percentage of bond-financed property sold or disposed of:`,
            name: 'taxExBondPropSalePctgLabel',
            type: 'label',
            col: 4,
          },
          {
            label: 'Percentage',
            name: 'taxExBondPropSalePctg',
            type: 'percent',
            col: 2,
            prior: true,
            isPriorEditable: true,
            validations: { ...Validators.maxValue(100), },
            axcess: {
              pull: {
                fields: ['XFDS99K1.60'],
                fn: 'anyValidPull',
              },
            },
          },
        ],
      },
      {
        groupId: 8,
        isVisible: false,
        hasDivider: true,
        groupType: 'lineItem',
        uploadType: 'drawer',
        class: 'householdEmploymentUpload',
        fields: [
          {
            label:
              'Upload calculation and description regarding private business use and /or UBI',
            name: 'taxExBondPcntByNonC3CalcLabel',
            type: 'label',
            col: 9,
          },
          { type: 'lineButtons', col: 3 },
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [],
          footerLabels: [],
          lineButtons: [
            {
              button: 'uploadOnly',
              uploadSectionName: 'taxExBondPcntByNonC3Calc',
              viewSectionId: 0,
            },
          ],
        },
      },
    ],
  },
  {
    title: 'Remedial Details',
    isVisible: true,
    sectionId: 3,
    isCollapsed: true,
    isDenseRow: true,
    hasNoLineItem: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'For each Tax-Exempt Bond, fill-in if applicable:',
            name: 'staticLabel',
            type: 'label',
            col: 4,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label: `Remedial action was taken pursuant to Regulations sections 1.141-12 and 1.145-2:`,
            name: 'taxExBondPropSaleRmdlLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBondPropSaleRmdl',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            default: false,
            isFieldCheckbox: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.61'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
    ],
  },
];

const reinvestmentBondProceedsSections = [
  {
    title: 'Bond Details',
    isVisible: true,
    sectionId: 1,
    isCollapsed: true,
    isDenseRow: true,
    hasNoLineItem: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'For each Tax-Exempt Bond, fill-in if applicable:',
            name: 'staticLabel',
            type: 'label',
            col: 4,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label: 'The bond issue is a variable rate issue:',
            name: 'taxExBndArbVrblRateLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBndArbVrblRate',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.33'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
    ],
  },
  {
    title: 'Rebate Details',
    isVisible: true,
    sectionId: 2,
    isCollapsed: true,
    isDenseRow: true,
    hasNoLineItem: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'For each Tax-Exempt Bond, fill-in if applicable:',
            name: 'staticLabel',
            type: 'label',
            col: 4,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label:
              'A Form 8038-T has been filed with respect to the bond issue:',
            name: 'taxExBndArbForm8038TLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBndArbForm8038T',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            logicFunction: [{ trigger: 'showTaxExBndArbRbtNADescr' }, { trigger: 'showRebateFields' }],
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        fields: [
          {
            label: 'Rebate is not due yet:',
            name: 'taxExBndArbRbtNotYetLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBndArbRbtNotYet',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            logicFunction: [{ trigger: 'showTaxExBndArbRbtNADescr' }],
          },
        ],
      },
      {
        groupId: 4,
        isVisible: true,
        fields: [
          {
            label: 'Rebate exception:',
            name: 'taxExBndArbRbtExcptnLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBndArbRbtExcptn',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            logicFunction: [{ trigger: 'showTaxExBndArbRbtNADescr' }],
          },
        ],
      },
      {
        groupId: 5,
        isVisible: true,
        fields: [
          {
            label: 'No rebate due:',
            name: 'taxExBndArbRbtNotDueLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBndArbRbtNotDue',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            logicFunction: [
              { trigger: 'showTaxExBndArbRbtNotDueDateAndUpload' },
              { trigger: 'showTaxExBndArbRbtNADescr' }
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.57'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 6,
        isVisible: true,
        fields: [
          {
            label: `If you do not believe any of the above are applicable, 
            please enter explanation in the space provided or if you need 
            assistance note that in the explanation.`,
            name: 'taxExBndArbRbtNADescrLabel',
            type: 'label',
            col: 12,
            class: 'labelTitleTextArea',
          },
          {
            label: 'Explanation',
            name: 'taxExBndArbRbtNADescr',
            type: 'textarea',
            col: 12,
            maxRows: 3,
            class: 'fieldTextArea',
          },
        ],
      },
      {
        groupId: 7,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'The rebate computation was performed on (date):',
            name: 'taxExBndArbRbtNotDueDateLabel',
            type: 'label',
            col: 4,
          },
          {
            label: 'Select date',
            name: 'taxExBndArbRbtNotDueDate',
            type: 'date',
            col: 2,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.63'],
                fn: 'anyValidPull',
              },
            },
          },
        ],
      },
      //WARNING!!: groupId: 8 can't be used for future added group/s in this section for this form version as group id 8 was previously defined and used before, possible data loss and form reset will happen on forms with TEB data 
      // {
      //   groupId: 8,
      //   isVisible: false,
      //   fields: [
      //     {
      //       // label: 'Upload a copy of the rebate calculation',
      //       // name: 'taxExBndArbRbtNotDueCalc',
      //       // type: 'label',
      //       // col: 4,
      //     },
      //   ],
      // },
      {
        groupId: 9,
        isVisible: false,
        isOptionalGroupMerge: true,
        groupType: 'lineItem',
        uploadType: 'drawer',
        class: 'householdEmploymentUpload',
        fields: [
          {
            label: 'Upload a copy of the rebate calculation',
            name: 'taxExBndArbRbtNotDueCalc',
            type: 'label',
            col: 4,
          },
          { type: 'lineButtons', col: 3 }
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [],
          footerLabels: [],
          lineButtons: [
            {
              button: 'uploadOnly',
              uploadSectionName: 'taxExBndArbRbtNotDueCalcAttach',
              viewSectionId: 0
            }
          ]
        }
      },
    ],
  },
  {
    title: 'Hedge Details',
    isVisible: true,
    sectionId: 3,
    isCollapsed: true,
    isDenseRow: true,
    hasNoLineItem: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'For each Tax-Exempt Bond, fill-in if applicable:',
            name: 'staticLabel',
            type: 'label',
            col: 4,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label:
              'The issuer has entered into a qualified hedge with respect to the bond issue:',
            name: 'taxExBndArbHedgeLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBndArbHedge',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            logicFunction: [{ trigger: 'showAllHedgeDetailFields' }],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.34'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 3,
        isVisible: false,
        fields: [
          {
            label: 'Name of Provider:',
            name: 'taxExBndArbHedgeNameLabel',
            type: 'label',
            col: 4,
          },
          {
            label: 'Provider',
            name: 'taxExBndArbHedgeName',
            type: 'freeText',
            maxLength: 75,
            col: 2,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.29'],
                fn: 'anyValidPull',
              },
            },
          },
        ],
      },
      {
        groupId: 4,
        isVisible: false,
        fields: [
          {
            label: 'Term of Hedge (rounded to nearest 1/10 year):',
            name: 'taxExBndArbHedgeTermLabel',
            type: 'label',
            col: 4,
          },
          {
            label: 'Term of Hedge',
            name: 'taxExBndArbHedgeTerm',
            type: 'percent',
            col: 2,
            hasNoPercentSign: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.35'],
                fn: 'anyValidPull',
              },
            },
          },
        ],
      },
      {
        groupId: 5,
        isVisible: false,
        fields: [
          {
            label: 'Hedge was super-integrated:',
            name: 'taxExBndArbHedgeSprIntLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBndArbHedgeSprInt',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.49'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 6,
        isVisible: false,
        fields: [
          {
            label: 'Hedge was terminated:',
            name: 'taxExBndArbHedgeTrmdLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBndArbHedgeTrmd',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.50'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
    ],
  },
  {
    title: 'Gross Proceeds Details',
    isVisible: true,
    sectionId: 4,
    isCollapsed: true,
    isDenseRow: true,
    hasNoLineItem: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label: 'For each Tax-Exempt Bond, fill-in if applicable:',
            name: 'staticLabel',
            type: 'label',
            col: 4,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        fields: [
          {
            label:
              'The gross proceeds were invested in a Guaranteed Investment Contract (GIC):',
            name: 'taxExBndArbGICLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBndArbGIC',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            logicFunction: [{ trigger: 'showGrossProceedsFields' }],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.36'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 3,
        isVisible: false,
        fields: [
          {
            label: 'Name of Provider:',
            name: 'taxExBndArbGICNameLabel',
            type: 'label',
            col: 4,
          },
          {
            label: 'Provider',
            name: 'taxExBndArbGICName',
            type: 'freeText',
            col: 2,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.30'],
                fn: 'anyValidPull',
              },
            },
          },
        ],
      },
      {
        groupId: 4,
        isVisible: false,
        fields: [
          {
            label: 'Remaining term of GIC (rounded to nearest 1/10 year):',
            name: 'taxExBndArbGICTermLabel',
            type: 'label',
            col: 4,
          },
          {
            label: 'Term of Hedge',
            name: 'taxExBndArbGICTerm',
            type: 'percent',
            col: 2,
            hasNoPercentSign: true,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.31'],
                fn: 'anyValidPull',
              },
            },
          },
        ],
      },
      {
        groupId: 5,
        isVisible: false,
        fields: [
          {
            label:
              'The regulatory safe harbor for establishing the fair market value of the GIC was satisfied:',
            name: 'taxExBndArbGICSafeHbrLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBndArbGICSafeHbr',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.37'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
      {
        groupId: 6,
        isVisible: true,
        fields: [
          {
            label:
              'Any gross proceeds were invested beyond an available temporary period:',
            name: 'taxExBndArbInvByndTempLabel',
            type: 'label',
            col: 4,
            class: 'labelSingleCheckbox',
          },
          {
            label: 'Yes',
            name: 'taxExBndArbInvByndTemp',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            default: false,
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDS99K1.38'],
                fn: 'checkboxPull',
              },
            },
          },
        ],
      },
    ],
  },
];

export const sections = [
  {
    title: 'General Information',
    isVisible: true,
    sectionId: 1,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label:
              'This section should be reviewed with bond counsel prior to submitting the 990 Exchange to CLA',
            name: 'generalInfoLabel',
            type: 'label',
            col: 10,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              "How many tax-exempt bond issues are outstanding with a principal amount of more than $100,000 as of the end of the organization's year?",
            name: 'taxExBondIssuesCountLabel',
            type: 'label',
            col: 10,
          },
          {
            label: 'No of Tax Exempt Bond Issues',
            name: 'taxExBondIssuesCount',
            type: 'total',
            col: 2,
            ...pullFieldValue(['XFDS99K1.0'], true),
          },
        ],
      },
      {
        groupId: 15,
        isVisible: false,
        hasDivider: false,
        isOptionalGroupMerge: true,
        fields: [
          {
            label:
              'Upload a copy of all Form 8038s for bonds reported on the prior year Sch K or fill-in box if forms not available',
            name: 'taxExBond8038sCcAttachPriorLabel',
            type: 'label',
            col: 11,
          },
          {
            label: '',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'taxExBond8038sCcAttachPrior',
            col: 1,
            class: 'singleCheckboxMargin',
           
            logicFunction: [
              { trigger: 'showTaxExBond8038sCcAttachUpload', isDifferentGroup: true },
            ],
          },
        ],
        },
         {
        groupId: 3,
        isVisible: false,
        hasDivider: true,
        groupType: 'lineItem',
        uploadType: 'drawer',
        class: 'householdEmploymentUpload',
        fields: [
          {
            label:
              'Provide a copy of all Form 8038s for bonds reported on the prior year Sch K',
            name: 'taxExBond8038sCcAttachLabel',
            type: 'label',
            col: 9,
          },
          { type: 'lineButtons', col: 3 },
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [],
          footerLabels: [],
          lineButtons: [
            {
              button: 'uploadOnly',
              uploadSectionName: 'taxExBond8038sCcAttach',
              viewSectionId: 0,
            },
          ],
        },
      },
      {
        groupId: 4,
        isVisible: false,
        hasDivider: true,
        groupType: 'lineItem',
        uploadType: 'drawer',
        class: 'householdEmploymentUpload',
        fields: [
          {
            label:
              'Upload a copy of all Form 8038s for new bonds not previously reported on Sch K',
            name: 'taxExBond8038sCcAttachNewLabel',
            type: 'label',
            col: 9,
          },
          { type: 'lineButtons', col: 3 },
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [],
          footerLabels: [],
          lineButtons: [
            {
              button: 'uploadOnly',
              uploadSectionName: 'taxExBond8038sCcAttachNew',
              viewSectionId: 0,
            },
          ],
        },
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              "May CLA contact the bond counsel regarding the organization's tax-exempt bonds?",
            name: 'taxExBondCnslOkContactLabel',
            type: 'label',
            col: 10,
          },
          {
            label: '',
            name: 'taxExBondCnslOkContact',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showTaxExBondCounsel', isDifferentGroup: true },
            ],
          },
        ],
      },
      {
        groupId: 6,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: "Provide the following for the organization's bond counsel:",
            name: 'orgBondCounselLabel',
            type: 'label',
            class: 'labelTitleTextArea',
            col: 12,
          },
          {
            label: 'Name',
            name: 'taxExBondCnslName',
            placeholder: 'name',
            type: 'freeText',
            col: 5,
            printFieldWidth: 300
          },
          {
            label: 'Address',
            name: 'taxExBondCnslAdd',
            type: 'freeText',
            col: 7,
            printFieldWidth: 360
          },
          {
            label: 'City',
            name: 'taxExBondCnslCity',
            type: 'freeText',
            col: 4,
            printFieldWidth: 240
          },
          {
            label: 'State',
            name: 'taxExBondCnslState',
            type: 'select',
            lookup: 'FOREIGN_ASSETS_STATES_CODES',
            col: 4,
            printFieldWidth: 260
          },
          { label: 'Zip', name: 'taxExBondCnslZip', type: 'zippostal', col: 4, printFieldWidth: 150 },
          {
            label: 'Phone Number',
            name: 'taxExBondCnslPhone',
            type: 'telephone',
            col: 4,
            printFieldWidth: 240
          },
          {
            label: 'Email Address',
            name: 'taxExBondCnslEmail',
            type: 'freeText',
            col: 4,
            printFieldWidth: 340
          },
        ],
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'At anytime during the fiscal year, the organization maintained an escrow account (other than a refunding escrow) to defease any tax-exempt bond(s):',
            name: 'taxExBondEscAcctLabel',
            type: 'label',
            col: 10,
          },
          {
            label: '',
            name: 'taxExBondEscAcct',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
          },
        ],
      },
      {
        groupId: 8,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Does the organization have a post-issuance compliancy policy?',
            name: 'taxExBondPostIssLabel',
            type: 'label',
            col: 10,
          },
          {
            label: '',
            name: 'taxExBondPostIss',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showTaxExBondField', isDifferentGroup: true },
            ],
          },
        ],
      },
      {
        groupId: 9,
        isVisible: false,
        hasDivider: true,
        groupType: 'lineItem',
        uploadType: 'drawer',
        class: 'householdEmploymentUpload',
        fields: [
          {
            label: 'Upload a copy of this policy',
            name: 'taxExBondPostIssUploadLabel',
            type: 'label',
            col: 9,
          },
          { type: 'lineButtons', col: 3 },
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [],
          footerLabels: [],
          lineButtons: [
            {
              button: 'uploadOnly',
              uploadSectionName: 'taxExBondPostIssUpload',
              viewSectionId: 0,
            },
          ],
        },
      },
      {
        groupId: 10,
        isVisible: false,
        fields: [
          {
            label:
              'Either the post-issuance bond compliance policy or another written policy includes the following provision(s):',
            name: 'taxExBondCnslOkContactLabel',
            type: 'label',
            col: 10,
          },
        ],
      },
      {
        groupId: 11,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Established written procedures to ensure that all nonqualified bonds of the issue are remediated in accordance with the requirements under Regulations sections 1.141-12 and 1.145-2:',
            name: 'taxExBondEstProcLabel',
            type: 'label',
            styles: {paddingLeft: '35px'},
            col: 10,
          },
          {
            label: '',
            name: 'taxExBondEstProc',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showTaxExBondAdoption', isDifferentGroup: true },
            ],
            prior: true,
            isPriorEditable: true,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99K1.62'],
                fn: 'checkboxToRadioPull',
              },
            },
          },
        ],
      },
      {
        groupId: 12,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              "Contained written procedures to ensure that violations of federal tax requirements are timely identified and corrected through the voluntary closing agreement program if self-remediation isn't available under applicable regulations:",
            name: 'taxExBondPostIssWrittenLabel',
            type: 'label',
            styles: {paddingLeft: '35px'},
            col: 10,
          },
          {
            label: '',
            name: 'taxExBondPostIssWritten',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showTaxExBondAdoption', isDifferentGroup: true },
            ],
            prior: true,
            isPriorEditable: true,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99K1.65'],
                fn: 'YToRadioPull',
              },
            },
          },
        ],
      },
      {
        groupId: 13,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Established written procedures to monitor the requirements of Section 148:',
            name: 'taxExBondWritProcMonLabel',
            type: 'label',
            styles: {paddingLeft: '35px'},
            col: 10,
          },
          {
            label: '',
            name: 'taxExBondWritProcMon',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
            logicFunction: [
              { trigger: 'showTaxExBondAdoption', isDifferentGroup: true },
            ],
            prior: true,
            isPriorEditable: true,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99K1.58'],
                fn: 'checkboxToRadioPull',
              },
            },
          },
        ],
      },
      {
        groupId: 14,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'The organization would like CLA to provide a sample policy to consider for adoption:',
            name: 'taxExBondPostIssWrittenAdptLabel',
            type: 'label',
            col: 10,
          },
          {
            label: '',
            name: 'taxExBondPostIssWrittenAdpt',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 2,
          },
        ],
      },
    ],
  },
  {
    title: 'Basic Bond Information',
    isVisible: true,
    hasDenseRow: true,
    sectionId: 2,
    groups: [
      {
        groupId: 1,
        groupType: 'lineItem',
        isVisible: true,
        with990DenseRows: true,
        hasTableManagerTabs: true,
        isH2TabsHidden: false,
        entityPlaceHolder: 'Bond',
        summaryAllType: 'Bonds',
        entityNameField: 'bondNameType',
        isMasterSummaryHidden: true,
        axcessGroup: {
          pull: {
            fromSections: [
              'Schedule K - Supplemental Information on Tax-Exempt Bonds',
            ],
            fieldPrefixes: ['XFDS99K1'],
            fieldIdentifiers: [
              'XFDS99K1.1',
              'XFDS99K1.2',
              'XFDS99K1.3',
              'XFDS99K1.4',
              'XFDS99K1.5',
              'XFDS99K1.8',
              'XFDS99K1.6',
              'XFDS99K1.7',
              'XFDS99K1.43',
            ],
          },
        },
        entityIdentifiers: [
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
        ],
        fields: [
          {
            label: '1',
            name: 'bondID',
            type: 'label',
            styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
          },
          {
            label: 'Issuer Name: \nIssuer EIN: \nCUSIP #:',
            issuerName: '',
            name: 'aboutIssuerSummary',
            type: 'label',
            styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
            prior: true,
            isPriorEditable: false,
            pullCalculation: {
              keyIdentifiers: ['XFDS99K1.1', 'XFDS99K1.2', 'XFDS99K1.3'],
              fn: 'taxExemptBondAboutIssuerLabelPull',
            },
          },
          {
            label: 'Date Issued: \nIssued Price: $',
            issuedDate: '',
            name: 'issueDetailsSummary',
            type: 'label',
            styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
            prior: true,
            isPriorEditable: false,
            pullCalculation: {
              keyIdentifiers: ['XFDS99K1.4', 'XFDS99K1.5'],
              fn: 'taxExemptBondIssueDetailsLabelPull',
            },
          },
          {
            label: '',
            name: 'descOfPurposeSummary',
            type: 'label',
            styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
            prior: true,
            isPriorEditable: false,
            pullCalculation: {
              keyIdentifiers: ['XFDS99K1.8'],
              fn: 'anyValidPull',
            },
          },
          {
            label: 'Defeased: \nOn Behalf of Issuer: \nPooled Financing: ',
            name: 'otherDetailsSummary',
            type: 'label',
            styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
            prior: true,
            isPriorEditable: false,
            pullCalculation: {
              keyIdentifiers: ['XFDS99K1.6', 'XFDS99K1.7', 'XFDS99K1.43'],
              fn: 'taxExemptBondOtherDetailsLabelPull',
            },
          },
          { type: 'lineButtons' },
        ],
        lineItems: [],
        entities: [],
        lineSections: basicBondInfoSections,
        currentEntity: null,
        subWorkSheets: [],
				headerLabelsPrintStyles: {gridTemplateColumns: '1.75fr 2fr 2fr 2fr 2fr 1fr'},
        lineItemDetails: {
          headerLabels: [
            {
              label: 'Bond ID',
              col: 2,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '48px' },
            },
            {
              label: 'About Issuer',
              col: 2,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '44px' },
            },
            {
              label: 'Issue Details',
              col: 2,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '25px' },
            },
            {
              label: 'Description of Purpose',
              col: 3,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '25px' },
            },
            {
              label: 'Other Details',
              col: 2,
              colTablet: 2,
              align: 'left',
              styles: { paddingLeft: '25px' },
            },
            { label: 'Actions', col: 1, colTablet: 2, align: 'left' },
          ],
          footerLabels: [
            {
              type: 'buttons',
              col: 12,
              buttonTitle: 'Add Another Bond',
              icon: 'add',
            },
          ],
          lineButtons: [{ button: 'remove' }],
        },
      },
    ],
  },
  {
    title: 'Use of Proceeds',
    isVisible: true,
    sectionId: 3,
    hasDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label:
              'This section should be reviewed with bond counsel prior to submitting the 990 Exchange to CLA',
            name: 'generalInfoLabel',
            type: 'label',
            col: 10,
          },
        ],
      },
      {
        groupId: 2,
        groupType: 'bondsItem',
        isVisible: true,
        with990DenseRows: true,
        hasTableManagerTabs: true,
        isH2TabsHidden: false,
        entityPlaceHolder: 'Bond',
        summaryAllType: 'Bonds',
        entityNameField: 'bondNameType',
        isMasterSummaryHidden: true,
        hideSummaryAll: true,
        axcessGroup: {
          pull: {
            fromSections: [
              'Schedule K - Supplemental Information on Tax-Exempt Bonds',
            ],
            fieldPrefixes: ['XFDS99K1'],
            fieldIdentifiers: [
              'XFDS99K1.0',
              'XFDS99K1.44',
              'XFDS99K1.45',
              'XFDS99K1.9',
              'XFDS99K1.10',
              'XFDS99K1.46',
              'XFDS99K1.11',
              'XFDS99K1.13',
              'XFDS99K1.47',
              'XFDS99K1.14',
              'XFDS99K1.15',
              'XFDS99K1.48',
              'XFDS99K1.12',
              'XFDS99K1.16',
              'XFDS99K1.17',
              'XFDS99K1.18',
              'XFDS99K1.19',
              'XFDS99K1.20',
            ],
          },
        },
        entityIdentifiers: [
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
        ],
        // entities: [],
        bondsSections: useOfProceedsSections,
        bonds: [],
        currentEntity: null,
        subWorkSheets: [],
      },
    ],
  },
  {
    title: 'Private Business Use',
    isVisible: true,
    sectionId: 4,
    hasDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label:
              'This section should be reviewed with bond counsel prior to submitting the 990 Exchange to CLA',
            name: 'generalInfoLabel',
            type: 'label',
            col: 10,
          },
        ],
      },
      {
        groupId: 2,
        groupType: 'bondsItem',
        isVisible: true,
        with990DenseRows: true,
        hasTableManagerTabs: true,
        isH2TabsHidden: false,
        entityPlaceHolder: 'Bond',
        summaryAllType: 'Bonds',
        entityNameField: 'bondNameType',
        hideSummaryAll: true,
        isMasterSummaryHidden: true,
        axcessGroup: {
          pull: {
            fromSections: [
              'Schedule K - Supplemental Information on Tax-Exempt Bonds',
            ],
            fieldPrefixes: ['XFDS99K1'],
            fieldIdentifiers: [
              'XFDS99K1.0',
              'XFDS99K1.21',
              'XFDS99K1.22',
              'XFDS99K1.23',
              'XFDS99K1.53',
              'XFDS99K1.24',
              'XFDS99K1.25',
              'XFDS99K1.27',
              'XFDS99K1.28',
              'XFDS99K1.58',
              'XFDS99K1.15',
              'XFDS99K1.60',
              'XFDS99K1.61',
              'XFDS99K1.62',
            ],
          },
        },
        entityIdentifiers: [
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
        ],
        // entities: [],
        bondsSections: privateBusinessUseSections,
        bonds: [],
        currentEntity: null,
        subWorkSheets: [],
      },
    ],
  },
  {
    title: 'Reinvestment of Bond Proceeds (Arbitrage)',
    isVisible: true,
    sectionId: 5,
    hasDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label:
              'This section should be reviewed with bond counsel prior to submitting the 990 Exchange to CLA',
            name: 'generalInfoLabel',
            type: 'label',
            col: 10,
          },
        ],
      },
      {
        groupId: 2,
        groupType: 'bondsItem',
        isVisible: true,
        with990DenseRows: true,
        hasTableManagerTabs: true,
        isH2TabsHidden: false,
        entityPlaceHolder: 'Bond',
        summaryAllType: 'Bonds',
        entityNameField: 'bondNameType',
        hideSummaryAll: true,
        isMasterSummaryHidden: true,
        axcessGroup: {
          pull: {
            fromSections: [
              'Schedule K - Supplemental Information on Tax-Exempt Bonds',
            ],
            fieldPrefixes: ['XFDS99K1'],
            fieldIdentifiers: [
              'XFDS99K1.0',
              'XFDS99K1.57',
              'XFDS99K1.63',
              'XFDS99K1.33',
              'XFDS99K1.34',
              'XFDS99K1.29',
              'XFDS99K1.35',
              'XFDS99K1.49',
              'XFDS99K1.50',
              'XFDS99K1.36',
              'XFDS99K1.30',
              'XFDS99K1.31',
              'XFDS99K1.37',
              'XFDS99K1.38',
              'XFDS99K1.58',
            ],
          },
        },
        entityIdentifiers: [
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
        ],
        // entities: [],
        bondsSections: reinvestmentBondProceedsSections,
        bonds: [],
        currentEntity: null,
        subWorkSheets: [],
      },
    ],
  },
  // NOTE: Adding new section to TEB form causes an error upon navigating to TEB form
  {  
    title: 'Additional Information',
    collapseClass: 'collapsibleForm',
    isVisible: true,
    isDefaultExpanded: false,
    isOptionalSectionMerge: true,
    sectionId: 6,
    groups: [
      {
        groupId: 2,
        groupType: 'details',
        fields: [
          { 
            label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
            type: 'label', 
            class: 'labelLeftLight' 
          },
        ]
      },
      {
        groupId: 1,
        fields: [
          {
            label: 'Start typing to provide additional information', 
            name: 'additionalInfoTextArea', 
            type: 'textarea', 
            col: 12,
            maxLength: 9000
          }
        ]
      }
    ]
  },
];
export const getTaxExemptBondsTiles = () => {
  let titles = [];
  sections.forEach((section) => {
    if (!titles.includes(section.title)) {
      titles.push(section.title);
    }
  });
  return titles;
};

function TaxExemptBonds(props) {
  const { isExportForm } = props; 
  const { updateCard, REDUX } = usePageFramework(NAV.TAX_EXEMPT_BONDS);
  const [sectionState, setSectionState] = useState('');
  const {isActiveClient, isLoading} = useLoadOrganizer();

  useEffect(() => {
    if (!isExportForm) updateCard();

    setSectionState(sections);
    // eslint-disable-next-line
  }, []);

  const axcessIdentifiers = [
    { section: 'Schedules', id: 'Sch K - Tax Exempt Bond Information' },
  ];

  return (
    <>
      {!isLoading && isActiveClient && <FormRendererHelper
        sections={sectionState}
        key={REDUX.TAX_EXEMPT_BONDS}
        formName={REDUX.TAX_EXEMPT_BONDS}
        identifiers={axcessIdentifiers}
        triggeredEvent={triggeredEvent}
        sectionFieldLogic={sectionFieldLogic}
        isExportForm={isExportForm}
      />}
      {!isLoading && !isActiveClient && <NoActiveClient />}
    </>
  );
}

export default TaxExemptBonds;
