import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { Validators } from 'cla-formrenderer';
import { triggeredEvent, sectionFieldLogic } from './fundraisingAndGamingLogicTrigger';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

const fundEventSections = [
	{
		title: 'Fundraising Event Information',
		isVisible: true,
		sectionId: 1,
		isCollpased: true,
		isDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				fields: [
					{ label: 'Event Name/Type', name: 'fundEvntNameTypeLabel', type: 'label', col: 6 },
					{ label: 'Event Name/Type', name: 'fundEvntNameType', type: 'freeText', col: 6, logicFunction: [{ trigger: 'setFundEvntNameTypeSummary' }] },
				]
			}
		]
	},
	{
		title: 'Gross Receipts/Revenue',
		isVisible: true,
		sectionId: 2,
		isCollpased: true,
		isDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Gross Receipts`,
						name: 'fundEvntGrossRcptsLabel',
						type: 'label',
						col: 9,
					},
					{
						label: `Gross Receipts`,
						name: 'fundEvntGrossRcpts',
						type: 'money',
						col: 3,
						logicFunction: [
							{ trigger: 'fundEvntGrossRcptsErrorVal' },
							{ trigger: 'calculateFundEvntCalcContr', isDifferentGroup: true },
							{ trigger: 'setfundEvntGrossRcptsSummary' },
							
						]
					},
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `FMV Received by Attendees`,
						name: 'fundEvntFMVByAttndLabel',
						type: 'label',
						col: 9,
					},
					{
						label: `FMV`,
						name: 'fundEvntFMVByAttnd',
						type: 'money',
						col: 3,
						logicFunction: [{ trigger: 'fundEvntFMVByAttndErrorval' }, { trigger: 'calculateFundEvntCalcContr', isDifferentGroup: true },
						]

					},
				]
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Calculated Contribution`,
						name: 'fundEvntCalcContrLabel',
						type: 'label',
						col: 10,
					},
					{
						label: `$0`,
						name: 'fundEvntCalcContr',
						type: 'label',
						col: 2,
						class: 'labelTextMoney',
					},
				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Did you have an auction?`,
						name: 'fundEvntAuctLabel',
						type: 'label',
						col: 10,
					},
					{
						label: ``,
						name: 'fundEvntAuct',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 2,
						logicFunction: [
							{ trigger: 'showFundEvntAuctItmFMV', isDifferentGroup: true },
							{ trigger: 'showFundEvntAuctSvcs', isDifferentGroup: true },
							{ trigger: 'showFundEvntAuctGrssPrcd', isDifferentGroup: true },
							{ trigger: 'showFundEvntAuctPur', isDifferentGroup: true },
							{ trigger: 'showFundEvntAuctSvcsFMV', isDifferentGroup: true },
							{ trigger: 'showFundEvntAuctPurAmtPd', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 5,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `FMV of donated auction items received`,
						name: 'fundEvntAuctItmFMVLabel',
						type: 'label',
						col: 9,
					},
					{
						label: `FMV of Donated Auction Items`,
						name: 'fundEvntAuctItmFMV',
						type: 'money',
						col: 3,
					},
				]
			},
			{
				groupId: 6,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `Were any of the donated services or use of facilities such as donated account or other services or donated use of vacation home or hotel nights?`,
						name: 'fundEvntAuctSvcsLabel',
						type: 'label',
						col: 10,
					},
					{
						label: ``,
						name: 'fundEvntAuctSvcs',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 2,
						logicFunction: [
							{ trigger: 'showFundEvntAuctSvcsFMV', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 7,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `Please provide the FMV of these donated services and use of facilities`,
						name: 'fundEvntAuctSvcsFMVLabel',
						type: 'label',
						col: 9,
					},
					{
						label: `FMV of Donated Services and Use of Facilities`,
						name: 'fundEvntAuctSvcsFMV',
						type: 'money',
						col: 3,
					},
				]
			},
			{
				groupId: 8,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `Gross proceeds from auction items sold`,
						name: 'fundEvntAuctGrssPrcdLabel',
						type: 'label',
						col: 9,
					},
					{
						label: `Gross Proceeds from Auction Items`,
						name: 'fundEvntAuctGrssPrcd',
						type: 'money',
						col: 3,
					},
				]
			},
			{
				groupId: 9,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `Were there any goods purchased to be sold at the auction?`,
						name: 'fundEvntAuctPurLabel',
						type: 'label',
						col: 10,
					},
					{
						label: ``,
						name: 'fundEvntAuctPur',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 2,
						logicFunction: [
							{ trigger: 'showFundEvntAuctPurAmtPd', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 10,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `Please provide the amount paid`,
						name: 'fundEvntAuctPurAmtPdLabel',
						type: 'label',
						col: 9,
					},
					{
						label: `Gross Proceeds from Auction Items`,
						name: 'fundEvntAuctPurAmtPd',
						type: 'money',
						col: 3,
					},
				]
			},
			{
				groupId: 11,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Other cash charitable contributions received in connection with the event?`,
						name: 'fundEvntCashCntrbLabel',
						type: 'label',
						col: 10,
					},
					{
						label: ``,
						name: 'fundEvntCashCntrb',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 2,
						logicFunction: [
							{ trigger: 'showFundEvntCashCntrbRcvd', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 12,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `Please provide amount received`,
						name: 'fundEvntCashCntrbRcvdLabel',
						type: 'label',
						col: 9,
					},
					{
						label: `Goods Purchased to be Sold`,
						name: 'fundEvntCashCntrbRcvd',
						type: 'money',
						col: 3,
					},
				]
			},
			{
				groupId: 13,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Other non-cash charitable contributions received in connection with event?`,
						name: 'fundEvntNonCashCntrbLabel',
						type: 'label',
						col: 10,
					},
					{
						label: ``,
						name: 'fundEvntNonCashCntrb',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 2,
						logicFunction: [
							{ trigger: 'showFundEvntNonCashCntrbRcvd', isDifferentGroup: true },
							{ trigger: 'showFundEvntNonCashCntrbRcvdDesc', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 14,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `Please provide amount received`,
						name: 'fundEvntNonCashCntrbRcvdLabel',
						type: 'label',
						col: 9,
					},
					{
						label: `Amount Paid`,
						name: 'fundEvntNonCashCntrbRcvd',
						type: 'money',
						col: 3,
					},
				]
			},
			{
				groupId: 15,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `Description of non-cash donations received`,
						name: 'fundEvntNonCashCntrbRcvdDescLabel',
						type: 'label',
						col: 6,
					},
					{
						label: `Description of Non-Cash Donations Received`,
						name: 'fundEvntNonCashCntrbRcvdDesc',
						type: 'freeText',
						printFieldWidth: 500,
						col: 6,
					},
				]
			},
		]
	},
	{
		title: 'Expenses',
		isVisible: true,
		sectionId: 3,
		isCollpased: true,
		isDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Cash Prize Expenses`,
						name: 'fundEvntExpCshPrzLabel',
						type: 'label',
						col: 10
					},
					{
						label: `Cash Prize Expenses`,
						name: 'fundEvntExpCshPrz',
						type: 'money',
						col: 2,
						logicFunction: [
							{ trigger: 'calculateFundEvntExpTotal', isDifferentGroup: true },
							{ trigger: 'setfundEvntExpTotalSummary' },
						]
					},
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Noncash Prizes FMV`,
						name: 'fundEvntExpNonCshPrzLabel',
						type: 'label',
						col: 10
					},
					{
						label: `Noncash Prizes FMV`,
						name: 'fundEvntExpNonCshPrz',
						type: 'money',
						col: 2,
						logicFunction: [
							{ trigger: 'calculateFundEvntExpTotal', isDifferentGroup: true },
							{ trigger: 'setfundEvntExpTotalSummary' },
						]
					},
				]
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Rent/Facility Costs`,
						name: 'fundEvntExpRntCstLabel',
						type: 'label',
						col: 10
					},
					{
						label: `Rent/Facility Costs`,
						name: 'fundEvntExpRntCst',
						type: 'money',
						col: 2,
						logicFunction: [
							{ trigger: 'calculateFundEvntExpTotal', isDifferentGroup: true },
							{ trigger: 'setfundEvntExpTotalSummary' },
						]
					},
				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Food & Beverages`,
						name: 'fundEvntExpFdBvLabel',
						type: 'label',
						col: 10
					},
					{
						label: `Food & Beverages`,
						name: 'fundEvntExpFdBv',
						type: 'money',
						col: 2,
						logicFunction: [
							{ trigger: 'calculateFundEvntExpTotal', isDifferentGroup: true },
							{ trigger: 'setfundEvntExpTotalSummary' },
						]
					},
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Entertainment`,
						name: 'fundEvntExpEntLabel',
						type: 'label',
						col: 10
					},
					{
						label: `Entertainment`,
						name: 'fundEvntExpEnt',
						type: 'money',
						col: 2,
						logicFunction: [
							{ trigger: 'calculateFundEvntExpTotal', isDifferentGroup: true },
							{ trigger: 'setfundEvntExpTotalSummary' },
						]
					},
				]
			},
			{
				groupId: 6,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Other Direct Expenses`,
						name: 'fundEvntExpOthDirLabel',
						type: 'label',
						col: 10
					},
					{
						label: `Other Direct Expenses`,
						name: 'fundEvntExpOthDir',
						type: 'money',
						col: 2,
						logicFunction: [
							{ trigger: 'calculateFundEvntExpTotal', isDifferentGroup: true },
							{ trigger: 'setfundEvntExpTotalSummary' },
						]
					},
				],
			},
			{
				groupId: 7,
				isVisible: true,
				fields: [
					{ label: 'Total Expenses: \n $0', name: 'fundEvntExpTotal', type: 'label', col: 12, class: 'footerTotalMoneyBar' },
				]
			},
		]
	},
];

const orgSections = [
	{
		title: 'About Fundraiser',
		isVisible: true,
		sectionId: 1,
		isCollapsed: true,
		isDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Name`,
						name: 'fundActivityNameLabel',
						type: 'label',
						col: 2
					},
					{
						label: `Name`,
						name: 'fundActivityName',
						type: 'freeText',
						col: 10,
						printFieldWidth: 450,
						logicFunction: [
							{ trigger: 'setFundActivityName' },
						]
					},
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Address', name: 'fundActivityAddressLabel', type: 'label', col: 12 },
					
					{ label: 'Country', name: 'fundActivityCountry', type: 'select', lookup: 'FOREIGN_ASSETS_COUNTRIES', col: 4, printFieldWidth: 280, logicFunction: [{ trigger: 'showFundActivityStateProvince', isDifferentGroup: true }, { trigger: 'setFundActivityAddress' }] },
					{ label: 'Address 1', name: 'fundActivityAdd1', type: 'freeText', col: 8, printFieldWidth: 470, logicFunction: [{ trigger: 'setFundActivityAddress' }] },
					
					{ label: 'City', name: 'fundActivityCity', type: 'freeText', col: 4, printFieldWidth: 330, logicFunction: [{ trigger: 'setFundActivityAddress' }] },
					{ label: 'Province / State', name: 'fundActivityState', type: 'freeText', col: 4, printFieldWidth: 300, isVisible: true, logicFunction: [{ trigger: 'setFundActivityAddress' }] },
					{ label: 'Province', name: 'fundActivityProvince', type: 'select', lookup: 'BASIC_DATA_PROVINCES', col: 4, printFieldWidth: 300, isVisible: false, logicFunction: [{ trigger: 'setFundActivityAddress' }] },
					{ label: 'State', name: 'fundActivityAmericaState', type: 'select', lookup: 'BASIC_DATA_STATES', col: 4, printFieldWidth: 300, isVisible: false, logicFunction: [{ trigger: 'setFundActivityAddress' }] },
					{ label: 'Zip / Postal Code', name: 'fundActivityZip', type: 'zippostal', col: 4,  printFieldWidth: 220, logicFunction: [{ trigger: 'setFundActivityAddress' }] },
				]

			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Type of fundraising activities`,
						name: 'fundActivityActvLabel',
						type: 'label',
						col: 3
					},
					{
						label: `Type of fundraising activities`,
						name: 'fundActivityActv',
						type: 'freeText',
						col: 9,
						printFieldWidth: 320,
						logicFunction: [{ trigger: 'setFundActivityActv' }]
					},
				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Fundraiser had custody-control of contributions`,
						name: 'fundActivityContCustLabel',
						type: 'label',
						col: 3
					},
					{
						label: `Custody-control of contributions`,
						name: 'fundActivityContCust',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 9,
					},
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Gross receipts from activities`,
						name: 'fundActivityGrossRcptLabel',
						type: 'label',
						col: 9,
					},
					{
						label: `Gross Receipts`,
						name: 'fundActivityGrossRcpt',
						type: 'money',
						col: 3,
						logicFunction: [
							{ trigger: 'calculatefundActivityTotPaid', isDifferentGroup: true },
							{ trigger: 'setFundActivityGrossRcpt' },
							{ trigger: 'setFundActivityTotPaid' }
						]
					},
				]
			},
			{
				groupId: 6,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Amount paid to/retained by fundraiser`,
						name: 'fundActivityFeePaidLabel',
						type: 'label',
						col: 9,
					},
					{
						label: `Amount Paid/Retained`,
						name: 'fundActivityFeePaid',
						type: 'money',
						col: 3,
						logicFunction: [
							{ trigger: 'calculatefundActivityTotPaid', isDifferentGroup: true },
							{ trigger: 'setFundActivityFeePaid' },
							{ trigger: 'setFundActivityTotPaid' }
						]
					},
				]
			},
			{
				groupId: 7,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Amount paid to/retained by organization`,
						name: 'fundActivityTotPaidLabel',
						type: 'label',
						col: 10
					},
					{
						label: `$0`,
						name: 'fundActivityTotPaid',
						type: 'label',
						col: 2,
						class: 'labelTextMoney',
					},
				]
			},
		]

	}
]

const gamingEventSections = [
	{
		title: 'Event Details',
		isVisible: true,
		sectionId: 1,
		isCollapsed: true,
		isDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Gross Revenue`,
						name: 'fundGmGrsRvLabel',
						type: 'label',
						col: 10,
					},
					{
						label: `Gross Revenue`,
						name: 'fundGmGrsRv',
						type: 'money',
						col: 2,
						logicFunction: [
							{ trigger: 'setFundGmGrsRvSummary' },
						]
					},
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Cash Prizes`,
						name: 'fundGmCashLabel',
						type: 'label',
						col: 10,
					},
					{
						label: `Cash Prizes`,
						name: 'fundGmCashPrz',
						type: 'money',
						col: 2,
						logicFunction: [
							{ trigger: 'setFundGmCashSummary' },
						]
					},
				]
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Noncash Prizes`,
						name: 'fundGmNonCashPrzLabel',
						type: 'label',
						col: 10,
					},
					{
						label: `Noncash Prizes`,
						name: 'fundGmNonCashPrz',
						type: 'money',
						col: 2,
						logicFunction: [
							{ trigger: 'setFundGmNonCashPrzSummary' },
						]
					},
				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Rent/Facility Costs`,
						name: 'fundGmRentFacCostLabel',
						type: 'label',
						col: 10,
					},
					{
						label: `Rent/Facility Costs`,
						name: 'fundGmRentFacCost',
						type: 'money',
						col: 2,
						logicFunction: [
							{ trigger: 'setFundGmRentFacCostSummary' },
						]
					},
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Other Direct Expenses`,
						name: 'fundGmOthDirExpLabel',
						type: 'label',
						col: 10,
					},
					{
						label: `Other Direct Expenses`,
						name: 'fundGmOthDirExp',
						type: 'money',
						col: 2,
						logicFunction: [
							{ trigger: 'setFundGmOthDirExpSummary' },
						]
					},
				]
			},
			{
				groupId: 6,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Volunteer Labor	`,
						name: 'fundGmVolLaborLabel',
						type: 'label',
						col: 10,
					},
					{
						label: ``,
						name: 'fundGmVolLabor',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 2,
						logicFunction: [
							{ trigger: 'showFundGmVolLaborPctg', isDifferentGroup: true },
							{ trigger: 'setFundGmVolLaborPctgSummary' },
						]
					},
				]
			},
			{
				groupId: 7,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `% of Volunteer Labor`,
						name: 'fundGmVolLaborPctgLabel',
						type: 'label',
						col: 10,
					},
					{
						label: `% of Volunteer Labor`,
						name: 'fundGmVolLaborPctg',
						type: 'percent',
						validations: { ...Validators.maxValue(100), },
						col: 2,
						logicFunction: [
							{ trigger: 'setFundGmVolLaborPctgSummary' },
						]
					},
				]
			},
		]
	}
]

const sections = [
	{
		title: 'General Information',
		isVisible: true,
		sectionId: 1,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				fields: [
					{ label: 'Please Select All That Apply:', name: 'selectAllLabel', type: 'label', col: 12, class: 'formSingleHeaderContainer' },
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization report a total of more than $15,000 of expenses for professional fundraising services?', name: 'reqSchdChkGiLabel', type: 'label', col: 10 },
					{ label: '', name: 'reqSchdChkGi', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showProfessionalFundraising', isDifferentGroup: true }] },
				]
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization hold any special fundraising events during the year (e.g., include dinners, dances, door-to-door sales of merchandise, concerts, carnivals, sports events, auctions, and similar events not regularly carried on that are conducted for the primary purpose of raising funds)?', name: 'fundEvntLabel', type: 'label', col: 10 },
					{ label: '', name: 'fundEvnt', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showFundraisingEvents', isDifferentGroup: true }] },
				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization, as a standalone activity or as part of a larger activity, conduct any charitable gaming (e.g., raffles, sweepstakes, bingo, pull-tabs, paddlewheels, or other games of chance)?', name: 'fundGamingLabel', type: 'label', col: 10 },
					{
						label: '',
						name: 'fundGaming',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 2,
						logicFunction: [
							{ trigger: 'showCharitableGaming', isDifferentGroup: true },
						]
					},
				]
			},
		]
	},
	{
		title: 'Professional Fundraising',
		isVisible: false,
		sectionId: 2,
		hasDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				fields: [
					{
						label: 'Did the organization raise funds through any of the following activities?',
						name: 'proFundLabel',
						type: 'label',
						col: 12,
						class: 'labelQuestionTitle'
					}
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Mail Solicitations', name: 'fundActivityType1Label', type: 'label', col: 10 },
					{ label: '', name: 'fundActivityType1', type: 'radio', lookup: 'YESNO_RADIO', col: 2 },
				]
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Internet and email solicitations', name: 'fundActivityType2Label', type: 'label', col: 10 },
					{ label: '', name: 'fundActivityType2', type: 'radio', lookup: 'YESNO_RADIO', col: 2 },
				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Phone solicitations', name: 'fundActivityType3Label', type: 'label', col: 10 },
					{ label: '', name: 'fundActivityType3', type: 'radio', lookup: 'YESNO_RADIO', col: 2 },
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'In-person solicitations', name: 'fundActivityType4Label', type: 'label', col: 10 },
					{ label: '', name: 'fundActivityType4', type: 'radio', lookup: 'YESNO_RADIO', col: 2 },
				]
			},
			{
				groupId: 6,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Solicitation of non-government grants', name: 'fundActivityType5Label', type: 'label', col: 10 },
					{ label: '', name: 'fundActivityType5', type: 'radio', lookup: 'YESNO_RADIO', col: 2 },
				]
			},
			{
				groupId: 7,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Solicitation of government grants', name: 'fundActivityType6Label', type: 'label', col: 10 },
					{ label: '', name: 'fundActivityType6', type: 'radio', lookup: 'YESNO_RADIO', col: 2 },
				]
			},
			{
				groupId: 8,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Special fundraising events', name: 'fundActivityType7Label', type: 'label', col: 10 },
					{ label: '', name: 'fundActivityType7', type: 'radio', lookup: 'YESNO_RADIO', col: 2 },
				]
			},
			{
				groupId: 9,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'List all states in which the organization is registered or licensed to solicit funds or has been notified it is exempt from registration or licensing', name: 'fundActivityStatesLabel', type: 'label', col: 9 },
					{
						label: '',
						name: 'fundActivityStates',
						type: 'multiSelectDropdown',
						lookup: 'SELECTSTATES',
						singleSelect: false,
						placeHolder: 'States',
						showArrow: true,
						showCheckbox: true,
						displayValue: 'name',
						isObject: true,
						disablePreSelectedValues: false,
						col: 3,
					},
				]
			},
			{
				groupId: 10,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization have a written or oral agreement with any individual or entity in connection with professional fundraising services?', name: 'fundActivityStatesLabel', type: 'label', col: 10 },
					{ label: '', name: 'fundActivityAgree', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showTenHighFund', isDifferentGroup: true }] },
				]
			},
			{
				groupId: 11,
				isVisible: false,
				fields: [
					{
						label: 'Provide the following information for the organization\'s ten highest paid fundraisers (including individuals and entities)',
						name: 'tenHighFundLabel',
						type: 'label',
						col: 12,
						class: 'labelQuestionTitle'
					}
				]
			},
			{
				groupId: 12,
				groupType: 'lineItem',
				isVisible: false,
				with990DenseRows: true,
				hasTableManagerTabs: true,
				isH2TabsHidden: true,
				entityPlaceHolder: 'Fundraiser',
				summaryAllType: 'Fundraisers',
				entityNameField: 'fundActivityName',
				isMasterSummaryHidden: true,
				fields: [
					{
						label: '-',
						name: 'tenHighFundName',
						type: 'label',
						styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
					},
					{ label: '-', name: 'tenHighFundAdd', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ label: '-', name: 'tenHighFundType', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ label: '$	-', name: 'tenHighFundGrossReceipts', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ label: '$	-', name: 'tenHighFundRetFund', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ label: '$	-', name: 'tenHighFundRetOrg', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ type: 'lineButtons' }
				],
				lineItems: [],
				entities: [],
				lineSections: orgSections,
				currentEntity: null,
				subWorkSheets: [],
				headerLabelsPrintStyles: {gridTemplateColumns: '1.25fr 1fr 1fr 1.25fr 1.25fr 1.25fr .5fr'},
				lineItemDetails: {
					headerLabels: [
						{ label: 'Fundraiser Name', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '48px' } },
						{ label: 'Address', col: 2, colTablet: 2, align: 'left', styles: { paddingLeft: '44px' } },
						{ label: 'Type of Fundraising Activities', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '40px' } },
						{ label: 'Gross Receipts from Activities', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '32px' } },
						{ label: 'Amount Paid to/Retained by Fundraiser', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '25px' } },
						{ label: 'Amount Paid to/Retained by Org', col: 1, colTablet: 1, align: 'left', styles: { paddingLeft: '25px' } },
						{ label: 'Actions', col: 1, colTablet: 1, align: 'left', }
					],
					footerLabels: [
						{ type: 'buttons', col: 12, buttonTitle: 'Add Another Fundraiser', icon: 'add' },
					],
					lineButtons: [
						{ button: 'remove', },
					]
				}
			},
		]
	},
	{
		title: 'Fundraising Events',
		isVisible: false,
		hasDenseRow: true,
		sectionId: 3,
		groups: [
			{
				groupId: 1,
				groupType: 'lineItem',
				isVisible: true,
				with990DenseRows: true,
				hasTableManagerTabs: true,
				isH2TabsHidden: true,
				entityPlaceHolder: 'Event',
				summaryAllType: 'Events',
				entityNameField: 'fundEvntNameType',
				isMasterSummaryHidden: true,
				fields: [
					{ label: 'Event', name: 'fundEvntNameTypeSummary', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ label: '$', name: 'fundEvntGrossRcptsSummary', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ label: '$', name: 'fundEvntExpTotalSummary', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ type: 'lineButtons' }
				],
				lineItems: [],
				entities: [],
				lineSections: fundEventSections, 
				currentEntity: null,
				subWorkSheets: [],
				headerLabelsPrintStyles: {gridTemplateColumns: '3.75fr 1.5fr 2.5fr .75fr'},
				lineItemDetails: {
					headerLabels: [
						{ label: 'Name/Type of Event', col: 3, colTablet: 2, align: 'left', styles: { paddingLeft: '48px' } },
						{ label: 'Gross Receipts', col: 4, colTablet: 2, align: 'left', styles: { paddingLeft: '44px' } },
						{ label: 'Expenses', col: 4, colTablet: 2, align: 'left', styles: { paddingLeft: '25px' } },
						{ label: 'Actions', col: 1, colTablet: 2, align: 'left', }
					],
					footerLabels: [
						{ type: 'buttons', col: 12, buttonTitle: 'Add Another Fundraising Event', icon: 'add' },
					],
					lineButtons: [
						{ button: 'remove', },
					]
				}
			},
		]
	},
	{
		title: 'Gaming Events',
		isVisible: false,
		hasDenseRow: true,
		sectionId: 4,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				fields: [
					{
						label: 'Please provide the following information for your gaming events:',
						name: 'gamingEventsInfoLabel',
						type: 'label',
						col: 12,
						class: 'labelQuestionTitle'
					}
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'List all states where the organization operated gaming activities',
						name: 'fundGmActStatesLabel',
						type: 'label',
						col: 6
					},
					{
						label: '',
						name: 'fundGmActStates',
						type: 'multiSelectDropdown',
						lookup: 'SELECTFILINGSTATES',
						singleSelect: false,
						placeHolder: 'States',
						showArrow: true,
						showCheckbox: true,
						displayValue: 'name',
						isObject: true,
						disablePreSelectedValues: false,
						col: 6,
						prior: true,
						isPriorEditable: true,
						axcess: {
							pull: {
								fields: ['XFDS99G5.0'],
								fn: 'gridToMultiselectPull',
								populateMultiple: true
							}
						}
					},
				]
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Is the organization licensed to conduct gaming activities in each of the above states?',
						name: 'fundGmActStateLcnsLabel',
						type: 'label',
						col: 10
					},
					{
						name: 'fundGmActStateLcns',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 2,
						logicFunction: [
							{ trigger: 'showFundGmActStateLcnsExp', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 4,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Provide an explanation for not having licenses to conduct gaming activities in each of the above states:',
						name: 'fundGmActStateLcnsExpLabel',
						type: 'label',
						class: 'labelTitleTextArea',
						col: 12
					},
					{
						label: 'Please Explain',
						placeholder: 'Please explain',
						name: 'fundGmActStateLcnsExp',
						type: 'textarea',
						maxRows: 3,
						class: 'fieldTextArea',
						maxLength: 9000,
						col: 12
					},
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Were any of the organization's gaming licenses revoked, suspended or terminated during the year?`,
						name: 'fundGmActStateLcnsTermLabel',
						type: 'label',
						col: 10
					},
					{
						name: 'fundGmActStateLcnsTerm',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 2,
						logicFunction: [
							{ trigger: 'showFundGmActStateLcnsTermExp', isDifferentGroup: true },
						]
					},
				]
			},
			{
				groupId: 6,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Provide an explanation for any licenses that were revoked, suspended or terminated:',
						name: 'fundGmActStateLcnsTermExpLabel',
						type: 'label',
						class: 'labelTitleTextArea',
						col: 12
					},
					{
						label: 'Please Explain',
						placeholder: 'Please explain',
						name: 'fundGmActStateLcnsTermExp',
						type: 'textarea',
						maxRows: 3,
						class: 'fieldTextArea',
						maxLength: 9000,
						col: 12
					},
				]
			},
			{
				groupId: 7,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Does the organization operate gaming activities with nonmembers?',
						name: 'fundGmActNonMbrLabel',
						type: 'label',
						col: 10
					},
					{
						name: 'fundGmActNonMbr',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 2,
					},
				]
			},
			{
				groupId: 8,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `
							Is the organization a grantor, beneficiary or trustee of a trust, 
							or a member of a partnership or other entity formed to administer charitable gaming?
						`,
						name: 'fundGmActOthEntLabel',
						type: 'label',
						col: 10
					},
					{
						name: 'fundGmActOthEnt',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 2,
					},
				]
			},
			{
				groupId: 9,
				isVisible: true,
				fields: [
					{
						label: `Indicate the percentage of the organization's gaming activity conducted in:`,
						name: 'percentageOfGamingActLabel',
						type: 'label',
						col: 12,
						class: 'labelQuestionTitle'
					}
				]
			},
			{
				groupId: 10,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `The organization's facilities`,
						name: 'fundGmMgrPctOrgFacLabel',
						type: 'label',
						col: 10
					},
					{
						label: `Percentage in Org's facilities`,
						name: 'fundGmMgrPctOrgFac',
						type: 'percent',
						validations: { ...Validators.maxValue(100), },
						col: 2,
					},
				]
			},
			{
				groupId: 11,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Outside facilities',
						name: 'fundGmMgrPctOutFacLabel',
						type: 'label',
						col: 10
					},
					{
						label: 'Percentage in Outside facilities',
						name: 'fundGmMgrPctOutFac',
						type: 'percent',
						validations: { ...Validators.maxValue(100), },
						col: 2,
					},
				]
			},
			{
				groupId: 12,
				isVisible: true,
				fields: [
					{
						label: `Provide the following information about your organization's gaming activities:`,
						name: 'gamingActLabel',
						type: 'label',
						col: 12,
						class: 'labelQuestionTitle'
					}
				]
			},
			{
				groupId: 13,
				groupType: 'lineItem',
				isVisible: true,
				with990DenseRows: true,
				entityPlaceHolder: 'Event',
				fields: [
					{ label: '', name: 'fundGmActTypeSummary', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ label: '$ -', name: 'fundGmGrsRvSummary', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ label: '$ -', name: 'fundGmCashSummary', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ label: '$	-', name: 'fundGmNonCashPrzSummary', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ label: '$	-', name: 'fundGmRentFacCostSummary', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ label: '$	-', name: 'fundGmOthDirExpSummary', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ label: '%	-', name: 'fundGmVolLaborPctgSummary', type: 'label', styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' }, },
					{ type: 'lineButtons' }
				],
				prePopulate: [
					{ label: 'Bingo'},
					{ label: 'Pull Tabs, Instant Bingo, Progressive Bingo' },
					{ label: 'Other Gaming' },
				],
				prePopulateControls: ['label', 'label', 'label', 'label', 'label', 'label', 'label', 'lineButtons'],
				prePopulateIndexes: [{ currentIndex: 1 }, { currentIndex: 2 }],
				lineItems: [],
				entities: [],
				lineSections: gamingEventSections,
				currentEntity: null,
				subWorkSheets: [],
				headerLabelsPrintStyles: {gridTemplateColumns: '1fr 1.5fr 1fr 1fr 1fr 1fr 1.25fr .75fr'},
				lineItemDetails: {
					headerLabels: [
						{ label: 'Event Type', col: 1, colTablet: 1, align: 'left', styles: { paddingLeft: '48px' } },
						{ label: 'Gross Revenue', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '44px' } },
						{ label: 'Cash Prizes', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '40px' } },
						{ label: 'Noncash Prizes', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '32px' } },
						{ label: 'Rent/Facility Costs', col: 2, colTablet: 1, align: 'left', styles: { paddingLeft: '25px' } },
						{ label: 'Other Direct Expenses', col: 1, colTablet: 1, align: 'left', styles: { paddingLeft: '25px' } },
						{ label: '% of Volunteer Labor', col: 1, colTablet: 1, align: 'left', isVisible: false, styles: { paddingLeft: '25px' } },
						{ label: 'Actions', col: 1, colTablet: 1, align: 'left', }
					],
					footerLabels: [
					],
					lineButtons: [
                        { button: 'clear', retainFieldName: 'fundGmActTypeSummary', logicFunction: [{ trigger: 'hideEntityPercentField' }]},
					]
				}
			},
		]
	},
	{
		title: 'Gaming Management',
		isVisible: false,
		sectionId: 5,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				fields: [
					{
						label: 'Provide the following information for the person who prepares the organization\'s gaming and/or special events books and records',
						name: 'fundGmMgrPrepInfoLabel',
						type: 'label',
						col: 12,
						class: 'labelQuestionTitle'
					}
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Name`,
						name: 'fundGmMgrPrepNameLabel',
						type: 'label',
						col: 4
					},
					{
						label: `Name`,
						name: 'fundGmMgrPrepName',
						type: 'freeText',
						col: 8,
					},
				]
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Address', name: 'fundGmMgrPrepAddInfoLabel', type: 'label', col: 12 },
					{ label: 'Country', name: 'fundGmMgrPrepCtry', type: 'select', lookup: 'FOREIGN_ASSETS_COUNTRIES', col: 4, logicFunction: [{ trigger: 'showGmMgrStateProvince', isDifferentGroup: true }] },
					{ label: 'Street Address', name: 'fundGmMgrPrepAdd', type: 'freeText', col: 8, printFieldWidth: 400, },
					{ label: 'Apt', name: 'fundGmMgrPrepAppt', type: 'freeText', col: 2 },
					{ label: 'City', name: 'fundGmMgrPrepCity', type: 'freeText', col: 3 },
					{ label: 'Province / State', name: 'fundGmMgrPrepState', type: 'freeText', col: 4, isVisible: true },
					{ label: 'Province', name: 'fundGmMgrPrepProvince', type: 'select', lookup: 'BASIC_DATA_PROVINCES', col: 4, isVisible: false },
					{ label: 'State', name: 'fundGmMgrPrepAmericaState', type: 'select', lookup: 'BASIC_DATA_STATES', col: 4, isVisible: false },
					{ label: 'Zip / Postal Code', name: 'fundGmMgrPrepZip', type: 'zippostal', col: 3 },
				]

			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Does the organization have a contract with a third party from whom the organization receives gaming revenues?`,
						name: 'fundGmMgr3PtyCntrctLabel',
						type: 'label',
						col: 10,
						class: 'labelQuestionTitle'
					},
					{ label: '', name: 'fundGmMgr3PtyCntrct', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showFundGmMgr3Pty', isDifferentGroup: true }] },
				]
			},
			{
				groupId: 5,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `Name of Third Party`,
						name: 'fundGmMgr3PtyNameLabel',
						type: 'label',
						col: 4
					},
					{
						label: `Third Party Name`,
						name: 'fundGmMgr3PtyName',
						type: 'freeText',
						col: 8,
					},
				]
			},
			{
				groupId: 6,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Address', name: 'fundGmMgr3PtyAddInfoLabel', type: 'label', col: 12 },
					{ label: 'Country', name: 'fundGmMgr3PtyCtry', type: 'select', lookup: 'FOREIGN_ASSETS_COUNTRIES', col: 4, logicFunction: [{ trigger: 'showGmMgr3PtyStateProvince', isDifferentGroup: true }], },
					{ label: 'Address', name: 'fundGmMgr3PtyAdd', type: 'freeText', col: 8, printFieldWidth: 400, },
					{ label: 'Apt', name: 'fundGmMgr3PtyAppt', type: 'freeText', col: 2 },
					{ label: 'City', name: 'fundGmMgr3PtyCity', type: 'freeText', col: 3 },
					{ label: 'Province / State', name: 'fundGmMgr3PtyState', type: 'freeText', col: 4 },
					{ label: 'Province', name: 'fundGmMgr3PtyProvince', type: 'select', lookup: 'BASIC_DATA_PROVINCES', col: 4, isVisible: false },
					{ label: 'State', name: 'fundGmMgr3PtyAmericaState', type: 'select', lookup: 'BASIC_DATA_STATES', col: 4, isVisible: false },
					{ label: 'Zip / Postal Code', name: 'fundGmMgr3PtyZip', type: 'zippostal', col: 3 },
				]
			},
			{
				groupId: 7,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Gaming revenue received by the organization', name: 'fundGmMgr3PtyRevRecLabel', type: 'label', col: 9 },
					{ label: 'Gaming Revenue Received', name: 'fundGmMgr3PtyRevRec', type: 'money', col: 3 },
				]
			},
			{
				groupId: 8,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Gaming revenue retained by the third party', name: 'fundGmMgr3PtyRevRetLabel', type: 'label', col: 9 },
					{ label: 'Gaming Revenue Retained', name: 'fundGmMgr3PtyRevRet', type: 'money', col: 3 },
				]
			},
			{
				groupId: 9,
				isVisible: true,
				fields: [
					{ label: 'Provide the following about the organization\'s gaming manager:', name: 'fundGmMgrNameInfoLabel', type: 'label', col: 12 },
				]
			},
			{
				groupId: 10,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Name of gaming manager`,
						name: 'fundGmMgrNameLabel',
						type: 'label',
						col: 4
					},
					{
						label: `Gaming Manager Name`,
						name: 'fundGmMgrName',
						type: 'freeText',
						col: 8,
					},
				]
			},
			{
				groupId: 11,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Amount of Gaming Manager compensation`,
						name: 'fundGmMgrCompLabel',
						type: 'label',
						col: 9,
					},
					{
						label: `Amount of Compensation`,
						name: 'fundGmMgrComp',
						type: 'money',
						col: 3,
					},
				]
			},
			{
				groupId: 12,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Description of services provided`,
						name: 'fundGmMgrSrvLabel',
						type: 'label',
						col: 4
					},
					{
						label: `Description of Services Provided`,
						name: 'fundGmMgrSrv',
						type: 'freeText',
						col: 8,
						printFieldWidth: 500,
					},
				]
			},
			{
				groupId: 13,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Position with the organization`,
						name: 'fundGmMgrOrgMbrLabel',
						type: 'label',
						col: 9
					},
					{
						label: ``,
						name: 'fundGmMgrOrgMbr',
						type: 'select',
						lookup: 'GAME_MANAGER_POSITION',
						col: 3
					},
				]
			},
			{
				groupId: 14,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Is the organization required under state law to make charitable distributions from the gaming proceeds to retain its state gaming license?`,
						name: 'fundGmMgrDistPrcdLabel',
						type: 'label',
						col: 10,
					},
					{ label: '', name: 'fundGmMgrDistPrcd', type: 'radio', lookup: 'YESNO_RADIO', col: 2, logicFunction: [{ trigger: 'showFundGmMgrAmtDist', isDifferentGroup: true }] },
				]
			},
			{
				groupId: 15,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: `Provide the amount of gaming revenues that were distributed to other exempt organizations or spent in the organization's own exempt activities during the year`,
						name: 'fundGmMgrAmtDistLabel',
						type: 'label',
						col: 9,
					},
					{ label: 'Amount of Gaming Revenues Distributed', name: 'fundGmMgrAmtDist', type: 'money', col: 3 },
				]
			},
		]
	},
	{  
    title: 'Additional Information',
    collapseClass: 'collapsibleForm',
    isVisible: true,
    isDefaultExpanded: false,
		isOptionalSectionMerge: true,
    sectionId: 6,
    groups: [
      {
        groupId: 2,
        groupType: 'details',
        fields: [
          { 
            label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
            type: 'label', 
            class: 'labelLeftLight' 
          },
        ]
      },
      {
        groupId: 1,
        fields: [
          {
            label: 'Start typing to provide additional information', 
            name: 'additionalInfoTextArea', 
            type: 'textarea', 
            col: 12,
						maxLength: 9000
          }
        ]
      }
    ]
  },
];
export const getFundraisingAndGamingTitles = () => {
	let titles = [];
	sections.forEach((section) => {
		if (!titles.includes(section.title)) {
			//if (!titles.includes(section.title) && section.isVisible) {
			titles.push(section.title);
		}
	});
	return titles;
};
function FundraisingAndGaming(props) {
	const { isExportForm } = props;
	const { updateCard, REDUX } = usePageFramework(NAV.FUNDRAISING_AND_GAMING);
	const [sectionState, setSectionState] = useState('');
	const {isActiveClient, isLoading} = useLoadOrganizer();

	useEffect(() => {
		if (!isExportForm) updateCard();
		setSectionState(sections);
		// eslint-disable-next-line 
	}, []);

	const axcessIdentifiers = [
		{ section: 'Schedules', id: 'Sch G - Fundraising and Gaming Activities' }
	];

	return (
		<>
			{!isLoading && isActiveClient &&
				<FormRendererHelper
					sections={sectionState}
					key={REDUX.FUNDRAISING_AND_GAMING}
					formName={REDUX.FUNDRAISING_AND_GAMING}
					identifiers={axcessIdentifiers}
					triggeredEvent={triggeredEvent}
					sectionFieldLogic={sectionFieldLogic}
					isExportForm={isExportForm} />}
			{!isLoading && !isActiveClient && <NoActiveClient />}
		</>
	);
}

export const getFundEventSections = () => {
	return fundEventSections;
}

export const getOrgSections = () => {
	return orgSections;
}

export const getSections = () => {
	return sections;
}

export const getGamingEventSections = () => {
	return gamingEventSections;
}

export default FundraisingAndGaming;