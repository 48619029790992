import * as EVENT from '@utilities/constants/triggerKeys';

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
    default:
        break;
    }
};

//TRIGGERS FOR POLICIES & RECORDS SECTION
const showEdNonDiscrimRacePolNo = (options) => {
    const { section, field } = options;

    section.groups[1].isVisible = field.default === EVENT.KEY_NO;
};

const showEdNonDiscrimRaceStateNo = (options) => {
    const { section, field } = options;

    section.groups[3].isVisible = field.default === EVENT.KEY_NO;
};

const showEdCopyMatNo = (options) => {
    const { section } = options;

    const edDocRaceComp = section.groups[7].fields[1].default === EVENT.KEY_NO;
    const edDocScholar = section.groups[8].fields[1].default === EVENT.KEY_NO;
    const edCopyCat = section.groups[9].fields[1].default === EVENT.KEY_NO;
    const edCopyMat = section.groups[10].fields[1].default === EVENT.KEY_NO;

    section.groups[11].isVisible = edDocRaceComp || edDocScholar || edCopyCat || edCopyMat;
}

//TRIGGERS FOR RACIAL DISCRIMINATION SECTION
const showEdDiscrimExCurActYes = (options) => {
    const { section } = options;

    const edDiscrimStudentYes = section.groups[1].fields[1].default === EVENT.KEY_YES;
    const edDiscrimAdmitYes = section.groups[2].fields[1].default === EVENT.KEY_YES;
    const edDiscrimEmployYes = section.groups[3].fields[1].default === EVENT.KEY_YES;
    const edDiscrimScholarYes = section.groups[4].fields[1].default === EVENT.KEY_YES;
    const edDiscrimEdPolYes = section.groups[5].fields[1].default === EVENT.KEY_YES;
    const edDiscrimFacilityYes = section.groups[6].fields[1].default === EVENT.KEY_YES;
    const edDiscrimAthleticYes = section.groups[7].fields[1].default === EVENT.KEY_YES;
    const edDiscrimExCurActYes = section.groups[8].fields[1].default === EVENT.KEY_YES;

    section.groups[9].isVisible = edDiscrimStudentYes || edDiscrimAdmitYes || edDiscrimEmployYes || edDiscrimScholarYes || edDiscrimEdPolYes || edDiscrimFacilityYes || edDiscrimAthleticYes || edDiscrimExCurActYes
}

//TRIGGERS FOR GOVERNMENT ASSISTANCE & CERTIFICATION SECTION

const showEdFinAidRevokeYes = (options) => {
    const { section } = options;
    const isEdFinAidYes = section.groups[0].fields[1].default === EVENT.KEY_YES;
    const isEdFinAidRevokeYes = section.groups[1].fields[1].default === EVENT.KEY_YES;
    
    section.groups[2].isVisible = isEdFinAidYes || isEdFinAidRevokeYes;
}

const showEdCertComplyNon = (options) => {
    const { section, field } = options;

    section.groups[4].isVisible = field.default === EVENT.KEY_NO;
}

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        case 'showEdNonDiscrimRacePolNo':
            showEdNonDiscrimRacePolNo(options);
            break;
        case 'showEdNonDiscrimRaceStateNo':
            showEdNonDiscrimRaceStateNo(options);
            break;
        case 'showEdCopyMatNo':
            showEdCopyMatNo(options);
            break;
        case 'showEdDiscrimExCurActYes':
            showEdDiscrimExCurActYes(options);
            break;
        case 'showEdFinAidRevokeYes':
            showEdFinAidRevokeYes(options);
            break;
        case 'showEdCertComplyNon':
            showEdCertComplyNon(options);
            break;
        default:
            break;
}
};

export {
    triggeredEvent,
    sectionFieldLogic
};