import _ from 'lodash';
import * as LOOKUPS from '@utilities/constants/lookupInfo';
import { filterPYByIdentifier, findFromData, findSection } from '@utilities/axcessTaxPull';
import { filterGroupLines } from '@utilities/populatePriorData/axcessGroupFilter';

const convertToEntities = (entity) => [entity];

const filterPYFromIdentifiers = (priorYearData) => (prev, identifier) => {
	if (priorYearData && _.isArray(priorYearData)) {
		priorYearData.filter(filterPYByIdentifier(identifier)).forEach((priorItem) => {
			prev.push(priorItem);
		});
	}

	return prev;
};

const fragmentPYLineItems = (priorYearEntities = [], identifiers = []) => {
	let worksheetNames = [];

	if (identifiers) {
		worksheetNames = identifiers.map(x => x.worksheet).filter(x => x);
	}

	const fragmentedPYData = [];

	priorYearEntities?.forEach((entityWorksheet) => {
		entityWorksheet?.forEach((entityWorksheetSection) => {
			if (entityWorksheetSection) {
				const { data, ...metadata } = entityWorksheetSection;
				data.forEach((dataSet) => {
					if (worksheetNames.length && !worksheetNames.includes(dataSet?.name)) return;

					dataSet?.lineItems?.forEach((lineItem) => {
						fragmentedPYData.push([Object.assign({}, metadata, { data: [{ name: dataSet.name, lineItems: [lineItem] }] })]);
					})
				});
			}
		});
	});

	return fragmentedPYData;
};

const isMatchingEntity = (value, matcher) => (matchingEntity) => {
	const { matchOnKey, matchOnType, matchOnSection, translateMatchValue } = matcher;
	const matchSection = findSection(matchingEntity?.data, matchOnSection);
	const valuesToMatch = findFromData(matchSection, matchOnKey, matchOnType);

	if (translateMatchValue) {
		const found = valuesToMatch?.find(x => LOOKUPS[translateMatchValue]?.find(y => y.name === x.value && y.value === value))?.value;
		return found ? true : false;
	}

	const valueToMatch = valuesToMatch?.find(x => x.value === value)?.value;
	if (!valueToMatch) return false;
	return value === valueToMatch;
};

const applyParseOptions = (priorYearEntities, options,) => {
	const { groupByEntityId } = options;

	const appliedPYEntities = priorYearEntities.reduce((prev, curr) => {
		if (groupByEntityId) {
			const entityId = Number(curr?.entityId) || 1;
			const indexTarget = entityId - 1;
			if (prev[indexTarget]) {
				prev[indexTarget].push(curr);
			} else {
				prev[indexTarget] = [curr];
			}
			return prev;
		}

		prev.push([curr]);
		return prev;

	}, []);

	return appliedPYEntities;
};

const loadPriorEntityData = (identifiers, matchIdentifiers, spreadIdentifiers, priorYearData, entityFilter, options = {}) => {
	const entityBaseData = loadBaseEntityData(identifiers, matchIdentifiers, priorYearData, options);

	let filteredEntities = entityBaseData;

	if (entityFilter) {
		const { type, filters } = entityFilter;
		filteredEntities = filterGroupLines(entityBaseData, type, filters);
	}

	if (spreadIdentifiers && _.isArray(spreadIdentifiers) && spreadIdentifiers.length) {
		const priorYearEntities = spreadIdentifiers.reduce(filterPYFromIdentifiers(priorYearData), []);
		const spreadAllIdentifiers = spreadIdentifiers.filter(x => !x.toFirstEntity);
		const otherPriorYearEntities = spreadAllIdentifiers.reduce(filterPYFromIdentifiers(priorYearData), []);

		filteredEntities?.forEach((x, i) => {
			const entitiesToSpread = i === 0 ? priorYearEntities : otherPriorYearEntities;
			x.push(...entitiesToSpread);
		});
	}

	return filteredEntities;

};

const loadBaseEntityData = (identifiers, matchIdentifiers, priorYearData, options = {}) => {
	const priorYearEntities = identifiers.reduce(filterPYFromIdentifiers(priorYearData), []);

	// Matching Prior Year data based on key/value is needed per entity
	if (matchIdentifiers && _.isArray(matchIdentifiers)) {
		const primaryMatch = matchIdentifiers.find(x => x.isPrimary);
		const matchers = matchIdentifiers.filter(x => !x.isPrimary);

		if (!primaryMatch || !matchers.length) return priorYearEntities.map(convertToEntities);

		const {
			matchOnKey: primaryMatchOnKey,
			matchOnSection: primaryMatchOnSection,
			matchOnType: primaryMatchOnType,
			identifierIndex: primaryIdentifierIndex,
			fragment
		} = primaryMatch;

		let entities = priorYearEntities.filter(filterPYByIdentifier(identifiers[primaryIdentifierIndex])).map(convertToEntities);
		entities = fragment ? fragmentPYLineItems(entities, [identifiers[primaryIdentifierIndex]]) : entities;

		matchers.forEach((matcher) => {
			const { matchType, identifierIndex } = matcher;
			const matcherPYEntities = priorYearEntities.filter(filterPYByIdentifier(identifiers[identifierIndex]));

			entities.forEach((pyEntityData) => {
				const primaryPY = pyEntityData[0];

				const primarySection = findSection(primaryPY?.data, primaryMatchOnSection);
				const primaryValuesToMatch = findFromData(primarySection, primaryMatchOnKey, primaryMatchOnType);

				primaryValuesToMatch?.forEach((valuesToMatch) => {
					const { value: primaryValueToMatch } = valuesToMatch;

					if (!primaryValueToMatch) return;

					if (matchType === 'first') {
						const matchingEntity = matcherPYEntities.find(isMatchingEntity(primaryValueToMatch, matcher));

						if (matchingEntity) {
							pyEntityData.push(matchingEntity);
						}
					}
				});
			});
		});

		return entities;
	}

	return applyParseOptions(priorYearEntities, options);
};

export default loadPriorEntityData;

export {
	loadPriorEntityData,
	convertToEntities,
	filterPYFromIdentifiers,
	fragmentPYLineItems,
	isMatchingEntity,
};