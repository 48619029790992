// common modules
import { useState, useEffect } from "react";
// import _ from 'lodash';
import { filterPYByIdentifier } from "@utilities/axcessTaxPull.js"
import { findFromPYData } from '@utilities/axcessTaxPull.js';
import { MM_DD_YYYY, YYYY_MM_DD } from "@utilities/constants/dateFormats";
import _ from "lodash";
import moment from "moment";

// custom modules
import api from '@utilities/claApi';

const incrementTaxYear = (value) => {
    if (!value || !_.isArray(value)) return null;

    const date = moment(value[0].value, [MM_DD_YYYY, YYYY_MM_DD]);
    date.add(1, 'year');

    return date?.isValid() ? date.format(MM_DD_YYYY) : null;
};

export const yearEndValue = (organizer) => {
    const { id: organizerId, year: taxYear } = organizer;

    if (!organizerId) {
        return;
    };

    return api.get(`/organizers/${organizerId}`).then((response) => { // step 1; get the tax year end value from basic data
        let taxYearEndFieldDate = ''
        if (response.data.forms.length > 0) {
            for (let i = 0; i < response.data.forms.length; i++) {
                if (response.data.forms[i].key === 'basicData') {
                    // GROWTH: If the basic data form changes, this may target the wrong field
                    taxYearEndFieldDate = response?.data?.forms?.[i]?.data?.[0]?.groups?.[9]?.fields?.[1]?.value;
                }
            }
        }
        
        if (taxYearEndFieldDate) {
            return taxYearEndFieldDate;
        }

        return api.get(`/organizers/${organizerId}/prior`).then((response) => {
            const findPYData = response.data.data.taxData.priorYear.filter(filterPYByIdentifier({ section: 'General', id: 'Basic Data' }));

            const value = findFromPYData(findPYData, null, 'XFDSGEN.18');

            const newDate = incrementTaxYear(value);

            return newDate ?? `12/31/${taxYear}`;
        });
    }).catch((err) => {
        console.error(err);
        return `12/31/${taxYear}`;
    });
};

const LoadYearEnd = (props) => {
    const { organizer } = props;
    const [yearEndInfo, setYearEndInfo] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        yearEndValue(organizer).then((yearValue) => {
            setYearEndInfo(yearValue);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        })

    }, [organizer])

    if (isLoading) {
        return (
            <div>
                loading...
            </div>
        )
    }

    return (
        <div>
            {yearEndInfo}
        </div>
    );
};

export default LoadYearEnd