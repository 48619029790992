// common modules
import React from 'react';

// custom modules
import RestfulTable from '@components/restfulTable/RestfulTable.js';
import * as NAV from '@utilities/constants/navigation';
import Breadcrumb from '@components/Breadcrumb.js';

function ManageClients(props) {
    const resourceName = 'Client';
    const resourceUri = 'clients';
    const columns = [
        { key: 'name', text: 'Name', create: { show: true, required: true }, edit: { show: true, required: true } },
        { key: 'number', text: 'Number', create: { show: true, required: true, }, edit: { show: true, required: true } },
        { key: 'csl', text: 'CSL', create: { show: true, required: true, }, edit: { show: true, required: true } }
    ];
    const defaultSortKey = 'firstName';
    const defaultSortOrder = 'ascending';

    const navItems = [
        { to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
        { to: NAV.MANAGE_CLIENTS, label: 'Clients', current: true }
    ];

    const filterFn = (searchValue) => (item) => {
		return item.name?.toLowerCase().includes(searchValue);
	};

    return (
        <div className="pracDashboardSize">
            <Breadcrumb items={navItems} />
            <h1>Clients</h1>
            <RestfulTable resourceName={resourceName} resourceUri={resourceUri} columns={columns} defaultSortKey={defaultSortKey} defaultSortOrder={defaultSortOrder} pageSize={5} filterFn={filterFn}></RestfulTable>
        </div>
    );
}

export default ManageClients;