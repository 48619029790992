import { Divider } from "react-md";
import { useState } from "react";
import _ from 'lodash';
import FormBody from "../exportRenderer/collapsibleForm/formBody/index";
import { getTotalByYear, getYear } from "../utils/getYear";

function SubSectionHeader(props) {
  const { section, 
    sectionClass, 
    groups,
    setIsSubSecCollapsed, 
    hasLineItemSection, 
    denseRowCollapsed, 
    setDenseRowCollapsed } = props;
  const subSectionGroup = section.groups
    ? section.groups.find((x) => x.hasSubSectionTotalDisplay)
    : null;
  //TODO: add implementation for showing total miles for vehicle
  const footerFieldlabels = subSectionGroup
    ? subSectionGroup.subSectionTotalType === "miles"
      ? subSectionGroup.fields
      : subSectionGroup.groupType === "lineItem"
      ? subSectionGroup.lineItemDetails.footerLabels
      : subSectionGroup.fields
    : [];
  const footerField = footerFieldlabels.find((x) => x.isSubSectionTotal);
  const [collapsed, setCollapsed] = useState(false);
  // const leftDisplay = footerField ? subSectionGroup.subSectionTotalType === 'miles' ? '0 ' :'$ ' : '';
  // const rightDisplay = footerField ? subSectionGroup.subSectionTotalType === 'miles' ? 'miles' : subSectionGroup.groupType === 'lineItem' ? footerField.label : footerField.default ? footerField.default : ' 0': '';

  const handleClick = () => {
    setCollapsed(!collapsed);
    // need this for conditional subSections margin bottom
    //  when toggled
    setIsSubSecCollapsed(!collapsed);
  };

  const styles = (label) => (label.length > 20 ? { width: "174.1px" } : null);

  const headerLabels = (amounts) => {
    return amounts.map(
      (item, index) =>
        item && (
          <div
            key={`headerLabels-${section.sectionId}-${index}`}
            className="headerTotalLabel"
            style={styles(item.label)}
          >
            <div>
              <p>{item.year} Total:</p>
            </div>
            <div>
              <p>
                {typeof item.label === "string"
                  ? item.label
                  : `$${item.label.toLocaleString()}`}
              </p>
            </div>
          </div>
        )
    );
  };

  const individualHeaderLabel = (amount) => {
    return (
      <div
        key={`headerLabels-${section.sectionId}`}
        className="headerTotalLabel"
        style={styles(amount.label)}
      >
        <div>
          <p>{amount.year} Total:</p>
        </div>
        <div>
          <p>
            {typeof amount.label === "string"
              ? amount.label
              : `$${amount.label.toLocaleString()}`}
          </p>
        </div>
      </div>
    );
  };

  // TODO: Fix H2's header total not showing if year is undefined
  const headerTotals = () => {
    let totalAmounts = [];
    let prior,
      current = {};
    const labels = section.groups
      ? section.groups.find(
          (item) =>
            item.lineItemDetails && item.lineItemDetails.footerLabels.length > 0
        )
      : null;

    let amounts =
      labels &&
      labels.lineItemDetails.footerLabels.filter(
        (item) => item.type === "totalMoney"
      );

    const amountsWithoutYear = amounts && amounts.filter((item) => !item.year);
    if (amountsWithoutYear && amountsWithoutYear.length > 0) {
      return headerLabels(amountsWithoutYear);
    }
    amounts = _.chunk(amounts, 2);
    if (amounts.length > 1) {
      prior = getTotalByYear(amounts, "prior");
      current = getTotalByYear(amounts, "current");
      totalAmounts = [prior, current].filter((item) => item);
    } else {
      totalAmounts = amounts
        .flat()
        .map((item) => getTotalByYear(amounts, item.year));
    }

    // NOTE: getTotalByYear expects amounts to have 2D array
    // amounts = _.chunk(amounts, 2);
    // prior = getTotalByYear(amounts, 'prior');
    // current = getTotalByYear(amounts, 'current');
    // totalAmounts = [prior, current];

    // Check if totalAmounts only has 1 data then render the label
    if (totalAmounts.length === 1 && totalAmounts[0].year === getYear('prior')) {
      const amount = totalAmounts[0];
      return (
        <>
          {individualHeaderLabel(amount)}
          <div className="headerTotalLabel"></div>
        </>
      );
    } else if (
      totalAmounts.length === 1 &&
      (totalAmounts[0].year === getYear('current') || totalAmounts[0].year === "")
    ) {
      const amount = totalAmounts[0];
      return (
        <>
          <div className="headerTotalLabel"></div>
          {individualHeaderLabel(amount)}
        </>
      );
    }

    return headerLabels(totalAmounts);
  };

  const renderHeaderTotal = () => {
    return footerField
      ? subSectionGroup.subSectionTotalType === "miles"
        ? "0 miles"
        : subSectionGroup.groupType === "lineItem"
        ? headerTotals(footerField)
        : footerField.default
        ? headerTotals(footerField)
        : headerTotals(footerField)
      : "";
  };

  const renderSections = () => {
    return (
      <div
        key={`subSecExpanded-${section.title}-${section.sectionId}}`}
        className={sectionClass}
      >
        {groups}
      </div>
    );
  };

  const key = `${section.title}-${section.sectionId}}`;

  return (
    <div key={`subSecExpanded-${key}`}>
      <FormBody
        key={`formBody-subSecExpanded-${key}`}
        sectionType="subSection"
        title={section.title}
        collapsed={collapsed}
        renderHeaderTotal={renderHeaderTotal}
        handleClick={handleClick}
        renderSections={renderSections}
        isDenseRow={section.isDenseRow}
        hasLineItemSection={hasLineItemSection}
        setDenseRowCollapsed={setDenseRowCollapsed}
        denseRowCollapsed={denseRowCollapsed}
      />
      {collapsed && (
        <div className="formSubSectionDivider">
          <Divider />
        </div>
      )}
    </div>
  );
}

export default SubSectionHeader;
