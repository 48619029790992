import { Radio, GridCell } from 'react-md';
import * as LOOKUPS from '@utilities/constants/lookupInfo';

function OptionButton(props) {
    const { field, isFormLocked, parentIndex } = props;
    const lookups = LOOKUPS[field.lookup] || LOOKUPS.YESNO_RADIO;
    const options = lookups.map((lookup) => lookup.value);

    const radioStyle = {
        float: 'right',
        position: 'relative',
        margin: '0px 5px'
    };

    const radioCellStyle = {
        marginTop: '-5px',
    };

    const radio = options.map((option) => (
        <Radio
            id={`radio-${field.name}-${option}-${parentIndex}`}
            key={`radio-${field.name}-${option}-${parentIndex}`}
            name={`radio-${field.name}-${option}-${parentIndex}`}
            trigger={field.trigger || null}
            label={option}
            value={option}
            style= {radioStyle}
            checked= {field.default === option}
            onChange={() => {}}
            disabled={isFormLocked}
            readOnly
        />
    ));

    return (
        <GridCell colSpan={6} key={`cell-${field.name}`} style={field.styles || radioCellStyle}>
            {/* <Label style={lblAlign} key={`lbl-${field.name}`} htmlFor={field.name}>{field.label}</Label> */}
            {radio}
        </GridCell>
    );
}

export default OptionButton;
