export const BINARY = 'binary';
export const UNARY = 'unary';

// Constant query states below are used for the PractitionerDashboard component
export const PREFETCH_LIMIT = 250;
export const PAGE_LIMIT = 10;
export const DEFAULT_FILTER_QUERY = [];
export const DEFAULT_SORT_QUERY = { sort: 'client.name', order: 'asc' };
export const COLUMN_TO_DATA_MAP = {
  name: 'client.name',
  csl: 'client.number',
  // year end does not sort
  crl: 'crl.initials',
  signer: 'signer.initials',
  inCharge: 'inCharge.initials',

  status: 'status',
  cchPush: 'updatedOn',
  lastEdit: 'updatedOn',
  lock: 'locked'
};

export const OPERATORS_MAP = {
  contains: { key: 'co', type: BINARY },
  equals: { key: 'eq', type: BINARY },
  startsWith: { key: 'sw', type: BINARY },
  endsWith: { key: 'ew', type: BINARY },
  isEmpty: { key: 'ie', type: UNARY },
  isNotEmpty: { key: 'ine', type: UNARY },
};

export const OPERATORS_LIST = ['contains', 'equals', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'];


