export const DEFAULT = '/';
export const DASHBOARD = '/dashboard';
export const PRACTITIONERS_DASHBOARD = '/exchangeManager';
export const ADMIN_DASHBOARD = '/admin';
export const MANAGE_CLIENTS = '/admin/clients';
export const MANAGE_ORGANIZERS = '/admin/organizers';
export const MANAGE_NOTES = '/admin/notes';
export const MANAGE_FORMS = '/admin/forms';
export const MANAGE_GROUPS = '/admin/groups';
export const MANAGE_USERS = '/admin/users';
export const MANAGE_IN_CHARGE = '/admin/inCharge';

export const BOARD_OF_DIRECTORS_AND_COMPENSATION = '/boardOfDirectorsAndCompensation';
export const ENTRY_INTERVIEW = `${DASHBOARD}#welcome`;
export const BASIC_DATA = '/basicData';
export const PROGRAMS = '/programs';
export const INTERNAL_SETUP = '/internalSetup';
export const TAX_COMPLIANCE_FILINGS = '/taxComplianceFilings';
export const GOVERNANCE_AND_MANAGEMENT = '/governanceAndManagement';
export const FINANCIALS = '/financials';
export const CONTRIBUTIONS = '/contributions';
export const FUNDRAISING_AND_GAMING = '/fundraisingAndGaming';
export const FOREIGN_ACTIVITIES = '/foreignActivities';
export const POLITICAL_CAMPAIGN_AND_LOBBYING = '/politicalCampaignAndLobbying';
export const DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE = '/domesticGrantsAndOtherAssistance';
export const LIQUIDATION_AND_DISPOSITION_OF_ASSETS = '/liquidationAndDispositionOfAssets';
export const PUBLIC_CHARITY_AND_SUPPORT = '/publicCharityAndSupport';
export const INTERESTED_PERSONS_TRANSACTIONS = '/interestedPersonsTransactions';
export const RELATED_ORGANIZATIONS_SCHED_R = '/relatedOrganizationsSched.R';
export const SUPPLEMENTAL_FINANCIAL_STATEMENTS = '/supplementalFinancialStatements';
export const TAX_EXEMPT_BONDS = '/taxExemptBonds';
export const HOSPITAL = '/hospital';
export const SCHOOLS = '/schools';
export const UNRELATED_BUSINESS_INCOME_TAX = '/unrelatedBusinessIncomeTax';
export const ORGANIZER_EXPORT = '/export';
export const HOME = `/home.js`;
export const CACHE_BREAK = `/cachebreak.js`;