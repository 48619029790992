import _ from 'lodash';
import { findFromPYData } from '@utilities/axcessTaxPull';

// return true/false based on match

const filterTypeContainsAnyValue = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;
	const { key } = lineFilter;

	const foundKey = lineData.find(x => x.key === key);

	if (!foundKey) return false;

	return !_.isEmpty(foundKey.value) ? true : false;
};

const filterTypeContainsAny = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;

	const { key, filterData } = lineFilter;
	const foundKey = lineData.find(x => x.key === key);

	if (!foundKey || !_.isArray(filterData)) return false;
	return filterData.includes(foundKey.value);
};

const filterTypeNotContainsAny = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;

	const { key, filterData } = lineFilter;
	const foundKey = lineData.find(x => x.key === key);

	if (!foundKey || !_.isArray(filterData)) return false;
	const foundValue = foundKey.value.toLowerCase();
	return filterData.some(x => foundValue.includes(x.toLowerCase()));
};

const filterTypeContainsKeyWithValue = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;
	const { key, value } = lineFilter;

	const hasMatch = lineData.some(x => x.key === key && x.value === value);

	return hasMatch ? true : false;
};

const filterTypeContainsKey = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;
	const { key } = lineFilter;

	const foundKey = lineData.find(x => x.key === key);
	return foundKey ? true : false;
};

const filterTypeNotInKeyGroup = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;
	const { key, filterData } = lineFilter;

	const lineFromGroup = filterData.some(x => lineData.some(y => y?.key?.startsWith(x)));
	const foundKey = lineData.find(x => x.key === key);

	return lineFromGroup && !foundKey ? true : false;
};

const filterTypeNotZero = (lineData, lineFilter) => {
	if (!lineData || !_.isArray(lineData) || !lineFilter) return false;
	const { keys } = lineFilter;

	const isZeroOrMissing = keys.some((key) => {
		const foundKey = lineData.find(x => x.key === key);

		return foundKey && _.toSafeInteger(foundKey?.value) !== 0;
	});

	return isZeroOrMissing ? true : false;
};

const filterTypeEntityNotZero = (pyData, lineFilter) => {
	if (!pyData || !_.isArray(pyData) || !lineFilter) return false;
	const { keys } = lineFilter;

	const isZeroOrMissing = keys.some((key) => {
		const foundKey = findFromPYData(pyData, { name: key });

		return foundKey && _.toSafeInteger(foundKey[0].value) !== 0;
	});

	return isZeroOrMissing ? true : false;
};

const FILTER_TYPE_FUNCTIONS = {
	// Filter Types for PR row data
	containsAny: filterTypeContainsAny,
	notContainsAny: filterTypeNotContainsAny,
	containsKey: filterTypeContainsKey,
	containsKeyWithValue: filterTypeContainsKeyWithValue,
	notInKeyGroup: filterTypeNotInKeyGroup,
	containsAnyValue: filterTypeContainsAnyValue,
	inGroupNotZero: filterTypeNotZero,
	// Filter Types for PY Entities
	inEntityNotZero: filterTypeEntityNotZero,
};

const filterLine = (lineData) => (lineFilter) => {
	const { filterType } = lineFilter;

	const filterTypeFunction = FILTER_TYPE_FUNCTIONS[filterType];

	if (filterTypeFunction) {
		return filterTypeFunction(lineData, lineFilter);
	}

	return false;
};

const lineFilterAny = (curr, lineFilters) => {
	const lineFilterResult = lineFilters.map(filterLine(curr)).reduce((prevRes, currRes) => prevRes || currRes, false);
	return lineFilterResult ? true : false;
};

const lineFilterOmitAny = (curr, lineFilters) => {
	const lineFilterResult = lineFilters.map(filterLine(curr)).reduce((prevRes, currRes) => prevRes || currRes, false);
	return !lineFilterResult ? true : false;
};

const LINE_FILTER_FUNCTIONS = {
	any: lineFilterAny,
	omitAny: lineFilterOmitAny,
};

const filterGroupLines = (dataLines, lineFilterType, lineFilters) => {
	const lineFilterFunction = LINE_FILTER_FUNCTIONS[lineFilterType];
	if (!lineFilterType || !lineFilterFunction) return dataLines;

	const filteredList = dataLines.reduce((prev, curr) => {

		if (lineFilterFunction(curr, lineFilters)) {
			prev.push(curr);
		}

		return prev;
	}, []);

	return filteredList;
};

const matchesCondition = (dataLine, lineFilterType, lineFilters) => {
	const lineFilterFunction = LINE_FILTER_FUNCTIONS[lineFilterType];
	if (!lineFilterType || !lineFilterFunction) return false;

	return lineFilterFunction(dataLine, lineFilters);
};

export {
	filterTypeContainsAny,
	filterTypeNotContainsAny,
	filterTypeContainsKey,
	filterTypeContainsAnyValue,
	filterTypeContainsKeyWithValue,
	filterTypeNotZero,
	filterLine,
	lineFilterAny,
	lineFilterOmitAny,
	filterGroupLines,
	matchesCondition,
};