import { SCREEN_SIZES } from '@utilities/constants/screenSizes';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { isDesktop, isMobile } from 'react-device-detect';

const isIPadOrTabletDevice = () =>
  (window.matchMedia(
    '(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait)'
  ).matches ||
    window.matchMedia(
      '(min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape)'
    ).matches) &&
  'ontouchstart' in window;

const useAdaptive = () => {
  const { REDUX, selectState } = usePageFramework();
  const screenSize = selectState(REDUX.SCREEN_SIZE);

  return {
    isMobile:
      screenSize === SCREEN_SIZES.MOBILE ||
      screenSize === SCREEN_SIZES.MOBILE_480,
    isMobile480: screenSize === SCREEN_SIZES.MOBILE_480,
    isTablet: screenSize === SCREEN_SIZES.TABLET,
    isLaptop: screenSize === SCREEN_SIZES.LAPTOP,
    isDesktop: screenSize === SCREEN_SIZES.DESKTOP,
    isLaptopOrDesktop:
      screenSize === SCREEN_SIZES.LAPTOP || screenSize === SCREEN_SIZES.DESKTOP,
    isDesktopDevice: isDesktop && !isIPadOrTabletDevice(),
    isMobileDevice: isMobile,
  };
};

export default useAdaptive;
