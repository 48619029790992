import * as EVENT from '@utilities/constants/triggerKeys';
import * as LOOKUPS from '@utilities/constants/lookupInfo';

const showProfessionalFundraising = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;
  name[1].isVisible = event === EVENT.KEY_YES;

}

const showFundraisingEvents = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;
  name[2].isVisible = event === EVENT.KEY_YES;
}

const showCharitableGaming = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;
  name[3].isVisible = event === EVENT.KEY_YES;
  name[4].isVisible = event === EVENT.KEY_YES;
}

const showFundGmActStateLcnsExp = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_NO) {
    name[3].groups[3].isVisible = true;
  } else {
    name[3].groups[3].isVisible = false;
  }

  setFormSections(name);
}

const showFundGmActStateLcnsTermExp = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_YES) {
    name[3].groups[5].isVisible = true;
  } else {
    name[3].groups[5].isVisible = false;
  }

  setFormSections(name);
}

const showTenHighFund = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_YES) {
    name[1].groups[10].isVisible = true;
    name[1].groups[11].isVisible = true;
  } else {
    name[1].groups[10].isVisible = false;
    name[1].groups[11].isVisible = false;
  }
}

const showFundGmMgr3Pty = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_YES) {
    name[4].groups[4].isVisible = true;
    name[4].groups[5].isVisible = true;
    name[4].groups[6].isVisible = true;
    name[4].groups[7].isVisible = true;
  } else {
    name[4].groups[4].isVisible = false;
    name[4].groups[5].isVisible = false;
    name[4].groups[6].isVisible = false;
    name[4].groups[7].isVisible = false;
  }
}

const showFundGmMgrAmtDist = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_YES) {
    name[4].groups[14].isVisible = true;
  } else {
    name[4].groups[14].isVisible = false;
  }
}

const showGmMgrStateProvince = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  const isUnitedStates = event === EVENT.KEY_UNITED_STATES;
  const isCanada = event === EVENT.KEY_CANADA;
  const isOther = !isUnitedStates && !isCanada

  name[4].groups[2].fields[7].isVisible = isUnitedStates; //fundGmMgrPrepAmericaState
  name[4].groups[2].fields[6].isVisible = isCanada; //fundGmMgrPrepProvince
  name[4].groups[2].fields[5].isVisible = isOther; //fundGmMgrPrepState

}

const showGmMgr3PtyStateProvince = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  const isUnitedStates = event === EVENT.KEY_UNITED_STATES;
  const isCanada = event === EVENT.KEY_CANADA;
  const isOther = !isUnitedStates && !isCanada

  name[4].groups[5].fields[7].isVisible = isUnitedStates; //fundGmMgrPrepAmericaState
  name[4].groups[5].fields[6].isVisible = isCanada; //fundGmMgrPrepProvince
  name[4].groups[5].fields[5].isVisible = isOther; //fundGmMgrPrepState
}

const setFundEvntNameTypeSummary = (options) => {
  const { group, parentGroup, parentIndex } = options;

  let fundEventName = 'asdfasdfasdf';

  group.fields.forEach((groupField) => {
    if (groupField.name === 'fundEvntNameType') {
      fundEventName = groupField.default;
    }
  })


  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'fundEvntNameTypeSummary') {
      if (fundEventName.length > 20) {
        lineItemField.default =`${fundEventName.substring(0,17)}...`;
      } else if (fundEventName.length <= 20){
        lineItemField.default =`${fundEventName}`;
      }
    }
  });
}

const showFundActivityStateProvince = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  const isUnitedStates = event === EVENT.KEY_UNITED_STATES;
  const isCanada = event === EVENT.KEY_CANADA;
  const isOther = !isUnitedStates && !isCanada

  name[0].groups[1].fields[6].isVisible = isUnitedStates; //fundActivityAmericaState
  name[0].groups[1].fields[5].isVisible = isCanada; //fundActivityProvince
  name[0].groups[1].fields[4].isVisible = isOther; //fundActivityState
}

const calculatefundActivityTotPaid = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  const grossReceipts = name[0].groups[4].fields[1].default;

  const fundActivityFeePaid = name[0].groups[5].fields[1].default;

  const fundActivityTotPaid = grossReceipts - fundActivityFeePaid;

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const contribSecRule1CalcDollar = `$${dollerUSlocale.format(fundActivityTotPaid.toFixed())}`;

  name[0].groups[6].fields[1].default = contribSecRule1CalcDollar
};

const setFundActivityName = (options) => {
  const { group, parentGroup, parentIndex } = options;

  let fundName = '';

  group.fields.forEach((groupField) => {
    if (groupField.name === 'fundActivityName') {
      fundName = groupField.default;
    }
  });


  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'tenHighFundName') {
      if (fundName.length > 20) {
        lineItemField.default = `${fundName.substring(0,17)}...`;
      } else if (fundName.length <=20) {
        lineItemField.default = `${fundName}`;
      }
    }
  });
}

const setFundActivityAddress = (options) => {
  const { group, parentGroup, parentIndex } = options;

  let fundAddCountry = '';
  let fundAdd1 = '';
  let fundAddCity = '';
  let fundAddProvinceState = '';
  let fundAddProvince = '';
  let fundAddState = '';
  let fundAddZipPostal = '';

  group.fields.forEach((groupField) => {
    if (groupField.name === 'fundActivityCountry') {
      const lookupName = groupField.lookup;
      fundAddCountry = LOOKUPS[lookupName]?.find(country => country.value === groupField.default)?.name || '';
    }
    if (groupField.name === 'fundActivityAdd1') {
      fundAdd1 = groupField.default
    }
    if (groupField.name === 'fundActivityCity') {
      fundAddCity = groupField?.default?.trim() ? groupField.default + ',' : '';
    }
    if (groupField.name === 'fundActivityState' && groupField.isVisible === true) {
      fundAddProvinceState = groupField?.default?.trim() ? groupField.default + ',' : '';
    }
    if (groupField.name === 'fundActivityProvince' && groupField.isVisible === true) {
      fundAddProvince = groupField?.default?.trim() ? groupField.default + ',' : '';
    }
    if (groupField.name === 'fundActivityAmericaState' && groupField.isVisible === true) {
      fundAddState = groupField?.default?.trim() ? groupField.default + ',' : '';
    }
    if (groupField.name === 'fundActivityZip') {
      fundAddZipPostal = groupField.default
    }
  })

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'tenHighFundAdd') {
      if (fundAdd1.length > 20) {
        lineItemField.default = `${fundAdd1.substring(0,17)}...\n ${fundAddCity} ${fundAddProvinceState} ${fundAddProvince} ${fundAddState} ${fundAddCountry}\n ${fundAddZipPostal}`;
      } else if (fundAdd1.length <= 20) {
        lineItemField.default = `${fundAdd1}\n ${fundAddCity} ${fundAddProvinceState} ${fundAddProvince} ${fundAddState} ${fundAddCountry}\n ${fundAddZipPostal}`;
      }
    }
  });
}

const setFundActivityActv = (options) => {
  const { group, parentGroup, parentIndex } = options;

  let fundType = '';

  group.fields.forEach((groupField) => {
    if (groupField.name === 'fundActivityActv') {
      fundType = groupField.default;
    }
  });


  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'tenHighFundType') {
      if (fundType.length > 20) {
        lineItemField.default = `${fundType.substring(0,17)}...`;
      } else if (fundType.length <= 20) {
        lineItemField.default = `${fundType}`;
      }
    }
  });
}

const setFundActivityGrossRcpt = (options) => {
  const { group, parentGroup, parentIndex } = options;

  let grossReceipts = '';

  group.fields.forEach((groupField) => {
    if (groupField.name === 'fundActivityGrossRcpt') {
      grossReceipts = groupField.default;
    }
  });

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const formattedGrossReceipts = `$${dollerUSlocale.format(grossReceipts)}`;

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'tenHighFundGrossReceipts') {
      lineItemField.default = `${formattedGrossReceipts}`
    }
  })
}

const setFundActivityFeePaid = (options) => {
  const { group, parentGroup, parentIndex } = options;

  let feePaid = '';

  group.fields.forEach((groupField) => {
    if (groupField.name === 'fundActivityFeePaid') {
      feePaid = groupField.default;
    }
  })

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const formattedFeePaid = `$${dollerUSlocale.format(feePaid)}`;

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'tenHighFundRetFund') {
      lineItemField.default = `${formattedFeePaid}`
    }
  })
}

const setFundActivityTotPaid = (options) => {
  const { section, parentGroup, parentIndex } = options;

  let totalPaidRetained = '';
  totalPaidRetained = section.groups[4].fields[1].default - section.groups[5].fields[1].default;

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const formattedTotalPaidRetained = `$${dollerUSlocale.format(totalPaidRetained.toFixed())}`;

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'tenHighFundRetOrg') {
      lineItemField.default = `${formattedTotalPaidRetained}`
    }
  })
}

const fundEvntGrossRcptsErrorVal = ( options) => {
  const { section} = options;
  const fundEvntGrossRcpts = Number(section.groups[0].fields[1].default);
  const fundEvntFMVByAttnd =Number(section.groups[1].fields[1].default);

  if (fundEvntGrossRcpts < fundEvntFMVByAttnd) {
    section.groups[0].fields[1].error = true;
    section.groups[0].fields[1].errorMessage = 'Gross Receipts cannot be less than FMV Received by Attendees';
  }
else{
  section.groups[0].fields[1].error = false;
  section.groups[1].fields[1].error = false;
  section.groups[0].fields[1].errorMessage = null;
  section.groups[1].fields[1].errorMessage = null;
}
};
const fundEvntFMVByAttndErrorval = (options) => {
  const {  section} = options;
const fundEvntGrossRcpts = Number(section.groups[0].fields[1].default);
  const fundEvntFMVByAttnd =Number(section.groups[1].fields[1].default);

  if ( fundEvntFMVByAttnd > fundEvntGrossRcpts) {
    section.groups[1].fields[1].error = true;
    section.groups[1].fields[1].errorMessage = 'FMV Received by Attendees cannot be more than Gross Receipts';
  }
else{
  section.groups[0].fields[1].error = false;
  section.groups[1].fields[1].error = false;
  section.groups[0].fields[1].errorMessage = null;
  section.groups[1].fields[1].errorMessage = null; 
}
};

const calculateFundEvntCalcContr = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  const fundEvntGrossRcpts = Number(name[1].groups[0].fields[1].default);

  const fundEvntFMVByAttnd = Number(name[1].groups[1].fields[1].default);

  let fundEvntCalcContr = fundEvntGrossRcpts - fundEvntFMVByAttnd;

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const contribSecRule1CalcDollar = `$${dollerUSlocale.format(fundEvntCalcContr.toFixed(2))}`;

  name[1].groups[2].fields[1].default = contribSecRule1CalcDollar;
};

const showFundEvntAuctItmFMV = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_YES) {
    name[1].groups[4].isVisible = true;
  } else {
    name[1].groups[4].isVisible = false;
  }
}

const showFundEvntAuctSvcs = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_YES) {
    name[1].groups[5].isVisible = true;
  } else {
    name[1].groups[5].isVisible = false;
  }
}

const showFundEvntAuctSvcsFMV = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  const fundEvntAuct = name[1].groups[3].fields[1].default === EVENT.KEY_YES;
  const fundEvntAuctSvcs = name[1].groups[5].fields[1].default === EVENT.KEY_YES;

  if (fundEvntAuctSvcs && fundEvntAuct) {
    name[1].groups[6].isVisible = true;
  } else {
    name[1].groups[6].isVisible = false;
  }
}

const showFundEvntAuctGrssPrcd = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_YES) {
    name[1].groups[7].isVisible = true;
  } else {
    name[1].groups[7].isVisible = false;
  }
}

const showFundEvntAuctPur = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_YES) {
    name[1].groups[8].isVisible = true;
  } else {
    name[1].groups[8].isVisible = false;
  }
}

const showFundEvntAuctPurAmtPd = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  const fundEvntAuct = name[1].groups[3].fields[1].default === EVENT.KEY_YES;
  const fundEvntAuctPurYes = name[1].groups[8].fields[1].default === EVENT.KEY_YES;

  if (fundEvntAuct && fundEvntAuctPurYes) {
    name[1].groups[9].isVisible = true;
  } else {
    name[1].groups[9].isVisible = false;
  }
}

const showFundEvntCashCntrbRcvd = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_YES) {
    name[1].groups[11].isVisible = true;
  } else {
    name[1].groups[11].isVisible = false;
  }
}

const showFundEvntNonCashCntrbRcvd = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_YES) {
    name[1].groups[13].isVisible = true;
  } else {
    name[1].groups[13].isVisible = false;
  }
}

const showFundEvntNonCashCntrbRcvdDesc = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_YES) {
    name[1].groups[14].isVisible = true;
  } else {
    name[1].groups[14].isVisible = false;
  }
}

const setfundEvntGrossRcptsSummary = (options) => {
  const { group, parentGroup, parentIndex } = options;

  let fundEvntGrossReceipt = '';

  group.fields.forEach((groupField) => {
    if (groupField.name === 'fundEvntGrossRcpts') {
      fundEvntGrossReceipt = groupField.default;
    }
  });

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const formattedFundEvntGrossReceipts = `$${dollerUSlocale.format(fundEvntGrossReceipt)}`;

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'fundEvntGrossRcptsSummary') {
      lineItemField.default = `${formattedFundEvntGrossReceipts}`;
    }
  });
}

const calculateFundEvntExpTotal = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  const fundEvntExpCshPrz = parseFloat(name[2].groups[0].fields[1].default);

  const fundEvntExpNonCshPrz = parseFloat(name[2].groups[1].fields[1].default);

  const fundEvntExpRntCst = parseFloat(name[2].groups[2].fields[1].default);

  const fundEvntExpFdBv = parseFloat(name[2].groups[3].fields[1].default);

  const fundEvntExpEnt = parseFloat(name[2].groups[4].fields[1].default);

  const fundEvntExpOthDir = parseFloat(name[2].groups[5].fields[1].default);

  const fundEvntExpTotal = fundEvntExpCshPrz + fundEvntExpNonCshPrz + fundEvntExpRntCst + fundEvntExpFdBv + fundEvntExpEnt + fundEvntExpOthDir;

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const contribSecRule1CalcDollar = `Total Expenses: \n $${dollerUSlocale.format(fundEvntExpTotal.toFixed())}`;

  name[2].groups[6].fields[0].default = contribSecRule1CalcDollar
};

const setfundEvntExpTotalSummary = (options) => {
  const { section, parentGroup, parentIndex } = options;

  let fundEvntExpTotalSummary = '';

  const fundEvntExpCshPrz = parseFloat(section.groups[0].fields[1].default);

  const fundEvntExpNonCshPrz = parseFloat(section.groups[1].fields[1].default);

  const fundEvntExpRntCst = parseFloat(section.groups[2].fields[1].default);

  const fundEvntExpFdBv = parseFloat(section.groups[3].fields[1].default);

  const fundEvntExpEnt = parseFloat(section.groups[4].fields[1].default);

  const fundEvntExpOthDir = parseFloat(section.groups[5].fields[1].default);

  fundEvntExpTotalSummary = fundEvntExpCshPrz + fundEvntExpNonCshPrz + fundEvntExpRntCst + fundEvntExpFdBv + fundEvntExpEnt + fundEvntExpOthDir;

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const formattedfundEvntExpTotal = `$${dollerUSlocale.format(fundEvntExpTotalSummary)}`;

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'fundEvntExpTotalSummary') {
      lineItemField.default = `${formattedfundEvntExpTotal}`;
    }
  });
}

const setFundGmGrsRvSummary = (options) => {
  const { group, parentGroup, parentIndex } = options;

  let fundGmGrsRv = '';

  group.fields.forEach((groupField) => {
    if (groupField.name === 'fundGmGrsRv') {
      fundGmGrsRv = groupField.default;
    }
  });

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const formattedFundGmGrsRv = `$${dollerUSlocale.format(fundGmGrsRv)}`;

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'fundGmGrsRvSummary') {
      lineItemField.default = `${formattedFundGmGrsRv}`;
    }
  });
}

const setFundGmCashSummary = (options) => {
  const { group, parentGroup, parentIndex } = options;

  let fundGmGrsRv = '';

  group.fields.forEach((groupField) => {
    if (groupField.name === 'fundGmCashPrz') {
      fundGmGrsRv = groupField.default;
    }
  });

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const formattedFundGmGrsRv = `$${dollerUSlocale.format(fundGmGrsRv)}`;

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'fundGmCashSummary') {
      lineItemField.default = `${formattedFundGmGrsRv}`;
    }
  });
}

const setFundGmNonCashPrzSummary = (options) => {
  const { group, parentGroup, parentIndex } = options;

  let fundGmGrsRv = '';

  group.fields.forEach((groupField) => {
    if (groupField.name === 'fundGmNonCashPrz') {
      fundGmGrsRv = groupField.default;
    }
  });

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const formattedFundGmGrsRv = `$${dollerUSlocale.format(fundGmGrsRv)}`;

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'fundGmNonCashPrzSummary') {
      lineItemField.default = `${formattedFundGmGrsRv}`;
    }
  });
}

const setFundGmRentFacCostSummary = (options) => {
  const { group, parentGroup, parentIndex } = options;

  let fundGmGrsRv = '';

  group.fields.forEach((groupField) => {
    if (groupField.name === 'fundGmRentFacCost') {
      fundGmGrsRv = groupField.default;
    }
  });

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const formattedFundGmGrsRv = `$${dollerUSlocale.format(fundGmGrsRv)}`;

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'fundGmRentFacCostSummary') {
      lineItemField.default = `${formattedFundGmGrsRv}`;
    }
  });
}

const setFundGmOthDirExpSummary = (options) => {
  const { group, parentGroup, parentIndex } = options;

  let fundGmGrsRv = '';

  group.fields.forEach((groupField) => {
    if (groupField.name === 'fundGmOthDirExp') {
      fundGmGrsRv = groupField.default;
    }
  });

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const formattedFundGmGrsRv = `$${dollerUSlocale.format(fundGmGrsRv)}`;


  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'fundGmOthDirExpSummary') {
      if (formattedFundGmGrsRv.length > 15) {
        lineItemField.default = `${formattedFundGmGrsRv.substring(0,15)}...`;
      } else if (formattedFundGmGrsRv.length <= 15) {
        lineItemField.default = `${formattedFundGmGrsRv}`;
      }
    }
  });
}

const setFundGmVolLaborPctgSummary = (options) => {
  const { parentGroup, parentIndex, field } = options;
  const parentSection = parentGroup.entities[parentIndex].sections;

  let fundGmGrsRv = '';
  let isfundGmActType = false;

  parentSection.forEach((section, index) => {
    if (index === 0) {
      section.groups.forEach(group => {
        group.fields.forEach(field1 => {
          if (field1.name === 'fundGmVolLaborPctg' || field.name === 'fundGmVolLaborPctg') {
            fundGmGrsRv = field.name === 'fundGmVolLaborPctg' ? field.default : field1.default;
          }
          if (field1.name === 'fundGmVolLabor' || field.name === 'fundGmVolLabor') {
            isfundGmActType = field.name === 'fundGmVolLabor' ? field.default : field1.default;
          }
        })
      })
    }
  });

  const dollerUSlocale = Intl.NumberFormat('en-US');
  const formattedFundGmGrsRv = isfundGmActType === EVENT.KEY_YES && !!fundGmGrsRv ? `${dollerUSlocale.format(fundGmGrsRv)}%` : `%-`;

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'fundGmVolLaborPctgSummary') {
      lineItemField.default = `${formattedFundGmGrsRv}`;
    }
  });
}


const showFundGmVolLaborPctg = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;

  if (event === EVENT.KEY_YES) {
    name[0].groups[6].isVisible = true;
  } else {
    name[0].groups[6].isVisible = false;
  }
}

const hideEntityPercentField = (options) => {
    const { group, index } = options;
    group.entities[index].sections[0].groups[6].isVisible = false;
};

const triggeredEvent = (trigger, event, options) => {
  switch (trigger) {
    case 'showProfessionalFundraising':
      showProfessionalFundraising(event, options);
      break;
    case 'showFundraisingEvents':
      showFundraisingEvents(event, options);
      break;
    case 'showCharitableGaming':
      showCharitableGaming(event, options);
      break;
    case 'showFundGmActStateLcnsExp':
      showFundGmActStateLcnsExp(event, options);
      break;
    case 'showFundGmActStateLcnsTermExp':
      showFundGmActStateLcnsTermExp(event, options);
      break;
    case 'showTenHighFund':
      showTenHighFund(event, options);
      break;
    case 'showFundGmMgr3Pty':
      showFundGmMgr3Pty(event, options);
      break;
    case 'showFundGmMgrAmtDist':
      showFundGmMgrAmtDist(event, options);
      break;
    case 'showGmMgrStateProvince':
      showGmMgrStateProvince(event, options);
      break;
    case 'showGmMgr3PtyStateProvince':
      showGmMgr3PtyStateProvince(event, options);
      break;
    case 'showFundActivityStateProvince':
      showFundActivityStateProvince(event, options);
      break;
    case 'calculatefundActivityTotPaid':
      calculatefundActivityTotPaid(event, options);
      break;
    case 'calculateFundEvntCalcContr':
      calculateFundEvntCalcContr(event, options);
      break;
    case 'showFundEvntAuctItmFMV':
      showFundEvntAuctItmFMV(event, options);
      break;
    case 'showFundEvntAuctSvcs':
      showFundEvntAuctSvcs(event, options);
      break;
    case 'showFundEvntAuctSvcsFMV':
      showFundEvntAuctSvcsFMV(event, options);
      break;
    case 'showFundEvntAuctGrssPrcd':
      showFundEvntAuctGrssPrcd(event, options);
      break;
    case 'showFundEvntAuctPur':
      showFundEvntAuctPur(event, options);
      break;
    case 'showFundEvntAuctPurAmtPd':
      showFundEvntAuctPurAmtPd(event, options);
      break;
    case 'showFundEvntCashCntrbRcvd':
      showFundEvntCashCntrbRcvd(event, options);
      break;
    case 'showFundEvntNonCashCntrbRcvd':
      showFundEvntNonCashCntrbRcvd(event, options);
      break;
    case 'showFundEvntNonCashCntrbRcvdDesc':
      showFundEvntNonCashCntrbRcvdDesc(event, options);
      break;
    case 'calculateFundEvntExpTotal':
      calculateFundEvntExpTotal(event, options);
      break;
    case 'showFundGmVolLaborPctg':
      showFundGmVolLaborPctg(event, options);
      break;
     
   
    default:
      break;
  }
};

const sectionFieldLogic = (logicFunction, options) => {
  switch (logicFunction) {
    case 'setFundEvntNameTypeSummary':
      setFundEvntNameTypeSummary(options);
      break;
    case 'setFundActivityName':
      setFundActivityName(options);
      break;
    case 'setFundActivityAddress':
      setFundActivityAddress(options);
      break;
    case 'setFundActivityActv':
      setFundActivityActv(options);
      break;
    case 'setFundActivityGrossRcpt':
      setFundActivityGrossRcpt(options);
      break;
    case 'setFundActivityFeePaid':
      setFundActivityFeePaid(options);
      break;
    case 'setFundActivityTotPaid':
      setFundActivityTotPaid(options);
      break;
    case 'setfundEvntGrossRcptsSummary':
      setfundEvntGrossRcptsSummary(options);
      break;
    case 'setfundEvntExpTotalSummary':
      setfundEvntExpTotalSummary(options);
      break;
    case 'setFundGmGrsRvSummary':
      setFundGmGrsRvSummary(options);
      break;
    case 'setFundGmCashSummary':
      setFundGmCashSummary(options);
      break;
    case 'setFundGmNonCashPrzSummary':
      setFundGmNonCashPrzSummary(options);
      break;
    case 'setFundGmRentFacCostSummary':
      setFundGmRentFacCostSummary(options);
      break;
    case 'setFundGmOthDirExpSummary':
      setFundGmOthDirExpSummary(options);
      break;
    case 'setFundGmVolLaborPctgSummary':
      setFundGmVolLaborPctgSummary(options);
      break;
    case 'hideEntityPercentField':
      hideEntityPercentField(options);
      break;
      case 'fundEvntGrossRcptsErrorVal':
        fundEvntGrossRcptsErrorVal( options);
        break;
      case 'fundEvntFMVByAttndErrorval':
        fundEvntFMVByAttndErrorval(options);
        break;
     
    // case 'showFundGmVolLaborPctg':
    //   showFundGmVolLaborPctg(options);
    //   break;
    default:
      break;
  }
};

export {
  triggeredEvent,
  sectionFieldLogic
};