// common modules
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';

// custom modules
import '@views/PractitionerDashboard.css';
import OrganizerList from '@components/OrganizerList.js';
import api from '@utilities/claApi';
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as STRING from '@utilities/constants/strings';
import { PREFETCH_LIMIT, PAGE_LIMIT, DEFAULT_FILTER_QUERY, DEFAULT_SORT_QUERY, COLUMN_TO_DATA_MAP, OPERATORS_MAP } from '@utilities/constants/practitionerDashboard';
import useServerSideMUI from '@utilities/hooks/useServerSideMUI';

function PractitionerDashboard() {
  const { dispatch, ACTION } = usePageFramework();
  const [isLoaded, setIsLoaded] = useState(false);
  const [reloadOrgList, setReloadOrgList] = useState()
  const [paginatedLoad, setPaginatedLoad] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [prefetchedData, setPrefetchedData] = useState([]);
  const [data, setData] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const previousOrganizerQuery = useRef(null);

  const payload = {
    defaultFilter: DEFAULT_FILTER_QUERY,
    defaultSort: DEFAULT_SORT_QUERY,
    columnToDataMap: COLUMN_TO_DATA_MAP,
    operatorsMap: OPERATORS_MAP,
  };
  const {
    handleFilterModelChange,
    handleSortModelChange,
    handlePaginationModelChange,
    currentPageNumber,
    filterQueryParameters,
    filterMethodParameters,
    sortQueryParameters,
  } = useServerSideMUI(payload);

  const buildOrganizerQuery = (limit, offset, filtering, sorting, filterMethodParameters) => {
    const defaultQuery = 'status ne Pending Delete';
    const filterQuery = filtering && filtering.length ? [...filtering, defaultQuery].map(x => `&filter=${x}`).join('') : `&filter=${defaultQuery}`;
    const filterMethodQuery = filterMethodParameters ? `&filterMethod=${filterMethodParameters}` : '';
    const sortQuery = sorting && Object.entries(sorting).length ? Object.entries(sorting).map(([param,query]) => `&${param}=${query}`).join('') : '';

    return `limit=${limit}&offset=${offset}${filterMethodQuery}${filterQuery}${sortQuery}`;
  };

  useEffect(() => {
    // On page load, set pracDash data
    dispatch(ACTION.setToolbarTitle(STRING.EXCHANGE_MANAGER_990));
    // eslint-disable-next-line
  }, [dispatch, ACTION]);

  useEffect(() => {
    setPaginatedLoad(true);

    const totalOffset = currentPageNumber * PAGE_LIMIT;
    const prefetchOffset = Math.floor(totalOffset / PREFETCH_LIMIT) * PREFETCH_LIMIT;
    const prefetchPageOffset = totalOffset % PREFETCH_LIMIT

    const organizerQuery = buildOrganizerQuery(PREFETCH_LIMIT, prefetchOffset, filterQueryParameters, sortQueryParameters, filterMethodParameters);
    // If the resulting queries are the same, grab organizer data from the prefetched set
    if (previousOrganizerQuery.current !== null && organizerQuery === previousOrganizerQuery.current && reloadOrgList === undefined) {
      const resultSlice = prefetchedData.slice(prefetchPageOffset, prefetchPageOffset + PAGE_LIMIT);
      setData(resultSlice);
      setPaginatedLoad(false);
      return;
    }

    previousOrganizerQuery.current = organizerQuery;

    api.get(`/organizers?${organizerQuery}`).then((response) => {
      return response.data;
    }).then((response) => {
      const { results, total } = response;

      const resultSlice = results.slice(prefetchPageOffset, prefetchPageOffset + PAGE_LIMIT);

      setPrefetchedData(results);
      setData(resultSlice);
      setTotalResults(total);
    }).catch((error) => {
      console.error(error);
      setHasError(true);
    }).finally(() => {
      setIsLoaded(true);
      setPaginatedLoad(false);
    });
    // eslint-disable-next-line
  }, [currentPageNumber, filterQueryParameters, sortQueryParameters, filterMethodParameters, reloadOrgList]);

  const syncParentData = (organizers) => {
    setData(organizers);

    const totalOffset = currentPageNumber * PAGE_LIMIT;
    const prefetchPageOffset = totalOffset % PREFETCH_LIMIT

    const prefetch = _.cloneDeep(prefetchedData);
    prefetch.splice(prefetchPageOffset, prefetchPageOffset + PAGE_LIMIT, ...organizers);
    setPrefetchedData(prefetch);
  };

  return (
    <div className="pracDashboardSize pracDashboardSizeAdjHeight ">
      {
        isLoaded
          ? hasError
            ? <>An error has occurred while loading the exchange manager. Please try again later.</>
            : <OrganizerList
              organizers={data}
              pageSize={10}
              currentPageNumber={currentPageNumber}
              totalResults={totalResults}
              paginatedLoad={paginatedLoad}
              handlePaginationModelChange={handlePaginationModelChange}
              handleFilterModelChange={handleFilterModelChange}
              handleSortModelChange={handleSortModelChange}
              syncParentData={syncParentData}
              setReloadOrgList={setReloadOrgList}
            />
          : <>Loading...</>
      }
    </div>
  );
}

export default PractitionerDashboard;