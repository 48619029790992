// import * as REDUX from '@utilities/constants/redux';

export const isExportSectionFiltered = (formName) => {
    const exportFormSectionsFiltered = [];

    return exportFormSectionsFiltered.find(form => formName.startsWith(form));
}

const handleSectionVisibility = (entitySection) => {
    entitySection.groups.forEach(entitySectionGroup => {
        if (entitySectionGroup.lineItems && entitySectionGroup.lineItems.length) {
            entitySection.isVisible = true;
        } else if (entitySectionGroup.fields && entitySectionGroup.fields.length) {
            entitySectionGroup.fields.forEach((field) => {
                if (field.type === 'viewButton') return;
                else if (field.type === 'checkbox' && !field.default) 
                    entitySection.isVisible = false;
                else if (
                    field.inputState && 
                    !(field.default?.trim() !== '' || 
                    (field.type === 'money' && field.default?.trim() !== '0'))
                    ) {
                    entitySection.isVisible = false;
                }
            });
        }
    });
}

export const checkValuedFields = (section, formSections) => {
    let valuedFieldsCount = 0;
    if (section.subSections && !section.groups.length) {
        formSections.forEach(formSection => {
            section.subSections.forEach(subsection => {
                if (subsection.subSectionId === formSection.sectionId) {
                    valuedFieldsCount += checkValuedFields(formSection)
                }
            })
        })
        return valuedFieldsCount;
    }
    else if (section.groups && section.groups.length) {
        if (section.title === 'Passthrough Information') {
            section.groups.forEach(group => {
                if (group.entities && group.entities.length) {
                    group.entities.forEach((entity) => {
                        if (group.lineItems && group.lineItems.length) {
                            entity.sections.forEach((entitySection, sectionIdx) => {
                                if (sectionIdx === 0) {
                                    handleSectionVisibility(entitySection);
                                }
                            })
                        }
                    });
                }
            });
            
        }

        section.groups.forEach(group => {
            if (group.lineItems && group.lineItems.length) {
                group.lineItems.forEach((lineItem) => {
                    lineItem.forEach((field) => {
                        if (field.type === 'viewButton') return;
                        else if (field.type === 'checkbox' && field.default) valuedFieldsCount++;
                        else if (field.inputState && (field.default?.trim() !== '' || (field.type === 'money' && field.default?.trim() !== '0'))) {
                            valuedFieldsCount++;
                        }
                    });
                });
            } else if (group.fields && group.fields.length) {
                group.fields.forEach((field) => {
                    if (field.type === 'viewButton') return;
                    else if (field.type === 'checkbox' && field.default) valuedFieldsCount++;
                    else if (field.inputState && (field.default?.trim() !== '' || (field.type === 'money' && field.default?.trim() !== '0'))) {
                        valuedFieldsCount++;
                    }
                });
            }
            //for dense row line sections / entities
            else if (group.entities && group.entities.length) {
                group.entities.forEach((entity) => {
                    if (entity.sections && entity.sections.length) {
                        entity.sections.forEach((section) => {
                            valuedFieldsCount = checkValuedFields(section);
                        })
                    }
                });
            }
        });

        if (section.isSubSection && valuedFieldsCount === 0) section.isVisible = false;
    }

    return valuedFieldsCount;
}