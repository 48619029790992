import {
    ExpansionPanel,
} from 'react-md';

const GlossaryTerms = ({ terms, panels, searchTerm }) => {
    let termsList = [];
    let termListNum = 1;
    for (let i = 0; i < terms?.length; i++) {
        let termNum = i + 1;
        if (searchTerm === "") {
            termsList.push(
                <ExpansionPanel {...panels[i]} header={`${termNum}. ${terms[i]?.term}`} className="expansion-panel" data-testid="expansion-panel">
                    <div className="term-description" dangerouslySetInnerHTML={{__html: terms[i]?.description}}></div>
                </ExpansionPanel>
            )
        } else if (terms[i]?.term.toLowerCase().includes(searchTerm.toLowerCase())) {
            termsList.push(
                <ExpansionPanel {...panels[i]} header={`${termListNum}. ${terms[i]?.term}`} className="expansion-panel" data-testid="expansion-panel">
                    <div className="term-description" dangerouslySetInnerHTML={{__html: terms[i]?.description}}></div>
                </ExpansionPanel>
            )
            termListNum += 1;
        }
    }
    return termsList
};

export default GlossaryTerms;
