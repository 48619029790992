import * as EVENT from '@utilities/constants/triggerKeys';


const showLiquidationDissolutionSections = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_YES) {
        name[1].isVisible = true;
        name[2].isVisible = true;
    } else {
        name[1].isVisible = false;
        name[2].isVisible = false;
    }

    setFormSections(name);

}

const showSaleExchangeSections = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_YES) {
        name[3].isVisible = true;
        name[4].isVisible = true;
    } else {
        name[3].isVisible = false;
        name[4].isVisible = false;
    }
    setFormSections(name);
}

const showAssetLiqPrsnInvldExpl = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const assetLiqSuccess = name[2].groups[1].fields[1].default
    const assetLiqEmplee = name[2].groups[2].fields[1].default
    const assetLiqOwner = name[2].groups[3].fields[1].default
    const assetLiqComp = name[2].groups[4].fields[1].default

    if (assetLiqSuccess || assetLiqEmplee || assetLiqOwner || assetLiqComp) {
        name[2].groups[5].isVisible = true;
        name[2].groups[6].isVisible = true;
    } else {
        name[2].groups[5].isVisible = false;
        name[2].groups[6].isVisible = false;
    }

    setFormSections(name);
}

const showAssetLiqGovernInstDesc = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_YES) {
        name[2].groups[8].isVisible = true;
    } else {
        name[2].groups[8].isVisible = false;
    }

    setFormSections(name);
}

const showAssetLiqReqNoticeMade = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;


    if (event === EVENT.KEY_YES) {
        name[2].groups[10].isVisible = true;
    } else {
        name[2].groups[10].isVisible = false;
    }

    setFormSections(name);
}

const showAssetLiqReqNoticeNotMade = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const assetLiqReqNotice = name[2].groups[9].fields[1].default === EVENT.KEY_YES
    const assetLiqReqNoticeMade = name[2].groups[10].fields[1].default === EVENT.KEY_NO

    if (assetLiqReqNotice && assetLiqReqNoticeMade) {
        name[2].groups[11].isVisible = true;
    } else {
        name[2].groups[11].isVisible = false;
    }

    setFormSections(name);
}

const showAssetLiqDischgAllLiabNo = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_NO) {
        name[2].groups[13].isVisible = true;
    } else {
        name[2].groups[13].isVisible = false;
    }

    setFormSections(name);
}

const showAssetLiqBondsDschrg = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_YES) {
        name[2].groups[15].isVisible = true;
    } else {
        name[2].groups[15].isVisible = false;
    }

    setFormSections(name);
}

const showAssetLiqBondsDschrgExplTrns = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const assetLiqBondsYes = name[2].groups[14].fields[1].default === EVENT.KEY_YES
    const assetLiqBondsNo = name[2].groups[14].fields[1].default === EVENT.KEY_NO
    const assetLiqBondsDschrgYes = name[2].groups[15].fields[1].default === EVENT.KEY_YES
    const assetLiqBondsDschrgNo = name[2].groups[15].fields[1].default === EVENT.KEY_NO


    if (assetLiqBondsYes && assetLiqBondsDschrgYes) {
        name[2].groups[16].isVisible = true;
        name[2].groups[17].isVisible = false;
    } else if (assetLiqBondsYes && assetLiqBondsDschrgNo) {
        name[2].groups[16].isVisible = false;
        name[2].groups[17].isVisible = true;
    } else if (assetLiqBondsNo && assetLiqBondsDschrgNo) {
        name[2].groups[16].isVisible = false;
        name[2].groups[17].isVisible = false;
    } else if (assetLiqBondsNo && assetLiqBondsDschrgYes) {
        name[2].groups[16].isVisible = false;
        name[2].groups[17].isVisible = false;
    }


    setFormSections(name);
}

const showAssetLiqBondsDschrgTrnsDesc = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const assetLiqBondsYes = name[2].groups[14].fields[1].default === EVENT.KEY_YES
    const assetLiqBondsDschrgNo = name[2].groups[15].fields[1].default === EVENT.KEY_NO
    const assetLiqBondsDschrgTrnsYes = name[2].groups[17].fields[1].default === EVENT.KEY_YES

    if (assetLiqBondsYes && assetLiqBondsDschrgNo && assetLiqBondsDschrgTrnsYes) {
        name[2].groups[18].isVisible = true;
    } else {
        name[2].groups[18].isVisible = false;
    }

    setFormSections(name);
}

const showAssetLiqCodeEntType = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    if (event === EVENT.KEY_YES) {
        name[0].groups[5].isVisible = true;
        name[0].groups[6].isVisible = false;
    } else if (event === EVENT.KEY_NO) {
        name[0].groups[5].isVisible = false;
        name[0].groups[6].isVisible = true
    }

    setFormSections(name)

}

const showassetLiqAddStateOrProvince = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    const isUnitedStates = event === EVENT.KEY_UNITED_STATES;
    const isCanada = event === EVENT.KEY_CANADA;
    const isOther = !isUnitedStates && !isCanada

    name[0].groups[3].fields[6].isVisible = isUnitedStates;
    name[0].groups[3].fields[5].isVisible = isCanada;
    name[0].groups[3].fields[4].isVisible = isOther;
}

const showAssetSaleCodeEntType = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    const assetSaleTaxExmptYes = event === EVENT.KEY_YES
    const assetSaleTaxExmptNo = event === EVENT.KEY_NO

    if (assetSaleTaxExmptYes) {
        name[0].groups[5].isVisible = true
        name[0].groups[6].isVisible = false
    } else if (assetSaleTaxExmptNo) {
        name[0].groups[5].isVisible = false
        name[0].groups[6].isVisible = true
    } else {
        name[0].groups[5].isVisible = false
        name[0].groups[6].isVisible = false
    }
}

const showAssetSaleStateOrProvince = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    const isUnitedStates = event === EVENT.KEY_UNITED_STATES;
    const isCanada = event === EVENT.KEY_CANADA;
    const isOther = !isUnitedStates && !isCanada;

    name[0].groups[3].fields[4].isVisible = isUnitedStates;
    name[0].groups[3].fields[5].isVisible = isCanada;
    name[0].groups[3].fields[6].isVisible = isOther;


}

const showAssetSalePrsnInvldExpl = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    const assetSaleSuccess = name[4].groups[1].fields[1].default;
    const assetSaleEmplee = name[4].groups[2].fields[1].default;
    const assetSaleOwner = name[4].groups[3].fields[1].default;
    const assetSaleComp = name[4].groups[4].fields[1].default;

    if (assetSaleSuccess || assetSaleEmplee || assetSaleOwner || assetSaleComp) {
        name[4].groups[5].isVisible = true;
        name[4].groups[6].isVisible = true;
    } else {
        name[4].groups[5].isVisible = false;
        name[4].groups[6].isVisible = false;
    }

}


const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'showLiquidationDissolutionSections':
            showLiquidationDissolutionSections(event, options);
            break;
        case 'showSaleExchangeSections':
            showSaleExchangeSections(event, options);
            break;
        case 'showAssetLiqPrsnInvldExpl':
            showAssetLiqPrsnInvldExpl(event, options);
            break;
        case 'showAssetLiqGovernInstDesc':
            showAssetLiqGovernInstDesc(event, options);
            break;
        case 'showAssetLiqReqNoticeMade':
            showAssetLiqReqNoticeMade(event, options);
            break;
        case 'showAssetLiqReqNoticeNotMade':
            showAssetLiqReqNoticeNotMade(event, options);
            break;
        case 'showAssetLiqDischgAllLiabNo':
            showAssetLiqDischgAllLiabNo(event, options);
            break;
        case 'showAssetLiqBondsDschrg':
            showAssetLiqBondsDschrg(event, options);
            break;
        case 'showAssetLiqBondsDschrgExplTrns':
            showAssetLiqBondsDschrgExplTrns(event, options);
            break;
        case 'showAssetLiqBondsDschrgTrnsDesc':
            showAssetLiqBondsDschrgTrnsDesc(event, options);
            break;
        case 'showassetLiqAddStateOrProvince':
            showassetLiqAddStateOrProvince(event, options);
            break;
        case 'showAssetLiqCodeEntType':
            showAssetLiqCodeEntType(event, options);
            break;
        case 'showAssetSaleCodeEntType':
            showAssetSaleCodeEntType(event, options);
            break;
        case 'showAssetSaleStateOrProvince':
            showAssetSaleStateOrProvince(event, options);
            break;
        case 'showAssetSalePrsnInvldExpl':
            showAssetSalePrsnInvldExpl(event, options);
            break;
        default:
            break;
    }
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        default:
            break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};