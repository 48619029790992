import { useState, useEffect } from 'react';
import usePageFramework from './usePageFramework';
import { useParams } from 'react-router-dom';
import useLoading from './useLoading';
import { getOrganizerById } from '@utilities/helpers/getOrganizerById';


const useLoadOrganizer = () => {
  const { organizerId, dispatch, ACTION, clearFormState } = usePageFramework();
  const { organizerId: paramOrganizerId } = useParams();
  const [isActiveClient, setIsActiveClient] = useState(paramOrganizerId === organizerId)
  const { isLoading, setIsLoading } = useLoading()

  useEffect(() => {
    if (paramOrganizerId !== organizerId) {
      getOrganizerById({ organizerId: paramOrganizerId, setIsLoading, setIsActiveClient, dispatch, ACTION, clearFormState })
    }
    // eslint-disable-next-line
  }, [])

  return {
    isActiveClient, isLoading, organizerId,
  }

}
export default useLoadOrganizer;