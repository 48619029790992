import * as EVENT from '@utilities/constants/triggerKeys';
import { forEach } from 'lodash';

const showActivitiesOutsideUSAndFF = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  const secondRadio = formSections[0].groups[1].fields[1].default;
  const thirdRadio = formSections[0].groups[2].fields[1].default;
  name[1].isVisible = event === EVENT.KEY_YES;
  name[4].isVisible = secondRadio === EVENT.KEY_YES || thirdRadio === EVENT.KEY_YES || event === EVENT.KEY_YES;
  setFormSections(name);
}

const showGrantsOthAssistOrgAndFF = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;
  const firstRadio = formSections[0].groups[0].fields[1].default;
  const thirdRadio = formSections[0].groups[2].fields[1].default;
  name[2].isVisible = event === EVENT.KEY_YES;
  name[4].isVisible = firstRadio === EVENT.KEY_YES || thirdRadio === EVENT.KEY_YES || event === EVENT.KEY_YES;
}

const showGrantsOthAssistIndvAndFF = (event, options) => {
  const { sections, formSections } = options;
  const name = formSections ? formSections : sections;
  const firstRadio = formSections[0].groups[0].fields[1].default;
  const secondRadio = formSections[0].groups[1].fields[1].default;
  name[3].isVisible = event === EVENT.KEY_YES;
  name[4].isVisible = secondRadio === EVENT.KEY_YES || firstRadio === EVENT.KEY_YES || event === EVENT.KEY_YES;
}

const showDescOne = (options) => {
  const { section } = options;

  section.groups[0].fields[2].isVisible = section.groups[0].fields[1].default === EVENT.KEY_YES;
};

const showDescTwoThree = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  
  name[4].groups[1].fields[2].isVisible = event === EVENT.KEY_YES;
  name[4].groups[1].fields[3].isVisible = event === EVENT.KEY_YES;
  
  setFormSections(name);
}

const showDescFour = (options) => {
  const { section } = options;

  section.groups[2].fields[2].isVisible = section.groups[2].fields[1].default === EVENT.KEY_YES;
};

const showDescFive = (options) => {
  const { section } = options;

  section.groups[3].fields[2].isVisible = section.groups[3].fields[1].default === EVENT.KEY_YES;
};

const showDescSix = (options) => {
  const { section } = options;

  section.groups[4].fields[2].isVisible = section.groups[4].fields[1].default === EVENT.KEY_YES;
};

const showDescSeven = (options) => {
  const { section } = options;

  section.groups[5].fields[2].isVisible = section.groups[5].fields[1].default === EVENT.KEY_YES;
};

const updateOrgGrntOutUsChrtyType = (event, options) => {
  let allFormSections = options.allFormSections;
  let strVal = allFormSections[2].groups[1].countOrgGrntOutUsRcptOrgNo.toString();
  let otherStrVal = allFormSections[2].groups[1].countOrgGrntOutUsOtherOrgNo.toString();
  allFormSections[2].groups[2].fields[1].label = strVal;
  allFormSections[2].groups[3].fields[1].label = otherStrVal;
  allFormSections[2].groups[2].fields[1].default = strVal;
  allFormSections[2].groups[3].fields[1].default = otherStrVal;
}

const setOrgGrntOutUsChrtyType = (options) => {
  const { field, parentGroup, parentIndex } = options;
  let countOrgGrntOutUsRcptOrgNo = 0;
  let countOrgGrntOutUsOtherOrgNo = 0;
  forEach(parentGroup.entities, (entity, index) => {
    if(parentIndex === index && field){
      entity.sections[0].groups[2].fields[1] = field;
      if(field.default === "501(c)(3)" || field.default === "Foreign Equiv-501(c)(3)"){
        countOrgGrntOutUsRcptOrgNo++;
      }else if(field?.default?.trim() !== ""){
        countOrgGrntOutUsOtherOrgNo++;
      }
    }else{
      const field = entity.sections[0].groups[2].fields[1];
      if(field.default === "501(c)(3)" || field.default === "Foreign Equiv-501(c)(3)"){
        countOrgGrntOutUsRcptOrgNo++;
      }else if(field?.default?.trim() !== ""){
        countOrgGrntOutUsOtherOrgNo++;
      }
    }
  });
  parentGroup.countOrgGrntOutUsRcptOrgNo = countOrgGrntOutUsRcptOrgNo;
  parentGroup.countOrgGrntOutUsOtherOrgNo = countOrgGrntOutUsOtherOrgNo;
}

const showActOutUSMaintRecExp = (options) => {
  const {field, section} = options;
  section.groups[1].isVisible = field.default === EVENT.KEY_NO;
}

const showProgramService = (event, options) => {
  const {allFormSections} = options;
  forEach(allFormSections[1].groups[4].lineItems, (item, index) => {
    allFormSections[1].groups[4].entities[index].sections[0].groups[2].isVisible = item[1].default === "Program Services";
  });
}

const showOrgGrntOutUsCodeIRC = (options) => {
  const {field, section} = options;
  section.groups[2].isVisible = field.default === EVENT.KEY_OTHER_501C;
}

const triggeredEvent = (trigger, event, options) => {
  switch (trigger) {
    case 'showActivitiesOutsideUSAndFF':
      showActivitiesOutsideUSAndFF(event, options);
      break;
    case 'showGrantsOthAssistOrgAndFF':
      showGrantsOthAssistOrgAndFF(event, options);
      break;
    case 'showGrantsOthAssistIndvAndFF':
      showGrantsOthAssistIndvAndFF(event, options);
      break;
    case 'showDescTwoThree':
      showDescTwoThree(event, options);
      break;
    case 'updateOrgGrntOutUsChrtyType':
      updateOrgGrntOutUsChrtyType(event, options);
      break;
    case 'showProgramService':
        showProgramService(event, options);
        break;
    default:
      break;
  }
};

const sectionFieldLogic = (logicFunction, options) => {
  switch (logicFunction) {
    case 'showDescOne':
      showDescOne(options);
      break;
    case 'showDescFour':
      showDescFour(options);
      break;
    case 'showDescFive':
      showDescFive(options);
      break;
    case 'showDescSix':
      showDescSix(options);
      break;
    case 'showDescSeven':
      showDescSeven(options);
      break;
    case 'setOrgGrntOutUsChrtyType':
      setOrgGrntOutUsChrtyType(options);
      break;
    case 'showActOutUSMaintRecExp':
      showActOutUSMaintRecExp(options);
      break;
    case 'showOrgGrntOutUsCodeIRC':
      showOrgGrntOutUsCodeIRC(options);
      break;
    default:
      break;
  }
};

export {
  triggeredEvent,
  sectionFieldLogic
};