import Organizer from '@components/organizerExport/organizer'

function OrganizerExport() {
    return (
        <div>
            <Organizer/>
        </div>
    )
}

export default OrganizerExport;