import './footer.css';
import * as IMG from '@utilities/constants/images';

function AppFooter() {
    const currentYear = new Date().getFullYear() || '2023';
    return(
        <div className="AppFooter" data-testid='app-footer-id'>
            <img className="appFooterLogo" data-testid='app-footer-logo-id' alt="CLA white logo" src={IMG.CLA_WHITE_LOGO} />
            <a className="appFooterText" data-testid='app-footer-text-id' href="https://www.claconnect.com/"> {`${currentYear} CliftonLarsonAllen LLP  |  CLAConnect.com`} </a>
            <p className="appFooterVersion" data-testid='app-footer-version-id'> {`App Version:${process.env.REACT_APP_VERSION}`}</p>
        </div>
    );
}

export default AppFooter; 