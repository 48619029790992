// import { useState } from 'react';
import { Button, useToggle } from 'react-md';
import * as IMG from '@utilities/constants/images';
import WarningDialog from '../../../dialogBox/warningDialog';

function InlineRemoveButton(props) {
    const { className, onClick } = props;
    const subtitleText = '';
    const questionText = 'Are you sure you want to permanently delete this record?';
    const confirmText = '';

    const [visibleWarningDialog, showWarningDialog, hideWarningDialog] = useToggle(false);

    const removeIcon = <Button className={className}
        data-testid='btn-inline-remove'
        disabled
        onClick={showWarningDialog} >
        <img alt='line item remove icon' src={IMG.removeIconNormal} />
    </Button>;

    const confirmRemove = () => {
        onClick();
        hideWarningDialog();
    };

    return (
        <WarningDialog subtitleText={subtitleText} questionText={questionText} confirmText={confirmText} onConfirmClick={confirmRemove}
            buttonElement={removeIcon}
            visible={visibleWarningDialog} onCancelWarning={hideWarningDialog}>
        </WarningDialog>
    );
}

export default InlineRemoveButton;