// common module
import {
	Button, useToggle,
	DropdownMenu, MenuItem,
	Collapse as ReactMDCollapse,
	TextIconSpacing, FontIcon
} from 'react-md';
import { useState, useEffect } from 'react';
import { AddSVGIcon, RemoveSVGIcon, MoreVertSVGIcon } from '@react-md/material-icons';
import ReactPaginate from 'react-paginate';
import _ from 'lodash';

// custom modules
import api from '@utilities/claApi';
import NotesPanel from '@components/NotesPanel.js';
import './expandedOrganizer.css';
import RelatedPeopleDialog from './relatedPeopleDialog';
import usePageFramework from '@utilities/hooks/usePageFramework';

const ExpandedOrganizer = (props) => {
	const { sectionTitle, rowData, changeData} = props;
	const { dispatch, ACTION } = usePageFramework(); 
	const [isCollapsed, setIsCollapsed] = useState(true);
	const [isLoaded, setIsLoaded] = useState(false);
	const [data, setData] = useState([]);
	const [notes, setNotes] = useState([])

    const [visibleAdd, showAdd, hideAdd] = useToggle(false);
    // const [visibleEdit, showEdit, hideEdit] = useToggle(false);
	// const [selectedPersonId, setSelectedPerson] = useState(null);

	//for pagination
    const itemsPerPage = 5;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
	const [pageCount, setPageCount] = useState(0);

	const changeLocalData = (organizerId, delta) => {
		setNotes(delta?.find(d => d.column === 'notes')?.value ?? []);
		changeData(organizerId, delta);
	};

	const handlePageClick = ({ selected: selectedPage }) => {
        const newOffset = selectedPage * itemsPerPage % data.length;
        setItemOffset(newOffset);
    }

	const sendWelcomeEmail = (person) => {
		api.put(`/organizers/${rowData.orgId}/users/${person.id}/invite`).then((response) =>
		{
			dispatch(ACTION.setShowCustomDialog(true));
			dispatch(ACTION.setCustomDialogTitle('Welcome email has been sent'));
			dispatch(ACTION.setCustomDialogMsg(''));
		}).catch((error) => {
			console.log(error);
			dispatch(ACTION.setShowCustomDialog(true));
			dispatch(ACTION.setCustomDialogTitle(''));
			dispatch(ACTION.setCustomDialogMsg('Please contact IT to remove this Client Contact record'));
		});
	};

	useEffect(() => {
		if (sectionTitle === 'Related People' && !isCollapsed) {
			api.get(`/organizers/${rowData.orgId}/users`).then((response) => {
				const resultData = _.cloneDeep(response.data.results);
				setData(resultData);
				setIsLoaded(true);
			}).catch((error) => {
				console.log(error);
				setIsLoaded(true);
			});
		}
	//eslint-disable-next-line
	}, [sectionTitle, isCollapsed]);

	useEffect(() => {
		api.get(`/organizers/${rowData.orgId}/notes`).then((response) => {
            return response.data.results;
		}).then((notes) => {
            setNotes(notes)
        }).catch((err) => {
            console.error(err);
        })
	}, [rowData.orgId])

	useEffect(() => {
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, data]);

	function sortByLastName(itemA, itemB) {
		return `${itemA.last} ${itemA.first}`.toLowerCase().localeCompare(`${itemB.last} ${itemB.first}`.toLowerCase());
	};

	function getMappedRole(role) {
		if (!role) return '';
		let mappedRole = role;
		switch(role) {
			case 'Portal Owner':
				mappedRole = 'Client Admin';
				break;
			case 'Basic User':
				mappedRole = 'Client User';
				break;
			default:
				mappedRole = role;
				break;
		}

		return mappedRole;
	};

	const relPeopleRows = () => {
		const inCharge = data.filter(person => person.role === 'In Charge');
		const others = data.filter(person => person.role !== 'In Charge');
		inCharge.sort(sortByLastName);
		others.sort(sortByLastName);
		//filter out claconnect internal users
		const relatedPeople = inCharge.concat(others).filter(user => !user?.email?.includes('@claconnect.com')).slice(itemOffset, endOffset);

		return relatedPeople.map((person, index) => (
			<>
				<div data-testid='relPeopleTableRow' className="relPeopleTableRow" key={`related-people-row-${index}`}>
					<div style={{color: '#2e334e'}}> {`${person.first || ''} ${person.last || ''}` || ''} </div>
					<div style={{fontWeight: '500'}}> {person.email || ''} </div>
					<div style={{fontSize: '13px'}}> {getMappedRole(person.role)} </div>
					<div style={{fontSize: '13px'}}>{person.notifications || 'All'}</div>
					<div className="headerCardMoreBtnContainer" style={{marginLeft: '-23px'}}>
							<DropdownMenu
								data-testid='relPersonEllipsis'
								className="headerCardMoreBtnContainer"
								id={`headerImgEllipsis-${index}`}
								buttonType="icon"
								aria-label="Actions"
								buttonChildren={ <MoreVertSVGIcon className="relPeopleEllipsis" />}
							>
								<MenuItem 
									key='related-people-edit-button'
									className='cardMenuItems'
									// future update person function??
									// onClick={() => {
									// 	setSelectedPerson(person.id)
									// 	showEdit();
									// }}
									onClick= {() => {
										dispatch(ACTION.setShowCustomDialog(true));
        								dispatch(ACTION.setCustomDialogTitle(''));
										dispatch(ACTION.setCustomDialogMsg('Please contact IT to update this Client Contact record'));
									}}
								> 
								Edit Information
								</MenuItem> 
								<MenuItem 
									key='related-people-remove-button'
									className='cardMenuItems'
									onClick= {() => {
										dispatch(ACTION.setShowCustomDialog(true));
        								dispatch(ACTION.setCustomDialogTitle(''));
										dispatch(ACTION.setCustomDialogMsg('Please contact IT to remove this Client Contact record'));
									}}
								> Remove Access
								</MenuItem> 
								<MenuItem 
									key='related-people-send-email-button'
									className='cardMenuItems'
									onClick= {() =>{
											sendWelcomeEmail(person);
										}}

								> Resend Welcome Email
								</MenuItem> 
							</DropdownMenu>
							{/* <RelatedPeopleDialog key={`edit-person-dialog-${index}`} peopleData={data} setData={setData} role={'edit'} visibleDialog={visibleEdit} hideDialog={hideEdit} personId={selectedPersonId} dialogTitle={'Edit Information'}/> */}
					</div>
				</div>
				<div className="relPeopleTableDivider" />
			</>
		));
	};

	return (
		<div
			key={`org-expanded-container-`}
			id={`org-expanded-container-`}
			className='orgExpandedContainer'
		>
			<div className='denseRowBtnContainer' style={{margin: '50px 0 0 15px'}}>
				<Button
					className='denseRowBtn'
					buttonType='icon'
					id={`org-expanded-collapse-btn-`}
					data-testid={`org-expanded-collapse-btn`}
					onClick={() => setIsCollapsed(!isCollapsed)}
					disableRipple
				>
					{
						isCollapsed ? <AddSVGIcon className="denseRowBtn" /> :
							<RemoveSVGIcon className="denseRowBtn" />
					}
				</Button>
			</div>
			<div className={'expandedRowSize'}>
				<div
					className='expandedOrgHeaderContainer' 
				>
					<div className='sectionHeaderTitle'>
						<div className="sectionHeaderTitleContainer">
							<p className='expandedRowTitle'> {sectionTitle} </p>
						</div>
					</div>
					<div className="expandDocManagerSectionContainer">
						<Button className="expandFormBtn" onClick={() => setIsCollapsed(!isCollapsed)}>
							{isCollapsed ? 'Expand' : 'Collapse'}
						</Button>
					</div>
				</div>
				<ReactMDCollapse collapsed={isCollapsed} className='collapsibleForm'>
					{ sectionTitle === 'Details' ? <NotesPanel changeData={changeLocalData} organizerId={rowData.orgId} clientName={rowData.name} notes={notes} setIsCollapsed={setIsCollapsed}></NotesPanel> :
					isLoaded ? <div className="docManagerGridContainer" style={{ paddingBottom: '20px', position: 'relative' }}>
						<div className="relPeopleHeaderContainer">
							<p>Name</p>
							<p>Email</p>
							<p>Role</p>
							<p>Notification Level</p>
							<p>Actions</p>
						</div>
						{relPeopleRows()}
						<Button 
							className="lineItemButton" 
							style={{float: 'left', paddingLeft: '30px', marginTop: '8px'}}
							onClick={showAdd}
						> 
							<TextIconSpacing 
								icon={
									<FontIcon className="addIcon">
										add
									</FontIcon>
								}>
								Add New Person
							</TextIconSpacing>
						</Button>
						<RelatedPeopleDialog 
                            key={'add-new-person-dialog'} 
                            peopleData={data} 
							setData={setData} 
							role={'add'} 
                            visibleDialog={visibleAdd} 
							hideDialog={hideAdd} 
                            organizerId={rowData.orgId} 
                            organizerStatus={rowData.status} 
							dialogTitle={'Add New Person'}
                        />
						<div style={{marginRight: '40px'}}>
							<ReactPaginate
								previousLabel='<'
								nextLabel='>'
								pageCount={pageCount}
								marginPagesDisplayed={1}
								pageRangeDisplayed={2}
								onPageChange={handlePageClick}
								containerClassName='pagination'
								pageClassName='orgExpandedPagination'
								activeClassName='paginationActivePage'
								breakLabel='...'
								breakClassName='page-item'
								breakLinkClassName='page-link'
								renderOnZeroPageCount={null}
							/> 
						</div>
						<div className="orgExpandedRowCircleIcon"></div>
					</div> : <>Loading...</>
					}
				</ReactMDCollapse >
			</div>
			<div className="orgExpandedRowConnectorLine"></div>
		</div >
	);
};

export default ExpandedOrganizer;