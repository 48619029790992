import * as EVENT from '@utilities/constants/triggerKeys';

const showOthFilingsFedErTaxRet = (options) => {
    const { section, field } = options;
    section.groups[4].isVisible = field.default > 0;
};

const showOthFilingsForeignCntry = (options) => {
    const { section, field } = options;

    section.groups[6].isVisible = field.default === EVENT.KEY_YES;
};

const showOthNpHealhInsIss = (options) => {
    const { section, field } = options;

    section.groups[8].isVisible = field.default === EVENT.KEY_NO;
    section.groups[7].isVisible = field.default === EVENT.KEY_NO;
    section.groups[9].isVisible = field.default === EVENT.KEY_YES;
    section.groups[10].isVisible = field.default === EVENT.KEY_YES;
};

const showOthFilingsPaymentRpt = (options) => {
    const { section, field } = options;

    const isOthFilingsPaymentRptNo = section.groups[12].fields[1].default === EVENT.KEY_NO;

    section.groups[12].isVisible = field.default === EVENT.KEY_YES;
    section.groups[13].isVisible = isOthFilingsPaymentRptNo && field.default === EVENT.KEY_YES;
};

const showOthFilingsNonPaymntRptRsn = (options) => {
    const { section, field } = options;
    
    section.groups[13].isVisible = field.default === EVENT.KEY_NO;
};

const showOthFilingsParachutePayData = (options) => {
    const { section, field } = options;

    section.groups[15].isVisible = field.default === EVENT.KEY_YES;
    section.groups[16].isVisible = field.default === EVENT.KEY_YES;
};

const showothFilingsNotifyNotTaxDeduct = (options) => {
    const { section, field } = options;

    section.groups[8].isVisible = field.default === EVENT.KEY_YES;
};

const showothFilingsNotifyValueGoods = (options) => {
    const { section, field } = options;

    section.groups[1].isVisible = field.default === EVENT.KEY_YES;
};

const showothFilingsNoFile8282 = (options) => {
    const { section, field } = options;

    section.groups[3].isVisible = field.default === EVENT.KEY_YES;
};

const showothFilingsForm1098Cfile= (options) => {
    const { section, field } = options;

    section.groups[9].isVisible = field.default === EVENT.KEY_YES;
};

const showothFilingsIntelPropForm8899 = (options) => {
    const { section, field } = options;

    section.groups[7].isVisible = field.default === EVENT.KEY_YES;
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        case 'showOthFilingsFedErTaxRet':
            showOthFilingsFedErTaxRet(options);
            break;
        case 'showOthFilingsForeignCntry':
            showOthFilingsForeignCntry(options);
            break;
        case 'showothFilingsNotifyNotTaxDeduct':
            showothFilingsNotifyNotTaxDeduct(options);
            break;
        case 'showOthNpHealhInsIss':
            showOthNpHealhInsIss(options);
            break;
        case 'showOthFilingsPaymentRpt':
            showOthFilingsPaymentRpt(options);
            break;
        case 'showOthFilingsParachutePayData':
            showOthFilingsParachutePayData(options);
            break;
        case 'showOthFilingsNonPaymntRptRsn':
            showOthFilingsNonPaymntRptRsn(options);
            break;
        case 'showothFilingsNotifyValueGoods':
            showothFilingsNotifyValueGoods(options);
        break;
        case 'showothFilingsNoFile8282':
            showothFilingsNoFile8282(options);
        break;
        case 'showothFilingsForm1098Cfile':
            showothFilingsForm1098Cfile(options);
        break;
        case 'showothFilingsIntelPropForm8899':
            showothFilingsIntelPropForm8899(options);
        break;
        default:
            break;
    }
};

export {
    sectionFieldLogic
};