import { Button, Dialog } from 'react-md';
import nextId from 'react-id-generator';
import '@components/WarningDialog.css';

function SubmitDialog(props){
    const { buttonElement, 
        onCancelWarning,
        onConfirmClick,
        visible, confirmText } = props;

    return(
        <>
            {buttonElement && buttonElement}
            <Dialog
                id={`warning-dialog${nextId()}`}
                role="alertdialog"
                visible={visible}
                onRequestClose={()=> {}}
                aria-labelledby="dialog-title"
                className="WarningDialogContainer"
                data-testid="warning-dialog"
            >
                <div className='WarningDialogInnerContainer' >
                    <div className="WarningDialogTextContainer">
                        <div className="WarningDialogText1"> {confirmText}</div>
                    </div>
                    <div className="warningDialogButton">
                        <Button className="warningDialogCancel" data-testid="dialog-cancel" id="dialog-cancel" onClick={onCancelWarning} > No </Button>
                        <Button className="warningDialogConfirm" data-testid="dialog-confirm" id="dialog-confirm" onClick={onConfirmClick} > Yes </Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default SubmitDialog;