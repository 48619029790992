import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { triggeredEvent, sectionFieldLogic } from './contributionsLogicTrigger';
import * as NAV from '@utilities/constants/navigation';
import { generateContributionsSections } from './helper';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';


let sections = [];
//TODO: need to refactor this to get the updated formstate titles
export const getContributionsTitles = () => {
	let titles = [];
	sections.forEach((section) => {
		if (!titles.includes(section.title)) {
			titles.push(section.title);
		}
	});
	return titles;
};

export const getContributionsSections = () => {
	return sections;
};

function Contributions({ isExportForm }) {
	const { updateCard, REDUX, selectState, setGlobalFormState } = usePageFramework(NAV.CONTRIBUTIONS);
	const [sectionState, setSectionState] = useState('');
	const formName = REDUX.CONTRIBUTIONS;
	const basicDataForm = selectState(REDUX.BASIC_DATA);
	const {isActiveClient, isLoading} = useLoadOrganizer();

	useEffect(() => {
		if (!isExportForm) updateCard();

		sections = generateContributionsSections({ setGlobalFormState, basicDataForm })

		setSectionState(sections);
		// eslint-disable-next-line 
	}, []);

	const axcessIdentifiers = [
		{ section: 'Schedules', id: 'Sch M - NonCash Contributions' },
	];

	return (
		<>
			{!isLoading && isActiveClient && <FormRendererHelper
				sections={sectionState}
				key={formName}
				formName={formName}
				triggeredEvent={triggeredEvent}
				sectionFieldLogic={sectionFieldLogic}
				identifiers={axcessIdentifiers}
				isExportForm={isExportForm}
			/>}
			{!isLoading && !isActiveClient && <NoActiveClient />}
		</>
	);
}
export default Contributions;