import html2pdf from 'html2pdf.js';
import UTIF from 'utif';
import { downloadDocumentFile } from '@utilities/apis/documentsApi';

export const renderTiffImage = (arrayBuffer, imageFile) => {
    let imageBase64;
    // Decode TIFF file using UTIF
    const ifds = UTIF.decode(arrayBuffer);
    UTIF.decodeImages(arrayBuffer, ifds);
    const rgba = UTIF.toRGBA8(ifds[0]);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = ifds[0].width;
    canvas.height = ifds[0].height;
    const imageData = ctx.createImageData(ifds[0].width, ifds[0].height);
    imageData.data.set(rgba);
    ctx.putImageData(imageData, 0, 0);
    imageBase64 = canvas.toDataURL(imageFile.mimeType);
    return imageBase64;
}

const getImageElement = async (organizerId, imageFile) => {
    // Download file and transform to base64 for image rendering
    const arrayBuffer = await downloadDocumentFile(organizerId, imageFile.id, { responseType: 'arraybuffer' });
    const base64 = Buffer.from(arrayBuffer, 'binary').toString('base64');
    const imageBase64 = `data:${imageFile.type};base64,${base64}`;

    // Render html image element
    return new Promise((resolve, reject) => {
        var image = new Image();
        image.src = imageBase64;
        image.onload = () => {
            resolve({
                content: image.outerHTML,
                width: image.width,
                height: image.height,
            });
        };
        image.onerror = (err) => {
            reject(err);
        };
    });
};

export const isFileToConvertToPdf = (file) => {
    const imageTypes = ['jpg', 'jpeg', 'png'];
    if (imageTypes.includes(file?.extension?.toLowerCase())) {
        return true;
    }

    return false;
};

export const getFileName = (file) => {
    if (!file || !file.name) {
        return '';
    }

    if (isFileToConvertToPdf(file)) {
        var filenames = file.name.split('.');
        filenames.pop();
        return `${filenames.join('.')}.pdf`;
    }

    return file.name;
};

/**
 * Helper function to convert a file to pdf using html2pdf
 * @param {*} organizerId
 * @param {*} imageFile
 * @param {*} returnAsBlob 
 * @returns saved pdf or blob representation of the file
 */
export const convertImageToPdf = async (organizerId, imageFile, returnAsBlob = false) => {
    if (!isFileToConvertToPdf(imageFile)) {
        return false;
    }

    try {
        const imgElement = await getImageElement(organizerId, imageFile);
        const pdfConfig = {
            margin: 1,
            filename: getFileName(imageFile),
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { width: imgElement.width, height: imgElement.height }
        };

        const pdfWorker = html2pdf().set(pdfConfig).from(imgElement.content);
        if (returnAsBlob) {
            return pdfWorker.output('blob');
        }
        return pdfWorker.outputPdf().save();
    } catch (err) {
        console.error(err);
        return null;
    };
};