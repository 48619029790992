import { useCallback, useState } from "react";

const { BINARY, UNARY } = require("@utilities/constants/practitionerDashboard");

const useServerSideMUI = (props) => {
  const { defaultFilter, defaultSort, columnToDataMap, operatorsMap } = props;

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [filterQueryParameters, setFilterQueryParameters] = useState(defaultFilter || null);
  const [filterMethodParameters, setFilterMethodParameters] = useState(null);
  const [sortQueryParameters, setSortQueryParameters] = useState(defaultSort || null);

  const parseToFilterString = (columnToDataMap, operatorsMap) => (filterItem) => {
    const dataField = columnToDataMap[filterItem?.field];
    const operatorValue = operatorsMap[filterItem?.operator];
    const filterValue = filterItem.value;
    if (dataField && operatorValue) {
      if (operatorValue.type === BINARY && filterValue?.trim()) {

        const filterString = `${dataField} ${operatorValue.key} ${filterValue?.trim()}`;
        return filterString;
      }
      if (operatorValue.type === UNARY) {
        const filterString = `${dataField} ${operatorValue.key}`;
        return filterString;
      }

      return null;
    } else {
      return null;
    }
  };

  const handleFilterModelChange = useCallback((filterModel) => {
    // Multi-filtering; iterate through all filterModal.items
    setCurrentPageNumber(0);

    const filterItems = filterModel?.items;
    const filterMethod = filterModel?.logicOperator;

    if (!filterItems || !filterItems.length) {
      setFilterQueryParameters(defaultFilter);
      return;
    }

    const filters = filterItems.map(parseToFilterString(columnToDataMap, operatorsMap)).filter(x => x);

    setFilterQueryParameters(filters);
    setFilterMethodParameters(filterMethod);
  }, [defaultFilter, columnToDataMap, operatorsMap]);

  const handleSortModelChange = useCallback((sortModel) => {
    // Growth: Handle parsing multi sort
    const sortItem = sortModel?.[0];

    if (sortItem) {
      const dataField = columnToDataMap[sortItem.field];

      if (dataField) {
        setSortQueryParameters({ sort: dataField, order: sortItem.sort });
      }
    } else {
      setSortQueryParameters(defaultSort);
    }
  }, [defaultSort, columnToDataMap]);

  const handlePaginationModelChange = (paginationModel) => {
    const { page } = paginationModel;
    setCurrentPageNumber(page);
  };

  return {
    handleFilterModelChange,
    handleSortModelChange,
    handlePaginationModelChange,

    currentPageNumber,
    filterQueryParameters,
    filterMethodParameters,
    sortQueryParameters,
  };
};

export default useServerSideMUI;