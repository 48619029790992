// common modules
import { Button } from 'react-md';
import { useState, useEffect } from "react";
import {
	Dialog,
	DialogHeader,
	DialogTitle,
	DialogContent,
	DialogFooter,
} from "@react-md/dialog";
import {
	Form,
	TextField,
	FormMessage,
	Checkbox
} from "@react-md/form";

// custom modules
import '@components/restfulTable/EditResourceDialog.css';

function EditResourceDialog(props) {
	const { visible, selected, resourceName, resourceProps, onCancel, onConfirm } = props;
	const [data, setData] = useState(selected);

	useEffect(() => {
		setData(selected);
	}, [selected]);

	const changeFieldValue = (event, col) => {
		const { value, checked } = event.target;

		setData((prev) => ({ ...prev, [col.key]: col.type === 'boolean' ? checked : value }));
	};

	const hasChanges = Object.keys(data).map((key) => !(!selected[key] && data[key] === '') && selected[key] !== data[key]).some(Boolean);

	return (
		<Dialog
			id="edit-resource-dialog"
			role="alertdialog"
			visible={visible}
			onRequestClose={() => { }}
			aria-labelledby="edit-resource-dialog-title"
			data-testid="edit-resource-dialog-title"
		>
			<DialogHeader>
				<DialogTitle>{`Edit ${resourceName}`}</DialogTitle>
			</DialogHeader>
			<DialogContent>
				<Form>
					{resourceProps.filter((props) => props.edit.show).map((col) => (
						col.type === 'boolean'
							? <div key={`checkbox-field-${col.key}-container`}>
								<Checkbox
									id={`checkbox-field-${col.key}`}
									key={`checkbox-field-${col.key}`}
									name={`checkbox-field-${col.key}`}
									label={col.text}
									checked={data[col.key] === true || data[col.key] === 'true' ? true : false}
									onChange={(event) => changeFieldValue(event, col)}
								/>
							</div>
							: <div key={`text-field-${col.key}-container`}>
								<TextField
									id={`text-field-${col.key}`}
									key={`text-field-${col.key}`}
									type="text"
									label={col.text}
									required={col.edit.required ? true : false}
									value={data[col.key] ? data[col.key] : ''}
									onChange={(event) => changeFieldValue(event, col)}>
								</TextField>
								<FormMessage id={`text-field-${col.key}-message`}></FormMessage>
							</div>
					))}
				</Form>
			</DialogContent>
			<DialogFooter>
				<Button id="dialog-submit" onClick={() => onConfirm(selected.id, data)} disabled={!hasChanges}>Save</Button>
				<Button id="dialog-close" onClick={() => onCancel()}>Close</Button>
			</DialogFooter>
		</Dialog>
	);
}

export default EditResourceDialog;