import { useState ,useEffect} from 'react';
import {
    Dialog,
} from 'react-md';
import EntryWelcomeIntro from '@components/entryExperience/welcomeIntro';
import Questionnaire from '@components/entryExperience/questionnaire';
import usePageFramework from '@utilities/hooks/usePageFramework';
import './entryModal.css';

function EntryExperienceModal(props) {
    const { onClose, isGoToQuestionnaire, setIsGoToQuestionnaire } = props;
    const { location } = usePageFramework()
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(!!location?.hash?.includes('#welcome'))

    useEffect(() => {
        setIsOpen(!!location?.hash?.includes('#welcome'))

    }, [location])



    return (
        <Dialog
            id="simple-dialog"
            visible={isOpen}
            onRequestClose={onClose}
            aria-labelledby="entry-experience-dialog-title"
            className={!isGoToQuestionnaire ? 'landingPageDialogContainer' : 'questionnaireDialogContainer'}
        >
            {
                isGoToQuestionnaire ?
                    <Questionnaire
                        onClose={onClose}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                    /> :
                    <EntryWelcomeIntro goToQuestionnaire={()=>setIsGoToQuestionnaire(true)} />
            }
        </Dialog>
    );
}

export default EntryExperienceModal;