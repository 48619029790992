// common modules
import React, { useState, useEffect } from 'react';
import { Button, FontIcon, Collapse } from 'react-md';
import { FileInput, Form } from '@react-md/form';
import Dropzone from 'react-dropzone';
import { isMobile } from 'react-device-detect';

// custom modules
import usePageFramework from '@utilities/hooks/usePageFramework';
import OrganizerUploadsDrawer from '@components/OrganizerUploadsDrawer';
import * as STRING from '@utilities/constants/strings';
import { ACCEPTED_FILE_TYPES } from '@utilities/constants/document';

import '@components/OrganizerUploads.scss';

function OrganizerUploads() {
  const { REDUX, selectState, onFileUpload } = usePageFramework();
  const organizerId = selectState(REDUX.ORGANIZER_ID);
  const uploadList = selectState(REDUX.UPLOAD_LIST) || [];
  const [collapsed, setCollapsed] = useState(true);
  const [style, setStyle] = useState();
  const activeDragStyle = { borderColor: '#76d2d3' };
  const inactiveDragStyle = { borderColor: '#979797' };

  const onUpload = async (event) =>
    await onFileUpload(
      event,
      null,
      null,
      uploadList,
      { updateState },
      STRING.DASHBOARD_SECTION_NAME
    );
  const handleDrop = (acceptedFiles) => {
    onFileUpload(
      null,
      acceptedFiles,
      null,
      uploadList,
      { updateState },
      STRING.DASHBOARD_SECTION_NAME
    );
    setStyle(inactiveDragStyle);
  };
  const updateState = () => setCollapsed(false);
  const filteredList = uploadList.filter((u) => u.form === STRING.DASHBOARD_SECTION_NAME);
  const uploadTotal = filteredList.length;
  const toggleListText = `${uploadTotal && !collapsed ? 'Hide ' : 'View '
    }${uploadTotal || ''} ${uploadTotal === 1 ? 'File' : 'Files'}`;

  useEffect(()=>{
    if(uploadTotal === 0){
      setCollapsed(true);
    }
  },[uploadTotal])

  return (
    <Dropzone
      id={`organizer-upload-dropzone-${organizerId}`}
      onDrop={handleDrop}
      onDragOver={() => setStyle(activeDragStyle)}
      onDragEnter={() => setStyle(activeDragStyle)}
      onDragLeave={() => setStyle(inactiveDragStyle)}
      noClick={true}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({ className: 'formUploadContainer', style })}>
          <input {...getInputProps()} />
          <div
            className={"formUploadHeaderContainer"}>
            <div className="sectionHeaderTitle">
              <div className="sectionHeaderTitleContainer">
                <div>
                  <p className="formTitle"> {STRING.DASHBOARD_FILE_UPLOADS}</p>
                </div>
                <div>
                  <FontIcon className="helpIcon">help</FontIcon>
                </div>
              </div>
              <div className="formUploadSubTitle">
                {' '}
                {isMobile
                  ? STRING.DASHBOARD_UPLOAD_SUBTITLE_MOBILE
                  : STRING.DASHBOARD_UPLOAD_SUBTITLE}{' '}
              </div>
            </div>
            <div className="expandFormContainer">
              <Button
                themeType="flat"
                id={`upload-button-${organizerId}`}
                onClick={() => { }}>
                <Form>
                  <FileInput
                    className="entryBackButton uploadButton"
                    id={`bulkUpload2-${organizerId}`}
                    onChange={onUpload}
                    buttonType={'text'}
                    theme="clear"
                    themeType="flat"
                    icon={null}
                    multiple={true}
                    accept={ACCEPTED_FILE_TYPES}>
                    Upload
                  </FileInput>
                </Form>
              </Button>
              <Button
                themeType="contained"
                data-testid="setCollapseBtnId"
                className={
                  uploadTotal
                    ? 'entryNextButton uploadButton'
                    : 'inactiveButton'
                }
                disabled={!uploadTotal}
                onClick={
                  uploadTotal ? () => setCollapsed(!collapsed) : () => { }
                }>
                {toggleListText}
              </Button>
            </div>
          </div>
          <Collapse
            collapsed={collapsed}
            id={`form-upload-collapse-${organizerId}`}>
            <div>
              <OrganizerUploadsDrawer
                uploadList={filteredList}
                onUpload={onUpload}
              />
            </div>
          </Collapse>
        </div>
      )}
    </Dropzone>
  );
}

export default OrganizerUploads;
