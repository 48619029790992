import ReactModal from 'react-modal-resizable-draggable';
import { useEffect, useState } from 'react';
import GlossarySearch from '../GlossarySearch';
import api from '@utilities/claApi';
import GlossaryTerms from '../GlossaryTerms';
import '../glossarymodal.css';
// import api from '@utilities/claApi';
import {
    CloseSVGIcon,ExpansionList,
    usePanels,
} from 'react-md';

const GlosaryModal = ({ top, left, setDialog }) => {
    const [terms, setTerms] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        api.get(`/terms`).then((response) => {
            setTerms(response.data.terms);
        }).catch(err => {
            console.log("Error: ", err.message);
        })
    }, [])

    const panelsCount = terms?.length ? terms.length : 1;

    const [panels, onKeyDown] = usePanels({ idPrefix: "single-panel", count: panelsCount });

    const onClick = () => {
        setDialog(false);
    }
     
    return <ReactModal
        initWidth={272}
        initHeight={443}
        top={20}
        left={left}
        minHeight={400}
        actions={<button>Close</button>}
        // onFocus={() => console.log("Modal is clicked")}
        className={"my-modal-custom-class"}
        isCloseButton={true}
        style={{ header: { color: '#fff' } }}
        isOpen={true}>

        <div id="glossary-dialog-container" data-testid='glossary-dialog-container'>
            < div className="glossary-header ">
                <div className="glossary-header-row ">
                    <div className="glossary-title-container">
                        <div className='glossaryTitle'>Glossary
                        </div>
                        <div className='glossarySubTitle'>Type your glossary term
                        </div>
                    </div>
                    <div id="delete-dialog-close" data-testid="delete-dialog-close" onClick={onClick} ><CloseSVGIcon className="closeIcon" /></div>
                </div>
            </div>
            <div className="glossary-body">
                <div className="searchBarContainer">
                    <GlossarySearch placeholder="Search for keywords:" iconPosition="left" setSearchTerm={setSearchTerm}/>
                </div>
                <div className="termsContainerMargin">
                    <div className="termsContainer">
                        <ExpansionList className="termsList" onKeyDown={onKeyDown} data-testid="termList">
                            <GlossaryTerms terms={terms} panels={panels} searchTerm={searchTerm} className="termDefs" data-testid="expansion-panel" />
                        </ExpansionList>
                    </div>
                </div>
            </div>
            
        </div>


    </ReactModal>
};
export default GlosaryModal;