import * as EVENT from '@utilities/constants/triggerKeys';

const showSupFinCustNotBalDetails = (options) => {
  const { section, field } = options;

  section.groups[1].isVisible = field.default === EVENT.KEY_YES;
  section.groups[2].isVisible = field.default === EVENT.KEY_YES;
  section.groups[3].isVisible = field.default === EVENT.KEY_YES;
  section.groups[4].isVisible = field.default === EVENT.KEY_YES;
  section.groups[5].isVisible = field.default === EVENT.KEY_YES;
  section.groups[6].isVisible = field.default === EVENT.KEY_YES;
  // section.groups[7].isVisible = field.default === EVENT.KEY_YES;
};

const showSupFinCustNotBalLiabExp = (options) => {
  const { section, field } = options;

  section.groups[8].isVisible = field.default === EVENT.KEY_YES;
};

const showSupFinEndowNonPossRelNames = (options) => {
  const { section, field } = options;

  section.groups[17].isVisible = field.default === EVENT.KEY_YES;
};

const showSupFinConEaseHistStructNo = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    let val = '';
    
    if (event) {
        name[1].groups[10].isVisible = true;
    } else {
        name[1].groups[10].isVisible = false;
    }

    val = name[1].groups[10].fields[1].default;
    val = val ? Number(val) : 0;
    if (event && val > 0) {
        name[1].groups[11].isVisible = true;
    } else {
        name[1].groups[11].isVisible = false;
    }

    val = name[1].groups[11].fields[1].default;
    val = val ? Number(val) : 0;
    if (event && val > 0) {
        name[1].groups[12].isVisible = true;
        name[1].groups[13].isVisible = true;
    } else {
        name[1].groups[12].isVisible = false;
        name[1].groups[13].isVisible = false;
    }

    setFormSections(name);
}

const showSupFinConEaseHistNonNatNo = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event > 0) {
        name[1].groups[11].isVisible = true;
    } else {
       name[1].groups[11].isVisible = false;
    }
    
    setFormSections(name);
}

const showSupFinConEaseHistStructRules = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event > 0) {
        name[1].groups[12].isVisible = true;
        name[1].groups[13].isVisible = true;
    } else {
        name[1].groups[12].isVisible = false;
        name[1].groups[13].isVisible = false;
    }
    
    setFormSections(name);
}

const showSupFinConEaseModifyExp = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event > 0) {
        name[1].groups[15].isVisible = true;
    } else {
        name[1].groups[15].isVisible = false;
    }
    
    setFormSections(name);
}

const showSupFinConEasePolicyDesc = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event === EVENT.KEY_YES) {
        name[1].groups[18].isVisible = true;
    } else {
        name[1].groups[18].isVisible = false;
    }
    
    setFormSections(name);
}

const showSupFinArtExhbRptElectRptDependents = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event === EVENT.KEY_YES) {
        name[2].groups[2].isVisible = true;
        name[2].groups[3].isVisible = false;
        name[2].groups[4].isVisible = true;
        name[2].groups[5].isVisible = true;
    } else {
        name[2].groups[2].isVisible = false;
        name[2].groups[3].isVisible = true;
        name[2].groups[4].isVisible = false;
        name[2].groups[5].isVisible = false;
    }
    
    setFormSections(name);
}

const showSupFinArtGainHoldDependents = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event === EVENT.KEY_YES) {
        name[2].groups[7].isVisible = true;
        name[2].groups[8].isVisible = true;
        name[2].groups[9].isVisible = true;
    } else {
        name[2].groups[7].isVisible = false;
        name[2].groups[8].isVisible = false;
        name[2].groups[9].isVisible = false;
    }
    
    setFormSections(name);
}

const showSupFinArtUseOtherDesc = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;
    
    if (event) {
        name[2].groups[16].isVisible = true;
    } else {
        name[2].groups[16].isVisible = false;
    }
    
    setFormSections(name);
}

const showSupFinEndowBalEnd = ( options) => {
    const { section } = options;

   let total1 = (Number (section.groups[2].fields[1].default) + Number(section.groups[3].fields[1].default) + Number(section.groups[4].fields[1].default)) - (Number (section.groups[5].fields[1].default) + Number(section.groups[6].fields[1].default) + Number(section.groups[7].fields[1].default)) || "0";
   
    section.groups[8].fields[1].label = `$${total1}`;
    section.groups[8].fields[1].default = `$${total1}`;
}

const showSupFinAddInfo = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (name.length === 6) {
        const isAnySectionVisible = name.filter(sec => sec.isVisible);
        name[5].isVisible = isAnySectionVisible?.length;
    }
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
    case 'showSupFinConEaseHistStructNo': 
        showSupFinConEaseHistStructNo(event, options);
        break;
    case 'showSupFinConEaseHistNonNatNo': 
        showSupFinConEaseHistNonNatNo(event, options);
        break;
    case 'showSupFinConEaseHistStructRules': 
        showSupFinConEaseHistStructRules(event, options);
        break;
    case 'showSupFinConEaseModifyExp': 
        showSupFinConEaseModifyExp(event, options);
        break;
    case 'showSupFinConEasePolicyDesc': 
        showSupFinConEasePolicyDesc(event, options);
        break;
    case 'showSupFinArtExhbRptElectRptDependents': 
        showSupFinArtExhbRptElectRptDependents(event, options);
        break;
    case 'showSupFinArtGainHoldDependents': 
        showSupFinArtGainHoldDependents(event, options);
        break;
    case 'showSupFinArtUseOtherDesc': 
        showSupFinArtUseOtherDesc(event, options);
        break;
    case 'showSupFinAddInfo':
        showSupFinAddInfo(event, options);
        break;
    default:
        break;
    }
};

const sectionFieldLogic = (logicFunction, options) => {
  switch (logicFunction) {
    case 'showSupFinCustNotBalDetails':
      showSupFinCustNotBalDetails(options);
      break;
    case 'showSupFinCustNotBalLiabExp':
      showSupFinCustNotBalLiabExp(options);
      break;
    case 'showSupFinEndowNonPossRelNames':
      showSupFinEndowNonPossRelNames(options);
      break;
    case 'showSupFinEndowBalEnd':
        showSupFinEndowBalEnd(options);
        break;
    default:
      break;
  }
};

export {
  triggeredEvent,
  sectionFieldLogic
};