import * as EVENT from '@utilities/constants/triggerKeys';

const showHideTabs = (option1, option2, pageSections, setFormSections) => {
    const isReqSchdChkIii = option1 === EVENT.KEY_YES;
    const isReqSchdChkIiii = option2 === EVENT.KEY_YES;
    const OrgRecMgmtIndex = 1;
    const OrgGovIndex = 2;
    const IndIndex = 3;
    if (option1 && option2) {
        if (isReqSchdChkIii && isReqSchdChkIiii) {
            pageSections[OrgRecMgmtIndex].isVisible = true;
            pageSections[OrgGovIndex].isVisible = true;
            pageSections[IndIndex].isVisible = true;
        }
        else if (isReqSchdChkIii && !isReqSchdChkIiii) {
            pageSections[OrgRecMgmtIndex].isVisible = true;
            pageSections[OrgGovIndex].isVisible = true;
            pageSections[IndIndex].isVisible = false;
        }
        else if (!isReqSchdChkIii && isReqSchdChkIiii) {
            pageSections[OrgRecMgmtIndex].isVisible = true;
            pageSections[OrgGovIndex].isVisible = false;
            pageSections[IndIndex].isVisible = true;
        }
        else if (!isReqSchdChkIii && !isReqSchdChkIiii) {
            pageSections[OrgRecMgmtIndex].isVisible = false;
            pageSections[OrgGovIndex].isVisible = false;
            pageSections[IndIndex].isVisible = false;
        }
    }
    else {
        if (isReqSchdChkIii) {
            pageSections[OrgRecMgmtIndex].isVisible = true;
            pageSections[OrgGovIndex].isVisible = true;
            pageSections[IndIndex].isVisible = false;
        }
        else if (isReqSchdChkIiii) {
            pageSections[OrgRecMgmtIndex].isVisible = true;
            pageSections[OrgGovIndex].isVisible = false;
            pageSections[IndIndex].isVisible = true;
        }
        else {
            pageSections[OrgRecMgmtIndex].isVisible = false;
            pageSections[OrgGovIndex].isVisible = false;
            pageSections[IndIndex].isVisible = false;
        }
    }
    setFormSections(pageSections);
}

const showStepOneAndTwo = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const pageSections = formSections ? formSections : sections;
    showHideTabs(pageSections[0].groups[0].fields[1].default, pageSections[0].groups[1].fields[1].default, pageSections, setFormSections);
}

const showStepOneAndThree = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const pageSections = formSections ? formSections : sections;
    showHideTabs(pageSections[0].groups[0].fields[1].default, pageSections[0].groups[1].fields[1].default, pageSections, setFormSections);
}
const showGrntMaintRecProc = (options) => {
    const { section, field } = options;
    section.groups[1].isVisible = field.default === EVENT.KEY_YES;
}
const showTableOrUpload = (options) => {
    const { section, field } = options;

    section.groups[1].isVisible = field.default === EVENT.KEY_ATTACH;
    section.groups[2].isVisible = field.default === EVENT.KEY_TABLE;

};

const showGovEntity = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;

    if (event === "None") {
        name[0].groups[2].isVisible = true;
    } else {
        name[0].groups[2].isVisible = false;
    }
}

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'showStepOneAndTwo':
            showStepOneAndTwo(event, options);
            break;
        case 'showStepOneAndThree':
            showStepOneAndThree(event, options);
            break;
        case 'showGovEntity':
            showGovEntity(event, options);
            break;
        default:
            break;
    }
};

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        case 'showGrntMaintRecProc':
            showGrntMaintRecProc(options);
            break;
        case 'showTableOrUpload':
            showTableOrUpload(options);
            break;
        default:
            break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};