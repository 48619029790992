import _ from 'lodash';
import { preparePrePopulates } from '@utilities/populatePriorData/preparePrePopulates';
import * as AXCESS_KEYS from '@utilities/constants/axcessKeys';
import { ObjectId } from 'bson';

const includesFieldIdentifier = (lineItemKeys) => (fieldIdentifier) =>
  lineItemKeys?.includes(fieldIdentifier) || false;
const includesfieldPrefix = (lineItemKeys) => (fieldPrefix) =>
  lineItemKeys?.some((key) => key?.startsWith(fieldPrefix)) || false;

const calculateBondTableGroup = (
  group,
  sectionName,
  lineItemData,
  dataList,
  priorYearData,
  buildFromSheetSectionFn
) => {
  const {
    axcessGroup,
    subWorkSheets,
    bondsSections,
    bonds,
    entityIdentifiers,
  } = group;
  const hasSubWorkSheetEntity = subWorkSheets && bondsSections && bonds;
  const dataListClone = _.cloneDeep(dataList);

  dataListClone.forEach((fieldData) => {
    if (group.prePopulate && !group.isNew) {
      preparePrePopulates(group, fieldData);
    }
  });

  if (!axcessGroup?.pull) return;

  const { fromSections, fieldIdentifiers, fieldPrefixes } = axcessGroup.pull;

  const lineItemKeys = lineItemData.map((x) => x.key);

  const matchToFieldIdentifiers = fieldIdentifiers?.some(
    includesFieldIdentifier(lineItemKeys)
  );
  const matchToFieldPrefixes = fieldPrefixes?.some(
    includesfieldPrefix(lineItemKeys)
  );

  if (!fromSections?.includes(sectionName)) return;
  if (!matchToFieldIdentifiers && !matchToFieldPrefixes) return;

  dataListClone.forEach((dataRow, rowIndex) => {
    if (hasSubWorkSheetEntity) {
      const clonedBondSections = _.cloneDeep(bondsSections);

      clonedBondSections.forEach((bondSection, sectionIndex, self) => {
        const sectionEntityIdentifier = entityIdentifiers[sectionIndex];
        const { axcessDataType } = sectionEntityIdentifier;

        let passedPriorData = null;
        if (axcessDataType === AXCESS_KEYS.PASS_DATA_TO_CHILD) {
          passedPriorData = { fields: dataRow, name: sectionName };
        }

        if (passedPriorData) {
          buildFromSheetSectionFn([passedPriorData], [bondSection], self);
        }
      });

      bonds.push({
        id: new ObjectId().toString(),
        sections: clonedBondSections,
      });
    }
  });
};

export default calculateBondTableGroup;

export {
  // For testing purpose
  includesFieldIdentifier,
  includesfieldPrefix,
};
