import React, { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { sectionFieldLogic, triggeredEvent } from './interestedPersonsLogicTrigger';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';


const ExcessBenefitSection = [
	{
		title: 'Transaction Description Detail',
		isVisible: true,
		sectionId: 1,
		isCollapsed: false,
		isDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				fields: [
					{ label: 'Description of Transaction', name: 'intPrsnExcessDescLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
					{ label: '', name: 'intPrsnExcessDesc', type: 'textarea', col: 12, maxLength: '9000', class: 'fieldTextArea' }
				]
			},
		]
	},
];
const LoansLineSection = [
	{
		title: 'Loan Details',
		isVisible: true,
		sectionId: 1,
		isCollapsed: false,
		isDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Relationship with Organization', name: 'intPrsnLoanRelLabel', type: 'label', col: 4 },
					{ label: 'Relationship with Organization', name: 'intPrsnLoanRel', type: 'freeText', col: 8, maxLength: '75' }
				],
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Purpose of Loan', name: 'intPrsnLoanPurpLabel', type: 'label', class: 'labelTitleTextArea', col: 4 },
					{ label: 'Purpose of Loan', name: 'intPrsnLoanPurp', type: 'freeText', maxLength: '75', col: 8, class: 'fieldTextArea' },
				],

			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'In Default?', name: 'intPrsnLoanDefaultLabel', type: 'label', col: 9 },
					{ label: '', name: 'intPrsnLoanDefault', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
				]

			},

			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Approved by Board or Committee?', name: 'intPrsnLoanApprvLabel', type: 'label', col: 9 },
					{ label: '', name: 'intPrsnLoanApprv', type: 'radio', lookup: 'YESNO_RADIO', col: 3 }
				],
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Written Agreement?', name: 'intPrsnLoanWrittenAgrmtLabel', type: 'label', col: 9 },
					{ label: '', name: 'intPrsnLoanWrittenAgrmt', type: 'radio', lookup: 'YESNO_RADIO', col: 3 }
				],
			}
		]
	},
];
const GrantsOrAssistanceLineSection = [
	{
		title: 'Assistance Details',
		isVisible: true,
		sectionId: 1,
		isCollapsed: false,
		isDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Relationship with Organization', name: 'intPrsnGrantRelLabel', type: 'label', col: 4 },
					{ label: '', placeholder: 'Relationship with Organization', name: 'intPrsnGrantRel', type: 'freeText', maxLength: 75, col: 8 }
				],
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Type of Assistance', name: 'intPrsnGrantTypeLabel', type: 'label', col: 4 },
					{ label: '', placeholder: 'Type of Assistance', name: 'intPrsnGrantType', type: 'freeText', maxLength: 75, col: 8 },
				],
			},
			{
				groupId: 3,
				isVisible: true,
				fields: [
					{ label: 'Purpose of Assistance', name: 'intPrsnGrantPurpLabel', type: 'label', col: 4 },
					{ label: '', placeholder: 'Purpose of Assistance', name: 'intPrsnGrantPurp', type: 'freeText', maxLength: 75, col: 8 },
				],
			}
		]
	},
];
const IntPrsnBusTransLineSection = [
	{
		title: 'Interested Person Details',
		isVisible: true,
		sectionId: 1,
		isCollapsed: false,
		isDenseRow: true,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Relationship with Organization', name: 'intPrsnBusTransRelLabel', type: 'label', col: 8 },
					{ label: 'Relationship with Organization', name: 'intPrsnBusTransRel', type: 'freeText', maxLength: 75, col: 4 }
				],
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Description of Transaction', name: 'intPrsnBusTransTypeLabel', type: 'label', class: 'labelTitleTextArea', col: 12 },
					{ label: 'Description of Transaction', name: 'intPrsnBusTransType', type: 'textarea', class: 'fieldTextArea', maxLength: 1000, col: 12 },
				],
			},
			{
				groupId: 3,
				isVisible: true,
				fields: [
					{ label: 'Sharing of the Organization\'s Revenues?', name: 'intPrsnBusTransRevShareLabel', type: 'label', col: 9 },
					{ label: '', name: 'intPrsnBusTransRevShare', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
				],
			},
		]
	},
];

let sections = [];

export const getInterestedPersonsTransactionsTitles = () => {
	let titles = [];
	sections.forEach((section) => {
		if (!titles.includes(section.title)) {
			titles.push(section.title);
		}
	});
	return titles;
};

export const getInterestedPersonsSections = () => {
	return sections;
};

function InterestedPersonsTransactions(props) {
	const { isExportForm } = props;
	const { updateCard, REDUX } = usePageFramework(NAV.INTERESTED_PERSONS_TRANSACTIONS);
	const [sectionState, setSectionState] = useState('');
	const {isActiveClient, isLoading} = useLoadOrganizer();

	useEffect(() => {
		if (!isExportForm) updateCard();

		sections = [
			{
				title: 'Related Party Transactions',
				isVisible: true,
				sectionId: 1,
				groups: [
					{
						groupId: 1,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: 'Did the organization engage in an excess benefit transactions with a disqualified person during the tax year?',
								name: 'reqSchdChkLiALabel', type: 'label', col: 9
							},
							{
								label: '',
								name: 'reqSchdChkLiA',
								type: 'radio',
								lookup: 'YESNO_RADIO',
								col: 3,
								triggered: 'showExcessBenefitTrans',
							},
						]
					},
					{
						groupId: 2,
						isVisible: false,
						hasDivider: true,
						fields: [
							{
								label: '	Did the organization become aware that in a prior year it engaged in an excess benefit transaction with a disqualified person?',
								name: 'reqSchdChkLiBLabel', type: 'label', col: 9
							},
							{ label: '', name: 'reqSchdChkLiB', type: 'radio', lookup: 'YESNO_RADIO', col: 3, triggered: 'showExcessBenefitTrans' },
						]
					},
					{
						groupId: 3,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Did the organization report any amounts for receivables from or payables to any current or former officer, director, trustee, key employee, creator or founder, substantial contributor, or 35% controlled entity or family member of any of these persons?',
								name: 'reqSchdChkLiiLabel', type: 'label', col: 9
							},
							{ label: '', name: 'reqSchdChkLii', type: 'radio', lookup: 'YESNO_RADIO', col: 3, triggered: 'showLoans' },
						]
					},
					{
						groupId: 4,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Did the organization provide a grant or other assistance to any current or former officer, director, trustee, key employee, creator or founder, substantial contributor or employee thereof, a grant selection committee member, or to a 35% controlled entity (including an employee thereof) or family member of any of these persons?',
								name: 'reqSchdChkLiiiLabel', type: 'label', col: 9
							},
							{ label: '', name: 'reqSchdChkLiii', type: 'radio', lookup: 'YESNO_RADIO', col: 3, triggered: 'showGrantsorAssistance' },
						]
					},
					{
						groupId: 5,
						isVisible: true,
						fields: [
							{
								htmlLabel:
									<React.Fragment key={'related-Party-Transactions'}>
										<ul key={'related-Party-listOne'} className='discList'>
											<div>Use the following thresholds when determining if the organization was a party to business transactions below</div>
											<li key={'related-Party-list-1'} className='discListItem'>All payments during the tax year between the organization and the interested person exceeded $100,000</li>
											<li key={'related-Party-list-2'} className='discListItem'>All payments during the tax year from a single transaction between such parties exceeded the greater of $10,000 or 1% of the filing organization's total revenue for the tax year.</li>
											<li key={'related-Party-list-3'} className='discListItem'>Compensation payments during the tax year by the organization to a family member of a current or former officer, director, trustee, or key employee of the organization listed in the Board of Dir. and Compensation section under Officer, Director, Trustees, Key EEs and Highest Comp. EEs, exceeded $10,000; or</li>
											<li key={'related-Party-list-4'} className='discListItem'>In the case of a joint venture with an interested person, the organization has invested $10,000 or more in the joint venture, whether or not during the tax year, and the profits or capital interest of the organization and of the interested person each exceeds 10% at some time during the tax year</li>
										</ul>
									</React.Fragment>,
								name: 'listOne',
								type: 'label',
								col: 12
							},
						]
					},
					{
						groupId: 6,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'Was the organization a party to a business transaction where (check all that apply)?',
								name: 'textThreeLabel', type: 'label', col: 9
							},
						]
					},
					{
						groupId: 7,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'An interested person had a direct or indirect business relationship with the organization?',
								name: 'reqSchdChkLivALabel', type: 'label', col: 10, class: 'labelSingleCheckbox'
							},
							{ label: 'Yes', name: 'reqSchdChkLivA', type: 'checkbox', isFieldCheckbox: true, col: 2, class: 'singleCheckboxMargin', triggered: 'showBusinessTransactions' },
						]
					},
					{
						groupId: 8,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: 'A family member of an interested person had a direct or indirect business relationship with the organization?',
								name: 'reqSchdChkLivBLabel', type: 'label', col: 10
							},
							{ label: 'Yes', name: 'reqSchdChkLivB', type: 'checkbox', isFieldCheckbox: true, col: 2, class: 'singleCheckboxMargin', triggered: 'showBusinessTransactions' },
						]
					},
					{
						groupId: 9,
						isVisible: true,
						hasDivider: true,
						fields: [
							{
								label: '	An interested person served as an officer, director, trustee, key employee, partner, shareholder, or member of a for-profit entity doing business with the organization?',
								name: 'reqSchdChkLivCLabel', type: 'label', col: 10
							},
							{ label: 'Yes', name: 'reqSchdChkLivC', type: 'checkbox', isFieldCheckbox: true, col: 2, class: 'singleCheckboxMargin', triggered: 'showBusinessTransactions' },
						]
					},
				]
			},
			{
				title: 'Excess Benefit Transactions',
				isVisible: false,
				hasDenseRow: true,
				sectionId: 2,
				groups: [
					{
						groupId: 1,
						isVisible: true,
						fields: [
							{ label: 'Provide the following about each transaction, regardless of amount:', name: 'intPrsnExcessLabel', type: 'label', col: 12 },
						]
					},
					{
						groupType: 'lineItem',
						groupId: 2,
						fields: [
							{ label: 'Name of Disqualified Person', name: 'intPrsnExcessName', type: 'freeText', maxLength: 75 },
							{ label: 'Relationship Between Disqualified Person and Organization', name: 'intPrsnExcessRel', type: 'freeText', maxLength: 75 },
							{ label: 'Was this corrected?', name: 'intPrsnExcessCorrect', type: 'select', lookup: 'YESNO' },
							{ type: 'lineButtons' }
						],
						lineItems: [],
						entities: [],
						currentEntity: -1,
						lineSections: ExcessBenefitSection,
						headerLabelsPrintStyles: { gridTemplateColumns: '2fr 2fr 2fr .5fr' },
						lineItemDetails:
						{
							headerLabels: [
								{ label: 'Name of Disqualified Person', name: 'intPrsnExcessName', col: 4, colTablet: 2, styles: { textAlign: 'left', paddingLeft: '50px' } },
								{ label: 'Relationship Between Disqualified Person and Organization', name: 'intPrsnExcessRel', col: 4, colTablet: 2, styles: { textAlign: 'left', paddingLeft: '40px' } },
								{ label: 'Was this corrected?', name: 'intPrsnExcessCorrect', col: 3, colTablet: 2, styles: { textAlign: 'left', paddingLeft: '35px' } },
								{ label: 'Actions', col: 1, colTablet: 1, styles: { paddingLeft: '20px' } }
							],
							footerLabels: [
								{ type: 'buttons', col: 12, buttonTitle: ' Add Another Disqualified Person', icon: 'add' },
							],
							lineButtons: [
								{ button: 'remove' },
							]
						}
					},
				]
			},
			{
				title: 'Loans',
				isVisible: false,
				sectionId: 3,
				hasDenseRow: true,
				groups: [
					{
						groupId: 1,
						isVisible: true,
						fields: [
							{ label: 'Provide the following information for each transaction:', name: 'intPrsnLoanLabel', type: 'label', col: 10 },
						]
					},
					{
						groupType: 'lineItem',
						groupId: 2,
						fields: [
							{ label: 'Name of Interested Person', name: 'intPrsnLoanName', type: 'freeText', maxLength: '75' },
							{ label: 'Loan To or From Organization', name: 'intPrsnLoanToFrom', type: 'select', lookup: 'TOFROM' },
							{ label: 'Original Principal Amount', name: 'intPrsnLoanOrigPrin', type: 'money', isTotal: true },
							{ label: 'Balance Due', name: 'intPrsnLoanBalance', type: 'money', isTotal: true },
							{ type: 'lineButtons' }
						],
						lineItems: [],
						entities: [],
						currentEntity: -1,
						lineSections: LoansLineSection,
						headerLabelsPrintStyles: { gridTemplateColumns: '1.8fr 1.85fr 1.75fr 1.5fr 1fr' },
						footerLabelsPrintStyles: {gridTemplateColumns: '6.5fr 2fr 2fr'},
						lineItemDetails:
						{
							headerLabels: [
								{ label: 'Name of Interested Person', name: 'intPrsnLoanName', col: 3, colTablet: 2, styles: { textAlign: 'left', paddingLeft: '50px' } },
								{ label: 'Loan To or From Organization', name: 'intPrsnLoanToFrom', col: 3, colTablet: 2, styles: { textAlign: 'left', paddingLeft: '40px' } },
								{ label: 'Original Principal Amount', name: 'intPrsnLoanOrigPrin', col: 3, colTablet: 2, styles: { textAlign: 'left', paddingLeft: '40px' } },
								{ label: 'Balance Due', name: 'intPrsnLoanBalance', col: 2, colTablet: 1, styles: { textAlign: 'left', paddingLeft: '30px' } },
								{ label: 'Actions', col: 1, colTablet: 1, styles: { paddingLeft: '20px' } }
							],
							footerLabels: [
								{ type: 'buttons', col: 12, buttonTitle: 'Add Transaction', icon: 'add' },
								{ type: 'totalMoney', name: 'intPrsnLoanOrigPrin', col: 9, colTablet: 6, label: '$ 0' },
								{ type: 'totalMoney', name: 'intPrsnLoanBalance', col: 2, colTablet: 1, label: '$ 0' },
							],
							lineButtons: [
								{ button: 'remove' },
							]
						}
					},
				]
			},
			{
				title: 'Grants or Assistance',
				isVisible: false,
				hasDenseRow: true,
				sectionId: 4,
				groups: [
					{
						groupId: 1,
						isVisible: true,
						fields: [
							{ label: 'Provide the following information for each transaction:', name: 'intPrsnGrantLabel', type: 'label', col: 12 },
						]
					},
					{
						groupType: 'lineItem',
						groupId: 2,
						fields: [
							{ label: 'Name of Interested Person', name: 'intPrsnGrantName', type: 'freeText', maxLength: 75 },
							{ label: 'Amount of Assistance', name: 'intPrsnGrantAmt', type: 'money', isTotal: true },
							{ type: 'lineButtons' }
						],
						lineItems: [],
						entities: [],
						currentEntity: -1,
						lineSections: GrantsOrAssistanceLineSection,
						headerLabelsPrintStyles: { gridTemplateColumns: '5fr 2.5fr .5fr' },
						footerLabelsPrintStyles: { gridTemplateColumns: '6fr 2fr' },
						lineItemDetails:
						{
							headerLabels: [
								{ label: 'Name of Interested Person', name: 'intPrsnGrantName', type: 'textarea', maxLength: 75, col: 6, colTablet: 2, styles: { textAlign: 'left', paddingLeft: '40px' } },
								{ label: 'Amount of Assistance', name: 'intPrsnGrantAmt', col: 5, colTablet: 2, styles: { textAlign: 'left', paddingLeft: '30px' } },
								{ label: 'Actions', col: 1, colTablet: 1, styles: { paddingLeft: '20px' } }
							],
							footerLabels: [
								{ type: 'buttons', col: 12, buttonTitle: 'Add Transaction', icon: 'add' },
								{ type: 'totalMoney', name: 'intPrsnGrantAmt', col: 9, colTablet: 4, label: ' $ 0' },
							],
							lineButtons: [
								{ button: 'remove', }
							]
						}
					},
				]
			},
			{
				title: 'Business Transactions',
				isVisible: false,
				hasDenseRow: true,
				sectionId: 5,
				groups: [
					{
						groupId: 1,
						isVisible: true,
						fields: [
							{ label: 'Provide the following information for each transaction:', name: 'intPrsnBusTransLabel', type: 'label', col: 12 },
						]
					},
					{
						groupType: 'lineItem',
						groupId: 2,
						fields: [
							{ label: 'Name of Interested Person', name: 'intPrsnBusTransName', type: 'freeText', maxLength: 75 },
							{ label: 'Amount of Transaction', name: 'intPrsnBusTransAmt', type: 'money', isTotal: true },
							{ type: 'lineButtons' }
						],
						lineItems: [],
						entities: [],
						currentEntity: -1,
						lineSections: IntPrsnBusTransLineSection,
						headerLabelsPrintStyles: { gridTemplateColumns: '5fr 2.5fr .5fr' },
						footerLabelsPrintStyles: { gridTemplateColumns: '6fr 2fr' },
						lineItemDetails:
						{
							headerLabels: [
								{ label: 'Name of Interested Person', name: 'intPrsnBusTransName', col: 6, colTablet: 2, styles: { textAlign: 'left', paddingLeft: '40px' } },
								{ label: 'Amount of Transaction', name: 'intPrsnBusTransAmt', col: 5, colTablet: 2, styles: { textAlign: 'left', paddingLeft: '30px' } },
								{ label: 'Actions', col: 1, colTablet: 1, styles: { paddingLeft: '20px' } }
							],
							footerLabels: [
								{ type: 'buttons', col: 12, buttonTitle: 'Add Transaction', icon: 'add' },
								{ type: 'totalMoney', name: 'intPrsnBusTransAmt', col: 11, colTablet: 4, label: ' $ 0' },
							],
							lineButtons: [
								{ button: 'remove' }
							]
						}
					},
				]
			},
			{  
				title: 'Additional Information',
				collapseClass: 'collapsibleForm',
				isVisible: true,
				isDefaultExpanded: false,
				isOptionalSectionMerge: true,
				sectionId: 6,
				groups: [
					{
						groupId: 2,
						groupType: 'details',
						fields: [
							{ 
								label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
								type: 'label', 
								class: 'labelLeftLight' 
							},
						]
					},
					{
						groupId: 1,
						fields: [
							{
								label: 'Start typing to provide additional information', 
								name: 'additionalInfoTextArea', 
								type: 'textarea', 
								col: 12,
								maxLength: 9000
							}
						]
					}
				]
			},
		];

		setSectionState(sections);
		// eslint-disable-next-line	
	}, []);

	return (
		<>
			{!isLoading && isActiveClient && <FormRendererHelper
				sections={sectionState}
				key={REDUX.INTERESTED_PERSONS_TRANSACTIONS}
				formName={REDUX.INTERESTED_PERSONS_TRANSACTIONS}
				triggeredEvent={triggeredEvent}
				sectionFieldLogic={sectionFieldLogic}
				isExportForm={isExportForm} />}
			{!isLoading && !isActiveClient && <NoActiveClient />}
		</>
	);
}

export default InterestedPersonsTransactions;