
import api from '@utilities/claApi';
import _ from 'lodash';
import { dashboard as defaultDashboard } from '@utilities/constants/dashboard';

export const getOrganizerById = async ({ organizerId, setIsLoading, setIsActiveClient, dispatch, ACTION, clearFormState }) => {
    if (!organizerId) {
        return;
    };

    setIsLoading(true);
    clearFormState();

    try {
        const organizerData = await api.get(`/organizers/${organizerId}`, {}).then(res => res.data)
        if (!organizerData) throw new Error('No data available');
        const { id: dashboardId, dashboard } = await api.get(`organizers/${organizerId}/dashboard`).then((response) => response.data);

        const {
            forms,
            locked,
            client,
            status,
            year,
        } = organizerData;

        dispatch(ACTION.setYear(year));

        // get prior year data for organizer
        try {
            const responsePriorYear = await api.get(`/organizers/${organizerId}/prior`)
            dispatch(ACTION.setPriorYearData(responsePriorYear.data.data.taxData.priorYear));
        } catch (error) {
            // most likely, when organizerId exist but has no prior year data, will get 404 error from backend.
            console.error("responsePriorYear", error)
        }

        let files = [];

        try {
            const documents = await api.get(`/organizers/${organizerId}/documents`)
                .then((response) => response.data.results);

            /*
              GROWTH: remove this transformation
                This transformation is done due to compatability with
                  form renderer, document manager, and sharepoint implementation
            */
            const transformedDocuments = documents.map((document) => {
                document.updated_on = document.updatedOn;
                document.downloaded_on = document.downloadedOn;
                document.upload_id = document.id;
                document.uploaded_by = document.createdBy;
                return document;
            });

            files.push(...transformedDocuments);
        } catch (error) {
            console.error(error);
        }

        // store upload list in state
        dispatch(ACTION.setUploadList(files));

        // store dashboard in state
        const newDash = _.cloneDeep(defaultDashboard);
        dispatch(ACTION.setDashboard(dashboard ? dashboard : newDash));
        dispatch(ACTION.setDashboardId(dashboardId ?? null));

        // Set form data into redux
        forms?.forEach((form) => {
            dispatch(ACTION.setForm(form.key, form.data));
        });

        // Set organizer metadata
        const clientInfo = {
            displayName: client?.name ?? '',
            clientNumber: client?.number ?? '',
            formStatus: status
        };

        dispatch(ACTION.setOrganizerId(organizerId));
        dispatch(ACTION.setActiveReturn(clientInfo));
        dispatch(ACTION.setLockForms(locked || false));
        dispatch(ACTION.setIsSaveSuccess(organizerId ? true : false));
        setIsActiveClient(true)

    } catch (err) {
        setIsActiveClient(false)
        console.error(err);
    } finally {
        setIsLoading(false);
    }
};