import * as REDUX from '@utilities/constants/redux';

export const buildPayloadObj = (type, payload, options = {}) => {
    return { type, payload, ...options };
};

export const setFileCategoryCount = (payload) => buildPayloadObj(REDUX.FILE_CATEGORY_COUNT, payload);
export const setisAuthenticated = (payload) => buildPayloadObj(REDUX.IS_AUTHENTICATED, payload);
export const setCurrentUser = (payload) => buildPayloadObj(REDUX.CURRENT_USER, payload);
export const setIsPractitioner = (payload) => buildPayloadObj(REDUX.IS_PRACTITIONER, payload);
export const setIsClient = (payload) => buildPayloadObj(REDUX.IS_CLIENT, payload);
export const setIsAdmin = (payload) => buildPayloadObj(REDUX.IS_ADMIN, payload);
export const setAuthUser = (payload) => buildPayloadObj(REDUX.AUTH_USER, payload);
export const setScreenSize = (payload) => buildPayloadObj(REDUX.SCREEN_SIZE, payload);
export const setToolbarTitle = (payload) => buildPayloadObj(REDUX.TOOLBAR_TITLE, payload);
export const showBulkUpload = (payload) => buildPayloadObj(REDUX.SHOW_BULK_UPLOAD_CONTROL, payload);
export const setCustomDialogTitle = (payload) => buildPayloadObj(REDUX.CUSTOM_DIALOG_TITLE, payload);
export const setCustomDialogMsg = (payload) => buildPayloadObj(REDUX.CUSTOM_DIALOG_MSG, payload);
export const setHideCustomDialogCloseButton = (payload) => buildPayloadObj(REDUX.HIDE_CUSTOM_DIALOG_CLOSE_BUTTON, payload);
export const setShowCustomDialog = (payload) => buildPayloadObj(REDUX.SHOW_CUSTOM_DIALOG, payload);
export const setDashboardSortRequirements = (payload) => buildPayloadObj(REDUX.DASHBOARD_SORT_REQ, payload);
export const setDashboard = (payload) => buildPayloadObj(REDUX.DASHBOARD, payload);
export const setProgressVisible = (payload) => buildPayloadObj(REDUX.PROGRESS_VISIBLE, payload);
export const setProgressText = (payload) => buildPayloadObj(REDUX.PROGRESS_TEXT, payload);
export const setYear = (payload) => buildPayloadObj(REDUX.YEAR, payload);
export const setActiveReturn = (payload) => buildPayloadObj(REDUX.ACTIVE_RETURN, payload);
export const setLockForms = (payload) => buildPayloadObj(REDUX.LOCK_FORMS, payload);
export const setPriorYearData = (payload) => buildPayloadObj(REDUX.PRIOR_YEAR_DATA, payload);
export const setUploadProgressVisible = (payload) => buildPayloadObj(REDUX.UPLOAD_PROGRESS_VISIBLE, payload);

export const setCurrentCardKey = (payload) => buildPayloadObj(REDUX.CURRENT_CARD_KEY, payload);
export const setCurrentCardStatus = (payload) => buildPayloadObj(REDUX.CURRENT_CARD_STATUS, payload);
export const setCard = (payload) => buildPayloadObj(REDUX.CURRENT_CARD, payload);
export const setDashboardFilterStatus = (payload) => buildPayloadObj(REDUX.DASHBOARD_FILTER_STATUS, payload);
export const setOrganizerId = (payload) => buildPayloadObj(REDUX.ORGANIZER_ID, payload);
export const setIsSaveSuccess = (payload) => buildPayloadObj(REDUX.IS_SAVE_SUCCESS, payload);
export const setDashboardLoading = (payload) => buildPayloadObj(REDUX.LOAD_DASHBOARD, payload);
export const setForm = (formName, payload) => buildPayloadObj(formName, payload);
export const setDashboardId = (payload) => buildPayloadObj(REDUX.DASHBOARD_ID, payload);

export const setBoardOfDirectorsAndCompensation = (payload) => buildPayloadObj(REDUX.BOARD_OF_DIRECTORS_AND_COMPENSATION, payload);

export const setUploadList = (payload) =>
    buildPayloadObj(REDUX.UPLOAD_LIST, payload);

//for print view 
export const setTaxYearEndDate = (payload) =>
    buildPayloadObj(REDUX.TAX_YEAR_END_DATE, payload);

// Duplicate files warning dialog
export const setUploadWarningVisible = (payload) => buildPayloadObj(REDUX.UPLOAD_WARNING_VISIBLE, payload);
export const setDuplicateUploadFiles = (payload) => buildPayloadObj(REDUX.DUPLICATE_UPLOAD_FILES, payload);
export const setUploadsProps = (payload) => buildPayloadObj(REDUX.UPLOADS_PROPS, payload);