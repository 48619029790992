import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { FormRenderer, BuildFormGroups, Collapse } from 'cla-formrenderer';
import { ObjectId } from 'bson';

import './formRenderer.css';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { mergeFormData } from '@utilities/formData/formDataMerger';
import * as LOOKUPS from '@utilities/constants/lookupInfo';
import populatePriorDataModule from '@utilities/populatePriorData/populatePriorData';
import prePopulateLineItems from '@utilities/populatePriorData/prePopulateLineItems';
import { filterPYFromIdentifiers } from '@utilities/populatePriorData/parsePriorEntityData';
import populateSections from '@utilities/populatePriorData/populateSections';
import { calculateTotalFooter } from '@utilities/populatePriorData/calculateTotalFooter';
import { getPostPopulateFunction } from '../../utilities/populatePriorData/postPopulate';
import ErrorMessage from '@components/errorMessage';

//exportRenderer new components
import CollapseExport from '@components/organizerExport/organizerRenderer/components/exportRenderer/collapsibleForm';
import BuildFormGroupsExport from '@components/organizerExport/organizerRenderer/components/exportRenderer/buildGroups';
import ExportRenderer from '@components/organizerExport/organizerRenderer/components/exportRenderer';
import { dashboard } from "@utilities/constants/dashboard";
import { isExportSectionFiltered, checkValuedFields } from '@components/organizerExport/organizerRenderer/components/utils/exportFormSections';

/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

function FormRendererHelper(props) {
  const {
    sections,
    formName,
    updateEntity,
    identifiers,
    parentFns,
    passFromEntity,
    hasLineItemSection,
    passedPriorData,
    entityScrollTab,
    entityIndex,
    updateEntityUniqueValue,
    setUniqueEntityFieldValue,
    showSharedEntityDialog,
    isFormPresent,
    isFormExpandable,
    isLineItemSection,
    parentFormName,
    parentGroup,
    parentIndex,
    isScheduleFormSection,
    triggeredEvent,
    sectionFieldLogic,
    currentIndex,
    isExportForm,
    isBondItemSection
  } = props;
  const {
    selectState,
    screenSize,
    REDUX,
    CARDSTATUS,
    INPUTSTATE,
    setGlobalFormState,
    updateStatus,
    card,
    setCardsRequired,
  } = usePageFramework();
  const formState = selectState(formName || null);
  const priorYearData =
    !identifiers || !identifiers.length || identifiers[0].isNew
      ? null
      : passedPriorData
      ? identifiers
      : selectState(REDUX.PRIOR_YEAR_DATA);
  const inputState = selectState(REDUX.IS_PRACTITIONER) || selectState(REDUX.IS_ADMIN) ? 
    INPUTSTATE.PRACTITIONER_INPUT : 
    INPUTSTATE.USER_INPUT;
  const activeClient = selectState(REDUX.ACTIVE_RETURN);
  const isAdmin = selectState(REDUX.IS_ADMIN);
  const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
  const isFormLocked =
    (activeClient?.formStatus === 'Client Submitted' &&
      !isPractitioner &&
      !isAdmin) ||
    selectState(REDUX.LOCK_FORMS);
  const [isLoaded, setIsLoaded] = useState(false);
  const isFormNA = selectState(REDUX.IS_CURRENT_FORM_NA);
  const [isFormDisabled, setIsFormDisabled] = useState(
    isFormLocked ? isFormLocked : isFormNA
  );
  const [formSections, setFormSections] = useState(sections);
  const [bookmarkTitle, setBookmarkTitle] = useState('');
  const lastDenseRowSection = sections[sections.length - 1];
  let isDenseRowNA = false;
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const [isSorted, setIsSorted] = useState(true);
  const isEntryInterview = formName === REDUX.ENTRY_INTERVIEW;
  const dashboardCards = dashboard.flatMap(item => item.cards);

  useEffect(() => {
    setIsLoaded(false);
    setIsFormDisabled(isFormLocked ? isFormLocked : isFormNA);
  }, [formState, isFormLocked, isFormNA, parentGroup, currentIndex]);

  useEffect(() => {
    const dialog = [];

    if (formName === 'taxExemptBonds' && formSections) {
      const basicBondEntities = formSections[1].groups[0].entities;

      formSections[1].groups[0].lineItems.forEach((item, idx) => {
        let issuerName =
          basicBondEntities?.[idx].sections[0].groups[0].fields[1].default ||
          '';
        let issuedDate =
          basicBondEntities?.[idx].sections[1].groups[0].fields[1].default ||
          '';

        item[1].issuerName = issuerName;
        item[2].issuedDate = issuedDate;

        dialog.push({
          issuerName,
          issuedDate,
        });
      });

      formSections.forEach((section, secIdx) => {
        if (secIdx > 1 && secIdx < 5) {
          section.groups[1].bonds.forEach((bond, bondIdx) => {
            if (dialog.length) bond.bondDetails = dialog[bondIdx];
          });
        }
      });
    }
  }, [formSections, formName]);

  const validatedSections = _.cloneDeep(sections);
  const saveForm = (
    field,
    nextTabId,
    childFormSectionData,
    denseRowSection,
    setDenseRowSection
  ) => {
    if (field) {
      field.inputState = inputState;
      
      //to handle dropdown that is changing from inputState 1 to 5 when NA is clicked
      if (field.inputState === 5 && field.prior && !field.isPriorEditable) {
        field.inputState = INPUTSTATE.PRIOR_INPUT;
      }

      if (field.isEntityName) {
        if (!denseRowSection) updateEntity(field.default, formSections);
        else updateEntity(field.default, denseRowSection);
      }

      // for saving the unique vehicle field values that are specific to the entity
      if (field.uniqueName && field.default) {
        updateEntityUniqueValue(field);
      }

      if (field.isSorted) {
        setIsSorted(false);
      }

      if (field.requiredCardsToSet) {
        setCardsRequired(field.requiredCardsToSet, field.isSetRequiredCards);
      }

      const priorYearChanged = field.priorYearValue !== field.default;
      const valued =
        field.type === 'checkbox' && field.default !== undefined
          ? true
          : field.default?.length && field.default !== ' '
          ? true
          : false;
      const isValueChanged = field.priorYearValue ? priorYearChanged : valued;

      if (
        card &&
        (card.statusId === CARDSTATUS.NOT_STARTED ||
          card.statusId === CARDSTATUS.REQUIRED) &&
        isValueChanged
      ) {
        updateStatus(CARDSTATUS.IN_PROGRESS);
      }
    }

    if (formName) {
      if (childFormSectionData && childFormSectionData.childFormSections) {
        const {
          childFormSections,
          sectionIndex,
          groupIndex,
          parentIndex,
          groupItemIndex,
          hasBondItems,
        } = childFormSectionData;
        let newForm = [];

        if (!denseRowSection) newForm = _.cloneDeep(formSections);
        else newForm = _.cloneDeep(denseRowSection);
        if (
          groupItemIndex &&
          newForm?.[sectionIndex]?.groups?.[groupItemIndex]?.groupItems?.[
            parentIndex
          ]?.sections
        ) {
          newForm[sectionIndex].groups[groupItemIndex].groupItems[
            parentIndex
          ].sections = childFormSections;
        } else if (
          newForm?.[sectionIndex]?.groups?.[groupIndex]?.entities?.[parentIndex]
            ?.sections
        ) {
          newForm[sectionIndex].groups[groupIndex].entities[
            parentIndex
          ].sections = childFormSections;
        } else if (
          hasBondItems &&
          newForm[sectionIndex].groups[1].bonds[parentIndex].sections
        ) {
          newForm[sectionIndex].groups[1].bonds[parentIndex].sections =
            childFormSections;
        }

        if (setDenseRowSection && newForm) {
          setDenseRowSection(newForm);
        }

        setFormSections(newForm);
        if (!isExportForm) setGlobalFormState(formName, newForm);
      } else {
        if (!isLineItemSection) {
          if (!isExportForm) setGlobalFormState(formName, formSections);
        }
      }
    }

    if (parentFns) {
      parentFns.saveForm(field, nextTabId, { childFormSections: formSections });
    }

    if (nextTabId) {
      // This should fix the tabbing issue with textbox!
      setTimeout(() => {
        document.getElementById(nextTabId)?.focus();
      }, 100);
    }
  };

  const triggered = (trigger, event, triggerObj) => {
    const {
      triggeredSections,
      triggeredFormSections,
      triggeredSetFormSections,
    } = triggerObj ? triggerObj : {};
    triggeredEvent(trigger, event, {
      sections: triggeredSections || validatedSections,
      formSections: triggeredFormSections || formSections,
      setFormSections: triggeredSetFormSections || setFormSections,
      inputState,
      allFormSections: formSections,
    });
  };

  const populateValues = () => {
    if (!validatedSections || isLoaded) return;
    if (hasLoadingError) return;

    // Client data exists
    // Attempt to merge the client's data with the form definition
    if (formState) {
      try {
        const { mergedFormData, hasErrors } = mergeFormData(
          validatedSections,
          null,
          formState,
          isSorted,
          isFormLocked
        );

        if (!hasErrors) {
          setFormSections(mergedFormData);
          setIsLoaded(true);
        } else {
          setHasLoadingError(true);
        }
      } catch (error) {
        console.error(error);
        // allow reload of form
        setHasLoadingError(true);
      }
      return;
    }

    setFormSections(validatedSections);

    if (!identifiers || !priorYearData) {
      validatedSections?.forEach((section) => {
        section?.groups?.forEach((group) => {
          if (group?.axcessGroup?.pull?.postPopulateFn) {
            const postPopulateFunction = getPostPopulateFunction(
              group.axcessGroup.pull.postPopulateFn
            );
            postPopulateFunction(
              group,
              group.axcessGroup.pull.postPopulateMetadata,
              false
            );
          }
        });
      });
      setIsLoaded(true);
      if (!isExportForm) setGlobalFormState(formName, validatedSections);
      return;
    }

    if (typeof identifiers === 'object' && !Array.isArray(identifiers)) {
      // Raw data instead of having to extract from prior data
      populateWithPriorData(validatedSections, identifiers);
      if (formName) {
        if (!isExportForm) setGlobalFormState(formName, validatedSections);
      } else {
        saveForm(null, null, validatedSections);
      }

      setIsLoaded(true);
      return;
    }

    const priorYearDataFiltered = identifiers.reduce(
      filterPYFromIdentifiers(priorYearData),
      []
    ); // Filter prior year data based on identifiers provided

    // // Associative mapping/matching of data
    validatedSections.forEach((section) => {
      section?.groups?.forEach((group) => {
        if (group.entityIdentifiers) {
          populateSections(priorYearDataFiltered, priorYearData)(group);
        }
      });
    });

    // // Regular mapping of PY data to form definition
    priorYearDataFiltered.forEach((priorYearDataSheet) => {
      if (priorYearDataSheet) {
        populatePriorDataModule(
          priorYearData,
          triggeredEvent
        ).buildFromSheetSection(priorYearDataSheet.data, validatedSections);
      }
    });

    // Post-mapping form data building
    validatedSections.forEach((section) => {
      section?.groups?.forEach((group) => {
        if (group.prePopulate && group.prePopulate.length) {
          prePopulateLineItems(group);

          if (group.lineSections) {
            const copySection = _.cloneDeep(group.lineSections);
            group.prePopulate.forEach((prePop) => {
              group.entities.push({
                id: new ObjectId().toString(),
                sections: copySection,
              });
            });
          }

          group.prePopulate = [];
        }

        if (group?.axcessGroup?.pull?.postPopulateFn) {
          const postPopulateFunction = getPostPopulateFunction(
            group.axcessGroup.pull.postPopulateFn
          );

          postPopulateFunction(
            group,
            group.axcessGroup.pull.postPopulateMetadata,
            true,
            priorYearDataFiltered,
          );
          calculateTotalFooter(group);
        }
      });
    });

    if (formName) {
      if (!isExportForm) setGlobalFormState(formName, validatedSections);
    }

    setIsLoaded(true);
  };

  populateValues();

  const Sections = !formSections
    ? null
    : formSections.map((section, index) => {
        isDenseRowNA =
          section?.isDenseRow &&
          parentGroup?.lineItems?.length &&
          parentGroup?.lineItems[parentIndex]
            ? parentGroup.lineItems[parentIndex][0]?.notApplicable
            : false;

        if (isEntryInterview && !isExportForm) {
          index === currentIndex
            ? (section.isVisible = true)
            : (section.isVisible = false);
        } else if (isEntryInterview && isExportForm) {
          (section.isVisible = true)
        }

        if (section.triggered) {
            triggered(section.triggered, null, null);
        }

        const isSectionVisible =
          typeof section?.isVisible === 'undefined'
            ? true
            : typeof section?.isVisible === 'function'
            ? section?.isVisible()
            : section?.isVisible;
        let sectionClass = 'normalSection';

        if (!isSectionVisible) return null;

        if (isExportForm && isExportSectionFiltered(formName)) {
          if (checkValuedFields(section, formSections) === 0) return (null);
		    } 

        if (section?.groups?.length === 0) {
          sectionClass = '';
        }

        const groups = section ? isExportForm ? 
        <BuildFormGroupsExport
          key={`build-form-groups-key-${section.sectionId}`}
          section={section}
          screenSize={screenSize}
          saveForm={saveForm}
          isFormLocked={isFormDisabled}
          triggered={triggered}
          sectionClass={sectionClass}
          sectionIndex={index}
          entityScrollTab={entityScrollTab}
          entityIndex={entityIndex}
          setUniqueEntityFieldValue={setUniqueEntityFieldValue}
          showSharedEntityDialog={showSharedEntityDialog}
          parentGroup={parentGroup}
          parentIndex={parentIndex}
          isDenseRowNA={isDenseRowNA}
          sectionFieldLogic={sectionFieldLogic}
          isEntryInterview={isEntryInterview}
          updateEntityUniqueValue={updateEntityUniqueValue}
          formName={formName}
        /> : (
          <BuildFormGroups
            key={`build-form-groups-key-${section.sectionId}`}
            section={section}
            screenSize={screenSize}
            saveForm={saveForm}
            isFormLocked={isFormDisabled}
            triggered={triggered}
            sectionClass={sectionClass}
            sectionIndex={index}
            entityScrollTab={entityScrollTab}
            entityIndex={entityIndex}
            setUniqueEntityFieldValue={setUniqueEntityFieldValue}
            showSharedEntityDialog={showSharedEntityDialog}
            parentGroup={parentGroup}
            parentIndex={parentIndex}
            isDenseRowNA={isDenseRowNA}
            sectionFieldLogic={sectionFieldLogic}
            isEntryInterview={isEntryInterview}
            formSections={formSections}
          />
        ) : null;

        return section ? (
          <React.Fragment key={`section-collapse-key-${section.sectionId}`}>
            {
              isExportForm ? 
              <CollapseExport
                key={index}
                title={section.title}
                collapseClass={section.collapseClass || null}
                index={index}
                groups={groups}
                allSections={formSections}
                section={section}
                sectionClass={sectionClass}
                fns={{ saveForm, setUniqueEntityFieldValue, showSharedEntityDialog, updateEntityUniqueValue }}
                isFormPresent={isFormPresent}
                isFormLocked={isFormDisabled}
                screenSize={screenSize}
                triggered={triggered}
                hasLineItemSection={hasLineItemSection}
                entityIndex={entityIndex}
                isFormExpandable={isFormExpandable}
                lastDenseRowSection={lastDenseRowSection}
                parentFormName={parentFormName}
                isScheduleFormSection={isScheduleFormSection}
                bookmarkTitle={bookmarkTitle}
                sectionFieldLogic={sectionFieldLogic}
                isEntryInterview={isEntryInterview}
              /> :
              <Collapse
                key={index}
                title={section.title}
                collapseClass={section.collapseClass || null}
                index={index}
                groups={groups}
                allSections={formSections}
                section={section}
                sectionClass={sectionClass}
                fns={{
                  saveForm,
                  setUniqueEntityFieldValue,
                  showSharedEntityDialog,
                }}
                isFormPresent={isFormPresent}
                isFormLocked={isFormDisabled}
                screenSize={screenSize}
                triggered={triggered}
                hasLineItemSection={hasLineItemSection}
                entityIndex={entityIndex}
                isFormExpandable={isFormExpandable}
                lastDenseRowSection={lastDenseRowSection}
                parentFormName={parentFormName}
                isScheduleFormSection={isScheduleFormSection}
                bookmarkTitle={bookmarkTitle}
                sectionFieldLogic={sectionFieldLogic}
                isEntryInterview={isEntryInterview}
              />
            }
          </React.Fragment>
        ) : null;
      });

  return !hasLoadingError ? (
    <>
      { isExportForm ? 
        <ExportRenderer
          sections={sections}
          formName={formName}
          passFromEntity={passFromEntity}
          hasLineItemSection={hasLineItemSection}
          entityScrollTab={entityScrollTab}
          entityIndex={entityIndex}
          setUniqueEntityFieldValue={setUniqueEntityFieldValue}
          showSharedEntityDialog={showSharedEntityDialog}
          isFormPresent={isFormPresent}
          isFormExpandable={isFormExpandable}
          isLineItemSection={isLineItemSection}
          parentFormName={parentFormName}
          parentGroup={parentGroup}
          parentIndex={parentIndex}
          isScheduleFormSection={isScheduleFormSection}
          formSections={formSections}
          isFormNA={isFormNA}
          isFormDisabled={isFormDisabled}
          usePageFramework={usePageFramework}
          triggered={triggered}
          saveForm={saveForm}
          setFormSections={setFormSections}
          setHasLoadingError={setHasLoadingError}
          setIsLoaded={setIsLoaded}
          mappedSections={Sections}
          sectionFieldLogic={sectionFieldLogic}
          lookups={LOOKUPS}
          bookmarkTitle={bookmarkTitle}
          setBookmarkTitle={setBookmarkTitle}
          dashboardCards={dashboardCards}
          isExportForm={isExportForm}
          isBondItemSection={isBondItemSection}
        /> : 
        <FormRenderer
          sections={sections}
          formName={formName}
          passFromEntity={passFromEntity}
          hasLineItemSection={hasLineItemSection}
          entityScrollTab={entityScrollTab}
          entityIndex={entityIndex}
          setUniqueEntityFieldValue={setUniqueEntityFieldValue}
          showSharedEntityDialog={showSharedEntityDialog}
          isFormPresent={isFormPresent}
          isFormExpandable={isFormExpandable}
          isLineItemSection={isLineItemSection}
          parentFormName={parentFormName}
          parentGroup={parentGroup}
          parentIndex={parentIndex}
          isScheduleFormSection={isScheduleFormSection}
          formSections={formSections}
          isFormNA={isFormNA}
          isFormDisabled={isFormDisabled}
          usePageFramework={usePageFramework}
          triggered={triggered}
          saveForm={saveForm}
          setFormSections={setFormSections}
          setHasLoadingError={setHasLoadingError}
          setIsLoaded={setIsLoaded}
          mappedSections={Sections}
          sectionFieldLogic={sectionFieldLogic}
          lookups={LOOKUPS}
          isEntryInterview={isEntryInterview}
          bookmarkTitle={bookmarkTitle}
          setBookmarkTitle={setBookmarkTitle}
        />
      }
    </>
    )
    : (<ErrorMessage text='An error occurred while loading your form. Please contact CLA Support for assistance.'/>);
}

export default FormRendererHelper;
