import * as INPUTSTATE from '@utilities/constants/inputState';

function TextAreaBox(props) {
    const { field } = props;
    const priorYearChanged = field.priorYearValue !== field.default;

    return (
        <div className="orgExportTextAreaDiv">  
            <label className="orgExportTextAreaTopLabel">{field.label}</label>
            <p className='orgExportTextArea' style={!priorYearChanged ? {fontStyle: 'italic'} : null} > {field.default || ' '}</p>
            {
                field.inputState === INPUTSTATE.PRACTITIONER_INPUT ? 
                <label className="orgExportTextAreaPracLabel">{'(practitioner)'}</label> : null
            }
        </div>
    );
}

export default TextAreaBox;
