import { useState, useEffect } from 'react';
import { Validators } from 'cla-formrenderer';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { sectionFieldLogic } from './relatedOrganizationsLogicTrigger';
import {
  conditionalPullFieldValueIfOtherEquals,
  pullFieldValue,
  pullFilteredFieldValue,
  pullFieldPercentValue,
} from '../../../utilities/pullFieldValue';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

const orgNameFields = (fieldName, id) => {
  return [
    {
      label: 'Organization Name',
      name: `${fieldName}Label`,
      type: 'label',
      col: 4,
    },
    {
      label: 'Organization Name',
      name: fieldName,
      type: 'freeText',
      col: 8,
      maxLength: 76,
      logicFunction: [{ trigger: 'setOrgName' }],
      prior: true,
      ...pullFieldValue([id], true),
    },
  ];
};

const einFields = (fieldName, id) => {
  return [
    {
      label: 'EIN (Employer Identification Number)',
      name: `${fieldName}Label`,
      type: 'label',
      col: 9,
    },
    {
      label: 'EIN',
      name: fieldName,
      type: 'ein',
      col: 3,
      prior: true,
      validations: { ...Validators.ein() },
      ...pullFilteredFieldValue(id, true, '-'),
    },
  ];
};

const addressFields = (
  orgAddress,
  countryName,
  countryNameId,
  streetName,
  streetNameId,
  cityName,
  cityNameId,
  stateName,
  stateNameId,
  provName,
  provNameId,
  stateProvName,
  stateProvNameId,
  stateNameId2,
  countryNameId2,
  zipName,
  zipNameId
) => {
  return [
    {
      label: 'Address',
      name: orgAddress,
      type: 'label',
      col: 12,
      styles: { padding: '0 0 20px 17px' },
    },
    {
      label: 'Country',
      name: countryName,
      type: 'select',
      lookup: 'BASIC_DATA_COUNTRIES',
      default: 'US',
      logicFunction: [{ trigger: 'setSchedRProvState' }],
      col: 3,
      prior: true,
      ...pullFieldValue([countryNameId], true),
    },
    {
      label: 'Street Address',
      name: streetName,
      type: 'freeText',
      maxLength: 76,
      col: 9,
      prior: true,
      ...pullFieldValue([streetNameId], true),
    },
    {
      label: 'City',
      name: cityName,
      type: 'freeText',
      maxLength: 35,
      col: 4,
      prior: true,
      ...pullFieldValue([cityNameId], true),
    },
    {
      label: 'State',
      name: stateName,
      type: 'select',
      lookup: 'BASIC_DATA_STATES',
      col: 4,
      isVisible: true,
      prior: true,
      ...pullFieldValue([stateNameId], true),
    },
    {
      label: 'Province',
      name: provName,
      type: 'select',
      lookup: 'BASIC_DATA_PROVINCES',
      col: 4,
      isVisible: false,
      prior: true,
      ...pullFieldValue([provNameId], true),
    },
    {
      label: 'Province / State',
      name: stateProvName,
      type: 'freeText',
      col: 4,
      isVisible: false,
      prior: true,
      ...conditionalPullFieldValueIfOtherEquals(
        stateProvNameId,
        stateNameId2,
        countryNameId2,
        true
      ),
    },
    {
      label: 'Zip / Postal Code',
      name: zipName,
      type: 'zippostal',
      col: 4,
      prior: true,
      ...pullFieldValue([zipNameId], true),
    },
  ];
};

const activityFields = (fieldName, id) => {
  return [
    {
      label: 'Primary Activity',
      name: `${fieldName}Label`,
      type: 'label',
      col: 4,
    },
    {
      label: 'Primary Activity',
      name: fieldName,
      type: 'freeText',
      col: 8,
      maxLength: 76,
      prior: true,
      ...pullFieldValue([id], true),
    },
  ];
};

const domicileFields = (
  radioName,
  radioNameId,
  stateName,
  stateNameId,
  countryName,
  countryNameId
) => {
  return [
    {
      label: 'Is the Legal Domicile in the United States of America?',
      name: `${radioName}Label`,
      type: 'label',
      col: 4,
    },
    {
      name: radioName,
      type: 'radio',
      col: 4,
      styles: { paddingRight: '100px', marginTop: '-5px' },
      logicFunction: [{ trigger: 'showLegalDmcl' }],
      lookup: 'YESNO_RADIO',
      prior: true,
      isPriorEditable: true,
      priorCompareOptions: {
        radioShouldLookup: true,
      },
      axcess: {
        pull: {
          fields: [radioNameId],
          fn: 'anyValidCheckboxOrFieldToYesNoRadioPull',
        },
      },
    },
    {
      label: 'Legal Domicile (State)',
      name: stateName,
      type: 'select',
      col: 4,
      lookup: 'BASIC_DATA_STATES',
      isVisible: false,
      prior: true,
      ...conditionalPullFieldValueIfOtherEquals(
        stateNameId,
        null,
        stateNameId,
        true
      ),
    },
    {
      label: 'Legal Domicile (Foreign country)',
      name: countryName,
      type: 'select',
      lookup: 'INDEPENDENT_CONTRACTOR_COUNTRIES',
      col: 4,
      isVisible: false,
      prior: true,
      ...conditionalPullFieldValueIfOtherEquals(
        null,
        countryNameId,
        stateNameId,
        true
      ),
    },
  ];
};

const radioTransactFields = (fieldName, logicTrigger) => {
  return [
    {
      label: 'Did you have any transactions with this related organization?',
      name: `${fieldName}Label`,
      type: 'label',
      col: 9,
    },
    {
      name: fieldName,
      type: 'radio',
      col: 3,
      logicFunction: [{ trigger: logicTrigger }],
    },
  ];
};

const transactTableGroup = (
  groupId,
  transTypeField,
  transAmtField,
  transMethodField
) => {
  return {
    groupId: groupId,
    groupType: 'lineItem',
    isVisible: false,
    isShowFooter: false,
    fields: [
      {
        label: 'Transaction Type',
        name: transTypeField,
        type: 'select',
        lookup: 'REL_ORG_TRANS_TYPE',
      },
      { label: 'Amount Involved', name: transAmtField, type: 'money' },
      {
        label: 'Method of Determining Amount',
        name: transMethodField,
        type: 'freeText',
        maxLength: 33,
      },
      { type: 'lineButtons' },
    ],
    lineItems: [],
    headerLabelsPrintStyles: {gridTemplateColumns: '4fr 2fr 2fr .5fr'},
    lineItemDetails: {
      headerLabels: [
        {
          label: 'Transaction Type',
          col: 5,
          colTablet: 3,
          styles: { paddingLeft: 0 },
        },
        { label: 'Amount Involved', maxLength: 18, col: 3, colTablet: 2 },
        {
          label: 'Method of Determining Amount',
          col: 3,
          colTablet: 2,
          styles: { paddingLeft: '25px' },
        },
        {
          label: 'Actions',
          col: 1,
          colTablet: 1,
          styles: { paddingLeft: '35px' },
        },
      ],
      footerLabels: [
        {
          type: 'buttons',
          col: 12,
          buttonTitle: 'Add Another Type of Transaction',
          icon: 'add',
        },
      ],
      lineButtons: [{ button: 'remove' }],
    },
  };
};

const percentOwnFields = (fieldName, id) => {
  return [
    {
      label: 'Percentage Ownership',
      name: `${fieldName}Label`,
      type: 'label',
      col: 9,
    },
    {
      label: 'Percentage Ownership',
      name: fieldName,
      type: 'percent',
      col: 3,
      validations: { ...Validators.maxValue(100) },
      prior: true,
      ...pullFieldPercentValue([id], true),
    },
  ];
};

const shareIncomeFields = (fieldName) => {
  return [
    {
      label: 'Share of Total Income',
      name: `${fieldName}Label`,
      type: 'label',
      col: 9,
    },
    {
      label: 'Share of Total Income',
      name: fieldName,
      type: 'money',
      allowNegative: true,
      col: 3,
    },
  ];
};

const shareAssetsFields = (fieldName) => {
  return [
    {
      label: 'Share of End-of-Year Assets',
      name: `${fieldName}Label`,
      type: 'label',
      col: 9,
    },
    {
      label: 'Share of End-of-Year Assets',
      name: fieldName,
      type: 'money',
      col: 3,
    },
  ];
};

const dispropAllocFields = (fieldName, id) => {
  return [
    {
      label: 'Disproportionate Allocations?',
      name: `${fieldName}Label`,
      type: 'label',
      col: 9,
    },
    {
      name: fieldName,
      type: 'radio',
      col: 3,
      lookup: 'YESNO_RADIO',
      prior: true,
      isPriorEditable: true,
      priorCompareOptions: {
        radioShouldLookup: true,
      },
      axcess: {
        pull: {
          fields: [id],
          fn: 'anyValidCheckboxOrFieldToYesNoRadioPull',
        },
      },
    },
  ];
};

const codeVUBIFields = (fieldName) => {
  return [
    {
      label: 'Code V-UBI Amount in Box 20 of K-1',
      name: `${fieldName}Label`,
      type: 'label',
      col: 9,
    },
    {
      label: 'Code V-UBI Amount in Box 20 of K-1',
      name: fieldName,
      type: 'money',
      col: 3,
    },
  ];
};

const control50Fields = (fieldName, id) => {
  return [
    {
      label: 'Direct Controlling Entity (more than 50% control)',
      name: `${fieldName}Label`,
      type: 'label',
      col: 4,
    },
    {
      label: 'Direct Controlling Entity',
      name: fieldName,
      type: 'freeText',
      col: 8,
      maxLength: 76,
      prior: true,
      ...pullFieldValue([id], true),
    },
  ];
};

export const relOrgLineSections = [
  {
    //Disregarded Entity
    title: 'Organization Details',
    isVisible: false,
    sectionId: 1,
    isCollpased: false,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: orgNameFields('relOrgDisregardName', 'XFDS99R1.0'),
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: einFields('relOrgDisregardEIN', 'XFDS99R1.1'),
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: addressFields(
          'relOrgDisregardAddressLabel',
          'relOrgDisregardCntry',
          'XFDS99R1.7',
          'relOrgDisregardAdd',
          'XFDS99R1.2',
          'relOrgDisregardCity',
          'XFDS99R1.3',
          'relOrgDisregardState',
          'XFDS99R1.4',
          'relOrgDisregardProv',
          'XFDS99R1.6',
          'relOrgDisregardProvState',
          'XFDS99R1.6',
          'XFDS99R1.4',
          'XFDS99R1.7',
          'relOrgDisregardZip',
          'XFDS99R1.5'
        ),
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: activityFields('relOrgDisregardActvty', 'XFDS99R1.8'),
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: domicileFields(
          'relOrgDisregardLglDmcl',
          'XFDS99R1.13',
          'relOrgDisregardLegalDom',
          'XFDS99R1.13',
          'relOrgDisregardLegalDomForeign',
          'XFDS99R1.9'
        ),
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Total Income',
            name: 'relOrgDisregardTotIncmLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'Total Income',
            name: 'relOrgDisregardTotIncm',
            type: 'money',
            allowNegative: true,
            col: 3,
          },
        ],
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'End-of-Year Assets',
            name: 'relOrgDisregardEoyAsstsLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'End-of-Year Assets',
            name: 'relOrgDisregardEoyAssts',
            type: 'money',
            col: 3,
          },
        ],
      },
      {
        groupId: 8,
        isVisible: true,
        fields: [
          {
            label: 'Direct Controlling Entity (100% control)',
            name: 'relOrgDisregardDirCntrlEntLabel',
            type: 'label',
            col: 4,
          },
          {
            label: 'Direct Controlling Entity',
            name: 'relOrgDisregardDirCntrlEnt',
            type: 'freeText',
            col: 8,
            maxLength: 76,
            prior: true,
            ...pullFieldValue(['XFDS99R1.10'], true),
          },
        ],
      },
    ],
  },
  {
    // Tax Exempt
    title: 'Organization Details',
    isVisible: false,
    sectionId: 2,
    isCollpased: false,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: orgNameFields('relOrgTaxExmptName', 'XFDS99R2.0'),
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: einFields('relOrgTaxExmptEIN', 'XFDS99R2.1'),
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: addressFields(
          'relOrgTaxExmptAddressLabel',
          'relOrgTaxExmptCntry',
          'XFDS99R2.7',
          'relOrgTaxExmptAdd',
          'XFDS99R2.2',
          'relOrgTaxExmptCity',
          'XFDS99R2.3',
          'relOrgTaxExmptState',
          'XFDS99R2.4',
          'relOrgTaxExmptProv',
          'XFDS99R2.6',
          'relOrgTaxExmptProvState',
          'XFDS99R2.6',
          'XFDS99R2.4',
          'XFDS99R2.7',
          'relOrgTaxExmptZip',
          'XFDS99R2.5',
        ),
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: activityFields('relOrgTaxExmptActvty', 'XFDS99R2.8'),
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: domicileFields(
          'relOrgTaxExmptLglDmcl',
          'XFDS99R2.10',
          'relOrgTaxExmptLegalDom',
          'XFDS99R2.10',
          'relOrgTaxExmptLegalDomForeign',
          'XFDS99R2.9',
        ),
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Exempt Code Section',
            name: 'relOrgTaxExmptCodeLabel',
            type: 'label',
            col: 8,
          },
          {
            label: 'Exempt Code Section',
            name: 'relOrgTaxExmptCode',
            type: 'select',
            lookup: 'EXEMPT_STATUSES_BY_NUMBER',
            col: 4,
            logicFunction: [{ trigger: 'showrelOrgTaxExmptPCS' }],
            prior: true,
            priorCompareOptions: {
              compareAllLookup: true,
            },
            ...pullFieldValue(['XFDS99R2.14'], true),
          },
        ],
      },
      {
        groupId: 7,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Public Charity Status',
            name: 'relOrgTaxExmptPCSLabel',
            type: 'label',
            col: 8,
          },
          {
            label: 'Public Charity Status',
            name: 'relOrgTaxExmptPCS',
            type: 'select',
            lookup: 'PUBLIC_CHARITY_STATUS',
            col: 4,
            prior: true,
            ...pullFieldValue(['XFDS99R2.15'], true),
          },
        ],
      },
      {
        groupId: 8,
        isVisible: true,
        hasDivider: true,
        fields: control50Fields('relOrgTaxExmptDirCntrlEnt', 'XFDS99R2.13'),
      },
      {
        groupId: 9,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Do you have more than 80% control?',
            name: 'relOrgTaxExmptMore80Label',
            type: 'label',
            col: 9,
          },
          {
            name: 'relOrgTaxExmptMore80',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            prior: true,
            isPriorEditable: true,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99R2.16'],
                fn: 'anyValidCheckboxOrFieldToYesNoRadioPull',
              },
            },
          },
        ],
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: true,
        fields: radioTransactFields(
          'relOrgTaxExmptTrnsacts',
          'showrelOrgTaxExmptTrnsacts'
        ),
      },
      transactTableGroup(
        11,
        'relOrgTaxExmptTransType',
        'relOrgTaxExmptTransAmt',
        'relOrgTaxExmptTransMthd'
      ),
    ],
  },
  {
    //Partnership
    title: 'Organization Details',
    isVisible: false,
    sectionId: 3,
    isCollpased: false,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: orgNameFields('relOrgPartshipName', 'XFDS99R3.0'),
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: einFields('relOrgPartshipEIN', 'XFDS99R3.1'),
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: addressFields(
          'relOrgPartshipAddressLabel',
          'relOrgPartshipCntry',
          'XFDS99R3.7',
          'relOrgPartshipAdd',
          'XFDS99R3.2',
          'relOrgPartshipCity',
          'XFDS99R3.3',
          'relOrgPartshipState',
          'XFDS99R3.4',
          'relOrgPartshipProv',
          'XFDS99R3.6',
          'relOrgPartshipProvState',
          'XFDS99R3.6',
          'XFDS99R3.4',
          'XFDS99R3.7',
          'relOrgPartshipZip',
          'XFDS99R3.5'
        ),
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: activityFields('relOrgPartshipActvty', 'XFDS99R3.8'),
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: domicileFields(
          'relOrgPartshipLglDmcl',
          'XFDS99R3.10',
          'relOrgPartshipLegalDom',
          'XFDS99R3.10',
          'relOrgPartshipLegalDomForeign',
          'XFDS99R3.9'
        ),
      },
      {
        groupId: 6,
        isVisible: true,
        fields: control50Fields('relOrgPartshipDirCntrlEnt', 'XFDS99R3.11'),
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Predominant Type of Income',
            name: 'rrelOrgPartshipIncmTypeLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'Predominant Type of Income',
            name: 'relOrgPartshipIncmType',
            type: 'select',
            lookup: 'PARTNERSHIP_INCOME_TYPE',
            col: 3,
            prior: true,
            priorCompareOptions: {
              compareAllLookup: true,
              isCaseSensitive: true,
            },
            ...pullFieldValue(['XFDS99R3.17'], true),
          },
        ],
      },
      {
        groupId: 8,
        isVisible: true,
        hasDivider: true,
        fields: shareIncomeFields('relOrgPartshipShareIncm'),
      },
      {
        groupId: 9,
        isVisible: true,
        hasDivider: true,
        fields: shareAssetsFields('relOrgPartshipShareEoyAssts'),
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: true,
        fields: dispropAllocFields('relOrgPartshipDisAll', 'XFDS99R3.12'),
      },
      {
        groupId: 11,
        isVisible: true,
        hasDivider: true,
        fields: codeVUBIFields('relOrgPartshipUBI'),
      },
      {
        groupId: 12,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'General or Managing Partner?',
            name: 'relOrgPartshipPartnerLabel',
            type: 'label',
            col: 9,
          },
          {
            name: 'relOrgPartshipPartner',
            type: 'radio',
            col: 3,
            lookup: 'YESNO_RADIO',
            prior: true,
            isPriorEditable: true,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99R3.13'],
                fn: 'anyValidCheckboxOrFieldToYesNoRadioPull',
              },
            },
          },
        ],
      },
      {
        groupId: 13,
        isVisible: true,
        hasDivider: true,
        fields: percentOwnFields('relOrgPartshipPercentOwn', 'XFDS99R3.19'),
      },
      {
        groupId: 14,
        isVisible: true,
        hasDivider: true,
        fields: radioTransactFields(
          'relOrgPartshipTrnsactns',
          'showrelOrgPartshipTrnsacts'
        ),
      },
      transactTableGroup(
        15,
        'relOrgPartshipTransType',
        'relOrgPartshipTransAmt',
        'relOrgPartshipTransMthd'
      ),
    ],
  },
  {
    //Corp Trust
    title: 'Organization Details',
    isVisible: false,
    sectionId: 4,
    isCollpased: false,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: orgNameFields('relOrgCorpTrstName', null),
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: einFields('relOrgCorpTrstEIN', null),
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: addressFields(
          'relOrgCorpTrstAddressLabel',
          'relOrgCorpTrstCntry',
          null,
          'relOrgCorpTrstAdd',
          null,
          'relOrgCorpTrstCity',
          null,
          'relOrgCorpState',
          null,
          'relOrgCorpTrstProv',
          null,
          'relOrgCorpTrstProvState',
          null,
          null,
          null,
          'relOrgCorpTrstZip',
          null,
        ),
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: activityFields('relOrgCorpTrstActvty', null),
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: domicileFields(
          'relOrgCorpTrstLglDmcl',
          null,
          'relOrgCorpTrstLegalDom',
          null,
          'relOrgCorpTrstLegalDomForeign',
          null,
        ),
      },
      {
        groupId: 6,
        isVisible: true,
        fields: control50Fields('relOrgCorpTrstDirCntrlEnt', null),
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Type of Entity',
            name: 'relOrgCorpTrstEntTypeLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'Type of Entity',
            name: 'relOrgCorpTrstEntType',
            type: 'select',
            lookup: 'CORP_TRUST_TYPE',
            col: 3,
          },
        ],
      },
      {
        groupId: 8,
        isVisible: true,
        hasDivider: true,
        fields: shareIncomeFields('relOrgCorpTrstShareIncm'),
      },
      {
        groupId: 9,
        isVisible: true,
        hasDivider: true,
        fields: shareAssetsFields('relOrgCorpTrstShareEoyAssts'),
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: true,
        fields: percentOwnFields('relOrgCorpTrstPercentOwn', null),
      },
      {
        groupId: 11,
        isVisible: true,
        hasDivider: true,
        fields: radioTransactFields(
          'relOrgCorpTrustTrnsactns',
          'showrelOrgCorpTrustTrnsacts'
        ),
      },
      transactTableGroup(
        12,
        'relOrgCorpTrstTransType',
        'relOrgCorpTrstTransAmt',
        'relOrgCorpTrstTransMthd'
      ),
    ],
  },
  {
    //Unrelated Partnership
    title: 'Organization Details',
    isVisible: false,
    sectionId: 5,
    isCollpased: false,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: orgNameFields('relOrgUnRelName', 'XFDS99R6.0'),
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: einFields('relOrgUnRelEIN', 'XFDS99R6.1'),
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: addressFields(
          'relOrgUnRelAddressLabel',
          'relOrgUnRelCntry',
          'XFDS99R6.7',
          'relOrgUnRelAdd',
          'XFDS99R6.2',
          'relOrgUnRelCity',
          'XFDS99R6.3',
          'relOrgUnRelState',
          'XFDS99R6.4',
          'relOrgUnRelProv',
          'XFDS99R6.6',
          'relOrgUnRelProvState',
          'XFDS99R6.6',
          'XFDS99R6.4',
          'XFDS99R6.7',
          'relOrgUnRelZip',
          'XFDS99R6.5'
        ),
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: activityFields('relOrgUnRelActvty', 'XFDS99R6.8'),
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: domicileFields(
          'relOrgUnRelLglDmcl',
          'XFDS99R6.10',
          'relOrgUnRelLegalDom',
          'XFDS99R6.10',
          'relOrgUnRelLegalDomForeign',
          'XFDS99R6.9'
        ),
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Predominant Type of Income',
            name: 'relOrgUnRelIncmTypeLabel',
            type: 'label',
            col: 6,
          },
          {
            label: 'Predominant Type of Income',
            name: 'relOrgUnRelIncmType',
            type: 'select',
            lookup: 'UNREL_INCOME_TYPE',
            col: 6,
            prior: true,
            priorCompareOptions: {
              compareAllLookup: true,
              isCaseSensitive: true,
            },
            ...pullFieldValue(['XFDS99R6.16'], true),
          },
        ],
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'All partners are 501(c)(3)',
            name: 'rrelOrgUnRel501c3Label',
            type: 'label',
            col: 9,
          },
          {
            name: 'relOrgUnRel501c3',
            type: 'radio',
            col: 3,
          },
        ],
      },
      {
        groupId: 8,
        isVisible: true,
        hasDivider: true,
        fields: shareIncomeFields('relOrgUnRelShareIncm'),
      },
      {
        groupId: 9,
        isVisible: true,
        hasDivider: true,
        fields: shareAssetsFields('relOrgUnRelShareEoyAssts'),
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: true,
        fields: dispropAllocFields('relOrgUnRelDisAll', 'XFDS99R6.13'),
      },
      {
        groupId: 11,
        isVisible: true,
        hasDivider: true,
        fields: codeVUBIFields('relOrgUnRelUBI'),
      },
      {
        groupId: 12,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'General or Managing Partner',
            name: 'relOrgUnRelPartnerLabel',
            type: 'label',
            col: 9,
          },
          {
            name: 'relOrgUnRelPartner',
            type: 'radio',
            col: 3,
            lookup: 'YESNO_RADIO',
            prior: true,
            isPriorEditable: true,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99R6.15'],
                fn: 'anyValidCheckboxOrFieldToYesNoRadioPull',
              },
            },
          },
        ],
      },
      {
        groupId: 13,
        isVisible: true,
        hasDivider: true,
        fields: percentOwnFields('relOrgUnRelPercentOwn', 'XFDS99R6.18'),
      },
    ],
  },
];

export const sections = [
  {
    title: 'General Information',
    isVisible: true,
    sectionId: 1,
    hasDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label:
              'Did the organization have ownership in or a relationship with any of the following types of entities?',
            name: 'textOne',
            type: 'label',
            col: 10,
            class: 'labelQuestionTitle',
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Disregarded entities',
            name: 'relOrgDisregardLabel',
            type: 'label',
            col: 10,
            styles: { paddingTop: 0 },
          },
          {
            type: 'radio',
            name: 'relOrgDisregard',
            col: 2,
            styles: { marginTop: '-15px' },
            lookup: 'YESNO_RADIO',
            prior: true,
            isPriorEditable: true,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99P4.39'],
                fn: 'anyValidCheckboxOrFieldToRadioPull',
              },
            },
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Related tax exempt organizations',
            name: 'relOrgTaxExmptLabel',
            type: 'label',
            col: 10,
            styles: { paddingTop: 0 },
          },
          {
            type: 'radio',
            name: 'relOrgTaxExmpt',
            col: 2,
            styles: { marginTop: '-15px' },
            lookup: 'YESNO_RADIO',
            prior: true,
            isPriorEditable: true,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99P4.40'],
                fn: 'anyValidCheckboxOrFieldToRadioPull',
              },
            },
          },
        ],
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Related partnerships',
            name: 'relOrgPartnerLabel',
            type: 'label',
            col: 10,
            styles: { paddingTop: 0 },
          },
          {
            type: 'radio',
            name: 'relOrgPartner',
            col: 2,
            styles: { marginTop: '-15px' },
            lookup: 'YESNO_RADIO',
            prior: true,
            isPriorEditable: true,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99P4.40'],
                fn: 'anyValidCheckboxOrFieldToRadioPull',
              },
            },
          },
        ],
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Related corporations or trusts',
            name: 'relOrgCorpTrustLabel',
            type: 'label',
            col: 10,
            styles: { paddingTop: 0 },
          },
          {
            type: 'radio',
            name: 'relOrgCorpTrust',
            col: 2,
            styles: { marginTop: '-15px' },
            lookup: 'YESNO_RADIO',
            prior: true,
            isPriorEditable: true,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99P4.40'],
                fn: 'anyValidCheckboxOrFieldToRadioPull',
              },
            },
          },
        ],
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Unrelated organizations- taxable as partnership',
            name: 'relOrgUnRelTaxPartnerLabel',
            type: 'label',
            col: 10,
            styles: { paddingTop: 0 },
          },
          {
            type: 'radio',
            name: 'relOrgUnRelTaxPartner',
            col: 2,
            styles: { marginTop: '-15px' },
            lookup: 'YESNO_RADIO',
            prior: true,
            isPriorEditable: true,
            priorCompareOptions: {
              radioShouldLookup: true,
            },
            axcess: {
              pull: {
                fields: ['XFDS99P4.43'],
                fn: 'anyValidCheckboxOrFieldToRadioPull',
              },
            },
          },
        ],
      },
      {
        groupId: 7,
        groupType: 'lineItem',
        isVisible: true,
        with990DenseRows: true,
        hasTableManagerTabs: true,
        isH2TabsHidden: true,
        entityPlaceHolder: 'Org',
        summaryAllType: 'Orgs',
        entityNameFields: [
          'relOrgDisregardName',
          'relOrgUnRelName',
          'relOrgTaxExmptName',
          'relOrgCorpTrstName',
          'relOrgPartshipName',
        ],
        isMasterSummaryHidden: true,
        axcessGroup: {
          pull: {
            fromSections: [
              'Identification of Disregarded Entities ',
              'Identification of Related Tax-Exempt Organizations ',
              'Related Organizations Taxable as a Partnership ',
              'Unrelated Organizations Taxable as a Partnership ',
            ],
            fieldPrefixes: ['XFDS99R1', 'XFDS99R2', 'XFDS99R3', 'XFDS99R6'],
          },
        },
        entityIdentifiers: [
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
          {
            axcessDataType: 'PassDataToChild',
          },
        ],
        fields: [
          {
            label: '-',
            name: 'relorgName',
            type: 'label',
            prior: true,
            isPriorEditable: false,
            pullCalculation: {
              keyIdentifiers: [
                'XFDS99R1.0',
                'XFDS99R2.0',
                'XFDS99R3.0',
                'XFDS99R4.0',
                'XFDS99R6.0',
              ],
              fn: 'anyValidPull',
            },
          },
          {
            label: 'Type',
            name: 'relOrgEntityType',
            type: 'select',
            printStyles: {zoom: '100%'},
            lookup: 'REL_ORG_TYPE',
            logicFunction: [{ trigger: 'setRelOrgEntity' }],
            prior: true,
            isPriorEditable: true,
            pullCalculation: {
              keyIdentifiers: [
                'XFDS99R1.0',
                'XFDS99R2.0',
                'XFDS99R3.0',
                'XFDS99R4.0',
                'XFDS99R6.0',
              ],
              fn: 'pullFieldToMatchDropDown',
            },
          },
          { label: '', type: 'label', name: '' },
          { type: 'lineButtons' },
        ],
        lineItems: [],
        entities: [],
        lineSections: relOrgLineSections,
        currentEntity: -1,
        subWorkSheets: [],
        headerLabelsPrintStyles: {gridTemplateColumns: '5fr 1.5fr 1.25fr .75fr'},
        lineItemDetails: {
          headerLabels: [
            {
              label: 'Organization',
              col: 3,
              colTablet: 3,
              align: 'left',
              styles: { paddingLeft: '48px' },
            },
            {
              label: 'Type',
              col: 3,
              colTablet: 3,
              align: 'left',
              styles: { paddingLeft: '44px' },
            },
            { col: 5, colTablet: 1 },
            { label: 'Actions', col: 1, colTablet: 1, align: 'left' },
          ],
          footerLabels: [
            {
              type: 'buttons',
              col: 12,
              buttonTitle: 'Add Another Org',
              icon: 'add',
            },
          ],
          lineButtons: [{ button: 'remove' }],
        },
      },
    ],
  },
  {  
    title: 'Additional Information',
    collapseClass: 'collapsibleForm',
    isVisible: true,
    isDefaultExpanded: false,
    isOptionalSectionMerge: true,
    sectionId: 2,
    groups: [
      {
        groupId: 2,
        groupType: 'details',
        fields: [
          { 
            label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
            type: 'label', 
            class: 'labelLeftLight' 
          },
        ]
      },
      {
        groupId: 1,
        fields: [
          {
            label: 'Start typing to provide additional information', 
            name: 'additionalInfoTextArea', 
            type: 'textarea', 
            col: 12,
            maxLength: 9000
          }
        ]
      }
    ]
  },
];

export const getRelatedOrganizationsTitles = () => {
  let titles = [];
  sections.forEach((section) => {
    if (!titles.includes(section.title)) {
      titles.push(section.title);
    }
  });
  return titles;
};

function RelatedOrganizations(props) {
  const { isExportForm } = props;
  const { updateCard, REDUX } = usePageFramework(NAV.RELATED_ORGANIZATIONS_SCHED_R);
  const [sectionState, setSectionState] = useState('');
  const {isActiveClient, isLoading} = useLoadOrganizer();

  useEffect(() => {
    if (!isExportForm) updateCard();

    setSectionState(sections);
    // eslint-disable-next-line
  }, []);

  const axcessIdentifiers = [
    { section: 'Return Type', id: 'Form 990 - Exempt Organization Return' },
    {
      section: 'Schedules',
      id: 'Sch R - Related Organizations and Unrelated Partnerships',
    },
  ];

  return (
    <>
    {!isLoading && isActiveClient &&<FormRendererHelper
      sections={sectionState}
      key={REDUX.RELATED_ORGANIZATIONS_SCHED_R}
      formName={REDUX.RELATED_ORGANIZATIONS_SCHED_R}
      identifiers={axcessIdentifiers}
      // triggeredEvent={triggeredEvent}
      sectionFieldLogic={sectionFieldLogic}
	  isExportForm={isExportForm}

    />}
    {!isLoading && !isActiveClient && <NoActiveClient />}
</>
  );
}

export default RelatedOrganizations;
