const pullFieldValue = (fieldId, isPriorEditable) => {
  return {
    prior: true,
    isPriorEditable: isPriorEditable,
    axcess: {
      pull: {
        fields: [...fieldId],
        fn: 'anyValidPull',
      },
    },
  };
};

/**
 * Returns an object containing all the properties necessary to add Axcess Tax
 * pull mapping to a field where you want it to populate from one field if
 * another fields equals the specified value.
 *
 * @param {string} valueFieldId
 * @param {string} matchFieldId
 * @param {string} matchValue
 * @param {boolean} isPriorEditable
 * @returns object containing pull properties
 */
const pullFieldValueIfOtherEquals = (
  valueFieldId,
  matchFieldId,
  matchValue,
  isPriorEditable
) => {
  return {
    prior: true,
    isPriorEditable: isPriorEditable,
    axcess: {
      pull: {
        fields: [valueFieldId, matchFieldId],
        fn: 'pullIfFieldMatches',
        data: {
          matchValue,
        },
        populateMultiple: true, // GROWTH: Allow as argument
        separator: '\n', // GROWTH: Allow as argument
      },
    },
  };
};

/**
 * Returns an object containing all the properties necessary to add Axcess Tax
 * pull mapping to a field where you want it to populate from one field if
 * another fields equals the specified value.
 *
 * @param {string} valueFieldIdOne
 * @param {string} valueFieldIdTwo
 * @param {string} matchFieldId
 * @param {string} matchValue
 * @param {boolean} isPriorEditable
 * @returns object containing pull properties
 */
const conditionalPullFieldValueIfOtherEquals = (
  valueFieldIdOne,
  valueFieldIdTwo,
  matchFieldId,
  isPriorEditable
) => {
  return {
    prior: true,
    isPriorEditable: isPriorEditable,
    axcess: {
      pull: {
        fields: [valueFieldIdOne, valueFieldIdTwo, matchFieldId],
        fn: 'conditionalPullIfFieldMatches',
        populateMultiple: true, // GROWTH: Allow as argument
        separator: '\n', // GROWTH: Allow as argument
      },
    },
  };
};

const pullFilteredFieldValue = (fieldId, isPriorEditable, charToFilter) => {
  return {
    prior: true,
    isPriorEditable: isPriorEditable,
    axcess: {
      pull: {
        fields: [fieldId],
        fn: 'anyValidFilteredPull',
        data: {
          charToFilter,
        },
      },
    },
  };
};

const pullFieldPercentValue = (fieldId, isPriorEditable) => {
  return {
    prior: true,
    isPriorEditable: isPriorEditable,
    axcess: {
      pull: {
        fields: [...fieldId],
        fn: 'anyValidPercentPull',
      },
    },
  };
};

export {
  pullFieldValue,
  pullFieldValueIfOtherEquals,
  conditionalPullFieldValueIfOtherEquals,
  pullFilteredFieldValue,
  pullFieldPercentValue,
};
