import React from 'react';
// common modules
import {
  Grid,
  GridCell,
  FileInput,
  Form,
  FontIcon,
  TextIconSpacing,
  Divider,
} from 'react-md';

// custom modules
import usePageFramework from '@utilities/hooks/usePageFramework';
import useWindowDimensions from '@utilities/hooks/useWindowDimensions';
import { ACCEPTED_FILE_TYPES } from '@utilities/constants/document';
import DocumentListItem from './documentListItem/DocumentListItem';
import DocumentListItemMobile from './documentListItem/DocumentListItemMobile';
import { Pagination } from '@mui/material';
import usePagination from '@utilities/hooks/usePagination';
import useAdaptive from '@utilities/hooks/useAdaptive';

function OrganizerUploadsDrawer(props) {
  const { uploadList, onUpload, useType } = props;
  const { width } = useWindowDimensions();
  const dashKey = 'dashboard';
  const { isLaptopOrDesktop } = useAdaptive();
  const uploadsPerPage = 5;
  const sortedUploads = [...uploadList].sort(
    (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
  );
  const totalPages = Math.ceil(sortedUploads.length / uploadsPerPage);
  const uploadsPagination = usePagination(sortedUploads, uploadsPerPage);

  const onChange = (event) => onUpload(event, dashKey);

  const getScreenCol = (col) => {
    const colTab =
      col === 10 ? 6 : col === 6 ? 3 : col === 3 ? 2 : col === 2 ? 1 : col;
    return width < 1025 ? colTab : col;
  };

  const handlePageChange = (_, p) => uploadsPagination.jump(p);

  const uploadedFileList = isLaptopOrDesktop
    ? sortedUploads.map((file, idx, array) => (
        <DocumentListItem
          key={`organizer-upload-${file.id}`}
          {...{ file, idx, array, useType, getScreenCol }}
        />
      ))
    : // Mobile version
      uploadsPagination
        .currentData()
        .map((file, idx, array) => (
          <DocumentListItemMobile
            key={`organizer-upload-${file.id}`}
            {...{ file, idx, array, useType, getScreenCol }}
          />
        ));

  return (
    <div className="uploadDrawerContainer">
      {isLaptopOrDesktop && (
        <Grid className="uploadDrawerHeaderContainer">
          <GridCell colSpan={getScreenCol(12)}>
            <Divider />
          </GridCell>
          <GridCell colSpan={getScreenCol(6)} className="headerLabelsLeft">
            File Name
          </GridCell>
          <GridCell
            colSpan={getScreenCol(3)}
            className="uploadHeaderheaderLabelsLeft">
            Uploaded By
          </GridCell>
          <GridCell
            colSpan={getScreenCol(2)}
            className="uploadHeaderheaderLabelsLeft">
            Date Uploaded
          </GridCell>
          <GridCell colSpan={1}></GridCell>
          <GridCell colSpan={getScreenCol(12)}>
            <Divider />
          </GridCell>
        </Grid>
      )}
      {!!uploadedFileList?.length && (
        <Divider className="uploadDrawerDivider" />
      )}
      <div className="uploadedListContainer">
        <div className="uploadList">{uploadedFileList}</div>
        {!isLaptopOrDesktop && uploadedFileList.length ? (
          <Pagination
            className="uploadsPagination"
            count={totalPages}
            page={uploadsPagination.currentPage}
            size="small"
            shape="rounded"
            onChange={handlePageChange}
          />
        ) : (
          <></>
        )}
      </div>
      {!isLaptopOrDesktop || (useType && useType === 'readOnly') ? (
        <></>
      ) : (
        <Grid>
          <GridCell colSpan={6}>
            <Form>
              <FileInput
                theme="clear"
                themeType="flat"
                id="dashboard-upload-button"
                onChange={onChange}
                buttonType={'text'}
                disableIconSpacing={true}
                multiple={true}
                icon={
                  <TextIconSpacing
                    icon={
                      <FontIcon className="addIcon">add</FontIcon>
                    }></TextIconSpacing>
                }
                className={'addMoreFile'}
                accept={ACCEPTED_FILE_TYPES}>
                Upload More Files
              </FileInput>
            </Form>
          </GridCell>
        </Grid>
      )}
    </div>
  );
}

export default OrganizerUploadsDrawer;
