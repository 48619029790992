import * as INPUTSTATE from '@utilities/constants/inputState';

function MultiSelectDropdown(props) {
  const { field, group } = props;
  const priorYearChanged = field.priorYearValue !== field.default;
  const isLineItemField = group.groupType === 'lineItem';
  const fieldValue = () => {
    let fieldValueText = ' ';
    field.default?.forEach((value, index) => {
      index ===  field.default?.length - 1 ? fieldValueText = `${fieldValueText} ${value.name}` :
      fieldValueText = `${fieldValueText} ${value.name},`
    });
    return fieldValueText;
    
  };
  const fieldWidth = field.printFieldWidth;   
  const maxWidth = field.printFieldWidth || 300;   
  const visibleHeaders = group.lineItemDetails?.headerLabels?.filter(header => header.label !== '');
  const colWidthCalc = visibleHeaders?.length ? visibleHeaders?.length <= 4 && visibleHeaders?.length !== 2 ? 280
      : visibleHeaders?.length <= 7 && visibleHeaders?.length !== 2 ? 180 : fieldWidth : fieldWidth;
  return (
    <div data-testid='multiselect-dropdown' className={isLineItemField ? '' : "orgExportTextAreaDiv" } style={isLineItemField ? {maxWidth: maxWidth, width: colWidthCalc, minWidth: 120, marginTop: '-6px'} : {marginTop: 0}}>  
      <label className="orgExportTextAreaTopLabel">{field.placeHolder}</label>
      <p className='orgExportTextArea' style={!priorYearChanged ? {fontStyle: 'italic'} : null} > {fieldValue()}</p>
      {
        field.inputState === INPUTSTATE.PRACTITIONER_INPUT ? 
        <label className="orgExportTextAreaPracLabel">{'(practitioner)'}</label> : null
      }
    </div>
  );
}

export default MultiSelectDropdown;