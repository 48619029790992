import { useEffect } from 'react';

import DashboardSubMenu from '@components/header/dashboardSubMenu';
import DashboardGrid from '@components/dashboard/dashboardGrid';
import { setToolbarTitle } from '@utilities/actions/global';
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as STRING from '@utilities/constants/strings';
//hidden forms to load forms initially
import Contributions from '@views/financial/contributions';
import BasicData from '@views/basicInformation/basicData';
import Financials from '@views/financial/financials';
import TaxComplianceFilings from '@views/governance/taxComplianceFilings';
import EntryInterview from '@views/basicInformation/entryInterview';
import PubCharStaSec from '@views/financial/publicCharityAndSupport';
import InterestedPersonsTransactions from '@views/otherSchedules/interestedPersons';
import PolCampaigLobAno from '@views/financial/politicalCampaignAndLobbying';
import SuppFinStatements from '@views/otherSchedules/supplementalFinancialStatements';
import TaxExemptBonds from '@views/otherSchedules/taxExemptBonds';
import InternalSetup from '@views/basicInformation/internalSetup';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';
import OrganizerUploads from '@components/OrganizerUploads';

function Dashboard() {
  const { dispatch, updateCard, selectState, REDUX } = usePageFramework();
  const dashboard = selectState(REDUX.DASHBOARD);
  const categories = dashboard ? dashboard : [];
  const contributionForm = selectState(REDUX.CONTRIBUTIONS);
  const basicDataForm = selectState(REDUX.BASIC_DATA);
  const financialsForm = selectState(REDUX.FINANCIALS);
  const taxComplianceForm = selectState(REDUX.TAX_COMPLIANCE_FILINGS);
  const entryExperienceForm = selectState(REDUX.ENTRY_INTERVIEW);
  const publicCharityForm = selectState(REDUX.PUBLIC_CHARITY_AND_SUPPORT);
  const interestedPersonsForm = selectState(
    REDUX.INTERESTED_PERSONS_TRANSACTIONS
  );
  const politicalCampaignForm = selectState(
    REDUX.POLITICAL_CAMPAIGN_AND_LOBBYING
  );
  const supFinStmntsForm = selectState(REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS);
  const taxExemptBondsForm = selectState(REDUX.TAX_EXEMPT_BONDS);
  const internalSetupForm = selectState(REDUX.INTERNAL_SETUP);

  useEffect(() => {
    dispatch(setToolbarTitle(STRING.DASHBOARD));
    updateCard();
  });

  const { isActiveClient, isLoading } = useLoadOrganizer();

  return (
    <>
      {!isLoading && isActiveClient && (
        <>
          <OrganizerUploads style={{ top: '30px' }} />
          <div className="dashboardSize">
            <DashboardSubMenu />
            <DashboardGrid categories={categories} />
            <div className="hiddenForms">
              {!contributionForm ? <Contributions /> : null}
              {!basicDataForm ? <BasicData /> : null}
              {!financialsForm ? <Financials /> : null}
              {!taxComplianceForm ? <TaxComplianceFilings /> : null}
              {!entryExperienceForm ? (
                <EntryInterview currentIndex={0} />
              ) : null}
              {!entryExperienceForm ? (
                <EntryInterview currentIndex={1} />
              ) : null}
              {!entryExperienceForm ? (
                <EntryInterview currentIndex={2} />
              ) : null}
              {!publicCharityForm ? <PubCharStaSec /> : null}
              {!interestedPersonsForm ? (
                <InterestedPersonsTransactions />
              ) : null}
              {!politicalCampaignForm ? <PolCampaigLobAno /> : null}
              {!supFinStmntsForm ? <SuppFinStatements /> : null}
              {!taxExemptBondsForm ? <TaxExemptBonds /> : null}
              {!internalSetupForm ? <InternalSetup /> : null}
            </div>
          </div>
        </>
      )}
      {!isLoading && !isActiveClient && <NoActiveClient />}
    </>
  );
}

export default Dashboard;
