export const NOT_STARTED = 1;
export const IN_PROGRESS = 2;
export const ERRORS = 3;
export const COMPLETED = 4;
export const DOES_NOT_APPLY = 5;
export const REQUIRED = 6;

export const STATUS_TITLE = {
    1: 'Not Started',
    2: 'In Progress',
    3: 'Errors',
    4: 'Completed',
    5: 'Does Not Apply',
    6: 'Required',
};