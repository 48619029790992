import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { triggeredEvent, sectionFieldLogic } from './governanaceAndManagementLogicTrigger';
import { pullFieldValue } from '@utilities/pullFieldValue';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

const sections = [
	{
		title: 'Governance',
		isVisible: true,
		sectionId: 1,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Enter the number of members of the governing body (i.e. board of directors) that have the power to vote on all matters that come before the governing body as of the end of the organization’s tax year:',
						name: 'govBodyVoteMbrNoLabel',
						type: 'label',
						col: 9
					},
					{ name: 'govBodyVoteMbrNo', placeholder: 'Member', type: 'total', col: 3, hasDivider: 'Yes' },
					{
						label: 'Last year you reported:',
						name: 'govBodyVoteMbrNoPYLabel',
						type: 'label',
						col: 9
					},
					{ name: 'govBodyVoteMbrNoPY', placeholder: 'Year', type: 'total', col: 3, ...pullFieldValue(['XFDS99P6.0'], false) },
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Are there material differences in voting rights among members of the governing body?',
						name: 'govBodyVoteMbrDifLabel',
						type: 'label',
						col: 9
					},
					{
						name: 'govBodyVoteMbrDif',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showGovBodyVoteMbrDifDscdiff' }]
					}
				]
			},
			{
				groupId: 3,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Please explain the differences.', name: 'govBodyVoteMbrDifDscdiffLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
					{ label: 'Please explain', placeholder: 'Please explain', type: 'textarea', col: 12, maxRows: 3, maxLength: 9000, class: 'fieldTextArea', name: 'govBodyVoteMbrDifDscdiff' }
				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Does the organization’s governing body or governing documents delegate authority to act on its behalf to an executive committee or similar committee?',
						name: 'govBodyVoteMbrDlgtLabel',
						type: 'label',
						col: 9,
					},
					{
						name: 'govBodyVoteMbrDlgt',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showGovBodyVoteMbrDlgtDsc' }]
					}
				]
			},
			{
				groupId: 5,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Describe the composition of this committee, whether any of the committee’s members are not on the governing body, and the scope of the committee’s authority. ', name: 'govBodyVoteMbrDlgtDscLabel', class: 'labelTitleTextArea', type: 'label', col: 12 },
					{ label: 'Please explain', placeholder: 'Please explain', type: 'textarea', col: 12, maxRows: 3, class: 'fieldTextArea', name: 'govBodyVoteMbrDlgtDsc', maxLength: 9000 }
				]
			},
			{
				groupId: 6,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Did any officer, director, trustee or key employee have a family relationship (spouse, sibling, parent, child) or a business relationship with any other officer, director, trustee or key employee?',
						name: 'govBodyOffFamRelLabel',
						type: 'label',
						col: 9
					},
					{
						name: 'govBodyOffFamRel',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showGovBodyOffFamRelData' }]
					}
				]
			},
			{
				isVisible: false,
				groupId: 7,
				hasDivider: true,
				fields: [
					{
						label: 'Provide names of individuals and the relationships between the individuals', class: 'labelTitleTextArea', name: 'govBodyOffFamRelNamesLabel',
						type: 'label', col: 12
					},
					{
						label: 'Provide names of individuals and the relationships between the individuals', name: 'govBodyOffFamRelNames', class: 'fieldTextArea', type: 'textarea', col: 12,
					}
				],
			},
			{
				groupId: 8,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'During the tax year, did the organization delegate control over management duties customarily performed by or under the direct supervision of officers, directors, trustees, or key employees to a management company or other person?',
						name: 'govBodyDelMgmtDutyLabel',
						type: 'label',
						class: 'labelTitleTextArea',
						col: 9
					},
					{
						name: 'govBodyDelMgmtDuty',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showOrgDelFields' }]
					}
				]
			},
			{
				groupId: 9,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Include the name of the management company/person, and services provided.  List any current/former Officer, Director, Key Employee, or Highest Compensated and the amount they were paid.', class: 'labelTitleTextArea', name: 'govBodyDelMgmtDutyNameLabel', type: 'label', col: 12 },
					{ label: 'Explain: Include the name of the management company/person, and services provided.  List any current/former Officer, Director, Key Employee, or Highest Compensated and the amount they were paid:', name: 'govBodyDelMgmtDutyName', type: 'textarea', col: 12, maxLength: 9000, class: 'fieldTextArea' },
				]
			},
			{
				groupId: 10,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Did the organization become aware during the year of a material diversion of the organization\'s assets?',
						name: 'govBodyDiversionAssetsLabel',
						type: 'label',
						col: 9
					},
					{
						name: 'govBodyDiversionAssets',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showMaterialDiversionFields' }]
					},
				]
			},
			{
				groupId: 11,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Explain the nature of the diversion, including the dollar amount of the property involved and explain any corrective action taken',
						name: 'govBodyDiversionAssetsNatureLabel',
						type: 'label',
						class: 'labelTitleTextArea',
						col: 12
					},
					{
						label: 'Please explain', name: 'govBodyDiversionAssetsNature', type: 'textarea', class: 'fieldTextArea', col: 12, maxLength: 9000
					},
				]
			},
			{
				groupId: 12,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Does the organization have members or stockholders?',
						name: 'govBodyMbrsOrStockLabel',
						type: 'label',
						col: 9
					},
					{
						label: 'Does the organization have members or stockholders?',
						name: 'govBodyMbrsOrStock',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showGovBodyMbrsOrStockData' }],
						...pullFieldValue(['XFDS99P6.6'], true)
					}
				]
			},
			{
				isVisible: false,
				groupId: 13,
				hasDivider: true,
				fields: [
					{ label: 'Please include classes and rights of member/stockholders', name: 'govBodyMbrsOrStockClassLabel', class: 'labelTitleTextArea', type: 'label', col: 12 },
					{
						label: 'Please explain',
						type: 'textarea',
						class: 'fieldTextArea',
						col: 12,
						maxLength: 9000,
						name: 'govBodyMbrsOrStockClass',
						prior: true,
						isPriorEditable: true,
						axcess: {
							pull: {
								fields: ['XFDS9AEX.1', 'XFDS9AEX.0'],
								fn: 'govMbrsStockPull',
								populateMultiple: true,
								separator: '\n',
							}
						},
					},
				],
			},
			{
				groupId: 14,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'At any time during the tax year, were there one or more persons (other than the organization’s governing body) that had the right to elect or appoint one or more members of the governing body?',
						name: 'govBodyPowerApptGovBodyLabel',
						type: 'label',
						col: 9
					},
					{
						label: 'At any time during the tax year, were there one or more persons (other than the organization’s governing body) that had the right to elect or appoint one or more members of the governing body?',
						name: 'govBodyPowerApptGovBody',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showGovBodyPowerApptGovData' }],
						...pullFieldValue(['XFDS99P6.7'], true)
					},
				]
			},
			{
				groupId: 15,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Please include classes of individual/entities and nature of their rights', name: 'govBodyPowerApptGovBodyClassLabel', class: 'labelTitleTextArea', type: 'label', col: 12 },
					{
						label: 'Please explain',
						type: 'textarea',
						col: 12,
						maxLength: 9000,
						class: 'fieldTextArea',
						name: 'govBodyPowerApptGovBodyClass',
						prior: true,
						isPriorEditable: true,
						axcess: {
							pull: {
								fields: ['XFDS9AEX.1', 'XFDS9AEX.0'],
								fn: 'govBodyGovPull',
								populateMultiple: true,
								separator: '\n',
							}
						},
					},
				],
			},
			{
				groupId: 16,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'At any time during the tax year, were any of the organization’s governance decisions reserved to or subject to approval by any other parties other than the governing body, such as by members, stockholders, or other persons?',
						name: 'govBodyPowerApptNonGovBodyLabel',
						type: 'label',
						col: 9
					},
					{
						name: 'govBodyPowerApptNonGovBody',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showGovBodyPowerApptNonGovBodyData' }],
						...pullFieldValue(['XFDS99P6.8'], true)
					},
				]
			},
			{
				groupId: 17,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Include class(es) of individuals/entities, decisions that require their approval, and nature of their voting rights',
						name: 'govBodyPowerApptNonGovBodyClassLabel',
						type: 'label',
						class: 'labelTitleTextArea',
						col: 10
					},
					{
						label: 'Please explain',
						type: 'textarea',
						name: 'govBodyPowerApptNonGovBodyClass',
						col: 12,
						class: 'fieldTextArea',
						maxLength: 9000,
						prior: true,
						isPriorEditable: true,
						axcess: {
							pull: {
								fields: ['XFDS9AEX.1', 'XFDS9AEX.0'],
								fn: 'govBodyNonGovPull',
								populateMultiple: true,
								separator: '\n',
							}
						},
					}
				],
			},
			{
				groupId: 18,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Did the organization contemporaneously document the meetings held or written actions undertaken during the year by the governing body?',
						name: 'govBodyContempDocGovBodyLabel',
						type: 'label',
						col: 9,
					},
					{
						name: 'govBodyContempDocGovBody',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						logicFunction: [{ trigger: 'showgovBodyContempDocExp' }],
						col: 3,
					}
				]
			},
			{
				groupId: 19,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Explain the organization\'s practices or policies regarding documentation of meetings and written actions of its governing body.',
						name: 'govBodyContempDocExpLabel',
						type: 'label',
						col: 12,
						class: 'labelTitleTextArea'
					},
					{
						label: 'Please explain',
						name: 'govBodyContempDocExp',
						type: 'textarea',
						maxLength: 9000,
						class: 'fieldTextArea',
						col: 12,
						prior: true,
						isPriorEditable: true,
						axcess: {
							pull: {
								fields: ['XFDS9AEX.1', 'XFDS9AEX.0'],
								fn: 'govBodyContExpPull',
								populateMultiple: true,
								separator: '\n',
							}
						},
					}
				]
			},
			{
				groupId: 20,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Did the organization contemporaneously document the meetings held or written actions undertaken during the year by each committee with authority to act on behalf of the governing body?',
						name: 'govBodyContempDocCmteLabel',
						type: 'label',
						col: 9
					},
					{
						name: 'govBodyContempDocCmte',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						logicFunction: [{ trigger: 'showgovBodyContempDocCmteExp' }],
						col: 3,
					}
				]
			},
			{
				groupId: 21,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Explain the organization\'s practices or policies regarding documentation of meetings and written actions of each committee with authority to act on its behalf.',
						name: 'govBodyContempDocCmteExpLabel',
						type: 'label',
						class: 'labelTitleTextArea',
						col: 12
					},
					{
						label: 'Please explain',
						name: 'govBodyContempDocCmteExp',
						type: 'textarea',
						col: 12,
						class: 'fieldTextArea',
						maxLength: 9000,
						prior: true,
						isPriorEditable: true,
						axcess: {
							pull: {
								fields: ['XFDS9AEX.1', 'XFDS9AEX.0'],
								fn: 'govBodyContCmtePull',
								populateMultiple: true,
								separator: '\n',
							}
						},
					},
				]
			},
		]
	},
	{
		title: 'Policies',
		isVisible: true,
		sectionId: 3,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Does the organization have local chapters, branches or affiliates?',
						name: 'govBodyLocalChptsLabel',
						type: 'label',
						col: 9
					},
					{
						name: 'govBodyLocalChpts',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showGovBodyWrtnPolLocalChpts' }]
					}
				]
			},
			{
				groupId: 2,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Does the organization have written policies and procedures governing the activities of such chapters, affiliates and branches to ensure their operations are consistent with those of the organization?',
						name: 'govBodyWrtnPolLocalChptsLabel',
						type: 'label',
						col: 9
					},
					{
						name: 'govBodyWrtnPolLocalChpts',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showGovBodyWrtnPolLocalChptsExp' }]
					}
				]
			},
			{
				groupId: 3,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Explain how the organization ensures that the local unit\'s activities are consistent with its own', name: 'govBodyWrtnPolLocalChptsExpLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
					{
						label: 'Please enter your comments/questions',
						name: 'govBodyWrtnPolLocalChptsExp',
						type: 'textarea',
						col: 12,
						maxRows: 3,
						class: 'fieldTextArea',
						prior: true,
						isPriorEditable: true,
						axcess: {
							pull: {
								fields: ['XFDS9AEX.1', 'XFDS9AEX.0'],
								fn: 'govBodyWrtnPolLocalChptsExpPull',
								populateMultiple: true,
								separator: '\n'
							}
						}
					},
				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: `Will a COMPLETE Copy (including all of Schedule B) of the Form 990 be provided to the ENTIRE organization's governing body before it is filed?`,
						name: 'govBodyRev990Label',
						type: 'label',
						col: 9
					},
					{
						name: 'govBodyRev990',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3
					}
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Please describe the process used to review the Form 990:', name: 'govBodyRev990ProcLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
					{
						label: 'Please explain',
						name: 'govBodyRev990Proc',
						type: 'textarea',
						col: 12,
						maxRows: -1,
						maxLength: 9000,
						class: 'fieldTextArea',
					},
				]
			},
			{
				groupId: 6,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
						label: 'Does the organization have a written conflict of interest policy that covers all persons in a position of authority over the organization, including officers, directors or trustees, and key employees?',
						name: 'govBodyConflictPlcyLabel',
						type: 'label',
						col: 9
					},
					{
						name: 'govBodyConflictPlcy',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [
							{ trigger: 'showConflictInterestFields' },
							{ trigger: 'showGovBodyConflictEnfDesc' }
						]
					}
				]
			},
			{
				groupId: 7,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Are officers, directors/trustees, and key employees required to annually disclose interests (including family and businesses) that could give rise to conflicts?',
						name: 'govBodyAnnDiscloseLabel',
						type: 'label',
						col: 9
					},
					{
						name: 'govBodyAnnDisclose',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3
					}
				]
			},
			{
				groupId: 8,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: 'Does the organization regularly and consistently monitor and enforce compliance with the policy?',
						name: 'govBodyConflictEnfLabel',
						type: 'label',
						col: 9
					},
					{
						name: 'govBodyConflictEnf',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showGovBodyConflictEnfDesc' }]
					}
				]
			},
			{
				groupId: 9,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Please describe the regular and consistent process', name: 'descThreeLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
					{
						label: 'Please explain',
						name: 'descThree',
						type: 'textarea',
						col: 12,
						maxRows: 3,
						maxLength: 9000,
						class: 'fieldTextArea',
						...pullFieldValue(['XFDS99SB.5'], true)
					},
				]
			},
			{
				groupId: 10,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Does the organization have a written whistleblower policy?', name: 'govBodyWhistlePlcyLabel', type: 'label', col: 9 },
					{
						label: '',
						name: 'govBodyWhistlePlcy',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						...pullFieldValue(['XFDS99SB.3'], true)
					},
				]
			},
			{
				groupId: 11,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Does the organization have a written document retention and destruction policy?', name: 'govBodyRetenPlcyLabel', type: 'label', col: 9 },
					{
						label: '',
						name: 'govBodyRetenPlcy',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						...pullFieldValue(['XFDS99SB.4'], true)
					},
				]
			},
			{
				groupId: 12,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Does the organization have a process for determining compensation of the CEO, executive director, or top management official or key employees that includes an independent review, comparability data, and contemporaneous substantiation of the deliberation and decision?', name: 'govBodyCompRevTopLabel', type: 'label', col: 9 },
					{
						label: '',
						name: 'govBodyCompRevTop',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showgovBodyCompRevOthFields' }],
						...pullFieldValue(['XFDS99SB.6'], true)
					},
				]
			},
			{
				groupId: 13,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization have a process for determining compensation of other officers and key employees that includes independent review, comparability data, and contemporaneous substantiation of the deliberation and decision?', name: 'govBodyCompRevOthLabel', type: 'label', col: 9 },
					{
						label: '',
						name: 'govBodyCompRevOth',
						type: 'radio',
						lookup: 'YESNO_RADIO',
						col: 3,
						logicFunction: [{ trigger: 'showgovBodyCompRevOthFields' }],
						...pullFieldValue(['XFDS99SB.7'], true)
					},
				]
			},
			{
				groupId: 14,
				isVisible: false,
				fields: [
					{ label: 'Please describe the process for each of the above responses:', name: 'descFiveLabel', type: 'label', col: 9, class: 'labelTitleTextArea' },
					{
						label: 'Please explain',
						name: 'descFive',
						type: 'textarea',
						col: 12,
						maxRows: 3,
						maxLength: 9000,
						class: 'fieldTextArea',
						...pullFieldValue(['XFDS99SB.10'], true)
					},
				]
			},
			{
				groupId: 15,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Please select the last year this process was completed', name: 'govBodyCompRevOthWhenLabel', type: 'label', col: 10 },
					{ label: 'Year', name: 'govBodyCompRevOthWhen', type: 'yearPicker', col: 2, },
				]
			},
			{
				groupId: 16,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Did the organization invest in, contribute assets to, or participate in a joint venture or similar arrangement with a taxable entity during the year?', name: 'govBodyInvestJointVntrLabel', type: 'label', col: 9 },
					{ label: '', name: 'govBodyInvestJointVntr', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showgovBodyInvestPolicy' }] },
				]
			},
			{
				groupId: 17,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Has the organization adopted a written policy or procedure requiring the organization to evaluate its participation in joint venture arrangements and taken steps to safeguard the organization\'s exempt status with respect to such arrangements?', name: 'govBodyInvestPolicyLabel', type: 'label', col: 9 },
					{ label: '', name: 'govBodyInvestPolicy', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
				]
			},
		]
	},
	{

		title: 'Disclosures',
		isVisible: true,
		sectionId: 5,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Is the Organization required to file a copy of its Form 990 with any State?', name: 'govBody990FileStateReqLabel', type: 'label', col: 9 },
					{ label: '', name: 'govBody990FileStateReq', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showGovBody990FileStates' }] },
				]
			},
			{
				groupId: 2,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Select the states', name: 'govBody990FileStatesLabel', type: 'label', col: 6 },
					{
						label: '',
						placeHolder: 'Select the states',
						name: 'govBody990FileStates',
						type: 'multiSelectDropdown',
						singleSelect: false,
						showArrow: true,
						showCheckbox: true,
						displayValue: 'name',
						col: 12,
						isObject: true,
						disablePreSelectedValues: false,
						lookup: 'SELECTFILINGSTATES',
						prior: true,
						isPriorEditable: true,
						axcess: {
							pull: {
								fields: ['XFDSGENO.22'],
								fn: 'gridToMultiselectPull',
								populateMultiple: true
							}
						}
					},// TODO: convert to multiselect component
				]
			},
			{
				groupId: 3,
				isVisible: true,
				fields: [
					{ label: 'How do you make your Form 1023 (or 1024 if applicable), 990, and 990-T (section 501(c)(3) filers only) available for public inspection? (Check all that apply):', name: 'govBodyFormsInspMthdText', type: 'label', class: 'labelQuestionTitle', col: 12 },
				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: `Organization's Website`, name: 'govBodyFormsInspMthdOrgWebLabel', type: 'label', col: 10, class: 'labelSingleCheckbox' },
					{ label: 'Yes', type: 'checkbox', name: 'govBodyFormsInspMthdOrgWeb', isFieldCheckbox: true, col: 2, class: 'singleCheckboxMargin'}
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Another website', name: 'govBodyFormsInspMthdOtherWebLabel', type: 'label', col: 10, class: 'labelSingleCheckbox' },
					{ label: 'Yes', type: 'checkbox', name: 'govBodyFormsInspMthdOtherWeb', isFieldCheckbox: true, col: 2, class: 'singleCheckboxMargin' }
				]
			},
			{
				groupId: 6,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Upon request', name: 'govBodyFormsInspMthdRequestLabel', type: 'label', col: 10, class: 'labelSingleCheckbox' },
					{ label: 'Yes', type: 'checkbox', name: 'govBodyFormsInspMthdRequest', isFieldCheckbox: true, col: 2, class: 'singleCheckboxMargin' }
				]
			},
			{
				groupId: 7,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Other', name: 'govBodyFormsInspMthdOtherLabel', type: 'label', col: 10, class: 'labelSingleCheckbox' },
					{
						label: 'Yes',
						type: 'checkbox',
						name: 'govBodyFormsInspMthdOther',
						isFieldCheckbox: true,
						col: 2,
						class: 'singleCheckboxMargin',
						logicFunction: [{ trigger: 'showGovBodyPublcInspOther' }]
					}
				]
			},
			{
				groupId: 8,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Please describe other method(s)', name: 'govBodyPublcInspOtherLabel', type: 'label', col: 12 },
					{ label: 'Please enter your comments', name: 'govBodyPublcInspOther', col: 12, type: 'textarea', maxLength: 9000, class: 'fieldTextArea' }
				]
			},
			{
				groupId: 9,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Not Available', name: 'govBodyFormsInspMthdNALabel', type: 'label', col: 10, class: 'labelSingleCheckbox' },
					{
						label: 'Yes',
						type: 'checkbox',
						name: 'govBodyFormsInspMthdNA',
						isFieldCheckbox: true,
						col: 2,
						class: 'singleCheckboxMargin',
						logicFunction: [{ trigger: 'showGovBodyPublcInspNotExp' }]
					}

				]
			},
			{
				groupId: 10,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
						label: ' Please explain why the applicable documents are not made available to the public:',
						name: 'govBodyPublcInspNotExpLabel', type: 'label', col: 12, class: 'labelTitleTextArea'
					},
					{
						label: 'Please enter your comments',
						name: 'govBodyPublcInspNotExp',
						type: 'textarea',
						col: 12,
						class: 'fieldTextArea',
						maxLength: 9000,
						...pullFieldValue(['XFDS995C.5'], true)
					}
				]
			},
			{
				groupId: 11,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Check the box for each item the organization has available to the public this tax year:', name: 'govBodyPublcInspProcTypeLabel', type: 'label', col: 9 },
					{
						label: '', name: 'govBodyPublcInspProcType', type: 'multiSelectDropdown', showCheckbox: true, showArrow: true, singleSelect: false, lookup: 'DOCUMENT_TYPE', col: 12,
						displayValue: 'name', isObject: true, disablePreSelectedValues: false, logicFunction: [{ trigger: 'showGovBodyPublcInspProcDesc' }]
					},// TODO: convert to multiselect component
				]
			},
			{
				groupId: 12,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Describe how they are made available to the public.', name: 'govBodyPublcInspProcDescLabel', type: 'label', col: 12, class: 'labelTitleTextArea' },
					{
						label: 'Please explain',
						name: 'govBodyPublcInspProcDesc',
						type: 'textarea',
						maxLength: 9000,
						col: 12,
						class: 'fieldTextArea',
						...pullFieldValue(['XFDS995C.4'], true)
					}
				]
			},
			{
				groupId: 13,
				isVisible: true,
				fields: [
					{ label: 'Provide the following information regarding the possession of the organizations books and records:', name: 'descSix', type: 'label', class: 'labelTitleTextArea', col: 12 },
					{ label: 'Person\'s Name', name: 'govBodyRecHoldName', maxLength: 76, placeholder: 'name', type: 'freeText', col: 3 },
					{ label: 'Address', name: 'govBodyRecHoldAddr', maxLength: 76, type: 'freeText', col: 6 },
					{ label: 'Country', name: 'govBodyRecHoldCntry', type: 'select', lookup: 'FOREIGN_ASSETS_DISPOSITION_COUNTRIES', col: 3, logicFunction: [{ trigger: 'showDisclosureStateProv' }] },
					{ label: 'City', name: 'govBodyRecHoldCity', maxLength: 25, type: 'freeText', col: 3 },
					{ label: 'State', name: 'govBodyRecHoldState', type: 'select', lookup: 'FOREIGN_ASSETS_STATES_CODES', col: 3, isVisible: false },
					{ label: 'Province/State', name: 'govBodyRecHoldProv',maxLength: 17, type: 'freeText', col: 3, isVisible: false },
					{ label: 'Province', name: 'govBodyRecHoldProvState', type: 'select', lookup: 'BASIC_DATA_PROVINCES',col: 3, isVisible: false},
					{ label: 'ZIP or Postal Code', name: 'govBodyRecHoldZip', type: 'zippostal', col: 3 },
					{ label: 'Telephone Number', name: 'govBodyRecHoldPhone', type: 'telephone', col: 3 },
				]
			},
		]
	},
	{  
    title: 'Additional Information',
    collapseClass: 'collapsibleForm',
    isVisible: true,
    isDefaultExpanded: false,
		isOptionalSectionMerge: true,
    sectionId: 6,
    groups: [
      {
        groupId: 2,
        groupType: 'details',
        fields: [
          { 
            label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
            type: 'label', 
            class: 'labelLeftLight' 
          },
        ]
      },
      {
        groupId: 1,
        fields: [
          {
            label: 'Start typing to provide additional information', 
            name: 'additionalInfoTextArea', 
            type: 'textarea', 
            col: 12,
			maxLength: 9000
          }
        ]
      }
    ]
  },
];

export const getGovernanceManagementTitles = () => {
	let titles = [];
	sections.forEach((section) => {
		if (!titles.includes(section.title)) {
			//if (!titles.includes(section.title) && section.isVisible) {
			titles.push(section.title);
		}
	});
	return titles;
};

function GovernanceManagement(props) {

	const { updateCard, REDUX } = usePageFramework(NAV.GOVERNANCE_AND_MANAGEMENT);
	const [sectionState, setSectionState] = useState('');
    const { isExportForm } = props;

	const {isActiveClient, isLoading} = useLoadOrganizer();

	useEffect(() => {
		if (!isExportForm) updateCard();

		setSectionState(sections);
		// eslint-disable-next-line	
	}, []);

	const axcessIdentifiers = [
		{ section: 'Return Type', id: 'Form 990 - Exempt Organization Return' },
		{ section: 'General', id: 'Basic Data' }
	];

	return (
		<>
			{!isLoading && isActiveClient && <FormRendererHelper
				sections={sectionState}
				key={REDUX.GOVERNANCE_AND_MANAGEMENT}
				formName={REDUX.GOVERNANCE_AND_MANAGEMENT}
				identifiers={axcessIdentifiers}
				triggeredEvent={triggeredEvent}
				sectionFieldLogic={sectionFieldLogic}
				isExportForm={isExportForm}
			/>}
			{!isLoading && !isActiveClient && <NoActiveClient />}
		</>
	);
}

export default GovernanceManagement;
