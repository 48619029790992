import _ from 'lodash';
import moneyFormatter, { noDollarFormatter } from '../moneyFormatter';
import recalculateTotalFooter from './recalculateTotalFooter';
import {
  findMergeData,
  getPullFieldValues,
  getPYCalculationFunction,
} from '@utilities/axcessTaxPull';
import { splitGroupLines } from '@utilities/populatePriorData/axcessGroupSplitter';
import {
  filterGroupLines,
  matchesCondition,
} from '@utilities/populatePriorData/axcessGroupFilter';
import { setFieldValue } from '@utilities/populatePriorData/populateFields';
import { preparePrePopulates } from '@utilities/populatePriorData/preparePrePopulates';
import * as AXCESS_KEYS from '@utilities/constants/axcessKeys';
import { ObjectId } from 'bson';

const includesFieldIdentifier = (lineItemKeys) => (fieldIdentifier) =>
  lineItemKeys?.includes(fieldIdentifier) || false;
const includesfieldPrefix = (lineItemKeys) => (fieldPrefix) =>
  lineItemKeys?.some((key) => key?.startsWith(fieldPrefix)) || false;

const calculateTableGroup = (
  group,
  sectionName,
  lineItemData,
  dataList,
  lineItemTotals,
  priorYearData,
  buildFromSheetSectionFn,
  lineItemIndex,
) => {
  const {
    axcessGroup,
    fields,
    lineItems,
    subWorkSheets,
    lineSections,
    entities,
    entityIdentifiers,
  } = group;


  const hasSubWorkSheetEntity = subWorkSheets && lineSections && entities;
  const dataListClone = _.cloneDeep(dataList);

  dataListClone.forEach((fieldData) => {
    if (group.prePopulate && !group.isNew) {
      preparePrePopulates(group, fieldData);
    }
  });

  if (!axcessGroup?.pull) return;

  const {
    fromSections,
    fieldIdentifiers,
    fieldPrefixes,
    mergeWithOtherData,
    mergeWithContextData,
    lineSplitterType,
    lineSplitters,
    lineFilterType,
    lineFilters,
  } = axcessGroup.pull;

  const lineItemKeys = lineItemData.map((x) => x.key);

  const matchToFieldIdentifiers = fieldIdentifiers?.some(
    includesFieldIdentifier(lineItemKeys)
  );
  const matchToFieldPrefixes = fieldPrefixes?.some(
    includesfieldPrefix(lineItemKeys)
  );

  if (!fromSections?.includes(sectionName)) return;
  if (!matchToFieldIdentifiers && !matchToFieldPrefixes) return;

  const pushToAll = (keyValues) => {
    if (_.isArray(dataListClone) && dataListClone.every((x) => _.isArray(x))) {
      dataListClone.forEach((x) => x.push(...keyValues));
    } else if (
      _.isArray(dataListClone) &&
      dataListClone.every((x) => !_.isArray(x))
    ) {
      dataListClone.push(...keyValues);
    }
  };

  const pushToRow = (mergeRow, mergingData) => {
    
    if (_.isArray(dataListClone) && dataListClone.every((x) => _.isArray(x))) {
      dataListClone.forEach((x) => {
        if (mergeRow === lineItemIndex) x.push(mergingData)
      });
  
    } else if (
      _.isArray(dataListClone) &&
      dataListClone.every((x) => !_.isArray(x))
    ) {
      dataListClone.push(mergingData);
    }
  };

  // Exceptions to find and throw extra data into the line/row
  if (_.isArray(mergeWithOtherData) && mergeWithOtherData.length) {

    const foundMergingData = findMergeData(priorYearData, mergeWithOtherData);
    foundMergingData.forEach(x => {
      if (x?.mergeType === AXCESS_KEYS.MERGE_WITH_OTHER_DATA) pushToAll(x.mergingData);
      if (x?.mergeType === AXCESS_KEYS.MERGE_WITH_OTHER_ROW) x.mergingData.forEach((data, index) => {
         pushToRow(index, data);
      })
    })
    
  }

  if (_.isArray(mergeWithContextData) && mergeWithContextData.length) {
    pushToAll(mergeWithContextData);
  }
  // Apply splitters
  const splitteredList =
    lineSplitters && _.isArray(lineSplitters)
      ? splitGroupLines(
          dataListClone,
          lineSplitterType,
          lineSplitters,
          priorYearData
        )
      : dataListClone;

  // Apply filters
  const filteredList =
    lineFilterType && _.isArray(lineFilters)
      ? filterGroupLines(splitteredList, lineFilterType, lineFilters)
      : splitteredList;

  filteredList.forEach((dataRow, rowIndex) => {
    const clonedRow = _.cloneDeep(fields);

    clonedRow.forEach((clonedCell) => {
      const { name, pullCalculation, prior, isTotal, isMoney } = clonedCell;

      if (!prior) return;

       // Splitting data at the column level
      const splitteredList2 =
        pullCalculation?.lineSplitters &&
        _.isArray(pullCalculation?.lineSplitters)
          ? splitGroupLines(
              [dataRow],
              pullCalculation?.lineSplitterType,
              pullCalculation?.lineSplitters,
              priorYearData
            )
          : [dataRow];

      // Filtering data at the column level
      const filteredList2 =
        pullCalculation?.lineFilterType &&
        _.isArray(pullCalculation?.lineFilters)
          ? filterGroupLines(
              splitteredList2,
              pullCalculation?.lineFilterType,
              pullCalculation?.lineFilters
            )
          : splitteredList2;

      // Grab values
      const keyIdentifiers = pullCalculation?.keyIdentifiers || [name];
      const values = pullCalculation?.pullLines
        ? filteredList2.map((x) => keyIdentifiers.map(getPullFieldValues(x)))
        : keyIdentifiers.map(getPullFieldValues(dataRow));

      const fn = getPYCalculationFunction(pullCalculation?.fn);
      const calculatedValue = fn(values);
      let defaultValue = null;

      // Calculate default value on match condition
      if (
        _.isNil(calculatedValue) &&
        pullCalculation?.defaultOnMatch &&
        _.isArray(pullCalculation?.defaultOnMatch)
      ) {
        const matchedPullCalc = pullCalculation?.defaultOnMatch.find(
          (conditionObject) => {
            const { lineFilterType, lineFilters } = conditionObject;
            const hasMatched = matchesCondition(
              dataRow,
              lineFilterType,
              lineFilters
            );
            return hasMatched;
          }
        );

        defaultValue = matchedPullCalc?.defaultValue || null;
      }

      const calculatedColumnValue = !_.isNil(calculatedValue)
        ? calculatedValue
        : defaultValue || pullCalculation?.defaultIfEmpty || null;

      if (!_.isNil(calculatedColumnValue)) {
        setFieldValue(clonedCell, calculatedColumnValue);
      }

      if (isTotal) {
        const foundLineTotalItem = !lineItemTotals.length
          ? null
          : lineItemTotals?.find((x) => x.totalName === name);
        const columnTotal = _.toSafeInteger(clonedCell.default);

        if (!foundLineTotalItem) {
          const lineTotalItem = { totals: columnTotal, totalName: name };
          lineItemTotals.push(lineTotalItem);
        } else {
          foundLineTotalItem.totals += columnTotal;
        }

        const numericValue =
          isMoney === false
            ? noDollarFormatter(columnTotal)
            : moneyFormatter(columnTotal);
        setFieldValue(clonedCell, numericValue);
      }
    });

    lineItems.push(clonedRow);

    if (hasSubWorkSheetEntity) {
      const clonedLineSections = _.cloneDeep(lineSections);

      clonedLineSections.forEach((lineSection, sectionIndex, self) => {
        const sectionEntityIdentifier = entityIdentifiers[sectionIndex];
        const { axcessDataType } = sectionEntityIdentifier;

        let passedPriorData = null;
        if (axcessDataType === AXCESS_KEYS.PASS_DATA_TO_CHILD) {
          passedPriorData = { fields: dataRow, name: sectionName };
        } else {
          const pySubData = subWorkSheets?.[sectionIndex]?.[0] || [];
          passedPriorData = pySubData?.[lineItems.length - 1];
        }

        if (passedPriorData) {
          buildFromSheetSectionFn([passedPriorData], [lineSection], self);
        }
      });

      entities.push({
        id: new ObjectId().toString(),
        sections: clonedLineSections,
      });
    }
  });

  if (lineItemTotals.length) {
    recalculateTotalFooter(lineItemTotals, group);
  }
};

export default calculateTableGroup;

export {
  // For testing purpose
  includesFieldIdentifier,
  includesfieldPrefix,

};
