import { useState, useEffect } from 'react';
import {
    Grid,
    GridCell,
    GridList,
    GridListCell,
    Divider,
    Button,
    TextIconSpacing
} from 'react-md';
import {
    CircularProgress
} from "@react-md/progress";
import { ExpandMoreSVGIcon, ExpandLessSVGIcon } from '@react-md/material-icons';
import _ from 'lodash';
import './dashboardGrid.css';
import DashboardCard from '@components/dashboard/dashboardCard';
import usePageFramework from '@utilities/hooks/usePageFramework';
import EntryExperienceModal from '@components/entryExperience/entryModal';

function DashboardGrid(props) {
    const { categories } = props;
    const { REDUX, CARDSTATUS, selectState, dispatch, ACTION, history, NAV, organizerId } = usePageFramework();
    const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
    const isAdmin = selectState(REDUX.IS_ADMIN);
    // rePaint use for rePaint dashboard and update card status.
    const [rePaint, setRePaint] = useState(false)

    const dashboardCategories = () => {
        const catCopy = _.cloneDeep(categories);
        const clientCards = catCopy.map((category) => {
            const filteredCards = category.cards.filter(card => !card.shouldRenderForPractioner)
            let payload = {
                title: category.title,
                entryType: category.entryType,
                cards: filteredCards,
            };
            return payload;
        });
        return !isPractitioner && !isAdmin ? clientCards : catCopy;
    };

    const filterStatusId = selectState(REDUX.DASHBOARD_FILTER_STATUS) || CARDSTATUS.REQUIRED;
    const sortReq = selectState(REDUX.DASHBOARD_SORT_REQ);
    const loadDashboard = selectState(REDUX.LOAD_DASHBOARD);
    const [isViewAll, setIsViewAll] = useState([
        { id: 0, show: false },
        { id: 1, show: false },
        { id: 2, show: false },
        { id: 3, show: false }
    ]);
    const [categoryIndex, setCategoryIndex] = useState();
    const [filteredCategories, setFilteredCategories] = useState(dashboardCategories());
    const [categoryEntryType, setCategoryEntryType] = useState('');
    const [isEqualLength, setIsEqualLength] = useState([
        { id: 0, isEqual: false },
        { id: 1, isEqual: false },
        { id: 2, isEqual: false },
        { id: 3, isEqual: false },
    ]);
    const cardsPerRow = 5; //added const for cards per row  
    const sortByTitleAToZ = (cardA, cardB) => cardA.title.toLowerCase().localeCompare(cardB.title.toLowerCase());
    const sortByTitleZToA = (cardA, cardB) => cardB.title.toLowerCase().localeCompare(cardA.title.toLowerCase());
    const sortByStatusIdIncreasing = (cardA, cardB) => cardA.statusId - cardB.statusId;
    const sortByStatusIdDecreasing = (cardA, cardB) => cardB.statusId - cardA.statusId;
    const sortByTraditional = (cardA, cardB) => cardA.key.localeCompare(cardB.key); 
    const [isGoToQuestionnaire, setIsGoToQuestionnaire] = useState(false);

    //temp solution for useStory-44456. Paul will revisit/refactor the code when time permits 
    const reloadDashboard = () => {
        if (loadDashboard) return;
        setTimeout(() => {
            dispatch(ACTION.setDashboardLoading(true));
        }, 1000);
    }
    let filteredBy = '';
    switch (filterStatusId) {
        case CARDSTATUS.NOT_STARTED:
            filteredBy = 'Not Started';
            break;
        case CARDSTATUS.IN_PROGRESS:
            filteredBy = 'In Progress';
            break;
        case CARDSTATUS.ERRORS:
            filteredBy = 'Errors Only';
            break;
        case CARDSTATUS.COMPLETED:
            filteredBy = 'Completed';
            break;
        case CARDSTATUS.DOES_NOT_APPLY:
            filteredBy = 'Does Not Apply';
            break;
        case CARDSTATUS.REQUIRED:
            filteredBy = 'Required';
            break;
        default:
            filteredBy = 'Required';
            break;
    }

    let sortBy = sortByTraditional;
    switch (sortReq) {
        case 'alphaIncreasing':
            sortBy = sortByTitleAToZ;
            break;
        case 'alphaDecreasing':
            sortBy = sortByTitleZToA;
            break;
        case 'progressIncreasing':
            sortBy = sortByStatusIdIncreasing;
            break;
        case 'progressDecreasing':
            sortBy = sortByStatusIdDecreasing;
            break;
        case 'traditional':
            sortBy = sortByTraditional;
            break;
        default:
            sortBy = sortByTraditional;
            break;
    }

    useEffect(() => {
    // when Entry Experience Card is Not Started && !Internal User, pop modal
      if(categories[0]?.cards[0]?.statusId === 1 && !isAdmin && !isPractitioner){
        history.push(`/${organizerId}${NAV.ENTRY_INTERVIEW}`)
      }
      // eslint-disable-next-line
    }, [])
    
    useEffect(() => {
        if (categoryEntryType) {
            if (isViewAll[categoryIndex].show) {
                setCategoryEntryType(prevState => {
                    if ((isViewAll.show && prevState === categoryEntryType) || (prevState === categoryEntryType)) {
                        setFilteredCategories(prevState => {
                            const category = prevState.find(data => data.title === categoryEntryType);
                            const index = prevState.indexOf(category);
                            prevState[index].cards = dashboardCategories()
                                .find(data => data.title === categoryEntryType).cards;
                            return [...prevState];
                        });
                    }
                });
            } else if ((!isViewAll[categoryIndex].show && !filterStatusId) || (filterStatusId < 0)) {
                setFilteredCategories(prevState => {
                    const category = prevState.find(data => data.title === categoryEntryType);
                    const index = prevState.indexOf(category);
                    prevState[index].cards = dashboardCategories()
                        .find(data => data.title === categoryEntryType).cards
                        .slice(0, cardsPerRow);
                    return [...prevState];
                });
            } else if (!isViewAll[categoryIndex].show && filterStatusId) {
                setFilteredCategories(prevState => {
                    const category = prevState.find(data => data.title === categoryEntryType);
                    const index = prevState.indexOf(category);
                    // Filter by statusId if filterStatusId > 0
                    prevState[index].cards = dashboardCategories()
                        .find(data => data.title === categoryEntryType).cards
                        .filter(card => card.statusId === filterStatusId)
                        .slice(0, cardsPerRow);
                    return [...prevState];
                });
            }
        }
    // eslint-disable-next-line
    },[categoryEntryType, isViewAll, setFilteredCategories, setCategoryEntryType, setIsViewAll]);
    
    useEffect(() => {
        if (filterStatusId && filterStatusId !== -1) {
            const initialCategories = dashboardCategories().map((category, index) => {
                const filteredCards = filterStatusId === CARDSTATUS.REQUIRED ?  category.cards.filter(card => card.isRequired) 
                    : category.cards.filter(card => card.statusId === filterStatusId);
                let payload = {
                    title: category.title,
                    entryType: category.title,
                    cards: filteredCards,
                    defaultCardLength: filteredCards.length
                };

            
                isEqual(payload, index);

                return payload;
            });
            setFilteredCategories(initialCategories);   
        } else {
            const initialCategories = dashboardCategories().map((category, index) => {
                const payload = {
                    title: category.title,
                    entryType: category.title,
                    cards: category.cards, // change for showing all the cards as default
                    defaultCardLength: category.cards.length
                };

                isEqual(payload, index);
                //need to add this as per change for showing all cards as deafult view
                setIsViewAll(prevState => {
                    const isView = prevState.find(item => item.id === index);
                    const isViewIndex = prevState.indexOf(isView);
                    prevState[index].show = !prevState[isViewIndex].show;
                    return [...prevState];
                });

                return payload;
            });
            setFilteredCategories(initialCategories);
        }
        reloadDashboard();
        // eslint-disable-next-line
    }, [filterStatusId, rePaint, loadDashboard]);

    const isEqual = (payload, categoryIndex) => {
        setIsEqualLength(prevState => {
            const data = prevState.find(item => item.id === categoryIndex);
            const index = prevState.indexOf(data);
            // changing the condition to check if the length is equal to the cards per row const
            prevState[index].isEqual = payload.defaultCardLength === cardsPerRow;
            return [...prevState];
        });
    };
    
    const onViewAllClick = (title, categoryIndex) => {
        setCategoryEntryType(title);
        setCategoryIndex(categoryIndex);
        // toggle view all forms
        setIsViewAll(prevState => {
            const isView = prevState.find(item => item.id === categoryIndex);
            const index = prevState.indexOf(isView);
            prevState[index].show = !prevState[index].show;
            return [...prevState];
        });
    };

    const filteredCard = (category) => {
        let filteredCards = filterStatusId === CARDSTATUS.REQUIRED ? category.cards.filter(card => card.isRequired)
            : category.cards.filter(card => card.statusId === filterStatusId);
        
        if (sortReq) {
            filteredCards.sort(sortBy).filter(card => card.statusId === filterStatusId);
        }
        
        return (
            filteredCards.length === 0 ? <GridListCell className="noShow" colSpan={1}>
                No {filteredBy} Cards to Show
            </GridListCell> :
                filteredCards.map((card, index) => (
                    <GridListCell key={`category-card-${index}`} className="cardGridCell" colSpan={1}>
                        <DashboardCard
                            card={card}
                            setIsGoToQuestionnaire={setIsGoToQuestionnaire}
                        />
                    </GridListCell>
                ))
        );
    };
    
    const allCards = (category) => {
        let allCards = category.cards;
        
        if (sortReq) {
            allCards.sort(sortBy);
        }
        
        return (
            allCards.map((card, index) => (       
                <GridListCell 
                    key={`category-card-${index}`} 
                    className="cardGridCell" 
                    colSpan={1}
                >
                    <DashboardCard
                        card={card}
                        setIsGoToQuestionnaire={setIsGoToQuestionnaire}
                    />
                </GridListCell>
            ))
        );
    };

    const renderViewAllBtn = (category, index) => {
        return (!filterStatusId || filterStatusId === -1) && (!isEqualLength[index].isEqual) && (
            <Button
                id="combined-button-2"
                className="viewAllCards"
                onClick={() => onViewAllClick(category.title, index)}
            >
                <TextIconSpacing
                    icon={
                        !isViewAll[index].show ?
                            <ExpandMoreSVGIcon className="expandCardsButton" /> :
                            <ExpandLessSVGIcon className="expandCardsButton" />}
                    iconAfter
                >
                    {!isViewAll[index].show ? 'View All' : 'View Less'}
                </TextIconSpacing>
            </Button>
        );
    };

    const onEntryExperienceModalClose = ()=>{
        history.push(`/${organizerId}${NAV.DASHBOARD}`)
        setRePaint(pre=>!pre)
    } 

    return (
        <>
            {!!loadDashboard === false ? (<CircularProgress id="loading-progress" />) : (
                <>
            <EntryExperienceModal onClose={onEntryExperienceModalClose} isGoToQuestionnaire={isGoToQuestionnaire} setIsGoToQuestionnaire={setIsGoToQuestionnaire} />
            <Grid className="dashboardHeader">
                {filteredCategories.map((category, index) => (
                    <GridCell key={`category-${index}`} colSpan={12} className="categoryCell">
                        <GridCell key={`categoryTitle-${index}`} colSpan={12} className="dashboardCategoriesHeader">
                            <div className="dashboardCardHeaderTitle">
                                <h1 className="dashHeaderTitle">
                                    {category.title} <span>{category.defaultCardLength} {category.defaultCardLength === 1 ? 'Form' : 'Forms'} Total</span>
                                </h1>
                            </div>
                            {category.cards.length >= cardsPerRow &&
                                <div className="viewAllBtn">
                                    {renderViewAllBtn(category, index)}
                                </div>}
                        </GridCell>
                        <GridCell colSpan={12} id="dashboardCategoryDivider">
                            <Divider />
                        </GridCell>
                        <GridList className="dashboardGridListCategory" maxCellSize={300} cellMargin="10px">
                            {!filterStatusId || filterStatusId === -1 ? allCards(category) : filteredCard(category)}
                        </GridList>
                    </GridCell>
                ))}
            </Grid>
            </>
            )}
        </>
    );
}

export default DashboardGrid;