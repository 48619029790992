/* eslint-disable no-useless-escape */
import { isNaN } from "lodash";

function moneyFormatter(money, currencySymbol) {
    const currencySign = currencySymbol || '$';
    return currencySign + money.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function noDollarFormatter(amount) {
    if(isNaN(amount)) return 0;
    return amount?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function parseMoneyToNumber(moneyString) {
    return moneyString ? parseInt(moneyString.replace(/[\$ ,]/g, '')) : 0;
}

export default moneyFormatter;

export {
    parseMoneyToNumber,
    noDollarFormatter
};