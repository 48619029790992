
const LineItemHeader = ({ item, width, index, group }) => {
    if (width < 1025 && item.colTablet === 0) return <></>;
            
    const headerCol = item.col;
    const headerLabel = item?.label ?? ''; 
    const isFirstDenseRowHeader = index === 0 && (group.hasTableManagerTabs || group.entityPlaceHolder);
    // const headerStyles = isFirstDenseRowHeader ? {paddingLeft: 30} : item.styles;
    const headerStyles = isFirstDenseRowHeader ? {paddingLeft: '15px'} :  null;

    return (
        headerCol !== 0 &&
        <div 
            key={`lbl-${index}-d`} 
            colSpan={headerCol}
            className={
                item.align==='right' ? 'headerLabelsRight' : 
                    item.align==='left' ? 'headerLabelsLeft' : 
                        item.align==='noPaddingLeft' ? 'headerLabelsButtonLeft' :
                            'headerLabelsLeft'
            }
            style={headerStyles}
        >
            {headerLabel}
        </div>
    );
};

export default LineItemHeader;