//Globals.  This is Global state related to the person who is using the app. Form data should NOT be G_
export const IS_AUTHENTICATED = 'G_isAuthenticated';
export const CURRENT_USER = 'G_currentUser';
export const IS_CLIENT = 'G_isClient';
export const IS_PRACTITIONER = 'G_isPractitioner';
export const IS_ADMIN = 'G_isAdmin';
export const AUTH_USER = 'G_authUser';
export const SCREEN_SIZE = 'G_screenSize';
export const TOOLBAR_TITLE = 'G_toolbarTitle';
export const SHOW_BULK_UPLOAD_CONTROL = 'G_showBulkUpload';
export const CUSTOM_DIALOG_TITLE = 'G_customDialogTitle';
export const CUSTOM_DIALOG_MSG = 'G_customDialogMsg';
export const HIDE_CUSTOM_DIALOG_CLOSE_BUTTON = 'G_hideCustomDialogCloseButton';
export const SHOW_CUSTOM_DIALOG = 'G_showCustomDialog';
export const DASHBOARD_SORT_REQ = 'G_dashboardSortRequirements';
export const DASHBOARD = 'G_dashboard';
export const PROGRESS_VISIBLE = 'G_isProgressVisible';
export const UPLOAD_PROGRESS_VISIBLE = 'G_isUploadProgressVisible';
export const PROGRESS_TEXT = 'G_progressText';
export const YEAR = 'G_year';

// Data Specific details.  Can change by return being viewed. These are reset when a new return is selected
export const ACTIVE_RETURN = 'activeReturn';
export const LOCK_FORMS = 'lockForms';
export const CURRENT_CARD_KEY = 'currentKeyCard';
export const CURRENT_CARD_STATUS = 'currentCardStatus';
export const PRIOR_YEAR_DATA = 'PriorYearData';
export const CURRENT_CARD = 'currentCard';
export const DASHBOARD_FILTER_STATUS = 'dashboardFilterStatus';
export const ORGANIZER_ID = 'organizerId';
export const FILE_CATEGORY_COUNT = 'fileCategoryCount';

export const BOARD_OF_DIRECTORS_AND_COMPENSATION = 'boardOfDirectorsAndCompensation';
export const ENTRY_INTERVIEW = 'entryInterview';
export const BASIC_DATA = 'basicData';
export const PROGRAMS = 'programs';
export const INTERNAL_SETUP = 'internalSetup';
export const TAX_COMPLIANCE_FILINGS = 'taxComplianceFilings';
export const GOVERNANCE_AND_MANAGEMENT = 'governanceAndManagement';
export const FINANCIALS = 'financials';
export const CONTRIBUTIONS = 'contributions';
export const FUNDRAISING_AND_GAMING = 'fundraisingAndGaming';
export const FOREIGN_ACTIVITIES = 'foreignActivities';
export const POLITICAL_CAMPAIGN_AND_LOBBYING = 'politicalCampaignAndLobbying';
export const DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE = 'domesticGrantsAndOtherAssistance';
export const LIQUIDATION_AND_DISPOSITION_OF_ASSETS = 'liquidationAndDispositionOfAssets';
export const PUBLIC_CHARITY_AND_SUPPORT = 'publicCharityAndSupport';
export const INTERESTED_PERSONS_TRANSACTIONS = 'interestedPersonsTransactions';
export const RELATED_ORGANIZATIONS_SCHED_R = 'relatedOrganizationsSched.R';
export const SUPPLEMENTAL_FINANCIAL_STATEMENTS = 'supplementalFinancialStatements';
export const TAX_EXEMPT_BONDS = 'taxExemptBonds';
export const HOSPITAL = 'hospital';
export const SCHOOLS = 'schools';
export const UNRELATED_BUSINESS_INCOME_TAX = 'unrelatedBusinessIncomeTax';
export const DASHBOARD_ID = 'dashboardId';

export const IS_SAVE_SUCCESS = 'saveSuccess';
export const UPLOAD_LIST = 'uploadList';
export const LOAD_DASHBOARD = 'loadDashboard';

// Print View
export const TAX_YEAR_END_DATE = 'taxYearEndDate';

//upload warning dialog
export const UPLOAD_WARNING_VISIBLE = 'uploadWarningVisible';
export const DUPLICATE_UPLOAD_FILES = 'duplicateUploadFiles';
export const UPLOADS_PROPS = 'uploadsProps';