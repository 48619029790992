import { useEffect, useRef } from "react";

const usePolling = (props = {}) => {
  const { interval = 5, pollErrorFn } = props;

  useEffect(() => {
    return () => {
      stopPolling();
    };
    // eslint-disable-next-line
  }, []);

  const isPolling = useRef(false);
  const pollFn = useRef(null);
  const pollTimer = useRef(null);

  const startPolling = (fn) => {
    stopPolling();
    pollFn.current = fn;
    isPolling.current = true;
    startPoll();
  };

  const startPoll = () => {
    const timer = window.setTimeout(callResource, interval * 1000);
    pollTimer.current = timer;
  };

  const stopPolling = () => {
    isPolling.current = false;
    interruptPoll();
  };

  const interruptPoll = () => {
    if (pollTimer.current) {
      window.clearTimeout(pollTimer.current);
      pollTimer.current = null;
    }
  }

  const callResource = async () => {
    return pollFn?.current?.().then((continuePolling) => {
      if (!continuePolling) {
        stopPolling();
      } else {
        startPoll();
      }
    }).catch((err) => {
      console.log(err);
      stopPolling();
      pollErrorFn(err);
    });
  };

  return {
    isPolling: isPolling.current,
    startPolling,
    stopPolling,
  };
};

export default usePolling;