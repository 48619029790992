// common modules
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { DropdownMenu, MenuItem, useToggle, Button } from 'react-md';
import {
	Dialog,
	DialogHeader,
	DialogTitle,
	DialogContent,
	DialogFooter,
} from "@react-md/dialog";

// MUI imports
import { Box, Tooltip } from '@mui/material';
import { TOP_LEFT_ANCHOR } from '@react-md/utils';
import { MoreVertSVGIcon } from '@react-md/material-icons';
import { DataGridPro, gridRowCountSelector, gridPageSizeSelector, gridPageSelector, useGridApiContext, useGridSelector, gridClasses, getGridStringOperators } from '@mui/x-data-grid-pro';

//MUI pagination
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

// custom modules
import '@components/OrganizerList.scss';
import usePageFramework from '@utilities/hooks/usePageFramework';
import InPrepCheckBox from '@components/InPrepCheckBox.js';
import api from '@utilities/claApi';
import LoadYearEnd from '@components/exchangeManager/LoadYearEnd'
import ExpandedOrganizer from '@components/organizerList/ExpandedOrganizer.js';
import * as STRING from '@utilities/constants/strings';
import RelatedPeopleDialog from '@components/organizerList/relatedPeopleDialog.js';
import CustomToolbar from '@components/organizerList/customToolbar';
import CustomNoResultsOverlay from '@components/organizerList/customNoResultsOverlay';
import * as PRACTITIONERDASHBOARD from '@utilities/constants/practitionerDashboard';

function OrganizerList(props) {
	const {
		organizers, totalResults, paginatedLoad,
		currentPageNumber = 0,
		handlePaginationModelChange = () => { },
		handleFilterModelChange = () => { },
		handleSortModelChange = () => { },
		syncParentData = () => { },
		setReloadOrgList
	} = props;
	const { history, dispatch, NAV, ACTION, selectState, REDUX } = usePageFramework();
	const isClient = selectState(REDUX.IS_CLIENT);
	const isAdmin = selectState(REDUX.IS_ADMIN);
	const isPractitioner = selectState(REDUX.IS_PRACTITIONER);

	const [rowData, setRowData] = useState(organizers ?? []);
	const [expandedRows, setExpandedRows] = useState([]);

	useEffect(() => {
		setRowData(organizers);
	}, [organizers]);

	const [visibleEdit, showEdit, hideEdit] = useToggle(false);
	const [visibleDialog, showDialog, hideDialog] = useToggle(false);
	const [visibleDelete, showDelete, hideDelete] = useToggle(false);
	const [selectedOrganizer, setSelectedOrganizer] = useState(null);
	const [enteredClientNumber, setEnteredClientNumber] = useState('');
	const [clientNo, setClientNo] = useState('');
	const [clientStatus, setClientStatus] = useState('')
	const [filterButtonEl, setFilterButtonEl] = React.useState(null);
	const [filterHighlight, setFilterHighlight] = React.useState("filterButton")
	const changeData = (id, newData) => {
		const dataDeepCopy = _.cloneDeep(rowData);

		dataDeepCopy.forEach((item) => {
			if (item.id === id) {
				newData.forEach((newChangeData) => {
					item[newChangeData.column] = newChangeData.value;
				});
			}
		});

		setRowData(dataDeepCopy);
		syncParentData(dataDeepCopy);
	};

	const updateSameInCharge = (inChargeData, orgId) => {
		const dataDeepCopy = _.cloneDeep(rowData);

		dataDeepCopy.forEach((organizer) => {
			// update org in charge on all orgs where email matches
			if (organizer.inCharge?.email === inChargeData.email || organizer.id === orgId) {
				organizer.inCharge = inChargeData;
			}
		});

		setRowData(dataDeepCopy);
		syncParentData(dataDeepCopy);
	};

	const onSendClick = (data) => {
		dispatch(ACTION.setProgressText('Return to Client'));
		dispatch(ACTION.setProgressVisible(true));
		api.put(`/organizers/${data?.orgId}`, { status: 'With Client', lastUserActivityOn: new Date() }).then((response) => {
			dispatch(ACTION.setProgressVisible(false));
			changeData(
				data.orgId,
				[
					{ column: 'status', value: 'With Client' },
				]
			);
		}).catch((error) => {
			console.log(error);
			dispatch(ACTION.setProgressVisible(false));
		});
	}

	const preventBubbleEvent = (event) => {
		event.stopPropagation();
	};

	const buildInitials = (employee) => {
		if (!employee) return '';

		const { first, middle, last } = employee;
		const getFirstLetter = (string) => string?.[0]?.toUpperCase()?.trim() || '';

		return `${getFirstLetter(first)}${getFirstLetter(middle)}${getFirstLetter(last)}`;
	};

	const buildNameOnHover = (employee) => {
		if (!employee) return '';

		let name = '';

		if (employee.last) name = employee.last;
		if (employee.first) {
			if (name) name += ', ';

			name += employee.first;
		}
		if (employee.middle && employee.first) name += ` ${employee.middle[0]}`;

		return name
	};

	const getLastEdit = (organizer) => {
		let lastEdit = new Date(organizer.lastUserActivityOn);

		if (lastEdit == "Invalid Date") {
			lastEdit = new Date(organizer.updatedOn);
		}

		return lastEdit.toLocaleDateString(undefined, { month: "2-digit", day: "2-digit", year: "2-digit" });
	}

	const names = [];
	const csl = [];
	const years = [];
	const statuses = [];
	const lastActivity = [];
	const lockCheckbox = [];
	const rows = [];
	const notes = [];
	const loadOrganizerInfo = [];

	if (rowData.length > 0) {
		rowData.forEach((organizer, index) => {
			loadOrganizerInfo.push(
				{
					id: organizer.id,
					organizer: organizer
				}
			);
			names.push(
				{
					id: organizer.id,
					name: organizer?.client?.name
				}
			);
			csl.push(
				{
					id: organizer.id,
					csl: organizer?.client?.csl,
					number: organizer?.client?.number
				}
			);
			years.push(
				{
					id: organizer.id,
					year: organizer.year
				}
			);
			statuses.push(
				{
					id: organizer.id,
					status: organizer.status
				}
			);
			lastActivity.push(
				{
					id: organizer.id,
					lastActivity: getLastEdit(organizer),
				}
			);
			lockCheckbox.push(
				{
					id: organizer.id,
					organizer: organizer,
					index: index,
				}
			);
			notes.push(
				{
					id: organizer.id,
					name: `${organizer?.client?.name}`,
					note: `${organizer.note}`
				}
			);
			rows.push(
				{

					id: organizer.id,
					orgId: `${organizer.id}`,
					notes: organizer.notes ? organizer.notes : [],
					name: `${organizer?.client?.name}`,
					csl: `${organizer?.client?.number}`,
					year: `${organizer.year}`,
					yearEnd: ``,
					crl: `${buildInitials(organizer.crl)}`,
					organizerCrl: organizer.crl,
					signer: `${buildInitials(organizer.signer)}`,
					organizerSigner: organizer.signer,
					inCharge: `${buildInitials(organizer.inCharge)}`,
					organizerInCharge: organizer.inCharge,
					status: `${organizer.status}`,
					cchPush: getLastEdit(organizer),
					lastEdit: getLastEdit(organizer),
					lock: `${organizer.locked}`,
				}
			);
		});
	}

	const filterOperators = getGridStringOperators().filter(({ value }) =>
		PRACTITIONERDASHBOARD.OPERATORS_LIST.includes(value),
	);
	const columns = [
		{
			field: 'name',
			headerName: 'Name',
			cellClassName: 'clientInfoText clientTableCell',
			width: isClient ? 400 : 200,
			pinnable: false,
			filterOperators,
			renderCell: (params) => {

				let name = ""

				names.forEach((orgName) => {
					if (params.id === orgName.id) {
						name = orgName.name
					}
				})

				return (
					<div key="organizer-list-row-cell-name" style={{ overflowWrap: "break-word", whiteSpace: "normal" }}>
						{name}
					</div>
				)

			},
			renderHeader: (params) => {
				return (
					<div data-testid={`pracDash-tableCell-name`} key="name">
						Organization Name
					</div>
				)
			}
		},
		{
			field: 'csl',
			headerName: 'Client Number',
			cellClassName: 'clientInfoText clientTableCell',
			width: isClient ? 200 : 150,
			pinnable: false,
			filterOperators,
			renderCell: (params) => {
				let clientNumber = ""

				csl.forEach((cslClient) => {
					if (params.id === cslClient.id) {
						clientNumber = cslClient.number
					}
				})

				return (
					<div key="organizer-list-row-cell-clientCsl">
						<div>{clientNumber}</div>
					</div>
				)
			},
			renderHeader: (params) => {
				return (
					<div data-testid={`pracDash-tableCell-clientCsl`} key="clientCsl">
						Client Number
					</div>
				)
			}
		},
		{
			field: 'yearEnd',
			headerName: 'Year End',
			cellClassName: 'clientInfoText clientTableCell',
			width: isClient ? 200 : 110,
			filterable: false,
			pinnable: false,
			sortable: false,
			disableColumnMenu: true,
			renderCell: (params) => {
				const yearEndOrganizer = loadOrganizerInfo.find(x => x.id === params.id).organizer;

				return (
					<div>
						<LoadYearEnd organizer={yearEndOrganizer} />
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div data-testid={`pracDash-tableCell-year`} key="year">
						Year End
					</div>
				)
			}
		},
		{
			field: 'crl',
			headerName: 'CRL',
			cellClassName: 'clientInfoText clientTableCell',
			width: 90,
			pinnable: false,
			filterOperators,
			renderCell: (params) => {
				let orgCRL = ""
				rows.forEach((crlName) => {
					if (params.id === crlName.id) {
						orgCRL = buildNameOnHover(crlName.organizerCrl)
					}
				})


				return (
					<div key="organizer-list-row-cell-status">
						<Tooltip title={orgCRL} >
							<span className="table-cell-trucate">{params.value}</span>
						</Tooltip>
					</div>
				)


			},
			renderHeader: (params) => {
				return (
					<div data-testid={`pracDash-tableCell-status`} key="status">
						CRL
					</div>
				)
			}
		},
		{
			field: 'signer',
			headerName: 'Signer',
			cellClassName: 'clientInfoText clientTableCell',
			width: 100,
			pinnable: false,
			filterOperators,
			renderCell: (params) => {
				let signerName = '';

				rows.forEach((row) => {
					if (params.id === row.id) {
						signerName = buildNameOnHover(row.organizerSigner)
					}
				})

				return (
					<div key="organizer-list-row-cell-signer">
						<Tooltip title={signerName} >
							<span className="table-cell-trucate">{params.value}</span>
						</Tooltip>
					</div>
				)
			},
			renderHeader: (params) => {
				return (
					<div data-testid={`pracDash-tableCell-status`} key="status">
						Signer
					</div>
				)
			}
		},
		{
			field: 'inCharge',
			headerName: 'In Charge',
			cellClassName: 'clientInfoText clientTableCell',
			width: 120,
			pinnable: false,
			filterOperators,
			renderCell: (params) => {
				let inChargeName = '';

				rows.forEach((row) => {
					if (params.id === row.id) {
						inChargeName = buildNameOnHover(row.organizerInCharge)
					}
				})

				return (
					<div key="organizer-list-row-cell-incharge">
						<Tooltip title={inChargeName}>
							<span className="table-cell-trucate">{params.value}</span>
						</Tooltip>
					</div>
				)

			},
			renderHeader: (params) => {
				return (
					<div data-testid={`pracDash-tableCell-status`} key="status">
						In Charge
					</div>
				)
			}
		},
		{
			field: 'status',
			headerName: 'Status',
			cellClassName: 'clientInfoText clientTableCell',
			width: isClient ? 200 : 120,
			pinnable: false,
			filterOperators,
			renderCell: (params) => {
				let orgStatus = ""

				statuses.forEach((status) => {
					if (params.id === status.id) {
						orgStatus = status.status
					}
				})

				return (
					<div key="organizer-list-row-cell-status">
						{orgStatus}
					</div>
				)


			},
			renderHeader: (params) => {
				return (
					<div data-testid={`pracDash-tableCell-status`} key="status">
						Status
					</div>
				)
			}
		},
		{
			field: 'cchPush',
			headerName: 'CCH Push',
			cellClassName: 'clientInfoText clientTableCell',
			width: 120,
			filterable: false,
			pinnable: false,
			renderCell: (params) => {
				let cchPushDate = ""
				rows.forEach((row) => {
					if (params.id === row.id && row.locked) {
						cchPushDate = row.lastEdit
					}
				})

				return (
					<div key="organizer-list-row-cell-updatedOn">
						{cchPushDate}
					</div>
				)
			},
			renderHeader: (params) => {
				return (
					<div data-testid={`pracDash-tableCell-updatedOn`} key="updatedOn">
						CCH Push
					</div>
				)
			}
		},
		{
			field: 'lastEdit',
			headerName: 'Last Edit',
			cellClassName: 'clientInfoText clientTableCell',
			width: isClient ? 200 : 100,
			filterable: false,
			pinnable: false,
			renderCell: (params) => {
				let activity = ""

				lastActivity.forEach((activityTime) => {
					if (params.id === activityTime.id) {
						activity = activityTime.lastActivity
					}
				})

				return (
					<div key="organizer-list-row-cell-updatedOn">
						{activity}
					</div>
				)
			},
			renderHeader: (params) => {
				return (
					<div data-testid={`pracDash-tableCell-updatedOn`} key="updatedOn">
						Last Edit
					</div>
				)
			}
		},
		{
			field: 'lock',
			headerName: 'Lock',
			cellClassName: 'clientInfoText clientTableCell lockColumn',
			headerClassName: 'lockHeader',
			width: 135,
			filterable: false,
			pinnable: false,
			disableColumnMenu: true,
			renderCell: (params) => {
				let indexNum = '';
				let organizerData = {};
				lockCheckbox.forEach((lockbox) => {
					if (params.id === lockbox.id) {
						indexNum = lockbox.index;
						organizerData = { ...lockbox.organizer }
					}
				})

				return (
					<div key={`organizer-list-row-cell-locked`} className='clientInfoText clientTableCell' onClick={preventBubbleEvent}>
						<InPrepCheckBox index={indexNum} data={organizerData} changeData={changeData} ></InPrepCheckBox>
					</div>
				)
			},
			renderHeader: (params) => {
				return (
					<div data-testid={`pracDash-tableCell-locked`} key="locked">
						Lock
					</div>
				)
			}
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'clientInfoText clientTableCell menuColumn',
			headerClassName: '',
			width: 130,
			disableClickEventBubbling: true,
			filterable: false,
			pinnable: false,
			renderCell: (params) => {
				let indexNum = '';
				lockCheckbox.forEach((lockbox) => {
					if (params.id === lockbox.id) {
						indexNum = lockbox.index;
					}
				})
				return ( menuItems(params?.row).filter(Boolean).length > 0 && (
					<div className="headerCardMoreBtnContainer">
						<DropdownMenu
							id={`organizer-row-ellipsis-${indexNum}`}
							buttonType="icon"
							aria-label={`organizer-row-ellipsis-${indexNum}`}
							anchor={TOP_LEFT_ANCHOR}
							buttonChildren={<MoreVertSVGIcon className="dropDownMenu moreIconBtn" />}
						>
							{menuItems(params?.row)}
						</DropdownMenu>
						{visibleEdit && selectedOrganizer === params?.row.orgId &&
							<RelatedPeopleDialog
								key={`edit-in-charge-dialog`}
								rowData={params?.row}
								changeData={changeData}
								role={'updateInCharge'}
								visibleDialog={visibleEdit}
								hideDialog={hideEdit}
								organizerId={params?.row.orgId}
								dialogTitle={STRING.UPDATE_IN_CHARGE}
								updateSameInCharge={updateSameInCharge}
							/>
						}
					</div>
				))
			},
		},
	];

	const menuItems = (row) => {
		return [
			(row?.status === "Client Submitted" && (isAdmin || isPractitioner)) &&
			<MenuItem
				key='return-to-client-menu-item'
				className='cardMenuItems'
				onClick={() => onSendClick(row)}
			> {STRING.RETURN_TO_CLIENT}
			</MenuItem>,
			(isAdmin || isPractitioner) &&
			<MenuItem
				key='update-in-charge-menu-item'
				className='cardMenuItems'
				onClick={() => {
					showEdit();
					setSelectedOrganizer(row.orgId);
				}}
			> {STRING.UPDATE_IN_CHARGE}
			</MenuItem>,
			(isAdmin || isPractitioner) &&
			<MenuItem
				key='delete-menu-item'
				className='cardMenuItems'
				onClick={() => {
					showDialog();
					setSelectedOrganizer(row.orgId);
					setClientNo(row.csl);
					setClientStatus(row.status);
				}}
			> {STRING.DELETE}
			</MenuItem >,
			((row?.status === "Client Submitted" || row?.status === "Complete") && (isClient)) &&
			<MenuItem
				key='print-menu-item'
				className='cardMenuItems'
				onClick={() => history.push(`${NAV.ORGANIZER_EXPORT}/${row.orgId}`)}
			> {STRING.PRINT}
			</MenuItem >,
		];
	};

	function CustomPagination() {
		const apiRef = useGridApiContext();
		const page = useGridSelector(apiRef, gridPageSelector);
		const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
		const rowCount = useGridSelector(apiRef, gridRowCountSelector);
		// GROWTH: page count issues with MUI 6: https://github.com/mui/mui-x/issues/8450
		// const pageCount = useGridSelector(apiRef, gridPageCountSelector);
		const pageCount = Math.ceil(rowCount / pageSize)

		return (
			<Box
				sx={{
					display: 'flex', justifyContent: 'right'
				}}>
				<Pagination
					size="small"
					page={page + 1}
					count={pageCount}
					renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
					onChange={(event, value) =>
						apiRef.current.setPage(value - 1)
					}
				/>
			</Box>
		);
	}

	const getRowSpacing = React.useCallback((params) => {
		return {
			top: params.isFirstVisible ? 30 : 6,
			bottom: params.isLastVisible ? 9 : 6,
		};
	}, []);

	const columnVisibilityModel = {
		// Hide these columns when client user is logged in
		crl: !isClient,
		signer: !isClient,
		inCharge: !isClient,
		cchPush: !isClient,
		lock: !isClient
	};
	const handleChange = (e) => {
		setEnteredClientNumber(e.target.value);
	}
	const deleteClient = async () => {
		dispatch(ACTION.setProgressVisible(true));
		dispatch(ACTION.setProgressText('Deleting...'));
		api.put(`/organizers/${selectedOrganizer}`, { status: "Pending Delete", lastUserActivityOn: new Date() }).then((response) => {
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle(''));
			dispatch(ACTION.setCustomDialogMsg('Exchange successfully marked as pending delete'));
			dispatch(ACTION.setShowCustomDialog(true));
			setReloadOrgList(Math.random());
		}).catch((error) => {
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle(''));
			dispatch(ACTION.setCustomDialogMsg('Exchange successfully marked as pending delete'));
			dispatch(ACTION.setShowCustomDialog(true));
		});
	}
	const deleteExchange = () => {
		if (clientStatus === 'New') {
			deleteClient();
		} else {
			hideDialog();
			showDelete();
		};
	}
	const selectedExchange = selectedOrganizer ? loadOrganizerInfo.find(x => x.id === selectedOrganizer)?.organizer : '';
	const yearEnddate = selectedOrganizer ? <LoadYearEnd organizer={selectedExchange} /> : '';

	return <>
		<Box sx={{ height: 'auto', width: '100%' }} data-testid='pracdash-columnheader'>
			<DataGridPro
				loading={paginatedLoad}
				filterMode={'server'}
				onFilterModelChange={(props) => {
					if (props.items?.[0]?.value) {
						setFilterHighlight("filterButtonHighlight")
					} else {
						setFilterHighlight("filterButton")
					};
					handleFilterModelChange(props);
				}}
				getRowSpacing={getRowSpacing}
				onRowClick={(params, event) => {
					//prevent row from being clicked when row menu button is clicked
					const isActionCell = !!event.target.closest('.MuiDataGrid-actionsCell');

					if (!isActionCell) {
						const organizerId = params.id
						history.push(`/${organizerId}${NAV.DASHBOARD}`);
					}
				}}
				rows={rows}
				columns={columns}
				columnVisibilityModel={columnVisibilityModel}
				disableColumnSelector
				paginationModel={{ pageSize: 10, page: currentPageNumber }}
				paginationMode={'server'}
				rowCount={totalResults}
				onPaginationModelChange={(props) => {
					handlePaginationModelChange(props);
					setExpandedRows([]);
				}}
				sortingMode={'server'}
				onSortModelChange={handleSortModelChange}
				pageSizeOptions={[10]}
				localeText={{ toolbarFilters: "" }}
				autoHeight={true}
				disableVirtualization
				disableColumnResize={true}
				rowHeight={98}
				pagination
				detailPanelExpandedRowIds={expandedRows}
				onDetailPanelExpandedRowIdsChange={setExpandedRows}
				getDetailPanelContent={({ row }) => {
					return (isClient ? null :
						<div data-testid='pracdash-columnheader' key={`notes-row-key`}>
							<ExpandedOrganizer key='expanded-notes-details' sectionTitle={'Details'} rowData={row} changeData={changeData} />
							<ExpandedOrganizer key='expanded-related-people' sectionTitle={'Related People'} rowData={row} changeData={changeData} />
						</div>
					)
				}}
				getDetailPanelHeight={({ row }) => 'auto'}
				slots={{
					toolbar: CustomToolbar,
					noRowsOverlay: CustomNoResultsOverlay,
					noResultsOverlay: CustomNoResultsOverlay,
					footer: CustomPagination,
				}}
				slotProps={{
					panel: {
						anchorEl: filterButtonEl,
					},
					toolbar: {
						setFilterButtonEl,
						filterHighlight
					},
				}}
				sx={{
					[`& .${gridClasses.row}`]: {
						bgcolor: '#ffffff',
						borderRadius: 1,
						margin: 4,
						width: '91%',
						// border: 0.25,
						borderColor: '#E5E5E5'
					},
					'& .MuiDataGrid-cell': {
						borderBottom: 'none',
					},
					'& .MuiDataGrid-columnHeadersInner': {
						margin: 4
					},
					'& .MuiDataGrid-detailPanelToggleCell': {
						padding: 3
					},
					'& .MuiDataGrid-detailPanel': {
						marginLeft: 4,
						marginRight: 5,
						width: '96%',
					},
					'& .MuiDataGrid-columnSeparator--sideRight': {
						display: 'none'
					},
				}}
			/>
		</Box>
		{selectedExchange && <Dialog
			id="delete-organizer-dialog"
			role="alertdialog"
			visible={visibleDialog}
			onRequestClose={() => { }}
			aria-labelledby="delete-resource-dialog-title"
			data-testid="delete-resource-dialog-title">
				<DialogHeader>
				<DialogTitle>Delete Exchange Organizer</DialogTitle>
			</DialogHeader>
			<DialogContent><div style={{lineHeight:"1.5"}}>Are you sure you want to delete <b>{selectedExchange?.client?.name} {yearEnddate}</b></div></DialogContent>
			<DialogFooter>
				<div className="deleteModalFooterContainer">
				<Button id="delete-dialog-delete" className= 'deleteButtonActive' onClick={deleteExchange}>Delete </Button>
				<Button id="delete-dialog-cancel" className= 'cancelButtonInActive' onClick={() => hideDialog()}>Cancel</Button>
				</div>
			</DialogFooter>
		</Dialog>}
		{selectedExchange && <Dialog
			id="delete-confirmation-organizer-dialog"
			role="alertdialog"
			visible={visibleDelete}
			onRequestClose={() => { }}
			aria-labelledby="delete-confirmation-organizer-title"
			data-testid="delete-confirmation-organizer-title">
			<DialogContent>
				<label><b>Client Number</b></label> <input onChange={handleChange} type='text'></input>
				<br /><br />
				<b>NOTE:</b> Data might be lost. Please enter the Client Number to proceed 
			</DialogContent>
			<DialogFooter>
			<div  className="deleteModalFooterContainer">
				<Button id="delete-dialog-delete" className= {enteredClientNumber !== clientNo ? 'deleteButtonInActive' : 'deleteButtonActive'} onClick={() =>{deleteClient(); hideDelete();}} disabled={enteredClientNumber !== clientNo}>Delete </Button>
				<Button id="delete-dialog-cancel" className= 'cancelButtonInActive' onClick={() => hideDelete()}>Cancel</Button>
			</div>
			</DialogFooter>
		</Dialog>}
	</>;
}
export default OrganizerList;