import React, { useState, useEffect } from "react";
// import { AppSizeListener } from "react-md";
// import _ from "lodash";
import Collapse from "./collapsibleForm/index";
import BuildFormGroups from "./buildGroups";
import { ErrorBoundary } from "react-error-boundary";
import updateTotals from '../utils/updateTotals';
// loading the logo images
// import { getFormBarcode } from '@utilities/constants/images';
import { getClientDetails } from '../utils/getClientDetails';
import getYear from '@utilities/helpers/getYear';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { getFormTitle } from '../utils/getFormTitle';
// import getFormName from "@utilities/helpers/getFormName";
import { yearSetter } from '../utils/getYear';
import { CLA_BLACK_LOGO } from '@utilities/constants/images.js';

function ExportRenderer(props) {
  const {
    sections,
    formName,
    parentFns,
    // passFromEntity,
    hasLineItemSection,
    entityScrollTab,
    entityIndex,
    setUniqueEntityFieldValue,
    showSharedEntityDialog,
    isFormPresent,
    isFormExpandable,
    // isLineItemSection,
    parentFormName,
    parentGroup,
    parentIndex,
    isScheduleFormSection,
    // usePageFramework,
    renderEntity,
    triggered,
    // formSections,
    setFormSections,
    saveForm,
    isFormNA,
    isFormDisabled,
    sectionFieldLogic,
    setHasLoadingError,
    setIsLoaded,
    mappedSections,
    // lookups,
    grandParentGroup,
    // containerStyles,
    // isEntryInterview,
    bookmarkTitle,
    // setBookmarkTitle,
    // year,
    dashboardCards,
    defaultFormName,
    isExportForm,
    isBondItemSection
  } = props;
  // const { screenSize, card } = usePageFramework(); // replace this imported function with the usePageFramework prop
  // framework = usePageFramework;
  // lookupSetter.lookups = lookups;
  const [denseRowSection, setDenseRowSection] = useState(sections);
  const [footerTotals, setFooterTotals] = useState([]);
  const visibleSections = sections?.length ? sections?.filter(sec => sec?.isVisible) : [];
  const lastDenseRowSection = visibleSections[visibleSections?.length - 1];
  let isDenseRowNA = false;
  const { selectState, REDUX } = usePageFramework();
  const year = getYear(selectState);
  const formTitle = getFormTitle(dashboardCards, formName);
  yearSetter.year = year; // Need to set year in order to get dynamic years into form footer totals
  const taxYearEndDate = selectState(REDUX.TAX_YEAR_END_DATE);

  useEffect(() => {
    setDenseRowSection(sections); // Update if provided sections has changed
  }, [sections, footerTotals]);

  // const triggered = (trigger, event, {triggeredSections, triggeredFormSections, triggeredSetFormSections}) => {
  //   triggeredEvent(trigger, event, 
  //       {   sections: triggeredSections || sections, 
  //           formSections: triggeredFormSections || formSections, 
  //           setFormSections: triggeredSetFormSections || setFormSections, 
  //           // inputState,  
  //       }
  //   );
  // };

  useEffect(() => {
    if (parentGroup && parentGroup.summaryList) {
      updateTotals(parentGroup.summaryList.sections, setFooterTotals);
    }

    if (grandParentGroup && grandParentGroup.summaryList) {
      updateTotals(grandParentGroup.summaryList.sections, setFooterTotals);
    }

    updateTotals(denseRowSection, setFooterTotals);
  }, [parentGroup, grandParentGroup, denseRowSection]);

  const save = (field, nextTabId, childFormSectionData) => {
    saveForm(field, nextTabId, childFormSectionData, denseRowSection, setDenseRowSection);
    if (parentFns) {
      updateTotals(denseRowSection, setFooterTotals);
      parentFns.saveForm(field, nextTabId, { childFormSections: denseRowSection });
    }
  };

  const LineItemSectiontriggered = (trigger, event, triggeredFormSection) => {
    triggered ? triggered(
      trigger,
      event,
      {
        triggeredSections: sections,
        triggeredFormSections: triggeredFormSection || denseRowSection,
        triggeredSetFormSections: triggeredFormSection ? null : setDenseRowSection,
      }
    ) : sectionFieldLogic(trigger,
      {
        event: event,
        triggeredSections: sections,
        triggeredFormSections: triggeredFormSection || denseRowSection,
        triggeredSetFormSections: triggeredFormSection ? null : setDenseRowSection,
      });
  };

  const showSummaryViewHeaders = (summaryGroup, section) => {
    const visibleGroupItems = summaryGroup
      .map(group => group.groupItems?.map(item => item
        ?.filter(group => group.isVisible))
        .filter(items => items?.length > 0))
      .filter(items => items?.length > 0);

    if (section.title !== 'Compensation Details' && visibleGroupItems.length > 0) {
      section.headers?.forEach(header => header.isVisible = true);
    }
  }

  const buildSections = () => {
    if (mappedSections) return mappedSections;
    else if (!mappedSections && denseRowSection) {
      return denseRowSection.map((section, index) => {
        isDenseRowNA =
          section?.isDenseRow &&
            parentGroup?.lineItems?.length &&
            parentGroup?.lineItems[parentIndex]
            ? parentGroup.lineItems[parentIndex][0]?.notApplicable
            : false;

        if (section?.sectionType) {
          return renderEntity(section, formName);
        }

        const isSectionVisible =
          typeof section?.isVisible === "undefined"
            ? true
            : typeof section?.isVisible === "function"
              ? section?.isVisible()
              : section?.isVisible;
        let sectionClass = "normalSection";

        if (!isSectionVisible) return null;

        if (section?.groups?.length === 0) {
          sectionClass = "";
        }

        const groups = section ? (
          <BuildFormGroups
            key={`build-form-groups-key-${section.sectionId}`}
            section={section}
            // screenSize={screenSize}
            saveForm={save}
            isFormLocked={isFormDisabled}
            triggered={LineItemSectiontriggered}
            sectionClass={sectionClass}
            sectionIndex={index}
            entityScrollTab={entityScrollTab}
            entityIndex={entityIndex}
            setUniqueEntityFieldValue={setUniqueEntityFieldValue}
            showSharedEntityDialog={showSharedEntityDialog}
            parentGroup={parentGroup}
            parentIndex={parentIndex}
            isDenseRowNA={isDenseRowNA}
            sectionFieldLogic={sectionFieldLogic}
            renderEntity={renderEntity}
            setFormSections={setFormSections}
            grandParentGroup={grandParentGroup}
          />
        ) : null;

        // const key = new Date().getTime();
        let summaryGroup = section?.groups.filter(group => group.groupType === 'groupItem');
        if (summaryGroup) showSummaryViewHeaders(summaryGroup, section);

        return section ? (
          <React.Fragment key={`section-collapse-key-${section.sectionId}`}>
            <Collapse
              key={index}
              title={section.title}
              collapseClass={section.collapseClass || null}
              index={index}
              groups={groups}
              allSections={denseRowSection}
              section={section}
              sectionClass={sectionClass}
              fns={{
                save,
                setUniqueEntityFieldValue,
                showSharedEntityDialog,
              }}
              isFormPresent={isFormPresent}
              isFormLocked={isFormDisabled}
              // screenSize={screenSize}
              triggered={LineItemSectiontriggered}
              sectionFieldLogic={sectionFieldLogic}
              hasLineItemSection={hasLineItemSection}
              entityIndex={entityIndex}
              isFormExpandable={isFormExpandable}
              lastDenseRowSection={lastDenseRowSection}
              parentFormName={parentFormName}
              isScheduleFormSection={isScheduleFormSection}
              bookmarkTitle={bookmarkTitle}
              parentGroup={parentGroup}
              bondID={section.bondID}
              parentIndex={parentIndex}
            />
          </React.Fragment>
        ) : null;
      });
    } else return null;
  }

  const resetErrorBoundary = () => {
    if (setHasLoadingError) {
      setHasLoadingError(true);
    }
    setIsLoaded(false);
  };

  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    console.log(error);
    resetErrorBoundary();
    return <></>;
  };

  return (
    // <AppSizeListener>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={resetErrorBoundary}
    >
      <div>
        {isFormPresent ? (
          buildSections()
        ) : (
          <div
            // className={
            //   (isFormNA || isDenseRowNA
            //     ? "formBuilderContainer notApplicableForm"
            //     : isEntryInterview ? '' : "formBuilderContainer") +
            //   (isLineItemSection ? " isSectionVisible" : "")
            // }
            // style={containerStyles}
            className={hasLineItemSection || isBondItemSection ? null : `pdfFormsContainer`}
          >
            <div className="grid-container">
              {isExportForm ?
                <div id={`pdfHeaderContainer-${formName}`} style={{ display: 'flex', flexDirection: 'column-reverse', flex: 1 }}>
                  <div className="grid-child">
                    {
                      formTitle && <h3 style={{ textAlign: 'center' }}>{year.current + ' ' + formTitle}</h3>
                    }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="grid-child">
                      <img src={CLA_BLACK_LOGO} alt="CLA logo" width="25" height="25"></img>
                    </div>
                    {
                      defaultFormName !== 'stateTax' &&
                      <div className="grid-child clientDetailsContainer" style={{ display: 'flex' }}>
                        <span>{getClientDetails().clientName}</span>&nbsp;
                        <span>{getClientDetails().clientNumber}</span>&nbsp;
                        <span>{taxYearEndDate}</span>
                      </div>
                    }
                  </div>
                </div> : <></>
              }
            </div>
            {isFormNA && <div className="watermark">Not Applicable</div>}
            {buildSections()}
          </div>
        )}
      </div>
    </ErrorBoundary>
    // </AppSizeListener>
  );
}

export default ExportRenderer;
