import { useEffect, useRef, useState } from "react";
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Button } from 'react-md';

import api from '@utilities/claApi';
import ExportIndex from "@components/organizerExport/exportIndex";
import { yearEndValue } from '@components/exchangeManager/LoadYearEnd';

//Forms
import EntryInterview from '@views/basicInformation/entryInterview';
import BasicData from '@views/basicInformation/basicData';
import Programs from '@views/basicInformation/programs';
import InternalSetup from '@views/basicInformation/internalSetup';
import TaxComplianceFilings from '@views/governance/taxComplianceFilings';
import GovernanceManagement from '@views/governance/governanceAndManagement/index.js';
import BoardOfDirectorsAndCompensation from '@views/governance/boardOfDirectorsAndCompensation';
import Financials from '@views/financial/financials';
import Contributions from '@views/financial/contributions';
import FundraisingAndGaming from '@views/financial/fundraisingAndGaming'
import ForeignActivities from '@views/financial/foreignActivities';
import PoliticalCampaign from '@views/financial/politicalCampaignAndLobbying';
import DomesticGrants from '@views/financial/domesticGrants';
import LiquidationAndDisposition from '@views/financial/liquidationAndDisposition';
import PublicCharityAndSupport from '@views/financial/publicCharityAndSupport';
import UnrelatedBusinessIncomeTax from '@views/financial/unrelatedBusinessIncomeTax';
import InterestedPersonsTransactions from '@views/otherSchedules/interestedPersons';
import RelatedOrganizations from "@views/otherSchedules/relatedOrganizations";
import SuppFinStatements from '@views/otherSchedules/supplementalFinancialStatements';
import TaxExemptBonds from "@views/otherSchedules/taxExemptBonds";
import Hospital from '@views/otherSchedules/hospital';
import Schools from '@views/otherSchedules/schools';

import '@components/organizerExport/organizer.scss'

import usePageFramework from '@utilities/hooks/usePageFramework';
import { dashboard as defaultDashboard } from '@utilities/constants/dashboard';
import { clientDetailsSetter } from '@components/organizerExport/organizerRenderer/components/utils/getClientDetails';

const Organizer = () => {
  const [activeOrganizer, setActiveOrganizer] = useState({
    clientName: '', clientNumber: '', year: ''
  });

  function browserPrint() {
    const docTitle = document.title;
    document.title = `${activeOrganizer.clientName}-${activeOrganizer.clientNumber}-${activeOrganizer.year}`
    window.print();
    document.title = docTitle;
  };

  const componentRef = useRef(null);
  const { dispatch, REDUX, selectState, ACTION, updatePage, CARDSTATUS, clearFormState } = usePageFramework();
  const dashboard = selectState(REDUX.DASHBOARD);
  const notes = selectState(REDUX.DASHBOARD_NOTES);
  const isClient = selectState(REDUX.IS_CLIENT);
  const { orgId } = useParams();
  const categories = dashboard ? dashboard.filter((category) => category.title !== 'Opportunity Services') : [];
  const [isLoaded, setIsLoaded] = useState(false);
  const [isHavingLoadingErrors, setIsHavingLoadingErrors] = useState(false);

  useEffect(() => {
    updatePage({ title: 'PDF Export', key: 'organizerExport' });
    clearFormState();

    // get organizer details
    api.get(`organizers/${orgId}`).then(async (response) => {
      const { id: dashboardId, dashboard } = await api
        .get(`organizers/${response?.data?.id}/dashboard`)
        .then(response => response.data);

      const {
        forms,
        client,
        year,
      } = response.data;

      // set organizer meta data
      dispatch(ACTION.setYear(year));

      // get prior year data for organizer
      await api.get(`/organizers/${orgId}/prior`).then((response) => {
        dispatch(ACTION.setPriorYearData(response.data.data.taxData.priorYear));
        return response.data.data.taxData;
      }).catch((err) => {
        console.error(err);
      });

      let dashboardCopy = _.cloneDeep(dashboard ?? defaultDashboard);
      dispatch(ACTION.setDashboard(dashboardCopy));
      dispatch(ACTION.setDashboardId(dashboardId ?? null));

      forms?.forEach((form) => {
        dispatch(ACTION.setForm(form.key, form.data));
      });

      setActiveOrganizer({
        clientName: client.name,
        clientNumber: client?.number ?? '',
        year: year
      });

      clientDetailsSetter.clientDetails = {
        clientName: client.name,
        clientNumber: client?.number ?? '',
        year: year,
        organizer: response.data
      };

      yearEndValue(response.data).then((yearValue) => {
        dispatch(ACTION.setTaxYearEndDate(yearValue));
        dispatch(ACTION.setProgressVisible(false));
        setIsLoaded(true);
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.error(err);
      setIsHavingLoadingErrors(true)
      dispatch(ACTION.setProgressVisible(false));
    });
    // eslint-disable-next-line
  }, []);

  //index logic
  let orgExportIndexArr = [];
  for (let section in categories) {
    for (let form in categories[section].cards) {
      orgExportIndexArr.push(categories[section].cards[form])
    }
  }
  //notes logic
  let orgExportNotesArr = [];
  for (let note in notes) {
    orgExportNotesArr.push(notes[note]);
  }

  const showExportForm = (formName) => {
    return orgExportIndexArr.find(
      card => card.formName === formName &&
        //   ((card.statusId !== CARDSTATUS.NOT_STARTED || card.hasPriorData) && card.statusId !== CARDSTATUS.DOES_NOT_APPLY)) //if NA forms should not show at all even with PY and CY data??
        (card.statusId !== CARDSTATUS.NOT_STARTED || card.hasPriorData))
  };

  let orgExportForms = [
    { formName: REDUX.ENTRY_INTERVIEW, component: <EntryInterview isExportForm={true} /> },
    { formName: REDUX.BASIC_DATA, component: <BasicData isExportForm={true} /> },
    { formName: REDUX.PROGRAMS, component: <Programs isExportForm={true} /> },
    { formName: REDUX.INTERNAL_SETUP, component: <InternalSetup isExportForm={true} /> },
    { formName: REDUX.TAX_COMPLIANCE_FILINGS, component: <TaxComplianceFilings isExportForm={true} /> },
    { formName: REDUX.GOVERNANCE_AND_MANAGEMENT, component: <GovernanceManagement isExportForm={true} /> },
    { formName: REDUX.BOARD_OF_DIRECTORS_AND_COMPENSATION, component: <BoardOfDirectorsAndCompensation isExportForm={true} /> },
    { formName: REDUX.FINANCIALS, component: <Financials isExportForm={true} /> },
    { formName: REDUX.CONTRIBUTIONS, component: <Contributions isExportForm={true} /> },
    { formName: REDUX.FUNDRAISING_AND_GAMING, component: <FundraisingAndGaming isExportForm={true} /> },
    { formName: REDUX.FOREIGN_ACTIVITIES, component: <ForeignActivities isExportForm={true} /> },
    { formName: REDUX.POLITICAL_CAMPAIGN_AND_LOBBYING, component: <PoliticalCampaign isExportForm={true} /> },
    { formName: REDUX.DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE, component: <DomesticGrants isExportForm={true} /> },
    { formName: REDUX.LIQUIDATION_AND_DISPOSITION_OF_ASSETS, component: <LiquidationAndDisposition isExportForm={true} /> },
    { formName: REDUX.PUBLIC_CHARITY_AND_SUPPORT, component: <PublicCharityAndSupport isExportForm={true} /> },
    { formName: REDUX.UNRELATED_BUSINESS_INCOME_TAX, component: <UnrelatedBusinessIncomeTax isExportForm={true} /> },
    { formName: REDUX.INTERESTED_PERSONS_TRANSACTIONS, component: <InterestedPersonsTransactions isExportForm={true} /> },
    { formName: REDUX.RELATED_ORGANIZATIONS_SCHED_R, component: <RelatedOrganizations isExportForm={true} /> },
    { formName: REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS, component: <SuppFinStatements isExportForm={true} /> },
    { formName: REDUX.TAX_EXEMPT_BONDS, component: <TaxExemptBonds isExportForm={true} /> },
    { formName: REDUX.HOSPITAL, component: <Hospital isExportForm={true} /> },
    { formName: REDUX.SCHOOLS, component: <Schools isExportForm={true} /> },
  ];

  if (isClient) {
    orgExportForms = orgExportForms.filter(form => form.formName !== REDUX.INTERNAL_SETUP);
  }

  // Filtering the forms beforehand to remove undefined items within the array due to showExportForm(form.formName)
  // and only process those forms that passed the conditions inside the showExportForm function
  const filteredOrgExportForms = orgExportForms.map((form) => showExportForm(form.formName) && form)
    .filter(item => item !== undefined);

  // This function puts pageBreakAfter each form except the last one
  const pagebreak = (index) => {
    let pagebreakAfter = filteredOrgExportForms.length - 1 === index ? '' : 'pageBreakAfter';

    return `pagebreak ${pagebreakAfter}`;
  }

  return (
    isLoaded ? <>
      <Button className="startPrint" onClick={browserPrint} >
        {'Print'}
      </Button>
      <div id="divToPrint" className="organizer" ref={componentRef}>
        <div className='pagebreak pageBreakAfter'>
          <ExportIndex index={orgExportIndexArr} ></ExportIndex>
          {/* Commenting out Notes Export page for now until fully implemented */}
          {/* <NotesExport notes={orgExportNotesArr}></NotesExport> */}
        </div>

        {filteredOrgExportForms.map((form, index) =>
          <div key={index} className={pagebreak(index)} id={form.formName}>
            <p hidden>{form.formName}</p>
            {form.component}
          </div>
        )}
      </div>
    </> : isHavingLoadingErrors ? <div>Errors occurred on loading, Please try again or contact support!</div> : <div>Loading...</div>
  );
};

export default Organizer;