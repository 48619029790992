import { TextArea, Button, DialogFooter, useToggle } from 'react-md';
import { useState, useRef, useEffect } from 'react';

// custom modules
import WarningDialog from '@components/WarningDialog.js';
import SuccessDialog from '@components/SuccessDialog.js';
import api from '@utilities/claApi';
import { 
    CLOSE_SUBTITLE_TEXT,
    CLOSE_QUESTION_TEXT,
    CLOSE_CONFIRM_TEXT,
    SAVE_IN_PROGRESS_TEXT,
    SAVE_SUCCESSFUL_TEXT,
    SAVE_ERROR_TEXT,
    PRAC_DASH_NOTE_KEY,
} from '@utilities/constants/notes.js';
import _ from 'lodash';

function NotesPanel(props) {
    const { organizerId, clientName, notes, changeData, setIsCollapsed } = props;
    const [savingText, setSavingText] = useState(SAVE_IN_PROGRESS_TEXT);
    const [visibleSuccessDialog, showSuccessDialog, hideSuccessDialog] = useToggle(false);
    const [visibleWarningDialog, showWarningDialog, hideWarningDialog] = useToggle(false);

    const DEFAULT_NOTE = { value: '' };
    const textAreaRef = useRef();
    const [currentformNote, setCurrentformNote] = useState(DEFAULT_NOTE.value);
    const [savedNotes, setSavedNotes] = useState([DEFAULT_NOTE]);

    useEffect(() => {
        // On prac dash component state changes, update note component
        // GROWTH: API call to get note data rather than passing note data from parent
        const targetNote = notes?.find(x => x.key === PRAC_DASH_NOTE_KEY) || notes?.find(x => !x.key) || DEFAULT_NOTE;

        setCurrentformNote(targetNote.value);
        setSavedNotes([targetNote])
    //eslint-disable-next-line
    }, [notes]);

    const note = savedNotes[0];

    const saveUpstream = (noteId, noteData) => {
        // Update changes to note component to prac dash component state
        const clonedNotes = _.cloneDeep(notes);
        const targetNote = clonedNotes.find(x => x.id === noteId);
        const noteDoc = Object.assign({}, { id: noteId }, noteData);

        if (targetNote) {
            Object.assign(targetNote, noteDoc);
        } else {
            clonedNotes.push(noteDoc);
        }

        const delta = [
            {
                column: 'notes',
                value: clonedNotes,
            }
        ];

        setSavedNotes([noteDoc]);
        changeData(organizerId, delta);
    };

    const onClose = () => {
        savingText === SAVE_SUCCESSFUL_TEXT ? setCurrentformNote(textAreaRef.current.value) : setCurrentformNote(savedNotes[0].value);
        hideSuccessDialog();
        setIsCollapsed(false)
    };

    const onConfirmClose = () => {
        savingText === SAVE_SUCCESSFUL_TEXT ? setCurrentformNote(textAreaRef.current.value) : setCurrentformNote(savedNotes[0].value);
        hideWarningDialog();
        setIsCollapsed(true);
        setSavingText(SAVE_IN_PROGRESS_TEXT);
    };

    const onCancel = () => {
        if (typeof (textAreaRef.current) !== 'undefined' && textAreaRef.current && textAreaRef.current.value !== currentformNote) {
            setCurrentformNote(textAreaRef.current.value);
            showWarningDialog();
        } else {
            onConfirmClose();
        }
    };

    const onSave = () => {
        setSavingText(SAVE_IN_PROGRESS_TEXT);
        showSuccessDialog();

        const value = textAreaRef.current.value;
        const noteData = { value, key: PRAC_DASH_NOTE_KEY };

        if (!note.id) {
            const newNoteData = Object.assign({}, noteData);

            // if we do not have an existing note, we need to create one
            api.post(`/organizers/${organizerId}/notes`, newNoteData).then((response) => {
                return response.data.id;
            }).then((noteId) => {
                // save was successful
                setCurrentformNote(value);
                setSavingText(SAVE_SUCCESSFUL_TEXT);
                saveUpstream(noteId, noteData);
            }).catch((error) => {
                setSavingText(SAVE_ERROR_TEXT);
                console.log(error);
            })
        } else {
            api.put(`/organizers/${organizerId}/notes/${note.id}`, noteData).then((response) => {
                // save was successful
                setCurrentformNote(value);
                setSavingText(SAVE_SUCCESSFUL_TEXT);
                saveUpstream(note.id, noteData);
            }).catch((error) => {
                setSavingText(SAVE_ERROR_TEXT);
                console.log(error);
            });
        }
    };

    return (
        <div className={'pracDashNotes'} data-testid='notes-panel'>
            <div className='filledHeaderContainer'> Organization Notes </div>
            <TextArea
                data-testid='notes-panel-text-area'
                className={'organizerNotesTextArea'}
                id={'prac-dash-notes-textArea'}
                key={'prac-dash-notes-textArea'}
                ref={textAreaRef}
                rows={7}
                maxRows={10}
                maxLength={998}
                defaultValue={typeof (textAreaRef.current) !== 'undefined' && textAreaRef.current && textAreaRef.current.value ? textAreaRef.current.value : currentformNote}
                placeholder={'Please enter your notes here...'}
                animate={false}
            />
            <DialogFooter className='organizerNotesFooter'>
                <div className='notesFooterText'> Saving this note will create a new version.</div>
                <div>
                    {typeof (textAreaRef.current) !== 'undefined' && textAreaRef.current && textAreaRef.current.value !== note.value ?
                        <WarningDialog className='formNotesCancel' subtitleText={CLOSE_SUBTITLE_TEXT(clientName)} questionText={CLOSE_QUESTION_TEXT} confirmText={CLOSE_CONFIRM_TEXT} onConfirmClick={onConfirmClose}
                            buttonElement={<Button className='formNotesCancel' onClick={showWarningDialog}> CANCEL </Button>}
                            visible={visibleWarningDialog} onCancelWarning={hideWarningDialog}>
                        </WarningDialog> :
                        <Button className='formNotesCancel' id="notes-cancel" onClick={onCancel}>
                            CANCEL
                        </Button>
                    }
                    <SuccessDialog className='formNotesSaveActive' subtitleText={savingText} onClick={onSave} onCloseClick={onClose}
                        buttonElement={!note.value ? 'SAVE' : 'UPDATE'}
                        visible={visibleSuccessDialog}>
                    </SuccessDialog>
                </div>
            </DialogFooter>
        </div>
    );
}

export default NotesPanel;