import _ from 'lodash';
import { setFieldValue } from '@utilities/populatePriorData/populateFields';
import * as INPUT_STATE from '@utilities/constants/inputState';
import { filterPYByIdentifier, findSection, filterSections, findFromData, findFieldInLine } from '@utilities/axcessTaxPull.js';

// define post populate functions
const mergeA1IntoFirstEntity = (group, options) => {
	if (!group) return;

	const { entities, lineItems } = group;

	if (!entities || !_.isArray(entities) || !entities.length) return;
	if (!lineItems || !_.isArray(lineItems) || !lineItems.length) return;

	const separator = '\n';

	// merge all entities into first entity

    const joinedText = entities.map((entity) => {
        return entity?.sections?.[0]?.groups?.[3]?.fields?.[1]?.default?.trim();
      }).filter(x => x).join(separator);
      
    entities[0].sections[0].groups[3].fields[1].default = joinedText;

	// remove all but the first lineItem and entity
	group.lineItems.splice(1);
	group.entities.splice(1)
};

const setFieldValueIfNotFound = (group, options, hasPYData) => {
	if (!hasPYData) return;
	if (!group) return;
	if (!options) return;

	const pyDefaultValues = options.pyDefaultValues;

	group.fields.forEach((field) => {
		const pyDefaultValue = pyDefaultValues[field.name];
		const isPopulated = (field.inputState & INPUT_STATE.PRIOR_INPUT);
		
		// Final setFieldValue if the field is not populated
		if (pyDefaultValue && !isPopulated) {
			setFieldValue(field, pyDefaultValues[field.name]);
		}
	});
};

const threeLargestProgramAccomplishments = (group, options, hasPYData, priorYearData) => {
	if (!group) return;
	if (!options) return;
	if (!hasPYData) return;

	const {
		descriptionFieldName,
		threeLargestWorksheetData,
		schOWorksheetData,
	} = options;

	const paDescriptions = [ [], [], [] ];
	const types = {
		'PS1': 0,
		'PS2': 1,
		'PS3': 2,
	};

	// Get Three Largest Accomplishments WorksheetSection descriptions
	const {
		form990Identifier,
		form990WorksheetSectionName,
		threeLargestFieldKeys
	} = threeLargestWorksheetData;
	const form990Worksheet = priorYearData.filter(filterPYByIdentifier(form990Identifier));
	const threeLargest = findSection(form990Worksheet?.[0]?.data, form990WorksheetSectionName);

	threeLargestFieldKeys?.forEach((fieldKey, index) => {
		const descriptions = findFromData(threeLargest, fieldKey, 'fields').map(x => x.value).filter(x => x);
		paDescriptions[index].push(...descriptions);
	});

	// Get Accomplishments Descriptions from Sch O
	const {
		schOIdentifier,
		schOWorksheetSectionName,
		schOTypeKey,
		schOValueKey,
	} = schOWorksheetData;
	const schOWorksheet = priorYearData.filter(filterPYByIdentifier(schOIdentifier));
	const supp990Sections = filterSections(schOWorksheet?.[0]?.data, schOWorksheetSectionName);

	supp990Sections?.forEach((supp990Section) => {
		const type = findFieldInLine(supp990Section?.fields, schOTypeKey)?.value;
		const value = findFieldInLine(supp990Section?.fields, schOValueKey)?.value;
		const index = types[type];

		if (!_.isNil(index) && value) {
			paDescriptions[index].push(value);
		}
	});

	const insertRows = paDescriptions.map((paDescription) => {
		const description = paDescription.filter(x => x).join('\n');
		let clonedRow;

		if (description) {
			clonedRow = _.cloneDeep(group.fields);
			setFieldValue(clonedRow.find(x => x.name === descriptionFieldName), description);
		}

		return clonedRow;
	}).filter(x => x);

	if (insertRows.length) {
		group.lineItems.splice(0, 0, ...insertRows);
	}
};

const postPopulateFunctions = {
	mergeA1IntoFirstEntity,
	setFieldValueIfNotFound,
	threeLargestProgramAccomplishments,
};

// define handler functions
const returnFn = ([x]) => x;

const getPostPopulateFunction = (functionName) => {
	return functionName && postPopulateFunctions[functionName]
		? postPopulateFunctions[functionName] : returnFn;
};

export {
	getPostPopulateFunction,

	// exported for testing purposes
	mergeA1IntoFirstEntity,
	setFieldValueIfNotFound,
	threeLargestProgramAccomplishments,
};