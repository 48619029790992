/*
/App

The main entry point for application components.
*/

import './App.scss';
import './App.css';
// common modules
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useIntercom } from 'react-use-intercom';
import _ from 'lodash';

// custom modules
import { reactPlugin, appInsights } from '@utilities/applicationInsights.js';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { getRoutes } from '@utilities/routes';
import TopBar from '@components/header/topBar';
import HeaderMenu from '@components/header/headerMenu';
import HeaderImage from '@components/header/headerImage';
import AppFooter from '@components/footer';
import ProgressDialog from '@components/dialog/progressDialog';
import CustomDialog from '@components/dialog/customDialog';
import { initRoles, hasRole, scopes } from '@utilities/claApi';
import { authenticated, getAccount, injectLocalDevelopmentTokens } from '@utilities/authentication';
import { LicenseInfo } from '@mui/x-license-pro';
import UploadWarningDialog from '@components/dialog/uploadWarningDialog';
import {
  SCREEN_BREAKPOINTS,
  SCREEN_SIZES,
} from '@utilities/constants/screenSizes';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_KEY);

//redirect to AAD login for sso, call when unauthenticated
const RedirectToLogin = () => {
  const { instance, inProgress } = useMsal();

  // when interaction is complete, redirect to login
  if (inProgress === InteractionStatus.None) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // inject development tokens into cache then refresh entire page
      injectLocalDevelopmentTokens();
      window.location.reload();
    } else {
      // redirect to MSAL login
      instance.loginRedirect({ scopes });
    }
  }
};

function App() {
  const { selectState, REDUX, dispatch, ACTION } = usePageFramework();
  const { boot } = useIntercom();

  // Roles
  const isAuthenicated = selectState(REDUX.IS_AUTHENTICATED);
  const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
  const isAdmin = selectState(REDUX.IS_ADMIN);
  const isClient = selectState(REDUX.IS_CLIENT);

  const activeReturn = selectState(REDUX.ACTIVE_RETURN);

  // Progress Dialog
  const isProgressVisible = selectState(REDUX.PROGRESS_VISIBLE);
  const progressText = selectState(REDUX.PROGRESS_TEXT);

  // Custom Dialog
  const showCustomDialog = selectState(REDUX.SHOW_CUSTOM_DIALOG);

  //Upload Warning Dialog
  const isUploadWarningVisible = selectState(REDUX.UPLOAD_WARNING_VISIBLE);
  const dupFiles = selectState(REDUX.DUPLICATE_UPLOAD_FILES) || [];
  const uploadProps = selectState(REDUX.UPLOADS_PROPS);
  const uploadList = selectState(REDUX.UPLOAD_LIST);

  const availableRoutes = getRoutes(isAuthenicated, isPractitioner, isAdmin, isClient, activeReturn).reduce((prev, page) => {
    prev.push(<Route path={page.to} exact component={page.componentObject} key={page.to} />);
    return prev;
  }, []);

  // GROWTH: Handle loading data with multiple client numbers
  useEffect(() => {
    async function initialize() {
      let isAdmin = false;
      let isPractitioner = false;
      //eslint-disable-next-line
      let isClient = false;

      if (authenticated()) {
        const account = getAccount();

        if (account) {
          // set Authenticated User Context for AppInsights
          appInsights.setAuthenticatedUserContext(
            account.username ?
              account.username : account.idTokenClaims.email);

          // boot Intercom as current user
          boot({
            name: account.name,
            email: account.username ? account.username : account.idTokenClaims.email,
            hideDefaultLauncher: true
          });
        }

        initRoles()
          .then(() => {
            // determine user roles and store in state
            isAdmin = hasRole(['Administrator']) ?? false;
            isPractitioner = (!hasRole(['Administrator']) && hasRole(['Employee'])) ?? false;
            isClient = (!hasRole(['Administrator']) && !hasRole(['Employee']) && hasRole(['Client'])) ?? false;

            dispatch(ACTION.setIsAdmin(isAdmin));
            dispatch(ACTION.setIsPractitioner(isPractitioner));
            dispatch(ACTION.setIsClient(isClient));
          })
          .catch((err) => {
            if (!isAdmin && !isPractitioner) console.log('Contact admin to get set up');
            console.log('Err: ', err);

          })
          .finally(() => {
            dispatch(ACTION.setisAuthenticated(authenticated() ?? false));
          });
      }
    }

    const handleWindowResize = _.debounce(() => {
      const screenWidth = window.innerWidth;

      if (
        screenWidth >= SCREEN_BREAKPOINTS.MOBILE_480 &&
        screenWidth < SCREEN_BREAKPOINTS.TABLET
      ) {
        dispatch(ACTION.setScreenSize(SCREEN_SIZES.MOBILE_480));
        return;
      }

      if (screenWidth < SCREEN_BREAKPOINTS.TABLET) {
        dispatch(ACTION.setScreenSize(SCREEN_SIZES.MOBILE));
        return;
      }

      if (
        screenWidth >= SCREEN_BREAKPOINTS.TABLET &&
        screenWidth < SCREEN_BREAKPOINTS.LAPTOP
      ) {
        dispatch(ACTION.setScreenSize(SCREEN_SIZES.TABLET));
        return;
      }

      if (
        screenWidth >= SCREEN_BREAKPOINTS.LAPTOP &&
        screenWidth < SCREEN_BREAKPOINTS.DESKTOP
      ) {
        dispatch(ACTION.setScreenSize(SCREEN_SIZES.LAPTOP));
        return;
      }

      dispatch(ACTION.setScreenSize(SCREEN_SIZES.DESKTOP));
    }, 300);

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    initialize();

    return () => window.removeEventListener('resize', handleWindowResize);

    //eslint-disable-next-line
  }, []);

  return (
    <div className='App'>
      <div className='topHeaders'>
        <TopBar />
        <HeaderMenu />
        <HeaderImage />
      </div>
      <AuthenticatedTemplate>
        <Switch>
          {availableRoutes}
        </Switch>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <RedirectToLogin />
      </UnauthenticatedTemplate>
      <AppFooter />

      <ProgressDialog visible={isProgressVisible ?? false} loadingText={progressText ?? ''} />
      <CustomDialog visible={showCustomDialog || false} />
      <UploadWarningDialog visible={isUploadWarningVisible || false} dupFiles={dupFiles} uploadProps={uploadProps} uploadList={uploadList} />
    </div>
  );
}

export default withAITracking(reactPlugin, App);
