import React, { useState, useEffect } from 'react';
import { Grid } from 'react-md';
// import _ from 'lodash';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRenderer from '../index';

const BondsItem = ({
    group, 
    colspan, 
    screenSize, 
    fns, 
    isFormLocked, 
    section, 
    sectionIndex, 
    groupIndex, 
    sections, 
    entityIndex, 
    showSharedEntityDialog,
    parentGroup,
    parentIndex,
    grandParentGroup,
    formSections
}) => {
    const [ bondsItem, setBondsItem ] = useState([]);
    const [currentEntity, setCurrentEntity] = useState(-1);
    const [currentH2, setCurrentH2] = useState('Summary');
    const isAddingNewEntity = false;
    
    useEffect(() => {
        createBonds(group, colspan);
        updateTableManager();
    // eslint-disable-next-line
    }, [group, currentEntity, currentH2, isAddingNewEntity]);

    const updateTableManager = () => {
        if (group.hasTableManagerTabs || group.isSummaryRow) {

            group.currentH2 = group.isH2TabsHidden ? group?.bondsSections[0]?.title : currentH2;
            setCurrentH2(group.currentH2);
            group.isAddingNewEntity = isAddingNewEntity;

            if (currentH2 && (group.isSummaryRow ? group.currentEntity === 0 : group.currentEntity >= 0)) {
                if (group.isAddingNewEntity && group.currentH2 === 'expandAll') {
                    group.bonds[group.currentEntity]?.sections.forEach((section) => {
                        section.isCollapsed = false;
                    });
                } 
                else {
                    if (group.currentH2 === 'Summary') {
                        group.bonds[group.currentEntity]?.sections.forEach((section) => {
                            section.isCollapsed = true;
                        });
                    }
                    group.bonds[group.currentEntity]?.sections.forEach((section) => {
                        if (section.title === group.currentH2 && section.isCollapsed) {
                            section.isCollapsed = false;
                        }
                        if (section.title !== group.currentH2 && !section.isCollapsed) {
                            section.isCollapsed = true;
                        }
                    });
                }
            }
        }

        if (group.isSummaryRow) {
            group.currentH2 = parentGroup.currentH2;
            setCurrentH2(group.currentH2);
            group.currentEntity = parentGroup.currentEntity === -2 ? 0 : -1;
            setCurrentEntity(group.currentEntity);
        }
    };

    const subSectionSaveForm = (field, nextTabId, childFormSectionData) => {
        if (fns) {
            fns.saveForm(
                field, 
                nextTabId, 
                Object.assign(
                    {}, 
                    childFormSectionData, 
                    { 
                        parentIndex: group.currentEntity, 
                        sectionIndex, 
                        hasBondItems: true 
                    }
                )
            );
        }
    };
    
    const createBonds = (group, colspan) => {
        const { bonds } = group;
        
        if (bonds) {
            bonds.forEach((bond, idx) => {
                if (bond && bond?.sections) {
                    bond.sections.forEach(section => {
                        section.bondID = idx + 1;
                    });
                }
            });
        }
        setBondsItem(bonds);
    }

    // const menuTabs = !group.hasTableManagerTabs ? null : <TableManagerTabs key={`table-manager-tab-${group.groupId}`} group={group} 
    //     isFormLocked={isFormLocked} fns={fns} section={section} setCurrentEntity={setCurrentEntity} setCurrentH2={setCurrentH2} 
    //     setIsAddingNewEntity={setIsAddingNewEntity} addNewLineItem={(group) => {}}/>
        
    return (
        <>
            {/* {menuTabs} */}
            <Grid className='expandableFieldsContainer' style={{zoom: '75%'}}>
                {bondsItem.map(bond => {
                    return <FormRenderer 
                        sections={bond.sections} 
                        parentFns={{ saveForm: subSectionSaveForm }} 
                        parentGroup={group} 
                        parentIndex={group.currentEntity} 
                        hasLineItemSection={false} 
                        isLineItemSection={false} 
                        usePageFramework={usePageFramework}
                        saveForm={fns.saveForm}
                        sectionFieldLogic={fns.sectionFieldLogic}
                        triggered={fns.triggered}
                        isFormDisabled={isFormLocked}
                        containerStyles={{minHeight: 'unset'}}
                        isBondItemSection={true} 
                    />;
                })}
            </Grid>
        </>
    )
}

export default BondsItem;