import { useState, useEffect } from 'react';
import { Validators } from 'cla-formrenderer';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import {
  triggeredEvent,
  sectionFieldLogic,
} from './foreignActivitiesLogicTrigger';
import { pullFieldValue } from '@utilities/pullFieldValue';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

const activitiesOutsideUS = [
  {
    title: 'Foreign Activities Outside US Details',
    isVisible: true,
    sectionId: 1,
    isCollapsed: false,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Number of Offices',
            name: 'actOutUsOffCntLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'Number of Offices',
            name: 'actOutUsOffCnt',
            type: 'total',
            col: 3,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Number of Employees, Agents and/or Contractors',
            name: 'actOutUsEECntLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'No of Employees, Agents and/or Contractors',
            name: 'actOutUsEECnt',
            type: 'total',
            col: 3,
          },
        ],
      },
      {
        groupId: 3,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Describe Program Services',
            name: 'actOutUsSrvcDescLabel',
            type: 'label',
            col: 9,
            class: 'labelTitleTextArea',
          },
          {
            label: 'Describe Program Services',
            name: 'actOutUsSrvcDesc',
            type: 'textarea',
            col: 12,
            maxRows: 3,
            class: 'fieldTextArea',
          },
        ],
      },
    ],
  },
];

export const grantSectionOrg = [
  {
    title: 'Grants/Assistance Organizations Outside the US Details',
    isVisible: true,
    sectionId: 1,
    isCollapsed: false,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'EIN/ITIN',
            name: 'orgGrntOutUsCodeEINLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'EIN/ITIN',
            name: 'orgGrntOutUsCodeEIN',
            type: 'number',
            col: 3,
            maxLength: 9,
            validations: Validators.ein(),
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Recognized Charity Type',
            name: 'orgGrntOutUsChrtyTypeLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'Recognized Charity Type',
            name: 'orgGrntOutUsChrtyType',
            type: 'select',
            lookup: 'GRNT_OUT_US_CHRTY_TYPE',
            col: 3,
            logicFunction: [
              { trigger: 'setOrgGrntOutUsChrtyType' },
              {
                trigger: 'updateOrgGrntOutUsChrtyType',
                isDifferentGroup: true,
              },
              { trigger: 'showOrgGrntOutUsCodeIRC' },
            ],
          },
        ],
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'IRC Section',
            name: 'orgGrntOutUsCodeIRCLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'IRC Section',
            name: 'orgGrntOutUsCodeIRC',
            maxLength: 20,
            type: 'freeText',
            col: 3,
          },
        ],
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Purpose of Grant',
            name: 'orgGrntOutUsPurposeLabel',
            type: 'label',
            col: 9,
            class: 'labelTitleTextArea',
          },
          {
            label: 'Please describe',
            name: 'orgGrntOutUsPurpose',
            type: 'textarea',
            col: 12,
            maxRows: 3,
            class: 'fieldTextArea',
          },
        ],
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Manner of Cash Disbursement',
            name: 'orgGrntOutUsDisbursMthdLabel',
            type: 'label',
            col: 7,
          },
          {
            label: 'Manner of Cash Disbursement',
            name: 'orgGrntOutUsDisbursMthd',
            type: 'select',
            lookup: 'GRNT_OUT_US_DISBURS_MTHD',
            col: 5,
          },
        ],
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Description of Noncash Assistance',
            name: 'orgGrntOutUsNonCashDescLabel',
            type: 'label',
            col: 9,
            class: 'labelTitleTextArea',
          },
          {
            label: 'Please describe',
            name: 'orgGrntOutUsNonCashDesc',
            type: 'textarea',
            col: 12,
            maxRows: 3,
            class: 'fieldTextArea',
          },
        ],
      },
      {
        groupId: 7,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Method of Valuation',
            name: 'orgGrntOutUsValMethLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'Method of Valuation',
            name: 'orgGrntOutUsValMeth',
            type: 'select',
            lookup: 'GRNT_OUT_US_VAL_METH',
            col: 3,
          },
        ],
      },
    ],
  },
];

const grantSectionIndv = [
  {
    title: 'Grants & Other Assistance to Individuals Outside the U.S. Details',
    isVisible: true,
    sectionId: 1,
    isCollapsed: false,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Number of recipients',
            name: 'indvGrntOutUsRecpCntLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'Number of recipients',
            name: 'indvGrntOutUsRecpCnt',
            type: 'total',
            col: 3,
            maxLength: 12,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Manner of Cash Disbursement',
            name: 'indvGrntOutUsDisbursMthdLabel',
            type: 'label',
            col: 7,
          },
          {
            label: 'Manner of Cash Disbursement',
            name: 'indvGrntOutUsDisbursMthd',
            type: 'select',
            lookup: 'GRNT_OUT_US_DISBURS_MTHD',
            col: 5,
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Description of Noncash Assistance',
            name: 'indvGrntOutUsNonCashDescLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'Please describe',
            name: 'indvGrntOutUsNonCashDesc',
            type: 'textarea',
            col: 12,
            maxRows: 3,
            class: 'fieldTextArea',
          },
        ],
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Method of Valuation',
            name: 'indvGrntOutUsValMethLabel',
            type: 'label',
            col: 9,
          },
          {
            label: 'Method of Valuation',
            name: 'indvGrntOutUsValMeth',
            type: 'select',
            lookup: 'GRNT_OUT_US_VAL_METH',
            col: 3,
          },
        ],
      },
    ],
  },
];

export const sections = [
  {
    title: 'Foreign Activities',
    isVisible: true,
    sectionId: 1,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization have aggregate revenues or expenses of more than $10,000 from grant making, fundraising, business, investment, and program service activities outside the United States, or aggregate foreign investments valued at $100,000 or more?',
            name: 'reqSchdChkFiLabel',
            type: 'label',
            col: 9,
          },
          {
            label: '',
            name: 'reqSchdChkFi',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              {
                trigger: 'showActivitiesOutsideUSAndFF',
                isDifferentGroup: true,
              },
            ],
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization report grants or other assistance of more than $5,000 to or for any foreign organization?',
            name: 'reqSchdChkFiiLabel',
            type: 'label',
            col: 9,
          },
          {
            label: '',
            name: 'reqSchdChkFii',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              {
                trigger: 'showGrantsOthAssistOrgAndFF',
                isDifferentGroup: true,
              },
            ],
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization report aggregate grants or other assistance of more than $5,000 to or for foreign individuals?',
            name: 'reqSchdChkFiiiLabel',
            type: 'label',
            col: 9,
          },
          {
            label: '',
            name: 'reqSchdChkFiii',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              {
                trigger: 'showGrantsOthAssistIndvAndFF',
                isDifferentGroup: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Activities Outside the U.S.',
    isVisible: false,
    hasDenseRow: true,
    sectionId: 2,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              "Does the organization maintain records to substantiate ALL of the following: the amount of the grants/assistance; the grantees' eligibility for the grants/assistance and the selection criteria used to award the grants/assistance?",
            name: 'actOutUSMaintRecLabel',
            type: 'label',
            col: 9,
          },
          {
            label: '',
            name: 'actOutUSMaintRec',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [{ trigger: 'showActOutUSMaintRecExp' }],
          },
        ],
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Explain why not:',
            name: 'actOutUSMaintRecExpLabel',
            type: 'label',
            col: '12',
            class: 'labelTitleTextArea',
          },
          {
            label: 'Explain',
            name: 'actOutUSMaintRecExp',
            type: 'textarea',
            col: 12,
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              "Describe the organization's procedures for monitoring the use of grant funds outside the United States:",
            name: 'actOutUSMonProcLabel',
            type: 'label',
            col: '12',
            class: 'labelTitleTextArea',
          },
          {
            label: 'Describe',
            name: 'actOutUSMonProc',
            type: 'textarea',
            col: 12,
            maxRows: 3,
            maxLength: 900,
            class: 'fieldTextArea',
            ...pullFieldValue(['XFDS99F1.2'], true),
          },
        ],
      },
      {
        groupId: 4,
        isVisible: true,
        fields: [
          {
            label:
              'Provide the following information about activities outside the United States: (Click here to Open List of Countries by Region)',
            name: 'actOutUSLabel',
            type: 'label',
            col: 10,
          },
        ],
      },
      {
        groupType: 'lineItem',
        groupId: 5,
        fields: [
          {
            label: 'Region',
            name: 'actOutUsRegion',
            type: 'select',
            lookup: 'GRNT_OUT_US_REGION',
          },
          //{ label: 'Number of Offices', name: 'actOutUsOffCnt', type: 'freeText'},
          //{ label: '# of EE, Agents & Contractors', name: 'actOutUsEECnt', type: 'freeText'},
          {
            label: 'Type of Act. Conducted',
            name: 'actOutUsActType',
            type: 'select',
            lookup: 'ACTIVITIES_OUTSIDEUS_TYPE',
            logicFunction: [
              { trigger: 'showProgramService', isDifferentGroup: true },
            ],
          },
          //{ label: 'Describe Program Services', name: 'actOutUsSrvcDesc', type: 'textarea'},
          {
            label: 'Total Expenditures',
            name: 'actOutUsTtlExpend',
            type: 'money',
            isTotal: true,
          },
          {
            label: 'Total Investments',
            name: 'actOutUsTtlInv',
            type: 'money',
            isTotal: true,
          },
          { type: 'lineButtons' },
        ],
        lineItems: [],
        entities: [],
        currentEntity: -1,
        lineSections: activitiesOutsideUS,
        headerLabelsPrintStyles: {gridTemplateColumns: '2fr 2fr 1.75fr 1.75fr 1fr'},
        footerLabelsPrintStyles: {gridTemplateColumns: '6fr 2fr 1fr'},
        lineItemDetails: {
          headerLabels: [
            {
              label: 'Region',
              name: 'actOutUsRegion',
              col: 4,
              colTablet: 3,
              styles: { textAlign: 'left', paddingLeft: '50px' },
            },
            //{ label: 'Number of Offices', name: 'actOutUsOffCnt',col: 2, colTablet: 2},
            //{ label: '# of EE, Agents & Contractors', name: 'actOutUsEECnt',col: 2, colTablet: 2},
            {
              label: 'Type of Act. Conducted',
              name: 'actOutUsActType',
              col: 3,
              colTablet: 2,
              styles: { textAlign: 'left', paddingLeft: '37px' },
            },
            //{ label: 'Describe Program Services', name: 'actOutUsSrvcDesc',col: 2, colTablet: 2},
            {
              label: 'Total Expenditures',
              name: 'actOutUsTtlExpend',
              col: 2,
              colTablet: 1,
              styles: { textAlign: 'left', paddingLeft: '29px' },
            },
            {
              label: 'Total Investments',
              name: 'actOutUsTtlInv',
              col: 2,
              colTablet: 1,
              styles: { textAlign: 'left', paddingLeft: '24px' },
            },
            {
              label: 'Actions',
              col: 1,
              colTablet: 1,
              styles: { paddingLeft: '20px' },
            },
          ],
          footerLabels: [
            {
              type: 'buttons',
              col: 12,
              buttonTitle: 'Add Activities',
              icon: 'add',
            },
            {
              type: 'totalMoney',
              name: 'actOutUsTtlExpend',
              col: 9,
              colTablet: 4,
              label: ' $ 0',
            },
            {
              type: 'totalMoney',
              name: 'actOutUsTtlInv',
              col: 2,
              colTablet: 4,
              label: ' $ 0',
            },
          ],
          lineButtons: [
            //{ button: 'uploadNa', uploadSectionName: '', viewSectionId: 0 },
            { button: 'remove' },
          ],
        },
      },
    ],
  },
  {
    title: 'Grants & Other Assistance to Organizations Outside the U.S.',
    isVisible: false,
    sectionId: 3,
    hasDenseRow: true,
    hasDivider: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label:
              'Complete the table below for organizations outside the U.S. who received grants and other Assistance of more than $5,000.',
            name: 'orgGrntOutUsLabel',
            type: 'label',
            col: 10,
          },
        ],
      },
      {
        groupType: 'lineItem',
        groupId: 2,
        fields: [
          {
            label: 'Name of Organization',
            name: 'orgGrntOutUsOrgName',
            maxLength: 75,
            type: 'freeText',
          },
          {
            label: 'Region',
            name: 'orgGrntOutUsRegion',
            type: 'select',
            lookup: 'GRNT_OUT_US_REGION',
          },
          {
            label: 'Amt of Cash Grant (US$)',
            name: 'orgGrntOutUsCashAmt',
            type: 'money',
            isTotal: true,
          },
          {
            label: 'FMV of Noncash Assistance (US$)',
            name: 'orgGrntOutUsTtlNonCash',
            type: 'money',
            isTotal: true,
          },
          { type: 'lineButtons' },
        ],
        lineItems: [],
        entities: [],
        currentEntity: -1,
        lineSections: grantSectionOrg,
        headerLabelsPrintStyles: {gridTemplateColumns: '2fr 2fr 1.75fr 1.75fr 1fr'},
        footerLabelsPrintStyles: {gridTemplateColumns: '6fr 2fr 1fr'},
        lineItemDetails: {
          headerLabels: [
            {
              label: 'Name of Organization',
              name: 'orgGrntOutUsOrgNameLabel',
              col: 3,
              colTablet: 2,
              styles: { textAlign: 'left', paddingLeft: '50px' },
            },
            {
              label: 'Region',
              name: 'orgGrntOutUsRegionLabel',
              col: 4,
              colTablet: 3,
              styles: { textAlign: 'left', paddingLeft: '40px' },
            },
            {
              label: 'Amt of Cash Grant (US$)',
              name: 'orgGrntOutUsCashAmtLabel',
              col: 2,
              colTablet: 1,
              styles: { textAlign: 'left', paddingLeft: '30px' },
            },
            {
              label: 'Fair Market Value of Noncash Assistance (US$)',
              name: 'orgGrntOutUsTtlNonCashLabel',
              col: 2,
              colTablet: 1,
              styles: { textAlign: 'left', paddingLeft: '25px' },
            },
            {
              label: 'Actions',
              col: 1,
              colTablet: 1,
              styles: { paddingLeft: '20px' },
            },
          ],
          footerLabels: [
            {
              type: 'buttons',
              col: 12,
              buttonTitle: 'Add Organization',
              icon: 'add',
            },
            {
              type: 'totalMoney',
              name: 'orgGrntOutUsCashAmt',
              col: 9,
              colTablet: 4,
              label: ' $ 0',
            },
            {
              type: 'totalMoney',
              name: 'orgGrntOutUsTtlNonCash',
              col: 2,
              colTablet: 2,
              label: ' $ 0',
            },
          ],
          lineButtons: [
            //{ button: 'uploadNa', uploadSectionName: '', viewSectionId: 0 },
            {
              button: 'remove',
              logicFunction: [
                { trigger: 'setOrgGrntOutUsChrtyType' },
                {
                  trigger: 'updateOrgGrntOutUsChrtyType',
                  isDifferentGroup: true,
                },
              ],
            },
          ],
        },
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'How many organizations listed above are recognized charities (by the foreign country; by the IRS; or by a Section 501(c)(3) equivalency letter)?',
            name: 'orgGrntOutUsRcptOrgNoLabel',
            type: 'label',
            col: 10,
          },
          {
            label: '0',
            default: 0,
            name: 'orgGrntOutUsRcptOrgNo',
            type: 'label',
            col: 2,
            styles: { paddingLeft: '60px' },
          },
        ],
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Total number of other organizations listed above:',
            name: 'orgGrntOutUsOtherOrgNoLabel',
            type: 'label',
            col: 10,
          },
          {
            label: '0',
            default: 0,
            name: 'orgGrntOutUsOtherOrgNo',
            type: 'label',
            col: 2,
            styles: { paddingLeft: '60px' },
          },
        ],
      },
    ],
  },
  {
    title: 'Grants & Other Assistance to Individuals Outside the U.S.',
    isVisible: false,
    sectionId: 4,
    hasDenseRow: true,
    hasDivider: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label:
              'Complete the table below for individuals outside the U.S. who received grants and other Assistance of more than $5,000.',
            name: 'indvGrntOutUsLabel',
            type: 'label',
            col: 10,
          },
        ],
      },
      {
        groupType: 'lineItem',
        groupId: 2,
        fields: [
          {
            label: 'Type of Grant or Assistance',
            name: 'indvGrntOutUsGrntType',
            maxLength: 900,
            type: 'freeText',
          },
          {
            label: 'Region',
            name: 'indvGrntOutUsRegion',
            type: 'select',
            lookup: 'GRNT_OUT_US_REGION',
          },
          {
            label: 'Amt of Cash Grant (US$)',
            name: 'indvGrntOutUsCashAmt',
            type: 'money',
            isTotal: true,
          },
          {
            label: 'FMV of Noncash Assistance (US$)',
            name: 'indvGrntOutUsTtlNonCash',
            type: 'money',
            isTotal: true,
          },
          { type: 'lineButtons' },
        ],
        lineItems: [],
        entities: [],
        currentEntity: -1,
        lineSections: grantSectionIndv,
        headerLabelsPrintStyles: {gridTemplateColumns: '2fr 2fr 1.75fr 1.75fr 1fr'},
        footerLabelsPrintStyles: {gridTemplateColumns: '6fr 2fr 1fr'},
        lineItemDetails: {
          headerLabels: [
            {
              label: 'Type of Grant or Assistance',
              name: 'indvGrntOutUsGrntType',
              maxLength: 9,
              col: 3,
              colTablet: 2,
              styles: { paddingLeft: '45px' },
            },
            {
              label: 'Region',
              name: 'indvGrntOutUsRegion',
              col: 4,
              colTablet: 2,
              styles: { paddingLeft: '40px' },
            },
            {
              label: 'Amt of Cash Grant (US$)',
              name: 'indvGrntOutUsCashAmt',
              col: 2,
              colTablet: 2,
              styles: { paddingLeft: '30px' },
            },
            {
              label: 'Fair Market Value of Noncash Assistance (US$)',
              name: 'indvGrntOutUsTtlNonCash',
              col: 2,
              colTablet: 2,
              styles: { paddingLeft: '25px' },
            },
            {
              label: 'Actions',
              col: 1,
              colTablet: 1,
              styles: { paddingLeft: '20px' },
            },
          ],
          footerLabels: [
            {
              type: 'buttons',
              col: 12,
              buttonTitle: 'Add Grant/Assistance',
              icon: 'add',
            },
            {
              type: 'totalMoney',
              name: 'indvGrntOutUsCashAmt',
              col: 9,
              colTablet: 4,
              label: ' $ 0',
            },
            {
              type: 'totalMoney',
              name: 'indvGrntOutUsTtlNonCash',
              col: 2,
              colTablet: 2,
              label: ' $ 0',
            },
          ],
          lineButtons: [
            // { button: 'uploadNa', uploadSectionName: '', viewSectionId: 0 },
            { button: 'remove' },
          ],
        },
      },
    ],
  },
  {
    title: 'Foreign Forms',
    isVisible: false,
    sectionId: 5,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Was the organization a U.S. transferor of property to a foreign corporation during the year?',
            name: 'forFormUsTransferorLabel',
            type: 'label',
            col: 9,
          },
          {
            label: '',
            name: 'forFormUsTransferor',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [{ trigger: 'showDescOne' }],
          },
          {
            label:
              'The organization may be required to file Form 926, Return by a U.S. Transferor of Property to a Foreign Corporation',
            name: 'descOne',
            type: 'label',
            isVisible: false,
            col: 12,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization have an interest in a foreign trust during the tax year?',
            name: 'forFormIntInTrustLabel',
            type: 'label',
            col: 9,
          },
          {
            label: '',
            name: 'forFormIntInTrust',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              { trigger: 'showDescTwoThree', isDifferentGroup: true },
            ],
          },
          {
            label:
              'The organization may be required to file Form 3520, Annual Return to Report Transactions with Foreign Trusts and Receipt of Certain Foreign Gifts',
            name: 'descTwo',
            type: 'label',
            isVisible: false,
            col: 12,
          },
          {
            label:
              'The organization may be required to file Form 3520-A, Annual Information Return of Foreign Trust With a U.S. Owner',
            name: 'descThree',
            type: 'label',
            isVisible: false,
            col: 12,
          },
        ],
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization have an ownership interest in a foreign corporation during the tax year?',
            name: 'forFormIntInCorpLabel',
            type: 'label',
            col: 9,
          },
          {
            label: '',
            name: 'forFormIntInCorp',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [{ trigger: 'showDescFour' }],
          },
          {
            label:
              'The organization may be required to file Form 5471, Information Return of U.S. Persons With Respect to Certain Foreign Corporations',
            name: 'descFour',
            type: 'label',
            isVisible: false,
            col: 12,
          },
        ],
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Was the organization a direct or indirect shareholder of a passive foreign investment company or a qualified electing fund during the tax year?',
            name: 'forFormIntInShareLabel',
            type: 'label',
            col: 9,
          },
          {
            label: '',
            name: 'forFormIntInShare',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [{ trigger: 'showDescFive' }],
          },
          {
            label:
              'The organization may be required to file Form 8621, Information Return by a Shareholder of a Passive Foreign Investment Company or Qualified Electing Fund',
            name: 'descFive',
            type: 'label',
            isVisible: false,
            col: 12,
          },
        ],
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization have an ownership interest in a foreign partnership during the tax year?',
            name: 'forFormIntInPartnerLabel',
            type: 'label',
            col: 9,
          },
          {
            label: '',
            name: 'forFormIntInPartner',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [{ trigger: 'showDescSix' }],
          },
          {
            label:
              'The organization may be required to file Form 8865, Return of U.S. Persons With Respect to Certain Foreign Partnerships',
            name: 'descSix',
            type: 'label',
            isVisible: false,
            col: 12,
          },
        ],
      },
      {
        groupId: 6,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization have any operations in or related to any boycotting countries during the tax year? (As of April 4, 2022, the Treasury Department lists the boycotting countries as follows:  Iraq, Kuwait, Lebanon, Libya, Qatar, Saudi Arabia, Syria, and Yemen.)',
            name: 'forFormIntInBoycotLabel',
            type: 'label',
            col: 9,
          },
          {
            label: '',
            name: 'forFormIntInBoycot',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [{ trigger: 'showDescSeven' }],
          },
          {
            label:
              'The organization may be required to file Form 5713, International Boycott Report',
            name: 'descSeven',
            type: 'label',
            isVisible: false,
            col: 12,
          },
        ],
      },
    ],
  },
  {  
    title: 'Additional Information',
    collapseClass: 'collapsibleForm',
    isVisible: true,
    isDefaultExpanded: false,
    isOptionalSectionMerge: true,
    sectionId: 6,
    groups: [
      {
        groupId: 2,
        groupType: 'details',
        fields: [
          { 
            label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
            type: 'label', 
            class: 'labelLeftLight' 
          },
        ]
      },
      {
        groupId: 1,
        fields: [
          {
            label: 'Start typing to provide additional information', 
            name: 'additionalInfoTextArea', 
            type: 'textarea', 
            col: 12,
            maxLength: 9000
          }
        ]
      }
    ]
  },
];

export const getForeignActivitiesTitles = () => {
  let titles = [];
  sections.forEach((section) => {
    if (!titles.includes(section.title)) {
      titles.push(section.title);
    }
  });
  return titles;
};

function ForeignActivities(props) {
	const { isExportForm } = props;
  const { updateCard, REDUX } = usePageFramework(NAV.FOREIGN_ACTIVITIES);
  const [sectionState, setSectionState] = useState('');
  const {isActiveClient, isLoading} = useLoadOrganizer();

  useEffect(() => {
    if (!isExportForm) updateCard();

    setSectionState(sections);
    // eslint-disable-next-line
  }, []);

  const axcessIdentifiers = [
    {
      section: 'Schedules',
      id: 'Sch F - Statement of Activities Outside the U.S.',
    },
  ];

  return (
    <>
    {!isLoading && isActiveClient &&<FormRendererHelper
      sections={sectionState}
      key={REDUX.FOREIGN_ACTIVITIES}
      formName={REDUX.FOREIGN_ACTIVITIES}
      identifiers={axcessIdentifiers}
      triggeredEvent={triggeredEvent}
      sectionFieldLogic={sectionFieldLogic}
      isExportForm={isExportForm}
    />}
    {!isLoading && !isActiveClient && <NoActiveClient />}
</>
  );
}

export default ForeignActivities;
