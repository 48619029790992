// common modules
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';

// custom modules
import { msalConfig, NotLoggedInError, authenticated } from '@utilities/authentication.js';

// globals
let roles = [];

// =============================================================================
// API client
// =============================================================================
const client = axios.create({
	baseURL: process.env.REACT_APP_API_BASE,
	timeout: 30000,
	headers: {
		'Accept': 'application/json',
		'Accept-Version': '2.0.0',
	}
});

// add authorization header interceptor
client.interceptors.request.use(async (config) => {
	if (!authenticated()) {
		throw new NotLoggedInError(
			'Unable to make request to CLA API; not logged in.'
		);
	}

	// add token to header
	config.headers.Authorization = `Bearer ${await getToken()}`;

	return config;
}, null);

export default client;


// =============================================================================
// scopes
// =============================================================================
// set of scopes this client may leverage
export const scopes = [
	`${process.env.REACT_APP_API_BASE_ID_URI}Forms.Read`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Forms.Write`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Forms.Manage`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Notes.Read`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Notes.Write`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Notes.Manage`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Organizers.Read`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Organizers.Write`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Organizers.Manage`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Terms.Read`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Tooltips.Read`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Groups.Read`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Groups.Write`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Groups.Manage`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Users.Read`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Users.Write`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Users.Manage`,
	`${process.env.REACT_APP_API_BASE_ID_URI}OrganizerUsers.Read`,
	`${process.env.REACT_APP_API_BASE_ID_URI}OrganizerUsers.Write`,
	`${process.env.REACT_APP_API_BASE_ID_URI}OrganizerUsers.Manage`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Clients.Read`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Clients.Write`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Clients.Manage`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Notifications.Write`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Documents.Read`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Documents.Write`,
	`${process.env.REACT_APP_API_BASE_ID_URI}Documents.Manage`,
]

// =============================================================================
// roles
// =============================================================================
// allows for initialization of roles
export const initRoles = async () => {
	if (authenticated()) {
		await getToken();
	}
}

// indicates if the access token contains any one of the specified roles
export const hasRole = (desiredRoles) => {
	return roles.some((el) => desiredRoles.indexOf(el) >= 0);
}

// =============================================================================
// helpers
// =============================================================================
export const getToken = async () => {
	const msalInstance = new PublicClientApplication(msalConfig);
	const activeAccount = msalInstance.getActiveAccount();
	const accounts = msalInstance.getAllAccounts();

	let token;
	try {
		const authResult = await msalInstance.acquireTokenSilent({
			scopes: scopes,
			account: activeAccount || accounts[0]
		});

		token = authResult.accessToken;
	} catch (error) {
		if (error instanceof InteractionRequiredAuthError) {
			const authResult = await msalInstance.acquireTokenPopup({
				scopes: scopes
			})

			token = authResult.accessToken;
		} else {
			throw error;
		}
	}

	// extract roles from token for later reference
	roles = jwtDecode(token).roles;

	return token;
}

export const getIdToken = async () => {
	const msalInstance = new PublicClientApplication(msalConfig);
	const activeAccount = msalInstance.getActiveAccount();
	const accounts = msalInstance.getAllAccounts();

	let token;
	try {
		const authResult = await msalInstance.acquireTokenSilent({
			scopes: scopes,
			account: activeAccount || accounts[0]
		});

		token = authResult.idToken;
	} catch (error) {
		if (error instanceof InteractionRequiredAuthError) {
			const authResult = await msalInstance.acquireTokenPopup({
				scopes: scopes
			})

			token = authResult.idToken;
		} else {
			throw error;
		}
	}

	// extract roles from token for later reference
	roles = jwtDecode(token).roles;

	return token;
}