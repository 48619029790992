import * as EVENT from '@utilities/constants/triggerKeys';

const showTaxExBondCounsel = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  name[0].groups[6].isVisible = event === EVENT.KEY_YES;
  setFormSections(name);
}

const showTaxExBondField = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  const result = event === EVENT.KEY_YES;
  
  name[0].groups[9].isVisible = result;
  name[0].groups[10].isVisible = result;
  name[0].groups[11].isVisible = result;
  name[0].groups[12].isVisible = result;
  name[0].groups[13].isVisible = result;
  const display = name[0].groups[8].fields[1].default === EVENT.KEY_YES && name[0].groups[11].fields[1].default === EVENT.KEY_YES && name[0].groups[12].fields[1].default === EVENT.KEY_YES && name[0].groups[12].fields[1].default === EVENT.KEY_YES;
  name[0].groups[14].isVisible = !display;
  setFormSections(name);
}

const showTaxExBondAdoption = (event, options) => {
  const { sections, formSections, setFormSections } = options;
  const name = formSections ? formSections : sections;
  const result = name[0].groups[8].fields[1].default === EVENT.KEY_YES && name[0].groups[11].fields[1].default === EVENT.KEY_YES && name[0].groups[12].fields[1].default === EVENT.KEY_YES && name[0].groups[13].fields[1].default === EVENT.KEY_YES;
  name[0].groups[14].isVisible = !result;
  setFormSections(name);
}

const showTaxExBond8038sCcAttachUpload = (event,options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
      name[0].groups[3].isVisible = !event;
     }
     
const syncAboutIssuer = (options) => {
  const { parentGroup, parentIndex, field } = options;
  const name = parentGroup.entities[parentIndex].sections;

  let issuerName = '', ein = '', cusip = '';

  name.forEach((section, index) => {
    if (index === 0) {
      section.groups.forEach(group => {
        group.fields.forEach(field1 => {
          if ((field1.name === 'taxExBondIssName' && field1.default) ||
            (field.name === 'taxExBondIssName' && field1.name === 'taxExBondIssName')) {
            issuerName = field.name === 'taxExBondIssName' ? field.default : field1.default;
          } else if ((field1.name === 'taxExBondIssEIN' && field1.default) ||
            (field.name === 'taxExBondIssEIN' && field1.name === 'taxExBondIssEIN')) {
            ein = field.name === 'taxExBondIssEIN' ? field.default : field1.default;
          } else if ((field1.name === 'taxExBondIssCUSIP' && field1.default) ||
            (field.name === 'taxExBondIssCUSIP' && field1.name === 'taxExBondIssCUSIP')) {
            cusip = field.name === 'taxExBondIssCUSIP' ? field.default : field1.default;
          }
        })
      })
    }
  });

  const firstTwoEinDigit = ein ? ein.slice(0, 2) : '';
  ein = ein ? `${firstTwoEinDigit}-${ein.slice(2)}` : '';

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'aboutIssuerSummary') {
      lineItemField.default = 'Issuer Name: ' + issuerName + '\n Issuer EIN: ' + ein + '\n CUSIP #: ' + cusip;
      lineItemField.issuerName = issuerName;
    }
  });
}

const syncIssuerDetails = (options) => {
  const { parentGroup, parentIndex, field } = options;
  const name = parentGroup.entities[parentIndex].sections;
  const dollerUSlocale = Intl.NumberFormat('en-US');
  let dateIssued = '', issuedPrice = '', desc = '';

  name.forEach((section, index) => {
    if (index === 1) {
      section.groups.forEach(group => {
        group.fields.forEach(field1 => {
          if ((field1.name === 'taxExBondIssDate' && field1.default) ||
            (field.name === 'taxExBondIssDate' && field1.name === 'taxExBondIssDate')) {
            dateIssued = field.name === 'taxExBondIssDate' ? field.default : field1.default;
          } else if ((field1.name === 'taxExBondIssPrice' && field1.default) ||
            (field.name === 'taxExBondIssPrice' && field1.name === 'taxExBondIssPrice')) {
            issuedPrice = field.name === 'taxExBondIssPrice' ? field.default : field1.default;
          } else if ((field1.name === 'taxExBondDesc' && field1.default) ||
            (field.name === 'taxExBondDesc' && field1.name === 'taxExBondDesc')) {
            desc = field.name === 'taxExBondDesc' ? field.default : field1.default;
          }
        })
      })
    }
  });

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'issueDetailsSummary') {
      issuedPrice = Number(issuedPrice) ? dollerUSlocale.format(Number(issuedPrice)) : '';
      lineItemField.default = 'Date Issued: ' + dateIssued + '\n Issued Price: $' + issuedPrice;
      lineItemField.issuedDate = dateIssued;
    } else if (lineItemField.name === 'descOfPurposeSummary') {
      lineItemField.default = desc;
    }
  });
}

const syncIssuerOtherDetails = (options) => {
  const { parentGroup, parentIndex, field } = options;
  const name = parentGroup.entities[parentIndex].sections;
  let defeased = '', bondOnBehalf = '', bondPooled = '';

  name.forEach((section, index) => {
    if (index === 2) {
      section.groups.forEach(group => {
        group.fields.forEach(field1 => {
          if ((field1.name === 'taxExBondDefease' && field1.default) ||
            (field.name === 'taxExBondDefease' && field1.name === 'taxExBondDefease')) {
            defeased = field.name === 'taxExBondDefease' ? field.default : field1.default;
          } else if ((field1.name === 'taxExBondOnBehalf' && field1.default) ||
            (field.name === 'taxExBondOnBehalf' && field1.name === 'taxExBondOnBehalf')) {
            bondOnBehalf = field.name === 'taxExBondOnBehalf' ? field.default : field1.default;
          } else if ((field1.name === 'taxExBondPooled' && field1.default) ||
            (field.name === 'taxExBondPooled' && field1.name === 'taxExBondPooled')) {
            bondPooled = field.name === 'taxExBondPooled' ? field.default : field1.default;
          }
        })
      })
    }
  });

  parentGroup.lineItems[parentIndex].forEach((lineItemField) => {
    if (lineItemField.name === 'otherDetailsSummary') {
      lineItemField.default = 'Defeased: ' + defeased +
        '\n On Behalf of Issuer: ' + bondOnBehalf +
        '\nPooled Financing: ' + bondPooled;
    }
  });
}

const calculateVariance = (options) => {
  const { parentGroup, parentIndex } = options;
  const useOfProceedsSections = parentGroup.bonds[parentIndex].sections;

  let totalOtherAmts = 0;
  const totalProceeds = useOfProceedsSections[1].groups[0].fields[1].default;
  const groups = useOfProceedsSections[1].groups.slice(1, 10);
  groups.forEach(group => {
    group.fields.forEach((field, idx) => {
      if (idx === 1) {
        if (field.default) {
          totalOtherAmts += Number(field.default);
        }
      }
    })
  })

  const strVarianceTotal = `$${Number(totalProceeds) - totalOtherAmts}`;
  const intVarianceTotal = Number(totalProceeds) - totalOtherAmts;
  parentGroup.bonds[parentIndex].sections[1].groups[10].fields[1].default = strVarianceTotal;
  parentGroup.bonds[parentIndex].sections[1].groups[10].fields[1].amount = intVarianceTotal;

  const variance = parentGroup.bonds[parentIndex].sections[1].groups[10].fields[1].amount !== 0;
  if (variance){
    parentGroup.bonds[parentIndex].sections[1].groups[10].isVisible = true;
    parentGroup.bonds[parentIndex].sections[1].groups[11].isVisible = true;
  } else {
    parentGroup.bonds[parentIndex].sections[1].groups[10].isVisible = false;
    parentGroup.bonds[parentIndex].sections[1].groups[11].isVisible = false;
  }
}

const showTaxExBondLeaseCnslRvw = (options) => {
  const { parentGroup, parentIndex } = options;
  const name = parentGroup.bonds[parentIndex].sections;
  const taxExBondLeaseArrange = name[0].groups[2].fields[1].default;

  name[0].groups[4].isVisible = taxExBondLeaseArrange ? taxExBondLeaseArrange : false;
  name[0].groups[8].isVisible = taxExBondLeaseArrange ? taxExBondLeaseArrange : false;
}

const showTaxExBondMgmtSvcCnslRvw = (options) => {
  const { parentGroup, parentIndex } = options;
  const name = parentGroup.bonds[parentIndex].sections;
  const taxExBondMgmtCntrct = name[0].groups[4].fields[1].default;

  name[0].groups[5].isVisible = taxExBondMgmtCntrct ? taxExBondMgmtCntrct : false;
  name[0].groups[9].isVisible = taxExBondMgmtCntrct ? taxExBondMgmtCntrct : false;
}

const showTaxExBondResearchCnslRvw = (options) => {
  const { parentGroup, parentIndex } = options;
  const name = parentGroup.bonds[parentIndex].sections;
  const taxExBondResearchAgr = name[0].groups[6].fields[1].default;

  name[0].groups[7].isVisible = taxExBondResearchAgr ? taxExBondResearchAgr : false;
}

const showTaxExBondPropSalePctg = (options) => {
  const { parentGroup, parentIndex } = options;
  const name = parentGroup.bonds[parentIndex].sections;
  const taxExBondPropSale = name[1].groups[5].fields[1].default;

  name[1].groups[6].isVisible = taxExBondPropSale ? taxExBondPropSale : false;
}

const showTaxExBondPcntByNonC3Calc = (options) => {
  const { parentGroup, parentIndex } = options;
  const name = parentGroup.bonds[parentIndex].sections;

  let taxExBondPcntPvtBus = name[1].groups[2].fields[1].default;
  if (taxExBondPcntPvtBus !== ' ') taxExBondPcntPvtBus = Number(taxExBondPcntPvtBus);
  else taxExBondPcntPvtBus = 0;
  if(taxExBondPcntPvtBus === 0 && name[1].groups[3].fields[1].default !== ' '){
    taxExBondPcntPvtBus = Number(name[1].groups[3].fields[1].default);
  }
  name[1].groups[7].isVisible = taxExBondPcntPvtBus > 0 ? true : false;
}
const showRebateFields = (options) => {
  const { parentGroup, parentIndex } = options;
  const name = parentGroup.bonds[parentIndex].sections;
  name[1].groups[2].isVisible = name[1].groups[3].isVisible = name[1].groups[4].isVisible = name[1].groups[5].isVisible = !(name[1].groups[1].fields[1].default);
  name[1].groups[6].isVisible = name[1].groups[7].isVisible = name[1].groups[4].isVisible && name[1].groups[4].fields[1].default;
}

const showTaxExBndArbRbtNADescr = (options) => {
  const { parentGroup, parentIndex } = options;
  const name = parentGroup.bonds[parentIndex].sections;

  let taxExBndArbForm8038T = name[1].groups[1].fields[1].default;
  let taxExBndArbRbtNotYet = name[1].groups[2].fields[1].default;
  let taxExBndArbRbtExcptn = name[1].groups[3].fields[1].default;
  let taxExBndArbRbtNotDue = name[1].groups[4].fields[1].default;


  if (taxExBndArbForm8038T || taxExBndArbRbtNotYet || taxExBndArbRbtExcptn || taxExBndArbRbtNotDue) {
    name[1].groups[5].isVisible = false;
  } else {
    name[1].groups[5].isVisible = true;
  }
}

const showTaxExBndArbRbtNotDueDateAndUpload = (options) => {
  const { parentGroup, parentIndex } = options;
  const name = parentGroup.bonds[parentIndex].sections;

  let taxExBndArbRbtNotDue = name[1].groups[4].fields[1].default;

  name[1].groups[6].isVisible = taxExBndArbRbtNotDue;
  name[1].groups[7].isVisible = taxExBndArbRbtNotDue;
}

const showAllHedgeDetailFields = (options) => {
  const { parentGroup, parentIndex } = options;
  const name = parentGroup.bonds[parentIndex].sections;

  let taxExBndArbHedge = name[2].groups[1].fields[1].default;

  name[2].groups[2].isVisible = taxExBndArbHedge;
  name[2].groups[3].isVisible = taxExBndArbHedge;
  name[2].groups[4].isVisible = taxExBndArbHedge;
  name[2].groups[5].isVisible = taxExBndArbHedge;
}

const showGrossProceedsFields = (options) => {
  const { parentGroup, parentIndex } = options;
  const name = parentGroup.bonds[parentIndex].sections;

  let taxExBndArbGIC = name[3].groups[1].fields[1].default;

  name[3].groups[2].isVisible = taxExBndArbGIC;
  name[3].groups[3].isVisible = taxExBndArbGIC;
  name[3].groups[4].isVisible = taxExBndArbGIC;
}

const triggeredEvent = (trigger, event, options) => {
  switch (trigger) {
    case 'showTaxExBondCounsel':
      showTaxExBondCounsel(event, options);
      break;
    case 'showTaxExBondField':
      showTaxExBondField(event, options);
      break;
    case 'showTaxExBondAdoption':
      showTaxExBondAdoption(event, options);
      break;
      case 'showTaxExBond8038sCcAttachUpload':
        showTaxExBond8038sCcAttachUpload(event, options);
        break;
    default:
      break;
  }
};

const sectionFieldLogic = (logicFunction, options) => {
  switch (logicFunction) {
    case 'syncAboutIssuer':
      syncAboutIssuer(options);
      break;
    case 'syncIssuerDetails':
      syncIssuerDetails(options);
      break;
    case 'syncIssuerOtherDetails':
      syncIssuerOtherDetails(options);
      break;
    case 'calculateVariance':
      calculateVariance(options);
      break;
    case 'showTaxExBondLeaseCnslRvw':
      showTaxExBondLeaseCnslRvw(options);
      break;
    case 'showTaxExBondMgmtSvcCnslRvw':
      showTaxExBondMgmtSvcCnslRvw(options);
      break;
    case 'showTaxExBondResearchCnslRvw':
      showTaxExBondResearchCnslRvw(options);
      break;
    case 'showTaxExBondPropSalePctg':
      showTaxExBondPropSalePctg(options);
      break;
    case 'showTaxExBondPcntByNonC3Calc':
      showTaxExBondPcntByNonC3Calc(options);
      break;
    case 'showTaxExBndArbRbtNADescr':
      showTaxExBndArbRbtNADescr(options);
      break;
    case 'showTaxExBndArbRbtNotDueDateAndUpload':
      showTaxExBndArbRbtNotDueDateAndUpload(options);
      break;
    case 'showAllHedgeDetailFields':
      showAllHedgeDetailFields(options);
      break;
    case 'showGrossProceedsFields':
      showGrossProceedsFields(options);
      break;
    case 'showRebateFields':
      showRebateFields(options);
      break;
    default:
      break;
  }
};

export {
  triggeredEvent,
  sectionFieldLogic
};