import React, { useState, useEffect } from 'react';
import { Grid, GridCell } from 'react-md';
import _ from 'lodash';
import usePageFramework from '@utilities/hooks/usePageFramework';
import moneyFormatter, { noDollarFormatter } from '@utilities/moneyFormatter';
import { buildField, buildTextArea, buildLabel, buildCheckbox, buildRadio, buildViewButton, buildMultiSelectDropdown } from '../buildControls';
import LineItemButtons from '../lineItemButtons/index';
import LineItemSection from '../lineItemSection/index';
// import DenseRowBtn from './denseRowBtn';
import prePopulateLineItems from '@utilities/populatePriorData/prePopulateLineItems.js';
import LineItemFooter from '../lineItemFooter/index';
import LineItemHeader from '../lineItemHeader/index';
// import TableManagerTabs from '../tableManagerTabs/index';
// import ReactPaginate from 'react-paginate';
import { updateMasterTotals } from '../../utils/updateTotals';
import { ObjectId } from 'bson';

function LineItemDetail(props) {
    const { group, 
        colspan, 
        screenSize, 
        fns, 
        isFormLocked, 
        section, 
        sectionIndex, 
        groupIndex, 
        // sections, 
        entityIndex, 
        showSharedEntityDialog,
        parentGroup,
        parentIndex,
        isDenseRowNA,
        // setParentCurrentEntity,
        renderLineItemSections,
        grandParentGroup,
        formSections
    } = props;
    const { selectState, REDUX, INPUTSTATE, dispatch, ACTION, setCardsRequired } = usePageFramework();
    const uploadList = selectState(REDUX.UPLOAD_LIST);
    const [ lineItemCtrls, setLineItemCtrls ] = useState([]);
    const [ headerDetail, setHeaderDetail ] = useState([]);
    const [ footerDetail, setFooterDetail ] = useState([]);
    // const [ menuTabsDetail, setMenuTabsDetail ] = useState(null);
    const [ paint, setPaint ] = useState(false);
    // const [footerBtns, setFooterBtns] = useState([]);
    const [footerLabels, setFooterLabels] = useState(false);
    const [summaryTotals, setSummaryTotals] = useState(null);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const isLineDisabled = isFormLocked || isDenseRowNA || section?.isEntityNa;
    const itemsPerPage = 4;
    const endOffset = itemOffset + itemsPerPage;
    //dense rows related
    const [currentEntity, setCurrentEntity] = useState(-1);
    const [currentH2, setCurrentH2] = useState('Summary');
    const [isAddingNewEntity, setIsAddingNewEntity] = useState(false);
    const hasDenseRow = section.hasDenseRow && group.lineSections?.length;
    const isDenseRow = section.isDenseRow;
    // const formHeaderContainer = section.title === 'Occupant Details' ? 'denseRowformHeaderContainer' : 
    //     isDenseRow ?  'formHeaderContainer denseRowHeaderMargin' : 'formHeaderContainer';

    const updateTableManager = () => {
        if (group.hasTableManagerTabs || group.isSummaryRow) {

            group.currentH2 = group.isH2TabsHidden ? group?.lineSections[0]?.title : currentH2;
            setCurrentH2(group.currentH2);
            group.isAddingNewEntity = isAddingNewEntity;
            if (group.hasTableManagerTabs && group.currentEntity >= 0){
                const newCurrentPage = Math.ceil((group.currentEntity + 1) / 4);
                const newOffset = (newCurrentPage - 1) * 4;
                setItemOffset(newOffset);
                setCurrentPage(newCurrentPage - 1);
            }

            if (currentH2 && (group.isSummaryRow ? group.currentEntity === 0 : group.currentEntity >= 0)) {
                if (group.isAddingNewEntity && group.currentH2 === 'expandAll') {
                    group.entities[group.currentEntity].sections.forEach((section) => {
                        section.isCollapsed = false;
                    });
                } 
                else {
                    if (group.currentH2 === 'Summary') {
                        group.entities[group.currentEntity].sections.forEach((section) => {
                            section.isCollapsed = true;
                        });
                    }
                    group.entities[group.currentEntity].sections.forEach((section) => {
                        if (section.title === group.currentH2 && section.isCollapsed) {
                            section.isCollapsed = false;
                        }
                        if (section.title !== group.currentH2 && !section.isCollapsed) {
                            section.isCollapsed = true;
                        }
                    });
                }
            }
        }

        if (group.isSummaryRow) {
            group.currentH2 = parentGroup.currentH2;
            setCurrentH2(group.currentH2);
            group.currentEntity = parentGroup.currentEntity === -2 ? 0 : -1;
            setCurrentEntity(group.currentEntity);
        }
    };
    
    // const handlePageClick = ({ selected: selectedPage }) => {
    //     const newOffset = selectedPage * itemsPerPage % group.lineItems.length;
    //     setItemOffset(newOffset);
    //     group.currentEntity = -1;
    // }

    useEffect(() => {
        createLineItemCtrl(group, colspan);
        setCurrentEntity(group.currentEntity);
        updateTableManager();
        setPageCount(Math.ceil(group.lineItems.length / itemsPerPage));
        //eslint-disable-next-line
    }, [
        group, 
        paint, 
        currentEntity, 
        currentH2, 
        isAddingNewEntity, 
        parentGroup, 
        itemOffset
    ]);

    useEffect(() => {
        setBondID();
        //eslint-disable-next-line
    }, [section.title, group.lineItems, group.entities]);

    if (formSections?.[2]?.groups?.[1]?.bonds?.length !== formSections?.[1]?.groups?.[0]?.entities?.length) {
        formSections?.[2]?.groups?.[1]?.bonds?.pop();
        formSections?.[3]?.groups?.[1]?.bonds?.pop();
        formSections?.[4]?.groups?.[1]?.bonds?.pop();
        fns.saveForm();
    }

    const buildLineButtons = (field, colspan, index, group, isLineDisabled, fn, section, parentGroup, parentIndex) => (
        <LineItemButtons 
            key={`grp-${group.groupId}-lib-${field.name}-${index}`} 
            field={field} 
            colspan={colspan} 
            group={group} 
            index={index} 
            fns={fn} 
            isFormLocked={isLineDisabled} 
            entityIndex={entityIndex} 
            section={section} 
            parentGroup={parentGroup} 
            parentIndex={parentIndex} 
            formSections={formSections}
        />
    );

    const uploadType = group.uploadType || 'single';
    const sectionName = uploadType === 'single' ? 'single' : group.lineItemDetails.lineButtons[0].uploadSectionName;

    const saveForm = (field, nextId) => {

        updateTotals(group);
        setPaint(!paint);

        if (fns) {
            fns.saveForm(field, nextId);
        }
    };
 
    const updateTotals = (group) => {
        const totals = [];

        group.lineItems.forEach((lineItem) => {
            const isNA = lineItem[0] && lineItem[0].notApplicable;
            const totalFields = lineItem.filter(x => (x.isTotal && !x.prior) || x.showToFooter);
            totalFields.forEach((fields) => {
                const total = totals.find(x => x.name === fields.name);
                // will fields.NumberValue affects anything??
                const value = fields.amount || fields.default;
                const numberValue = value ? value.replace(/[^0-9.]/g,'') : 0.0;
                const defaultValue = isNA ? 0 : Number(numberValue) || 0.0;

                if (total) {
                    total.default += defaultValue;
                } else {
                    totals.push({ name: fields.name, default: defaultValue });
                }
            });
        });

        const footerLabels = group.lineItemDetails.footerLabels;
       
        totals.forEach((total) => {
            const footer = footerLabels.find(x => x.name === total.name);
            
            if (footer) {
                footer.amount = total.default;
                footer.label = footer.type === 'totalMoney' ? moneyFormatter(Number(total.default), group.currencySymbol) : noDollarFormatter(Number(total.default));
                if (footer.logicFunction) {
                    footer.logicFunction.forEach((logic) => {
                        logic.isDifferentGroup ? fns.triggered(logic.trigger, footer.label, {}) :
                        fns.sectionFieldLogic(logic?.trigger, {group, field: footer, logic, parentGroup, parentIndex, section});
                    });
                }
            }
        });
    };

    const setBondID = () => {
        if (section.title === 'Basic Bond Information') {
            group.lineItems.forEach((item, idx) => {
                item.forEach(field => {
                    if (field.name === 'bondID') {
                        field.default = idx + 1;
                    }
                });
            });

            group.entities.forEach((entity, idx) => {
                if (entity && entity.sections) {
                    entity.sections.forEach(section => {
                        section.bondID = idx + 1;
                    });
                }
            });
        }
    }
    
    const newLineItem = (group, isAddingToLineItem) => {
        const { fields, lineItems } = group;

        if (lineItems.length === 200) {
            dispatch(ACTION.setShowCustomDialog(true));
            dispatch(ACTION.setCustomDialogTitle(''));
            dispatch(ACTION.setCustomDialogMsg('Maximum entry is 200 records. \n Please contact CLA for assistance.')); 
            return;
        }
        
        const itemFields = fields.map((field) => {
            const item = {};

            Object.keys(field).forEach((key) => item[key] = field[key]);
            item.hideLabel = screenSize === 'Desktop' ? true : false;

            setDefaultValues(item);

            return item;
        });
        
        if (isAddingToLineItem) {
            lineItems.push(itemFields);

            setBondID();

            if (group.groupType === 'lineSection') {
                fns.saveForm();
            }
        }

        if (group.lineSections) {
            const copySection = _.cloneDeep(group.lineSections);

            group.entities.push({ id: new ObjectId().toString(), sections: copySection });
            setBondID();
        }

        if (formSections?.[2]?.groups?.[1]?.bondsSections) {
            generateBond(formSections[2].groups[1], group, lineItems);
        } 
        
        if (formSections?.[3]?.groups?.[1]?.bondsSections) {
            generateBond(formSections[3].groups[1], group, lineItems);
        }

        if (formSections?.[4]?.groups?.[1]?.bondsSections) {
            generateBond(formSections[4].groups[1], group, lineItems);
        }
    
        return itemFields;
    };

    const generateBond = (bondItem, group, lineItems) => {
        const copySection = _.cloneDeep(bondItem.bondsSections);

        bondItem.bonds.push({ id: new ObjectId().toString(), sections: copySection });
        group.currentEntity = lineItems.length - 1;
        group.currentViewField = 100;
        setCurrentEntity(group.currentEntity);
        group.isAddingNewEntity = true;
        setIsAddingNewEntity(group.isAddingNewEntity);
        fns.saveForm();
    }

    const setDefaultValues = (item) => {
        if (item.name === 'totalReimbursedAndPaid') {
            item.default = `${group.currencySymbol ? group.currencySymbol : '$'}0`;
        }

        if (item.name === 'foreignIncomeOwner') {
            item.default = group.foreignIncomeOwner ? group.foreignIncomeOwner : '';
        }

        if (item.name === 'visaType') {
            item.default = group.visaType ? group.visaType : '';
        }
    };

    // const footerStyles = (group, item) => {
    //     const subSecWithYear = group.hasSubSectionTotalDisplay && !item.year;
    //     const labelsClass = !group.hasSubSectionTotalDisplay ? 
    //         'footerLabelMoney' : 
    //         subSecWithYear && item.name !== 'salePrice' ? 'businessEquipmentFooter': 
    //             subSecWithYear && item.name === 'salePrice' ? 'salePriceFooter' :
    //                 'footerSubSectionLabelMoney'; 
        
    //     if ((item.type === 'total' || item.type === 'totalMoney') && !item.align) {
    //         return labelsClass;
    //     } else if (!group.hasSubSectionTotalDisplay && item.align === 'left') {
    //         return 'footerLabelMoneyLeft';
    //     } else if (group.hasSubSectionTotalDisplay && item.align === 'left') {
    //         return 'footerSubSectionLabelMoneyLeft';
    //     }   else if ( item.type === 'label') {
    //         return 'footerLabelText';
    //     } else return '';
    // };
    
    // const addNewLineItem = (group) => {
    //     newLineItem(group, true); 
    //     setPaint(!paint); 
    //     if (group.hasTableManagerTabs) {
    //         //to expand the h2s of newly added lineItem row
    //         group.currentEntity = group.lineItems.length - 1;
    //         group.currentViewField = 100;
    //         setCurrentEntity(group.currentEntity);
    //         group.isAddingNewEntity = true;
    //         setIsAddingNewEntity(group.isAddingNewEntity);
    //     } else {
    //         // This will collapse expanded dense rows
    //         group.currentEntity = -1
    //     }
    // };

    // TODO: Fix data syncing with pagination
    const createLineItemCtrl = (group, colspan) => {
        const { lineItems } = group;
        // setPageCount(Math.ceil(lineItems.length / itemsPerPage));
        const footerClass = group.footerClass || 'formFooterLabel'; 
        const denseRowFooterMargin = isDenseRow ? 'denseRowFooterMargin' : '';

        if (group.prePopulate && group.prePopulate.length) {
            prePopulateLineItems(group);

            if (group.lineSections) {
                const copySection = _.cloneDeep(group.lineSections);
                group.prePopulate.forEach(prePop => {
                    group.entities.push({ id: new ObjectId().toString(), sections: copySection });
                });
            }

            group.prePopulate = [];
        }

        if (!lineItems.length) {
            newLineItem(group, true);
            if (group.hasTableManagerTabs) {
                //to expand the h2s of newly added lineItem row
                group.currentEntity = group.lineItems.length - 1;
                group.currentViewField = 100;
                setCurrentEntity(group.currentEntity);
                group.isAddingNewEntity = true;
                setIsAddingNewEntity(group.isAddingNewEntity);
            }
        }
        
        // this condition doesn't get the colspans of the sub sections fields
        // const isDesktop = screenSize === 'Desktop';
        const isShowHeader = group.isShowHeader !== false;
        const headerLabels = isShowHeader && group.lineItemDetails.headerLabels.map((item, index) => {
            // return <LineItemHeader key={index} item={item} width={width} index={index} />;
            return <LineItemHeader key={index} item={item} index={index} group={group}/>;
            // return null;
        }).filter(item => item);
        
        // const footerButtons = group.lineItemDetails.footerLabels
        //     .filter(footerLabel => footerLabel.type === 'buttons')
        //     .map((item, index) => {
        //         return (
        //             <Grid 
        //                 key={`btn-add-${index}`} 
        //                 className={!hasDenseRow ? 'formFooterBtn' : 'denseRowFormFooterBtn'}
        //             >
        //                 <Button 
        //                     disabled={isLineDisabled } 
        //                     className="lineItemButton" 
        //                     onClick={() => addNewLineItem(group)}
        //                 > 
        //                     <TextIconSpacing 
        //                         icon={
        //                             <FontIcon className="addIcon">
        //                                 {item.icon}
        //                             </FontIcon>
        //                         }>
        //                         {item.buttonTitle}
        //                     </TextIconSpacing>
        //                 </Button>
        //             </Grid>
        //         );
        //     });

        // setFooterBtns(footerButtons);
        // Conditions for showing footer on fields with amounts or
        // H1's/H2's with footer totals
        const moneyTypeFields = group.fields
            .filter(item => item.type === 'money' && !item.hideFooter);
        const filteredFooterLabels = group.lineItemDetails.footerLabels
            .filter(item => item.type === 'totalMoney' || item.type === 'total');
        const isShowFooter = group.isShowFooter !== false;
        // remove this condition to show footers on all forms
        // regardless if the footerlabels is empty
        const footerLabels = isShowFooter && 
        (filteredFooterLabels.length > 0  || moneyTypeFields.length > 0) &&
        <div key={`footer-grid-${group.groupId}`} 
            className={`${footerClass} ${denseRowFooterMargin} gridFooter`} 
            style={group.footerLabelsPrintStyles || {gridTemplateColumns: '7fr 2fr'}}
        >
            {
                group.lineItemDetails.footerLabels.map((item, index) => {
                    return <LineItemFooter key={index} group={group} item={item} index={index} />;
                })
            }
        </div>;
        
        setFooterLabels(footerLabels);

        if (group.hasTableManagerTabs) {
            const items = lineItems.slice(itemOffset, endOffset);
            if (!items.length && pageCount >= 1) {
                setCurrentPage(pageCount - 1);
                //need new offset to set the new current page data properly
                const newOffset = (currentPage - 1) * 4;
                setItemOffset(newOffset);
            }
        }
        
        let lineItemCount = 0;
        // const paginatedLineItems = group.hasTableManagerTabs ? lineItems.slice(itemOffset, endOffset) : lineItems;
        const items = lineItems.map((lineItem, lineIndex) => {
            const isLineItemVisible = typeof(lineItem.isVisible) === 'undefined' ? true : lineItem.isVisible;
            if (!isLineItemVisible) return (null);

            const lineItemSectionName = entityIndex ? `${entityIndex}-${lineItemCount}-${sectionName}` : `${lineItemCount}-${sectionName}`;
            let fieldCount = -1;
            const uploadDetails = lineItem[0];
            const lineItemIndex = group.hasTableManagerTabs ? lineItems.indexOf(lineItem) : lineItemCount;
            
            const fields = lineItem.map((field, index) => {
                const isVisible = typeof(field.isVisible) === 'undefined' ? true : field.isVisible;

                if(isVisible){
                    fieldCount++;
                }

                // const colType = width < 768 ? 'colPhone' : width < 1025 ? 'colTablet' : 'col';

                if (field.inputState === INPUTSTATE.PRIOR_INPUT && !field.isPriorEditable && !field.isTextFieldWithPrior) field.type = 'label';

                if (field.uniqueName) fns.setUniqueEntityFieldValue({field: field});
                
                if (!field.isPriorTriggered && field?.priorYearValue && field.logicFunction && !parentGroup?.isSummaryRow) {
                    field.logicFunction.forEach((logic) => {
                        const fieldValue = field.default ?  field.default : field.priorYearValue;
                        logic.isDifferentGroup ? fns.triggered(logic.trigger, fieldValue, null) : 
                        fns.sectionFieldLogic(logic?.trigger, {group, field, index: lineIndex, logic, parentGroup, parentIndex})
                    });
                    field.isPriorTriggered = true;
                    if (field.requiredCardsToSet) {
                        setCardsRequired(field.requiredCardsToSet, field.isSetRequiredCards);
                    }
                }

                // this condition doesn't get the colspans of the sub sections fields
                // const col = screenSize === 'Desktop' ? group.lineItemDetails.headerLabels[fieldCount].col : colspan;
                let col = group.lineItemDetails.headerLabels.length > 0 && 
                    group.lineItemDetails.headerLabels[fieldCount] ?
                    group.lineItemDetails.headerLabels[fieldCount]['col'] ? 
                        group.lineItemDetails.headerLabels[fieldCount]['col'] :
                        colspan : colspan;

                let isFieldVisible = typeof(field.isVisible) === 'undefined' ? true : field.isVisible;
                        
                // if (typeof(field.isVisible) === 'undefined') {
                //     if (width < 1025 && col != colspan) {
                //         isFieldVisible = true;
                //     } else if (width < 1025 && field.name === '') {
                //         isFieldVisible = false;
                //     } else if (width > 1025) {
                //         isFieldVisible = true;  
                //     }
                // }
                
                if (!isFieldVisible) return ('');

                let ctrl = buildField;

                switch (field.type) {
                // case 'select':
                //     ctrl = buildSelect;
                //     break;
                case 'multiSelectDropdown':
                    ctrl = buildMultiSelectDropdown;
                    break;
                case 'checkbox':
                    ctrl = buildCheckbox;
                    break;
                case 'checkboxLabel':
                    ctrl = buildCheckbox;
                    break;
                case 'radio':
                    ctrl = buildRadio;
                    break;
                case 'label':
                    ctrl = buildLabel;
                    break;
                case 'textarea':
                    ctrl = buildTextArea;
                    break;
                case 'lineButtons':
                    if (uploadType !== 'single' && uploadList) {
                        const filteredList = uploadList.filter(x => x?.section?.toLowerCase().trim() === lineItemSectionName?.toLowerCase().trim());
                        
                        if (filteredList.length) {
                            uploadDetails.uploaded = true;
                            if (!uploadDetails.drawerVisible) {
                                uploadDetails.drawerVisible = false;
                            }
                        } else {
                            uploadDetails.uploaded = false;
                            uploadDetails.drawerVisible = false;
                        }
                    }   

                    ctrl = buildLineButtons;
                    break;
                case 'viewButton':
                    ctrl = buildViewButton;
                    break;
                default:
                    ctrl = buildField;
                    break;
                }
                const visibleHeaders = group.lineItemDetails?.headerLabels?.filter(header => header.label !== '');
                col = visibleHeaders?.length > 4 && col > 2 ? 2 : col;
                return (
                    <React.Fragment key={`line-item-cell-wrapper-key-${index}`}>
                        <GridCell 
                            colSpan={field.printCol ?? col} 
                            className={group.class === 'aboutYouFieldsRow' ? 'aboutYouLineRowField' : 'lineRowField'}
                            style={field.printStyles ? field.printStyles : group.hasTableManagerTabs ? {zoom: '130%'} : group.entityPlaceHolder ? {zoom: '120%'} : null}
                        >
                            {ctrl(field, col, lineItemIndex, group, isLineDisabled, { saveForm, triggered:fns.triggered, screenSize, showSharedEntityDialog, sectionFieldLogic: fns.sectionFieldLogic }, section, parentGroup, parentIndex, grandParentGroup)}
                        </GridCell>
                    </React.Fragment>
                );
            });
    
            lineItemCount++;

            if (group.lineSections && group.entities.length < lineItemIndex) {
                const copySection = _.cloneDeep(group.lineSections);

                const identifiers = !group.subWorkSheets ? null : { identifiers: group.subWorkSheets.reduce((prev, item) => {
                    if (item && item.length >= lineItemIndex-1) {
                        prev.push(item[lineItemIndex-1] || null);
                    } else {
                        prev.push(null);
                    }

                    return prev;
                },[] ).filter(x => x)};

                copySection.forEach(x => x.lineItem = lineItemIndex); 

                console.log(copySection);

                group.entities.push({ id: new ObjectId().toString(), sections: copySection, identifiers });
            }
            
            // const expandCurrentLineItem = group.isSummaryRow ? parentGroup.currentEntity === -2 : group.currentEntity === lineItemIndex;
            const lineSections = group.lineSections && !group.isSummaryRow? 
                renderLineItemSections(
                    lineItemIndex,
                    group,
                    fns,
                    sectionIndex,
                    groupIndex,
                    lineItemIndex,
                    parentGroup,
                    setIsAddingNewEntity
                ) : null;

            const isHouseholdEmployment = group.class === 'householdEmploymentUpload';
            
            const renderFields = () => {
                return (
                    <>
                        {
                            isHouseholdEmployment ? 
                                <GridCell 
                                    colSpan={12} 
                                    className={isHouseholdEmployment && 'householdEmploymentUpload'}
                                >
                                    {fields}
                                </GridCell> : fields
                        }
                         { 
                            // group.currentEntity === lineIndex && 
                            <GridCell colSpan={12} style={hasDenseRow ? {margin: '41px 0 -40px 0'} : {}}>
                                <div className={'lineItemSectionContainer' + (lineSections && section.title === 'Passthrough Information' ? ' mbZero' : '')} >
                                    {lineSections}
                                </div>
                            </GridCell>
                        }
                    </>
                )
            }
            const isLineUpload = group.uploadType === 'drawer';
            const renderRow = () => {
                return (
                    <Grid 
                        className={!isLineUpload ? !hasDenseRow ? 'lineItemRow' : 'denseLineItemRow' : 'uploadItemRow'}
                        style={(group.isLastItem || isHouseholdEmployment) ? {marginBottom: '22px'} : null}
                        key={`line-item-row-key-${group.groupId}-${lineIndex}`}
                        id={`line-item-row-id-${group.groupId}-${lineIndex}`}
                    >
                        {
                            !hasDenseRow ? 
                            renderFields() 
                            :
                            <GridCell colSpan={12} id={`denseLineitem-${currentEntity}`} className="denseRowContainer" style={group.isSummaryRow && currentEntity !== lineIndex  ? {margin: '0 22px'} : null}> 
                                {/* {group.isSummaryRow && !parentGroup.entities.length ? null : <DenseRowBtn group={group} index={lineItemIndex} setCurrentEntity={setCurrentEntity} parentGroup={parentGroup} setParentCurrentEntity={setParentCurrentEntity}/>} */}
                                <Grid className="step">
                                    {renderFields()}
                                </Grid>
                                {/* {
                                    group.currentEntity !== -1 || (group.isSummaryRow && parentGroup?.currentEntity === -2) ?
                                    <div 
                                        className={
                                            group.with990DenseRows ? 'denseRow990ExpandConnectorLine' : 
                                            'denseRowExpandConnectorLine'
                                        }
                                    > 
                                    </div> : null
                                } */}
                            </GridCell>
                        }
                        {/* {
                            !group.isSummaryRow && (group.hasDivider || lineItems.length > 1 || footerButtons.length > 0) ? 
                                <GridCell colSpan={12}>
                                    <Divider className={group.class === 'aboutYouFieldsRow' || group.groupType === 'lineSection' ? null : 'lineItemRowDivider'}/>
                                </GridCell> : null
                        }      */}
                    </Grid>
                );
            }
            
            return (
                <React.Fragment key={`line-item-row-key-${group.groupId}-${lineIndex}`}> 
                    {
                        group.isSummaryRow && parentGroup.currentEntity !== -2 ? 
                        <div 
                            className='totalsRowTemporary'
                        >
                            {renderRow()}
                        </div> : renderRow()
                    }
                </React.Fragment>
            );
        });
        
        const header = !headerLabels || headerLabels.length < 1 || group.isSummaryRow ? null : (
            <div 
                key={`header-${group.groupId}`} 
                className={'orgExportTableHeader'}
                style={group.headerLabelsPrintStyles || {gridTemplateColumns: '3fr 1fr 1fr 1fr'}}
            >
                {headerLabels}
            </div>
        );
        // const footer = !footerLabels ? null : (<Grid key={`footer-${group.groupId}`}>{footerLabels}</Grid>);
        const footer = !footerLabels || group.isSummaryRow ? null : 
            <>
                {/* {footerButtons} */}
                {footerLabels}
            </>;

        // const menuTabs = !group.hasTableManagerTabs ? null : <TableManagerTabs key={`table-manager-tab-${group.groupId}`} group={group} 
        //     isFormLocked={isFormLocked} fns={fns} section={section} setCurrentEntity={setCurrentEntity} setCurrentH2={setCurrentH2} 
        //     setIsAddingNewEntity={setIsAddingNewEntity} addNewLineItem={addNewLineItem}/>

        const summaryTotalRow = () => {

            if (!group.hasTableManagerTabs || group.isMasterSummaryHidden) return null;
            
            const copySection =  _.cloneDeep(group.lineSections);
            
            const avgHoursSections = group.entities?.map((entity) => {
                 return entity.sections?.find(section => section.title === 'Average Hours/Week')
            });

            const aboutIndividualSections = group.entities?.map((entity) => {
                return entity.sections?.find(section => section.title === 'About Individual')
            });

            const avgHoursSummaryGroups = avgHoursSections.map((summarySection, index) => {
                if (summarySection.groups?.find(secGroup => secGroup.fields?.find(field => field.isVisible))) {
                    summarySection.groups.forEach(group => group.parentEntityIndex = index);
                    return summarySection.groups;
                }
                return null;
            });

            const aboutIndividualSummaryGroups = aboutIndividualSections.map((summarySection, index) => {
                summarySection.groups.forEach(group => group.parentEntityIndex = index);
                return summarySection.groups;
            });
            
            const compesationDetailsSections = group.entities?.map(entity => 
                entity.sections
                    ?.find(section => section.title === 'Compensation Details')
            ); 

            const compesationDetailsSummaryGroups = compesationDetailsSections.map((summarySection, index) => {
                summarySection.groups.forEach(group => group.parentEntityIndex = index);
                return summarySection.groups;
            });

             const benefitsDetailsSections = group.entities?.map(entity => 
                entity.sections
                    ?.find(section => section.title === 'Benefits Details')
            ); 

            const benefitsDetailsSummaryGroups = benefitsDetailsSections.map((summarySection, index) => {
                summarySection.groups.forEach(group => group.parentEntityIndex = index);
                return summarySection.groups;
            });
            
             const fiscalYearDetailsSections = group.entities?.map(entity => 
                entity.sections
                    ?.find(section => section.title === 'Fiscal Year Details')
            ); 

            const fiscalYearDetailsSummaryGroups = fiscalYearDetailsSections.map((summarySection, index) => {
                summarySection.groups.forEach(group => group.parentEntityIndex = index);
                return summarySection.groups;
            });
            
            if (!group.summaryList) {
                group.summaryList = { sections: copySection };
            }

            const compensationSummaryFields = compesationDetailsSummaryGroups
                .flatMap(groups => groups.map(group => group.isVisible && group.fields));

            const benefitsDetailsSummaryFields = benefitsDetailsSummaryGroups
                .flatMap(groups => groups.map(group => group.isVisible && group.fields));
            
            const fiscalYearSummaryFields = fiscalYearDetailsSummaryGroups
                .flatMap(groups => groups.map(group => group.isVisible && group.fields));

            group.summaryList.sections[0].groups = [{
                groupId: 1,
                groupType: 'groupItem',
                groupItems: aboutIndividualSummaryGroups,
                groupItemsLength: aboutIndividualSummaryGroups.length
            }];
            group.summaryList.sections[1].groups = [{
                groupId: 1,
                groupType: 'groupItem',
                groupItems: avgHoursSummaryGroups,
                groupItemsLength: avgHoursSummaryGroups.filter(group => group).length
            }];

            group.summaryList.sections[2].groups = [{
                groupId: 1,
                groupType: 'groupItem',
                groupItems: compesationDetailsSummaryGroups,
                groupItemsLength: compesationDetailsSummaryGroups
                    .flat()
                    .filter(group => group.isVisible).length
            }];

            group.summaryList.sections[3].groups = [{
                groupId: 1,
                groupType: 'groupItem',
                groupItems: benefitsDetailsSummaryGroups,
                groupItemsLength: benefitsDetailsSummaryGroups
                    .flat()
                    .filter(group => group.isVisible).length
            }];

            group.summaryList.sections[4].groups = [{
                groupId: 1,
                groupType: 'groupItem',
                groupItems: fiscalYearDetailsSummaryGroups,
                groupItemsLength: fiscalYearDetailsSummaryGroups
                    .flat()
                    .filter(group => group.isVisible).length
            }];
        
            const copyGroup = _.cloneDeep(group);
            copyGroup.hasTableManagerTabs = false;
            copyGroup.hasSaveCancelButtons = false;
            copyGroup.currentEntity = -1;
            copyGroup.isSummaryRow = true;
            copyGroup.entities = [group.summaryList];

            const summaryFields = { 
                compensationSummaryFields, 
                benefitsDetailsSummaryFields, 
                fiscalYearSummaryFields
            };
            const fields = updateMasterTotals(copyGroup, summaryFields);
            copyGroup.lineItems = [fields];
            copyGroup.lineItemDetails.lineButtons = [];
            
            return ( group.hasTableManagerTabs && !group.isSummaryRow ?
                <LineItemDetail 
                    key={`lid-${section.sectionId}-${-1}`} 
                    group={copyGroup} 
                    colspan={colspan} 
                    screenSize={screenSize} 
                    fns={fns} 
                    isFormLocked={isFormLocked} 
                    section={section} 
                    sectionIndex={sectionIndex} 
                    groupIndex={groupIndex} 
                    entityIndex={-1} 
                    showSharedEntityDialog={showSharedEntityDialog}
                    parentGroup={group}
                    parentIndex={parentIndex}
                    isDenseRowNA={isDenseRowNA}
                    setParentCurrentEntity={setCurrentEntity}
                    renderLineItemSections={(
                        lineItemCount,
                        group,
                        fns,
                        sectionIndex,
                        groupIndex,
                        lineIndex,
                        parentGroup
                    ) => (
                        <LineItemSection 
                            id={'lis-summary-row'} 
                            key={'lis-summary-row'} 
                            hasLineItemSection={true} 
                            entities={group.summaryList}
                            parentFns={fns} 
                            parentGroup={group} 
                            sectionIndex={sectionIndex} 
                            groupItemIndex={0} 
                            parentIndex={lineIndex} 
                            grandParentGroup={parentGroup}
                            isFormLocked={isFormLocked}
                        />
                    )}
                /> : null
            );
        };

        setLineItemCtrls(items);
        setHeaderDetail(header);
        setFooterDetail(footer);
        // setMenuTabsDetail(menuTabs);
        setSummaryTotals(summaryTotalRow);
    };
    
    return (
        <>
            {/* {menuTabsDetail} */}
            {summaryTotals}
            {headerDetail}
            <Grid 
                className={
                    !hasDenseRow ? 
                    "fieldsContainer" : "expandableFieldsContainer"
                }
                style={
                    footerLabels ? 
                    { marginBottom: '20px' } : null
                }
            >
                {lineItemCtrls}
            </Grid>
            {footerDetail}
            {/* <div className={group.hasTableManagerTabs && group.lineItems.length > itemsPerPage ? 'footerPaginationContainer' : null}>
                {footerDetail}
                { group.hasTableManagerTabs && group.lineItems.length > itemsPerPage  ? 
                    <Grid>
                        <GridCell colSpan={12}>
                            <ReactPaginate
                                previousLabel='<'
                                nextLabel='>'
                                pageCount={pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName='pagination'
                                activeClassName='paginationActivePage'
                                breakLabel='...'
                                breakClassName='page-item'
                                breakLinkClassName='page-link'
                                renderOnZeroPageCount={null}
                                forcePage={currentPage}
                            />
                        </GridCell>
                    </Grid>
                : <></>}
            </div> */}
        </>
    );
}

export default LineItemDetail;