import PropTypes from 'prop-types';
import { GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const CustomToolbar = ({ setFilterButtonEl, filterHighlight }) => (
  <GridToolbarContainer data-testid='custom-toolbar-container'>
    <GridToolbarFilterButton
      data-testid='custom-toolbar-button'
      ref={setFilterButtonEl}
      componentsProps={{
        button: {
          startIcon: (
            <FilterAltIcon className={filterHighlight} data-testid='custom-toolbar-icon' />
          )
        }
      }}
    />
  </GridToolbarContainer>
);

CustomToolbar.propTypes = {
  setFilterButtonEl: PropTypes.func.isRequired,
};

export default CustomToolbar;