import * as EVENT from '@utilities/constants/triggerKeys';
import { INTERESTED_PERSONS_TRANSACTIONS } from '@utilities/constants/redux';

const showActionFields = (event, options) => {
    const { formSections, setFormSections } = options;
    const name = formSections;
    const hasAddAction = event === EVENT.KEY_ADD || name[2].groups[20].lineItems.some(lineItem => lineItem[2].default === EVENT.KEY_ADD);
    const hasSubstituteAction = event === EVENT.KEY_SUBSTITUTE || name[2].groups[20].lineItems.some(lineItem => lineItem[2].default === EVENT.KEY_SUBSTITUTE);

    name[2].groups[21].isVisible = hasAddAction || hasSubstituteAction;
    name[2].groups[22].isVisible = hasSubstituteAction;

    setFormSections(name);
};

const showProgramRevenue = (event, options) => {
    const { formSections, setFormSections } = options;
    const name = formSections;

    name[1].groups[7].isVisible = true;

    const calculateRevenue = (event * .01);
    const dollerUSlocale = Intl.NumberFormat('en-US');
    const calculateRevenueDollar = `$${dollerUSlocale.format(calculateRevenue.toFixed())}`;


    if (calculateRevenue > 5000) {
        name[1].groups[7].fields[0].label = `Did the organization receive any payments from a payee of program revenue greater than ${calculateRevenueDollar}?`;
        name[1].groups[7].fields[0].default = `Did the organization receive any payments from a payee of program revenue greater than ${calculateRevenueDollar}?`;
    } else {
        name[1].groups[7].fields[0].label = `Did the organization receive any payments from a payee of program revenue greater than $5000?`;
        name[1].groups[7].fields[0].default = `Did the organization receive any payments from a payee of program revenue greater than $5000?`;
    }

    setFormSections(name);
};

const showPayeeListings = (event, options) =>{
    const {formSections, setFormSections} = options;
    const name = formSections;
 
    name[1].groups[8].isVisible = event === EVENT.KEY_YES;
    name[1].groups[7].hasDivider = event === EVENT.KEY_YES;
    name[1].groups[9].isVisible = event === EVENT.KEY_YES;
 
    setFormSections(name);
};

const showPubChrPayDqTable = (event, options) => {
    const { sections, formSections, setFormSections } = options;
    const name = formSections ? formSections : sections;

    const listOne = name[1].groups[1].fields[1].default;
    const listTwo = name[1].groups[2].fields[1].default;
    const listThree = name[1].groups[3].fields[1].default;
    const showContributorList = listOne || listTwo || listThree;

    if (event) {
        name[1].groups[4].isVisible = event;
        name[1].groups[5].isVisible = event;
    } else {
        name[1].groups[4].isVisible = !!showContributorList;
        name[1].groups[5].isVisible = !!showContributorList;
    }

    setFormSections(name);
}

const showPubCharAddInfo = (event, options) => {
    const { sections, formSections } = options;
    const name = formSections ? formSections : sections;
    if (name.length === 5) {
        const isAnySectionVisible = name.filter(sec => sec.isVisible);
        name[4].isVisible = isAnySectionVisible?.length;
    }
};

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        case 'showActionFields':
            showActionFields(event, options);
            break;
        case 'showProgramRevenue':
            showProgramRevenue(event,options);
            break;
        case 'showPayeeListings':
            showPayeeListings(event, options);
            break;
        case 'showPubChrPayDqTable':
            showPubChrPayDqTable(event, options);
            break;
        case 'showPubCharAddInfo':
            showPubCharAddInfo(event, options);
            break;
        default:
            break;
    }
};

const showPubChrSupOrgNameGovDocDesc = (options) => {
    const { section, field } = options;
    section.groups[3].isVisible = field.default === EVENT.KEY_NO;
};

const showPubChrSupOrgNoIrsDtrmExp = (options) => {
    const { section, field } = options;
    section.groups[5].isVisible = field.default === EVENT.KEY_YES;
};

const showPubChrSupOrgC456Fields = (options) => {
    const { section, field } = options;
    const isPubChrSupOrgC456Confirm = section.groups[7].fields[1].default === EVENT.KEY_YES;
    const isPubChrSupOrgC456ConfirmNo = section.groups[7].fields[1].default === EVENT.KEY_NO;

    const isPubChrSupOrgExcls170c2b = section.groups[10].fields[1].default === EVENT.KEY_YES;
    const isPubChrSupOrgExcls170c2bmNo = section.groups[10].fields[1].default === EVENT.KEY_NO;

    section.groups[7].isVisible = field.default === EVENT.KEY_YES;
    section.groups[8].isVisible = isPubChrSupOrgC456Confirm && field.default === EVENT.KEY_YES;
    section.groups[9].isVisible = isPubChrSupOrgC456ConfirmNo && field.default === EVENT.KEY_YES;
    section.groups[10].isVisible = field.default === EVENT.KEY_YES;
    section.groups[11].isVisible = isPubChrSupOrgExcls170c2b && field.default === EVENT.KEY_YES;
    section.groups[12].isVisible = isPubChrSupOrgExcls170c2bmNo && field.default === EVENT.KEY_YES;
};

const showPubChrSupOrgC456ConfirmFields = (options) => {
    const { section, field } = options;
    section.groups[8].isVisible = field.default === EVENT.KEY_YES;
    section.groups[9].isVisible = field.default === EVENT.KEY_NO;
    section.groups[7].hasDivider = field.default === EVENT.KEY_YES;
};

const showPubChrSupOrgExcls170c2bFields = (options) => {
    const { section, field } = options;
    section.groups[11].isVisible = field.default === EVENT.KEY_YES;
    section.groups[12].isVisible = field.default === EVENT.KEY_NO;
    section.groups[10].hasDivider = field.default === EVENT.KEY_YES;
};

const showPubChrSupOrgNonUSFields = (options) => { 
    const { section, field, logic } = options;

    const isPubChrSupOrgNonUSGrntCtrlDesc = section.groups[14].fields[1].default === EVENT.KEY_YES;
    const isPubChrSupOrgNonUSnoIRSDesc = section.groups[16].fields[1].default === EVENT.KEY_YES;
    const basicDataForm = logic.triggerRelatedForms?.basicData;
    const isTypeIorII = (basicDataForm[0]?.groups[11].fields[1].value === EVENT.EVENT_KEY_TAX_EXMP_1 || basicDataForm[0]?.groups[11].fields[1].value === EVENT.EVENT_KEY_TAX_EXMP_2)
        && (basicDataForm[0]?.groups[12].fields[1].value === EVENT.KEY_509A3I || basicDataForm[0]?.groups[12].fields[1].value === EVENT.KEY_509A3II);

    section.groups[14].isVisible = field.default === EVENT.KEY_YES;
    section.groups[15].isVisible = isPubChrSupOrgNonUSGrntCtrlDesc && field.default === EVENT.KEY_YES && isTypeIorII;
    section.groups[16].isVisible = field.default === EVENT.KEY_YES && isTypeIorII;
    section.groups[17].isVisible = isPubChrSupOrgNonUSnoIRSDesc && field.default === EVENT.KEY_YES && isTypeIorII;
};

const showPubChrSupOrgNonUSGrntCtrlDesc = (options) => {
    const { section, field, logic } = options;
    const basicDataForm = logic.triggerRelatedForms?.basicData;
    const isTypeIorII = (basicDataForm[0]?.groups[11].fields[1].value === EVENT.EVENT_KEY_TAX_EXMP_1 || basicDataForm[0]?.groups[11].fields[1].value === EVENT.EVENT_KEY_TAX_EXMP_2)
        && (basicDataForm[0]?.groups[12].fields[1].value === EVENT.KEY_509A3I || basicDataForm[0]?.groups[12].fields[1].value === EVENT.KEY_509A3II);

    section.groups[15].isVisible = field.default === EVENT.KEY_YES && isTypeIorII;
};

const showPubChrSupOrgNonUSnoIRSDesc = (options) => {
    const { section, field, logic } = options;

    const ispubChrSupOrgNonUSYes = section.groups[13].fields[1].default === EVENT.KEY_YES;
    const basicDataForm = logic.triggerRelatedForms?.basicData;
    const isTypeIorII = (basicDataForm[0]?.groups[11].fields[1].value === EVENT.EVENT_KEY_TAX_EXMP_1 || basicDataForm[0]?.groups[11].fields[1].value === EVENT.EVENT_KEY_TAX_EXMP_2)
        && (basicDataForm[0]?.groups[12].fields[1].value === EVENT.KEY_509A3I || basicDataForm[0]?.groups[12].fields[1].value === EVENT.KEY_509A3II);

    section.groups[17].isVisible = field.default === EVENT.KEY_YES && ispubChrSupOrgNonUSYes && isTypeIorII;
};

const showPubChrSupOrgAdd = (options) => {
    const { section, field } = options;
    const hasAddAction = section.groups[20].lineItems.some(lineItem => lineItem[2].default === EVENT.KEY_ADD);
    const hasSubstituteAction = section.groups[20].lineItems.some(lineItem => lineItem[2].default === EVENT.KEY_SUBSTITUTE);

    section.groups[19].isVisible = field.default === EVENT.KEY_YES;
    section.groups[20].isVisible = field.default === EVENT.KEY_YES;
    section.groups[21].isVisible = field.default === EVENT.KEY_YES && (hasAddAction || hasSubstituteAction);
    section.groups[22].isVisible = field.default === EVENT.KEY_YES && hasSubstituteAction;
};

const showpubChrSupOrgPrvdSupDtl = (options) => {
    const { section } = options;
    const isPubChrSupOrgPrvdSupA = section.groups[24].fields[1].default === EVENT.KEY_YES;
    const isPubChrSupOrgPrvdSupB = section.groups[25].fields[1].default === EVENT.KEY_YES;
    const isPubChrSupOrgPrvdSupC = section.groups[26].fields[1].default === EVENT.KEY_YES;
    section.groups[27].isVisible = isPubChrSupOrgPrvdSupA || isPubChrSupOrgPrvdSupB || isPubChrSupOrgPrvdSupC;
};

const showPubChrSupOrgDqCtrlIntExp = (options) => {
    const { section, field } = options;
    section.groups[32].isVisible = field.default === EVENT.KEY_YES;
};

const showPubChrSupOrgDqOwnExp = (options) => {
    const { section, field } = options;
    section.groups[34].isVisible = field.default === EVENT.KEY_YES;
};

const showTextThirteen = (options) => {
    const { section } = options;
    section.groups[35].fields[2].isVisible = section.groups[35].fields[1].default === EVENT.KEY_YES;
};

const showPubChrSupOrgDqCtrlExp = (options) => {
    const { section, field } = options;
    section.groups[30].isVisible = field.default === EVENT.KEY_YES;
};

const showPubChrSupOrgGiftDtl = (options) => {
    const { section } = options;
    const ispubChrSupOrgGiftODK = section.groups[37].fields[1].default === EVENT.KEY_YES;
    const ispubChrSupOrgGiftODKFamMbr = section.groups[38].fields[1].default === EVENT.KEY_YES;
    const ispubChrSupOrgGift35 = section.groups[39].fields[1].default === EVENT.KEY_YES;
    section.groups[40].isVisible = ispubChrSupOrgGiftODK || ispubChrSupOrgGiftODKFamMbr || ispubChrSupOrgGift35;
};

const showPubChrSupOrgPwrApptDesc = (options) => {
    const { section, field } = options;
    section.groups[1].isVisible = field.default === EVENT.KEY_NO;
};

const showPubChrSupOrgOpBenExpl = (options) => {
    const { section, field } = options;
    section.groups[3].isVisible = field.default === EVENT.KEY_YES;
};

const showPubChrSupOrgMjrBothDesc  = (options) => {
    const { section, field } = options;
    section.groups[5].isVisible = field.default === EVENT.KEY_NO;
};

const showTextSixteen = (options) => {
    const { section } = options;
    const isPubChrSupOrgWtrnNtc = section.groups[7].fields[1].default === EVENT.KEY_NO;
    const isPubChrSupOrgCopy990 = section.groups[8].fields[1].default === EVENT.KEY_NO;
    const isPubChrSupOrgCopyGovDoc = section.groups[9].fields[1].default === EVENT.KEY_NO;
    section.groups[10].isVisible = isPubChrSupOrgWtrnNtc || isPubChrSupOrgCopy990 || isPubChrSupOrgCopyGovDoc;
};

const showPubChrSupOrgOdtSrvGovBdyExp = (options) => {
    const { section, field } = options;
    section.groups[12].isVisible = field.default === EVENT.KEY_NO;
};

const showPubChrSupOrgSignfVoiceDesc = (options) => {
    const { section, field } = options;
    section.groups[14].isVisible = field.default === EVENT.KEY_YES;
};

const showpPubChrSupOrgSubstExPurp  = (options) => {
    const { section, field } = options;
    const isPubChrSupOrgSubstExPurp = section.groups[17].fields[1].default === EVENT.KEY_YES;
    const isPubChrSupOrgConstInv = section.groups[19].fields[1].default === EVENT.KEY_YES;
    section.groups[17].isVisible = field.default;
    section.groups[18].isVisible = field.default && isPubChrSupOrgSubstExPurp;
    section.groups[19].isVisible = field.default;
    section.groups[20].isVisible = field.default && isPubChrSupOrgConstInv;
    
};

const showPubChrSupOrgConstInvExp = (options) => {
    const { section, field } = options;
    section.groups[20].isVisible = field.default=== EVENT.KEY_YES;
};

const showPubChrSupOrgPwrAptODTdesc =  (options) => {
    const { section, field } = options;
    section.groups[23].isVisible = field.default=== EVENT.KEY_YES;
};

const showPubChrSupOrgIdExp = (options) => {
    const { section, field } = options;
    section.groups[18].isVisible = field.default === EVENT.KEY_YES;
};

const showPubChrSupOrgPwrAptODT = (options) => {
    const { section, field } = options;
    const isPubChrSupOrgPwrAptODT = section.groups[22].fields[1].default === EVENT.KEY_YES;
    const isPubChrSupOrgDirPlcyPrgrm = section.groups[24].fields[1].default === EVENT.KEY_YES;
    section.groups[22].isVisible = field.default;
    section.groups[24].isVisible = field.default;
    section.groups[23].isVisible = field.default && isPubChrSupOrgPwrAptODT;
    section.groups[25].isVisible = field.default && isPubChrSupOrgDirPlcyPrgrm;
};

const showPubChrSupOrgGovEntDescDir = (options) => {
    const { section, field } = options;
    section.groups[27].isVisible = field.default;
};
const showPubChrSupOrgDirPlcyPrgrmDesc =  (options) => {
    const { section, field } = options;
    section.groups[25].isVisible = field.default === EVENT.KEY_YES;
};

const setCardRequired = (options) => {
    const { field, logic } = options;
    
    if (field.default === EVENT.KEY_YES) {
        field.requiredCardsToSet = logic.requiredCards;
        field.isSetRequiredCards = true;
    } else {
        field.requiredCardsToSet = logic.requiredCards;
        field.isSetRequiredCards = false;
    }
};

const showExcessBenefitsTransactions = (options) => {
    const { field, logic } = options;

    const pubChrSupOrgGrnt = field.default === EVENT.KEY_YES;
    const interestedPersonsForm = logic.triggerRelatedForms?.interestedPersons;

    if (interestedPersonsForm) {
        interestedPersonsForm[0].groups[0].pubChrSupOrgGrntVal = pubChrSupOrgGrnt;
        const reqSchdChkLiA = interestedPersonsForm[0].groups[0].fields[1].value;
        const reqSchdChkLiB = interestedPersonsForm[0].groups[1].fields[1].value;

        const reqSchdChkLiAVal = pubChrSupOrgGrnt || 
            (pubChrSupOrgGrnt && reqSchdChkLiA === EVENT.KEY_NO) || 
            (!pubChrSupOrgGrnt && reqSchdChkLiA === EVENT.KEY_YES);
        const reqSchdChkLiBVal = pubChrSupOrgGrnt || 
            (pubChrSupOrgGrnt && reqSchdChkLiB === EVENT.KEY_NO) || 
            (!pubChrSupOrgGrnt && reqSchdChkLiB === EVENT.KEY_YES);
            
        interestedPersonsForm[1].isVisible = reqSchdChkLiAVal || reqSchdChkLiBVal;
    }

    logic.setGlobalFormState(INTERESTED_PERSONS_TRANSACTIONS, interestedPersonsForm);
}

const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        case 'showPubChrSupOrgNameGovDocDesc':
          showPubChrSupOrgNameGovDocDesc(options);
            break;
        case 'showPubChrSupOrgNoIrsDtrmExp':
          showPubChrSupOrgNoIrsDtrmExp(options);
            break;
        case 'showPubChrSupOrgC456Fields':
            showPubChrSupOrgC456Fields(options);
            break;
        case 'showPubChrSupOrgC456ConfirmFields':
            showPubChrSupOrgC456ConfirmFields(options);
            break;
        case 'showPubChrSupOrgExcls170c2bFields':
            showPubChrSupOrgExcls170c2bFields(options);
            break;
        case 'showPubChrSupOrgNonUSFields':
            showPubChrSupOrgNonUSFields(options);
            break;
        case 'showPubChrSupOrgNonUSGrntCtrlDesc':
            showPubChrSupOrgNonUSGrntCtrlDesc(options);
            break;
        case 'showPubChrSupOrgNonUSnoIRSDesc':
            showPubChrSupOrgNonUSnoIRSDesc(options);
            break;
        case 'showpubChrSupOrgPrvdSupDtl':
            showpubChrSupOrgPrvdSupDtl(options);
            break;
        case 'showPubChrSupOrgAdd':
            showPubChrSupOrgAdd(options);
            break;
        case 'showPubChrSupOrgDqCtrlIntExp':
            showPubChrSupOrgDqCtrlIntExp(options);
            break;
        case 'showPubChrSupOrgDqOwnExp':
            showPubChrSupOrgDqOwnExp(options);
            break;
        case 'showPubChrSupOrgGiftDtl':
            showPubChrSupOrgGiftDtl(options);
            break;
        case 'showPubChrSupOrgDqCtrlExp':
            showPubChrSupOrgDqCtrlExp(options);
            break;
        case 'showTextThirteen':
            showTextThirteen(options);
            break;
        case 'showPubChrSupOrgPwrApptDesc':
            showPubChrSupOrgPwrApptDesc(options);
            break;
        case 'showPubChrSupOrgOpBenExpl':
            showPubChrSupOrgOpBenExpl(options);
            break;
        case 'showPubChrSupOrgMjrBothDesc':
            showPubChrSupOrgMjrBothDesc(options);
            break;
        case 'showTextSixteen':
            showTextSixteen(options);
            break;
        case 'showPubChrSupOrgPwrAptODT':
            showPubChrSupOrgPwrAptODT(options);
            break;
        case 'showPubChrSupOrgOdtSrvGovBdyExp':
            showPubChrSupOrgOdtSrvGovBdyExp(options);
            break;
        case 'showPubChrSupOrgSignfVoiceDesc':
            showPubChrSupOrgSignfVoiceDesc(options);
            break;
        case 'showPubChrSupOrgIdExp':
            showPubChrSupOrgIdExp(options);
            break;
        case 'showpPubChrSupOrgSubstExPurp':
            showpPubChrSupOrgSubstExPurp(options);
            break;
        case 'showPubChrSupOrgGovEntDescDir':
            showPubChrSupOrgGovEntDescDir(options);
            break;
        case 'showPubChrSupOrgDirPlcyPrgrmDesc':
            showPubChrSupOrgDirPlcyPrgrmDesc(options);
            break;
        case 'showPubChrSupOrgConstInvExp':
            showPubChrSupOrgConstInvExp(options);
            break;
        case 'showPubChrSupOrgPwrAptODTdesc':
            showPubChrSupOrgPwrAptODTdesc(options);
            break;
        case 'setCardRequired':
            setCardRequired(options);
            break;
        case 'showExcessBenefitsTransactions':
            showExcessBenefitsTransactions(options);
            break;
        default:
            break;
        }

    }

export {
    triggeredEvent,
    sectionFieldLogic
};