import React, { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { Validators } from 'cla-formrenderer';
import {
  triggeredEvent,
  sectionFieldLogic
} from './liquidationAndDispositionLogicTriggers';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';


const assetExpenseSaleExDetails = [
  {
    title: 'Asset Expense Details',
    isVisible: true,
    sectionId: 1,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Description',
            name: 'assetSaleDescLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'assetSaleDesc',
            type: 'textarea',
            col: 12,
            maxRows: 3,
            maxLength: 1000,
            class: 'fieldTextArea'
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Recipient EIN',
            name: 'assetSaleEINLabel',
            type: 'label',
            col: 9
          },
          { label: 'Recipient EIN', name: 'assetSaleEIN', type: 'ein', col: 3, validations: { ...Validators.ein() }          
        }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Recipient Name',
            name: 'assetSaleNameLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Recipient Name',
            name: 'assetSaleName',
            type: 'freeText',
            col: 3
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Address',
            name: 'assetSaleAddLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Country',
            name: 'assetSaleCntry',
            type: 'select',
            lookup: 'FOREIGN_ASSETS_DISPOSITION_COUNTRIES',
            col: 4,
            printFieldWidth: 280,
            logicFunction: [
              {
                trigger: 'showAssetSaleStateOrProvince',
                isDifferentGroup: true
              }
            ]
          },
          {
            label: 'Street Address',
            name: 'assetSaleAdd',
            type: 'freeText',
            col: 8,
            printFieldWidth: 470,
          },
          { label: 'City', name: 'assetSaleCity', type: 'freeText', col: 4, printFieldWidth: 330, },
          {
            label: 'State',
            name: 'assetSaleState',
            type: 'select',
            lookup: 'BASIC_DATA_STATES',
            col: 4,
            isVisible: true,
            printFieldWidth: 300,
          },
          {
            label: 'Province',
            name: 'assetSaleProv',
            type: 'select',
            lookup: 'BASIC_DATA_PROVINCES',
            col: 4,
            isVisible: false,
            printFieldWidth: 300,
          },
          {
            label: 'Province',
            name: 'assetSaleOtherProv',
            type: 'freeText',
            col: 4,
            isVisible: false,
            printFieldWidth: 300,
            maxLength: 12,
          },
          { label: 'Zip', name: 'assetSaleZip', type: 'zippostal', col: 4, printFieldWidth: 220, }
        ]
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Tax Exempt',
            name: 'assetSaleTaxExmptLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Tax Exempt',
            name: 'assetSaleTaxExmpt',
            type: 'select',
            lookup: 'YESNO',
            col: 3,
            logicFunction: [
              { trigger: 'showAssetSaleCodeEntType', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 6,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'IRC Code Section',
            name: 'assetSaleCodeLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'IRC Code Section',
            name: 'assetSaleCode',
            type: 'select',
            lookup: 'EXEMPT_STATUSES',
            col: 3
          }
        ]
      },
      {
        groupId: 7,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Type of Entity',
            name: 'assetSaleEntTypeLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Type of Entity',
            name: 'assetSaleEntType',
            type: 'freeText',
            col: 3
          }
        ]
      }
    ]
  }
];

const assetExpenseLiqDisSection = [
  {
    title: 'Asset/Expense Details',
    isVisible: true,
    sectionId: 1,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Description',
            name: 'assetLiqDescLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Description',
            name: 'assetLiqDesc',
            type: 'textarea',
            col: 12,
            maxRows: 3,
            class: 'fieldTextArea',
            maxLength: 1000
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Recipient EIN',
            name: 'assetLiqEINLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Recipient EIN',
            name: 'assetLiqEIN',
            type: 'ein',
            col: 3,
            maxLength: 9,
            validations: { ...Validators.ein() }
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Recipient Name',
            name: 'assetLiqNameLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Recipient Name',
            name: 'assetLiqName',
            type: 'freeText',
            col: 3,
            maxLength: 76
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Address',
            name: 'assetLiqAddLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Country',
            name: 'assetLiqAddCountry',
            type: 'select',
            lookup: 'FOREIGN_ASSETS_DISPOSITION_COUNTRIES',
            col: 4,
            logicFunction: [
              {
                trigger: 'showassetLiqAddStateOrProvince',
                isDifferentGroup: true
              }
            ],
            printFieldWidth: 280,
          },
          {
            label: 'Street Address',
            name: 'assetLiqAdd',
            type: 'freeText',
            col: 8,
            maxLength: 76,
            printFieldWidth: 470,
          },
          {
            label: 'City',
            name: 'assetLiqAddCity',
            type: 'freeText',
            col: 4,
            printFieldWidth: 330,
            maxLength: 76
          },
          {
            label: 'Province / State',
            name: 'assetLiqAddStateProv',
            type: 'freeText',
            col: 4,
            printFieldWidth: 300,
            isVisible: true,
            maxLength: 12,
          },
          {
            label: 'Foreign Province',
            name: 'assetLiqAddProvince',
            type: 'select',
            lookup: 'BASIC_DATA_PROVINCES_CANADA_12',
            col: 4,
            printFieldWidth: 300,
            isVisible: false
          },
          {
            label: 'State',
            name: 'assetLiqAddState',
            type: 'select',
            lookup: 'BASIC_DATA_STATES',
            col: 4,
            printFieldWidth: 300,
            isVisible: false
          },
          { label: 'Zip', name: 'assetLiqAddZip', type: 'zippostal', col: 4, printFieldWidth: 220, }
        ]
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Tax Exempt',
            name: 'assetLiqTaxExmptLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'assetLiqTaxExmpt',
            type: 'select',
            lookup: 'YESNO',
            col: 3,
            logicFunction: [
              { trigger: 'showAssetLiqCodeEntType', isDifferentGroup: true }
            ]
          }
        ]
      },
      {
        groupId: 6,
        isVisible: false,
        fields: [
          {
            label: 'IRC Code Section',
            name: 'assetLiqCodeLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'assetLiqCode',
            type: 'select',
            lookup: 'EXEMPT_STATUSES',
            col: 3
          }
        ]
      },
      {
        groupId: 7,
        isVisible: false,
        fields: [
          {
            label: 'Type of Entity',
            name: 'assetLiqEntTypeLabel',
            type: 'label',
            col: 9
          },
          {
            label: 'Type of Entity',
            name: 'assetLiqEntType',
            type: 'freeText',
            col: 3,
            maxLength: 76
          }
        ]
      }
    ]
  }
];

const sections = [
  {
    title: 'Liquidation or Significant Disposition of Assets',
    isVisible: true,
    sectionId: 1,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization liquidate, terminate, or dissolve and cease operations?',
            name: 'assetOrgDslvCeaseLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'assetOrgDslvCease',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              {
                trigger: 'showLiquidationDissolutionSections',
                isDifferentGroup: 'true'
              }
            ]
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization sell, exchange, dispose or transfer more than 25% of its net assets?',
            name: 'assetOrgSell25asstsLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'assetOrgSell25assts',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              { trigger: 'showSaleExchangeSections', isDifferentGroup: 'true' }
            ]
          }
        ]
      }
    ]
  },
  {
    title: 'Asset/Expense Details from Liquidation/Dissolution',
    isVisible: false,
    hasDenseRow: true,
    sectionId: 2,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label:
              'Provide the following information about the assets being distributed (aggregate assets into categories and sufficiently describe) and related transaction expenses of at least $10,000:',
            name: 'assetLiqLabel',
            type: 'label',
            col: 10
          }
        ]
      },
      {
        groupId: 2,
        groupType: 'lineItem',
        isVisible: true,
        with990DenseRows: true,
        fields: [
          {
            label: 'Type',
            name: 'assetLiqType',
            type: 'select',
            lookup: 'ASSET_LIQ_TYPE'
          },
          { label: 'Date Distributed', name: 'assetLiqDate', type: 'date' },
          { label: 'FMV', name: 'assetLiqFmv', type: 'money', isTotal: true },
          {
            label: 'Method for FMV',
            name: 'assetLiqFmvMthd',
            type: 'freeText',
            maxLength: 1000
          },
          { type: 'lineButtons' }
        ],
        lineItems: [],
        entities: [],
        lineSections: assetExpenseLiqDisSection,
        currentEntity: -1,
        subWorkSheets: [],
        headerLabelsPrintStyles: {gridTemplateColumns: '2fr 2fr 1.75fr 1.75fr 1fr'},
        footerLabelsPrintStyles: {gridTemplateColumns: '6fr 2fr'},
        lineItemDetails: {
          headerLabels: [
            {
              label: 'Type',
              name: 'assetLiqType',
              col: 3,
              colTablet: 1,
              styles: { paddingLeft: '48px' }
            },
            {
              label: 'Date Distributed',
              name: 'assetLiqDate',
              col: 3,
              colTablet: 2,
              styles: { paddingLeft: '42px' }
            },
            {
              label: 'FMV',
              name: 'assetLiqFmv',
              col: 2,
              colTablet: 2,
              styles: { paddingLeft: '33px' }
            },
            {
              label: 'Method for FMV',
              name: 'assetLiqFmvMthd',
              col: 3,
              colTablet: 2,
              styles: { paddingLeft: '27px' }
            },
            { label: 'Actions', col: 1, colTablet: 1, align: 'left' }
          ],
          footerLabels: [
            {
              type: 'buttons',
              col: 12,
              buttonTitle: 'Add Distributed Assets',
              icon: 'add'
            },
            {
              type: 'totalMoney',
              name: 'assetLiqFmv',
              col: 8,
              colTablet: 5,
              label: ' $ 0'
            }
          ],
          lineButtons: [{ button: 'remove' }]
        }
      }
    ]
  },
  {
    title: 'Liquidation/Dissolution Questions',
    isVisible: false,
    sectionId: 3,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did or will any officer, director, trustee, or key employee of the organization (Check all that apply):',
            name: 'textOne',
            type: 'label',
            class: 'labelQuestionTitle',
            col: 12
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Become a director or trustee of a successor or transferee organization?',
            name: 'assetLiqSuccessLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            name: 'assetLiqSuccess',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            logicFunction: [
              { trigger: 'showAssetLiqPrsnInvldExpl', isDifferentGroup: 'true' }
            ]
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Become an employee of, or independent contractor for, a successor or transferee organization?',
            name: 'assetLiqEmpleeLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            name: 'assetLiqEmplee',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            logicFunction: [
              { trigger: 'showAssetLiqPrsnInvldExpl', isDifferentGroup: 'true' }
            ]
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Become a direct or indirect owner of a successor or transferee organization?',
            name: 'assetLiqOwnerLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            name: 'assetLiqOwner',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            logicFunction: [
              { trigger: 'showAssetLiqPrsnInvldExpl', isDifferentGroup: 'true' }
            ]
          }
        ]
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: `Receive, or become entitled to, compensation or other similar payments as a result of the organization's liquidation, termination, or dissolution?`,
            name: 'assetLiqCompLabel',
            type: 'label',
            col: 10,
            class: 'labelSingleCheckbox'
          },
          {
            label: 'Yes',
            name: 'assetLiqComp',
            type: 'checkbox',
            col: 2,
            class: 'singleCheckboxMargin',
            isFieldCheckbox: true,
            logicFunction: [
              { trigger: 'showAssetLiqPrsnInvldExpl', isDifferentGroup: 'true' }
            ]
          }
        ]
      },
      {
        groupId: 6,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Provide the name of the person(s) involved:',
            name: 'assetLiqPrsnInvldLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Provide the name of the person(s) involved:',
            name: 'assetLiqPrsnInvld',
            type: 'freeText',
            col: 12,
            printFieldWidth: 730,
          }
        ]
      },
      {
        groupId: 7,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Please explain:',
            name: 'assetLiqExplLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Please explain:',
            name: 'assetLiqExpl',
            type: 'freeText',
            col: 12,
            printFieldWidth: 730,
          }
        ]
      },
      {
        groupId: 8,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization distribute its assets in accordance with its governing instrument(s)?',
            name: 'assetLiqGovernInstLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'assetLiqGovernInst',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              {
                trigger: 'showAssetLiqGovernInstDesc',
                isDifferentGroup: 'true'
              }
            ]
          }
        ]
      },
      {
        groupId: 9,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Explain why assets were not distributed in accordance with the governing documents.',
            name: 'assetLiqGovernInstDescLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Please explain',
            name: 'assetLiqGovernInstDesc',
            type: 'textarea',
            col: 12
          }
        ]
      },
      {
        groupId: 10,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Is the organization required to notify the attorney general or other appropriate state official of its intent to dissolve, liquidate, or terminate?',
            name: 'assetLiqReqNoticeLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'assetLiqReqNotice',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              {
                trigger: 'showAssetLiqReqNoticeMade',
                isDifferentGroup: 'true'
              },
              {
                trigger: 'showAssetLiqReqNoticeNotMade',
                isDifferentGroup: 'true'
              }
            ]
          }
        ]
      },
      {
        groupId: 11,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Did the organization provide such notice?',
            name: 'assetLiqReqNoticeMadeLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'assetLiqReqNoticeMade',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              {
                trigger: 'showAssetLiqReqNoticeNotMade',
                isDifferentGroup: 'true'
              }
            ]
          }
        ]
      },
      {
        groupId: 12,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Explain why notice was not provided.',
            name: 'assetLiqReqNoticeNotMadeLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Please explain',
            name: 'assetLiqReqNoticeNotMade',
            type: 'textarea',
            col: 12
          }
        ]
      },
      {
        groupId: 13,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization discharge or pay all of its liabilities in accordance with state laws?',
            name: 'assetLiqDischgAllLiabLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'assetLiqDischgAllLiab',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              {
                trigger: 'showAssetLiqDischgAllLiabNo',
                isDifferentGroup: 'true'
              }
            ]
          }
        ]
      },
      {
        groupId: 14,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Explain why not.',
            name: 'assetLiqDischgAllLiabNoLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Please explain',
            name: 'assetLiqDischgAllLiabNo',
            type: 'freeText',
            col: 12,
            printFieldWidth: 730,
          }
        ]
      },
      {
        groupId: 15,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization have any tax-exempt bonds outstanding during the year?',
            name: 'assetLiqBondsLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'assetLiqBonds',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              { trigger: 'showAssetLiqBondsDschrg', isDifferentGroup: 'true' },
              {
                trigger: 'showAssetLiqBondsDschrgExplTrns',
                isDifferentGroup: 'true'
              },
              {
                trigger: 'showAssetLiqBondsDschrgTrnsDesc',
                isDifferentGroup: 'true'
              }
            ]
          }
        ]
      },
      {
        groupId: 16,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization discharge or defease all of its tax-exempt bond liabilities during the tax year in accordance with the Internal Revenue Code and state laws?',
            name: 'assetLiqBondsDschrgLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'assetLiqBondsDschrg',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              {
                trigger: 'showAssetLiqBondsDschrgExplTrns',
                isDifferentGroup: 'true'
              },
              {
                trigger: 'showAssetLiqBondsDschrgTrnsDesc',
                isDifferentGroup: 'true'
              }
            ]
          }
        ]
      },
      {
        groupId: 17,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe how the organization defeased or otherwise settled these liabilities.',
            name: 'assetLiqBondsDschrgExplLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Please explain',
            name: 'assetLiqBondsDschrgExpl',
            type: 'textarea',
            col: 12
          }
        ]
      },
      {
        groupId: 18,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Did the organization avoid the need for a defeasance of bonds through the transfer of assets to another section 501(c)(3) organization?',
            name: 'assetLiqBondsDschrgTrnsLabel',
            type: 'label',
            col: 9
          },
          {
            label: '',
            name: 'assetLiqBondsDschrgTrns',
            type: 'radio',
            lookup: 'YESNO_RADIO',
            col: 3,
            logicFunction: [
              {
                trigger: 'showAssetLiqBondsDschrgTrnsDesc',
                isDifferentGroup: 'true'
              }
            ]
          }
        ]
      },
      {
        groupId: 19,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label:
              'Describe here (include name of tranferees, CUSIP of the bond issue, and a description of the terms)',
            name: 'assetLiqBondsDschrgTrnsDescLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Please explain',
            name: 'assetLiqBondsDschrgTrnsDesc',
            type: 'freeText',
            col: 12
          }
        ]
      },
      // {
      // 	groupId: 20,
      // 	isVisible: true,
      // 	fields: [
      // 		{ label: 'Provide attach a signed copy of the Articles of Dissolution or similar document that has been filed with the State of Legal Domicile:', name: 'assetLiqArtDissolution', type: 'attachments', col: 12 }, // TODO: Uploads components
      // 	]
      // },
      {
        groupId: 21,
        isVisible: true,
        hasDivider: true,
        groupType: 'lineItem',
        uploadType: 'drawer',
        class: 'householdEmploymentUpload',
        fields: [
          {
            label:
              'Provide a signed copy of the Articles of Dissolution or similar document that has been filed with the State of Legal Domicile:',
            name: 'assetLiqArtDissolutionLabel',
            type: 'label',
            col: 9
          },
          { type: 'lineButtons', col: 3 }
        ],
        lineItems: [],
        lineItemDetails: {
          headerLabels: [],
          footerLabels: [],
          lineButtons: [
            {
              button: 'uploadOnly',
              uploadSectionName: 'assetLiqArtDissolution',
              viewSectionId: 0
            }
          ]
        }
      }
    ]
  },
  {
    title: 'Asset/Expense Details from Sale/Exchange',
    isVisible: false,
    hasDenseRow: true,
    sectionId: 4,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        fields: [
          {
            label:
              'Provide the following information about the assets being distributed (aggregate assets into categories and sufficiently describe) and related transaction expenses of at least $10,000:',
            name: 'assetSaleLabel',
            type: 'label',
            col: 10
          }
        ]
      },
      {
        groupType: 'lineItem',
        groupId: 2,
        isVisible: true,
        with990DenseRows: true,
        fields: [
          {
            label: 'Type',
            name: 'assetSaleType',
            type: 'select',
            lookup: 'ASSET_LIQ_TYPE'
          },
          { label: 'Date', name: 'assetSaleDate', type: 'date' },
          { label: 'FMV', name: 'assetSaleFmv', type: 'money', isTotal: true },
          {
            label: 'Method for FMV',
            name: 'assetSaleFmvMthd',
            type: 'freeText',
            maxLength: 1000
          },
          { type: 'lineButtons' }
        ],
        lineItems: [],
        entities: [],
        lineSections: assetExpenseSaleExDetails,
        currentEntity: -1,
        headerLabelsPrintStyles: {gridTemplateColumns: '2fr 2fr 1.75fr 1.75fr 1fr'},
        footerLabelsPrintStyles: {gridTemplateColumns: '6fr 2fr'},
        lineItemDetails: {
          headerLabels: [
            {
              label: 'Type',
              name: 'assetSaleType',
              col: 3,
              colTablet: 1,
              styles: { paddingLeft: '48px' }
            },
            {
              label: 'Date',
              name: 'assetSaleDate',
              col: 3,
              colTablet: 2,
              styles: { paddingLeft: '42px' }
            },
            {
              label: 'FMV',
              name: 'assetSaleFmv',
              col: 2,
              colTablet: 2,
              styles: { paddingLeft: '33px' }
            },
            {
              label: 'Method for FMV',
              name: 'assetSaleFmvMthd',
              col: 3,
              colTablet: 2,
              styles: { paddingLeft: '27px' }
            },
            { label: 'Actions', col: 1, colTablet: 1, align: 'left' }
          ],
          footerLabels: [
            {
              type: 'buttons',
              col: 12,
              buttonTitle: 'Add Asset/Expense Details',
              icon: 'add'
            },
            {
              type: 'totalMoney',
              name: 'assetSaleFmv',
              col: 8,
              colTablet: 5,
              label: ' $ 0'
            }
          ],
          lineButtons: [{ button: 'remove' }]
        }
      }
    ]
  },
  {
    title: 'Sale/Exchange Questions',
    isVisible: false,
    sectionId: 5,
    groups: [
      {
        groupId: 1,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Did or will any officer, director, trustee, or key employee of the organization (check all that apply):',
            name: 'textTwo',
            type: 'label',
            col: 12
          }
        ]
      },
      {
        groupId: 2,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Become a director or trustee of a successor or transferee organization?',
            name: 'assetSaleSuccessLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'assetSaleSuccess',
            type: 'checkbox',
            isFieldCheckbox: true,
            col: 2,
            logicFunction: [
              {
                trigger: 'showAssetSalePrsnInvldExpl',
                isDifferentGroup: 'true'
              }
            ]
          }
        ]
      },
      {
        groupId: 3,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Become an employee of, or independent contractor for, a successor or transferee organization?',
            name: 'assetSaleEmpleeLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'assetSaleEmplee',
            type: 'checkbox',
            isFieldCheckbox: true,
            col: 2,
            logicFunction: [
              {
                trigger: 'showAssetSalePrsnInvldExpl',
                isDifferentGroup: 'true'
              }
            ]
          }
        ]
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label:
              'Become a direct or indirect owner of a successor or transferee organization?',
            name: 'assetSaleOwnerLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'assetSaleOwner',
            type: 'checkbox',
            isFieldCheckbox: true,
            col: 2,
            logicFunction: [
              {
                trigger: 'showAssetSalePrsnInvldExpl',
                isDifferentGroup: 'true'
              }
            ]
          }
        ]
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: `Receive, or become entitled to, compensation or other similar payments as a result of the organization's liquidation, termination, or dissolution?`,
            name: 'assetSaleCompLabel',
            type: 'label',
            col: 10
          },
          {
            label: 'Yes',
            name: 'assetSaleComp',
            type: 'checkbox',
            isFieldCheckbox: true,
            col: 2,
            logicFunction: [
              {
                trigger: 'showAssetSalePrsnInvldExpl',
                isDifferentGroup: 'true'
              }
            ]
          }
        ]
      },
      {
        groupId: 6,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Provide the name of the person(s) involved:',
            name: 'assetSalePrsnInvldLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Provide the name of the person(s) involved:',
            name: 'assetSalePrsnInvld',
            type: 'textarea',
            col: 12
          }
        ]
      },
      {
        groupId: 7,
        isVisible: false,
        fields: [
          {
            label: 'Please explain:',
            name: 'assetSaleExplLabel',
            type: 'label',
            col: 12
          },
          {
            label: 'Please explain:',
            name: 'assetSaleExpl',
            type: 'textarea',
            col: 12
          }
        ]
      }
    ]
  },
  {  
    title: 'Additional Information',
    collapseClass: 'collapsibleForm',
    isVisible: true,
    isDefaultExpanded: false,
    isOptionalSectionMerge: true,
    sectionId: 6,
    groups: [
      {
        groupId: 2,
        groupType: 'details',
        fields: [
          { 
            label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
            type: 'label', 
            class: 'labelLeftLight' 
          },
        ]
      },
      {
        groupId: 1,
        fields: [
          {
            label: 'Start typing to provide additional information', 
            name: 'additionalInfoTextArea', 
            type: 'textarea', 
            col: 12,
            maxLength: 9000
          }
        ]
      }
    ]
  },
];

export const getLiquidationAndDispositionTitles = () => {
  let titles = [];
  sections.forEach((section) => {
    if (!titles.includes(section.title)) {
      titles.push(section.title);
    }
  });
  return titles;
};

export const getAssetExpenseLiqDisSection = () => {
  return assetExpenseLiqDisSection;
};

export const getAssetExpenseSaleExDetails = () => {
  return assetExpenseSaleExDetails;
};

function LiquidationAndDisposition(props) {
  const { isExportForm } = props;
  const { updateCard, REDUX } = usePageFramework(
    NAV.LIQUIDATION_AND_DISPOSITION_OF_ASSETS
  );
  const [sectionState, setSectionState] = useState('');
  const {isActiveClient, isLoading} = useLoadOrganizer();

  useEffect(() => {
    if (!isExportForm) updateCard();

    setSectionState(sections);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isLoading && isActiveClient && <FormRendererHelper
        sections={sectionState}
        key={REDUX.LIQUIDATION_AND_DISPOSITION_OF_ASSETS}
        formName={REDUX.LIQUIDATION_AND_DISPOSITION_OF_ASSETS}
        triggeredEvent={triggeredEvent}
        sectionFieldLogic={sectionFieldLogic}
        isExportForm={isExportForm}
      />}
      {!isLoading && !isActiveClient && <NoActiveClient />}
    </>
  );
}

export default LiquidationAndDisposition;
