import { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { triggeredEvent, sectionFieldLogic } from './schoolsLogicTrigger.js';
import { pullFieldValue } from '@utilities/pullFieldValue';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

const sections = [
	{
		title: 'Policies & Records',
		isVisible: true,
		sectionId: 1,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Does the organization have a racially nondiscriminatory policy toward students via a statement in its charter, bylaws, other governing instrument, or in a resolution of its governing body?', name: 'edNonDiscrimRacePolLabel', type: 'label', col: 9 },
					{ label: '', name: 'edNonDiscrimRacePol', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdNonDiscrimRacePolNo' }] },
				]
			},
			{
				groupId: 2,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Explain circumstances for non-compliance:',
					name: 'edNonDiscrimRacePolNoLabel',
					type: 'label', 
					col: 9, 
					class: 'labelTitleTextArea' },
					{
					label: 'Please explain',
					name: 'edNonDiscrimRacePolNo',
					type: 'textarea',
					col: 12,
					maxRows: 3,
					maxLength: 900,
					class: 'fieldTextArea',
					}
				]
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Does the organization include a statement of its racially nondiscriminatory policy toward students in all its brochures, catalogues, and other written communications with the public dealing with student’s admissions, programs, and scholarships?', name: 'edNonDiscrimRaceStateLabel', type: 'label', col: 9 },
					{ label: '', name: 'edNonDiscrimRaceState', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdNonDiscrimRaceStateNo' }] },
				]
			},
			{
				groupId: 4,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Explain circumstances for non-compliance:',
					name: 'edNonDiscrimRaceStateNoLabel',
					type: 'label', 
					col: 9, 
					class: 'labelTitleTextArea' },
					{
					label: 'Please explain',
					name: 'edNonDiscrimRaceStateNo',
					type: 'textarea',
					col: 12,
					maxRows: 3,
					maxLength: 900,
					class: 'fieldTextArea',
					}
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Has the organization publicized its racially nondiscriminatory policy through newspaper or broadcast media during the period of solicitation for students, or during the registration period if it has no solicitation program, in a way that makes the policy known to all parts of the general community it serves?', name: 'edNonDiscrimRacePolPubLabel', type: 'label', col: 9 },
					{ label: '', name: 'edNonDiscrimRacePolPub', type: 'radio', lookup: 'YESNO_RADIO', col: 3 },
				]
			},
			{
				groupId: 6,
				isVisible: true,
				hasDivider: true,
				fields: [
					{
                        label: 'If Yes, describe how publicized; If No, explain circumstances for non-compliance:',
                        name: 'edNonDiscrimRacePolPubDescLabel',
                        type: 'label', 
                        col: 9, 
                        class: 'labelTitleTextArea',
                    },
					{
                        label: 'Please explain',
                        name: 'edNonDiscrimRacePolPubDesc',
                        type: 'textarea',
                        col: 12,
                        maxRows: 3,
                        maxLength: 900,
                        class: 'fieldTextArea',
                        ...pullFieldValue(['XFDS99E1.17'], true),
					}
				]
			},
			{
				groupId: 7,
				isVisible: true,
				fields: [
					{ label: 'Does the organization maintain the following:', name: 'descTwo', type: 'label', col: 12 },
				]
			},
			{
				groupId: 8,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Records indicating the racial composition of the student body, faculty, and administrative staff?', name: 'edDocRaceCompLabel', type: 'label', col: 9 },
					{ label: '', name: 'edDocRaceComp', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdCopyMatNo' }] },
				]
			},
			{
				groupId: 9,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Records documenting that scholarships and other financial assistance are awarded on a racially nondiscriminatory basis?', name: 'edDocScholarLabel', type: 'label', col: 9 },
					{ label: '', name: 'edDocScholar', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdCopyMatNo' }] },
				]
			},
			{
				groupId: 10,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Copies of all catalogues, brochures, announcements, and other written communications to the public dealing with student admissions, programs, and scholarships?', name: 'edCopyCatLabel', type: 'label', col: 9 },
					{ label: '', name: 'edCopyCat', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdCopyMatNo' }] },
				]
			},
			{
				groupId: 11,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Copies of all material used by the organization or on its behalf to solicit contributions?', name: 'edCopyMatLabel', type: 'label', col: 9 },
					{ label: '', name: 'edCopyMat', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdCopyMatNo' }] },
				]
			},
			{
				groupId: 12,
				isVisible: false,
				hasDivider: true,
				fields: [
					{
                        label: 'Explain circumstances for documents not maintained:',
                        name: 'edCopyMatNoLabel',
                        type: 'label', 
                        col: 9, 
                        class: 'labelTitleTextArea',
                    },
					{
                        label: 'Please explain',
                        name: 'edCopyMatNo',
                        type: 'textarea',
                        col: 12,
                        maxRows: 3,
                        maxLength: 900,
                        class: 'fieldTextArea',
                        ...pullFieldValue(['XFDS99E1.3'], true),
					},
				]
			},
		]
	},
	{
		title: 'Racial Discrimination',
		isVisible: true,
		sectionId: 2,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				fields: [
					{ label: 'Does the organization discriminate by race in any way with respect to:', name: 'descThree', type: 'label', col: 12 },
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: `Students’ rights or privileges`, name: 'edDiscrimStudentLabel', type: 'label', col: 9 },
					{ label: '', name: 'edDiscrimStudent', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdDiscrimExCurActYes' }]  },
				]
			},
			{
				groupId: 3,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Admissions policies', name: 'edDiscrimAdmitLabel', type: 'label', col: 9 },
					{ label: '', name: 'edDiscrimAdmit', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdDiscrimExCurActYes' }] },
				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Employment of faculty or administrative staff', name: 'edDiscrimEmployLabel', type: 'label', col: 9 },
					{ label: '', name: 'edDiscrimEmploy', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdDiscrimExCurActYes' }] },
				]
			},
			{
				groupId: 5,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Scholarships or other financial assistance', name: 'edDiscrimScholarLabel', type: 'label', col: 9 },
					{ label: '', name: 'edDiscrimScholar', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdDiscrimExCurActYes' }] },
				]
			},
			{
				groupId: 6,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Educational policies', name: 'edDiscrimEdPolLabel', type: 'label', col: 9 },
					{ label: '', name: 'edDiscrimEdPol', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdDiscrimExCurActYes' }] },
				]
			},
			{
				groupId: 7,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Use of facilities', name: 'edDiscrimFacilityLabel', type: 'label', col: 9 },
					{ label: '', name: 'edDiscrimFacility', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdDiscrimExCurActYes' }] },
				]
			},
			{
				groupId: 8,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Athletic programs', name: 'edDiscrimAthleticLabel', type: 'label', col: 9 },
					{ label: '', name: 'edDiscrimAthletic', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdDiscrimExCurActYes' }] },
				]
			},
			{
				groupId: 9,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Other extracurricular activities', name: 'edDiscrimExCurActLabel', type: 'label', col: 9 },
					{ label: '', name: 'edDiscrimExCurAct', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdDiscrimExCurActYes' }] },
				]
			},
			{
				groupId: 10,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Explain how organization discriminates by race:',
					name: 'edDiscrimExCurActYesLabel',
					type: 'label', 
					col: 9, 
					class: 'labelTitleTextArea' },
					{
					label: 'Please explain',
					name: 'edDiscrimExCurActYes',
					type: 'textarea',
					col: 12,
					maxRows: 3,
					maxLength: 900,
					class: 'fieldTextArea',
                    ...pullFieldValue(['XFDS99E1.8'], true)
					},		
				]
			},
		]
	},
	{
		title: 'Government Assistance & Certification',
		isVisible: true,
		sectionId: 3,
		groups: [
			{
				groupId: 1,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'Does the organization receive any financial aid or assistance from a government agency?', name: 'edFinanceAidLabel', type: 'label', col: 9 },
					{ label: '', name: 'edFinanceAid', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdFinAidRevokeYes' }] },
				]
			},
			{
				groupId: 2,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: `Has the organization’s right to receive any financial aid or assistance from a government agency ever been revoked or suspended?`, name: 'edFinAidRevokeLabel', type: 'label', col: 9 },
					{ label: '', name: 'edFinAidRevoke', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdFinAidRevokeYes' }] },
				]
			},
			{
				groupId: 3,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Describe the financial aid/assistance (including listing the sources), and if applicable also describe any revocation/suspension:',
					name: 'edFinAidRevokeYesLabel',
					type: 'label', 
					col: 9, 
					class: 'labelTitleTextArea' },
					{
					label: 'Describe the financial aid/assistance (including listing the sources), and if applicable also describe any revocation/suspension:',
					name: 'edFinAidRevokeYes',
					type: 'textarea',
					col: 12,
					maxRows: 3,
					maxLength: 900,
					class: 'fieldTextArea',
                    ...pullFieldValue(['XFDS99E1.21'], true)
					},		
				]
			},
			{
				groupId: 4,
				isVisible: true,
				hasDivider: true,
				fields: [
					{ label: 'The organization certifies that the responses to the previous questions in this section are true and, to the best of our knowledge and belief the school has satisfied the applicable requirements of federal non-discriminatory policies, operations and recordkeeping.', name: 'edCertComplyLabel', type: 'label', col: 9 },
					{ label: '', name: 'edCertComply', type: 'radio', lookup: 'YESNO_RADIO', col: 3, logicFunction: [{ trigger: 'showEdCertComplyNon' }] },
				]
			},
			{
				groupId: 5,
				isVisible: false,
				hasDivider: true,
				fields: [
					{ label: 'Explain why not:',
					name: 'edCertComplyNonLabel',
					type: 'label', 
					col: 9, 
					class: 'labelTitleTextArea' },
					{
					label: 'Please explain',
					name: 'edCertComplyNon',
					type: 'textarea',
					col: 12,
					maxRows: 3,
					maxLength: 900,
					class: 'fieldTextArea',
                    ...pullFieldValue(['XFDS99E1.22'], true)
					},		
				]
			},
		]
	},
	{  
		title: 'Additional Information',
		collapseClass: 'collapsibleForm',
		isVisible: true,
		isDefaultExpanded: false,
		isOptionalSectionMerge: true,
		sectionId: 4,
		groups: [
			{
				groupId: 2,
				groupType: 'details',
				fields: [
					{ 
						label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
						type: 'label', 
						class: 'labelLeftLight' 
					},
				]
			},
			{
				groupId: 1,
				fields: [
					{
						label: 'Start typing to provide additional information', 
						name: 'additionalInfoTextArea', 
						type: 'textarea', 
						col: 12,
						maxLength: 9000
					}
				]
			}
		]
	},
];

export const getSchoolsSectionTitles = () => {
	let titles = [];
	sections.forEach((section) => {
		if (!titles.includes(section.title)) {
			titles.push(section.title);
		}
	});
	return titles;
};

function SchoolsSection(props) {
	const { isExportForm } = props;
	const { updateCard, REDUX } = usePageFramework(NAV.SCHOOLS);
	const [sectionState, setSectionState] = useState('');
	const {isActiveClient, isLoading} = useLoadOrganizer();


	useEffect(() => {
		if (!isExportForm) updateCard();

		setSectionState(sections);
		// eslint-disable-next-line	
	}, []);

    const axcessIdentifiers = [
		{ section: 'Schedules', id: 'Sch E - Schools' }
	];

	return (
		<>
			{!isLoading && isActiveClient && <FormRendererHelper
				sections={sectionState}
				key={REDUX.SCHOOLS}
				formName={REDUX.SCHOOLS}
				triggeredEvent={triggeredEvent}
				sectionFieldLogic={sectionFieldLogic}
				identifiers={axcessIdentifiers}
				isExportForm={isExportForm}
			/>}
			{!isLoading && !isActiveClient && <NoActiveClient />}
		</>
	);
}

export default SchoolsSection;