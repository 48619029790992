import _ from 'lodash';

const isNotNil = (value) => {
    return !_.isNil(value);
};

const isAllFormFieldsValued = (formSections) => {
    if (!formSections || !formSections.length) return false;
    let  hasNotValued = false;
    // this check is for groups with radio fields on entry interview sections, SHOULD be updated for other group and field types
    formSections?.forEach(section => {
        section.groups?.forEach(group => {
            if (group.isVisible) {
                group.fields?.forEach(field => {
                    if (field.type === 'radio' && !field.inputState && !field.value) {
                        hasNotValued = true;
                        return !hasNotValued
                    }
                })
            }
        })
    });
    return !hasNotValued; 
}

export {
    isNotNil,
    isAllFormFieldsValued
};