import React, { useState, useEffect } from 'react';
import usePageFramework from '@utilities/hooks/usePageFramework';
import FormRendererHelper from '@components/formRenderer';
import { Validators } from 'cla-formrenderer';
import {
  triggeredEvent,
  sectionFieldLogic,
} from './boardOfDirectorsAndCompensationLogicTrigger';
import { form } from '@utilities/helpers/getFormSections';
import { KEY_NO, KEY_YES } from '@utilities/constants/triggerKeys';
import * as NAV from '@utilities/constants/navigation';
import NoActiveClient from '@views/dashboard/noActiveClientPage';
import useLoadOrganizer from '@utilities/hooks/useLoadOrganizer';

export const orgSections = [
  {
    title: 'About Individual',
    isVisible: true,
    sectionId: 1,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        hasDivider: true,
        fields: [
          {
            label: 'Name',
            name: 'compIndvName',
            type: 'freeText',
            maxLength: 37,
            col: 4,
            validations: { ...Validators.required() },
            isSyncFieldValue: true,
            logicFunction: [
              { trigger: 'setIndividualName', isDifferentGroup: true },
              { trigger: 'syncUpIndividualName' },
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSOFCR.0'],
              },
            },
            isSorted: true,
          },
          {
            label: 'Title',
            name: 'compIndvTitle',
            type: 'freeText',
            maxLength: 50,
            col: 3,
            validations: { ...Validators.required() },
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSOFCR.1'],
              },
            },
            logicFunction: [
              { trigger: 'setIndividualTitle', isDifferentGroup: true },
              { trigger: 'syncUpIndividualName' },
            ],
            isSorted: true,
          },
          {
            label: 'Position',
            name: 'compIndvPosition',
            type: 'select',
            lookup: 'COMP_POSITION',
            col: 3,
            validations: { ...Validators.required() },
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: [
                  'XFDSOFCR.32',
                  'XFDSOFCR.38',
                  'XFDSOFCR.40',
                  'XFDSOFCR.41',
                ],
                fn: 'odtkeCompensationPull',
              },
            },
            isSorted: true,
          },
          {
            label: 'Officer',
            name: 'compIndvOfficer',
            type: 'select',
            lookup: 'YESNO',
            col: 2,
            validations: { ...Validators.required() },
            isSorted: true,
            logicFunction: [{ trigger: 'syncCompIndvOfficer' }],
          },
          // { label: 'Title', name: 'compIndvTitle', type: 'freeText', col: 3, validations: { ...Validators.required() },
          // logicFunction:[
          //     {trigger: 'setIndividualTitle', isDifferentGroup: true},
          //     {trigger: 'syncUpIndividualName'},
          // ] },
          // { label: 'Position', name: 'compIndvPosition', type: 'select', lookup: 'COMP_POSITION', col: 3, validations: { ...Validators.required() } },
          // { label: 'Officer', name: 'compIndvOfficer', type: 'select', lookup: 'YESNO', col: 2, validations: { ...Validators.required() }},
        ],
      },
      {
        groupId: 2,
        hasDivider: true,
        fields: [
          {
            label: 'Was compensation paid by:',
            name: 'compPaidByLabel',
            type: 'label',
            col: 6,
          },
          {
            label: 'Filing Org',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'filingOrg',
            col: 2,
            triggered: 'showFilingOrgFields',
            isSorted: true,
            logicFunction: [{ trigger: 'syncFilingOrgSummary' }],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSOFCR.2'],
                fn: 'checkboxValuePull',
              },
            },
          },
          {
            label: 'Related Org',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'relatedOrg',
            col: 2,
            triggered: 'showRelatedOrgFields',
            isSorted: true,
            logicFunction: [{ trigger: 'syncFilingOrgSummary' }],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSOFCR.4'],
                fn: 'checkboxValuePull',
              },
            },
          },
          {
            label: 'Unrelated Org',
            type: 'checkbox',
            isFieldCheckbox: true,
            name: 'unrelatedOrg',
            col: 2,
            triggered: 'showUnrelatedOrgFields',
            isSorted: true,
            logicFunction: [{ trigger: 'syncFilingOrgSummary' }],
          },
        ],
      },
      {
        groupId: 3,
        isVisible: false,
        hasDivider: true,
        fields: [
          {
            label: 'Compensation and benefits were greater than $150,000?',
            name: 'compGreater150kLabel',
            type: 'label',
            col: 10,
          },
          {
            label: 'Yes',
            name: 'compGreater150k',
            type: 'checkbox',
            isFieldCheckbox: true,
            default: true,
            col: 2,
            isSorted: true,
            triggered: 'showCompDetailsOrgsFields',
          },
        ],
      },
      {
        groupId: 4,
        isVisible: true,
        hasDivider: true,
        fields: [
          {
            label: 'Foreign Address?',
            name: 'foreignAddressLabel',
            type: 'label',
            col: 10,
          },
          {
            label: 'Select',
            name: 'foreignAddress',
            type: 'select',
            lookup: 'YESNO',
            default: 'No',
            col: 2,
            isSorted: true,
            triggered: 'showForeignAddress',
          },
        ],
      },
      {
        groupId: 5,
        isVisible: true,
        hasDivider: false,
        fields: [
          {
            label: 'Available at organization address?',
            name: 'AvailOrgAddressLabel',
            type: 'label',
            col: 10,
          },
          {
            label: 'Yes',
            name: 'AvailOrgAddress',
            type: 'checkbox',
            isFieldCheckbox: true,
            default: true,
            col: 2,
            triggered: 'setUSAddress',
          },
        ],
      },
      {
        groupId: 6,
        isVisible: false,
        hasDivider: false,
        fields: [
          {
            label: 'Street Address',
            name: 'compIndvAtMailAdd',
            type: 'freeText',
            col: 12,
            placeholder: 'Address 2',
          },
          {
            label: 'Country',
            name: 'compIndvAtMailCntry',
            type: 'select',
            lookup: 'INDEPENDENT_CONTRACTOR_COUNTRIES',
            col: 3,
            logicFunction: [{ trigger: 'setForeignAddress' }],
          },
          {
            label: 'City or town',
            name: 'compIndvAtMailCity',
            type: 'freeText',
            col: 3,
          },
          {
            label: 'Province',
            name: 'compIndvAtMailProv',
            type: 'select',
            lookup: 'PROVINCES_CODE',
            col: 3,
            isVisible: false,
          },
          {
            label: 'State',
            name: 'compIndvAtMailState',
            type: 'select',
            lookup: 'STATES',
            col: 3,
          },
          {
            label: 'State/Prov',
            name: `compIndvAtMailOther`,
            type: 'freeText',
            col: 3,
            isVisible: false,
            maxLength: 17,
            isSorted: true,
          },
          {
            label: 'State',
            name: `compIndvAtMailMexico`,
            type: 'select',
            lookup: 'MEX_STATES_CODE',
            col: 3,
            isVisible: false,
            isSorted: true,
          },
          {
            label: 'Zip/ Postal Code',
            name: 'compIndvAtMailZip',
            type: 'zippostal',
            col: 3,
            isVisible: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Average Hours/Week',
    isVisible: true,
    sectionId: 2,
    isCollapsed: true,
    isDenseRow: true,
    groups: [
      {
        groupId: 1,
        fields: [
          {
            label: '',
            name: 'compIndvName',
            type: 'label',
            col: 6,
            isSyncFieldValue: true,
            isVisible: true,
          },
          {
            label: 'Filing Org',
            name: 'compIndvAvgHrsOrg',
            type: 'percent',
            hasNoPercentSign: true,
            col: 2,
            isVisible: true,
            validations: { ...Validators.maxValue(168) },
            hasColSpace: true,
            logicFunction: [
              { trigger: 'setcompIndvAvgHrsOrg', isDifferentGroup: true },
              { trigger: 'syncAverageHoursWeek' },
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSOFCR.2'],
              },
            },
            isSorted: true,
          },
          {
            label: 'Related Org',
            name: 'compIndvAvgHrsRelated',
            type: 'percent',
            hasNoPercentSign: true,
            col: 2,
            isVisible: true,
            validations: { ...Validators.maxValue(168) },
            hasColSpace: true,
            logicFunction: [
              { trigger: 'setcompIndvAvgHrsRelated', isDifferentGroup: true },
              { trigger: 'syncAverageHoursWeek' },
            ],
            prior: true,
            isPriorEditable: true,
            axcess: {
              pull: {
                fields: ['XFDSOFCR.4'],
              },
            },
            isSorted: true,
          },
          {
            label: 'Unrelated Org',
            name: 'compIndvAvgHrsUnrelated',
            type: 'percent',
            hasNoPercentSign: true,
            col: 2,
            isVisible: true,
            validations: { ...Validators.maxValue(168) },
            hasColSpace: true,
            logicFunction: [
              { trigger: 'setcompIndvAvgHrsUnrelated', isDifferentGroup: true },
              { trigger: 'syncAverageHoursWeek' },
            ],
            isSorted: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Compensation Details',
    isVisible: false,
    sectionId: 3,
    isCollapsed: true,
    isDenseRow: true,
    hasOrgs: true,
    groups: [
      {
        groupId: 1,
        isVisible: false,
        hasDivider: true,
        title: 'Filing Organization',
        fields: [
          {
            label: '',
            name: 'compIndvName',
            type: 'label',
            col: 2,
            isVisible: false,
          },
          {
            label: 'W-2 Total (Greater of Box 1 or 5) or 1099',
            name: 'compIndvCompOrg',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'compW2Total',
            logicFunction: [
              { trigger: 'showFilingBaseCompAmount', isDifferentGroup: true },
              {
                trigger: 'checkW2FilingAmtGreaterThan150K',
                isDifferentGroup: true,
              },
              { trigger: 'syncCompensation' },
              {
                trigger: 'triggerCompGreater150kCheckbox',
                isDifferentGroup: true,
              },
            ],
            isSorted: true,
          },
          {
            label: 'Base Compensation',
            name: 'addCompBase',
            type: 'readOnly',
            col: 3,
            isVisible: true,
            totalOrg: 'compBase',
            isReadOnlyAmount: true,
            default: '$0',
          },
          {
            label: 'Bonus and Incentive Compensation',
            name: 'addCompBonus',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'compBonusIncentive',
            logicFunction: [
              { trigger: 'showFilingBaseCompAmount', isDifferentGroup: true },
            ],
            isSorted: true,
          },
          {
            label: 'Other Compensation',
            name: 'addCompOther',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'compOtherComp',
            logicFunction: [
              { trigger: 'showFilingBaseCompAmount', isDifferentGroup: true },
            ],
            isSorted: true,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        title: 'Related Organization',
        fields: [
          {
            label: '',
            name: 'compIndvName',
            type: 'label',
            col: 2,
            isVisible: false,
          },
          {
            label: 'W-2 Total (Greater of Box 1 or 5) or 1099',
            name: 'compIndvCompRelOrg',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'compW2Total',
            logicFunction: [
              { trigger: 'showRelatedBaseCompAmount', isDifferentGroup: true },
              {
                trigger: 'checkW2RelatedAmtGreaterThan150K',
                isDifferentGroup: true,
              },
              { trigger: 'syncCompensation' },
              {
                trigger: 'triggerCompGreater150kCheckbox',
                isDifferentGroup: true,
              },
            ],
            isSorted: true,
          },
          {
            label: 'Base Compensation',
            name: 'addCompRelBase',
            type: 'readOnly',
            col: 3,
            isVisible: true,
            totalOrg: 'compBase',
            isReadOnlyAmount: true,
            default: '$0',
          },
          {
            label: 'Bonus and Incentive Compensation',
            name: 'addCompRelBonus',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'compBonusIncentive',
            logicFunction: [
              { trigger: 'showRelatedBaseCompAmount', isDifferentGroup: true },
            ],
            isSorted: true,
          },
          {
            label: 'Other Compensation',
            name: 'addCompRelOther',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'compOtherComp',
            logicFunction: [
              { trigger: 'showRelatedBaseCompAmount', isDifferentGroup: true },
            ],
            isSorted: true,
          },
        ],
      },
      {
        groupId: 3,
        isVisible: false,
        title: 'Unrelated Organization',
        fields: [
          {
            label: '',
            name: 'compIndvName',
            type: 'label',
            col: 2,
            isVisible: false,
          },
          {
            label: 'W-2 Total (Greater of Box 1 or 5) or 1099',
            name: 'compIndvCompUnrelOrg',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'compW2Total',
            logicFunction: [
              {
                trigger: 'showUnrelatedBaseCompAmount',
                isDifferentGroup: true,
              },
              {
                trigger: 'checkW2UnrelatedAmtGreaterThan150K',
                isDifferentGroup: true,
              },
              { trigger: 'syncCompensation' },
              {
                trigger: 'triggerCompGreater150kCheckbox',
                isDifferentGroup: true,
              },
            ],
            isSorted: true,
          },
          {
            label: 'Base Compensation',
            name: 'addCompUnrelBase',
            type: 'readOnly',
            col: 3,
            isVisible: true,
            totalOrg: 'compBase',
            isReadOnlyAmount: true,
            default: '$0',
          },
          {
            label: 'Bonus and Incentive Compensation',
            name: 'addCompUnrelBonus',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'compBonusIncentive',
            logicFunction: [
              {
                trigger: 'showUnrelatedBaseCompAmount',
                isDifferentGroup: true,
              },
            ],
            isSorted: true,
          },
          {
            label: 'Other Compensation',
            name: 'addCompUnrelOther',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'compOtherComp',
            logicFunction: [
              {
                trigger: 'showUnrelatedBaseCompAmount',
                isDifferentGroup: true,
              },
            ],
            isSorted: true,
          },
        ],
      },
    ],
    footerLabelsPrintStyles: {gridTemplateColumns: '2.75fr 3.5fr 1.5fr 1fr'},
    footers: [
      {
        type: 'totalMoney',
        name: 'addTotW21099',
        col: 3,
        colTablet: 2,
        label: '$ 0',
        totalOrg: 'compW2Total',
      },
      {
        type: 'totalMoney',
        name: 'addTotBaseComp',
        col: 3,
        colTablet: 2,
        label: '$ 0',
        totalOrg: 'compBase',
      },
      {
        type: 'totalMoney',
        name: 'addTotBnsInctvComp',
        col: 3,
        colTablet: 2,
        label: '$ 0',
        totalOrg: 'compBonusIncentive',
      },
      {
        type: 'totalMoney',
        name: 'addTotOthrComp',
        col: 3,
        colTablet: 2,
        label: '$ 0',
        totalOrg: 'compOtherComp',
      },
    ],
    headers: [
      {
        type: 'totalMoney',
        name: 'compIndvCompOrg',
        label: 'Filing Org - W-2/1099 (Base,Bonus,Other):',
        totalOrg: 'compIndvCompOrg',
        amount: '$ 0',
        isVisible: false,
      },
      {
        type: 'totalMoney',
        name: 'compIndvCompRelOrg',
        label: 'Related Org - W-2/1099 (Base,Bonus,Other):',
        totalOrg: 'compIndvCompRelOrg',
        amount: '$ 0',
        isVisible: false,
      },
      {
        type: 'totalMoney',
        name: 'compIndvCompUnrelOrg',
        label: 'Unrelated Org - W-2/1099 (Base,Bonus,Other):',
        totalOrg: 'compIndvCompUnrelOrg',
        amount: '$ 0',
        isVisible: false,
      },
    ],
  },
  {
    title: 'Benefits Details',
    isVisible: false,
    sectionId: 4,
    isCollapsed: true,
    isDenseRow: true,
    hasOrgs: true,
    groups: [
      {
        groupId: 1,
        isVisible: false,
        hasDivider: true,
        title: 'Filing Organization',
        fields: [
          {
            label: '',
            name: 'compIndvName',
            type: 'label',
            col: 2,
            isVisible: false,
          },
          {
            label: 'Nontaxable Benefits - Employee Paid (Payroll Deductions)',
            name: 'addCompNontaxOrgEEPd',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'benefitsDetailsOrgDeductions',
            logicFunction: [
              {
                trigger: 'showFilingTotalNontaxableAmount',
                isDifferentGroup: true,
              },
              { trigger: 'syncBenefits' },
            ],
            isSorted: true,
          },
          {
            label: 'Nontaxable Benefits Employer Paid',
            name: 'addCompNontax',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'benefitsDetailsOrgEmployerPaid',
            logicFunction: [
              {
                trigger: 'showFilingTotalNontaxableAmount',
                isDifferentGroup: true,
              },
              { trigger: 'syncBenefits' },
            ],
            isSorted: true,
          },
          {
            label: 'Total Nontaxable Benefits',
            name: 'addTotNontaxOrgBnfts',
            type: 'readOnly',
            col: 3,
            isVisible: true,
            totalOrg: 'benefitsDetailsOrgBenefits',
            isReadOnlyAmount: true,
            default: '$0',
          },
          {
            label: 'Retirement/Deferred Comp Employer Only',
            name: 'addCompRetire',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'benefitsDetailsOrgDeferred',
            logicFunction: [{ trigger: 'syncBenefits' }],
            isSorted: true,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        title: 'Related Organization',
        fields: [
          {
            label: '',
            name: 'compIndvName',
            type: 'label',
            col: 2,
            isVisible: false,
          },
          {
            label: 'Nontaxable Benefits - Employee Paid (Payroll Deductions)',
            name: 'addCompNontaxRelOrgEEPd',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'benefitsDetailsOrgDeductions',
            logicFunction: [
              {
                trigger: 'showRelatedTotalNontaxableAmount',
                isDifferentGroup: true,
              },
              { trigger: 'syncBenefits' },
            ],
            isSorted: true,
          },
          {
            label: 'Nontaxable Benefits Employer Paid',
            name: 'addCompNontaxRelOrgERPd',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'benefitsDetailsOrgEmployerPaid',
            logicFunction: [
              {
                trigger: 'showRelatedTotalNontaxableAmount',
                isDifferentGroup: true,
              },
              { trigger: 'syncBenefits' },
            ],
            isSorted: true,
          },
          {
            label: 'Total Nontaxable Benefits',
            name: 'addTotNontaxRelOrgBnfts',
            type: 'readOnly',
            col: 3,
            isVisible: true,
            totalOrg: 'benefitsDetailsOrgBenefits',
            isReadOnlyAmount: true,
            default: '$0',
          },
          {
            label: 'Retirement/Deferred Comp Employer Only',
            name: 'addCompRelOrgRetire',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'benefitsDetailsOrgDeferred',
            logicFunction: [{ trigger: 'syncBenefits' }],
            isSorted: true,
          },
        ],
      },
      {
        groupId: 3,
        isVisible: false,
        title: 'Unrelated Organization',
        fields: [
          {
            label: '',
            name: 'compIndvName',
            type: 'label',
            col: 2,
            isVisible: false,
          },
          {
            label: 'Nontaxable Benefits - Employee Paid (Payroll Deductions)',
            name: 'addCompNontaxUnrelOrgEEPd',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'benefitsDetailsOrgDeductions',
            logicFunction: [
              {
                trigger: 'showUnrelatedTotalNontaxableAmount',
                isDifferentGroup: true,
              },
              { trigger: 'syncBenefits' },
            ],
            isSorted: true,
          },
          {
            label: 'Nontaxable Benefits Employer Paid',
            name: 'addCompNontaxUnrelOrgERPd',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'benefitsDetailsOrgEmployerPaid',
            logicFunction: [
              {
                trigger: 'showUnrelatedTotalNontaxableAmount',
                isDifferentGroup: true,
              },
              { trigger: 'syncBenefits' },
            ],
            isSorted: true,
          },
          {
            label: 'Total Nontaxable Benefits',
            name: 'addTotNontaxUnRelOrgBnfts',
            type: 'readOnly',
            col: 3,
            isVisible: true,
            totalOrg: 'benefitsDetailsOrgBenefits',
            isReadOnlyAmount: true,
            default: '$0',
          },
          {
            label: 'Retirement/Deferred Comp Employer Only',
            name: 'addCompRetireUnrelOrg',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'benefitsDetailsOrgDeferred',
            logicFunction: [{ trigger: 'syncBenefits' }],
            isSorted: true,
          },
        ],
      },
    ],
    footerLabelsPrintStyles: {gridTemplateColumns: '2.75fr 3.5fr 1.25fr 1.75fr'},
    footers: [
      {
        type: 'totalMoney',
        name: 'addTotCompNontaxEEPd',
        col: 3,
        colTablet: 2,
        label: '$ 0',
        totalOrg: 'benefitsDetailsOrgDeductions',
      },
      {
        type: 'totalMoney',
        name: 'addTotCompNontaxERPd',
        col: 3,
        colTablet: 2,
        label: '$ 0',
        totalOrg: 'benefitsDetailsOrgEmployerPaid',
      },
      {
        type: 'totalMoney',
        name: 'addTotNontaxBnfts',
        col: 3,
        colTablet: 2,
        label: '$ 0',
        totalOrg: 'benefitsDetailsOrgBenefits',
      },
      {
        type: 'totalMoney',
        name: 'addTotCompRetire',
        col: 3,
        colTablet: 2,
        label: '$ 0',
        totalOrg: 'benefitsDetailsOrgDeferred',
      },
    ],
    headers: [
      {
        type: 'totalMoney',
        name: 'benefitsDetailsOrgBenefits',
        label: 'Total Nontaxable Benefits:',
        totalOrg: 'benefitsDetailsOrgBenefits',
        amount: '$ 0',
      },
      {
        type: 'totalMoney',
        name: 'benefitsDetailsOrgDeferred',
        label: 'Total Retirement Benefits:',
        totalOrg: 'benefitsDetailsOrgDeferred',
        amount: '$ 0',
      },
      {
        type: 'totalMoney',
        name: 'totalbenefitsDetailsOrgDeferred',
        label: 'Total:',
        totalOrg: 'totalbenefitsDetailsOrgDeferred',
        amount: '$ 0',
        isCombinedTotal: true,
      },
    ],
  },
  {
    title: 'Fiscal Year Details',
    isVisible: false,
    sectionId: 5,
    isCollapsed: true,
    isDenseRow: true,
    hasOrgs: true,
    isTitleHidden: true,
    groups: [
      {
        groupId: 1,
        isVisible: false,
        hasDivider: true,
        title: 'Filing Organization',
        fields: [
          {
            label: '',
            name: 'compIndvName',
            type: 'label',
            col: 2,
            isVisible: false,
          },
          {
            label: 'FY Gross Salaries/Wages',
            name: 'compIndvCompOrgFY',
            type: 'money',
            col: 2,
            isVisible: true,
            totalOrg: 'fyGross',
            logicFunction: [{ trigger: 'syncFiscalDetails' }],
            isSorted: true,
          },
          {
            label: 'FY Employer Paid Benefits',
            name: 'EmprPaidBensFY',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'fyPaidBenefits',
            logicFunction: [{ trigger: 'syncFiscalDetails' }],
            isSorted: true,
          },
          {
            label: 'FY Employer Paid Retirement Contributions',
            name: 'EmprPaidRetirementFY',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'fyPaidCont',
            logicFunction: [{ trigger: 'syncFiscalDetails' }],
            isSorted: true,
          },
        ],
      },
      {
        groupId: 2,
        isVisible: false,
        hasDivider: true,
        title: 'Related Organization',
        fields: [
          {
            label: '',
            name: 'compIndvName',
            type: 'label',
            col: 2,
            isVisible: false,
          },
          {
            label: 'FY Gross Salaries/Wages',
            name: 'compIndvCompRelOrgFY',
            type: 'money',
            col: 2,
            isVisible: true,
            totalOrg: 'fyGross',
            logicFunction: [{ trigger: 'syncFiscalDetails' }],
            isSorted: true,
          },
          {
            label: 'FY Employer Paid Benefits',
            name: 'EmprPaidBensFYRelOr',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'fyPaidBenefits',
            logicFunction: [{ trigger: 'syncFiscalDetails' }],
            isSorted: true,
          },
          {
            label: 'FY Employer Paid Retirement Contributions',
            name: 'EmprPaidRetirementRelOrgFY',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'fyPaidCont',
            logicFunction: [{ trigger: 'syncFiscalDetails' }],
            isSorted: true,
          },
        ],
      },
      {
        groupId: 3,
        isVisible: false,
        title: 'Unrelated Organization',
        fields: [
          {
            label: '',
            name: 'compIndvName',
            type: 'label',
            col: 2,
            isVisible: false,
          },
          {
            label: 'FY Gross Salaries/Wages',
            name: 'compIndvCompUnrelOrgFY',
            type: 'money',
            col: 2,
            isVisible: true,
            totalOrg: 'fyGross',
            logicFunction: [{ trigger: 'syncFiscalDetails' }],
            isSorted: true,
          },
          {
            label: 'FY Employer Paid Benefits',
            name: 'EmprPaidBensFYUnrelOr',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'fyPaidBenefits',
            logicFunction: [{ trigger: 'syncFiscalDetails' }],
            isSorted: true,
          },
          {
            label: 'FY Employer Paid Retirement Contributions',
            name: 'EmprPaidRetirementUnrelOrgFY',
            type: 'money',
            col: 3,
            isVisible: true,
            totalOrg: 'fyPaidCont',
            logicFunction: [{ trigger: 'syncFiscalDetails' }],
            isSorted: true,
          },
        ],
      },
    ],
    footerLabelsPrintStyles: {gridTemplateColumns: '2.5fr 3fr 2.5fr .5fr'},
    footers: [
      {
        type: 'totalMoney',
        name: 'FYTotGrssSalWgs',
        col: 2,
        colTablet: 2,
        label: '$ 0',
        totalOrg: 'fyGross',
      },
      {
        type: 'totalMoney',
        name: 'FyTotERPdBnfts',
        col: 3,
        colTablet: 2,
        label: '$ 0',
        totalOrg: 'fyPaidBenefits',
      },
      {
        type: 'totalMoney',
        name: 'FYTotERPdRtrCntrb',
        col: 3,
        colTablet: 2,
        label: '$ 0',
        totalOrg: 'fyPaidCont',
      },
    ],
    headers: [
      {
        type: 'totalMoney',
        name: 'fyGross',
        label: 'Gross Salaries/Wages:',
        totalOrg: 'fyGross',
        amount: '$ 0',
      },
      {
        type: 'totalMoney',
        name: 'fyPaidCont',
        label: 'Total Retirement:',
        totalOrg: 'fyPaidCont',
        amount: '$ 0',
      },
      {
        type: 'totalMoney',
        name: 'fyPaidBenefits',
        label: 'Total Benefits:',
        totalOrg: 'fyPaidBenefits',
        amount: '$ 0',
      },
    ],
  },
];

const independentContractorFields = (suffixCount) => {
  return [
    {
      label: 'Name',
      name: `indContractName-${suffixCount}`,
      type: 'freeText',
      col: 4,
      maxLength: 75,
      isSorted: true,
      printFieldWidth: 400
    },
    {
      label: 'Has Foreign Address',
      name: `indContractForeignAddress-${suffixCount}`,
      type: 'select',
      lookup: 'YESNO',
      col: 2,
      logicFunction: [{ trigger: 'setContractorForeignAddress' }],
      isSorted: true,
    },
    {
      label: 'Compensation',
      name: `indContractComp-${suffixCount}`,
      type: 'money',
      col: 2,
      isSorted: true,
    },
    {
      label: 'Description of Services Provided',
      name: `indContractSrvs-${suffixCount}`,
      type: 'freeText',
      col: 4,
      maxLength: 72,
      isSorted: true,
      printFieldWidth: 400
    },
    {
      label: 'Country',
      name: `indContractForeignCountry-${suffixCount}`,
      type: 'select',
      lookup: 'INDEPENDENT_CONTRACTOR_COUNTRIES',
      logicFunction: [{ trigger: 'setContractorStateProv' }],
      col: 2,
      isVisible: false,
      isSorted: true,
    },
    {
      label: 'Street address',
      name: `indContractAdd1-${suffixCount}`,
      type: 'freeText',
      col: 4,
      maxLength: 50,
      isSorted: true,
      printFieldWidth: 320
    },
    {
      label: 'City',
      name: `indContractCity-${suffixCount}`,
      type: 'freeText',
      col: 2,
      maxLength: 19,
      isSorted: true,
    },
    {
      label: 'State',
      name: `indContractState-${suffixCount}`,
      type: 'select',
      lookup: 'FOREIGN_ASSETS_STATES_CODES',
      col: 2,
      isVisible: true,
      isSorted: true,
    },
    {
      label: 'State/Prov',
      name: `indContractStateProv-${suffixCount}`,
      type: 'freeText',
      col: 2,
      isVisible: false,
      maxLength: 17,
      isSorted: true,
    },
    {
      label: 'Province',
      name: `indContractProv-${suffixCount}`,
      type: 'select',
      lookup: 'PROVINCES_CODE',
      col: 2,
      isVisible: false,
      isSorted: true,
    },
    {
      label: 'State',
      name: `indContractMexState-${suffixCount}`,
      type: 'select',
      lookup: 'MEX_STATES_CODE',
      col: 2,
      isVisible: false,
      isSorted: true,
    },
    {
      label: 'Zip / Postal Code',
      name: `indContractZip-${suffixCount}`,
      type: 'zippostal',
      col: 2,
      maxLength: 15,
      isSorted: true,
    },
  ];
};

let sections = [];
export const getBoardOfDirectorsAndCompensationTitles = () => {
  let titles = [];
  sections.forEach((section) => {
    if (!titles.includes(section.title)) {
      //if (!titles.includes(section.title) && section.isVisible) {
      titles.push(section.title);
    }
  });
  return titles;
};

export const getBoardOfDirectorsAndCompensationSections = () => {
	return sections;
};

const hideSectionTitle = (val) => {
  orgSections[4].isTitleHidden = val !== null && val.value === KEY_YES ? true : false;
}

function BoardOfDirectorsAndCompensation(props) {
  const { isExportForm } = props;
  const { updateCard, REDUX, selectState } = usePageFramework(NAV.BOARD_OF_DIRECTORS_AND_COMPENSATION);
  const [sectionState, setSectionState] = useState('');
  const internalSetupForm = selectState(REDUX.INTERNAL_SETUP);
  form.data = internalSetupForm;
  const fyIsCalYr = internalSetupForm ? internalSetupForm[0].groups[15].fields[1] : null;
  const isFyIsCalYrNo = fyIsCalYr !== null && fyIsCalYr.value === KEY_NO;
  const {isActiveClient, isLoading} = useLoadOrganizer();

  useEffect(() => {
    hideSectionTitle(fyIsCalYr);
  }, [fyIsCalYr]);

  useEffect(() => {
    if (!isExportForm) updateCard();

    sections = [
      {
        title: 'Officers, Directors, Trustees, Key EE’s & Highest Comp EE’s',
        isVisible: true,
        sectionId: 1,
        hasDenseRow: true,
        groups: [
          {
            groupId: 1,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Was any individual compensated by the organization, a related organization or an unrelated organization for services provided to the filing organization?',
                name: 'compIndvRelOrgLabel',
                type: 'label',
                col: 9,
              },
              {
                name: 'compIndvRelOrg',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 3,
                triggered: 'showRelatedOrganization',
                logicFunction: [{ trigger: 'compIndvRelOrgLogic' }],
                prior: true,
                isPriorEditable: true,
                axcess: {
                  pull: {
                    fields: [
                      'XFDSOFCR.5',
                      'XFDSOFCR.8',
                      'XFDSOFCR.13',
                      'XFDSOFCR.6',
                      'XFDSOFCR.14',
                      'XFDSOFCR.18',
                      'XFDSOFCR.20',
                      'XFDSOFCR.21',
                      'XFDSOFCR.15',
                      'XFDSOFCR.7',
                    ],
                    fn: 'greaterThanZeroToRadioPull',
                  },
                },
              },
            ],
          },
          {
            groupId: 2,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label:
                  'Did any individual receive combined total compensation from the organization and any related organization in an amount greater than $150,000 (i.e. greater of Box 1 or 5 of W-2 plus fringe benefits provided)?',
                name: 'compReceived150KLabel',
                type: 'label',
                col: 9,
              },
              {
                name: 'compOver150k',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 3,
                triggered: 'showSupCompInfoSection',
              },
            ],
          },
          {
            groupId: 3,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label:
                  'How many total individuals, including those provided in the attachments or summary below, had more than $100,000 in reportable compensation from the organization?',
                name: 'compIndvOver100KLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Individuals',
                name: 'compIndvOver100K',
                type: 'total',
                col: 3,
              },
            ],
          },
          {
            groupId: 5,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Can all members be contacted through the Organization’s address?',
                name: 'compIndvAtMailLabel',
                type: 'label',
                col: 9,
              },
              {
                name: 'compIndvAtMail',
                type: 'radio',
                lookup: 'YESNO_RADIO',
                col: 3,
                logicFunction: [{ trigger: 'showOrgAddressQuestions' }],
              },
            ],
          },
          {
            groupId: 4,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Please provide data for the organization’s current officers, directors, trustees (whether individuals or organizations), and key employees. Also provide for anyone who received reportable compensation from the organization and any related or unrelated organizations and meets the following criteria: Five highest compensated employees who received more than $100,000; Former officers, key employees, and highest compensated employees who received more than $100,000; and/or any Former directors or trustees that in that capacity received more than $10,000.',
                name: 'attachOrTableLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Select',
                name: 'attachOrTable',
                type: 'select',
                lookup: 'TABLE_OR_ATTACH',
                col: 3,
                logicFunction: [{ trigger: 'showTableOrUpload' }],
                prior: true,
                isPriorEditable: true,
                axcess: {
                  pull: {
                    fields: ['XFDSOFCR.0'],
                    fn: 'attachOrTablePull',
                  },
                },
              },
            ],
          },
          {
            groupId: 8,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                  htmlLabel: (
                      <React.Fragment key={'bod-compensation'}>
                        Click <a href = "/Board of Directors and Compensation Template.xlsx" >here</a> to download CLA's preferred template. Upload once completed.
                      </React.Fragment>
                  ),
                  name: 'compIndvAttachDownlaodLabel',
                  type: 'label',
                  col: 12,
              }
          ],
          },
          {
            groupId: 6,
            isVisible: false,
            groupType: 'lineItem',
            uploadType: 'drawer',
            class: 'householdEmploymentUpload',
            fields: [
              {
                label: 'Upload any relevant documents for this section',
                name: 'compIndvAttachLabel',
                type: 'label',
                col: 9,
              },
              { type: 'lineButtons', col: 3 },
            ],
            lineItems: [],
            lineItemDetails: {
              headerLabels: [],
              footerLabels: [],
              lineButtons: [
                {
                  button: 'uploadOnly',
                  uploadSectionName: 'compIndvAttach',
                  viewSectionId: 0,
                },
              ],
            },
          },
          {
            groupId: 7,
            groupType: 'lineItem',
            isVisible: false,
            with990DenseRows: true,
            hasTableManagerTabs: true,
            entityNameField: 'compIndvName',
            entityTitleField: 'compIndvTitle',
            logicFunction: [
              { trigger: 'showOrgAddressQuestions' },
              { trigger: 'compIndvRelOrgLogic' },
              { trigger: 'showAverageHoursFields' },
            ],
            axcessGroup: {
              pull: {
                fromSections: [
                  'List of Officers, Directors, Trustees, Key Employees, Etc.',
                ],
                fieldPrefixes: ['XFDSOFCR'],
              },
            },
            entityIdentifiers: [
              {
                axcessDataType: 'PassDataToChild',
              },
              {
                axcessDataType: 'PassDataToChild',
              },
              {
                axcessDataType: 'PassDataToChild',
              },
              {
                axcessDataType: 'PassDataToChild',
              },
              {
                axcessDataType: 'PassDataToChild',
              },
            ],
            // hasSaveCancelButtons: true,
            fields: [
              {
                label: 'Name: \nTitle:',
                name: 'compIndvName',
                type: 'label',
                styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
                prior: true,
                pullCalculation: {
                  keyIdentifiers: ['XFDSOFCR.0', 'XFDSOFCR.1'],
                  fn: 'odtkeLabelPull',
                },
              },
              {
                label: 'Total: ',
                name: 'avgHoursLineSummary',
                type: 'label',
                styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
              },
              {
                label: '',
                name: 'compLineSummary',
                type: 'label',
                styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
              },
              {
                label:
                  'Total Nontaxable Benefits: $\nTotal Retirement Benefits: $\nTotal:',
                name: 'benefitsLineSummary',
                type: 'label',
                styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
              },
              {
                label:
                  'Gross Salaries/Wages: $\nTotal Retirement: $\nTotal Benefits: $',
                name: 'fiscalYearLineSummary',
                type: 'label',
                styles: { whiteSpace: 'pre-line', paddingTop: 0, fontSize: '10px' },
              },
              { type: 'lineButtons' },
            ],
            lineItems: [],
            entities: [],
            lineSections: orgSections,
            sorted: true,
            sortMethod: 'sortIndividualByCompensation',
            currentEntity: -1,
            subWorkSheets: [],
            headerLabelsPrintStyles: {gridTemplateColumns: '2.5fr 1fr 1.25fr 1.5fr 1.75fr 1fr'},
            lineItemDetails: {
              headerLabels: [
                {
                  label: 'About Individual',
                  col: 2,
                  colTablet: 1,
                  align: 'left',
                  styles: { paddingLeft: '48px' },
                },
                {
                  label: 'Avg Hrs/Wk',
                  col: 1,
                  colTablet: 2,
                  align: 'left',
                  styles: { paddingLeft: '44px' },
                },
                {
                  label: 'Compensation',
                  col: 3,
                  colTablet: 1,
                  align: 'left',
                  styles: { paddingLeft: '40px' },
                },
                {
                  label: 'Benefits Details',
                  col: 3,
                  colTablet: 1,
                  align: 'left',
                  styles: { paddingLeft: '32px' },
                },
                {
                  label: isFyIsCalYrNo ? 'Fiscal Year' : '',
                  col: 2,
                  colTablet: 1,
                  align: 'left',
                  styles: { paddingLeft: '25px' },
                },
                { label: 'Actions', col: 1, colTablet: 1, align: 'left' },
              ],
              footerLabels: [
                {
                  type: 'buttons',
                  col: 12,
                  buttonTitle: 'Add Another Officer/Director/Trustee/Key EE',
                  icon: 'add',
                },
              ],
              lineButtons: [{ button: 'remove' }],
            },
          },
        ],
      },
      {
        title: 'Supplemental Comp Info',
        isVisible: false,
        sectionId: 2,
        // triggered: 'showSupCompInfoSection',
        groups: [
          {
            groupId: 1,
            isVisible: true,
            fields: [
              {
                label: 'Supplemental Information',
                name: 'supplementalInfoLabel',
                type: 'label',
                col: 12,
                class: 'formSingleHeaderContainer',
              },
            ],
          },
          {
            groupId: 2,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Did the organization provide any of the following to or for anyone listed in the Board and Compensation data table? (Check all that apply)',
                name: 'supCompPerks',
                type: 'label',
                col: 10,
                class: 'labelQuestionTitle',
              },
            ],
          },
          {
            groupId: 3,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'First-class or charter travel?',
                name: 'supCompPerks1Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompPerks1',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompPerksQuestions',
              },
            ],
          },
          {
            groupId: 4,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Travel for companions?',
                name: 'supCompPerks2Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompPerks2',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompPerksQuestions',
              },
            ],
          },
          {
            groupId: 5,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Tax indemnification and gross-up payments?',
                name: 'supCompPerks3Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompPerks3',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompPerksQuestions',
              },
            ],
          },
          {
            groupId: 6,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Discretionary spending accounts?',
                name: 'supCompPerks4Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompPerks4',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompPerksQuestions',
              },
            ],
          },
          {
            groupId: 7,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Housing allowance or residence for personal use?',
                name: 'supCompPerks5Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompPerks5',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompPerksQuestions',
              },
            ],
          },
          {
            groupId: 8,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Payments for business use of personal residence?',
                name: 'supCompPerks6Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompPerks6',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompPerksQuestions',
              },
            ],
          },
          {
            groupId: 9,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Health or social club dues or initiation fees?',
                name: 'supCompPerks7Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompPerks7',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompPerksQuestions',
              },
            ],
          },
          {
            groupId: 10,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Personal services (e.g., maid, chauffeur, chef)?',
                name: 'supCompPerks8Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompPerks8',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompPerksQuestions',
              },
            ],
          },
          {
            groupId: 11,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label:
                  'Please provide any relevant information regarding provided items above:',
                name: 'supCompPerksInfoLabel',
                type: 'label',
                col: 12,
                class: 'labelTitleTextArea',
              },
              {
                label: 'Please explain',
                placeholder: 'Please enter your comments/questions',
                name: 'supCompPerksInfo',
                type: 'textarea',
                col: 12,
                maxRows: 3,
                class: 'fieldTextArea',
              },
            ],
          },
          {
            groupId: 12,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label:
                  'Did the organization follow a written policy regarding payment, reimbursement or provision of the expenses described above?',
                name: 'supCompPerksPolicyLabel',
                type: 'label',
                col: 10,
                class: 'labelQuestionTitleDropdown',
              },
              {
                label: 'Select',
                type: 'select',
                lookup: 'YESNO',
                name: 'supCompPerksPolicy',
                col: 2,
                triggered: 'showSupCompPerksPolicyExplain',
              },
            ],
          },
          {
            groupId: 13,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Please explain:',
                name: 'supCompPerksPolicyNoLabel',
                type: 'label',
                col: 12,
                class: 'labelTitleTextArea',
              },
              {
                label: 'Please explain',
                placeholder: 'Please enter your comments/questions',
                name: 'supCompPerksPolicyNo',
                type: 'textarea',
                col: 12,
                maxRows: 3,
                class: 'fieldTextArea',
              },
            ],
          },
          {
            groupId: 14,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label:
                  'For any of the benefits provided above, were they included in the recipients W-2?',
                name: 'supCompPerksW2Label',
                type: 'label',
                col: 10,
                class: 'labelQuestionTitleDropdown',
              },
              {
                label: 'Select',
                type: 'select',
                lookup: 'YESNO',
                name: 'supCompPerksW2',
                col: 2,
                triggered: 'showSupCompPerksW2Explain',
              },
            ],
          },
          {
            groupId: 15,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Please explain:',
                name: 'supCompPerksW2NoLabel',
                type: 'label',
                col: 12,
                class: 'labelTitleTextArea',
              },
              {
                label: 'Please explain',
                placeholder: 'Please enter your comments/questions',
                name: 'supCompPerksW2No',
                type: 'textarea',
                col: 12,
                maxRows: 3,
                class: 'fieldTextArea',
              },
            ],
          },
          {
            //not in zeplin??
            groupId: 16,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label:
                  'Did the organization require substantiation prior to reimbursing or allowing expenses incurred by all directors, trustees, and officers, including the CEO/Executive Director, regarding the items checked above?',
                name: 'supCompSubstantLabel',
                type: 'label',
                col: 10,
                class: 'labelQuestionTitleDropdown2',
              },
              {
                label: 'Select',
                type: 'select',
                lookup: 'YESNO',
                name: 'supCompSubstant',
                col: 2,
              },
            ],
          },
          {
            groupId: 17,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Which of the following does the organization (excluding related organizations) use to establish the compensation of the CEO/Executive Director? (Check all that apply)',
                name: 'supCompMthd',
                type: 'label',
                col: 10,
                class: 'labelQuestionTitle',
              },
            ],
          },
          {
            groupId: 18,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Compensation committee?',
                name: 'supCompMthd1Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompMthd1',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompMthdExpl',
              },
            ],
          },
          {
            groupId: 19,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Independent compensation consultant?',
                name: 'supCompMthd2Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompMthd2',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompMthdExpl',
              },
            ],
          },
          {
            groupId: 20,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Form 990 of other organizations?',
                name: 'supCompMthd3Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompMthd3',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompMthdExpl',
              },
            ],
          },
          {
            groupId: 21,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Written employment contract?',
                name: 'supCompMthd4Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompMthd4',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompMthdExpl',
              },
            ],
          },
          {
            groupId: 22,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Compensation survey or study?',
                name: 'supCompMthd5Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompMthd5',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompMthdExpl',
              },
            ],
          },
          {
            groupId: 23,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Approval by the board or compensation committee?',
                name: 'supCompMthd6Label',
                type: 'label',
                col: 10,
                class: 'labelSingleCheckbox',
              },
              {
                label: 'Yes',
                type: 'checkbox',
                isFieldCheckbox: true,
                name: 'supCompMthd6',
                col: 2,
                class: 'singleCheckbox',
                triggered: 'showSupCompMthdExpl',
              },
            ],
          },
          {
            groupId: 24,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Please explain:',
                name: 'supCompMthdExplLabel',
                type: 'label',
                col: 12,
                class: 'labelTitleTextArea',
              },
              {
                label: 'Please explain',
                placeholder: 'Please enter your comments/questions',
                name: 'supCompMthdExpl',
                type: 'textarea',
                col: 12,
                maxRows: 3,
                class: 'fieldTextArea',
              },
            ],
          },
          {
            groupId: 25,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'During the year, did any current or former officers, directors, trustees, key employees or highest compensated employees:',
                name: 'textOne',
                type: 'label',
                col: 10,
                class: 'labelQuestionTitle',
              },
            ],
          },
          {
            groupId: 26,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'Receive a severance payment or change of control payment?',
                name: 'supCompSvrncLabel',
                type: 'label',
                col: 10,
              },
              {
                type: 'radio',
                lookup: 'YESNO_RADIO',
                name: 'supCompSvrnc',
                col: 2,
                triggered: 'supCompPay1',
              },
            ],
          },
          {
            groupId: 27,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  "Participate in, or receive payment from, a supplemental nonqualified retirement plan (a '457(f)' plan)?",
                name: 'supCompNonQaulRetLabel',
                type: 'label',
                col: 10,
              },
              {
                type: 'radio',
                lookup: 'YESNO_RADIO',
                name: 'supCompNonQaulRet',
                col: 2,
                triggered: 'supCompPay2',
              },
            ],
          },
          {
            groupId: 28,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Participate in, or receive payment from, an equity-based compensation agreement?',
                name: 'supCompEqtyBasedLabel',
                type: 'label',
                col: 10,
              },
              {
                type: 'radio',
                lookup: 'YESNO_RADIO',
                name: 'supCompEqtyBased',
                col: 2,
                triggered: 'supCompPay3',
              },
            ],
          },
          {
            groupId: 29,
            isVisible: false,
            fields: [
              {
                label:
                  'Provide the following information for each applicable individual:',
                name: 'supCompPay',
                type: 'label',
                col: 10,
                class: 'labelQuestionTitle',
              },
            ],
          },
          {
            groupId: 30,
            groupType: 'lineItem',
            isVisible: false,
            fields: [
              { label: 'Name', name: 'supCompPayName', type: 'freeText' },
              { label: 'Description', name: 'supCompPayDesc', type: 'freeText' },
              { label: 'Amount', name: 'supCompPayAmt', type: 'money' },
              {
                label: 'Plan Description',
                name: 'supCompPayPlan',
                type: 'freeText',
              },
              { type: 'lineButtons' },
            ],
            lineItems: [],
            headerLabelsPrintStyles: {gridTemplateColumns: '2fr 1.75fr 1.75fr 1.75fr .75fr'},
            lineItemDetails: {
              headerLabels: [
                { label: 'Name', col: 2, colTablet: 1, align: 'left' },
                { label: 'Description', col: 3, colTablet: 2, align: 'left' },
                {
                  label: 'Calendar Year Amount',
                  col: 2,
                  colTablet: 2,
                  align: 'right',
                },
                {
                  label:
                    'Please describe the plan and/or situation under which the payment(s) were made',
                  col: 4,
                  colTablet: 2,
                  align: 'left',
                },
                { label: 'Actions', col: 1, colTablet: 1, align: 'left' },
              ],
              footerLabels: [
                {
                  type: 'buttons',
                  col: 12,
                  buttonTitle: 'Add Another Individual',
                  icon: 'add',
                },
              ],
              lineButtons: [{ button: 'remove' }],
            },
          },
          // {
          //     groupId: 29.1,
          //     isVisible: true,
          //     fields: [
          //         { label: 'Provide the following information for each applicable individual:', name: 'supCompPay1', type: 'label', col: 10 },
          //     ]
          // },
          // {
          //     groupId: 30.1,
          //     groupType: 'lineItem',
          //     isVisible: true,
          //     fields: [
          //         { label: 'Name', name: 'supCompPayName1', type: 'freeText',   },
          //         { label: 'Description', name: 'supCompPayDesc1', type: 'freeText',   },
          //         { label: 'Calendar Year Amount', name: 'supCompPayAmt1', type: 'money',   },
          //         { type: 'lineButtons' },
          //         { label: 'Please describe the plan and/or situation under which the payment(s) were made', name: 'supCompPayPlan1', type: 'textarea', maxRows: 2, class: 'fieldTextArea' },

          //     ],
          //     lineItems: [],
          //     lineItemDetails: {
          //         headerLabels: [
          //             { label: 'Individual Information', col: 3, colTablet: 2, align: 'left',  },
          //             { label: '', col: 6, colTablet: 3, align: 'left',  },
          //             { label: '', col: 2, colTablet: 2, align: 'right',  },
          //             { label: '', col: 1, colTablet: 1, align: 'left', },
          //             { label: '', col: 12, colTablet: 8, align: 'left', },
          //         ],
          //         footerLabels: [
          //             { type: 'buttons', col: 12, buttonTitle: 'Add Another Individual', icon: 'add' },
          //         ],
          //         lineButtons: [
          //         ]
          //     },
          // },
          {
            groupId: 31,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Did any current or former officers, directors, trustees, key employees or highest compensated employees receive or accrue compensation contingent on the revenues of:',
                name: 'textTwo',
                type: 'label',
                col: 12,
                class: 'labelQuestionTitle afterTable',
              },
            ],
          },
          {
            groupId: 32,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'The organization?',
                name: 'supCompContRevOrgLabel',
                type: 'label',
                col: 10,
              },
              {
                type: 'radio',
                lookup: 'YESNO_RADIO',
                name: 'supCompContRevOrg',
                col: 2,
                triggered: 'showSupCompContRevOrgDesc',
              },
            ],
          },
          {
            groupId: 33,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Please explain:',
                name: 'supCompContRevOrgDescLabel',
                type: 'label',
                col: 12,
                class: 'labelTitleTextArea',
              },
              {
                label: 'Please explain',
                placeholder: 'Please enter your comments/questions',
                name: 'supCompContRevOrgDesc',
                type: 'textarea',
                col: 12,
                maxRows: 3,
                class: 'fieldTextArea',
              },
            ],
          },
          {
            groupId: 34,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Any related organizations?',
                name: 'supCompContRevOrgRelLabel',
                type: 'label',
                col: 10,
              },
              {
                type: 'radio',
                lookup: 'YESNO_RADIO',
                name: 'supCompContRevOrgRel',
                col: 2,
                triggered: 'showSupCompContRevOrgRelDesc',
              },
            ],
          },
          {
            groupId: 35,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Please explain:',
                name: 'supCompContRevOrgRelDescLabel',
                type: 'label',
                col: 12,
                class: 'labelTitleTextArea',
              },
              {
                label: 'Comments/Questions',
                placeholder: 'Please enter your comments/questions',
                name: 'supCompContRevOrgRelDesc',
                type: 'textarea',
                col: 12,
                maxRows: 3,
                class: 'fieldTextArea',
              },
            ],
          },
          {
            groupId: 36,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Did any current or former officers, directors, trustees, key employees or highest compensated employees receive or accrue compensation contingent on the net earnings of:',
                name: 'textThree',
                type: 'label',
                col: 12,
                class: 'labelQuestionTitle',
              },
            ],
          },
          {
            groupId: 37,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label: 'The organization?',
                name: 'supCompContEarnOrgLabel',
                type: 'label',
                col: 10,
              },
              {
                type: 'radio',
                lookup: 'YESNO_RADIO',
                name: 'supCompContEarnOrg',
                col: 2,
                triggered: 'showSupCompContEarnOrgDesc',
              },
            ],
          },
          {
            groupId: 38,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Please explain:',
                name: 'supCompContEarnOrgDescLabel',
                type: 'label',
                col: 12,
                class: 'labelTitleTextArea',
              },
              {
                label: 'Comments/Questions',
                placeholder: 'Please enter your comments/questions',
                name: 'supCompContEarnOrgDesc',
                type: 'textarea',
                col: 12,
                maxRows: 3,
                class: 'fieldTextArea',
              },
            ],
          },
          {
            groupId: 39,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Any related organizations?',
                name: 'supCompContEarnOrgRelLabel',
                type: 'label',
                col: 10,
              },
              {
                type: 'radio',
                lookup: 'YESNO_RADIO',
                name: 'supCompContEarnOrgRel',
                col: 2,
                triggered: 'showSupCompContEarnOrgRelDesc',
              },
            ],
          },
          {
            groupId: 40,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Please explain:',
                name: 'supCompContEarnOrgRelDescLabel',
                type: 'label',
                col: 12,
                class: 'labelTitleTextArea',
              },
              {
                label: 'Please explain',
                placeholder: 'Please enter your comments/questions',
                name: 'supCompContEarnOrgRelDesc',
                type: 'textarea',
                col: 12,
                maxRows: 3,
                class: 'fieldTextArea',
              },
            ],
          },
          {
            groupId: 41,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  "Did any current or former officers, directors, trustees, key employees or highest compensated employees receive any non-fixed payments not contingent on the organization's revenues or net earnings?",
                name: 'supCompContNonFixedLabel',
                type: 'label',
                col: 10,
                class: 'labelQuestionTitleDropdown2',
              },
              {
                type: 'radio',
                lookup: 'YESNO_RADIO',
                name: 'supCompContNonFixed',
                col: 2,
                triggered: 'showSupCompContNonFixedDesc',
              },
            ],
          },
          {
            groupId: 42,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Please explain:',
                name: 'supCompContNonFixedDescLabel',
                type: 'label',
                col: 12,
                class: 'labelTitleTextArea',
              },
              {
                label: 'Please explain',
                placeholder: 'Please enter your comments/questions',
                name: 'supCompContNonFixedDesc',
                type: 'textarea',
                col: 12,
                maxRows: 3,
                class: 'fieldTextArea',
              },
            ],
          },
          {
            groupId: 43,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'Were any compensation amounts above pursuant to a binding written contract with a person who was not a disqualified person immediately prior to entering the contract?',
                name: 'supCompContExceptPayLabel',
                type: 'label',
                col: 10,
                class: 'labelQuestionTitleDropdown2',
              },
              {
                type: 'radio',
                lookup: 'YESNO_RADIO',
                name: 'supCompContExceptPay',
                col: 2,
                triggered: 'showSupCompContExceptPayDesc',
              },
            ],
          },
          {
            groupId: 44,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label: 'Please explain:',
                name: 'supCompContExceptPayDescLabel',
                type: 'label',
                col: 12,
                class: 'labelTitleTextArea',
              },
              {
                label: 'Please explain',
                placeholder: 'Please enter your comments/questions',
                name: 'supCompContExceptPayDesc',
                type: 'textarea',
                col: 12,
                maxRows: 3,
                class: 'fieldTextArea',
              },
            ],
          },
          {
            groupId: 45,
            isVisible: false,
            hasDivider: true,
            fields: [
              {
                label:
                  'Did the organization use an independent review, comparability data and timely document the deliberations and final decisions that were made regarding compensation?',
                name: 'supCompContExceptPayRebutLabel',
                type: 'label',
                col: 10,
                class: 'labelQuestionTitleDropdown2',
              },
              {
                type: 'radio',
                lookup: 'YESNO_RADIO',
                name: 'supCompContExceptPayRebut',
                col: 2,
              },
            ],
          },
        ],
      },
      {
        title: 'Independent Contractors',
        isVisible: true,
        sectionId: 3,
        sorted: true,
        groups: [
          {
            groupId: 1,
            isVisible: true,
            hasDivider: true,
            fields: [
              {
                label:
                  'How many total independent contractors received more than $100,000 in compensation from the organization?',
                name: 'indContractCompOver100kLabel',
                type: 'label',
                col: 9,
              },
              {
                label: 'Number of Individuals',
                name: 'indContractCompOver100kNo',
                type: 'total',
                col: 3,
                logicFunction: [{ trigger: 'showIndependentContractorsTable' }],
              },
            ],
          },
          {
            groupId: 2,
            isVisible: false,
            fields: [
              {
                label:
                  'Please provide below, the detailed information for your top 5 compensated independent contractors.',
                name: 'top5InstructionLabel',
                type: 'label',
                col: 12,
              },
            ],
          },
          {
            groupId: 3,
            isVisible: false,
            fields: [
              {
                label: 'Contractor Information',
                name: 'top5IndContractorLabel',
                type: 'label',
                col: 12,
                class: 'formSingleHeaderContainer',
              },
            ],
          },
          {
            groupId: 4,
            isVisible: false,
            hasDivider: true,
            printStyles: {zoom: '70%'},
            fields: independentContractorFields('1'),
          },
          {
            groupId: 5,
            isVisible: false,
            hasDivider: true,
            printStyles: {zoom: '70%'},
            fields: independentContractorFields('2'),
          },
          {
            groupId: 6,
            isVisible: false,
            hasDivider: true,
            printStyles: {zoom: '70%'},
            fields: independentContractorFields('3'),
          },
          {
            groupId: 7,
            isVisible: false,
            hasDivider: true,
            printStyles: {zoom: '70%'},
            fields: independentContractorFields('4'),
          },
          {
            groupId: 8,
            isVisible: false,
            hasDivider: true,
            printStyles: {zoom: '70%'},
            fields: independentContractorFields('5'),
          },
        ],
      },
      {  
        title: 'Additional Information',
        collapseClass: 'collapsibleForm',
        isVisible: true,
        isDefaultExpanded: false,
        isOptionalSectionMerge: true,
        sectionId: 4,
        groups: [
          {
            groupId: 2,
            groupType: 'details',
            fields: [
              { 
                label: 'Please use the area below to provide any additional information you think we may need or questions you would like to discuss.', 
                type: 'label', 
                class: 'labelLeftLight' 
              },
            ]
          },
          {
            groupId: 1,
            fields: [
              {
                label: 'Start typing to provide additional information', 
                name: 'additionalInfoTextArea', 
                type: 'textarea', 
                col: 12,
                maxLength: 9000
              }
            ]
          }
        ]
      },
    ];

    setSectionState(sections);
    // eslint-disable-next-line
  }, []);

  const axcessIdentifiers = [
    { section: 'Return Type', id: 'Form 990 - Exempt Organization Return' },
  ];

  return (
    <>
      {!isLoading && isActiveClient && <FormRendererHelper
        sections={sectionState}
        key="boardOfDirectorsAndCompensationForm"
        formName={REDUX.BOARD_OF_DIRECTORS_AND_COMPENSATION}
        identifiers={axcessIdentifiers}
        triggeredEvent={triggeredEvent}
        sectionFieldLogic={sectionFieldLogic}
        isExportForm={isExportForm}
      />}
      {!isLoading && !isActiveClient && <NoActiveClient />}
    </>
  );
}

export default BoardOfDirectorsAndCompensation;
