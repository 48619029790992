import React from 'react';
import YearField from '../YearField';
function YearCalendar(props) {
    return (
        <>
            <YearField
                {...props}
            />
        </>
    );
}
export default YearCalendar;