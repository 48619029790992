import { useState, useEffect } from 'react'; 
import { Button, Dialog } from 'react-md';
import nextId from 'react-id-generator';
import _ from 'lodash';

// custom modules
import * as IMG from '@utilities/constants/images.js';
import '@components/WarningDialog.css';
import usePageFramework from '@utilities/hooks/usePageFramework';
import * as STR from '@utilities/constants/strings'

function UploadWarningDialog(props){
    const { visible, dupFiles, uploadProps, uploadList } = props;
    const { dispatch, ACTION, onFileRemoval, uploadFiles } = usePageFramework();
    const [ currentDupFileIndex, setCurrentDupFileIndex ] = useState(0);
    const isMultiUpload = uploadProps?.files?.length > 1;
    let updatedList = _.cloneDeep(uploadList);

    const onCancelWarning = () => {
        dispatch(ACTION.setUploadWarningVisible(false));
    };

    const removeAndUpload = () => {
        if (currentDupFileIndex === dupFiles.length - 1) {
            const replacedFiles = dupFiles.filter((dup) => dup.isReplaced) || []; 
            const skipFiles = dupFiles.filter((dup) => dup.isSkip) || []; 
            const filesToUpload = uploadProps?.files?.filter((upload) => !upload.isSkip);
            updatedList = updatedList?.filter((upload) => !upload.isReplaced);

            if (replacedFiles.length) {
                dupFiles.forEach((file, index) => {
                    if (file.isReplaced) {
                        onFileRemoval(null, file, replacedFiles).then(() => {
                            if (replacedFiles?.length === 1 || (replacedFiles?.length > 1 && index === replacedFiles?.length - 1)) {
                                uploadFiles(filesToUpload, uploadProps?.section, uploadProps?.fn, uploadProps?.form, updatedList );
                            } 
                        })
                    } 
                })
            } else if (skipFiles.length) {
                uploadFiles(filesToUpload, uploadProps?.section, uploadProps?.fn, uploadProps?.form, updatedList );
            }
            dispatch(ACTION.setUploadWarningVisible(false));
            dispatch(ACTION.setDuplicateUploadFiles(null));
            dispatch(ACTION.setUploadsProps(null));
        } else {
            setCurrentDupFileIndex(currentDupFileIndex + 1);
        }
    };
    
    const onSkipWarning = () => {
        //remove the skipped duplicate file on the files to upload
        if (dupFiles[currentDupFileIndex]) {
            dupFiles[currentDupFileIndex].isSkip = true;
        }
        uploadProps?.files?.forEach((doc) => {
            if (doc.name ===  dupFiles[currentDupFileIndex].name) doc.isSkip = true;
        });
        removeAndUpload();
    };

    const onReplaceWarning = () => {
        if (dupFiles[currentDupFileIndex]) {
            dupFiles[currentDupFileIndex].isReplaced = true;
        }
        updatedList?.forEach((doc) => {
            if (doc.name ===  dupFiles[currentDupFileIndex]?.name) doc.isReplaced = true;
        });
        uploadProps?.files?.forEach((doc) => {
            if (doc.name ===  dupFiles[currentDupFileIndex]?.name) doc.isReplaced = true;
        });
        removeAndUpload();
    };

    useEffect(()=> {
        setCurrentDupFileIndex(0);
    },[visible]);

    return( dupFiles?.length ?
        <Dialog
            id={`warning-dialog-${nextId()}`}
            role="alertdialog"
            visible={visible}
            onRequestClose={()=> {}}
            aria-labelledby="dialog-title"
            className="WarningDialogContainer"
            data-testid="upload-warning-dialog"
        >
            <div className='WarningDialogInnerContainer' >
                <div className='WarningSignContainer'>
                    <img className="warningDialogIcon" width="24" height="20" alt="Warning icon" src={IMG.WARNING_ICON}></img>
                    <div className="SuccessDialogTitle"> {STR.WARNING} </div>
                </div>
                <div className="WarningDialogTextContainer">
                    <div className="WarningDialogText1"> A file named <span style={{color: '#2d3c59'}}>{`"${dupFiles[currentDupFileIndex]?.name}"` || ''}</span> already exists in this location.  Would you like to replace the existing file? </div>
                </div>
                <div className="warningDialogButton">
                    <Button className="warningDialogSkip" data-testid="dialog-cancel" id="dialog-cancel" onClick={onCancelWarning} > {isMultiUpload ? STR.CANCEL_ALL : STR.CANCEL} </Button>
                    {isMultiUpload && <Button className="warningDialogSkip" data-testid="dialog-skip" id="dialog-skip" onClick={onSkipWarning} > {STR.SKIP} </Button>}
                    <Button className="warningDialogCancel" style={{fontSize: '11px'}} data-testid="dialog-replace" id="dialog-replace" onClick={onReplaceWarning} > {STR.REPLACE} </Button>
                </div>
            </div>
        </Dialog> : null
    );
}

export default UploadWarningDialog;