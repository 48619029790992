import * as NAV from '@utilities/constants/navigation';
import * as IMG from '@utilities/constants/images';
import * as STR from '@utilities/constants/strings';
import * as REDUX from '@utilities/constants/redux';

const dashboard = [
	{
		title: STR.BASIC_INFO,
		cards: [
			{ title: STR.ENTRY_EXPERIENCE, key: 'dashCard-1-1', formName: REDUX.ENTRY_INTERVIEW, statusId: 1, route: NAV.ENTRY_INTERVIEW, dashBoardIcon: IMG.ENTRY_EXP_NEW, titles: [], isRequired: true, },
			{ title: STR.BASIC_DATA, key: 'dashCard-1-2', formName: REDUX.BASIC_DATA, statusId: 1, route: NAV.BASIC_DATA, dashBoardIcon: IMG.BASIC_DATA_NEW, titles: [], isRequired: true },
			{ title: STR.PROGRAMS, key: 'dashCard-1-3', formName: REDUX.PROGRAMS, statusId: 1, route: NAV.PROGRAMS, dashBoardIcon: IMG.PROGRAMS, titles: [], isRequired: true, },
			{ title: STR.INTERNAL_SETUP, key: 'dashCard-1-4', formName: REDUX.INTERNAL_SETUP, statusId: 1, route: NAV.INTERNAL_SETUP, dashBoardIcon: IMG.INTERNAL_SETUP, shouldRenderForPractioner: true, titles: [], isRequired: true, },
		]
	},
	{
		title: STR.GOVERNANCE,
		cards: [
			{ title: STR.TAX_COMPLIANCE_FILINGS, key: 'dashCard-2-1', formName: REDUX.TAX_COMPLIANCE_FILINGS, statusId: 1, route: NAV.TAX_COMPLIANCE_FILINGS, dashBoardIcon: IMG.TAX_COMPLIANCE_FILING_NEW, titles: [], isRequired: true, },
			{ title: STR.GOVERNANCE_AND_MANAGEMENT, key: 'dashCard-2-2', formName: REDUX.GOVERNANCE_AND_MANAGEMENT, statusId: 1, route: NAV.GOVERNANCE_AND_MANAGEMENT, dashBoardIcon: IMG.GOVERNANCE_AND_MANAGEMENT, titles: [], isRequired: true, },
			{ title: STR.BOARD_OF_DIRECTORS_AND_COMPENSATION, subtitle: STR.SCH_J, key: 'dashCard-2-3', formName: REDUX.BOARD_OF_DIRECTORS_AND_COMPENSATION, statusId: 1, route: NAV.BOARD_OF_DIRECTORS_AND_COMPENSATION, dashBoardIcon: IMG.BOARD_OF_DIRECTORS_AND_COMPENSATION, titles: [], isRequired: true, },
		]
	},
	{
		title: STR.FINANCIAL,
		cards: [
			{ title: STR.FINANCIALS, key: 'dashCard-3-1', formName: REDUX.FINANCIALS, statusId: 1, route: NAV.FINANCIALS, dashBoardIcon: IMG.FINANCIALS, titles: [], isRequired: true, },
			{ title: STR.CONTRIBUTIONS, subtitle: STR.SCHED_BM, key: 'dashCard-3-2', formName: REDUX.CONTRIBUTIONS, statusId: 1, route: NAV.CONTRIBUTIONS, dashBoardIcon: IMG.CONTRIBUTIONS, titles: [] },
			{ title: STR.FUNDRAISING_AND_GAMING, subtitle: STR.SCH_G, key: 'dashCard-3-3', formName: REDUX.FUNDRAISING_AND_GAMING, statusId: 1, route: NAV.FUNDRAISING_AND_GAMING, dashBoardIcon: IMG.FUNDRAISING_AND_GAMING, },
			{ title: STR.FOREIGN_ACTIVITIES, subtitle: STR.SCH_F, key: 'dashCard-3-4', formName: REDUX.FOREIGN_ACTIVITIES, statusId: 1, route: NAV.FOREIGN_ACTIVITIES, dashBoardIcon: IMG.FOREIGN_ACTIVITIES_NEW, titles: [], },
			{ title: STR.POLITICAL_CAMPAIGN_AND_LOBBYING, subtitle: STR.SCH_C, key: 'dashCard-3-5', formName: REDUX.POLITICAL_CAMPAIGN_AND_LOBBYING, statusId: 1, route: NAV.POLITICAL_CAMPAIGN_AND_LOBBYING, dashBoardIcon: IMG.POLITICAL_CAMPAIGNING_NEW, titles: [], },
			{ title: STR.DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE, subtitle: STR.SCH_I, key: 'dashCard-3-6', formName: REDUX.DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE, statusId: 1, route: NAV.DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE, dashBoardIcon: IMG.DOMESTIC_GRANTS_AND_OTHER_ASSISTANCE, titles: [] },
			{ title: STR.LIQUIDATION_AND_DISPOSITION_OF_ASSETS, subtitle: STR.SCH_N, key: 'dashCard-3-7', formName: REDUX.LIQUIDATION_AND_DISPOSITION_OF_ASSETS, statusId: 1, route: NAV.LIQUIDATION_AND_DISPOSITION_OF_ASSETS, dashBoardIcon: IMG.LIQUIDATION_AND_DISPOSITION_OF_ASSETS, titles: [], },
			{ title: STR.PUBLIC_CHARITY_AND_SUPPORT, subtitle: STR.SCH_A, key: 'dashCard-3-8', formName: REDUX.PUBLIC_CHARITY_AND_SUPPORT, statusId: 1, route: NAV.PUBLIC_CHARITY_AND_SUPPORT, dashBoardIcon: IMG.PUBLIC_CHARITY_AND_SUPPORT_NEW, titles: [], },
			{ title: STR.UNRELATED_BUSINESS_INCOME_TAX, key: 'dashCard-3-9', formName: REDUX.UNRELATED_BUSINESS_INCOME_TAX, statusId: 1, route: NAV.UNRELATED_BUSINESS_INCOME_TAX, dashBoardIcon: IMG.UNRELATED_BUSINESS_INCOME_TAX, isRequired: true, titles: [] },
		]
	},
	{
		title: STR.OTHER_SCHEDULES,
		cards: [
			{ title: STR.INTERESTED_PERSONS_TRANSACTIONS, subtitle: STR.SCH_L, key: 'dashCard-4-1', formName: REDUX.INTERESTED_PERSONS_TRANSACTIONS, statusId: 1, route: NAV.INTERESTED_PERSONS_TRANSACTIONS, dashBoardIcon: IMG.INTERESTED_PERSONS_TRANSACTIONS, titles: [], },
			{ title: STR.RELATED_ORGANIZATIONS_SCHED_R, subtitle: STR.SCH_R, key: 'dashCard-4-2', formName: REDUX.RELATED_ORGANIZATIONS_SCHED_R, statusId: 1, route: NAV.RELATED_ORGANIZATIONS_SCHED_R, dashBoardIcon: IMG.RELATED_ORGANIZATIONS, formTitle: STR.SCHED_R_FORM_TITLE },
			{ title: STR.SUPPLEMENTAL_FINANCIAL_STATEMENTS, subtitle: STR.SCH_D, key: 'dashCard-4-3', formName: REDUX.SUPPLEMENTAL_FINANCIAL_STATEMENTS, statusId: 1, route: NAV.SUPPLEMENTAL_FINANCIAL_STATEMENTS, dashBoardIcon: IMG.SUPPLEMENTAL_FINANCIAL, titles: [] },
			{ title: STR.TAX_EXEMPT_BONDS, subtitle: STR.SCH_K, key: 'dashCard-4-4', formName: REDUX.TAX_EXEMPT_BONDS, statusId: 1, route: NAV.TAX_EXEMPT_BONDS, dashBoardIcon: IMG.TAX_EXEMPT_BONDS, },
			{ title: STR.HOSPITALS, subtitle: STR.SCH_H, key: 'dashCard-4-5', formName: REDUX.HOSPITAL, statusId: 1, route: NAV.HOSPITAL, dashBoardIcon: IMG.HOSPITALS, },
			{ title: STR.SCHOOLS, subtitle: STR.SCH_E, key: 'dashCard-4-6', formName: REDUX.SCHOOLS, statusId: 1, route: NAV.SCHOOLS, dashBoardIcon: IMG.SCHOOLS_NEW, titles: [], },
		]
	},
];

const dashboardCards = dashboard.flatMap((item) => item.cards);

export {
	dashboard,
	dashboardCards,
};